import React from 'react';
import { useNavigate } from 'react-router-dom';
import { gtagSearch } from '../../action/gTag';
import { paginationAtom } from '../../atom/atom';
import { useRecoilState } from 'recoil';

function Search2({ searchKeyword, onChangeSearchKeyword, resetSearchKeyword }) {
  const navigate = useNavigate();
  const [, setPage] = useRecoilState(paginationAtom);

  const onSearchKeyword = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!searchKeyword.trim().length) return null;

    resetSearchKeyword();
    setPage(1);
    localStorage.setItem('scroll', 0);
    navigate(`/search?q=${searchKeyword}`);

    // gtag 검색 추적 태그
    gtagSearch(searchKeyword);
    // console.log('searchKeyword', searchKeyword);
  };

  const onEnterKeyword = e => {
    if (e.key === 'Enter') onSearchKeyword(e);
  };

  return (
    <div className='searchWrap'>
      <input
        type='text'
        className='inputSearch'
        title='Search'
        value={searchKeyword}
        onChange={onChangeSearchKeyword}
        onKeyDown={onEnterKeyword}
      />
      <button tabIndex='0' type='button' className='buttonSearch' onClick={onSearchKeyword}>
        Search
      </button>
    </div>
  );
}

export default Search2;
