const CheckoutProcess = ({ step }) => {
  return (
    <div className='process'>
      <div className='container'>
        <ul>
          <li>
            <div className='process-item'>
              <span className='num'>1</span>
              <span className='label'>Cart</span>
            </div>
          </li>
          <li>
            <div className={`process-item ${step === 2 && 'active'}`}>
              <span className='num'>2</span>
              <span className='label'>Payment</span>
            </div>
          </li>
          <li>
            <div className={`process-item ${step === 3 && 'active'}`}>
              <span className='num'>3</span>
              <span className='label'>Download</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CheckoutProcess;
