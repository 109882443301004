import CartFreeExpiredProduct from './CartFreeExpiredProduct';
import CartFreeNotExpiredProduct from './CartFreeNotExpiredProduct';

const CartFreeProduct = ({ data, limit, deleteItem }) => {
  return (
    <>
      {limit.expireTime && (
        <CartFreeExpiredProduct data={data} limit={limit} deleteItem={deleteItem} />
      )}
      {!limit.expireTime && (
        <CartFreeNotExpiredProduct data={data} limit={limit} deleteItem={deleteItem} />
      )}
    </>
  );
};

export default CartFreeProduct;
