export function getColorByStatus(status) {
  switch (status) {
    case 'public':
      return '#4CBE37';
    case 'secret':
      return '#FD5757';
    default:
      return '#969696';
  }
}

export function getByStatus(status) {
  switch (status) {
    case 'public':
      return 'Public';
    case 'secret':
      return 'Not for sale';
    default:
      return 'Draft';
  }
}

export function getByBtnTitle(status) {
  switch (status) {
    case 'public':
      return 'Update';
    case 'secret':
      return 'Resume Sale';
    default:
      return 'Publish';
  }
}

export function getByModalContantDraft(status) {
  const defaultButton = { title: 'Close', backgroundColor: '#fff', color: '#000' };
  const cancelButton = { title: 'Cancel', backgroundColor: '#fff', color: '#000' };
  const confirmButton = { title: 'Confirm', backgroundColor: '#4CBE37', color: '#fff' };
  const failedButton = { title: 'Close', backgroundColor: '#4CBE37', color: '#fff' };

  const modalConfigs = {
    back: {
      value: 'back',
      title: 'Are you sure?',
      modalContent: 'Do you want to leave without saving? \n Unsaved changes will be lost.',
      btnContents: [cancelButton, { title: 'Leave', backgroundColor: '#FD5757', color: '#fff' }],
    },
    draft: {
      value: 'draft',
      title: 'Draft saved',
      btnContents: [confirmButton],
    },
    failedpublic: {
      value: 'failed',
      title: 'Publishing failed',
      modalContent: 'Some required information is missing.',
      btnContents: [failedButton],
    },
    failedupdate: {
      value: 'failed',
      title: 'Update failed',
      modalContent: 'Some required information is missing.',
      btnContents: [failedButton],
    },
    update: {
      value: 'update',
      title: 'Would you like to\nupdate the model information?',
      btnContents: [cancelButton, confirmButton],
    },
    stop: {
      value: 'stop',
      title: 'Are you sure?',
      modalContent:
        'If you stop selling, the model will no longer be visible to customers. \nHowever, customers who have already purchased the model \ncan continue to download it.',
      btnContents: [
        cancelButton,
        { title: 'Stop Selling', backgroundColor: '#FD5757', color: '#fff' },
      ],
    },
    doneupdated: {
      value: 'doneupdated',
      title: 'The model information \n has been updated',
      btnContents: [
        defaultButton,
        { title: 'Check the model', backgroundColor: '#4CBE37', color: '#fff' },
      ],
    },
    donepublished: {
      value: 'donepublished',
      title: 'The model \n has been published',
      btnContents: [
        defaultButton,
        { title: 'Check the model', backgroundColor: '#4CBE37', color: '#fff' },
      ],
    },
    doneresumed: {
      value: 'doneresumed',
      title: 'The sale of the model \n has resumed',
      btnContents: [
        defaultButton,
        { title: 'Check the model', backgroundColor: '#4CBE37', color: '#fff' },
      ],
    },
    resume: {
      value: 'resume',
      title: 'The sale of the model \n has resumed',
      btnContents: [
        defaultButton,
        { title: 'Check the model', backgroundColor: '#4CBE37', color: '#fff' },
      ],
    },
    secret: {
      value: 'secret',
      title: 'Would you like to \n resume the sale of this model?',
      btnContents: [cancelButton, confirmButton],
    },
    public: {
      value: 'public',
      title: 'Would you like to \n  publish the model?',
      btnContents: [cancelButton, confirmButton],
    },
  };

  return modalConfigs[status];
}

export function makeManageList(
  idStudio,
  idItem,
  status,
  originalPrice,
  discountRate,
  finalPrice,
  ctgId,
  tags,
  printingTime,
  largestPartSize,
  description,
  coverImg,
  coverImgHover,
  files,
  detailImgList,
  modelName,
  totalWeight
) {
  const data = {
    idStudio: idStudio,
    idItem: idItem, // 수정일경우
    status: status, // 수정일경우
    name: modelName,
    originalPrice: originalPrice,
    discountRate: discountRate,
    finalPrice: finalPrice,
    idCategory: ctgId,
    tags: tags,
    printingTime: printingTime,
    totalWeight: totalWeight,
    partSizeX: largestPartSize.xAxis,
    partSizeY: largestPartSize.yAxis,
    partSizeZ: largestPartSize.zAxis,
    description: description,
    listImg: coverImg,
    listImg2: coverImgHover,
    files: files,
    detailImg: detailImgList,
  };
}
