import React from 'react';
import { PreviewImg } from '../../designers/Contents/manageModelPage/manageEditStyle';

const PreviewImgView = ({ onClickCloseImg, zoomImg }) => {
  return (
    <PreviewImg>
      <div className='img-wrap'>
        <button className='btn-close' onClick={() => onClickCloseImg()}>
          <img src='/assets/img/mange/icon-close.png' className='icon' />
        </button>
        <img src={zoomImg?.url ? zoomImg?.url : zoomImg} className='zoom-img' />
      </div>
    </PreviewImg>
  );
};

export default PreviewImgView;
