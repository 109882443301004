import { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import PhilosophyEn from './component/PhilosophyEn';
import PhilosophyKo from './component/PhilosophyKo';

const Philosophy = () => {
  const { action } = useContext(LangContext);
  return (
    <>
      <Layout>
        <main id='main' className='fab-page-main --no-padding-top'>
          <div id='main2'>{action.isKo() ? <PhilosophyKo /> : <PhilosophyEn />}</div>
        </main>
      </Layout>
    </>
  );
};

export default Philosophy;
