import ManageModal from '../common/ManageModal';
import ModelEmpty from './ModelEmpty';
import ProductItem from './ProductItem';
import {
  Container,
  DesignerItemListContainer,
  HeaderItem,
  ItemListContainer,
} from './styles/designerItemListStyle';
import { useRecoilState } from 'recoil';
import { managePrevAtom, manageEditIdAtom } from '../../../atom/atom';

import { useState, useEffect } from 'react';

const DesignerItemList = ({
  data,
  setDeletedModel,
  updateClick,
  noFound,
  noUploaded,
  isMobile,
  page,
  params,
}) => {
  const headers = [
    'ID',
    'Model Name',
    'Category',
    'Final Price',
    // 'License Fee',
    'Download',
    'Status',
    isMobile ? null : 'Options',
  ];

  const [, setPrevState] = useRecoilState(managePrevAtom);
  const [, setEditId] = useRecoilState(manageEditIdAtom);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onClickEdit = id => {
    setEditId(Number(id));
    setPrevState({ param: params, scroll: scrollPosition, page: page });
    updateClick();
  };

  return (
    <Container isMobile={isMobile}>
      <DesignerItemListContainer className='DesignerItemListContainer' isMobile={isMobile}>
        {headers?.map(header => (
          <HeaderItem key={header}>{header}</HeaderItem>
        ))}
      </DesignerItemListContainer>
      {data?.length > 0 ? (
        <>
          <ItemListContainer>
            {data?.map(item => (
              <ProductItem
                data={item}
                key={item.id_item}
                setDeletedModel={setDeletedModel}
                updateClick={updateClick}
                isMobile={isMobile}
                onClickEdit={onClickEdit}
              />
            ))}
          </ItemListContainer>
        </>
      ) : (
        //업로드한 모델이 없을 때 noUploadModel(true)
        //검색 결과가 없을 때 noSearchModel(true)
        <ModelEmpty
          noUploadModel={noUploaded}
          onClick={updateClick}
          isMobile={isMobile}
          noSearchModel={noFound}
        />
      )}
    </Container>
  );
};

export default DesignerItemList;
