import styled from 'styled-components';
import DiscountPrice from '../../../component/section/DiscountPrice';
import { freeDownloadItems, isIncluded } from '../../../util/itemData';
import { useEffect, useState } from 'react';
import {
  FreeItemStyle,
  GetThisModel,
  ItemPriceStyle,
  NewTippyStyle,
  Owned,
  RewardTicketStyle,
  SignUpGet,
  TextContainer,
  TicketContainer,
  TicketCountStyle,
} from './ItemPriceStyle';

const ItemPrice = ({
  price,
  freeTicketCount,
  isLogin,
  qty,
  finalPrice,
  discountRate,
  originPrice,
  secretProduct,
  itemNumber,
  value,
  isMobile,
  margin,
  priceOption,
  isOwn,
  downloadStatus,
  buyStatus,
  isDesigner,
}) => {
  const [isFreeItem, setIsFreeItem] = useState(false);
  const isSecret = secretProduct === 'secret';
  const isNavBar = value === 'nav-bar';
  useEffect(() => {
    const freeItem = isIncluded(Number(itemNumber));
    setIsFreeItem(freeItem);
  }, []);

  return (
    <ItemPriceStyle size={isNavBar ? 20 : 32}>
      {/* 할인율이 적용 유무 */}
      {price !== 0 && discountRate === '0' ? (
        <Price
          className='price font-nanumsquare font-nanumsquare-price'
          margin={margin}
          isMobile={isMobile}
        >
          {(buyStatus === 'y' && priceOption === 'paid') || isDesigner
            ? 'Purchased'
            : `$\u00A0${price}`}
        </Price>
      ) : (
        price !== 0 && (
          <DiscountPrice
            finalPrice={finalPrice}
            discountRate={discountRate}
            originPrice={originPrice}
            detailPage={'detailPage'}
            fontSize={isNavBar ? '20px' : '32px'}
            spanFontSize={isNavBar ? '16px' : '20px'}
            margin={margin}
            marginMobile={'0 0 10px 0'}
            buyStatus={buyStatus}
            isDesigner={isDesigner}
          />
        )
      )}
      {priceOption === 'free' && (
        <FreeItemStyle>
          {downloadStatus === 'y' || isDesigner ? <span>Owned</span> : <span>Free</span>}
        </FreeItemStyle>
      )}
      {priceOption === 'reward' && (
        <TextContainer>
          <div style={{ color: '#511AD0' }}>
            {!isLogin && price === 0 && !isSecret && <SignUpGet>Sign up and get it</SignUpGet>}
            {!isLogin && <GetThisModel>You can get this model with reward ticket</GetThisModel>}

            {isLogin &&
              !isFreeItem &&
              priceOption === 'reward' &&
              (buyStatus === 'y' || isDesigner || isOwn ? (
                <Owned>Owned</Owned>
              ) : (
                <RewardTicketStyle>
                  <span> {qty} Reward tickets</span>needed
                </RewardTicketStyle>
              ))}
          </div>
          {isLogin && !isFreeItem && (
            <>
              {isLogin && priceOption === 'reward' && (
                <TicketContainer isOwn={isOwn || isDesigner}>
                  <TicketCountStyle ticket={freeTicketCount - qty < 0}>
                    <span>You have</span>
                    {typeof freeTicketCount === 'number' && typeof qty === 'number' ? (
                      freeTicketCount - qty < 0 ? (
                        <img src='/assets/img/itemDetail/icon-ticket.png' />
                      ) : (
                        <img src='/assets/img/itemDetail/icon-ticket_2.png' />
                      )
                    ) : null}
                    <p>
                      X <span>{freeTicketCount}</span>
                    </p>
                  </TicketCountStyle>

                  {!isFreeItem && (
                    <span className='fab-btn-i--tooltip-f' aria-describedby='tippy-1'>
                      <NewTippyStyle
                        isMobile={isMobile}
                        className='tippy-1'
                        content={[
                          <li>&bull; Reward tickets are coupons for downloading reward model.</li>,
                          <li>
                            &bull; Reward tickets can only be used for reward models, not for paid
                            models.
                          </li>,
                          <li>
                            &bull; The reward models are created as a form of fan art, filled with
                            utmost respect for masterpieces. And we share them for FREE with our
                            customers.
                          </li>,
                          <li>
                            &bull; You can receive a reward ticket immediately after signing up for
                            Fab365.
                          </li>,
                          <li>
                            &bull; You can earn additional reward tickets for each purchased paid
                            model (including models purchased with Fab365 credit).
                          </li>,
                          <li>&bull; The issued reward ticket is valid for 6 months.</li>,
                          <li>
                            &bull; Please note that reward tickets are only available for accounts
                            that have completed email authentication..
                          </li>,
                        ]}
                      >
                        <img src='/assets/img/itemDetail/help.png' alt='' />
                      </NewTippyStyle>
                    </span>
                  )}
                </TicketContainer>
              )}
            </>
          )}
        </TextContainer>
      )}
    </ItemPriceStyle>
  );
};

export default ItemPrice;

const Price = styled.p`
  margin: ${({ margin }) => margin};

  @media (max-width: 640px) {
    margin: 0;
  }
`;
