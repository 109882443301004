import { useEffect, useState } from 'react';

const useClickHandler = () => {
  const [oneClick, setOneClick] = useState(null);
  const [twoClick, setTwoClick] = useState(null);

  const handleClick = index => {
    if (oneClick === index) {
      setOneClick(null); // 같은 박스를 두 번 클릭하면 빨간색 상태 해제
    } else {
      setTwoClick(index);
      setOneClick(index); // 클릭한 박스를 빨간색으로 설정
    }
  };

  useEffect(() => {
    const clickHandler = e => {
      // imgWrap 클래스가 없는 요소를 클릭하면 twoClick을 초기화

      if (!e.target.classList.contains('imgWrap')) {
        setTwoClick(null); // 초기화할 상태로 변경
        setOneClick(null);
      }
    };

    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, []);

  return { oneClick, twoClick, handleClick };
};

export default useClickHandler;
