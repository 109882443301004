import { useNavigate } from 'react-router-dom';
import { ABOUT, ABOUT_CEO } from '../../../route/constant';

const AboutCoCeoEn = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <article className='fab-founder-detail'>
        <div className='--desc'>
          <section className='fab-page-section'>
            <div className='container'>
              <div className='--desc-header --mb-60 --mb-40--m'>
                <h2 className='--name --txt-xxl --mb-5'>
                  <strong>Lee Jong han</strong>
                </h2>
                <p className='--role --font-basic'>co-CEO / Director of Platform Services</p>
              </div>
              <div className='--desc-body --mb-80 --mb-50--m'>
                <p className='--font-basic'>
                  CEO of FAB365 Jong han Lee, who is also a director of platform services, manages
                  and leads global service of our company in order for global users to use high
                  quality service. Jong han Lee contributed to a game company called, Neople who
                  created “Dungeon and Fighter” which was a big hit in Korea and China. After that,
                  he contributed in launching Korea’s very first social commerce, “WeMakePrice”,
                  successfully as a co-founder.
                  <br />
                  <br />
                  As an IT business professional, he is now responsible for business strategy of
                  FAB365. Also, through FAB365, he presents a vision that leads business success for
                  creators by bringing someone’s creative idea to a real life by over passing the
                  time and space.
                </p>
              </div>
              <div className='--desc-footer'>
                <a href='' className='--more' onClick={e => goTo(e, ABOUT)}>
                  <span className='--font-basic'>← Back</span>
                </a>
                <a href='' className='--more' onClick={e => goTo(e, ABOUT_CEO)}>
                  <span className='--font-basic'>co-CEO /Goo Sang kwon →</span>
                </a>
              </div>
            </div>
          </section>
        </div>

        <div
          className='--img'
          style={{
            backgroundImage:
              "url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/founder/ceo2.jpg')",
          }}
        ></div>
      </article>
    </>
  );
};
export default AboutCoCeoEn;
