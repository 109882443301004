import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FOOTER_TERMS } from '../../../../route/constant';

function Korean() {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <div id='main'>
      <div class='container'>
        <div class='terms'>
          <div class='terms-header'>
            <h1 class='terms-title'>크레딧 이용약관</h1>
          </div>
          <div class='terms-body'>
            <div class='term-block'>
              <h3>제1조. 크레딧의 종류</h3>
              <p>
                콘텐츠를 이용하기 위해서는 ‘FAB365’ 내에서 사용할 수 있는 전자적 지급수단으로 이용
                대가를 지불하여야 합니다. ‘FAB365’ 내에서 사용할 수 있는 전자적 지급수단은 유상으로
                판매되는 전자적 지급수단(이하 ‘크레딧’)과, 유상으로 판매되거나 무상으로 지급되면서
                사용기간이 ‘크레딧’보다 짧은 전자적 지급수단(이하 ‘보너스 크레딧’)으로 구분됩니다.
              </p>
            </div>

            <div class='term-block'>
              <h3>제2조. 크레딧의 충전</h3>
              <p>
                (1) 회원은 회사가 정한 대가를 지불하고 ‘크레딧’ 또는 ‘보너스 크레딧’을 취득할 수
                있습니다. 회사는 ‘크레딧’의 구매 방법, 구매 단위, 결제 방법 등을 ‘FAB365’ 내에
                게시합니다.
              </p>
              <p>
                (2) 회사는 다음 각 호의 방법으로 ‘크레딧’ 또는 ‘보너스 크레딧’을 판매할 수 있으며,
                판매 방식은 변경되거나 추가될 수 있습니다.
              </p>
              <p>
                1. 단건 판매 상품 : 회원이 결제하는 금액에 따라 ‘크레딧’ 또는 ‘보너스 크레딧’을
                차등하여 지급하는 상품
                <br />
                2. 한정 판매 상품 : 회원에게 ‘크레딧’ 또는 ‘보너스 크레딧’을 1회 또는 한정된 횟수만
                구매할 수 있도록 하는 상품
                <br />
                3. 정기 결제 상품 : 회원이 1개월마다 회원이 선정한 결제수단을 통해 ‘크레딧’을
                정기적으로 구매할 것을 회사와 사전에 약정하고, 회사는 정기적으로 회원과 약정한 바에
                따라 ‘크레딧’ 또는 ‘보너스 크레딧’을 지급하는 상품
              </p>
              <p>
                (3) 회원이 본 조 제(2)항 제3호에 규정된 정기 결제 상품을 구매하는 경우에는 아래 각
                호의 내용이 우선적으로 적용됩니다.
              </p>
              <p>
                1. 회원이 정기 결제 상품을 구매하는 경우, 해당 상품을 더 이상 이용하지 않겠다는
                철회의 의사표시가 없는 한 자동으로 매 월 정해진 날짜에 정기 결제 상품에 대한 결제가
                진행됩니다.
                <br />
                2. 회사는 회원의 철회의 의사표시가 없는 한 회원이 정기 결제 상품을 구매하기로 한
                때에 회원에게 표시/광고한 조건에 따라 ‘크레딧’ 및 기타 추가 보상을 지급합니다.
                <br />
                3. 회원은 정기 결제 상품을 더 이상 이용하지 않을 경우 회사 또는 정기 결제 상품에
                대한 결제 서비스를 제공하는 제3자에게 정기 결제 상품의 이용 중단에 대한 의사표시를
                하여야 합니다. 회사는 정기 결제 상품의 결제 형태, 방식 별로 이용 중단에 대한
                의사표시를 하여야 하는 사업자를 별도로 표시, 안내합니다.
                <br />
                4. 전 호의 이용 중단에 대한 의사표시는 회사에 대한 ‘크레딧’ 상품 구매의 청약철회의
                의사표시로 간주되지 않습니다. 전 호의 의사표시는 의사표시일 이후 발생할 수 있는 정기
                결제에 대한 중단을 통지하는 효력 밖에는 없으며, 정기 결제로 인하여 지급된 ‘크레딧’의
                청약철회, 환불 등과 관련된 사항은{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS)} style={{ color: '#835fec' }}>
                  [서비스 이용약관 (일반회원)]
                </a>{' '}
                제17조의 규정을 따릅니다.
                <br />
                5. 기타 본 항에서 규정하지 않은 사항에 대해서는 본 약관의 다른 조항에서 정한 바에
                따릅니다.
              </p>
              <p>
                (4) 회원이 구매하거나 지급 받은 ‘크레딧’ 또는 ‘보너스 크레딧’에 대해서는 별도의
                이자수익이 발생하지 않습니다.
              </p>
              <p>
                (5) ‘크레딧’ 또는 ‘보너스 크레딧’ 구입 시 대금지급방법은 다음 각 호의 방법 중 회사가
                제공하는 방법으로 할 수 있습니다.
              </p>
              <p>
                {' '}
                1. 계좌이체
                <br />
                2. 신용카드 결제
                <br />
                3. 전화 또는 휴대전화 결제
                <br />
                4. 상품권 결제
                <br />
                5. 전자화폐에 의한 결제
                <br />
                6. 기타 회사가 제공하는 결제수단
              </p>
              <p>
                (6) ‘크레딧’ 또는 ‘보너스 크레딧’ 구매 시 회원이 구매 대금을 외화로 지급하는
                경우에는 환율의 변동 등으로 인해 실제 회원에게 청구되는 청구금액이 회원이 구매
                시점에 회사에게 지불할 것으로 예상했던 금액과 달라질 수 있습니다.
              </p>
            </div>

            <div class='term-block'>
              <h3>제3조. 보너스 크레딧 등의 지급</h3>
              <p>
                (1) 회사는 이벤트, 캠페인, 프로모션 등의 방법으로 이용자에게 무상으로 ‘보너스
                크레딧’이나 ‘크레딧’, 쿠폰 등(이하 총칭하여 ‘보너스 크레딧 등’이라 합니다)을 지급할
                수 있습니다.
              </p>
              <p>
                (2) 회사가 회원에게 무상으로 지급한 ‘보너스 크레딧 등’은 유효기간 이내에만 사용이
                가능하고, 유효기간 도과 시 소멸합니다. 무상으로 지급되는 ‘보너스 크레딧 등’은 환불이
                불가합니다.
              </p>
              <p>
                (3) 회사는 ‘보너스 크레딧 등’의 사용방법이나 사용범위를 제한할 수 있으며, 구체적인
                내용은 별도의 화면을 통하여 안내합니다.
              </p>
              <p>
                (4) 회사는 ‘보너스 크레딧 등’의 서비스를 종료할 수 있습니다. 이 경우{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS)} style={{ color: '#835fec' }}>
                  [서비스 이용약관 (일반회원)]
                </a>{' '}
                제11조 제(4)항의 규정을 준용하며, 사전에 고지된 서비스의 종료일까지 사용되지 않은
                ‘보너스 크레딧 등’은 소멸합니다.
              </p>
            </div>

            <div class='term-block'>
              <h3>제4조. 크레딧 및 보너스 크레딧 등에 대한 주의사항</h3>
              <p>
                (1) 회사가 제공하는 ‘크레딧’, ‘보너스 크레딧 등’은 회사의 서비스 이용 외에 현금이나
                재물, 기타 경제적 가치를 내포하는 것과 교환할 수 없습니다. ‘크레딧’, ‘보너스 크레딧
                등’은 계정 단위로 보유하고 이용할 수 있으며, 이용자가 다수의 계정을 보유하고
                있더라도 각 계정 간 ‘크레딧’, ‘보너스 크레딧 등’의 연결 또는 합산은 불가합니다.
              </p>
              <p>
                (2) ‘크레딧’ 및 ‘보너스 크레딧’은 원칙적으로 무상으로 지급 받은 ‘보너스 크레딧’,
                유상으로 구매한 ‘보너스 크레딧’, 유상으로 구매한 ‘크레딧’의 순으로 사용되며, 동종의
                ‘보너스 크레딧’ 또는 ‘크레딧’의 경우 유효기간의 만료일이 먼저 도래하는 ‘보너스
                크레딧’ 또는 ‘크레딧’부터 사용됩니다.
              </p>
            </div>

            <div class='term-block'>
              <h3>제5조. 크레딧의 청약철회 등</h3>
              <p>(1) ‘크레딧’ 구매에 대한 청약철회에 대해서는 본 항 각 호의 내용이 적용됩니다.</p>
              <p>
                1. 회원은 ‘크레딧’에 대한 구매계약을 체결한 날 또는 구매계약에 따라 ‘크레딧’이
                회원의 아이디로 지급된 날로부터 7일 이내에는 청약철회를 할 수 있습니다.
                <br />
                2. 회원은 다음 각 목의 어느 하나에 해당하는 경우에는 회사의 의사에 반하여 ‘크레딧’에
                대한 청약철회를 할 수 없습니다.
                <br />
                &nbsp; &nbsp; &nbsp;① 회원이 ‘크레딧’을 일부 사용한 경우
                <br />
                &nbsp; &nbsp; &nbsp;② 그 밖에 거래의 안전을 위하여 법령으로 정하는 경우
                <br />
                3. 청약철회를 할 수 있는 경우에 해당하는 경우, 회사는 거래 취소 등 결제한 수단과
                같은 수단으로 결제금액을 환급하는 것을 원칙으로 합니다. 단, 거래 취소를 할 수 없거나
                결제한 수단과 같은 수단으로 결제금액을 환급할 수 없는 경우에는 현금으로 환급할 수
                있습니다.
              </p>
              <p>
                (2) 본 조의 규정이 적용되지 않는 ‘크레딧’의 환불에 대한 사항은 제6조의 규정에
                따릅니다.
              </p>
            </div>

            <div class='term-block'>
              <h3>제6조. 크레딧의 환불</h3>
              <p>
                (1) ‘크레딧’의 사용 유효기간은 ‘크레딧’의 구매일로부터 5년이 경과되는 시점까지이며,
                ‘보너스 크레딧’의 유효기간은 회원이 ‘보너스 크레딧’의 구매 전에 쉽게 확인할 수
                있도록 개별적으로 표시/고지되는 바에 따릅니다. ‘크레딧’ 및 ‘보너스 크레딧’의 사용
                유효기간이 도과되는 경우 ‘크레딧’ 및 ‘보너스 크레딧’은 환불되지 않습니다.
              </p>
              <p>
                (2) 회사는 전항(제5조 포함)에 따른 유효기간이 경과하기 전에 회원이 구매한 ‘크레딧’
                중 미 사용한 ‘크레딧’에 대한 환불을 요청하는 경우, 해당 ‘크레딧’의 구매 당시 단가로
                환불합니다. 단, 회사는 ‘크레딧’ 상품의 할인 등을 고려하여 환불정책을 별도로 마련할
                수 있으며, 해당 별도의 환불정책은 회원이 해당 ‘크레딧’ 상품을 구매하기 전에 쉽게
                확인할 수 있도록 안내 및 표시됩니다.
              </p>
              <p>
                (3) 환불 시에는 환불금액의 10% 또는 1,000원 중 큰 금액(환불수수료)을 공제한 후
                환불합니다. 따라서 환불수수료를 공제하기 전 금액이 1,000원 미만인 경우에는 환불이
                불가능합니다.
              </p>
              <p>
                (4) 회원이 결제정보 도용 등 법령에서 금지하는 행위를 통하여 ‘크레딧’ 또는 ‘보너스
                크레딧’을 충전하고 ‘크레딧’의 환급을 요구하는 경우, 회사는 ‘크레딧’의 환불을 거절할
                수 있습니다.
              </p>
              <p>
                (5) 환불과 관련하여 회원이 환불정보를 정확하게 입력하지 아니하여 발생한 손해에
                대하여 회사는 별도의 책임을 부담하지 않습니다.
              </p>
              <p>
                (6) 현행 법령 및 중대한 약관 위반 등 회원의 귀책사유로 인하여 계약이 해지되는 경우
                환불이 제한될 수 있습니다.
              </p>
            </div>

            <div class='term-block'>
              <h3>부칙</h3>
              <p>(1) 이 약관은 2024년 2월 23일부터 시행합니다.</p>
              <p>(2) 2019년 5월 30일부터 시행되던 종전의 포인트 약관은 본 약관으로 대체합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Korean;
