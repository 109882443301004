import { Helmet } from 'react-helmet-async';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import { FOOTER_HELP, MY_PROFILE } from '../../route/constant';
import { useNavigate } from 'react-router-dom';

const ImportProfile = ({ tab, title }) => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu tab={tab} title={title} />
          <section className='about-section'>
            <div className='help'>
              <div className='container'>
                <div className='about-description-block'>
                  <div className='about-description-block-header'>
                    <h2>Help center</h2>
                    <p>How to import fab365 cura profile?</p>
                  </div>
                </div>

                <div className='help-block'>
                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>
                        In this help, example fab365 item is
                        <a target='_new'>Foldable Unibilt sleeper-Add on for Peterbilt 379</a>
                        .And example 3d printer is Ender3.
                      </p>
                      <p>
                        You need to download the fab365 cura slicer profile file on{' '}
                        <a href={MY_PROFILE} target='_new'>
                          your profile page
                        </a>
                        .
                      </p>
                      <p>
                        If you don't know how to download slicer settings, please read this.
                        <a
                          href=''
                          onClick={e => goTo(e, FOOTER_HELP + '/43/how-to-download-slicer-setting')}
                        >
                          how-to-download-slicer-setting
                        </a>
                      </p>

                      <p>
                        If you double click the "Fab365_Foldable_Wheel-Cover.stl" file, Cura will be
                        run.
                      </p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-01.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>
                        Let's start importing the fab365 cura profile. Click the "Custom" button.
                      </p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-04.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>
                        You can see detail printing settings. Click the profile "Super Quality".
                        (can differently view depends on your previous setting)
                      </p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-05.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>Click the lower menu "Manage Profiles..".</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-06.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>You can see the "Profiles" window. Click the import button.</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-07.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>Select the fab365 cura profile you downloaded at first.</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-08.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>If you select the fab365 cura profile file, import succeed. Click "OK".</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-09.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>
                        You can see the fab365 Ender3 profile on "Custom profile". Click "Close".
                      </p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-10.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>Select fab365 Ender3 profile on "Profile".</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-11.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>You can see the fab365 Ender3 profile is applied. Click "Slice".</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-12.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>Slice is completed. You can now save gcode to file and start print.</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-import-fab365-cura-profile/how-to-import-cura-profile-13.png'
                    className='help-img'
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p> </p>
                    </div>
                  </div>

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>
                        We apologise for any inconvenience caused. We will fix inconveniences as
                        soon as possible.
                        <br />
                        If you have any other problems, please let us know.
                        <a href='mailto:help@fab365.net'>help@fab365.net</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default ImportProfile;
