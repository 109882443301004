import { useContext, useState } from 'react';
import ModalContext from '../context/modal/modal';

const useDownload = () => {
  const token = localStorage.getItem('accessToken');
  const isLogin = token ? true : false;
  const { action } = useContext(ModalContext);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [selectedModal, setSelectedModal] = useState(false);

  const onClickDownload = (e, id, name) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!isLogin) {
      action.setIsLogin(true);
      return;
    }
    setSelectedId(id);
    setSelectedName(name);
    setSelectedModal(true);
  };

  return {
    selectedId,
    selectedName,
    selectedModal,
    setSelectedModal,
    onClickDownload,
    setSelectedId,
  };
};

export default useDownload;
