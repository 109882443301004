import moment from 'moment';
import { DEFAULT_AVATAR } from '../../../component/avatar/const';
import { ImgStyled } from '../../../styles/ImgStyled';
const ReviewHeader = ({ data }) => {
  return (
    <>
      <div className='story-detail-header'>
        <h2 className='story-title'>{data.subject}</h2>
        <span className='story-date'>{moment(data.date).format('YYYY-MM-DD')}</span>
        <div className='author'>
          <div className='profile'>
            <div className='profile-pic'>
              <ImgStyled src={data.user_thumbnail ? data.user_thumbnail : DEFAULT_AVATAR} alt='' />
            </div>
            <span className='profile-name'>&nbsp;{data.user_name}</span>
          </div>
          <div className='post-stat'>
            <span className='hearted'></span>
            <span className='comment'></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewHeader;
