import React, { useState } from 'react';
import { PaymentContainer, PaypalContainer, StripeContainer } from '../styles';
import Paypal from './Paypal';
import { loadStripe } from '@stripe/stripe-js';
import { PAYPAL_CLIENT_ID, STRIPE_PUBLISHABLE_API_KEY } from './constant';
import Stripe from './Stripe';
import { Elements } from '@stripe/react-stripe-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_API_KEY);
function Payment({
  checkReadyForPay,
  chargePoint,
  onRefresh,
  setIsLoading,
  setIsProcessing,
  isLoading,
}) {
  const [isShowStripe, setIsShowStripe] = useState(false);
  const [isShowPaypal, setIsShowPaypal] = useState(false);

  const handleShowForm = type => {
    if (type === 'stripe') {
      setIsShowStripe(true);
      setIsShowPaypal(false);
    } else {
      setIsShowStripe(false);
      setIsShowPaypal(true);
    }
  };

  return (
    <PaymentContainer>
      <StripeContainer>
        <div className='stripe-inner-container'>
          <label onChange={() => handleShowForm('stripe')}>
            <input type='radio' name='payment-option' value='stripe' /> Pay with Card
            <img
              src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/icon/icon_card.png'
              alt='stripe'
              className='icon-pay'
            />
          </label>
          {isShowStripe && (
            <div>
              <Elements stripe={stripePromise}>
                <Stripe
                  checkReadyForPay={checkReadyForPay}
                  chargePoint={chargePoint}
                  onRefresh={onRefresh}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              </Elements>
            </div>
          )}
        </div>
      </StripeContainer>
      <PaypalContainer>
        <div className='point-charge-item-inner'>
          <label onChange={() => handleShowForm('paypal')}>
            <input type='radio' name='payment-option' value='paypal' /> Pay with PayPal
            <img
              src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/icon/icon_paypal.png'
              className='icon-pay'
              alt='paypal'
            />
          </label>
          <div style={{ display: isShowPaypal ? 'block' : 'none' }}>
            <PayPalScriptProvider options={{ 'client-id': PAYPAL_CLIENT_ID }}>
              <Paypal
                checkReadyForPay={checkReadyForPay}
                onRefresh={onRefresh}
                setIsLoading={setIsLoading}
                setIsProcessing={setIsProcessing}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </PaypalContainer>
    </PaymentContainer>
  );
}

export default Payment;
