import { useContext, useEffect, useState } from 'react';
import { PRINTER_PROFILE_ERROR_NEED_PAID_ORDER } from '../../../../context/lang/constant';
import LangContext from '../../../../context/lang/lang';
import { downloadPrinter, getPrinters, getSlicers } from '../../../../action/request';

const PrinterSettingDownload = ({ slicer, printer, downloadable }) => {
  const { action } = useContext(LangContext);
  const tryDownloadPrinter = async () => {
    try {
      const { data: res } = await downloadPrinter({
        action: 'userPrinter-save-all',
        params: {
          printer: printer,
          slicer: slicer,
        },
      });
      if (res) {
        const url = res.data.url;
        const part = url.split('/');
        const lastPart = part[part.length - 1];

        const a = document.createElement('a');
        a.href = url;
        a.download = lastPart.split('?')[0];
        document.body.appendChild(a);
        a.click();
      }
    } catch (e) {
      alert(e);
    }
  };

  if (printer === 0 || slicer === 0) {
    return (
      <button type='button' className='btn btn-fill btn-download'>
        Your settings not ready
      </button>
    );
  }

  if (downloadable === 'download') {
    return (
      <button
        type='button'
        className='btn btn-fill btn-download'
        data-action='download-printer-profile'
        onClick={tryDownloadPrinter}
      >
        Download slice settings
      </button>
    );
  }

  if (downloadable === 'service.printerProfile.error.needPaidOrder') {
    return (
      <button
        type='button'
        className='btn btn-fill btn-download'
        data-action='download-printer-profile-need-paid-order'
        data-message={action.trans(PRINTER_PROFILE_ERROR_NEED_PAID_ORDER)}
        onClick={tryDownloadPrinter}
      >
        Download slice settings
      </button>
    );
  }

  if (downloadable === 'service.printerProfile.error.settingNotReady')
    return (
      <button type='button' className='btn btn-fill btn-download'>
        Your settings not ready
      </button>
    );

  return (
    <button type='button' className='btn btn-fill btn-download'>
      Your settings not ready
    </button>
  );
};
export default PrinterSettingDownload;
