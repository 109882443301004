/* eslint-disable jsx-a11y/anchor-is-valid */
import { sanitize } from 'dompurify';
import { DEFAULT_AVATAR } from '../../../component/avatar/const';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { formatHrefLink, isEditable, requestImageHtml } from '../../../util/comment';
import { uploadItemImage, editRequestComment, deleteRequestComment } from '../../../action/request';
import { preventScroll } from '../../../util/commentImageViewer';
import { ImgStyled } from '../../../styles/ImgStyled';

const RequestCommentReply = ({
  onEdit,
  replys,
  idUser,
  onReload,
  isLast,
  setCommentReply,
  showReply,
  setShowImgViewer,
  setImgData,
}) => {
  const date = moment(replys.date).format('YYYY-MM-DD');
  const [isEdit, setIsEdit] = useState(false);
  const [editBody, setEditBody] = useState(replys['body']);
  const [medias, setMedias] = useState(replys.medias);
  const [loading, setLoading] = useState(false);
  const imageInput = useRef(null);
  const lastRef = useRef();
  const user = replys['user'];

  useEffect(() => {
    if (isLast) {
      setCommentReply(lastRef);
    }
  }, []);

  useEffect(() => {
    setInit();
    // console.log(replys);
  }, [replys]);

  const setInit = () => {
    setIsEdit(false);
    setEditBody(replys['body']);
    setMedias(replys.medias);
    setLoading(false);
  };

  const editComment = async e => {
    e.preventDefault();
    e.stopPropagation();
    // 아무 내용 없다면 알림
    if (!editBody || editBody === '') {
      return alert('Comment is empty');
    }
    onEdit({
      comment_id: replys.comment_id,
      msg: editBody,
      medias: medias,
    });
    setIsEdit(false);
  };

  const onDeleteComment = async () => {
    if (window.confirm('Delete?')) {
      try {
        await deleteRequestComment(replys.comment_id);
        // console.log(replys.comment_id);
        onReload();
      } catch (e) {}
    }
  };

  const onDeleteImage = idx => {
    const newMedias = medias.filter((_, i) => i !== idx);
    setMedias(newMedias);
  };

  const imageChanged = async e => {
    try {
      const files = e.target.files;
      const newMedias = [...medias];
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadItemImage({
          file: files[i],
        });
        newMedias.push(res.img);
      }
      setMedias(newMedias);
      setLoading(false);
    } catch (e) {
      alert('request failed');
    }
  };

  const onClickImg = (imgArr, idx) => {
    // console.log(imgArr, idx);
    setShowImgViewer(true);
    setImgData({ index: idx, data: imgArr });
    preventScroll();
  };

  return (
    <>
      <div
        key={replys.oid_comment}
        className='comment-item comment-item-re'
        //data-comment={sanitize(JSON.stringify(forData))}
      >
        {isEdit && (
          <div className='comment-write'>
            <div className='profile'>
              <div className='profile-pic'>
                <img src={user.thumbnail ? user.thumbnail : DEFAULT_AVATAR} alt='' />
              </div>
            </div>
            <form className='comment-write-form' data-action='1103-comment-reply-submit'>
              <div className='write-form'>
                <textarea
                  placeholder='Please input your text'
                  name='msg'
                  value={editBody}
                  onChange={e => setEditBody(e.target.value)}
                ></textarea>
                <div className='attachments'>
                  <ul className='files'>
                    {medias?.map((media, idx) => {
                      return (
                        <li key={idx + media.url}>
                          <div className='file-item'>
                            <a>
                              <ImgStyled src={media.thumbs[0].url} alt='' />
                            </a>
                            <button
                              type='button'
                              className='btn-delete'
                              onClick={() => onDeleteImage(idx)}
                            >
                              ×
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className='form-action'>
                  <div className='file-uploader'>
                    <input
                      type='file'
                      accept='image/*'
                      multiple
                      onChange={imageChanged}
                      ref={imageInput}
                    />
                    <button
                      type='button'
                      className={'btn-upload-file loading_color' + (loading && 'loading')}
                      onClick={() => imageInput.current.click()}
                    >
                      Edit Image
                    </button>
                  </div>
                  <button type='submit' className='btn btn-green btn-fill' onClick={editComment}>
                    Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        {!isEdit && (
          <>
            <div className='comment-item-header'>
              <div className='profile'>
                <div className='profile-pic'>
                  <img
                    src={
                      replys.status === 'active'
                        ? user.thumbnail
                          ? user.thumbnail
                          : DEFAULT_AVATAR
                        : DEFAULT_AVATAR
                    }
                    alt=''
                  />
                </div>
                <div className='profile-name'>{replys.status === 'active' ? user.name : ''}</div>
                <div className='comment-date'>{replys.status === 'active' ? date : ''}</div>
              </div>
              {isEditable(replys, idUser) && replys.status === 'active' && (
                <div className='util-btns'>
                  <button
                    className='btn-edit'
                    data-action='1100-comment-edit'
                    onClick={() => setIsEdit(true)}
                  >
                    Edit
                  </button>
                  <button
                    className='btn-delete'
                    data-action='1104-comment-delete'
                    onClick={() => onDeleteComment()}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <div className='comment-item-body'>
              <div className='comment-content'>
                {/* <p
                  dangerouslySetInnerHTML={
                    replys.status === 'active'
                      ? {
                          __html: sanitize(editBody.replaceAll('\n', '<br/>')),
                        }
                      : { __html: 'Deleted' }
                  }
                ></p> */}
                <p dangerouslySetInnerHTML={formatHrefLink(replys.body)}></p>
              </div>
              <div className='attachments' data-image-gallery='true'>
                {replys.status === 'active'
                  ? medias?.map((media, idx) => {
                      return (
                        <a
                          // href={media.url}
                          onClick={() => onClickImg(medias, idx)}
                          data-tb={media.type}
                          key={idx}
                        >
                          <ImgStyled src={media.thumbs[0].url} alt='' />
                        </a>
                      );
                    })
                  : ''}
              </div>
            </div>
            {isLast && (
              <div className='comment-item-footer' ref={lastRef}>
                <a className='btn btn-xs' data-action='1102-comment-reply' onClick={showReply}>
                  Reply
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RequestCommentReply;
