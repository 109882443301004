import { useState, useEffect } from 'react';
import Layout from '../../../component/layout/Layout';
import { fileSize } from '../../../util/file';
import Pagination from '../../Category/component/Pagination';
import { TAB_DOWNLOAD } from '../component/constant';
import MyTab from '../component/MyTab';
import { getDownloads } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import DownloadProcessingModal from '../../../component/modal/DownloadProcessingModal';
import styled, { css } from 'styled-components';
import { ImgStyled } from '../../../styles/ImgStyled';
import { ITEMS } from '../../../route/constant';
import Selectefile from '../../../component/selectefile/Selectefile';

const Download = () => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = useState(8);
  const [total, setTotal] = useState(0);
  const [downloadModalStep, setDownloadModalStep] = useState(0);
  const [itemId, setItemId] = useState(0);
  const [selectedModal, setSelectedModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedName, setSelectedName] = useState();
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const pageChange = val => {
    setPage(val);
  };
  const pageConfirm = val => {
    setPage(val);
  };

  const goToItemDetailPage = (e, productId) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(ITEMS + '/' + productId);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    try {
      const { data: res } = await getDownloads({ page });
      const downloads = res.data;
      setItems(downloads.itemDtos);
      setTotal(parseInt(downloads.count));
      setPageSize(downloads.paging.size);
    } catch (e) {}
  };

  const clickDownload = async id => {
    setSelectedName(id?.name_en);
    setItemId(id?.id_item);
    setSelectedId(id?.id_item);
    setSelectedModal(true);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  return (
    <>
      <PageMobileStyle id='main' step={downloadModalStep}>
        {selectedId && selectedModal && (
          <Selectefile
            setSelectedModal={setSelectedModal}
            id={selectedId}
            setSelectedId={setSelectedId}
            selectedName={selectedName}
          />
        )}

        <Layout>
          <div id='main'>
            <div className='page-top mypage'>
              <div className='container'>
                <h1 className='page-title align-left'>My Page</h1>
              </div>
            </div>
            <MyTab tab={TAB_DOWNLOAD} />
            <section className='mypage-section'>
              <div className='container'>
                <div className='content-box orders'>
                  <div className='content-box-header'>
                    <h2>
                      Download <small className='badge'>{total}</small>
                    </h2>
                  </div>
                  <div className='content-box-body'>
                    <div className='purchase-product-list'>
                      <ul className='clearfix'>
                        {items?.map(d => {
                          const size = fileSize(d.all_file_size);
                          return (
                            <li key={d.id_item}>
                              <div className='purchase-product-list-item'>
                                <div className='purchase-product-list-item-inner'>
                                  <div
                                    className='product-click-pointer'
                                    onClick={e => goToItemDetailPage(e, d.id_item)}
                                  >
                                    <ImgStyled
                                      src={d.list_img}
                                      className='tb '
                                      alt=''
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                  <div className='product-meta'>
                                    <span
                                      className='product-name  product-click'
                                      onClick={e => goToItemDetailPage(e, d.id_item)}
                                    >
                                      {d.name_en}
                                    </span>
                                    <span className='file-size'>
                                      {size.size.toFixed(1) + '' + size.unit}
                                    </span>
                                    <button
                                      onClick={() => clickDownload(d)}
                                      className='btn btn-sm btn-download'
                                      download
                                    >
                                      Download
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Pagination
              page={page}
              onconfirm={pageConfirm}
              onpagechange={pageChange}
              total={Math.ceil(total / pageSize)}
            />
          </div>
        </Layout>
      </PageMobileStyle>
      {/* 다운로드 모달 */}
      {downloadModalStep > 0 && (
        <DownloadProcessingModal
          itemId={itemId}
          step={downloadModalStep}
          setStep={setDownloadModalStep}
        />
      )}
    </>
  );
};

export default Download;

const PageMobileStyle = styled.div`
  ${props =>
    props.step !== 0 &&
    css`
      height: 100vh;
      overflow: hidden;
    `}
  /* STL다운로드 모달 640px 이하에서 띄울 시 스크롤바 없애기 */
   @media screen and (max-width: 640px) {
    ${props =>
      props.step !== 0 &&
      css`
        display: none;
      `}
  }
`;
