import styled from 'styled-components';

const DiscountPrice = ({
  finalPrice,
  discountRate,
  originPrice,
  detailPage,
  cartProduct,
  fontSize,
  spanFontSize,
  margin,
  marginMobile,
  buyStatus,
  isDesigner,
}) => {
  return (
    <DiscountWrapper
      detailPage={detailPage}
      cartProduct={cartProduct}
      fontSize={fontSize}
      spanFontSize={spanFontSize}
      margin={margin}
      marginMobile={marginMobile}
    >
      {buyStatus === 'y' || isDesigner ? (
        <p>Purchased</p>
      ) : (
        <>
          {finalPrice !== '0.00' && <p> {cartProduct ? `${finalPrice}` : `$ ${finalPrice}`}</p>}
          {discountRate !== '0' && (
            <>
              <span>{`$${originPrice}`}</span>
              <div>{`${discountRate}%`}</div>
            </>
          )}
        </>
      )}
    </DiscountWrapper>
  );
};

export default DiscountPrice;

const DiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: ${props => props.cartProduct && 'flex-end'};
  flex-direction: ${props => (props.cartProduct ? 'row-reverse' : '')};
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  margin: ${props => props.margin};

  p {
    font-size: ${props => props.fontSize};
  }

  span,
  div {
    font-size: 16px;
  }
  span {
    text-decoration: line-through;
    color: #c4c4c4;
    font-weight: 300;
  }

  div {
    color: #00c02a;
  }

  @media screen and (max-width: 480px) {
    gap: 5px;
  }

  @media (max-width: 640px) {
    margin: ${props => props.marginMobile};
  }
`;
