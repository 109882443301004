import styled from 'styled-components';

export const AdditionalInfoContainer = styled.div`
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 35px;
`;

export const TitleBox = styled.div`
  margin-bottom: 25px;
  .title {
    font-size: 30px;
    font-weight: 500;
  }
  p {
    font-size: 16px;
  }
  span {
    font-size: 12px;
    color: #9a9a9a;
  }
`;

export const TagItem = styled.span`
  display: inline-block;
  margin-right: 5px;
  background-color: ${({ isValid }) => (isValid ? '#4300F8' : '#fff')};
  border-radius: 10px;
  color: ${({ isValid }) => (isValid ? '#fff' : '#FD5757')};
  padding: 5px 20px;

  border: ${({ isValid }) => (isValid ? '1px solid #4300F8' : '1px solid #FD5757')};

  button {
    color: ${({ isValid }) => (isValid ? '#fff' : '#FD5757')} !important;
    font-weight: 500;
    margin-left: 10px;
  }
`;

export const TitleWarper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  p {
    margin: 0 !important;
    padding: 0 !important;
    color: #ff0000;

    font-size: 14px;
    font-weight: 400;
  }
`;

export const HiddenTextSpan = styled.span`
  font-size: 16px !important;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const TagInput = styled.input`
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  border: none;
  min-height: 28px;

  &::placeholder {
    font-size: 12px;
    color: #9a9a9a;
  }
`;

export const InputWrapper = styled.div``;

export const TagBox = styled.div`
  margin-bottom: 15px;
  .tag-input-container {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #000;
    padding: 10px 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

export const WeightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
`;
