import { useState } from 'react';
import ItemRecommand from '../../component/item/ItemRecommand';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import { PRINT_TIPS_LIST } from '../../route/constant';
import { useNavigate } from 'react-router-dom';

const MovingPartsFall = ({ tab, title }) => {
  const [isOne, setIsOne] = useState(false);
  const [isTwo, setIsTwo] = useState(false);
  const imageBase = 'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/pages/14013382173-tips/';

  const navigate = useNavigate();

  const toggle = (bool, setter) => e => {
    e.stopPropagation();
    e.preventDefault();
    setter(!bool);
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu tab={tab} title={title} />
          <div className='container fab-accordion-mt-s1 fab-accordion-mb-s1'>
            <div className='fab-accordion __w1340'>
              <div className='fab-accordion-header'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
                <h2 className='fab-accordion-header--title'>
                  <small>Problem 2</small>
                  <br />
                  Failure to print the moving part and falling apart
                </h2>
                <div className='fab-accordion-header--description'>
                  <p>
                    Interior of folding parts from Fab365 has a very complex structure. The surface
                    of these moving parts which touches the first layer of the bed usually is
                    smaller than it should be. The surface that touches the bed should be wide
                    enough so the parts do not fall apart. If the surface that touches the bed's not
                    big enough, it will fall apart from the bed while printing. Therefore, you have
                    to be very cautious on adhesion of the bed.
                  </p>
                  <img src={imageBase + 'tip-10.jpg'} alt='' style={{ width: '100%' }} />
                  <p>
                    When the leg falls apart due to weak bed/plate adhesion while printing. (See the
                    picture of example below)
                  </p>
                  <img src={imageBase + 'tip-11.jpg'} alt='' style={{ width: '100%' }} />
                </div>
              </div>
              <div className='fab-accordion-body'>
                <div className='fab-accordion-list'>
                  <ul>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isOne ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isOne, setIsOne)}
                          className='fab-accordion-item-header'
                        >
                          {' '}
                          <h4 className='fab-accordion-item--title'>
                            <small>Solution 01</small>
                            <br />
                            Use Raft
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            There are some people who really hate to use Raft, but we often use Raft
                            alot for more stabilized printing. With the design of Fab365, which the
                            most part of the first layer is hidden in the inner part, you won’t be
                            able to see any leftover marks of removing Raft. Also, if Raft is
                            sticked to the model and is not easily removable, you can make the air
                            gap of Raft wider to separate them well. Please use Raft with
                            confidence.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isTwo ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isTwo, setIsTwo)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Solution 02</small>
                            <br />
                            Replace the Bed/Plate with strong adhesion.
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            There are many good Bed/Plate with strong adhesion recently. PEI bed
                            from “Prusa”, PEX bed from “Whambam Systems” are one of the best
                            Bed/Plates we would like to recommend.{' '}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='fab-accordion-footer'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
              </div>
            </div>
          </div>

          <section>
            <ItemRecommand itemId={null} />
          </section>
        </div>
      </Layout>
    </>
  );
};

export default MovingPartsFall;
