export const getPercent = (data) => {
  return Math.min(Math.floor(data), 100);
};

export const numberFormat = (num) => {
  const strNum = num.toString().split(".");
  const strInt = strNum[0]
  let result = "";
  for (let i = 1; i < strInt.length + 1; i++) {
    if (i % 3 === 1 && i > 1) {
      result = strInt[strInt.length - i] + "," + result;
    } else {
      result = strInt[strInt.length - i] + result;
    }
  }

  result += strNum[1] ? "."+strNum[1] : "";
  return result;
};

export const round = (num)=>{
    return Math.round(num * 100) / 100;
}