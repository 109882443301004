import styled from 'styled-components';

export const ProcessingModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: auto;

  h1 {
    font-family: 'Outfit', sans-serif !important;
    font-size: 30px;
    font-weight: 600;
    line-height: 37.8px;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid #9a9a9a;
    height: 45px;
  }

  h2 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 32px;
    font-weight: 400;
    line-height: 39.01px;
    text-align: center;
  }

  @media screen and (max-width: 700px) {
    h1 {
      font-size: 20px;
      padding-bottom: 10px;
    }
    h2 {
      font-size: 20px;
    }
  }
`;

export const ModalInner = styled.div`
  background-color: #fff;
  position: absolute;
  top: ${props => (props.isMobile ? '58%' : '50%')};
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 560px;
  padding: 50px 30px;
  border-radius: 10px;
  box-sizing: border-box;

  @media screen and (max-width: 700px) {
    width: 90% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
  }

  @media screen and (max-width: 382px) {
    width: 90% !important;
    top: 20px;
    left: 20px;
    transform: translate(0%, 0%);
  }

  .processing-container {
    width: 100%;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .processing_icon {
      width: 31px;
      height: 37px;
      margin-right: 15px;
    }

    span {
      font-size: 32px;
      font-weight: 600;
      color: #9a9a9a;
      font-family: 'Inter' !important;
      margin-right: 3px;
    }

    p {
      font-size: 32px;
      font-weight: 600;
      color: #000;
      font-family: 'Inter' !important;
    }
  }
`;

export const UlStyle = styled.ul`
  width: 100%;
  font-family: 'Outfit', sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  padding: 50px 0 !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BarBox = styled.div``;

export const Percent = styled.div`
  font-family: 'Outfit', sans-serif !important;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  color: #4200f6;
  padding-top: 9px;
`;

export const Description = styled.div`
  font-family: 'Pretendard', sans-serif !;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: left;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

export const IagreeButton = styled.button`
  font-family: 'Outfit', sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 25.2px !important;
  text-align: center !important;
  background: #46b81e !important;
  width: 300px;
  margin: 0 auto;
  padding: 11px 0 12px 0 !important;
  border-radius: 10px !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;

  &:disabled {
    background: #dfdfdf !important;
    cursor: not-allowed !important;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;

  @media screen and (max-width: 700px) {
    gap: 3px;
  }
`;

export const IconStyle = styled.img`
  pointer-events: none;
  cursor: pointer;

  width: 36px;
  height: 36px;

  @media screen and (max-width: 700px) {
    width: 20px;
    height: 20px;
  }
`;

export const CloseBox = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 26px;
  height: 26px;
  padding: 10px !important;
  margin: 0 !important;
  background: none !important;
  border: none !important;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;
