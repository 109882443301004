import { useEffect, useState } from 'react';
import Layout from '../../../component/layout/Layout';
import Pagination from '../../Category/component/Pagination';
import { TAB_CREDIT } from '../component/constant';
import MyTab from '../component/MyTab';
import Records from './component/Record';
import './Point.css';
import Charge from './component/Charge';
import { getPoints } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import { MY_CREDIT } from '../../../route/constant';

const Point = () => {
  const [page, setPage] = useState(1);
  const [points, setPoints] = useState(0);
  const [records, setRecords] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const getPointsAndHistory = async () => {
    try {
      const { data: res } = await getPoints(page);
      setPoints(
        !res.data.credit || res.data.credit === 'null'
          ? 0
          : Number(res.data.credit).toLocaleString()
      );
      setRecords(res.data.records);
      setTotalPage(res.data.count);
    } catch (e) {}
  };

  useEffect(() => {
    getPointsAndHistory();
  }, [page]);

  const pageChange = value => {
    setPage(value);
  };

  const pageConfirm = value => {
    setPage(value);
  };

  const onRefresh = () => {
    window.location.href = MY_CREDIT;
  };

  useEffect(() => {
    localStorage.removeItem(ls.chargePoint);
    localStorage.removeItem(ls.totalAmount);

    return () => {
      localStorage.removeItem(ls.chargePoint);
    };
  }, []);

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  return (
    <Layout>
      <div id='main' className='point-page'>
        <div className='page-top mypage'>
          <div className='container'>
            <h1 className='page-title align-left'>My Page</h1>
          </div>
        </div>

        <MyTab tab={TAB_CREDIT} />

        <section className='mypage-section'>
          <div className='container'>
            <div className='content-box orders'>
              <div className='content-box-header'>
                <h2>Credit</h2>
              </div>
              <div className='content-box-body'>
                <div className='point-info-box'>
                  <div className='your-point'>
                    <dl>
                      <dt>Your Credit</dt>
                      <dd>
                        <strong className='my-point'>
                          <i className='icon-cash-blue'></i>
                          {points}
                        </strong>
                      </dd>
                    </dl>
                  </div>
                </div>

                <Charge onRefresh={onRefresh} />

                <div className='point-history' style={{ marginTop: '1rem' }}>
                  <h3>Credit History</h3>
                  <div className='table-container'>
                    <Records data={records} />
                  </div>
                  <Pagination
                    page={page}
                    total={Math.ceil(totalPage / 20)}
                    onconfirm={pageConfirm}
                    onpagechange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Point;
