import {
  STATUS_PRODUCTION_ENUM,
  STATUS_RELEASED_ENUM,
} from "../../../component/request/constant";

const RequestIcon = ({ status }) => {
  if (status === STATUS_PRODUCTION_ENUM) {
    <i className="request-badge request-badge-picked"></i>;
  }
  if (status === STATUS_RELEASED_ENUM)
    return <i className="request-badge request-badge-released"></i>;

  return "";
};

export default RequestIcon;
