export const emptyCard = {
  designer_img: null,
  exclusive: null,
  finalPrice: null,
  isBuyable: null,
  isDiscount: null,
  originPrice: null,
  product_comment_count: null,
  product_id: null,
  product_like_count: null,
  product_listImg: null,
  product_listImg2: null,
  product_name: null,
  product_shortDesc: null,
  ranking: null,
  review_count: null,
  reward: null,
  reward_qty: null,
  studio_name: null,
  in_cart: null,
  my_like: null,
};

export const emptyReviewCard = {
  body: null,
  comment_count: null,
  date_create: null,
  finalPrice: null,
  hide_yn: null,
  isBuyable: null,
  isDiscount: null,
  item_id: null,
  item_listImg: null,
  item_name: null,
  like_count: null,
  mainImg: null,
  medias: [],
  originPrice: null,
  product_comment_count: null,
  product_id: null,
  product_like_count: null,
  product_listImg: null,
  product_listImg2: null,
  product_name: null,
  product_shortDesc: null,
  review_oid: null,
  subject: null,
  tags: [],
  user_id: null,
  user_name: null,
  user_thumbnail: null,
};

export const emptyMenu = {
  category: null,
  id_category: null,
  name: null,
  thumb: null,
  url: null,
};
