import { useNavigate } from 'react-router-dom';
import { ABOUT, ABOUT_CO_CEO } from '../../../route/constant';

const AboutCeoEn = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <article className='fab-founder-detail'>
        <div className='--desc'>
          <section className='fab-page-section'>
            <div className='container'>
              <div className='--desc-header --mb-60 --mb-40--m'>
                <h2 className='--name --txt-xxl --mb-5'>
                  <strong>Goo Sang kwon</strong>
                </h2>
                <p class='--role --font-basic'>co-CEO / Director of Content Development</p>
              </div>
              <div className='--desc-body --mb-80 --mb-50--m'>
                <p className='--font-basic'>
                  Goo Sang kwon, a chief executive and content development officer at FAB365, is
                  responsible for the company's content development strategy and has shown
                  recognized achievements in the global 3D printing community. He is the best 3D
                  design expert who received a Ph.D. in design from Seoul National University in
                  Korea and taught as a professor at Konkuk University and Ewha University.
                  <br />
                  <br />
                  For the past 30 years, he has participated in 3D design projects for Hyundai Motor
                  and Kia Motors and 3D design projects for electronic products by Samsung and LG,
                  and also participated in many projects for special effects on movies and CF
                  videos. As a plastic model mania, an animation mania, and Ducati Motorcycle Mania,
                  his long experience and depth in level are reflected in the development of
                  contents in FAB365.
                </p>
              </div>
              <div className='--desc-footer'>
                <a href='' onClick={e => goTo(e, ABOUT)} className='--more'>
                  <span className='--font-basic'>← Back</span>
                </a>
                <a href='' onClick={e => goTo(e, ABOUT_CO_CEO)} className='--more'>
                  <span className='--font-basic'>co-CEO / Lee Jong han →</span>
                </a>
              </div>
            </div>
          </section>
        </div>

        <div
          className='--img'
          style={{
            backgroundImage:
              "url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/founder/ceo1.jpg')",
          }}
        ></div>
      </article>
    </>
  );
};
export default AboutCeoEn;
