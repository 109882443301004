/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from '../../../component/layout/Layout';
import { ITEMS } from '../../../route/constant';
import { TAB_LIKE } from '../component/constant';
import MyTab from '../component/MyTab';
import { useEffect, useState } from 'react';
import { getSelectLikes, likesDelete } from '../../../action/request';
import { Link, useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import { ImgStyled } from '../../../styles/ImgStyled';

const Like = () => {
  const [likes, setLikes] = useState([]);
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data: res } = await getSelectLikes();
      setLikes(res.data);
    } catch (e) {}
  };

  const tryLikesDelete = async idItem => {
    try {
      await likesDelete(idItem);
      fetchData();
    } catch (e) {
      alert(e);
    }
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <Layout>
      <div id='main'>
        <div className='page-top mypage'>
          <div className='container'>
            <h1 className='page-title align-left'>My Page</h1>
          </div>
        </div>

        <MyTab tab={TAB_LIKE} />
        <section className='mypage-section'>
          <div className='container'>
            <div className='content-box orders'>
              <div className='content-box-header'>
                <h2>Like List</h2>
              </div>
              <div className='content-box-body'>
                <div className='like-list'>
                  <div className='product-list product-list-3'>
                    <ul>
                      {!!likes?.length
                        ? likes?.map(l => {
                            // 좋아요 목록이 아무것도 없을 때 null인 아이템이 하나 존재해서 에러처리
                            if (l.item === null) return <></>;
                            return (
                              <li key={l.item?.id_item}>
                                <div className='product-list-item'>
                                  <Link
                                    to={ITEMS + '/' + l.item?.id_item}
                                    onClick={e => goTo(e, ITEMS + '/' + l.item?.id_item)}
                                    className='tb'
                                  >
                                    <ImgStyled src={l.item?.list_img} alt={l.item?.name_en} />
                                    <ImgStyled
                                      src={l.item?.list_img2}
                                      alt={l.item?.name_en}
                                      className='over'
                                    />
                                  </Link>
                                  <div className='product-desc'>
                                    <h3 className='product-name'>{l.item?.name_en}</h3>
                                    <p className='product-category'>{l.item?.short_desc_en}</p>
                                    <span className='price'>
                                      {l.item?.free === 'y' ? 'FREE' : '$ ' + l.item?.price_usd}
                                    </span>
                                  </div>
                                  <a
                                    className='btn-delete'
                                    data-action='like-delete'
                                    data-item={l.product_id}
                                    onClick={() => {
                                      tryLikesDelete(l.item?.id_item);
                                    }}
                                  >
                                    &times;
                                  </a>
                                </div>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Like;
