import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import styled from 'styled-components';
import { isMobileAtom, selectedTabAtom, selectedSortAtom } from '../../../atom/atom';
import { useNavigate } from 'react-router-dom';

const DesignersCountBox = ({ icon, title, count, data, tab }) => {
  const isMobile = useRecoilValue(isMobileAtom);
  const [, setSelectedTab] = useRecoilState(selectedTabAtom);
  const [, setSeletedSort] = useRecoilState(selectedSortAtom);
  const navigate = useNavigate();

  const getSortType = title => {
    switch (title) {
      case 'Models':
        return { title: 'Latest', value: 'Latest' };
      case 'Downloads':
        return { title: 'Most Downloaded', value: 'Downloads' };
      default:
        return { title: 'Latest', value: 'Latest' };
    }
  };

  const onClickDesignerPage = () => {
    const sortType = getSortType(title);
    setSeletedSort(sortType);
    setSelectedTab(tab);

    navigate(`/designers/${data?.name.replace(/\s+/g, '')}`, {
      state: { location: 'fromDesignerSection' },
    });
  };

  return (
    <Box
      className='box'
      height={isMobile ? 86 : 120}
      img={'/assets/img/designers/info_cell_frame_pc.png'}
      onClick={onClickDesignerPage}
    >
      <div>
        <Icon width={isMobile ? 20 : 24} src={icon} />
        <p className='title'>{title}</p>
      </div>

      <span className='count'>{count}</span>
    </Box>
  );
};

export default DesignersCountBox;

const Box = styled.div`
  width: 100px;
  height: ${props => props.height}px;
  border-radius: 6px;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 14px 14px 14px;
  cursor: pointer;

  .title {
    font-weight: 300;
    font-size: 14px;
    color: #929293;
    font-family: 'inter';
    margin-bottom: 8px;
  }

  .count {
    font-size: 22px;
    font-weight: 500;
    font-family: 'Outfit';
  }

  @media only screen and (max-width: 650px) {
    padding: 8px 12px;
    .title {
      font-size: 12px;
    }
    .count {
      font-size: 14px;
      /* margin-bottom: 8px; */
    }
  }
`;

const Icon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  cursor: default !important;
  margin-bottom: 11px;

  @media only screen and (max-width: 480px) {
    margin-bottom: 8px;
  }
`;
