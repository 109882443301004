import Layout from '../../../component/layout/Layout';
import { MY_REQUEST_WRITE } from '../../../route/constant';
import { TAB_REQUEST } from '../component/constant';
import MyTab from '../component/MyTab';
import RequestItem from './component/RequestItem';
import Pagination from '../../Category/component/Pagination';
import { useState, useEffect } from 'react';
import { getMyRequest } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';

const MyRequest = () => {
  const [request, setRequest] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(16);
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const pageChange = val => {
    setPage(val);
  };
  const pageConfirm = val => {
    setPage(val);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    try {
      const {
        data: { data: res },
      } = await getMyRequest({ page });
      setRequest(res.requests);
      setTotal(res.total);
      setPageSize(res.paging.size);
    } catch (e) {}
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top mypage'>
            <div className='container'>
              <h1 className='page-title align-left'>My Page</h1>
            </div>
          </div>
          <MyTab tab={TAB_REQUEST} />

          <section className='mypage-section'>
            <div className='container'>
              <div className='content-box orders'>
                <div className='content-box-header'>
                  <h2>Your Request List</h2>
                </div>
                <div className='content-box-body'>
                  <div className='request-cta'>
                    <p>Do you have a design you need?</p>
                    <a
                      href=''
                      onClick={e => goTo(e, MY_REQUEST_WRITE)}
                      className='btn btn-green btn-fill'
                    >
                      Upload your request
                    </a>
                  </div>

                  <div className='mypage-request-list'>
                    <ul className='clearfix'>
                      <RequestItem data={request} />
                    </ul>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Pagination
            page={page}
            onconfirm={pageConfirm}
            onpagechange={pageChange}
            total={parseInt(Math.ceil(total / pageSize))}
          />
        </div>
      </Layout>
    </>
  );
};

export default MyRequest;
