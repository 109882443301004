import Layout from '../../component/layout/Layout';
import CheckoutProcess from './component/CheckoutProcess';
import './Checkout.css';
import CheckoutProduct from './component/CheckoutProduct';
import { createCheckoutStep1, getPromotionCodeValidate } from '../../action/request';
import { useEffect, useState } from 'react';
import {
  calculateDiscountPrice,
  calculateSub,
  moneyFormatter,
  renderAvailableMileage,
} from '../../util/calculateCheckout';
import { useLocation, useNavigate } from 'react-router-dom';
import { ls } from '../../constant';
import { TippyStyle } from './CheckoutSucess';
import styled from 'styled-components';
import TroubleCheckOut from '../../component/checkOut/TroubleCheckOut';

const Checkout = () => {
  const ZERO_POINT = 0;

  const [code, setCode] = useState('');
  const [discount, setDiscount] = useState(ZERO_POINT.toFixed(2));
  const [discountRate, setDiscountRate] = useState(0);
  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [usePoints, setUsePoints] = useState(0);
  const [points, setPoints] = useState(0);
  const [originPoints, setOriginPoints] = useState(0);
  const [checkoutItems, setCheckoutItems] = useState({});
  const [pointError, setPointError] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [isValidPromocde, setIsValidPromocde] = useState(false);

  // console.log(
  //   'total:',
  //   total,
  //   '/ subTotal:',
  //   subTotal,
  //   '/usePoints:',
  //   usePoints,
  //   '/points:',
  //   points,
  //   '/discount:',
  //   discount,
  //   '/discountRate : ',
  //   discountRate
  // );

  const navigate = useNavigate();
  const location = useLocation();
  const checkedItems = location.search.split('=')[1];

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const { data: res } = await createCheckoutStep1(checkedItems);

      setCheckoutItems(res.data);
      setPoints(res.data?.credit || 0);
      setOriginPoints(res.data?.credit || 0);
      setSubTotal(res.data?.checkoutDto?.salesPrice.toFixed(2) || ZERO_POINT.toFixed(2));
      setTotal(res.data?.checkoutDto?.salesPrice.toFixed(2) || ZERO_POINT.toFixed(2));
    } catch (e) {}
  };

  const onCheckPromocde = () => {
    if (!code.trim().length) {
      setDiscount(ZERO_POINT.toFixed(2));
      setDiscountRate(0);
      setIsLimit(false);
      setIsValidPromocde(false);
      return alert('Promotion Code is not valid');
    }
    setIsApplyLoading(true);

    try {
      setTimeout(async () => {
        const { data: res } = await getPromotionCodeValidate(code.trim());
        setIsApplyLoading(false);
        if (res.error) {
          setDiscount(ZERO_POINT.toFixed(2));
          setDiscountRate(0);
          setCode('');
          setIsLimit(false);
          setIsValidPromocde(false);

          return alert(res.error);
        }

        const { discount, discountRate } = calculateDiscountPrice(
          checkoutItems.checkoutDto.salesPrice.toFixed(2),
          res.result.discount_limit,
          res.result.amount,
          setIsLimit
        );
        setDiscountRate(discountRate);
        setDiscount(discount);
        setIsValidPromocde(true);
      }, 300);
    } catch (e) {
      setDiscount(ZERO_POINT.toFixed(2));
      setDiscountRate(0);
      setIsLimit(false);
      setIsValidPromocde(false);
    }
  };

  const onChangeCode = e => {
    setCode(e.target.value);
    setIsValidPromocde(false);
  };

  const onChangePoints = e => {
    let sumPrice;
    let mileage = moneyFormatter(e.target.value);
    if (Number(e.target.value) > 0 && !!e.target.value.trim()) {
      sumPrice = Math.round(parseFloat(subTotal * 100));
    }

    let payPrice = calculateSub(sumPrice, mileage);
    if (payPrice <= 0) {
      mileage = sumPrice;
      payPrice = 0;
    }

    if (usePoints === mileage) return;

    setUsePoints(mileage);
  };

  const onCheckoutContinue = () => {
    // 실제 포인트보다 사용 포인트가 더 많을 경우의 유효성 검사
    if (Number(originPoints) < Number(usePoints)) {
      setPointError(true);
      return;
    }

    // 프로모 코드 체크 확인
    if (code.trim().length && !isValidPromocde)
      return alert('Please press the Apply button to check the promocode.');

    setPointError(false);

    const newUsePoints =
      usePoints[usePoints.length - 1] === '.'
        ? usePoints.slice(0, usePoints.length - 1)
        : usePoints;

    const params = {
      coin: newUsePoints,
      code: code.trim(),
      discount: discount,
      subTotal: subTotal,
    };
    navigate(`/checkout-step2`, { state: params });

    // 2024.03.09 수정
    // navigate(
    //   `/checkout-step2?coin=${newUsePoints}&code=${code.trim()}&discount=${discount}&subtotal=${subTotal}`
    // );
  };

  // discount에 따른 subTotal 변경
  useEffect(() => {
    if (!Number(discount)) setSubTotal(checkoutItems?.checkoutDto?.salesPrice.toFixed(2));
    else {
      setSubTotal((checkoutItems?.checkoutDto?.salesPrice.toFixed(2) - discount).toFixed(2));
    }
  }, [discount]);

  // 사용 point에 따른 남은 point & total 변경
  useEffect(() => {
    setPoints(renderAvailableMileage(checkoutItems?.credit, usePoints));
    setTotal((subTotal - usePoints / 100).toFixed(2));
  }, [usePoints]);

  // discount > subTotal 변경에 따른 사용 포인트 & 남은 포인트 비율 변경 및 total 변경
  useEffect(() => {
    setTotal((subTotal - usePoints / 100).toFixed(2));
    if (subTotal < usePoints / 100) {
      setUsePoints(usePoints - discount * 100);
    }
  }, [subTotal]);

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  if (!checkoutItems?.checkoutDto?.products) return null;
  return (
    <>
      <Layout>
        <div id='main' className='checkout-page'>
          <CheckoutProcess step={2} />
          <section className='shoppingbag-section'>
            <div className='container'>
              <div className='shoppingbag'>
                <div className='cart'>
                  <div className='content-box'>
                    <div className='content-box-header'>
                      <h2>
                        Cart
                        <small className='badge'>
                          {checkoutItems.checkoutDto.products?.length}
                        </small>
                      </h2>
                    </div>
                    <div className='content-box-body'>
                      <CheckoutProduct data={checkoutItems.checkoutDto.products} />
                      <hr />

                      <div className='payment-block payment-discount'>
                        <div>
                          <div className='tippy-container'>
                            <h5>Payment by credit</h5>{' '}
                            <NewTippyStyle
                              className='tippy-1'
                              content={[
                                <li>· Credit is a currency used in Fab365 to purchase models.</li>,
                                <li>· 100 Credits has the same value as 1USD.</li>,
                                <li>
                                  · From the credit page in My Profile, you are able to charge your
                                  Credit.
                                </li>,
                                <li>
                                  · When you charge Credit, you will receive Bonus Credit in
                                  proportion to the amount you charge.
                                </li>,
                              ]}
                            >
                              <i className='tippy-icon-questionmark'></i>
                            </NewTippyStyle>
                          </div>
                          <div className='payment-block-body'>
                            <div className='my-point'>
                              <div>
                                <input
                                  type='text'
                                  className='form-input'
                                  name='coin'
                                  // value={usePoints}
                                  value={usePoints}
                                  onChange={onChangePoints}
                                />
                              </div>
                              <span>
                                ( Your credit{' '}
                                <span className='point-available notranslate'>
                                  {Number(points).toLocaleString()}
                                </span>
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className='tippy-container'>
                            <h5>Enter a promocode</h5>
                            <NewTippyStyle
                              className='tippy-1'
                              content={[
                                <li>
                                  · Using the promo code is not mandatory for purchase. Any model is
                                  able to be purchased without the use of the promo code.
                                </li>,
                                <li>
                                  · By inputting the valid promo code on the text field, you can get
                                  a certain percentage of discount on the price.
                                </li>,
                                <li>
                                  · You may get a promo code from one of our many events or
                                  giveaways. Check out the newsletter especially carefully.
                                </li>,
                                <li>
                                  · Remember that promo codes may expire or only be active for a
                                  short period of time, so make sure to use your code right away.
                                </li>,
                              ]}
                            >
                              <i className='tippy-icon-questionmark'></i>
                            </NewTippyStyle>
                          </div>
                          <div className='payment-block-body'>
                            <div className='my-point'>
                              <div>
                                <input
                                  type='text'
                                  className='form-input'
                                  name='code'
                                  value={code}
                                  onChange={onChangeCode}
                                  placeholder='Please enter the code you know.'
                                />
                                <button
                                  id='applyCode'
                                  className={`btn btn-fill btn-green btn-full ${
                                    isApplyLoading && 'loading'
                                  } promocode-apply-btn`}
                                  style={{ maxWidth: '260px' }}
                                  onClick={onCheckPromocde}
                                >
                                  Apply
                                </button>
                              </div>
                              <span
                                style={{ marginTop: '-35px' }}
                                className='promocode-discount-txt'
                              >
                                ( Discount{' '}
                                <span className='code-discounted notranslate'>{discountRate}%</span>{' '}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>

                <div className='total'>
                  <div id='checkout-alert' className={`${pointError ? 'error show' : 'hidden'}`}>
                    Point is not enough
                  </div>
                  <input type='hidden' id='approved-code' />
                  <div className='your-order-box'>
                    <h3>Checkout</h3>
                    <div className='your-order-info'>
                      <div className='price-info'>
                        <dl>
                          <dt>Product price</dt>
                          <dd>
                            <span className='notranslate'>
                              {checkoutItems.checkoutDto.salesPrice.toFixed(2)}$
                            </span>
                          </dd>
                        </dl>
                        <dl>
                          <dt>Discount</dt>
                          <dd>
                            {isLimit && <span className='txt-red txt-limit'>(maximum cap) </span>}
                            <span className='txt-red discount notranslate'>- {discount}$</span>
                          </dd>
                        </dl>
                      </div>
                      <div className='price-info'>
                        <dl>
                          <dt>Sub total</dt>
                          <dd className='subtotal'>
                            <span className='notranslate'>{subTotal}$</span>
                          </dd>
                        </dl>
                        <dl>
                          <dt>Use Credit</dt>
                          <dd>
                            <span className='txt-red point notranslate'>
                              - {usePoints ? usePoints / 100 : ZERO_POINT.toFixed(2)}
                            </span>
                          </dd>
                        </dl>
                      </div>

                      <dl>
                        <dt>Total</dt>
                        <dd>
                          <span className='notranslate'>
                            <strong className='total-price'>{total}$</strong>
                          </span>
                        </dd>
                      </dl>

                      <button
                        className='btn btn-fill btn-green btn-full'
                        data-action='1502-checkout-step1'
                        onClick={onCheckoutContinue}
                      >
                        Continue Checkout
                      </button>
                    </div>
                  </div>
                  <TroubleCheckOut />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Checkout;

const NewTippyStyle = styled(TippyStyle)`
  li {
    list-style: none;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
