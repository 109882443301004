import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import Categoryist from './component/CategoryList';
import { OPTION_ITEM_NEW, OPTION_ITEM_POPULAR, OPTION_ITEM_ALL } from './component/constant';
import SelectSort from './component/SelectSort';
import SelectType from './component/SelectType';
import './Category.css';
import Pagination from './component/Pagination';
import ItemList from './component/ItemList';
import {
  findCategoryDetail,
  getCategories,
  getFreeLimit,
  getItems,
  report,
} from '../../action/request';
import { gtagViewItemList } from '../../action/gTag';
import { ls } from '../../constant';
import UserContext from '../../context/user/user';
import { useRecoilState } from 'recoil';
import { ctgSortAtom } from '../../atom/atom';
const Category = () => {
  // const  isPopular = !!useLocation().state;
  const navigate = useNavigate();
  const isPopular = useLocation();
  const { state, action: userAction } = useContext(UserContext);
  const [type, setType] = useState();
  const [sort, setSort] = useState();
  const [page, setPage] = useState(1);
  const [items, setItems] = useState({ count: 0 });
  const [limit, setLimit] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const { category } = useParams();
  const [sortCtgValue, setCtgSort] = useRecoilState(ctgSortAtom);
  const itemType = localStorage.getItem(ls.itemType) || OPTION_ITEM_ALL;
  const itemSort = localStorage.getItem(ls.itemSort) || OPTION_ITEM_NEW;

  useEffect(() => {
    const handleBeforeUnload = event => {
      navigate(isPopular.pathname, { replace: true });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // 홈에서 popular 섹션을 타고 왔을 경우
    if (isPopular?.state === 'popular' || isPopular?.search.includes('query')) {
      localStorage.setItem(ls.itemSort, OPTION_ITEM_POPULAR);
      setSort(OPTION_ITEM_POPULAR);
      setType(itemType);
    } else if (sortCtgValue) {
      setSort(sortCtgValue.sort);
      setType(sortCtgValue.type);
    } else {
      setSort(itemSort);
      setType(itemType);
    }

    setPage(Number(localStorage.getItem(ls.itemPage)) || 1);
  }, [
    localStorage.getItem(ls.itemPage),
    localStorage.getItem(ls.itemSort),
    localStorage.getItem(ls.itemType),
  ]);

  useEffect(() => {
    fetchData();
  }, [category, state.isLogin]);

  const typeChange = value => {
    setType(value);
    pageChange(1);
    localStorage.setItem(ls.itemType, value);
    setCtgSort({ type: value, sort: sort });
  };
  const sortChange = value => {
    setSort(value);
    pageChange(1);
    localStorage.setItem(ls.itemSort, value);
    navigate(isPopular.pathname, { replace: true });
    setCtgSort({ type: type, sort: value });
  };

  const pageChange = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
  };

  const pageConfirm = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
    updateItems();
  };

  const fetchData = async () => {
    try {
      const { data: resCategories } = await getCategories();
      setCategories(resCategories.data);
      const { data: resFreeLimit } = await getFreeLimit();
      setLimit(resFreeLimit.data);
      let cId = '';

      if (category) {
        const { data: resCategoryDetail } = await findCategoryDetail({ name: category });
        cId = resCategoryDetail.data.id_category;
        setCategoryId(cId);
      } else {
        setCategoryId('');
      }
      if (type && page && sort) {
        updateItems(cId);
      }
      // gtag 아이템 리스트 조회 태그
      gtagViewItemList(category);
      // console.log('tag test', window.location.href, category);
    } catch (e) {
      // TODO: 주석 풀기
      // report(e);
    }
  };

  const updateItems = async cId => {
    let id = cId;
    setCategoryId(r => {
      id = r ? r : id;
      return r;
    });

    if (id === undefined) return;

    const { data: resItems } = await getItems({
      sort,
      idCategory: id,
      offset: (page - 1) * 9,
      size: 9,
      type,
    });
    setItems(resItems.data);
  };

  useEffect(() => {
    if (sort && type) updateItems(categoryId);
  }, [type, sort, page, categoryId]);

  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top page-top-with-breadcrumb'>
            <div className='container container-lg'>
              <h1 className='page-title'>{category ? category : 'All'}</h1>
            </div>
          </div>

          <div className='main-content-with-aside'>
            <Categoryist current={category} data={categories.items} />
            <div className='main-content'>
              <section className='store-collection-list'>
                <div className='container'>
                  <div
                    className='content-section content-section-has-top-nav'
                    style={{ minHeight: '900px' }}
                  >
                    <div className='product-list product-list-3'>
                      <div className='product-list-filter margin-select'>
                        <SelectType value={type} onchange={typeChange} />
                        <SelectSort value={sort} onchange={sortChange} />
                      </div>
                      <ItemList data={items.results} limit={limit} />
                    </div>

                    <Pagination
                      page={page}
                      total={Math.ceil(items.count / 9)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Category;
