import React, { useState, useEffect } from 'react';
import { GtagModal, GtagWrap, EeaWrap, EeaBg } from './eea';
import AcceptBtn from './AcceptBtn';
import SettingBtn from './SettingBtn';
import { ImgStyled } from '../../styles/ImgStyled';
import styled from 'styled-components';

const EeaPopup = ({
  setCookiesModal,
  isMobile,
  setCookiesPopup,
  onClickCloseEea,
  onClickAllCookies,
}) => {
  const openMenu = localStorage.getItem('OpenCategoryMenu');
  const [openCtg, setOpenCtg] = useState();
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const onClickModal = () => {
    setCookiesModal(true);
    document.body.style.overflow = 'hidden';
  };

  useEffect(() => {
    openMenu && setOpenCtg(openMenu);
  }, [openMenu]);
  useEffect(() => {
    setTimeout(() => setIsSkeletonLoading(false), 500);
  }, []);

  return (
    <EeaWrap>
      <EeaBg>
        {!isSkeletonLoading && (
          <GtagWrap
            bottom={isMobile ? 0 : 30}
            left={isMobile ? 0 : 20}
            radius={isMobile ? '16px 16px 0px 0px' : '16px'}
            zIndex={openCtg ? 11 : 999}
            id='EeaPopup'
          >
            <GtagModal width={isMobile ? '100vw' : '450px'}>
              {isMobile ? (
                ''
              ) : (
                <PopupImg
                  src='/assets/img/cookies/cookies.png'
                  className='cookiesimg'
                  alt='cookies'
                />
              )}

              <span>
                By clicking “Accept all cookies”, you agree Fab365 can store cookies on your device
                and disclose information in accordance with our{' '}
                <a className='bold' href='/assets/pdf/CookiesPolicy.pdf' target='_blank'>
                  Cookie Policy.
                </a>
              </span>
              <div className='btnWrap'>
                <AcceptBtn
                  width={isMobile ? '98%' : '100%'}
                  color={'#96FF85'}
                  title={'Accept all cookies'}
                  onClick={onClickAllCookies}
                />
                <div className={isMobile ? 'settingMobile' : 'settingPC'}>
                  <SettingBtn
                    onClickModal={onClickModal}
                    isMobile={isMobile}
                    setCookiesPopup={setCookiesPopup}
                    setCookiesModal={setCookiesModal}
                    onClickCloseEea={onClickCloseEea}
                  />
                </div>
              </div>
            </GtagModal>
          </GtagWrap>
        )}
      </EeaBg>
    </EeaWrap>
  );
};

export default EeaPopup;

const PopupImg = styled(ImgStyled)`
  height: 150px;
`;
