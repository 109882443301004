const ReviewTags = ({ data }) => {
  return (
    <>
      <div className="story-content">
        {data?.map((t, idx) => {
          return (
            <span className="badge-tag" key={idx}>
              <i className="fas fa-hashtag"></i>
              {t}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default ReviewTags;
