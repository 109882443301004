import React, { useEffect, useState, useContext } from 'react';
import CommentBox from './CommentBox';
import styled from 'styled-components';
import { getDesignerComments } from '../../../action/request';
import CommentBody from '../../comment/CommentBody';
import CommentReply from '../../comment/CommentReply';
import ItemCommentReplyWrite from '../../../page/Item/component/ItemCommentReplyWrite';
import UserContext from '../../../context/user/user';
import { postDesignerCommentsEditWrite, deleteDesignerComment } from '../../../action/request';
import { RETURN_CODE } from '../../../action/constant';
import { sanitize } from 'dompurify';
import Pagination from '../../../page/Category/component/Pagination';
import Pagenation from '../Pagenation';
import CommentImageViewer from '../../comment/CommentImageViewer';
import { useRecoilState } from 'recoil';
import { commentTotalCountAtom, commentPrevAtom, designerListAtom } from '../../../atom/atom';

const Comments = ({ userState, designerURL, designerId, moveScroll, isMobile }) => {
  const [prevComment, setPrevComment] = useRecoilState(commentPrevAtom);
  const [comments, setComments] = useState();
  const [commentPage, setCommentPage] = useState(prevComment !== 'null' ? prevComment?.page : 1);
  const [showImgViewer, setShowImgViewer] = useState(false);
  const [imgData, setImgData] = useState({ index: null, data: {} });

  const [count, setCount] = useState();
  const [totalCount, setTotalCount] = useRecoilState(commentTotalCountAtom);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);

  const [showReply, setShowReply] = useState([]);

  const pageChange = value => {
    onCommentPageConfirm && onCommentPageConfirm(value);
  };
  const pageConfirm = value => {
    onCommentPageConfirm && onCommentPageConfirm(value);
  };

  const onCommentPageConfirm = page => {
    setCommentPage(page);
    moveScroll();
  };

  const onReload = async () => {
    fetchDeignerComments();
  };

  const editComment = async (comment, msg, medias) => {
    try {
      const imgs = medias?.map(media => JSON.stringify(media));
      const { data: res } = await postDesignerCommentsEditWrite(designerURL, {
        comment,
        msg,
        medias,
        'imgs[]': imgs,
      });
      if (res.returnCode === RETURN_CODE.C00000) {
        onReload();
      }
    } catch (e) {
      alert('request failed');
    }
  };

  const deleteComment = async ({ oidComment }) => {
    try {
      if (window.confirm('Delete?')) {
        const { data: res } = await deleteDesignerComment(designerURL, oidComment);
        if (res.returnCode === RETURN_CODE.C00000) {
          fetchDeignerComments();
        }
      }
    } catch (e) {
      alert('request failed');
    }
  };

  const showCommentReply = idx => {
    const showList = [...showReply];
    showList[idx] = { ...showList[idx], show: true };
    setShowReply(showList);
    const target = showList[idx]?.target;
    if (target) {
      target.current.scrollIntoView();
    }
  };

  const setCommentReply = (target, idx) => {
    setShowReply(sr => {
      const showList = [...sr];
      showList[idx] = { target, show: false };
      return showList;
    });
  };

  // 리플 박스 모두 닫기 처리
  const resetCommentReply = () => {
    const newArr = [...showReply];
    newArr.forEach(reply => {
      if (reply !== undefined) {
        reply.show = false;
      }
    });
    setShowReply(newArr);
  };

  useEffect(() => {
    resetCommentReply();
  }, [comments]);

  const fetchDeignerComments = async () => {
    try {
      const { data: comments } = await getDesignerComments(designerURL, commentPage);

      if (comments.returnCode === 'C00000') {
        setComments(comments.data.comments);
        setCount(comments.data.groupCount);
        setTotalCount({ count: comments.data.commentCount, total: 'update' });
        setPrevComment(false);

        if (designerList) {
          const result = designerList?.map(item =>
            Number(item.designer.id_studio) === Number(designerURL)
              ? {
                  designer: {
                    ...item.designer,
                    comment_count: comments.data.commentCount,
                  },
                  items: item.items,
                }
              : item
          );

          setDesignerList(result);
        }
      }
    } catch (e) {
      console.info(e);
    }
  };

  useEffect(() => {
    if (!prevComment) {
      moveScroll();
    }

    designerURL && fetchDeignerComments();
  }, [commentPage]);

  const onClickModel = () => {
    setPrevComment({ page: commentPage });
    localStorage.setItem('scrollDesigner', window.scrollY);
  };

  return (
    <CommentsTab id='Comments'>
      {userState?.isLogin ? (
        <CommentBox
          studioId={designerURL}
          onReload={onReload}
          userState={userState}
          className='commentBox'
        />
      ) : (
        ''
      )}

      <div className='comments-body'>
        <div className='comment-list'>
          <ul>
            {comments?.map((c, idx) => {
              return (
                <li key={c.id_comment}>
                  <div
                    className='comment-list-item'
                    data-json={sanitize(
                      JSON.stringify({
                        comment: c.id_comment,
                        root: c.id_item,
                      })
                    )}
                  >
                    <CommentBody
                      comment={c}
                      pkComment='id_comment'
                      onDelete={() => deleteComment({ oidComment: c.id_comment })}
                      showReply={() => showCommentReply(idx)}
                      onEdit={editComment}
                      setShowImgViewer={setShowImgViewer}
                      setImgData={setImgData}
                      onReload={onReload}
                      value='designer'
                      userState={userState}
                      studioId={designerURL}
                      designerId={designerId}
                      onClickModel={onClickModel}
                    />
                    {c['replys'] && c['replys'].length > 0 && (
                      <CommentReply
                        replys={c['replys']}
                        showReply={showCommentReply}
                        onEdit={editComment}
                        onDelete={deleteComment}
                        idx={idx}
                        setCommentReply={setCommentReply}
                        pkComment={'id_comment'}
                        setShowImgViewer={setShowImgViewer}
                        setImgData={setImgData}
                        value='designer'
                        designerId={designerId}
                      />
                    )}

                    {showReply[idx]?.show && (
                      <ItemCommentReplyWrite
                        userState={userState}
                        onReload={onReload}
                        parent={c.id_comment}
                        value='designer'
                        studioId={designerURL}
                        thumbnail={userState.thumbnail}
                      />
                    )}
                  </div>
                </li>
              );
            })}
          </ul>

          {count > 0 && (
            <Pagenation
              count={count}
              setPage={setCommentPage}
              page={commentPage}
              moveScroll={moveScroll}
              viewCount={10}
              value={'Comments'}
            />
          )}

          {showImgViewer && (
            <CommentImageViewer imgData={imgData} setShowImgViewer={setShowImgViewer} />
          )}
        </div>
      </div>
    </CommentsTab>
  );
};

export default Comments;

const CommentsTab = styled.div`
  max-width: 900px;
  margin: auto;

  .comments-header {
    margin-top: 45px;
  }

  .comments-body {
    margin-top: 20px;
  }

  #addBtn {
    display: flex;
    justify-content: center;
  }

  .crown {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 2rem !important;
    height: 2rem !important;
  }
`;
