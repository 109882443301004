import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useContext, useEffect, useState } from 'react';

import { round } from '../../../util/number';

import { getProductDetail } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import { formatDesignerNumber } from './utils';
import UserContext from '../../../context/user/user';

import { useRecoilValue, useRecoilState } from 'recoil';
import { seletecdPidAtom, seletecdMoreAtom } from '../../../atom/atom';
import { MyContents } from '../../../component/designers/designers';
import { BackBtn } from '../../../component/designers/designers';

const SalesProductDetail = ({ moveScroll }) => {
  const [sales, setSales] = useState([]);
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDownload, setTotalDownload] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [historyTotalDownload, setHistoryTotalDownload] = useState(0);
  const [historyTotalEarning, setHistoryTotalEarning] = useState(0);
  const [, setSelectedMore] = useRecoilState(seletecdMoreAtom);
  // const { pid } = useParams();
  const pid = useRecoilValue(seletecdPidAtom);
  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetchProductDetail();
  }, []);

  const fetchProductDetail = async () => {
    try {
      const { data: res } = await getProductDetail(pid);
      const result = res.result;

      if (res) {
        setSales(result);
        setHistory(result);
      }
    } catch (e) {
      alert(e);
    }
  };

  const queryHistories = (h, start, end) => {
    const result = [];
    const keys = Object.keys(h);
    for (let i = 0; i < h.length; i++) {
      let date = h[i].date;
      if (
        new Date(date).getTime() >= start.getTime() &&
        new Date(date).getTime() <= end.getTime()
      ) {
        result.push(h[keys[i]]);
      }
    }
    return result.reverse();
  };

  useEffect(() => {
    let d = 0;
    let e = 0;
    sales.forEach(s => {
      d += parseInt(s.count);
      e += parseFloat(s.earning);
    });
    setTotalDownload(d);
    setTotalEarning(e);
  }, [sales]);

  // history 조회 시 누적 카운트
  useEffect(() => {
    let d = 0;
    let e = 0;
    history.forEach(s => {
      d += parseInt(s.count);
      e += parseFloat(s.earning);
    });
    setHistoryTotalDownload(d);
    setHistoryTotalEarning(e);
  }, [history]);

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  const goToMySalesProduct = e => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedMore('Earning');
    moveScroll();
  };

  return (
    <>
      <>
        <MyContents id='main'>
          <section className='mypage-section mysale'>
            <div className='container'>
              <div className='content-box mysales'>
                <div className='content-box-body'>
                  <div className='__grid'>
                    <div className='fab-mypage-mysales-table __fullwidth '>
                      <BackBtn bottom={30}>
                        <div className='__footer'>
                          <button className='fab-btn-back' onClick={goToMySalesProduct}>
                            <svg
                              width='36'
                              height='42'
                              viewBox='0 0 36 42'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.2957 30.7041L7.42523 22.8336L24.9996 22.8336L24.9996 19.1669L7.42523 19.1669L15.2957 11.2964L12.7034 8.7041L0.407226 21.0003L12.7034 33.2964L15.2957 30.7041Z'
                                fill='#4200F8'
                              />
                            </svg>{' '}
                            Back
                          </button>
                        </div>
                      </BackBtn>

                      <div className='__header'>
                        <div className='__title-area'>
                          <h4 className='__title'>{sales[0]?.items.name_en}</h4>
                          <p className='__desc'>Shows the revenue and downloads of the product.</p>
                        </div>
                        <div className='__utils'>
                          <DateRangePicker
                            initialSettings={{
                              ranges: {
                                Today: [moment(), moment()],
                                Yesterday: [
                                  moment().subtract(1, 'days'),
                                  moment().subtract(1, 'days'),
                                ],
                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [
                                  moment().subtract(1, 'month').startOf('month'),
                                  moment().subtract(1, 'month').endOf('month'),
                                ],
                              },
                              startDate: new Date(moment().subtract(7, 'd').format('MM/DD/YYYY')),
                              endDate: new Date(moment().format('MM/DD/YYYY')),
                            }}
                            onCallback={(start, end, label) => {
                              const quried = queryHistories(
                                sales,
                                new Date(start.format('YYYY-MM-DD')),
                                new Date(end.format('YYYY-MM-DD'))
                              );
                              setHistory(quried);
                            }}
                          >
                            <input type='text' className='form-input' style={{ padding: 20 }} />
                          </DateRangePicker>
                        </div>
                      </div>
                      <div className='__body'>
                        <div className='fab-table-wrapper'>
                          <div className='__scroll-area'>
                            <table>
                              <colgroup>
                                <col width='10%' />
                                <col width='52%' />
                                <col width='14%' />
                                <col width='14%' />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th></th>
                                  <th>Download</th>
                                  <th className='__txt-right'>Earnings</th>
                                </tr>
                              </thead>
                              <tbody id='history-rows'>
                                {!isLoading &&
                                  history.length !== 0 &&
                                  history.map((h, k) => {
                                    return (
                                      <tr key={k}>
                                        <td className='__txt-left'>{h.date}</td>
                                        <td></td>
                                        <td>{formatDesignerNumber(round(h.count))}</td>
                                        <td className='__txt-right'>
                                          <span className='__red'>
                                            + ${formatDesignerNumber(round(h.earning))}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {!isLoading && history.length === 0 && (
                                  <tr>
                                    <td colSpan='5'>
                                      <div className='nodata'>No Data to Display</div>
                                    </td>
                                  </tr>
                                )}
                                {isLoading && (
                                  <tr>
                                    <td colSpan='5'>
                                      <div className='nodata'>Loading Data to Display</div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td></td>
                                  <td className='__txt-right'>Total</td>
                                  <td className='total-count'>
                                    {formatDesignerNumber(historyTotalDownload)}
                                  </td>
                                  <td className='__txt-right total-amount'>
                                    $ {formatDesignerNumber(historyTotalEarning.toFixed(2))}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='__footer'>
                        <button className='fab-btn-back' onClick={goToMySalesProduct}>
                          <svg
                            width='36'
                            height='42'
                            viewBox='0 0 36 42'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.2957 30.7041L7.42523 22.8336L24.9996 22.8336L24.9996 19.1669L7.42523 19.1669L15.2957 11.2964L12.7034 8.7041L0.407226 21.0003L12.7034 33.2964L15.2957 30.7041Z'
                              fill='#4200F8'
                            />
                          </svg>{' '}
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </MyContents>
      </>
    </>
  );
};

export default SalesProductDetail;
