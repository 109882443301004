import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import ItemPrice from './ItemPrice';
import { isFree } from '../../../util/itemData';
import { useNavigate } from 'react-router-dom';
import ItemDetailNavBtn from './ItemDetailNavBtn';
import { createCheckoutFree } from '../../../action/request';
import { CHECKOUT_FREE } from '../../../route/constant';
import CartContext from '../../../context/cart.js/carts';
import { ImgStyled } from '../../../styles/ImgStyled';
import { itemDetailNavAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';

const ItemDetailNav = ({
  selectedTab,
  detail,
  commentCount,
  reviewCount,
  navRef,
  onClickNav,
  freeTicketCount,
  action,
  onClickDownload,
  isMobile,
  isLogin,
  loginStatus,
  setShowScrollToTop,
  downloadStatus,
  buyStatus,
  isOwn,
}) => {
  const option = [
    { title: 'Details', value: 0 },
    { title: 'Reviews', value: 1 },
    { title: 'Comments', value: 2 },
    { title: 'Related Models', value: 3 },
  ];
  const { action: cartAction, action: modalAction } = useContext(CartContext);
  const [isFixed, setIsFixed] = useState(false);
  const [itemDetailNav, setItemDetailNav] = useRecoilState(itemDetailNavAtom);
  const itemRef = useRef();
  const notEnougth = freeTicketCount - detail?.qty < 0;

  const navigate = useNavigate();

  const handleScroll = () => {
    const sections = itemRef.current;
    const sectionsTop = sections.offsetTop;

    if (window.scrollY > 1020) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    if (window.scrollY + 180 >= sectionsTop) {
      setItemDetailNav(true);
      setShowScrollToTop(true);
    } else {
      setItemDetailNav(false);
      setShowScrollToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      setItemDetailNav(false);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const buydataClick = () => {
    if (!isLogin) {
      action.setIsLogin(true);
    } else {
      navigate(`/checkout-step1?item=${detail?.id}`);
    }
  };

  const handleDownload = () => {
    onClickDownload();
  };

  const checkoutFree = async () => {
    if (!isLogin) {
      action.setIsLogin(true);
      return;
    } else {
      try {
        const { data: res } = await createCheckoutFree(detail.id);
        cartAction.checkoutFreeProduct(res.data);

        navigate(CHECKOUT_FREE);
      } catch (error) {
        // console.log(error);
      }
    }
  };

  return (
    <DetailNav ref={itemRef} isFixed={isFixed}>
      {itemDetailNav ? (
        <ItemInfo>
          <>
            <div className='item-wrap' onClick={() => handleClick()} style={{ cursor: 'pointer' }}>
              <ImgStyled className='item-img' src={detail?.listImg} />
              <div className='item-info'>
                <span>{detail.name}</span>
                {detail.status !== 'secret' && (
                  <>
                    {detail.price_option === 'reward' ? (
                      <>
                        {loginStatus ? (
                          buyStatus === 'y' || detail.isDesigner || isOwn ? (
                            <Free>Owned</Free>
                          ) : (
                            <NavTicketInfo notEnougth={!notEnougth}>
                              {detail.qty} Reward tickets needed
                            </NavTicketInfo>
                          )
                        ) : (
                          <span style={{ marginTop: 5, fontWeight: 500 }}>Sign up and get it</span>
                        )}
                      </>
                    ) : detail.price_option === 'free' ? (
                      downloadStatus === 'y' || detail.isDesigner ? (
                        <Free>Owned</Free>
                      ) : (
                        <Free>Free</Free>
                      )
                    ) : (
                      detail.status !== 'secret' && (
                        <ItemPrice
                          itemNumber={detail.id}
                          price={isFree(detail.free) ? 0 : detail.price}
                          freeTicketCount={freeTicketCount}
                          isLogin={isLogin}
                          qty={detail.qty}
                          discountRate={detail.discountRate}
                          originPrice={detail.originPrice}
                          finalPrice={detail.price}
                          secretProduct={detail.status}
                          value='nav-bar'
                          margin={0}
                          buyStatus={buyStatus}
                          priceOption={detail.price_option}
                          downloadStatus={downloadStatus}
                          isDesigner={detail.isDesigner}
                        />
                      )
                    )}
                  </>
                )}
              </div>
            </div>

            {freeTicketCount !== undefined && (
              <div className='buy-wrap'>
                {(isLogin && detail.isOwn) || detail.price_option === 'free' ? (
                  <ItemDetailNavBtn
                    title={'DOWNLOAD'}
                    showProduct={detail.status}
                    onClick={handleDownload}
                    isOwn={detail.isOwn}
                    buyStatus={buyStatus}
                    priceOption={detail.price_option}
                    downloadStatus={downloadStatus}
                    isDesigner={detail.isDesigner}
                    secret={detail.status}
                  />
                ) : null}
                {detail.price_option === 'paid' && !detail.isOwn && !detail.isDesigner && (
                  <ItemDetailNavBtn
                    title={'BUY NOW'}
                    onClick={buydataClick}
                    showProduct={detail.status}
                    isDesigner={detail.isDesigner}
                    secret={detail.status}
                    isOwn={detail.isOwn}
                  />
                )}
                {(!isLogin || !notEnougth) && detail.price_option === 'reward' && !detail.isOwn && (
                  <ItemDetailNavBtn
                    title={'GET MODEL NOW'}
                    bg={'#F7F15B'}
                    showProduct={detail.status}
                    color={'#000'}
                    icon='btn-icon'
                    onClick={checkoutFree}
                    isDesigner={detail.isDesigner}
                    secret={detail.status}
                    isOwn={detail.isOwn}
                  />
                )}
                {isLogin &&
                  detail.price_option === 'reward' &&
                  !detail.isOwn &&
                  notEnougth &&
                  !detail.isDesigner && (
                    <>
                      <ItemDetailNavBtn
                        title={'NOT ENOUGH TICKETS'}
                        bg={'#AAAAAA'}
                        color='#ffffff'
                        showProduct={detail.status}
                        onClick={checkoutFree}
                        disabled={true}
                        buyStatus={buyStatus}
                        isDesigner={detail.isDesigner}
                        secret={detail.status}
                        isOwn={detail.isOwn}
                      />
                    </>
                  )}

                {isLogin && detail.price_option === 'reward' && detail.isDesigner && (
                  <>
                    <ItemDetailNavBtn
                      title={'DOWNLOAD'}
                      showProduct={detail.status}
                      onClick={handleDownload}
                      isOwn={detail.isOwn}
                      buyStatus={buyStatus}
                      priceOption={detail.price_option}
                      downloadStatus={downloadStatus}
                      isDesigner={detail.isDesigner}
                      secret={detail.status}
                    />
                  </>
                )}
                {isLogin && detail.price_option === 'paid' && detail.isDesigner && (
                  <>
                    <ItemDetailNavBtn
                      title={'DOWNLOAD'}
                      showProduct={detail.status}
                      onClick={handleDownload}
                      isOwn={detail.isOwn}
                      buyStatus={buyStatus}
                      priceOption={detail.price_option}
                      downloadStatus={downloadStatus}
                      isDesigner={detail.isDesigner}
                      secret={detail.status}
                    />
                  </>
                )}
              </div>
            )}
          </>
        </ItemInfo>
      ) : (
        ''
      )}

      <div className='nav-bar'>
        {option.map((nav, index) => {
          return (
            <>
              {isMobile ? (
                <MobileNav
                  key={index}
                  bg={selectedTab === index ? '#000' : '#F3F6F9'}
                  color={selectedTab === index ? '#fff' : '#797979'}
                  font={selectedTab === index ? 600 : 400}
                  className={nav.title}
                  onClick={() => onClickNav(nav.value)}
                >
                  <span className='nav-title'>
                    {nav.title}{' '}
                    {index === 1 ? `(${reviewCount})` : index === 2 ? `(${commentCount})` : ''}
                  </span>
                </MobileNav>
              ) : (
                <Nav
                  key={index}
                  color={selectedTab === index ? '#000' : '#797979'}
                  border={selectedTab === index ? '4px solid #222222' : 'none'}
                  font={selectedTab === index ? 600 : 400}
                  ref={ref => navRef?.current[index] == ref}
                  className={nav.title}
                  onClick={() => onClickNav(nav.value)}
                >
                  <span className='nav-title'>
                    {nav.title}{' '}
                    <span className='nav-count'>
                      {index === 1 ? `(${reviewCount})` : index === 2 ? `(${commentCount})` : ''}
                    </span>
                  </span>
                </Nav>
              )}
            </>
          );
        })}
      </div>
    </DetailNav>
  );
};

export default ItemDetailNav;

const DetailNav = styled.div`
  position: sticky;
  top: -2px;

  z-index: 20;

  .item-box {
    height: 50px;
    background-color: #292f36;
    color: #fff;
  }

  .nav-bar {
    height: 56px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
    background-color: #f3f6f9;
  }

  @media (max-width: 450px) {
    .nav-bar {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 100px;
      border: 1px solid #e6e6e7;
      align-items: center;
      justify-items: center;
      margin-top: -2px;
    }
  }
`;

const MobileNav = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bg};

  :nth-child(1),
  :nth-child(3) {
    border-right: 1px solid #e6e6e7;
  }

  :nth-child(3),
  :nth-child(4) {
    border-top: 1px solid #e6e6e7;
  }
  span {
    color: ${props => props.color};
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: ${props => props.font};
  }

  .nav-count {
    color: #797979 !important;
  }
`;

const Nav = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-bottom: ${props => props.border};
  span {
    color: ${props => props.color};
    text-align: center;
    font-weight: ${props => props.font};
  }

  .nav-count {
    color: #797979 !important;
  }
`;

const NavTicketInfo = styled.div`
  margin-top: 5px;
  font-weight: 500;
  font-family: 'Outfit';
  color: ${({ notEnougth }) => (notEnougth ? '#000' : '#FD5757')};
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  height: 85px;
  gap: 5px;

  padding: 0 10px;

  .item-wrap {
    display: flex;
    align-items: center;
  }
  .item-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; // 텍스트를 두 줄로 제한
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5em; // 한 줄의 높이 설정
      max-height: 3em; // 두 줄의 높이 설정
      margin: 0;
    }
  }

  .item-img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }

  .buy-wrap {
  }
`;

{
  /* <div className='container'>
        <ul className='menu'>
          <li className={selectedTab === 0 ? 'active' : ''}>
            <a
              className='item-tab'
              data-target='#store_detail_information'
              onClick={() => setSelectedTab(0)}
              style={{ cursor: 'pointer' }}
            >
              <span className='qlabel' its-ta-ident-ref='http://www.wikidata.org/entity/Q11028'>
                Information
              </span>
            </a>
          </li>
          <li className={selectedTab === 1 ? 'active' : ''}>
            <a
              className='item-tab'
              data-target='#store_detail_comments'
              onClick={() => setSelectedTab(1)}
              style={{ cursor: 'pointer' }}
            >
              <span>
                Comments <small className='badge'>{commentCount}</small>
              </span>
            </a>
          </li>
          <li className={selectedTab === 2 ? 'active' : ''}>
            <a
              className='item-tab'
              data-target='#store_detail_review'
              style={{ cursor: 'pointer' }}
              onClick={() => setSelectedTab(2)}
            >
              <span>
                Review <small className='badge'>{reviewCount}</small>
              </span>
            </a>
          </li>
        </ul>
      </div> */
}

const Free = styled.div`
  font-family: 'Outfit';
  font-size: 18px;
  font-weight: 500;
  line-height: 22.68px;
  text-align: left;
`;
