export const formatDesignerNumber = value => {
  // 숫자인지 확인하고 문자열로 변환
  const stringValue = typeof value === 'number' ? value.toString() : value;

  // 소수점을 기준으로 나누기
  const parts = stringValue.split('.');

  // 정수부분에 콤마 추가
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // 소수점 이하 값이 있을 경우
  if (parts.length === 2) {
    // 두 번째 자리까지만 유지하고 반올림 처리
    const rounded = Math.round(parseFloat(`${parts[1].substring(0, 2)}.${parts[1][2]}0`));
    // 소수점 둘째 자리까지 표시
    parts[1] = rounded < 10 ? `0${rounded}` : rounded.toString();
  }

  // 최종 값 반환
  return parts.join('.');
};
