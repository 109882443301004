import React from 'react';

const HoverBtns = ({ onClickImgZoom, index, onClickDeleteImg }) => {
  return (
    <div className='button-container'>
      <button className='button' onClick={() => onClickImgZoom(index)}>
        <img src='/assets/img/mange/icon-imgsize.png' className='hover-icon' />
      </button>
      <button className='button' onClick={() => onClickDeleteImg(index)}>
        <img src='/assets/img/mange/icon_trash.png' className='hover-icon' />
      </button>
    </div>
  );
};

export default HoverBtns;
