/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BuyDataNowButton = ({ isLogin, id, isLoading, showProduct }) => {
  const { action: modalAction } = useContext(ModalContext);

  const navigate = useNavigate();
  const secret = showProduct === 'secret';

  const buydataClick = () => {
    if (!isLogin) {
      modalAction.setIsLogin(true);
    } else {
      navigate(`/checkout-step1?item=${id}`);
    }
  };

  return (
    <>
      <Btn onClick={buydataClick} secret={secret} disabled={secret}>
        <img src='/assets/img/itemDetail/btn-icon-fff.png' />
        {!isLoading & !secret ? 'BUY NOW' : 'No longer for sale'}
      </Btn>
    </>
  );
};

export default BuyDataNowButton;

// #929599 !important

const Btn = styled.button`
  background-color: ${props => (props.secret ? '#929599' : '#000000')};
  color: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.8;
  border: none;

  img {
    width: 15px;
    position: relative;
    right: 10px;
    top: 5px;
  }
`;

export const ToggleSwitchStyle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin: 12px 10px;
`;
