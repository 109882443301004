import { useNavigate } from 'react-router-dom';
import { MY_PROFILE } from '../../../route/constant';
import UserContext from '../../../context/user/user';
import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import {
  MembersContainer,
  MembersWrapper,
  MemberTitleContainer,
} from '../../Term/component/TermStyle';

const PrivacyKo = () => {
  const navigate = useNavigate();
  const { state } = useContext(UserContext);
  const { action } = useContext(ModalContext);

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <MembersContainer className='container'>
        <div className='terms'>
          <div className='terms-header'>
            <MemberTitleContainer>
              <h1 className='logo'>
                <img src='/assets/img/members/logo.png' alt='logo' />
              </h1>
              <h2 className='terms-title'>개인정보 처리방침</h2>
            </MemberTitleContainer>
          </div>
          <MembersWrapper className='terms-body'>
            <p className='members-title'>개인정보 처리방침</p>
            <div className='term-block'>
              <p>
                팹삼육오(Fab365, Inc., 이하 ‘당사’)는 「개인정보 보호법」, 「정보통신망 이용촉진 및
                정보보호 등에 관한 법률」등 개인정보 보호에 관한 법률 및 규정에 따라 회원의 개인정보
                보호와 권익을 보호하고 개인정보와 관련한 회원의 고충을 원활하게 처리할 수 있도록
                다음과 같은 개인정보의 처리 및 취급 방침을 두고 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제1조. 개인정보의 처리 목적</h3>
              <p>
                당사는 다음 목적을 위해서 필요한 최소한의 개인정보만을 처리합니다. 처리된 개인정보는
                오직 이러한 목적을 위해서만 사용될 것이며, 사용 목적에 대한 변경이 있을 경우 사전에
                동의를 구할 것입니다. 회사가 개인정보를 처리하는 목적은 다음과 같습니다:
              </p>
              <p>
                - 회원제 서비스 이용에 따른 본인확인
                <br />
                - 콘텐츠 중개 플랫폼 서비스 제공
                <br />
                - 개인식별, 부정이용방지와 비인가 사용방지, 가입의사 확인
                <br />
                - 만14세 미만 여부 확인, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인
                <br />
                - 사고조사, 분쟁해결, 민원처리, 고지사항 전달
                <br />
                - 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공
                <br />
                - 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계
                <br />- 세금계산서 등의 발행, 유료서비스 이용시 결제 업무 등의 목적.
              </p>
            </div>
            <div className='term-block'>
              <h3>제2조. 수집하는 개인정보의 항목 및 수집방법</h3>
              <p>(1) 수집하는 개인정보의 항목은 다음과 같습니다.</p>
              <p>
                1. 일반회원 또는 구매자:
                <br />
                이름, 아이디, 휴대전화번호, 이메일 주소, (외국인의 경우)외국인등록번호, 서비스 이용
                정보, 기기정보, 생년월일, 성별, 본인인증정보, 구매·취소·환불 등에 관한 정보,
                계좌번호·카드번호 기타 결제에 관한 정보, 현금영수증 발급을 위한 정보 등
              </p>
              <p>
                2. 디자이너 회원 또는 판매자:
                <br />
                이름, 아이디, 휴대전화번호, 이메일 주소, (외국인의 경우)외국인등록번호, 서비스 이용
                정보, 기기정보, 생년월일, 성별, 본인인증정보, 구매·취소·환불 등에 관한 정보, 결제에
                관한 정보, 정산 내역, 계좌번호, 현금영수증 정보, 세금계산서 발행 등을 위한 정보,
                판매이력, 경력, 사업자등록번호 등
              </p>
              <br />
              <p>
                (2) 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집되거나 변환되어
                수집 될 수 있습니다
              </p>
              <p>- IP Address, 쿠키, 방문일시, 서비스 이용 기록, 접속 로그, 기기 정보 등</p>
              <br />
              <p>(3) 당사는 다음과 같은 방법으로 개인정보를 수집합니다:</p>
              <p>
                - 홈페이지, 서면양식, 팩스, 전화, 이메일, 게시판, 이벤트 응모, 회원센터,
                실명인증관련업체, 기타 제휴사로부터의 제공, 서비스 사용 중 이용자의 자발적 제공
              </p>
            </div>
            <div className='term-block'>
              <h3>제3조. 개인정보의 처리 및 보유 기간</h3>
              <p>
                원칙적으로 개인정보의 수집 및 이용 목적이 달성되면 해당 정보를 지체 없이 파기합니다.
                단, 다음의 정보에 대하여는 아래의 이유로 명시한 기간 동안 보존합니다.
              </p>
              <p>
                1. 계약 또는 청약철회 등에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한
                법률(5년)
              </p>
              <p>
                2. 대금결제 및 재화 등의 공급에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한
                법률(5년)
              </p>
              <p>
                3. 소비자의 불만 또는 분쟁처리에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한
                법률(3년)
              </p>
              <p>4. 방문기록 : 통신비밀보호법(3개월)</p>
              <p>5. 전자금융거래기록, 상대방에 관한 정보 등 : 전자금융거래법(5년)</p>
              <p>6. 국세 증빙에 관한 정보 : 국세 관련 증빙, 신고업무(5년)</p>
              <p>7. 부가가치세 등 세무업무를 위한 정보 : 부가가치세법 등(5년)</p>
            </div>
            <div className='term-block'>
              <h3>제4조. 개인정보의 제3자 제공</h3>
              <p>
                (1) 당사는 원칙적으로 이용자의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한
                범위 내에서 처리하며, 회원의 사전 동의 없이는 본래의 범위를 초과하여 처리하거나
                제3자에게 제공하지 않습니다. 단, 다음 각 호에 해당하는 경우에는 개인정보를 제3자에게
                제공할 수 있습니다.
              </p>
              <p>1. 회원이 사전에 동의한 경우</p>
              <p>
                2. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라
                수사기관의 요구가 있는 경우
              </p>
              <br />
              <p>
                (2) 현재 개인정보 제3자 제공 현황은 다음과 같으며, 상황에 따라 변동될 수 있습니다.
              </p>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공 목적</th>
                    <th>제공항목</th>
                    <th>보유기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>없음</td>
                    <td>없음</td>
                    <td>없음</td>
                    <td>없음</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='term-block'>
              <h3>제5조. 개인정보처리의 위탁</h3>
              <p>
                (1) 당사는 서비스 향상 및 원활한 계약사항의 이행 등을 위하여 개인정보 처리업무를
                외부 전문업체에 일부 위탁할 수 있습니다.
              </p>
              <br />
              <p>
                (2) 개인정보 처리위탁으로 인하여 정보주체의 개인정보가 분실·도난·유출·변조 또는
                훼손되지 아니하도록 수탁사를 교육하고, 현황점검 등을 통하여 수탁사가 개인정보를
                안전하게 처리하는지를 감독해야 합니다.
              </p>
              <br />
              <p>
                (3) 현재 개인정보 처리 위탁 현황은 다음과 같으며, 상황에 따라 변동될 수 있습니다.
              </p>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>수탁자</th>
                    <th>위탁업무</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>없음</td>
                    <td>없음</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='term-block'>
              <h3>제6조. 정보주체 및 법정대리인의 권리·의무 및 그 행사방법</h3>
              <p>
                (1) 회원 및 법정대리인은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 정정할
                수 있습니다. 개인정보 열람·정정 등은 어플리케이션 등을 통해 회원이 직접 처리할 수
                있으며, 개인정보 보호책임자 등에게 서면, 전화 또는 E-mail을 통해 요청할 수 있습니다.
                당사는 회원이 개인정보 오류 정정을 요청한 경우, 정정 완료 전까지 당해 개인정보를
                이용 또는 제공하지 않습니다.
              </p>
              <br />
              <p>
                (2) 회원은 언제든지 개인정보의 수집, 이용, 제공에 대한 동의내용을 철회할 수
                있습니다. 동의 철회는 서면, 전화, E-mail을 통해서 신청할 수 있으며, 담당자는 지체
                없이 필요한 조치를 취합니다.
              </p>
              <br />
              <p>
                (3) 귀하는 개인 데이터 삭제를 요청할 권리가 있습니다. 프로필의 개인 정보 설정에서
                그렇게 할 수 있습니다.{' '}
                <a
                  href=''
                  onClick={e => {
                    if (!state.isLogin) {
                      e.preventDefault();
                      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
                      action.setIsLogin(true);
                    } else {
                      goTo(e, MY_PROFILE);
                    }
                  }}
                  style={{ color: '#835fec' }}
                >
                  데이터 삭제 바로가기
                </a>
              </p>
            </div>
            <div className='term-block'>
              <h3>제7조. 개인정보의 파기</h3>
              <p>
                (1) 당사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를
                파기합니다. 다만 법령상 보관할 의무가 있는 항목에 대해서는 해당 법령에서 보관을 명한
                항목, 기간 및 이용 목적의 범위 내에서 계속 보관·이용합니다.
              </p>
              <br />
              <p>(2) 구체적인 파기의 절차, 방법은 다음과 같습니다.</p>
              <p>
                1. 파기절차:
                <br />
                회원이 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부
                방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로
                옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
              </p>
              <p>
                2. 파기방법:
                <br />
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일
                형태의 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제8조. 개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항</h3>
              <p>
                (1) 회사는 회원에게 특화된 맞춤서비스를 제공하고 회원으로 하여금 보다 원활하고
                편리하게 서비스를 이용할 수 있도록 하기 위하여 '쿠키(cookie)'를 사용합니다. 쿠키는
                웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는
                소량의 정보이며 이용자의 PC 등에 저장되기도 합니다.
              </p>
              <br />
              <p>
                (2) 회원은 쿠키의 설치/운영 및 거부에 대한 권한을 보유합니다. 회원은 웹브라우저에서
                옵션을 설정함으로써 쿠키를 허용하거나 거부할 수도 있습니다. 다만, 쿠키의 저장을
                거부할 경우에는 로그인이 필요한 서비스에 있어서 이용이 불가능하거나 불편이 따를 수
                있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제9조. 개인정보의 안전성 확보 조치</h3>
              <p>
                당사는 다음과 같이 안전성 확보에 필요한 기술적, 관리적 및 물리적 조치를 하고
                있습니다.
              </p>
              <p>
                1. 개인정보 취급 직원의 최소화 및 교육:
                <br />
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하고 있으며 개인정보를
                관리하는 대책을 시행하고 있습니다.
              </p>
              <p>
                2. 개인정보의 암호화:
                <br />
                회원 아이디의 비밀번호는 암호화 되어 저장 및 관리되고 있어 본인만이 알 수 있으며
                비밀번호 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
              </p>
              <p>
                3. 해킹 등에 대비한 기술적 대책:
                <br />
                당사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
                보안프로그램을 설치하고 주기적인 점검을 있으며, 외부로부터 접근이 통제된 구역에
                시스템을 설치하는 등 기술적/물리적으로 감시 및 차단하고 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제10조. 개인정보보호 책임자</h3>
              <p>
                당사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이
                개인정보 보호책임자를 지정하고 있습니다.
              </p>
              <p>- 이름: 이종한</p>
              <p>- 소속 및 지위: 대표</p>
              <p>- 전화번호: +82 2-579-0365</p>
              <p>
                - 이메일 :{' '}
                <a href='mailto:info@fab365.net' style={{ color: '#835fec' }}>
                  info@fab365.net
                </a>
              </p>
            </div>
            <div className='term-block'>
              <h3>제11조. 권익침해 구제방법</h3>
              <p>
                회원은 개인정보침해로 인한 신고나 상담이 필요하신 경우 당사 또는 아래 기관에
                문의하시기 바랍니다.
              </p>
              <p>
                - 개인정보분쟁조정위원회 (
                <a href='https://www.kopico.go.kr/' style={{ color: '#835fec' }}>
                  www.kopico.go.kr
                </a>{' '}
                / 02-405-5150)
              </p>
              <p>
                - 한국인터넷진흥원 개인정보침해신고센터 (
                <a href='https://privacy.kisa.or.kr/' style={{ color: '#835fec' }}>
                  privacy.kisa.or.kr
                </a>{' '}
                / 국번 없이 118)
              </p>
              <p>
                - 경찰청 사이버안전국 (
                <a href='https://ecrm.police.go.kr/minwon/main' style={{ color: '#835fec' }}>
                  ecrm.police.go.kr
                </a>{' '}
                / 국번 없이 182)
              </p>
            </div>
            <div className='term-block'>
              <h3>제12조. 개인정보의 처리 및 취급 방침의 변경에 따른 공지의무</h3>
              <p>
                당사가 개인정보 처리방침을 변경하는 경우에는 변경 및 시행의 시기, 변경된 내용을
                지속적으로 공개합니다.
              </p>
              <p>개인정보의 처리 및 취급 방침 시행일자 : 2016. 12. 26.</p>
            </div>
          </MembersWrapper>
        </div>
      </MembersContainer>
    </>
  );
};

export default PrivacyKo;
