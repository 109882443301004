import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../component/layout/Layout';
import OrderDetail from './component/OrderDetail';
import OrderMain from './component/OrderMain';
import { useEffect, useState } from 'react';
import { ls } from '../../../constant';
import styled, { css } from 'styled-components';
import DownloadProcessingModal from '../../../component/modal/DownloadProcessingModal';

const Order = () => {
  const [downloadModalStep, setDownloadModalStep] = useState(0);
  const [itemId, setItemId] = useState(0);
  const { oid } = useParams();
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <>
      <PageMobileStyle id='main' step={downloadModalStep}>
        <Layout>
          {!oid && <OrderMain />}

          {oid && (
            <div id='main2'>
              <OrderDetail oid={oid} setItemId={setItemId} setStep={setDownloadModalStep} />
            </div>
          )}
        </Layout>
      </PageMobileStyle>
      {/* 다운로드 모달 */}
      {downloadModalStep > 0 && (
        <DownloadProcessingModal
          itemId={itemId}
          step={downloadModalStep}
          setStep={setDownloadModalStep}
        />
      )}
    </>
  );
};

export default Order;

const PageMobileStyle = styled.div`
  ${props =>
    props.step !== 0 &&
    css`
      height: 100vh;
      overflow: hidden;
    `}
  /* STL다운로드 모달 640px 이하에서 띄울 시 스크롤바 없애기 */
   @media screen and (max-width: 640px) {
    ${props =>
      props.step !== 0 &&
      css`
        display: none;
      `}
  }
`;
