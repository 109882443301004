import CartFreeProduct from './CartFreeProduct';
import CartProduct from './CartProduct';

const CartItems = ({ data, limit, deleteItem }) => {
  if (limit.isPaid) {
    return (
      <>
        <div className='purchase-product-list per-one'>
          <form id='my-cart'>
            <ul className='clearfix'>
              {data?.map((c, idx) => {
                return <CartProduct key={c.productId} data={c} deleteItem={deleteItem} />;
              })}
            </ul>
          </form>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='purchase-product-list per-one'>
        <form id='my-cart'>
          <ul className='clearfix'>
            {data?.map((c, idx) => {
              return c.salesPrice > 0 ? (
                <CartProduct data={c} key={idx} deleteItem={deleteItem} />
              ) : (
                <CartFreeProduct data={c} limit={limit} key={idx} deleteItem={deleteItem} />
              );
            })}
          </ul>
        </form>
      </div>
    </>
  );
};

export default CartItems;
