import { useEffect, useState } from 'react';
import Gallery from '../../../component/gallery/Gallery';
import { getMainThumb } from '../../../util/image';
import RequestIcon from './RequestIcon';
const RequestSlide = ({ data }) => {
  return (
    <>
      <div className='store-detail-gallery'>
        <div className='gallery-item'>
          <Gallery imgs={data.medias} isRequest={true} />
        </div>
        <RequestIcon status={data.status} />
      </div>
    </>
  );
};

export default RequestSlide;
