import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { PAYPAL_CLIENT_ID, STRIPE_PUBLISHABLE_API_KEY } from '../../My/Point/component/constant';
import { Elements } from '@stripe/react-stripe-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Stripe from '../../My/Point/component/Stripe';
import Paypal from '../../My/Point/component/Paypal';
import { PaymentContainer } from '../styles';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_API_KEY);
function Payment({
  checkout,
  checkReadyForPay,
  setCheckoutMessage,
  requestId,
  totalAmount,
  checkoutMessage,
}) {
  const [isShowStripe, setIsShowStripe] = useState(false);
  const [isShowPaypal, setIsShowPaypal] = useState(false);
  const minStripeMock = 0.5;

  const handleShowForm = type => {
    if (type === 'stripe') {
      setIsShowStripe(true);
      setIsShowPaypal(false);
    } else {
      setIsShowStripe(false);
      setIsShowPaypal(true);
    }
  };

  return (
    <PaymentContainer>
      {parseFloat(checkout.finalPrice) > minStripeMock ? (
        <label className='payment-select-container' onChange={() => handleShowForm('stripe')}>
          <input type='radio' name='payment-option' value='stripe' /> Pay with Card
          <img
            src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/icon/icon_card.png'
            className='icon-pay'
            alt=''
          />
        </label>
      ) : (
        <label className='payment-select-container disabled'>
          <input type='radio' name='payment-option' value='' disabled /> Pay with Card - The payment
          amount must be at least $0.5.
        </label>
      )}
      <label className='payment-select-container' onChange={() => handleShowForm('paypal')}>
        <input type='radio' name='payment-option' value='paypal' /> Pay with PayPal
        <img
          src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/icon/icon_paypal.png'
          className='icon-pay'
          alt=''
        />
      </label>

      {isShowStripe && (
        <div>
          <Elements stripe={stripePromise}>
            <Stripe
              checkReadyForPay={checkReadyForPay}
              setCheckoutMessage={setCheckoutMessage}
              requestId={requestId}
              totalAmount={totalAmount}
              checkoutMessage={checkoutMessage}
            />
          </Elements>
        </div>
      )}
      <div style={{ display: isShowPaypal ? 'block' : 'none' }}>
        <PayPalScriptProvider options={{ 'client-id': PAYPAL_CLIENT_ID }}>
          <Paypal
            checkReadyForPay={checkReadyForPay}
            setCheckoutMessage={setCheckoutMessage}
            requestId={requestId}
          />
        </PayPalScriptProvider>
      </div>
    </PaymentContainer>
  );
}

export default Payment;
