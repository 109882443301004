/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import ModalContext from '../../context/modal/modal';

const FacebookNotice = () => {
  const { state, action } = useContext(ModalContext);

  return (
    <>
      <div className={'modal' + (state.isFacebookNotice ? ' active' : '')} id='modalFacebookNotice'>
        <div className='modal-inner'>
          <div className='modal-box facebook-notice'>
            <img src='/assets/img/notice/facebookerror.png' alt='Facebook login notice.' />
            <a
              onClick={() => {
                action.setIsFacebookNotice(false);
                action.setIsLogin(true);
              }}
              data-hide-modal='true'
              className='btn-close'
            >
              Back to Login
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacebookNotice;
