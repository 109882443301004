import * as c from './constant';
import React, { createContext, useEffect, useState } from 'react';
const ko = {
  [c.AVATAR_ERROR_FILE_TOO_BIG]: 'file is too big',
  [c.AVATAR_ERROR_INVALID_IMAGE_FORMAT]: 'invalid image format',

  [c.CHANGE_PASSWORD_ERROR_SNS_CANNOT]: 'sns user cannot change password',

  [c.CHECKOUT_ERROR_ALREADY_PURCHASED]: '이미 구매하신 제품입니다.',
  [c.CHECKOUT_ERROR_CANNOT_BUYABLE]: 'include cannot buyable items',
  [c.CHECKOUT_ERROR_EXCEED_MAX_ITEMS]: 'exceed max items',
  [c.CHECKOUT_ERROR_INVALID_PRICE]: 'invalid price',
  [c.CHECKOUT_ERROR_MILEAGE_NOT_ENOUGH]: 'mileage not enough',
  [c.CHECKOUT_ERROR_MIN_PRICE]: '신용카드 결제금액은 최소 100원 이상이어야 합니다',
  [c.CHECKOUT_STRING_CANNOT_BUYABLE]: '구매불가',

  [c.COUPON_ERROR_ALREADY_REGISTERED]: 'cannot registable coupon',
  [c.COUPON_ERROR_DATE_EXPIRED]: 'date expired',
  [c.COUPON_ERROR_GUID_NOT_EXIST]: 'coupon not exist',
  [c.COUPON_ERROR_ALL_ITEM_IS_PAID]:
    '당신은 이미 이 제품을 보유하고 있습니다. 이 쿠폰은 사용하실 수 없습니다.',

  [c.ERROR_PASSWORD_INVALID]: '비밀번호가 일치하지 않습니다.',
  [c.ERROR_PASSWORD_NOT_MATCH]: '암호가 일치하지 않습니다',

  [c.FORGOT_PASSWORD_ERROR_USER_NOT_ACTIVE]: 'user not active',
  [c.FORGOT_PASSWORD_ERROR_USER_NOT_EXIST]: 'user not exist',

  [c.LOGIN_ERROR_USER_NOT_EXIST]:
    '당신이 입력한 이메일 주소가 존재하지 않습니다. 또 다른 이메일주소를 입력하거나, 이전에 페이스북으로 로그인했는지 확인해주세요.',
  [c.LOGIN_ERROR_CANNOT_LOGINABLE]: 'cannot loginable',
  [c.LOGIN_ERROR_NOT_ACTIVE_USER]: 'closed user',
  [c.LOGIN_ERROR_EMAIL_INVALID]:
    '이메일의 형식이 잘못되었습니다. 올바른 이메일 형식]: ex) smith@gmail.com, john@fab365.net',
  [c.LOGIN_ERROR_PASSWORD_INVALID]: '비밀번호가 일치하지 않습니다.',

  [c.PRINTER_PROFILE_ERROR_SETTING_NOT_READY]: '셋팅 파일이 없습니다',
  [c.PRINTER_PROFILE_ERROR_NEED_PAID_ORDER]: '유료 구매를 해야 받을 수 있습니다',

  [c.MILEAGE_ERROR_EXCEED_MAX_AMOUNT]: 'mileage exceed max amount',

  [c.SIGNUP_ERROR_EMAIL_EXIST]: 'email exist',
  [c.SIGNUP_ERROR_SNS_TOKEN_EXIST]: 'already signed',
  [c.SIGNUP_ERROR_USERID_EXIST]: 'userid exist',

  [c.TICKET_ERROR_EXPIRED]: 'ticket expired',
  [c.TICKET_ERROR_NOT_ACTIVE]: 'ticket not active',

  //common
  [c.COMMON_FORM_REQUIRE_EMAIL]: '이메일을 입력해야 합니다',
  [c.COMMON_FORM_REQUIRE_PASSWORD]: '암호를 입력해야 합니다',

  [c.COMMON_FORMAT_EMAIL_INVALID]: '이메일 형식이 잘못되었습니다',

  //page
  [c.PAGE_FOOTER_HEADER_ABOUT]: '서비스소개',
  [c.PAGE_FOOTER_HEADER_CONTACT]: '연락처',
  [c.PAGE_FOOTER_HEADER_COPYRIGHT]: '디자인 저작권 안내',
  [c.PAGE_FOOTER_HEADER_HELP]: '도움말',
  [c.PAGE_FOOTER_HEADER_PRIVACY]: '개인정보 취급방침',
  [c.PAGE_FOOTER_HEADER_TERMS]: '이용약관',
  [c.PAGE_FOOTER_HEADER_TERMS_POINT]: '포인트 이용약관',

  [c.PAGE_FOOTER_COMPANY]:
    "<span className='addr-info-group'>상호: 주식회사 팹365| 사업자등록번호 : 398-81-00982</span><span>&nbsp;통신판매업신고번호 : 제 2011-서울강남-00810호 | 대표이사 : 구상권</span><br><span>서울 강남구 논현로 152, 302호 (우) 06301</span>",

  [c.PAGE_MY_POINT_HISTORY_TYPE_BUY]: '구매',
  [c.PAGE_MY_POINT_HISTORY_TYPE_USE]: '사용',
  [c.PAGE_CHECKOUT_LICENSE]:
    '본 저작물은 <b>미국 및 기타 국가의 저작권 법</b>에 의하여 보호됩니다. 본 저작물의 저작권은 <b>FAB365</b>에게 있으며, 저작권자의 허락없이 본 저작물을 <b>무단 복제, 재판매</b>하거나 저작권자의 로고를 가리고 <b>방송, 신문, SNS</b> 등에 노출시키는 행위는 <b>엄격히 금지</b>되며, 위반자는 <b>민사상 손해배상 책임</b> 또는 <b>형사처벌</b> 대상이 될 수 있습니다. (Title 17, United States Code. Section 501 and 506). 저작권 침해는 중범죄로서 <b>5년 이하의 징역</b> 또는 미화 <b>250,000달러의 벌금</b>에 처해질 수 있으며 이를 병과할 수 있습니다.',
  [c.PAGE_CHECKOUT_LICENSE_AGREEMENT]:
    '본인은 라이센스 문서 및 서비스 약관을 읽고 이해했으며 동의합니다.',
};

const en = {
  [c.AVATAR_ERROR_FILE_TOO_BIG]: 'file is too big',
  [c.AVATAR_ERROR_INVALID_IMAGE_FORMAT]: 'invalid image format',

  [c.CHANGE_PASSWORD_ERROR_SNS_CANNOT]: 'sns user cannot change password',

  [c.CHECKOUT_ERROR_ALREADY_PURCHASED]: 'This item is already purchased.',
  [c.CHECKOUT_ERROR_CANNOT_BUYABLE]: 'include cannot buyable items',
  [c.CHECKOUT_ERROR_EXCEED_MAX_ITEMS]: 'exceed max items',
  [c.CHECKOUT_ERROR_INVALID_PRICE]: 'invalid price',
  [c.CHECKOUT_ERROR_MILEAGE_NOT_ENOUGH]: 'mileage not enough',
  [c.CHECKOUT_ERROR_MIN_PRICE]: '신용카드 결제금액은 최소 100원 이상이어야 합니다',
  [c.CHECKOUT_STRING_CANNOT_BUYABLE]: 'No purchases',

  [c.COUPON_ERROR_ALREADY_REGISTERED]: 'cannot registable coupon',
  [c.COUPON_ERROR_DATE_EXPIRED]: 'date expired',
  [c.COUPON_ERROR_GUID_NOT_EXIST]: 'coupon not exist',
  [c.COUPON_ERROR_ALL_ITEM_IS_PAID]:
    'You already have this product. This coupon is not available to you.',

  [c.ERROR_PASSWORD_INVALID]: 'password invalid',
  [c.ERROR_PASSWORD_NOT_MATCH]: 'password not match',

  [c.FORGOT_PASSWORD_ERROR_USER_NOT_ACTIVE]: 'user not active',
  [c.FORGOT_PASSWORD_ERROR_USER_NOT_EXIST]: 'user not exist',

  [c.LOGIN_ERROR_USER_NOT_EXIST]:
    'The email address you entered does not exist. Please enter another email address or make sure you have logged in with Facebook before.',
  [c.LOGIN_ERROR_CANNOT_LOGINABLE]: 'cannot loginable',
  [c.LOGIN_ERROR_NOT_ACTIVE_USER]: 'closed user',
  [c.LOGIN_ERROR_EMAIL_INVALID]:
    'The format of the email is incorrect. Valid email format: ex) smith@gmail.com, john@fab365.net',
  [c.LOGIN_ERROR_PASSWORD_INVALID]: 'Passwords do not match.',

  [c.PRINTER_PROFILE_ERROR_SETTING_NOT_READY]: 'Slice settings for this printer is not ready',
  [c.PRINTER_PROFILE_ERROR_NEED_PAID_ORDER]: 'Slice setting is only for paid purchasing members',

  [c.MILEAGE_ERROR_EXCEED_MAX_AMOUNT]: 'mileage exceed max amount',

  [c.SIGNUP_ERROR_EMAIL_EXIST]: 'email exist',
  [c.SIGNUP_ERROR_SNS_TOKEN_EXIST]: 'already signed',
  [c.SIGNUP_ERROR_USERID_EXIST]: 'userid exist',

  [c.TICKET_ERROR_EXPIRED]: 'ticket expired',
  [c.TICKET_ERROR_NOT_ACTIVE]: 'ticket not active',

  //common
  [c.COMMON_FORM_REQUIRE_EMAIL]: 'The email is incorrect',
  [c.COMMON_FORM_REQUIRE_PASSWORD]: 'The password is incorrect',

  [c.COMMON_FORMAT_EMAIL_INVALID]: 'The email is incorrect',

  //page
  [c.PAGE_FOOTER_HEADER_ABOUT]: 'About Us',
  [c.PAGE_FOOTER_HEADER_CONTACT]: 'Contact',
  [c.PAGE_FOOTER_HEADER_COPYRIGHT]: 'Content & Copyright',
  [c.PAGE_FOOTER_HEADER_HELP]: 'Help',
  [c.PAGE_FOOTER_HEADER_PRIVACY]: 'Privacy Policy',
  [c.PAGE_FOOTER_HEADER_TERMS]: 'Terms of Service',
  [c.PAGE_FOOTER_HEADER_TERMS_POINT]: 'Point terms and Conditions',

  [c.PAGE_FOOTER_COMPANY]:
    '<span>#302, 152, Nonhyeon-ro, Gangnam-gu, Seoul, Republic of Korea,</span> <span>Zip code 06301</span>',

  [c.PAGE_MY_POINT_HISTORY_TYPE_BUY]: 'buy',
  [c.PAGE_MY_POINT_HISTORY_TYPE_USE]: 'use',
  [c.PAGE_CHECKOUT_LICENSE]:
    'This design is <b>protected by copyright law</b>, and all rights fully belong to the designer. Any <b>modification</b>, <b>copying</b>, <b>reselling</b>, or <b>sharing</b> of this design, <b>selling printed products</b>, or exposing the design through media like broadcasts, newspapers, or social media without crediting the copyright owner is <b>strictly prohibited</b> without the designer’s permission. Violations may result in <b>civil penalties and severe criminal charges</b>. Copyright infringement is a <b>serious crime</b>.',
  [c.PAGE_CHECKOUT_LICENSE_AGREEMENT]:
    'I have read, understood, and agree to the above License Agreement',
};

const LangContext = createContext({
  state: {
    lang: 'en',
    trigger: '',
  },
  action: {
    setLangState: () => {},
    setLang: () => {},
    isKo: () => {},
    trans: () => {},
    transBusiness: () => {},
  },
});
const update = l => {
  localStorage.setItem('lang', l);
  return l;
};

const LangProvider = ({ children }) => {
  let l;
  l = localStorage.getItem('lang');

  if (!l) {
    l = update('en');
  }

  const [lang, setLang] = useState(l);
  const [trigger, setTrigger] = useState('');
  const isKo = () => {
    return lang === 'ko';
  };

  const trans = code => {
    return isKo() ? ko[code] : en[code];
  };

  const transBusiness = code => {
    return isKo() ? c.BUSINESS_KO[code] : c.BUSINESS_EN[code];
  };

  const setLangState = (l, t) => {
    setTrigger(t ? t : 'bottom');
    update(l);
    setLang(l);
  };
  const value = {
    state: { lang, trigger },
    action: { setLang, setLangState, isKo, trans, transBusiness },
  };

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};

const { Consumer: LangConsumer } = LangContext;

export { LangProvider, LangConsumer };
export default LangContext;
