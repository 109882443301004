import { useContext, useState } from 'react';
import ModalContext from '../../context/modal/modal';
import { validateEmail } from '../../util/validate';
import LangContext from '../../context/lang/lang';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import { resetEmail } from '../../action/request';
import { RETURN_CODE } from '../../action/constant';
import {
  ForgotPasswordContainer,
  H2Style,
  SignUpHr,
  TextStyle,
  TitleBox,
} from './style/SignupStyle';

const ForgotPassword = () => {
  const { state, action } = useContext(ModalContext);
  const { state: langState } = useContext(LangContext);

  const [email, setEmail] = useState('');
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;

  const onSendResetLink = async e => {
    e.stopPropagation();
    e.preventDefault();

    if (!email.trim().length) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_UNSET);
    if (!validateEmail(email)) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_INVALID);

    try {
      const { data } = await resetEmail(email.trim());
      if (data.returnCode === RETURN_CODE.C00000) {
        action.setIsForgotPassword(false);
        setEmail('');
      }
      return alert(data.message);
    } catch (e) {
      return alert(e);
    }
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const handleFocus = e => {
    if (e.target.name === 'email') {
      setEmail('');
    }
  };

  return (
    <>
      <ForgotPasswordContainer
        className={'modal' + (state.isForgotPassword ? ' active' : '')}
        id='modalForgotPassword'
      >
        <div className='modal-inner'>
          <div className='modal-box'>
            <TitleBox>
              <H2Style>Forgot Password</H2Style>
              <SignUpHr />
            </TitleBox>
            <div className='modal-box-body'>
              <TextStyle className='m-b-lg'>
                Please enter your email address below.
                <br />
                We'll send you an email with your username,
                <br />
                and a link to reset your password.
              </TextStyle>
              <form name='forgot_password_form' className='m-b-md' onSubmit={onSendResetLink}>
                <div className='form-control'>
                  <input
                    type='text'
                    placeholder='Email address'
                    className='form-input'
                    name='email'
                    value={email}
                    onChange={onChangeEmail}
                    onFocus={e => handleFocus(e)}
                  />
                </div>

                <button type='submit' className='btn btn-full btn-fill '>
                  Send reset link
                </button>
              </form>
            </div>
            <a
              href='#'
              data-hide-modal='true'
              className='btn-close'
              onClick={() => action.setIsForgotPassword(false)}
            >
              Close
            </a>
          </div>
        </div>
      </ForgotPasswordContainer>
    </>
  );
};

export default ForgotPassword;
