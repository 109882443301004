import React from 'react';
import SalesProduct from '../../../page/My/Sales/SalesProduct';

const MySalesProducts = ({ moveScroll }) => {
  return (
    <div>
      <SalesProduct moveScroll={moveScroll} />
    </div>
  );
};

export default MySalesProducts;
