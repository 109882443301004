import styled from 'styled-components';

export const PaymentContainer = styled.div`
  .stripe-error {
    color: rgb(105, 115, 134);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
`;
