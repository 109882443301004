import { useState } from 'react';

const useImgLoadCheck = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return { isImageLoaded, handleImageLoad };
};

export default useImgLoadCheck;
