import { MY_REVIEW, MY_REVIEW_EDIT } from '../../../../route/constant';
import { getMainThumb } from '../../../../util/image';
import { deleteReview } from '../../../../action/request';
import { useNavigate } from 'react-router-dom';

const ReviewItem = ({ data }) => {
  const navigate = useNavigate();

  const tryDeleteReview = async oId => {
    try {
      await deleteReview(oId);
      window.location.href = MY_REVIEW;
    } catch (e) {
      alert(e);
    }
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      {data.map(r => {
        return (
          <li key={r.id}>
            <div
              className='mypage-request-list-item'
              data-json="' . htmlspecialchars(json_encode($forJs)) . '"
            >
              <div className='mypage-request-list-item-inner'>
                <div className='tb'>
                  <img src={getMainThumb(r)} alt='' />
                </div>
                <div className='product-meta'>
                  <div className='post-meta'>
                    <h5 className='product-name'>{r.subject}</h5>
                    <div className='post-stat'>
                      <span className='comment'>{r.comment_count}</span>
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <a
                    href=''
                    onClick={e => goTo(e, MY_REVIEW_EDIT + '/' + r.id)}
                    className='btn btn-sm'
                  >
                    Edit
                  </a>
                  <button
                    className='btn btn-sm loading_color'
                    data-action='1401-review-delete'
                    onClick={() => {
                      tryDeleteReview(r.id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </>
  );
};

export default ReviewItem;
