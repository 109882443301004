import React, { useState } from 'react';
import QuillEditor from './QuillEditor';
import Layout from '../layout/Layout';

const Demo = () => {
  const [value, setValue] = useState('');
  return (
    <Layout>
      <div style={{ width: '70%', margin: 50, paddingTop: 30, paddingBottom: 30 }}>
        <QuillEditor value={value} setValue={setValue} />
      </div>
    </Layout>
  );
};

export default Demo;
