import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import UserContext from '../../../context/user/user';
import DesignerSectionDropDown from './DesignerSectionDropDown';
import styled from 'styled-components';
import DesignerSectionContent from './DesignerSectionContent';
import { getDesignerList } from '../../../action/request';
import { useRecoilState } from 'recoil';
import {
  preDesignerListAtom,
  sortStateAtom,
  designerListAtom,
  randomSortStateAtom,
} from '../../../atom/atom';
import { isMobile } from 'react-device-detect';

const DesignerSection = ({ maxWidth }) => {
  const filterDefault = isMobile ? 'Filter' : 'Random';
  const sortDefault = isMobile ? 'Sort' : 'Default';

  const { state: userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const observer = useRef();
  const [sortState, setSortState] = useRecoilState(sortStateAtom);
  const [total, setTotal] = useState(sortState ? sortState?.total : 0);
  const [page, setPage] = useState(sortState ? sortState?.offset : 'page');
  const [randomValue, setRandomValue] = useRecoilState(randomSortStateAtom);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);
  const [sortType, setSortType] = useState(sortState ? sortState?.sortType : 'Random');
  const [sortDir, setSortDir] = useState(sortState ? sortState?.sortDir : 'Default');
  const [preDesignerList, setPreDesignerList] = useRecoilState(preDesignerListAtom);

  const handleSortType = (option, type) => {
    let sortDirValue = '';
    if (type === 'sortType') {
      if (randomValue) {
        const value = option === 'Name' ? 'Ascending' : 'Descending';
        sortDirValue = value;
        setSortDir(value);
      } else {
        const value = option === 'Name' ? 'Ascending' : 'Descending';
        setSortDir(value);
        sortDirValue = value;
      }
      setSortType(option);
      setRandomValue(false);
    } else {
      setSortDir(option);
      sortDirValue = option;
    }

    setSortState({
      offset: 0,
      size: 5,
      sortType: type === 'sortType' ? option : sortType,
      sortDir: sortDirValue,
      total: sortState.total || total,
    });
    if (type === 'sortDir' && sortDir === option) return;
    if (type === 'sortType' && sortType === option) return;
    setDesignerList(false);
    setTotal(0);
    setPage('page');
  };

  const fetchData = async () => {
    try {
      if (!preDesignerList) {
        const pageValue = page === 'page' || sortState.offset === 'page';
        const remaining = total - designerList?.length;

        let newOffset = pageValue ? 0 : page;

        //두번째 무한스크롤 조건 과 random일 때 두번쨰 무한 스크롤 api 통신 시 sortState.size 가 5 보다 작을 경우 증복디자이너가 발견되는 오류가 있습니다
        // 해당 조건일때 offset을 return 받은 size 값으로 지정해주어야 하기 떄문에 해당 조건을 추가 합니다.
        if (designerList?.length === 5 && sortState.size < 5) {
          newOffset = sortState.size;
        }

        const { data } = await getDesignerList(userData?.isLogin, {
          offset: newOffset,
          size: total === 0 || !designerList || remaining >= 5 ? 5 : remaining || 5,
          sortType: sortType,
          sortDir: sortDir,
        });

        const resultDataList = data.data.list;
        const resultPagingInfo = data.data.pagingInfo;
        const resultTotal = data.data.total;
        setTotal(resultTotal);
        setSortState(
          randomValue
            ? { ...resultPagingInfo, randomSort: true, total: resultTotal }
            : { ...resultPagingInfo, total: resultTotal }
        );
        if (!pageValue) {
          setDesignerList([...designerList, ...resultDataList]);
        } else {
          setDesignerList(resultDataList);
        }
      } else {
        setPreDesignerList(false);
      }

      setIsLoading(false);
    } catch (error) {}
  };

  const lastElementRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          const currentLength = designerList?.length;
          const remaining = total - currentLength;
          if (remaining > 0) {
            if (sortState?.randomSort) {
              //없다면 초기값으로 0지정
              const newPage = sortState.offset + 5 >= total ? 0 : sortState.offset + 5;
              setPage(newPage);
              setSortDir(sortState.sortDir);
              setSortType(sortState.sortType);
            } else {
              const prevPage = page === 'page' ? 0 : page;
              setPage(prevPage + 5 >= total ? 0 : prevPage + 5);
            }
          } else {
          }
          setPreDesignerList(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [total, designerList?.length]
  );

  useEffect(() => {
    fetchData();
  }, [page, sortDir, sortType, userData?.isLogin]);

  useEffect(() => {
    if (!designerList) {
      localStorage.removeItem('DesignerPageScrollPosition');
    }
    // 컴포넌트가 마운트될 때 스크롤 위치 복원
    const savedScrollPosition = localStorage.getItem('DesignerPageScrollPosition');

    if (savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseFloat(savedScrollPosition));
      }, 500); // 1초 뒤에 스크롤 위치 복원
    }

    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      localStorage.setItem('DesignerPageScrollPosition', currentScrollPosition);
    };

    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);

    return () => {
      // 스크롤 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = event => {
      const progressModalY = localStorage.getItem('progressModalY');
      if (progressModalY) return;
      window.scrollTo(0, 0);
      setDesignerList(false);
      setSortState(false);
      setTotal(0);
      setRandomValue(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    if (!preDesignerList) {
      window.scrollTo(0, 0);
    }

    return () => {
      setPreDesignerList(false);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <DesignerSectionStyle maxWidth={maxWidth} id='designerSection'>
      <DesignerSectionDropDownWrapper>
        <DesignerSectionDropDown
          handleSortType={handleSortType}
          options={['Name', 'Downloads', 'Newest', 'Models']}
          designerSectionPage={true}
          value='sortType'
          sortValue={randomValue ? filterDefault : sortType}
        />
        <DesignerSectionDropDown
          handleSortType={handleSortType}
          options={['Ascending', 'Descending']}
          value='sortDir'
          sortValue={randomValue ? sortDefault : sortDir}
          typeBtn={sortType === 'Random' || randomValue}
        />
      </DesignerSectionDropDownWrapper>
      {designerList && (
        <DesignerSectionContent designerList={designerList} setDesignerList={setDesignerList} />
      )}
      {!isLoading && <div ref={lastElementRef}></div>} {/* 로딩 중이 아닐 때만 렌더링 */}
    </DesignerSectionStyle>
  );
};

const DesignerSectionDropDownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 50;
  gap: 20px;
  margin-top: 40px;

  @media screen and (max-width: 450px) {
    gap: 10px;
    margin-right: 10px;
  }
`;

export default DesignerSection;

const DesignerSectionStyle = styled.div`
  max-width: ${props => props.maxWidth};
  min-height: 500px;
  margin: 0 auto;
`;
