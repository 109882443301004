import { useEffect } from 'react';
import { InputStyle, LabelStyle } from './styles/manageCheckBoxStyle';

const ManageCheckBox = ({ checkBoxOptions, setCheckBoxOptions }) => {
  const onCheckBoxChange = id => {
    const updatedOptions = checkBoxOptions.map(option => {
      if (option.id === id) {
        return { ...option, value: !option.value };
      } else {
        return option;
      }
    });

    setCheckBoxOptions(updatedOptions);
  };

  return (
    <>
      {Array.isArray(checkBoxOptions) &&
        checkBoxOptions?.map(option => (
          <LabelStyle key={option.id}>
            {option.name}
            <InputStyle
              type='checkbox'
              checked={option.value}
              onChange={() => onCheckBoxChange(option.id)}
            />
          </LabelStyle>
        ))}
    </>
  );
};

export default ManageCheckBox;
