import { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import AboutCeoEn from './component/AboutCeoEn';
import AboutCeoKo from './component/AboutCeoKo';

const AboutCeo = () => {
  const { action } = useContext(LangContext);

  return (
    <>
      <Layout>
        <main className='fab-page-main --no-padding-top'>
          <div id='main2'>{action.isKo() ? <AboutCeoKo /> : <AboutCeoEn />}</div>
        </main>
      </Layout>
    </>
  );
};

export default AboutCeo;
