import styled from 'styled-components';

export const SignupContainer = styled.div`
  font-family: 'Inter', sans-serif !important;
  max-width: 380px;
  margin: 0 auto;
`;

export const LoginContainer = styled.div`
  max-width: 380px;
  margin: 0 auto;
`;

export const H2Style = styled.h2`
  font-family: 'Inter', sans-serif !important;
  font-size: 24px;
  font-weight: 700;
  padding-top: 64px;
  /* margin-bottom: ${props => (props.page === 'login' ? '0px' : '16px')};
   */
`;

export const BoxContainer = styled.div`
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

export const SocialButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: 1px solid #d9d9d9 !important;
  border-radius: 100px;

  &:hover {
    background-color: #ebebeb;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
    }

    p {
      font-family: 'Inter', sans-serif;
      flex-grow: 1;
      font-size: 15px;
      font-weight: 600;
      line-height: 18.15px;
      text-align: center;
      color: #222222;
    }
  }
`;

export const AgreementText = styled.div`
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 1.5;
  margin-bottom: 30px;

  a {
    color: #00bb13;
  }
`;

export const UserEmailSignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    &::placeholder {
      color: #aaaaaa;
    }
  }

  button {
    background: #00de5a !important;
    border: none;
    font-size: 15px;
    border-radius: 100px;
  }

  button:nth-of-type(1) {
    margin-top: 16px;
  }

  button:nth-of-type(2) {
    border: 1px solid #d9d9d9 !important;
    background: #ffffff !important;
    color: #989898 !important;
    display: flex;
    align-items: center;
    padding: 0 16px !important;
    margin-bottom: 168px;
    p {
      flex-grow: 1;
    }

    img {
      width: 8.83px;
      height: 15px;
      top: 16.5px;
      left: 13.31px;
      gap: 0px;
    }
  }
`;

export const HaveAccount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: Inter;

  h4 {
    color: #222222;
  }

  .txt-green {
    color: #222222 !important;
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    text-decoration-color: #222222;
  }
`;

export const SignUpHr = styled.hr`
  margin: ${props => (props.page === 'login' ? '20px 0 30px 0' : '20px 0 16px 0')};
`;

export const LoginTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  justify-content: space-between;
`;

export const NewToFAB365 = styled.div`
  display: flex;
  align-items: center;
  padding-top: 64px;
  gap: 4px;
  font-family: Inter;

  p {
    color: #626262;
    font-size: 13px;
    margin-top: 8px;
  }
  .txt-green {
    color: #222222 !important;
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    text-decoration-color: #222222;
  }
  h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const LoginInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    &::placeholder {
      color: #aaaaaa;
    }
  }
`;

export const LoginButton = styled.button`
  background: #00de5a !important;
  border: none;
  font-size: 15px;
  border-radius: 100px;

  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.15px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-top: 20px;
`;

export const StayLoggedIn = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #ccc;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
  }

  input[type='checkbox']:checked {
    background-color: #222222;
    border-color: #222222;
  }

  /* 체크박스가 체크되었을 때 이미지 표시 */
  input[type='checkbox']:checked::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    background-image: url(/assets/img/login/check.png);
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -38%);
  }

  label {
    font-size: 16px;
    color: #222222;
    margin-left: 3px;
  }
`;

export const BottomBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextStyle = styled.div`
  font-family: Inter;
  font-size: 15px;
  font-weight: 300;
  line-height: 18.15px;
  text-align: left;
  color: #555555;
`;

export const ForgotPasswordContainer = styled.div`
  input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    &::placeholder {
      color: #aaaaaa;
    }
  }

  button {
    background: #00de5a !important;
    border: none;
    font-size: 15px;
    border-radius: 100px;
    font-family: Inter;
    font-weight: 600;
  }

  .modal-box-body {
    max-width: 380px;
    margin: 0 auto;
  }
`;

export const TitleBox = styled.div`
  max-width: 380px;
  margin: 0 auto;
`;
