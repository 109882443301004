import { getPercent } from '../../../util/number';
import { DEFAULT_AVATAR } from '../../../component/avatar/const';
import { requestVote } from '../../../action/request';
import { useContext } from 'react';
import UserContext from '../../../context/user/user';
import ModalContext from '../../../context/modal/modal';
import { ImgStyled } from '../../../styles/ImgStyled';

const RequestDescription = ({ data, onReload }) => {
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);

  const requestVoteBtn = async () => {
    if (userState.isLogin) {
      try {
        await requestVote(data.request_oid);
        alert('voted');
        onReload();
      } catch (error) {}
    } else {
      modalAction.setIsLogin(true);
    }
  };

  return (
    <>
      <div className='store-detail-content-description'>
        <div className='store-detail-content-description-header'>
          <div className='product-info'>
            <h2 className='product-name'>{data.subject}</h2>
            <div className='request-status-box'>
              <dl className='supporter'>
                <dl>Supporter</dl>
                <dd>{data.vote_count}</dd>
              </dl>
              <div className='request-status'>
                <div className='request-status-header'>{data.vote_count + '%'}</div>
                <div className='request-status-body'>
                  <div className='progress'>
                    <span
                      className='bar'
                      style={{ width: getPercent(data.vote_count) + '%' }}
                    ></span>
                  </div>
                </div>
              </div>
              <button
                className='btn btn-green btn-fill btn-full'
                data-action='1203-request-vote'
                onClick={requestVoteBtn}
              >
                Vote/Support this request
              </button>
            </div>
          </div>

          <div className='creator'>
            <div className='creator-header'>
              <div className='profile-pic'>
                <ImgStyled
                  src={data.user_thumbnail ? data.user_thumbnail : DEFAULT_AVATAR}
                  alt=''
                />
              </div>
              <div className='creator-info'>
                <div className='creator-job'>Requester</div>
                <h3 className='creator-name'>{data.user_name}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RequestDescription;
