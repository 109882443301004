const ContactEn = () => {
  return (
    <>
      <div className='page-top about page-top-with-breadcrumb'>
        <div className='container container-lg'>
          <h1 className='page-title'>Contact</h1>
        </div>
      </div>

      <section className='about-section'>
        <div className='contact'>
          <div className='container'>
            <div className='about-description-block'>
              <div className='about-description-block-header'>
                <h2>Contact us</h2>
                <p>
                  Fab365 presents you a<br />
                  new basis of 3D printing lifestyle.
                </p>
              </div>
              <div className='about-description-block-body'>
                <p>
                  We are always open. We want to be with everyone who wants to lead a new 3D
                  printing culture. For any question on business partnership and cooperation with
                  designer, 3D modeler, 3D print seller and 3D printer, please contact us at any
                  time.
                </p>
              </div>
            </div>
            <div className='diagram'>
              <div className='about-contact-info'>
                <div className='about-contact-info-item'>
                  <dl>
                    <dt>Tel</dt>
                    <dd>
                      <a href='tel:+82-2-579-0365'>+82-2-579-0365</a>
                    </dd>
                  </dl>
                </div>
                <div className='about-contact-info-item'>
                  <dl>
                    <dt>Fax</dt>
                    <dd>+82-2-529-0808</dd>
                  </dl>
                </div>
                <div className='about-contact-info-item'>
                  <dl>
                    <dt>E-mail</dt>
                    <dd>
                      <a href='mailto:INFO@FAB365.NET'>info@fab365.net</a>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className='about-description-block about-description-block-2'>
              <div className='about-description-block-header'>
                <h2>Address</h2>
                <p>#302,152, Nonhyeon-ro, Gangnam-gu, Seoul, Zip code 06301</p>
              </div>
              <div className='about-description-block-body'>
                <div className='contact-map'>
                  <iframe
                    title='company location'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.042578832753!2d127.04058595135695!3d37.48332157971438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca6b382915821%3A0xcc0c49da06ac0b1e!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDrj4Tqs6Ey64-ZIOuFvO2YhOuhnCAxNTI!5e0!3m2!1sko!2skr!4v1564368386846!5m2!1sko!2skr'
                    width='100%'
                    height='760'
                    frameBorder='0'
                    style={{ border: 0 }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ContactEn;
