import Layout from "../../component/layout/Layout";
import Menu from "../../component/menu/Menu";

const DownloadSlicerSetting = ({ tab, title }) => {
  return (
    <>
      <Layout>
        <div id="main">
          <Menu tab={tab} title={title} />
          <section className="about-section">
            <div className="help">
              <div className="container">
                <div className="about-description-block">
                  <div className="about-description-block-header">
                    <h2>Help center</h2>
                    <p>How to download slicer setting?</p>
                  </div>
                </div>

                <div className="help-block">
                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>1. You must log in.</p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/need_login.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>2. Go to the profile page.</p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/go_to_profile.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        3. On the profile page, you can save your printer and
                        slicer.
                        <br />
                        Select your 1) printer and 2) slicer both and 3) click
                        the "save printer" button.
                      </p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-slicer-setting/how-to-download-slicer-setting-1.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        FAB365 has only specific settings, not all settings.
                        <br />
                        If the setting does not exist, you'll see the "Your
                        settings not ready" button.
                      </p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-slicer-setting/how-to-download-slicer-setting-2.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        If the setting does exist, you'll see the "Download
                        slice settings" button.
                      </p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-slicer-setting/how-to-download-slicer-setting-3.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        But the slicer setting is an expression of appreciation
                        for paid purchasers.
                        <br />
                        Only paid users can download the slicer setting.
                      </p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-slicer-setting/how-to-download-slicer-setting-4.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        If you have paid order, you can download the slicer
                        setting when you click the "Download slice settings"
                        button.
                      </p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-slicer-setting/how-to-download-slicer-setting-5.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>Extract setting file then you can see.</p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-slicer-setting/how-to-download-slicer-setting-6.png"
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        If you have any other problems, please let us know.{" "}
                        <a href="mailto:help@fab365.net">help@fab365.net</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};
export default DownloadSlicerSetting;
