import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import { numberFormat } from '../../../util/number';
import { useEffect, useState } from 'react';
import { selectSales } from '../../../action/request';
import { ls } from '../../../constant';
import { RETURN_CODE } from '../../../action/constant';
import { formatDesignerNumber } from './utils';
import { MyContents } from '../../../component/designers/designers';
import { BackBtn } from '../../../component/designers/designers';

const SalesDaily = ({ seletecdMonth, setSelectedMore, moveScroll }) => {
  const [totalDownload, setTotalDownload] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [sales, setSales] = useState([]);
  const date = seletecdMonth;
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    const getSelectSales = async () => {
      // console.log('pathName', date);
      try {
        const { data: res } = await selectSales(date);
        if (res.returnCode === RETURN_CODE.C00000) {
          if (res.data.sales) {
            // sales배열 count가 높은순으로 내림차순
            res.data.sales.sort((a, b) => b.count - a.count);
            setSales(res.data.sales);
          }
        }
      } catch (e) {
        alert(e);
      }
    };
    getSelectSales();
  }, []);

  // 합계 구하기
  useEffect(() => {
    let d = 0;
    let e = 0;

    sales.forEach(s => {
      d += parseInt(s.count);
      e += parseFloat(s.earning);
    });
    setTotalDownload(d);
    setTotalEarning(e);
  }, [sales]);

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  const goToMySales = e => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedMore('my');
  };

  return (
    <>
      <>
        <MyContents id='main'>
          <section className='mypage-section mysale'>
            <div className='container'>
              <div className='content-box mysales'>
                <div className='content-box-body'>
                  <BackBtn bottom={30}>
                    <div className='__footer'>
                      <button
                        style={{ cursor: 'pointer' }}
                        className='fab-btn-back'
                        onClick={goToMySales}
                      >
                        <svg
                          width='36'
                          height='42'
                          viewBox='0 0 36 42'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15.2957 30.7041L7.42523 22.8336L24.9996 22.8336L24.9996 19.1669L7.42523 19.1669L15.2957 11.2964L12.7034 8.7041L0.407226 21.0003L12.7034 33.2964L15.2957 30.7041Z'
                            fill='#4200F8'
                          />
                        </svg>{' '}
                        Back
                      </button>
                    </div>
                  </BackBtn>

                  <div className='__grid'>
                    <div className='fab-mypage-mysales-table __fullwidth'>
                      <div className='__header'>
                        <div className='__title-area'>
                          <h4 className='__title'>{moment(date).format('YYYY. MM')}</h4>
                          <p className='__desc'>Shows the number of sales and revenue per month.</p>
                        </div>
                      </div>
                      <div className='__body'>
                        <div className='fab-table-wrapper'>
                          <table>
                            <colgroup>
                              <col width='72%' />
                              <col style={{ width: '14%' }} />
                              <col style={{ width: '14%' }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th className='__txt-left'>Products</th>
                                <th>Download </th>
                                <th>Earnings </th>
                              </tr>
                            </thead>
                            <tbody id='product-list'>
                              {sales.map(s => {
                                return (
                                  <tr key={s.id_item}>
                                    <td className='__txt-left'>{s.name_en}</td>
                                    <td>{formatDesignerNumber(s.count)}</td>
                                    <td>
                                      ${' '}
                                      {formatDesignerNumber(
                                        numberFormat(parseFloat(s.earning).toFixed(2))
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td className='__txt-right'>Total</td>
                                <td>{formatDesignerNumber(numberFormat(totalDownload))}</td>
                                <td>
                                  $ {formatDesignerNumber(numberFormat(totalEarning.toFixed(2)))}
                                </td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      <div className='__footer'>
                        <button
                          style={{ cursor: 'pointer' }}
                          className='fab-btn-back'
                          onClick={goToMySales}
                        >
                          <svg
                            width='36'
                            height='42'
                            viewBox='0 0 36 42'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.2957 30.7041L7.42523 22.8336L24.9996 22.8336L24.9996 19.1669L7.42523 19.1669L15.2957 11.2964L12.7034 8.7041L0.407226 21.0003L12.7034 33.2964L15.2957 30.7041Z'
                              fill='#4200F8'
                            />
                          </svg>{' '}
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </MyContents>
      </>
    </>
  );
};

export default SalesDaily;
