import moment from 'moment';
import { useContext } from 'react';
import {
  PAGE_MY_POINT_HISTORY_TYPE_BUY,
  PAGE_MY_POINT_HISTORY_TYPE_USE,
} from '../../../../context/lang/constant';
import LangContext from '../../../../context/lang/lang';
import { NO_EXPIRE, TYPE_BUY, TYPE_USE_CHECKOUT } from './constant';
const Records = ({ data }) => {
  const { action } = useContext(LangContext);
  return (
    <>
      <table className='table table-plain'>
        <colgroup>
          <col style={{ width: '10rem' }} />
          <col />
          <col style={{ width: '11rem' }} />
          <col style={{ width: '10rem' }} />
          <col style={{ width: '10rem' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Date</th>
            <th>Usage history</th>
            <th>Earning credit</th>
            <th>Used credit</th>
            <th>Expire</th>
          </tr>
        </thead>
        <tbody>
          {data?.map(r => {
            const date = moment(r.date.create * 1000).format('MMM DD YYYY');
            const money = r.amount;
            const expire =
              r.date.expire > NO_EXPIRE ? null : moment(r.date.expire).format('MMM DD YYY');

            let earn = '';
            let used = '';
            let typeMessage = '';
            if (r.type === TYPE_BUY) {
              earn = money;
              typeMessage = action.trans(PAGE_MY_POINT_HISTORY_TYPE_BUY);
            }

            if (r.type === TYPE_USE_CHECKOUT) {
              used = money;
              typeMessage = action.trans(PAGE_MY_POINT_HISTORY_TYPE_USE);
            }
            return (
              <tr key={r.id_point}>
                <td>{date}</td>
                <td>{typeMessage}</td>
                <td>{earn && `${Number(earn).toLocaleString()} credits`}</td>
                <td>{used && `${Number(used).toLocaleString()} credits`}</td>
                <td>{expire}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Records;
