import { useContext } from 'react';
import { PAGE_FOOTER_HEADER_HELP } from '../../context/lang/constant';
import LangContext from '../../context/lang/lang';
import { FOOTER_HELP, PRINT_TIPS_LIST } from '../../route/constant';
import { useNavigate } from 'react-router-dom';

const Menu = ({ title, tab }) => {
  const { action } = useContext(LangContext);
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='page-top about page-top-with-breadcrumb'>
        <div className='container container-lg'>
          <h1 className='page-title'>{title}</h1>
        </div>
      </div>
      <div className='tab-menu'>
        <div className='container'>
          <ul>
            <li className={tab === 'help' ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, FOOTER_HELP)}>
                <span>{action.trans(PAGE_FOOTER_HEADER_HELP)}</span>
              </a>
            </li>
            <li className={tab === 'printTips' ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, PRINT_TIPS_LIST)}>
                <span>Print Tips</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Menu;
