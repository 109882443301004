/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import { REVIEW } from '../../route/constant';

const EarningPoints = () => {
  const navigate = useNavigate();

  const goToReview = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate(REVIEW);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          {' '}
          <div id='main'>
            <div className='container'>
              <div className='terms'>
                <div className='terms-header'>
                  <h1 className='terms-title'>Earn Credit by Writing Reviews</h1>
                </div>
                <div className='terms-body padding-bottom-zero'>
                  <div className='term-block'>
                    <h3>
                      <p>
                        You can earn 100 credit for each review you post on the Fab365 website for
                        the paid models you purchased from Fab365.
                      </p>
                    </h3>
                  </div>
                  <div className='term-block'>
                    <h3>
                      <p>Please keep the following notes in mind when posting reviews:</p>
                    </h3>
                  </div>
                  <div className='term-block'>
                    <p>• Only post reviews for paid models that you have purchased.</p>
                    <p>• Do not post a review for multiple models with one photo.</p>
                    <p>• Do not post a review multiple times for the same model.</p>
                    <p>• Do not repost after deleting an existing post.</p>
                    <p>• Do not post a review using other people's photos.</p>
                    <p>
                      • Credit will be awarded within 7 days after posting a review, but it may take
                      a few extra days depending on our circumstances.
                    </p>
                  </div>

                  <div className='term-block'>
                    <h3>
                      <p className='center' style={{ width: '0%' }}>
                        <button className='btn btn-green-box'>
                          <a className='font-white' href='' onClick={goToReview}>
                            Write Review
                          </a>
                        </button>
                      </p>
                    </h3>
                  </div>
                  <p>
                    {'<'}&nbsp;This policy may change according to our circumstances, and we will
                    inform you of the changes on our homepage or newsletter.&nbsp;{'>'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EarningPoints;
