import React, { useEffect, useState } from 'react';
import { getItemdetailOthers, getItemdetailRelated } from '../../../action/request';
import axios from 'axios';
import styled from 'styled-components';
import { Webpage2StylesContainer } from '../../../styles/Webpage2Styles';
import ItemDetailSwiper from './ItemDetailSwiper';
import { useRecoilState } from 'recoil';
import { likeAtom } from '../../../atom/atom';

const ItemDetailRelatedModel = ({ id, state, studio, categoryName, scrollRef }) => {
  const [dataOthers, setDataOthers] = useState();
  const [dataRelated, setDataRelated] = useState();
  const [likeState, setLikeState] = useRecoilState(likeAtom);
  const [dataValue, setDataValue] = useState();
  const fecthData = async () => {
    const modelsOthers = await getItemdetailOthers(id, state);
    const modelsRelated = await getItemdetailRelated(id, state);
    const [{ data: othersData }, { data: relatedData }] = await axios.all([
      modelsOthers,
      modelsRelated,
    ]);
    setDataOthers(othersData.data.items);
    setDataRelated(relatedData.data.items);
  };

  useEffect(() => {
    fecthData();
  }, [id, state]);

  const options_others = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 25,
    loop: true,

    navigation: {
      prevEl: `.buttonSwiperPrevS4${1}`,
      nextEl: `.buttonSwiperNextS4${1}`,
    },
    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
      1180: {
        slidesPerView: 4,
      },
    },
  };

  const options_related = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 25,
    loop: true,

    navigation: {
      prevEl: `.buttonSwiperPrevS4${2}`,
      nextEl: `.buttonSwiperNextS4${2}`,
    },
    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
      1180: {
        slidesPerView: 4,
      },
    },
  };

  function checkLikeState() {
    const data = dataValue === 1 ? dataOthers : dataRelated;

    const newCards = data?.map(card =>
      Number(card.product_id) === Number(likeState.id)
        ? {
            ...card,
            product_like_count: likeState.count,
            my_like: likeState.value,
          }
        : card
    );
    if (dataValue === 1) {
      setDataOthers(newCards);
    } else {
      setDataRelated(newCards);
    }
  }

  useEffect(() => {
    dataValue && checkLikeState();
  }, [likeState, dataValue]);
  return (
    <RelatedModel>
      <Webpage2StylesContainer>
        <div className='other-wrap'>
          <ItemDetailSwiper
            title={'Designer’s other models'}
            index={1}
            options={options_others}
            cards={dataOthers}
            setCards={setDataOthers}
            setLikeState={setLikeState}
            setDataValue={setDataValue}
            categoryName={categoryName}
            studio={studio}
            id={id}
          />
        </div>
        <div className='related-wrap'>
          <ItemDetailSwiper
            title={'Related models'}
            index={2}
            options={options_related}
            cards={dataRelated}
            setCards={setDataRelated}
            setLikeState={setLikeState}
            setDataValue={setDataValue}
            categoryName={categoryName}
            studio={studio}
            id={id}
          />
        </div>
      </Webpage2StylesContainer>
    </RelatedModel>
  );
};

export default ItemDetailRelatedModel;

const RelatedModel = styled.div`
  border-top: 1px solid #d8d8d8;
  padding-top: 60px;
  padding-bottom: 60px;
  .slide img {
    width: 100%;
    height: 100%;
  }

  .other-wrap {
    margin-bottom: 50px;
  }

  .related-wrap {
    border-top: 1px solid #d8d8d8;
    padding-top: 50px;
  }
`;
