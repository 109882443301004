import React, { useContext, useEffect } from 'react';
import Layout from '../../component/layout/Layout';
import Pagination from '../Category/component/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getFreeLimit, getSearch } from '../../action/request';
import { useState } from 'react';
import Price from '../../component/Price/Price';
import UserContext from '../../context/user/user';
import { ImgStyled } from '../../styles/ImgStyled';
import { useRecoilState } from 'recoil';
import { paginationAtom } from '../../atom/atom';
import styled from 'styled-components';

function Search() {
  const location = useLocation();
  const searchType = location?.search?.split('=')[0]?.slice(1);
  const search = decodeURI(location?.search?.split('=')[1]?.split('&')[0]);
  const [limit, setLimit] = useState({});
  const { state } = useContext(UserContext);
  const navigate = useNavigate();

  const [searchItems, setSearchItems] = useState();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  // const [freeItemLimitDto, setFreeItemLimitDto] = useState({});
  const [index, setIndex] = useRecoilState(paginationAtom);
  const prevScroll = localStorage.getItem('scroll');

  const pageChange = value => {
    setPage(value);
    setIndex(value);
    localStorage.setItem('scroll', 0);
  };
  const pageConfirm = value => {
    setPage(value);
    setIndex(value);
  };

  const onGoItemDetail = (e, itemNo) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/items/${itemNo}`, { state: page });
    localStorage.setItem('scroll', window.scrollY);
  };

  const goToStore = e => {
    e.preventDefault();
    e.stopPropagation();

    navigate('/categories');
  };

  const fetch = async () => {
    try {
      const { data } = await getSearch(searchType, search, index === false ? page : index);
      setTimeout(() => window.scrollTo({ top: Number(prevScroll) }), 300);
      if (data.data.keyword) {
        setSearchItems(data.data.keyword);
      } else {
        setSearchItems(data.data.tag);
      }
      // setFreeItemLimitDto(data.data.freeItemLimitDto);
      setPageCount(data.data.count);
      const { data: resFreeLimit } = await getFreeLimit();
      setLimit(resFreeLimit.data);
    } catch (e) {}
  };

  // 로그인 상태 변경때마다 fetchData() 호출
  useEffect(() => {
    if (index !== false) {
      setPage(index);
    }
    fetch();
  }, [page, search, searchType, state?.isLogin]);

  return (
    <Layout>
      <div id='main'>
        <div className='page-top page-top-lg'>
          <div className='container container-lg'>
            <h1 className='page-title'>Explore</h1>
          </div>
        </div>

        {searchItems && (
          <section className='store-collection'>
            <div className='container'>
              {searchItems?.length !== 0 ? (
                <div className='content-section'>
                  <div className='content-section-header'>
                    <h2>{searchType === 'tag' ? `#${search}` : `Results for "${search}"`}</h2>
                  </div>
                  <div className='product-list product-list-3'>
                    <ul>
                      {searchItems?.map(item => (
                        <li key={item.id}>
                          <div className='product-list-item'>
                            <Link
                              to={`/items/${item.id}`}
                              className='tb'
                              onClick={e => onGoItemDetail(e, item.id)}
                            >
                              <ImgWrap>
                                <ImgStyled src={item?.listImg} alt={item.name} className='search' />
                                <ImgStyled
                                  src={item?.listImg2 || item?.listImg}
                                  alt={item.name}
                                  className='over'
                                />
                              </ImgWrap>
                            </Link>
                            <div className='product-desc search-result-product-desc'>
                              <h3 className='product-name'>{item.name}</h3>
                              <p className='product-category'>{item.shortDesc}</p>
                              {/* {getItemListPriceStr(item, limit)} */}
                              {Price(item, limit)}
                              <span className='post-stat'>
                                <span className='hearted'>{item.product_like_count}</span>
                                <span className='comment'>{item.product_comment_count}</span>
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className='product-list-nothing'>
                  <h2>
                    <strong>Sorry!</strong>
                  </h2>
                  <p>We couldn’t find any results.</p>
                  <a href='' className='txt-purple' onClick={goToStore}>
                    Go to store <i className='icon'></i>
                  </a>
                </div>
              )}
            </div>

            <Pagination
              page={page}
              total={Math.ceil(pageCount / 15)}
              onpagechange={pageChange}
              onconfirm={pageConfirm}
            />
          </section>
        )}
      </div>
    </Layout>
  );
}

export default Search;

const ImgWrap = styled.div`
  aspect-ratio: 1/1;
  img {
    width: 100%;
    height: 100%;
  }
`;
