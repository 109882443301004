import styled from 'styled-components';
import { goTo } from '../section/util';
import { Navigate } from 'react-router-dom';

const ViewAllButton = ({ handleClick }) => {
  return (
    <ViewAllButtonStyle onClick={handleClick}>
      <p>VIEW ALL</p>
    </ViewAllButtonStyle>
  );
};

export default ViewAllButton;

const ViewAllButtonStyle = styled.div`
  display: block;
  float: right;
  background: url(/assets/img/2.0WebImg/ui/arrow-view-all.png) right center / 16px 16px no-repeat;
  height: 28px;
  padding: 0px 20px 0px 0px;
  line-height: 28px;
  font-size: 16px;
  text-align: right;
  font-weight: 600;
  color: rgb(31, 190, 5) !important;
  cursor: pointer;
`;
