import { useNavigate } from 'react-router-dom';
import { ABOUT } from '../../../route/constant';

const PhilosophyKo = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <section
        className='fab-page-top'
        style={{
          backgroundImage:
            "url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/philosophy/top-img.jpg')",
        }}
      ></section>

      <section className='fab-page-section' id='fabPageAboutPhilosophy'>
        <div className='container fab-page-container'>
          <div className='--row'>
            <h3 className='--title --txt-xxl'>
              FAB365
              <br /> <strong>문화</strong>
            </h3>
            <div className='--desc'>
              <blockquote className='--quote --txt-lg --mb-50 --mb-30--m'>
                "오직 3D 프린팅에 미친 사람들, <br className='--when-mobile' />
                3D 프린팅을
                <br className='--when-desktop' />
                가장 많이 사용하는
                <br className='--when-mobile' />
                사람들, 3D 모델을 가장 잘 설계하는
                <br />
                <strong>
                  전문가들이 모여 끊임없이 <br className='--when-mobile' />
                  연구하고 있습니다
                </strong>
                ."
              </blockquote>
              <p className='--font-basic --mb-100 --mb-60--m'>
                3D 프린팅은 3D 프린터의 머신에 대한 이해뿐만 아니라, 기기를 사용하면서 발생하는
                수많은 변수들을 경험하고 이해하는 노하우가 필요합니다. 또한 3D 모델 파일과
                소프트웨어의 호환성에 따라 출력 품질이 미세하게 달라지게 됩니다. 이렇게 하드웨어와
                소프트웨어를 완벽하게 이해하고 3D 모델을 설계하는 것이 매우 중요합니다. 따라서
                FAB365는 세계 최고 품질의 3D 모델을 만들기 위해 3D프린팅과 관련한 최고의 전문가들이
                모여 연구하고 있습니다. 우리가 전세계의 유저들이 만족할 수 있는 3D 모델을 가장 잘
                만들 수 있는 이유입니다.
                <br />
                <br />
                우리는 비단 좋은 품질의 3D 모델을 설계할 뿐만 아니라 전세계의 다양한 사람들이
                선호하고 3D 프린팅하고 싶은 아이템을 계속해서 출시하고 있습니다. 이것은 바로 우리가
                이 분야의 가장 오래된 매니아이기 때문입니다. 호기심이 많고 상상하는 것을 좋아하는
                사람들이 모여 있기 때문에 새로운 컨셉과 신기한 아이템에 열광하는 유저들의 생각을 잘
                이해할 수 있습니다. 오로지 유저의 입장에서 그들이 무엇을 원하고 무엇을 출력하고 싶고
                무엇을 사고 싶은지 고민하고 연구합니다. FAB365가 출시한 수많은 아이템들이
                전세계적으로 초히트를 치는 이유이기도 합니다. 우리 팀 모두가 바로 밀리터리 매니아,
                자동차 매니아, 로보트 매니아, 메카닉 매니아입니다.
                <br />
                <br />
                한번 빠지면 미치도록 연구하는 태도, 문제를 해결할 때까지 물고 늘어지는 집념, 세계
                최고의 3D 프린팅 컨텐츠를 만드는 FAB365의 핵심 문화입니다.
              </p>
              <a href='' className='--more' onClick={e => goTo(e, ABOUT)}>
                <span className='--font-basic'>← Back</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PhilosophyKo;
