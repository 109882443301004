import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

const ItemIframe = ({ iframeSrc, body }) => {
  const renderedIframes = useMemo(() => {
    if (iframeSrc && Array.isArray(iframeSrc) && !body?.includes('<p>')) {
      return iframeSrc.map(i => (
        <iframe
          key={uuid()}
          width='100%'
          height='472'
          style={{ maxWidth: '840px' }}
          src={i}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        ></iframe>
      ));
    }
    return null;
  }, [iframeSrc, body]); // iframeSrc와 body가 변경될 때만 재렌더링

  return <div>{renderedIframes}</div>;
};

export default ItemIframe;
