import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

export const TippyStyle = styled(Tippy)`
  line-height: 1.4em;
  font-size: 14px;
  padding: 0.2rem;
  margin-top: ${props => (props.isMobile ? '0' : '45px')};
`;

export const ItemPriceStyle = styled.div`
  .font-nanumsquare-price {
    font-family: 'Outfit', sans-serif;
    font-size: ${props => props.size || 32}px;
    font-weight: 500;
  }
`;

export const NewTippyStyle = styled(TippyStyle)`
  li {
    list-style: none;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TextContainer = styled.div`
  /* background-color: #e0e0e0; */
  width: 100%;
  height: 100%;
`;

export const TicketCountStyle = styled.div`
  color: ${props => (props.ticket ? '#FD5757' : 'black')};
  background-color: #f3f6f9;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  font-family: 'Pretendard', sans-serif;
  width: 161px;
  display: flex;
  padding: 11px;
  margin: 15px 0;
  border: 5px;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 0.875rem;
  img {
    width: 56px;
  }
  p {
    span {
      font-weight: 600;
    }
  }
`;

export const RewardTicketStyle = styled.div`
  color: #353535;
  font-size: 20px;
  font-weight: 300;

  font-family: 'Outfit', sans-serif;

  span {
    font-weight: 500;
    font-size: 32px;
    margin-right: 10px;
  }
`;

export const FreeItemStyle = styled.div`
  color: #353535;
  font-size: 20px;
  font-weight: 300;

  font-family: 'Outfit', sans-serif;
  margin-bottom: 70px;

  span {
    font-weight: 500;
    font-size: 32px;
    margin-right: 10px;
  }
`;
export const GetThisModel = styled.p`
  font-family: 'Pretendard', sans-serif; // 폰트 패밀리 직접 설정
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 24px;

  @media (max-width: 640px) {
    color: #535353;
  }
`;

export const SignUpGet = styled.p`
  font-family: 'Outfit', sans-serif;
  font-size: 32px;
  font-weight: 400;
  color: #000;
`;

export const TicketContainer = styled.div`
  display: flex;
  opacity: ${props => (props.isOwn ? 0 : 1)};
  align-items: center;
  gap: 10px;

  .fab-btn-i--tooltip-f {
    img {
      width: 16px;
    }
  }
`;

export const Owned = styled.div`
  font-family: 'Outfit', sans-serif !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  text-align: left !important;
  color: #000 !important;
`;
