import { useContext, useEffect } from 'react';
import LangContext from '../../../context/lang/lang';
import {
  ABOUT,
  ABOUT_CEO,
  ABOUT_CO_CEO,
  ABOUT_PHILOSOPHY,
  ABOUT_SERVICE,
} from '../../../route/constant';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

const AboutEn = () => {
  const { action } = useContext(LangContext);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  });

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <section className='fab-page-tabmenu'>
        <div className='container'>
          <div className='fab-page-tabmenu-inner'>
            <ul>
              <li>
                <a href='' onClick={e => goTo(e, ABOUT_SERVICE)}>
                  <span>SERVICE</span>
                </a>
              </li>
              <li className='--active'>
                <a href='' onClick={e => goTo(e, ABOUT)}>
                  <span>ABOUT</span>
                </a>
              </li>
            </ul>
            <div className='fab-langs'>
              <a
                href='#'
                className='--active locale-en'
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('en', 'service');
                }}
              >
                EN
              </a>{' '}
              <span className='--split'>/</span>
              <a
                href='#'
                className='locale-ko'
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('ko', 'service');
                }}
              >
                KR
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section' id='fabPageAboutTopDesc'>
        <div className='container fab-page-container'>
          <div className='--row'>
            <div className='--title'>
              <h2 className='--txt-xxl'>
                FAB365 <br />
                <strong>Vision</strong>
              </h2>
            </div>
            <div className='--desc'>
              <blockquote className='--txt-lg --mb-40'>
                “In the future, there will be <br className='--when-mobile' />
                an era in which anyone can easily and quickly implement 3D printing for whatever
                they imagine.
                <br />
                <br />
                FAB365’s goal is{' '}
                <strong>
                  to create the most high quality 3D model to satisfy the customer’s needs of 3D
                  printing where they can print 3D models anywhere and anytime in the world.
                </strong>
                ”
              </blockquote>
              <p className='--font-basic'>
                FAB365 will willingly invite an outstanding designer to meet customer’s needs and we
                are willing to pay one the high value from all around the world. FAB365 will create
                an environment where a 3D designer can constantly produce the high quality design
                and a 3D printing user can enjoy the high quality design at a reasonable price. And
                eventually, FAB365 will grow as a platform where a user and designer can interact
                actively to communicate with each other.
                <br />
                <br />
                With the advent of 3D printing, a new era has arrived where an individual can be
                able to freely use their creativity. In fact, over the past 10 years, many 3D
                designers have attempted for fun experiments, thereby many works have been created
                and shared freely. Moreover, due to the cheap and mass production of 3D printers, a
                totally new era is opening now. To dive into this new era, FAB365 is going to be the
                epicenter of this change and want to grow as a leader in 3D printer contents.
                <br />
                <br />
                We've been experimenting over the years to find a design that will satisfy our 3D
                printer user demand. In the process, we gained vast amounts of information and
                high-level design know-how, and we knew what design our customers wanted. The
                differentiated designs of FAB365 were born through this process, and we will
                continue to develop content and invest in technology to be loved by thousands of
                users around the world in the future.
              </p>
            </div>
          </div>
        </div>
        <img
          src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/img1.jpg'
          alt=''
        />
      </section>

      <section className='fab-page-section' id='fabPageAboutFeatures'>
        <div className='container fab-page-container'>
          <article className='fab-about-feature-box' data-aos='fade-up'>
            <div className='fab-about-feature-box-inner'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/i1.svg'
                alt=''
                className='--icon'
              />
              <h3 className='--title'>
                <strong>Service with the most items</strong> <br />
                that users want
              </h3>
              <p className='--desc --font-basic --compact'>
                We are creating items that users most want through analysis{' '}
                <br className='--when-desktop' />
                of the characteristics of early adopters using 3D printing and{' '}
                <br className='--when-desktop' />
                big data of 3D models around the world. In addition, through active communication
                with users such as requesting items and solving printing problems, we will
                constantly release the items that users want the most.
              </p>
            </div>
          </article>
          <article className='fab-about-feature-box' data-aos='fade-up'>
            <div className='fab-about-feature-box-inner'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/i2.svg'
                alt=''
                className='--icon'
              />
              <h3 className='--title'>
                <strong>Leading technology</strong> <br />
                in 3D model design
              </h3>
              <p className='--desc --font-basic --compact'>
                We will build technical know-how and design ability to design content that prints
                well not only for the most popular FDM, but also for multicolor printers, DLP
                printers, and metal printers. <br className='--when-desktop' />
                And based on these technologies, we want to become the best company in the world in
                creating designs optimized <br className='--when-desktop' />
                for 3D printing.
              </p>
            </div>
          </article>
          <article className='fab-about-feature-box' data-aos='fade-up'>
            <div className='fab-about-feature-box-inner'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/i3.svg'
                alt=''
                className='--icon'
              />
              <h3 className='--title'>
                A platform where <br className='--when-desktop' />
                <strong>the top 3D designers</strong>
                participate
              </h3>
              <p className='--desc --font-basic --compact'>
                We will help 3D designers from all over the world sell{' '}
                <br className='--when-desktop' />
                their great 3D models on our platform. Users can purchase{' '}
                <br className='--when-desktop' />
                great quality 3D models at reasonable prices, and 3D designers have the opportunity
                to sell their designs worldwide.
              </p>
            </div>
          </article>
        </div>
      </section>

      <section className='fab-page-section --dark' id='fabPageAboutPresent'>
        <div className='container fab-page-container'>
          <h3 className='--title --txt-xxl'>
            FAB365 <br />
            <strong>Now</strong>
          </h3>
          <img
            src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/img2.jpg'
            alt=''
            className='--img'
          />
          <blockquote className='--quote --txt-lg --mb-50 --mb-30--m'>
            "
            <strong>
              Users from over 200 countries
              <br className='--when-desktop' />
              around the world
            </strong>{' '}
            are 3D printing
            <br className='--when-desktop' />
            with the design of FAB365."
          </blockquote>
          <p class='--desc --font-basic'>
            Although FAB365 was started in South Korea, it is now establishing itself as a global
            service
            <br class='--when-desktop' />
            used by more than 140,000 people in 200 countries around the world. In particular,{' '}
            <br class='--when-desktop' />
            users from the advanced 3D printing countries such as the US, UK, and France download
            content every day,
            <br class='--when-desktop' /> and the high-satisfaction reviews show high trust in
            FAB365.
          </p>
        </div>
      </section>

      <section className='fab-page-section' id='fabPageAboutStats'>
        <div className='container fab-page-container'>
          <div className='--grid'>
            <div className='--col'>
              <article className='fab-about-stat-box' data-aos='fade-up'>
                <div className='fab-about-stat-box-inner'>
                  <h3 className='--title --same-height --txt-lg'>
                    <strong>
                      200 countries, <br />
                      140,000 users
                    </strong>
                  </h3>
                  <p class='--font-basic'>
                    Currently, more than 140,000 registered users in 200 countries around the world
                    are enjoying the best 3D printing content of FAB365. We have about 100k
                    Instagram subscribers and about 130k YouTube subscribers.
                  </p>
                </div>
                <img
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/sns.png'
                  className='--diagram'
                />
              </article>
            </div>
            <div className='--col'>
              <article className='fab-about-stat-box' data-aos='fade-up'>
                <div className='fab-about-stat-box-inner'>
                  <h3 className='--title --same-height --txt-lg'>
                    <strong>
                      Triple growth <br />
                      every year
                    </strong>
                  </h3>
                  <p className='--font-basic'>
                    The number of FAB365 users worldwide is growing rapidly and has tripled annually
                    since 2018.
                  </p>
                </div>
                <img
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/graph.svg'
                  className='--diagram'
                />
              </article>
            </div>
            <div className='--col'>
              <article className='fab-about-stat-box' data-aos='fade-up'>
                <div className='fab-about-stat-box-inner'>
                  <h3 className='--title --txt-lg'>
                    Internationally{' '}
                    <strong>
                      recognized <br />
                      and highly praised
                    </strong>{' '}
                    <br />
                    by 3D printing <br className='--when-mobile' />
                    Youtube channels
                  </h3>
                  <p className='--font-basic'>
                    3D printing YouTube channels from around the world, such as 3D Printing Nerd
                    (580k), Uncle Jessi (413k),
                    <br className='--when-desktop' />
                    WildRoseBuilds (112k), Break'n'Makes (70k), who have voluntarily introduced and
                    praised the design of FAB365.
                  </p>
                  <div className='--youtubers'>
                    <figure>
                      <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/tb2.jpg' />
                      <figcaption>
                        <span className='--font-basic'>3D Printing Nerd</span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/tb3.jpg' />
                      <figcaption>
                        <span className='--font-basic'>WildRoseBuilds</span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/tb1.jpg' />
                      <figcaption>
                        <span className='--font-basic'>Break’n’Makers</span>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section' id='fabPageAboutPhilosophy'>
        <div className='container fab-page-container'>
          <div className='--row'>
            <h3 className='--title --txt-xxl'>
              FAB365 <br />
              <strong>Culture</strong>
            </h3>
            <div className='--desc'>
              <blockquote class='--quote --txt-lg --mb-50 --mb-30--m'>
                "The experts who know 3D models very well, <br class='--when-desktop' />
                people
                <br class='--when-mobile' />
                who are only crazy about 3D printing and people <br class='--when-mobile' /> who use
                3D printing the most,
                <br class='--when-mobile' />
                <strong> are constantly researching together</strong>”
              </blockquote>
              <a href='' className='--more' onClick={e => goTo(e, ABOUT_PHILOSOPHY)}>
                <span className='--font-basic'>View more →</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section' id='fabPageAboutFounders'>
        <div className='container fab-page-container'>
          <h2 className='--txt-xxl --txt-center --mb-70 --mb-40--m'>
            <strong>The founder</strong> of FAB365
          </h2>
          <div className='--founders'>
            <a href='' className='--founder' onClick={e => goTo(e, ABOUT_CEO)}>
              <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/ceo1.jpg' />
              <div className='--desc'>
                <h4 className='--txt-lg'>
                  <strong>Goo Sang-kwon</strong>
                </h4>
                <p class='--font-basic'>co-CEO & Director of Content Development</p>
              </div>
            </a>
            <a href='' className='--founder' onClick={e => goTo(e, ABOUT_CO_CEO)}>
              <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/ceo2.jpg' />
              <div className='--desc'>
                <h4 className='--txt-lg'>
                  <strong>Lee Jong Han</strong>
                </h4>
                <p class='--font-basic'>co-CEO & Director of Platform services</p>
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutEn;
