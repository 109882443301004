import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import { cardSwiperOption } from '../swiperOptions';
import { useNavigate } from 'react-router-dom';
import { CATEGORIES } from '../../route/constant';
import { getHomeInfoDetail } from '../../action/request';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import ItemCard from '../../component/common/itemCards/ItemCard';

function CardSwiper() {
  const navigate = useNavigate();
  const [cardsData, setCardData] = useState([]);

  useEffect(() => {
    const FetchData = async () => {
      //TODO 원하는 cardItemData 입력
      const itemData = await getHomeInfoDetail('collection1');
      setCardData(itemData.data.data.items);
    };
    FetchData();
  }, []);

  const goToAllProductsPage = e => {
    //원하는 라우터 페이지 입력
    navigate(CATEGORIES, { state: 'popular' });
  };

  return (
    <Webpage2StylesContainer>
      <div className='sectionTypeB' id='cardSwiper'>
        <div className='centerWrap'>
          <div className='titleRow'>
            <div className='title'></div>
          </div>
          <div className='secondRow'>
            <Button
              href='#'
              className='viewAll'
              id='viewAll'
              onClick={e => {
                goToAllProductsPage(e, e.currentTarget.id);
              }}
            >
              <span>VIEW ALL</span>
            </Button>
          </div>

          {/* 배너 */}
          <div className='bannersWrap'></div>

          <div className='swiperBigWrap card-swiper'>
            <Swiper
              {...cardSwiperOption}
              modules={[Navigation]}
              className='sectionASwiper sectionASwiper004'
            >
              <div className='swiper-wrapper'>
                <>
                  {cardsData.map(itemData => {
                    return (
                      <SwiperSlide>
                        <ItemCard data={itemData} />
                      </SwiperSlide>
                    );
                  })}
                </>
              </div>
            </Swiper>
            <Button
              tabIndex='0'
              type='button'
              className={`buttonSwiperPrev buttonSwiperPrevS4 card-swiper`}
              title='prev'
              opacity={1}
            ></Button>
            <Button
              tabIndex='0'
              type='button'
              className={`buttonSwiperNext buttonSwiperNextS4 card-swiper`}
              title='next'
              opacity={1}
            ></Button>
          </div>
        </div>
      </div>
    </Webpage2StylesContainer>
  );
}

export default CardSwiper;

const Button = styled.button`
  opacity: ${props => props.opacity};
`;
