import { useContext, useEffect, useState } from 'react';
import LangContext from '../../../context/lang/lang';
import { ABOUT, ABOUT_SERVICE } from '../../../route/constant';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { allowScroll, preventScroll } from '../../../util/commentImageViewer';

const ServiceEn = ({ isMobile }) => {
  const { action } = useContext(LangContext);
  const navigate = useNavigate();

  const toggleDetail = e => {
    e.stopPropagation();
    e.preventDefault();
    let classes;
    if (e.target.tagName === 'SPAN') {
      classes = e.target.parentElement.parentElement.parentElement.parentElement.classList;
    } else {
      classes = e.target.parentElement.parentElement.parentElement.classList;
    }
    let isHas = classes.value.split(' ').find(c => c === '--open');
    if (isHas) {
      if (isMobile) allowScroll();
      classes.remove('--open');
    } else {
      if (isMobile) preventScroll();
      classes.add('--open');
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  });

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <section className='fab-page-tabmenu'>
        <div className='container'>
          <div className='fab-page-tabmenu-inner'>
            <ul>
              <li className='--active'>
                <a href='' onClick={e => goTo(e, ABOUT_SERVICE)}>
                  <span>SERVICE</span>
                </a>
              </li>
              <li>
                <a href='' onClick={e => goTo(e, ABOUT)}>
                  <span>ABOUT</span>
                </a>
              </li>
            </ul>
            <div className='fab-langs'>
              <a
                href=''
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('en', 'service');
                }}
                className='--active locale-en'
              >
                EN
              </a>{' '}
              <span className='--split'>/</span>
              <a
                href=''
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('ko', 'service');
                }}
                className='locale-ko'
              >
                KR
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section' id='fabPageServiceTopDesc'>
        <div className='container fab-page-container'>
          <div className='fab-page-service-top-desc --mb-80 --mb-150--m'>
            <div className='--desc'>
              <h2 className='--logo'>
                <img
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/common/logo-black.svg'
                  alt='FAB 365'
                />
              </h2>
              <p className='--txt-xxl'>
                <strong>
                  Creative & Innovative
                  <br />
                  3D Printing Model Marketplace
                </strong>
              </p>
              <p className='--txt-lg --mb-5'>
                with global users from <br />
                over 150 countries.
              </p>
            </div>
            <div className='--model --model1'>
              <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/model1.svg' />
            </div>
          </div>
          <div className='fab-page-service-top-desc fab-page-service-top-desc2'>
            <div className='--desc'>
              <p className='--txt-lg --mb-60'>
                FAB365 is a 3D design platform <br className='--when-desktop' />
                that provides
                <strong>
                  various kinds of 3D models
                  <br className='--when-desktop' />
                  with great design and high level quality
                </strong>{' '}
                <br className='--when-desktop' />
                to people who enjoy 3D printing.
              </p>
              <p className='--font-basic'>
                Have you ever been stressed from difficult and complicated printing from a 3D
                printer you purchased, or had a hard time finding the right data so you failed in
                printing? 3D models at FAB365 have innovative design and high quality which is a
                whole new level from other free datas that are shared.
                <br />
                <br />
                We target the best quality with satisfying printouts from different kinds of most
                typical 3d printers with proven design data. Experience a new level of 3D printing
                through FAB365 high quality contents.
              </p>
            </div>
            <div className='--model --model2'>
              <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/model2.svg' />
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section fab--bg-dark-gray' id='fabPageServiceDesc'>
        <div className='container fab-page-container'>
          <h2 className='--txt-xxl --txt-center --mb-70 --mb-40--m'>
            <strong>High quality design</strong> of FAB365.
          </h2>

          <article className='fab-page-card --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  Easy and fun
                  <br /> <strong>foldable design</strong>
                </h3>
                <p className='--font-basic'>
                  With FAB365’s model, the shape is complete after simply folding it just like
                  origami.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div className='fab-page-card-img-inner'>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img1.jpg'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    A foldable design, that can be folded at once instantly to complete it after 3D
                    printing, is the representative design of FAB365, which is not like any other
                    typical plastic model that needs many parts to assemble.
                    <br />
                    <br />
                    The shape is finished after just a few folds of spreaded printout. Also a moving
                    and working part without assembling them right after printing, doubles the fun
                    of 3D printing.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --dark --black --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  Very precise and
                  <br /> <strong>amazing detail</strong>
                </h3>
                <p className='--font-basic'>
                  FAB365 model takes pride in the ultimate detail that enthusiasts admire.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div className='fab-page-card-img-inner'>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img2.jpg'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    We pride in the surprising details which enthusiasts admire from all designs
                    from FAB365. Because we develop products with extensive research and historical
                    evidence, we never miss even a little flaw.
                    <br />
                    <br />
                    It is our main goal to make users happy when they complete printing and
                    assembling.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --green --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  <strong>Various genres</strong>
                  <br className='--when-mobile' /> for 3D model enthusiasts
                </h3>
                <p className='--font-basic'>
                  FAB365 model is based on researching and understanding the exact needs of people
                  who love 3D models.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img3.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    We truly understand people who love 3D models. Robot enthusiast, automobile
                    enthusiast, military enthusiast, SF movie enthusiast… We research contents that
                    the enthusiasts want to print with 3D printers and develop an amazing design to
                    launch.
                    <br />
                    <br />
                    We are going to release items with different kinds of genres that 3D printer
                    users want to own.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <div className='--mt-160 --mb-160 --mt-100--m --mb-100--m'>
            <img
              src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/imgs.png'
              alt=''
              className='--img1'
              data-aos='fade-up'
            />
          </div>

          <h2 className='--txt-xxl --txt-center --mb-70 --mb-40--m'>
            <strong>High quality design</strong> of FAB365
          </h2>

          <article className='fab-page-card --dark --blue --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  Innovative design
                  <br /> with <strong>no support</strong>
                </h3>
                <p className='--font-basic'>
                  FAB365 model provides design that doesn’t generate support through special design
                  know-how.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img4.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    The biggest problem in 3D printing is the necessity of complex support and
                    removal of complicated support. We provide a design without the support with
                    special design knowhow.
                    <br />
                    <br />
                    It reduces the material and printing time, and an unnecessary post process of
                    removing the support. Aslo, a design without the support not only saves the
                    material, but also is another way to save the earth because it doesn’t produce
                    wastes.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --dark --black --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  Revolutionary
                  <br /> <strong>print-in-place technology</strong>
                </h3>
                <p className='--font-basic'>
                  FAB365 model connects several parts through different bonding structures that
                  minimizes the number of files and decreases the number of printing significantly.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img5.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    Printing all the parts every time is like assembling a complicated plastic
                    model. We applied Print-in-place technology to solve the inconvenience.
                    <br />
                    <br />
                    Because this technology minimizes the number of files by connecting all the
                    parts together through various bonding structures, it helps reduce the
                    repetitive file compression and decompression, slicing work, and the number of
                    printing significantly.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  <strong>
                    Flawless output
                    <br />{' '}
                  </strong>
                  with any printers
                </h3>
                <p className='--font-basic'>
                  FAB365 provides a design file that is optimized for printer’s specification and
                  option inorder to print a perfect output with any printers without failures.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img6.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    The quality of different kinds of personal 3d printers that are continuously
                    being launched varies a lot depending on low specification or high
                    specification. Many users face difficulties and failures in printing because of
                    low quality 3d models.
                    <br />
                    <br />
                    FAB365 provides a file that is optimized in any conditions and printing options,
                    so people can print without any failures and can have a perfect output. All
                    datas from FAB365 are designed from scratch considering all kinds of printing
                    methods of all printers.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --green --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  <strong>Proven quality</strong> from numerous test prints
                </h3>
                <p className='--font-basic'>
                  FAB365 model releases products after test print and thorough inspection.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img7.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    Even 3D models were designed with a perfect plan and leading technology, if it
                    doesn't print out well from different kinds of 3D printers, it is useless.
                    <br />
                    <br />
                    Therefore, we edit and correct the products through test printing the model a
                    lot when we design the content. We print the design in many different conditions
                    and environments such as changes in different types of a filament, room
                    temperature, and considering the history of the machine. Then we launch the
                    product after going through assembling the printed product several times with
                    thorough inspection.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};
export default ServiceEn;
