// ImageWrapper.jsx
import React from 'react';
import { DndImg, DndWrap } from '../manageEditStyle';
import HoverBtns from '../../../../mangemodel/editModel/HoverBtns';
import ManageImg from '../../../../mangemodel/common/ManageImg';

const ImageWrapper = ({
  imgSrc,
  index,
  label,
  isRequired,
  acceptTypes,
  onClickImgZoom,
  onClickDeleteImg,
  imageChanged,
  setIndex,
}) => {
  return (
    <div className='img-cover'>
      {imgSrc ? (
        <DndWrap>
          <DndImg className='image-container' height={120}>
            <img src={imgSrc} alt={label} />
            <HoverBtns
              onClickImgZoom={onClickImgZoom}
              onClickDeleteImg={onClickDeleteImg}
              index={index}
            />
          </DndImg>
          <span className='img-count'>({label})</span>
        </DndWrap>
      ) : (
        <>
          <ManageImg
            imageChanged={imageChanged}
            setIndex={setIndex}
            index={index}
            bordercolor={isRequired ? '#FF0000' : '#000'}
            acceptTypes={acceptTypes}
          />
          <span className='img-count'>({label})</span>
        </>
      )}
    </div>
  );
};

export default ImageWrapper;
