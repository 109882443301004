import styled from 'styled-components';

export const Container = styled.div`
  min-height: 50vh;

  padding-top: 49px;

  @media screen and (max-width: 700px) {
    padding-top: 74px;
  }
  @media screen and (max-width: 500px) {
    /* padding-top: 145px; */
  }

  .blank-h3 {
    font-weight: 600;
    font-size: 35px;
    margin: 2rem 0;
  }
  .blank-alarm {
    border: 1px solid;
    padding: 20px 25px;
    border-radius: 0.3em;
  }
  .blank-alarm.blank-error {
    border-color: #c00;
    background-color: #fef9f9;
  }
  .blank-alarm.blank-success {
    border-color: #0c0;
    background-color: #f9fef9;
  }
`;
