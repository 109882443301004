// // 초기화
// const gtagInitialized = () => {
//   const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS; // 환경 변수에 저장된 추적ID 가져오기
//   ReactGA4.initialize(gaTrackingId, {
//     debug: true,
//     alwaysSendToDefaultTracker: false,
//     titleCase: false,
//   });
// };
import ReactGA4 from 'react-ga4';

// 로그인 추적 태그
export const gtagOnClickLogin = method => {
  window.gtag('event', 'login', {
    currency: 'USD',
    method: method,
  });
};

// 회원가입 추적 태그
export const gtagOnClickSignup = method => {
  window.gtag('event', 'sign_up', {
    currency: 'USD',
    method: method,
  });
};

//googleAds ad.fab365 => fab365 유저의 신규가입 추적 태그
export const gtagOnClickSignupAd = () => {
  window.gtag('event', 'conversion', {
    send_to: 'AW-16568745759/JIWFCLnxvLIZEJ_-y9w9',
    value: 1.0,
    currency: 'KRW',
  });
};

// 아이템 리스트 조회 추적 태그 (list_name 테스트)
export const gtagViewItemList = name => {
  window.gtag('event', 'view_item_list', {
    currency: 'USD',
    list_name: name,
  });
};

// 아이템 상세페이지 조회 추적 태그 (item_id 테스트)
export const gtagViewItemDetailPage = itemId => {
  window.gtag('event', 'view_item', {
    currency: 'USD',
    item_id: itemId,
  });
};

// 검색 추적 태그
export const gtagSearch = value => {
  window.gtag('event', 'view_search_results', {
    currency: 'USD',
    search_term: value,
  });
};

// 장바구니 추적 태그
export const gtagAddToCart = ({ value, itemId }) => {
  window.gtag('event', 'add_to_cart', {
    currency: 'USD',
    value: value,
    label: itemId,
  });
};

// 구매 추적 태그
export const gtagPurchase = ({ value, orderno }) => {
  window.gtag('event', 'purchase', {
    currency: 'USD',
    value: value,
    transaction_id: orderno,
  });
};

// 구매 과정 에러 추적 태그
export const gtagException = description => {
  window.gtag('event', 'exception', {
    currency: 'USD',
    description: description,
  });
};

// 결제 페이지 로드 추적 태그
export const gtagBeginCheckout = value => {
  window.gtag('event', 'begin_checkout', {
    currency: 'USD',
    value: value,
  });
};

// 결제 페이지 > 페이팔 구매 버튼 클릭 추적 태그
export const gtagCheckoutProgress = value => {
  window.gtag('event', 'checkout_progress', {
    currency: 'USD',
    value: value,
  });
};
