import {
  OPTION_ITEM_NEW,
  OPTION_ITEM_POPULAR,
  OPTION_ITEM_PRICE_HIGH,
  OPTION_ITEM_PRICE_LOW,
} from "./constant";

const SelectSort = ({ value, onchange }) => {
  const select = (e) => {
    onchange(e.target.value);
  };
  return (
    <>
      <select className="form-input sort" onChange={select} value={value}>
        <option value={OPTION_ITEM_NEW}>Newest</option>
        <option value={OPTION_ITEM_POPULAR}>Popular</option>
        <option value={OPTION_ITEM_PRICE_HIGH}>High Price</option>
        <option value={OPTION_ITEM_PRICE_LOW}>Low Price</option>
      </select>
    </>
  );
};

export default SelectSort;
