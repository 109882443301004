import React, { useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import UserContext from '../../../context/user/user';
import { uploadManageImg } from '../../../action/request';
import ManageImg from '../common/ManageImg';
import {
  DndWrap,
  DndImg,
  HoverIcon,
  PreviewImg,
} from '../../designers/Contents/manageModelPage/manageEditStyle';
import PreviewImgView from './PreviewImgView';
import HoverBtns from './HoverBtns';
import { useRecoilState } from 'recoil';
import { manageProcessingAtom } from '../../../atom/atom';

const ImgDnd = ({ detailImg, setDetailImg, onClickAddImg, idItem, requiredDetailImg }) => {
  const height = 80;
  const [, setProcessing] = useRecoilState(manageProcessingAtom);
  const { state: userState } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const [bigsizeImg, setBigSizeImg] = useState(false);
  const [zoomImg, setZoomImg] = useState();
  const dragItem = useRef(null); // Specify the type for dragItem
  const dragOverItem = useRef(null); // Specify the type for dragOverItem
  const maxLength = 20;

  // const [imgList, setImgList] = useState([]);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = e => {
    const copyListItems = [...detailImg];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    setDetailImg(copyListItems);
  };

  const imageChanged = async (e, key) => {
    try {
      setProcessing(true);

      const prefix = 'detailImage';

      const files = e.target.files;
      const newMedias = [...detailImg];
      const filterlist = detailImg.filter(el => el.value !== 'add');

      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadManageImg({
          file: files[i],
          idItem,
          prefix,
        });

        if (res.returnCode === 'C00008') {
          alert(res?.message);
        } else {
          if (filterlist?.length + i <= 19) {
            newMedias[filterlist?.length + i] = { img: res.data.url, sort: i };
          }
        }
      }

      setDetailImg(newMedias);
      e.target.value = ''; // value reset
    } catch (e) {
      setProcessing(false);
      alert('request failed');
    } finally {
      setProcessing(false);
    }
  };

  const onClickImgZoom = key => {
    setZoomImg(detailImg[key]?.img);
    setBigSizeImg(true);
  };

  const onClickCloseImg = () => {
    setBigSizeImg(false);
    setZoomImg('');
  };

  const onClickDeleteImg = key => {
    const filterList = detailImg.filter((el, index) => index !== key);
    setDetailImg([...filterList, { value: 'add', img: 'add' }]);
  };

  return (
    <div className='detail-img-wrap'>
      {detailImg &&
        detailImg.map((item, key) => {
          return (
            <DndWrap
              key={key}
              draggable
              onDragStart={e => dragStart(e, key)}
              onDragEnter={e => dragEnter(e, key)}
              onDragEnd={drop}
              onDragOver={e => e.preventDefault()}
            >
              {item.value === 'add' ? (
                <>
                  <ManageImg
                    height={`${height}px`}
                    width={'100%'}
                    icon='24px'
                    size={14}
                    imageChanged={imageChanged}
                    index={key}
                    setIndex={setIndex}
                    title={`(${key + 1}/20)`}
                    bordercolor={requiredDetailImg && key === 0 ? '#FF0000' : '#000'}
                    acceptTypes={'.jpg, .jpeg, .webp, .png, .gif'}
                    multiple={'detail'}
                  />
                </>
              ) : (
                <DndImg
                  className='image-container'
                  height={height}
                  border={requiredDetailImg && key === 0 ? '#FF0000' : '#000'}
                >
                  <img src={item?.img} />
                  <span className='img-count'>{`(${key + 1}/20)`}</span>
                  <div className='overlay'>
                    <HoverIcon>
                      <img src='/assets/img/mange/icon_move.png' />
                    </HoverIcon>
                  </div>
                  <HoverBtns
                    onClickImgZoom={onClickImgZoom}
                    index={key}
                    onClickDeleteImg={onClickDeleteImg}
                  />
                </DndImg>
              )}
            </DndWrap>
          );
        })}
      {detailImg.length < maxLength && (
        <ManageImg
          height={'80px'}
          width={'100%'}
          color='#9A9A9A'
          icon='20px'
          size={12}
          value={true}
          title={'5 more...'}
          onClick={onClickAddImg}
        />
      )}

      {bigsizeImg && <PreviewImgView onClickCloseImg={onClickCloseImg} zoomImg={zoomImg} />}
    </div>
  );
};

export default ImgDnd;
