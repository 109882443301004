import moment from 'moment';
import { STATUS_ACTIVE, STATUS_EXPIRED, STATUS_USED } from './constant';
import { useEffect } from 'react';
const TicketStatus = ({ data }) => {
  const isExpired = () => {
    if (data.status === STATUS_EXPIRED) {
      return true;
    }

    if (data.status === STATUS_ACTIVE) {
      return moment() > moment(data.expiredAt);
    }
    return false;
  };

  // if (isExpired()) {
  //   return <span style={{ color: '#b9b9b9' }}>Reward ticket expired.</span>;
  // }

  if (data.status === STATUS_ACTIVE) {
    return 'Reward ticket acquired.';
  }
  if (data.status === STATUS_EXPIRED) {
    return <span style={{ color: '#b9b9b9' }}>Reward ticket expired.</span>;
  }
  if (data.status === STATUS_USED) {
    return <span style={{ color: '#b9b9b9' }}>Reward ticket used.</span>;
  }
};

export default TicketStatus;
