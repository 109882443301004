import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { seletecdMoreAtom, seletecdMonthAtom } from '../../../../atom/atom';
const SalesMonthlyTd = ({ data, moveScroll }) => {
  const [, setSelectedMore] = useRecoilState(seletecdMoreAtom);
  const [, setSelectedMonth] = useRecoilState(seletecdMonthAtom);

  const onClickMore = () => {
    setSelectedMore('Month');
    setSelectedMonth(data.monthDate);
    moveScroll();
  };

  return (
    <tr>
      <td>{data.month}</td>
      <td>{data.downloads}</td>
      {data?.fees && <td>$ {data.fees}</td>}
      {data?.earnings && <td>$ {data.earnings}</td>}
      {/* {<td>$ {data?.earnings}</td>} */}
      {/*
      <td>$</td> */}

      {/*년도를 바꿀때 more 라는 텍스트가 데이터가 들어오지 않았음에서 계속 랜더링되서 일단 fees key값이 있을 때 띄워주도록 해놨습니다. */}
      {/* {data?.fees && (
        <td>
          <button>more</button>
        </td>
      )} */}

      <td>
        <Btn onClick={() => onClickMore(data)}>more</Btn>
      </td>
    </tr>
  );
};

export default SalesMonthlyTd;

const Btn = styled.div`
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 1px;
`;
