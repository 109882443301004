import { useNavigate } from 'react-router-dom';
import { FOOTER_CONTACT_BUSINESS } from '../../../route/constant';
import {
  MembersContainer,
  MembersWrapper,
  MemberTitleContainer,
} from '../../Term/component/TermStyle';

const CopyrightKo = () => {
  const navigate = useNavigate();
  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };
  return (
    <>
      <MembersContainer className='container'>
        <div className='terms'>
          <div className='terms-header'>
            <MemberTitleContainer>
              <h1 className='logo'>
                <img src='/assets/img/members/logo.png' alt='logo' />
              </h1>
              <h2 className='terms-title'>콘텐츠 및 저작권 정책</h2>
            </MemberTitleContainer>
          </div>
          <MembersWrapper className='terms-body'>
            <p className='members-title'>콘텐츠 및 저작권 정책</p>
            <div className='term-block'>
              <h3>제1조. 목적</h3>
              <p>
                본 라이선스 계약은 팹삼육오(Fab365, Inc., 이하 ‘회사’)에서 제공하는 3D 프린팅용
                e-Product 마켓 플레이스인 Fab365 (이하 ‘서비스’)에서 이루어지는 콘텐츠 등 거래와
                관련하여 판매되는 콘텐츠 등의 소유권과 라이선스 부여 내용(사용 목적과 사용 범위 등을
                포함)을 명시하고 구매자와 판매자의 콘텐츠 등 소유권과 사용에 관련된 권리, 의무, 책임
                및 기타 필요한 사항을 규정함을 목적으로 합니다
              </p>
            </div>

            <div className='term-block'>
              <h3>제2조. 용어의 정의</h3>
              <p>(1) 이 계약에서 사용하는 용어의 정의는 다음 각 호와 같습니다.</p>
              <p>
                1. 회원: 회사에 개인정보를 제공하여 회원등록을 한 개인 또는 법인으로서, 다음 각
                목에서 정의하는 것과 같이 일반회원과 판매회원으로 구분됩니다.
              </p>
              <p>
                ① 일반회원(이하 ‘일반회원’ 또는 ‘구매자’): 회사에서 제공하는 구매서비스를 이용할 수
                있는 14세 이상의 개인이나 법인
              </p>
              <p>
                ② 디자이너회원(이하 ‘디자이너회원’ 또는 ‘판매자’): 회사에서 제공하는 구매서비스 및
                판매서비스를 이용할 수 있는 14세 이상의 개인이나 법인
              </p>
              <p>
                2. 콘텐츠: 회사가 제공하는 서비스를 통하여 판매자와 구매자 사이에, 또는 회사와
                구매자 사이에 거래되는 3D 프린팅 모델 데이터 및 그와 관련된 콘텐츠
              </p>
              <br />
              <p>
                (2) 제(1)항 또는 용어가 사용되는 조항에서 별도로 정의되지 않은 이 계약상의 용어의
                의미는 일반적인 거래관행에 의합니다.
              </p>
            </div>

            <div className='term-block'>
              <h3>제3조. 콘텐츠 등의 소유권</h3>
              <p>
                판매자가 제작하여 서비스를 통해 판매하는 모든 콘텐츠 등의 저작권은 저작권의 소유자가
                그대로 보유하며, 본 계약의 어떤 조항도 저작권이 구매자에게 이관되는 것으로 해석되지
                않습니다.
              </p>
            </div>

            <div className='term-block'>
              <h3>제4조. 판매자의 구매자에 대한 라이선스 부여</h3>
              <p>
                (1) 구매자가 서비스를 통하여 콘텐츠 등을 구매하는 경우, 판매자는 그 구매 시점부터
                구매자에게 그 콘텐츠 등을 이용할 수 있는 라이선스를 부여합니다. 구매자가 그 콘텐츠
                등을 사용하는 목적과 방법은 다음 각 호와 같습니다.
              </p>
              <p>1. 콘텐츠 등 이용 용도 : 개인적, 비상업적 목적</p>
              <p>
                2. 콘텐츠 등 이용 범위 : 국내 및 국외에서 복제, 배포, 전시, 공연, 방송, 대여, 각색,
                수정, 편집, 공중송신, 파생저작물 작성이 불가능하고, 열람 및 3D 프린터 출력 가능
              </p>
              <p>
                3. 콘텐츠 등 이용 조건 : 국내 및 국외에서, 양도불가능한, 비독점적인, 서브라이선스
                부여 불가능한, 구매 비용을 제외한 별도의 로열티 없는 권리로서 이용
              </p>
              <p>
                4. 콘텐츠 등 이용 매체 또는 플랫폼 : 현재 알려져 있거나 앞으로 개발될 모든 형태의
                매체, 장비, 기술을 포함
              </p>
            </div>

            <div className='term-block'>
              <h3>제5조. 콘텐츠 출력물 판매 등</h3>
              <p>
                (1) 구매자는 서비스를 통하여 구매한 콘텐츠를 3D 프린터로 출력할 수 있으나, 해당
                출력물을 상업적 용도로 판매, 복제, 배포, 전시, 공연, 방송, 대여, 각색, 수정, 편집,
                공중송신, 파생저작물 작성을 할 수 없습니다.
              </p>
              <br />
              <p>
                (2) 단, 회사는 콘텐츠 출력물 판매에 대해 일부 예외를 허용할 수 있으며 예외사항에
                대한 구체적인 내용은{' '}
                <a
                  href=''
                  onClick={e => goTo(e, FOOTER_CONTACT_BUSINESS)}
                  style={{ color: '#835fec' }}
                >
                  [별도의 지침]
                </a>
                을 통하여 안내합니다.
              </p>
            </div>

            <div className='term-block'>
              <h3>제6조. 로열티</h3>
              <p>
                구매자가 본 계약상 이용범위와 목적내에서 콘텐츠 등을 사용하며 구매자와 판매자가
                본계약과 별도의 계약을 체결하지 않는 한, 별도의 로열티를 지불하지 않습니다.
              </p>
            </div>

            <div className='term-block'>
              <h3>제7조. 판매자가 구매자 간의 별도의 라이선스 부여 계약</h3>
              <p>
                본 계약의 내용에도 불구하고, 판매자와 구매자는 상호 개별 합의 하에 콘텐츠의 이용
                용도, 이용 범위, 이용 조건, 이용 매체 및 출력한 콘텐츠의 판매 등에 대하여 본 계약의
                내용과 다른 내용의 라이선스 부여 계약을 별도로 체결할 수 있습니다.
              </p>
            </div>

            <div className='term-block'>
              <h3>제8조. 회사의 역할</h3>
              <p>
                회사는 구매자 또는 다른 제3자가 본 계약 혹은 판매자와 구매자가 개별적으로 체결한
                라이선스 부여 계약에 의하여 구매자에게 부여된 라이선스를 침해하지 않도록 상업적으로
                합리적인 조치를 취하기 위하여 노력합니다. 그러나 회사는 서비스에 등록된 모든 콘텐츠
                등과 관련하여 판매자의 저작권 혹은 관련 권리를 위반한 회원 및 제3자에 대하여 사실적
                및 법적 조치를 취해야 할 의무를 지지는 않으며, 그러한 침해행위에 대하여 구매자에게
                손해배상할 의무를 지지 않습니다.
              </p>
            </div>

            <div className='term-block'>
              <h3>제9조. 준거법 및 관할법원</h3>
              <p>
                (1) 회사와 구매자, 또는 회사와 판매자 간 제기된 소송은 대한민국법을 준거법으로
                합니다.
              </p>
              <br />
              <p>
                (2) 본 계약과 관련하여 회사를 당사자로 하는 소송이 제기될 경우에는 회사의 본사
                소재지를 관할하는 법원을 합의관할법원으로 정합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>부칙</h3>
              <p>이 약관은 2024년 2월 23일부터 시행합니다.</p>
            </div>
          </MembersWrapper>
        </div>
      </MembersContainer>
    </>
  );
};

export default CopyrightKo;
