import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  const onGoBack = e => {
    e.stopPropagation();
    e.preventDefault();

    navigate(-1);
  };

  return (
    <div class='page-error'>
      <div class='message'>
        <h1 class='logo'>
          <img src='/assets/img/SVG/logo-fab365-w.svg' alt='FAB365' />
        </h1>
        <h2>ERROR</h2>
        <p>Please check the address or go back to the homepage</p>

        <a href='#' class='btn btn-white btn-sm' onClick={onGoBack}>
          Go Back
        </a>
      </div>
    </div>
  );
}

export default NotFound;
