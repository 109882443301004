import React, { createContext, useState } from 'react';

const ModalContext = createContext({
  state: {
    isLogin: false,
    isSignup: false,
    isForgotPassword: false,
    isFacebookNotice: false,
    isLoginForInvite: false,
  },
  action: {
    setIsLogin: () => {},
    setIsSignup: () => {},
    setIsForgotPassword: () => {},
    setIsFacebookNotice: () => {},
    setIsLoginForInvite: () => {},
  },
});

const ModalProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isFacebookNotice, setIsFacebookNotice] = useState(false);
  const [isLoginForInvite, setIsLoginForInvite] = useState(false);

  const value = {
    state: { isLogin, isSignup, isForgotPassword, isFacebookNotice, isLoginForInvite },
    action: {
      setIsLogin,
      setIsSignup,
      setIsForgotPassword,
      setIsFacebookNotice,
      setIsLoginForInvite,
    },
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

const { Consumer: ModalConsumer } = ModalContext;

export { ModalProvider, ModalConsumer };
export default ModalContext;
