import { sanitize } from 'dompurify';
import { useContext, useState } from 'react';
import CommentBody from '../../../component/comment/CommentBody';
import RequestCommentHeader from '../../../component/comment/RequestCommentHeader';
import CommentReply from '../../../component/comment/CommentReply';
import UserContext from '../../../context/user/user';
import Pagination from '../../Category/component/Pagination';
import RequestCommentBody from './RequestCommentBody';
import RequestCommentReply from './RequestCommentReply';
import RequestCommentReplyWrite from './RequestCommentReplyWrite';
import { editRequestComment } from '../../../action/request';
import { RETURN_CODE } from '../../../action/constant';
import uuid from 'react-uuid';

const RequestComment = ({
  idRequest,
  commentGroups,
  commentReplys,
  count,
  size,
  page,
  setPage,
  onReload,
  setShowImgViewer,
  setImgData,
}) => {
  const { state } = useContext(UserContext);
  const [showReply, setShowReply] = useState([]);

  const pageChange = val => {
    setPage(val);
  };
  const pageConfirm = val => {
    setPage(val);
  };

  const showCommentReply = idx => {
    const showList = [...showReply];
    showList[idx] = { ...showList[idx], show: true };
    setShowReply(showList);
    const target = showList[idx]?.target;
    if (target) {
      target.current.scrollIntoView();
    }
  };

  const setCommentReply = (target, idx) => {
    setShowReply(sr => {
      const showList = [...sr];
      showList[idx] = { target, show: false };
      return showList;
    });
  };

  // 댓글 수정
  const editComment = async ({ comment_id, msg, medias }) => {
    try {
      const { data: res } = await editRequestComment({
        comment_id,
        msg,
        medias,
      });
      if (res.returnCode === RETURN_CODE.C00000) {
        onReload();
      }
    } catch (e) {
      alert('request failed');
    }
  };

  return (
    <>
      <div className='store-detail-tab-content active' id='store_detail_comments'>
        <div className='container'>
          <div className='store-detail-content-block'>
            <div className='store-detail-content-block-left'>
              <div className='comments'>
                {state.id && (
                  // 코멘트 작성 박스
                  <RequestCommentHeader
                    thumbnail={state.thumbnail}
                    id={idRequest}
                    onReload={onReload}
                  />
                )}

                <div className='comments-body'>
                  <div className='comment-list'>
                    <ul>
                      {commentGroups?.map((c, idx) => {
                        // console.log(`comment ${idx}`, c);
                        const replys = commentReplys?.filter(r => {
                          return c.comment_id === r.parent_oid;
                        });
                        // 리플 삭제되면 삭제된 것이 맨 위로 정렬
                        replys?.sort((a, b) => {
                          if (a.status === 'delete' && b.status !== 'delete') {
                            return -1;
                          } else if (a.status !== 'delete' && b.status === 'delete') {
                            return 1;
                          } else {
                            return 0;
                          }
                        });
                        return (
                          <>
                            <li>
                              <div className='comment-list-item'>
                                <RequestCommentBody
                                  comment={c}
                                  onEdit={editComment}
                                  onReload={onReload}
                                  showReply={() => showCommentReply(idx)}
                                  setShowImgViewer={setShowImgViewer}
                                  setImgData={setImgData}
                                />
                                {/* 리플 목록 */}
                                {replys?.map((r, requestIdx) => {
                                  return (
                                    <RequestCommentReply
                                      onEdit={editComment}
                                      replys={r}
                                      idUser={r.user_id}
                                      onReload={onReload}
                                      setCommentReply={target => setCommentReply(target, idx)}
                                      isLast={state.id && requestIdx === replys.length - 1}
                                      showReply={() => showCommentReply(idx)}
                                      setShowImgViewer={setShowImgViewer}
                                      setImgData={setImgData}
                                    />
                                  );
                                })}
                                {/* 리플 작성 박스 */}
                                {showReply[idx]?.show && (
                                  <RequestCommentReplyWrite
                                    thumbnail={state.thumbnail}
                                    onReload={onReload}
                                    root={idRequest}
                                    parent={c.comment_oid}
                                  />
                                )}
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                    <Pagination
                      page={page}
                      total={Math.ceil(count / size)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestComment;
