import React from 'react';

const ItemCardEtc = ({ data, reviewValue }) => {
  return (
    <div className='etc'>
      <>
        <button tabIndex='0' type='button' className='favorites'>
          <span className='hiddenTxt'>favorites</span>
          {reviewValue ? data.like_count : data?.product_like_count || 0}
        </button>
        <button tabIndex='0' type='button' className='comments'>
          <span className='hiddenTxt'>comments</span>
          {reviewValue ? data.comment_count : data?.product_comment_count || 0}
        </button>
        {!reviewValue && (
          <button tabIndex='0' type='button' className='pictures'>
            <span className='hiddenTxt'>pictures</span>
            {data?.review_count || 0}
          </button>
        )}
      </>
    </div>
  );
};

export default ItemCardEtc;
