import React from 'react';
import {
  MembersContainer,
  MembersWrapper,
  MemberTitleContainer,
} from '../../Term/component/TermStyle';

const LicenseEn = () => {
  return (
    <div>
      <MembersContainer className='container'>
        <div className='terms'>
          <div className='terms-header'>
            <MemberTitleContainer>
              <h1 className='logo'>
                <img src='/assets/img/members/logo.png' alt='logo' />
              </h1>
              <h2 className='terms-title'>USER LICENSE AGREEMENT</h2>
            </MemberTitleContainer>
          </div>
          <MembersWrapper className='terms-body'>
            <p className='members-title'>User License Agreement</p>
            <div className='term-block'>
              <h3>Article 1. Objective</h3>
              <p>
                This license agreement has been executed to provide grant of the ownership and
                license (including the purpose of use and the area of use) for the Contents in
                relation to the online e-Product marketplace for 3D printing models (hereinafter
                called ‘Service’), provided by FAB365 Inc. (hereinafter called ‘Company’), and to
                provide provisions regarding the right, obligation, responsibility and other
                necessary items related to the copyright and usage of the Contents of the Buyers and
                the Sellers.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 2. Definition of Terms</h3>
              <p>(1) The definition of terms used in this agreement is as follows.</p>
              <p style={{ marginLeft: 30 }}>
                1. Member: A person or corporation that subscribed by providing their personal
                information to the Company, and is divided by ordinary member and designer member as
                follows.
              </p>
              <p style={{ marginLeft: 50 }}>
                a. Ordinary member (hereinafter called ‘Ordinary Member’ or ‘Buyer’): A person or
                corporation of 14 years old or older that may use the purchase Service provided by
                the Company.
                <br />
                b. Designer member (hereinafter called ‘Designer Member’ or ‘Seller’): A person or
                corporation of 14 years old or older that may use the purchase Service and sale
                Service provided by the Company.
              </p>
              <p style={{ marginLeft: 30 }}>
                2. Contents: 3D printing model data and its related Contents traded between the
                Seller and the Buyer or between the Company and Buyer through the Service provided
                by the Company.
              </p>
              <p>
                (2) Unless defined in Article 1 or the Article in which the term is used, the
                meaning of such terms in this agreement shall be interpreted according to the
                general standard/common practices of transaction.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 3. Ownership of Contents</h3>
              <p>
                The copyright of all Contents shall be held by the owner of copyright, and any
                Article in this agreement shall not be interpreted as a transfer of the copyright to
                Buyer.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 4. Seller’s Grant of License to Buyer</h3>
              <p>
                If the Buyer purchases any Contents through the Service, the Seller shall grant the
                Buyer the license for usage of the Contents from the point of purchase. The purpose
                and method of Buyer’s usage of the Contents are as follows.
              </p>
              <p>1. Use of Contents: Personal and noncommercial purpose.</p>
              <p>
                2. Range of usage: It is impossible to copy, distribute, exhibit, perform,
                broadcast, rent, dramatize, correct, edit, publicly transmit, and write the derived
                work, and it is possible to read and print through 3D printer.
              </p>
              <p>
                3. Condition of usage: It is non-transferable, non-exclusive, and non-sublicensable,
                and is possible to use as royalty-free.
              </p>
              <p>
                4. Media or platform of usage: Including all kinds of media, equipment, and
                technology currently known or to be developed.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 5. Sales of the Printed Contents, etc.</h3>
              <p>
                The Buyer may print the Contents using a 3D printer. Provided, the Buyer shall not
                sell, copy, distribute, exhibit, perform, broadcast, rent, dramatize, correct, edit,
                publicly transmit the printed Contents nor write the derived work, for the
                commercial purpose.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 6. Royalty</h3>
              <p>
                The Buyer shall use the Contents in the scope and purpose of usage of Contents
                pursuant to this agreement, and shall not pay the other royalty unless the Buyer and
                the Seller execute a separate agreement.
              </p>
            </div>

            <div className='term-block'>
              <h3> Article 7. Contract of Seller’s Giving Other License to Buyer</h3>
              <p>
                Notwithstanding the provisions under this agreement, the Seller and Buyer may
                execute a separate agreement to grant the license under terms and conditions,
                including but not limited to the purpose of use, the scope of usage, the media of
                usage, and sale of the printed contents, which are not in accordance with this
                agreement.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 8. Role of the Company</h3>
              <p>
                The Company shall endeavor to take commercially reasonable measures to prevent Buyer
                or the third person from breaching terms and conditions of license under this
                agreement or the separately executed agreement between the Seller and Buyer.
                Provided, the Company shall not be obliged to take factual or legal measures to the
                Member and the third person who violated the Seller’s copyright or related rights,
                in association with all the Contents registered in Service, and shall not be obliged
                to indemnify the Buyer for such damage incurred from the infringement.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 9: Content License Priority Clause</h3>
              <p>
                In the event that license provisions are included in the documents within the
                content files, content details, or other screens, such provisions shall take
                precedence over this agreement.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 10. Applicable Law and Competent Court</h3>
              <p>
                (1) The lawsuit filed between the Company and the Buyer, or between the Company and
                the Seller shall be based on the law of Republic of Korea.
              </p>
              <p>
                (2) If any lawsuit is filed against the Company in regards to this agreement, the
                competent court shall be determined according to the Civil Procedure Code.
              </p>
            </div>

            <p>
              ﹡ All support requests, completed registrations and other inquiries should be sent
              via e-mail to{' '}
              <a
                href='mailto:info@fab365.net'
                style={{ color: '#835fec', textDecoration: 'underline' }}
              >
                info@fab365.net
              </a>{' '}
              <br />
              Visit{' '}
              <a
                href='http://www.fab365.net/help'
                style={{ color: '#835fec', textDecoration: 'underline' }}
              >
                http://www.fab365.net/help
              </a>{' '}
              for forms, answers to frequently asked questions, and more.
            </p>
          </MembersWrapper>
        </div>
      </MembersContainer>
    </div>
  );
};

export default LicenseEn;
