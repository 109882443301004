import { useContext, useEffect } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import PrivacyEn from './component/PrivacyEn';
import PrivacyKo from './component/PrivacyKo';
import { MainContainer } from '../Term/Term';

const Privacy = () => {
  const { action } = useContext(LangContext);

  return (
    <>
      <Layout>
        <MainContainer id='main'>{action.isKo() ? <PrivacyKo /> : <PrivacyEn />}</MainContainer>
      </Layout>
    </>
  );
};

export default Privacy;
