import React, { useContext, useState } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import { resetPassword } from '../../action/request';
import { useLocation, useNavigate } from 'react-router-dom';
import { RETURN_CODE } from '../../action/constant';
import ModalContext from '../../context/modal/modal';
import styled from 'styled-components';

function ResetPassword() {
  const [isShow, setIsShow] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const { action: modalAction } = useContext(ModalContext);
  const { state: langState } = useContext(LangContext);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;

  const navigate = useNavigate();
  const location = useLocation();
  const code = location.search.split('=')[1];

  const handleIsShow = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsShow(prev => !prev);
    navigate('/');
    modalAction.setIsLogin(true);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = e => {
    setPasswordConfirm(e.target.value);
  };

  const handleFocus = e => {
    if (e.target.className === 'form-input confirm-password') {
      setPasswordConfirm('');
    } else if (e.target.className === 'form-input new-password') {
      setPassword('');
    }
  };
  const onResetPassword = async () => {
    if (!password.trim().length) return alert(loginSignupMessages.SIGNUP_ERROR_PASSWORD_NEED);
    if (password !== passwordConfirm)
      return alert(loginSignupMessages.LOGIN_ERROR_PASSWORD_INVALID);

    try {
      const { data } = await resetPassword(code, password, passwordConfirm);

      if (data.returnCode === RETURN_CODE.C00000) {
        navigate('/');
        modalAction.setIsLogin(true);
      }
      return alert(data.message);
    } catch (e) {
      return alert('request failed');
    }
  };

  return (
    <Layout>
      <ResetPasswordInputWrapper id='main'>
        <div className={`modal ${isShow && 'active'}`} id='modalResetPassword'>
          <div className='modal-inner'>
            <div className='modal-box'>
              <Box>
                <div className='modal-box-header'>
                  <h1 className='modal-title'>Reset Password</h1>
                </div>
                <div className='modal-box-body'>
                  <form action='' name='reset_password_form' className='signup-form m-b-md'>
                    <input type='hidden' name='code' value='<?php echo $code?>' />

                    <div className='form-control new-pw'>
                      <input
                        type='password'
                        placeholder='New Password'
                        className='form-input new-password'
                        name='password'
                        value={password}
                        onChange={onChangePassword}
                        maxLength={36}
                        onFocus={e => handleFocus(e)}
                      />
                    </div>

                    <div className='form-control confirm-pw'>
                      <input
                        type='password'
                        placeholder='Confirm New Password'
                        className='form-input confirm-password'
                        name='passwordConfirm'
                        value={passwordConfirm}
                        onChange={onChangeConfirmPassword}
                        maxLength={36}
                        onFocus={e => handleFocus(e)}
                      />
                    </div>

                    <button type='button' onClick={onResetPassword}>
                      Reset Password
                    </button>
                  </form>
                </div>
                <a href='#' data-hide-modal='true' className='btn-close' onClick={handleIsShow}>
                  Close
                </a>
              </Box>
            </div>
          </div>
        </div>
      </ResetPasswordInputWrapper>
    </Layout>
  );
}

const ResetPasswordInputWrapper = styled.div`
  .modal-title {
    font-family: 'Inter', sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 29.05px !important;
    letter-spacing: -0.02em !important;
    text-align: left !important;
  }

  .modal-box-header {
    padding: 64px 0 20px 0;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 40px;
  }

  .form-input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    ::placeholder {
      font-family: Inter;
      font-size: 15px;
      font-weight: 400;
      line-height: 18.15px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #aaaaaa;
    }
  }

  .form-control.new-pw {
    margin-bottom: 6px;
  }

  .form-control.confirm-pw {
    margin-bottom: 20px;
  }

  button {
    background: #00de5a !important;
    border: none;
    width: 100%;
    height: 46px;
    border-radius: 999px;
    padding: 14px 16px 14px 16px;
    gap: 10px;
    opacity: 0px;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  .m-b-md {
    margin-bottom: 0px !important;
  }
`;

const Box = styled.div`
  max-width: 380px;
  margin: 0 auto;
`;

export default ResetPassword;
