import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import Categoryist from './component/CategoryList';
import { OPTION_ITEM_NEW, OPTION_ITEM_SET, OPTION_ITEM_SINGLE } from './component/constant';
import SelectSort from './component/SelectSort';
import SelectType from './component/SelectType';
import './Category.css';
import Pagination from './component/Pagination';
import ItemList from './component/ItemList';
import { getCategories, getFreeLimit, getItems, report } from '../../action/request';
import { gtagViewItemList } from '../../action/gTag';
import { ls } from '../../constant';
const SetItem = ({}) => {
  const [type, setType] = useState(OPTION_ITEM_SINGLE);
  const [categories, setCategories] = useState([]);
  const [sort, setSort] = useState('');
  const [page, setPage] = useState(0);
  const [items, setItems] = useState({ count: 0 });
  const [limit, setLimit] = useState({});
  const { category } = useParams();

  // 아이템 페이지네이션, filter 값이 있을 경우 해당 값으로 상태값 초기화
  useEffect(() => {
    setSort(localStorage.getItem(ls.itemSort) || OPTION_ITEM_NEW);
    setPage(Number(localStorage.getItem(ls.itemPage)) || 1);
  }, [localStorage.getItem(ls.itemPage), localStorage.getItem(ls.itemSort)]);

  const sortChange = value => {
    setSort(value);
    pageChange(1);
    localStorage.setItem(ls.itemSort, value);
  };

  const pageChange = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
  };

  const pageConfirm = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
    updateItems();
  };

  const fetchData = async () => {
    try {
      const { data: categories } = await getCategories();
      setCategories(categories.data);
      const { data: resLimit } = await getFreeLimit();
      setLimit(resLimit.data);

      if (page && sort) {
        updateItems();
      }

      // gtag 아이템 리스트 조회 태그
      gtagViewItemList('set-items');

      // console.log('tag test', window.location.href, 'set-items');
    } catch (e) {
      // TODO: 주석 풀기
      // report(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (page && sort) {
      updateItems();
    }
  }, [page, sort]);

  const updateItems = async cId => {
    let id = cId;
    const { data: resItems } = await getItems({
      sort,
      idCategory: '',
      offset: (page - 1) * 9,
      size: 9,
      type: OPTION_ITEM_SET,
    });
    setItems(resItems.data);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top page-top-with-breadcrumb'>
            <div className='container container-lg'>
              <h1 className='page-title'>Set Items</h1>
            </div>
          </div>

          <div className='main-content-with-aside'>
            <Categoryist current={'set'} data={categories.items} />
            <div className='main-content'>
              <section className='store-collection-list'>
                <div className='container'>
                  <div
                    className='content-section content-section-has-top-nav'
                    style={{ minHeight: '900px' }}
                  >
                    <div className='product-list product-list-3'>
                      <div className='product-list-filter margin-select'>
                        <SelectSort value={sort} onchange={sortChange} />
                      </div>
                      <ItemList data={items.results} limit={limit} />
                    </div>
                    <Pagination
                      page={page}
                      total={Math.ceil(items.count / 9)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SetItem;
