import { sanitize } from 'dompurify';
import { useContext } from 'react';
import {
  PAGE_CHECKOUT_LICENSE,
  PAGE_CHECKOUT_LICENSE_AGREEMENT,
} from '../../../context/lang/constant';
import LangContext from '../../../context/lang/lang';
import './License.css';
import { ls } from '../../../constant';
const License = ({ isAgree, setIsAgree, checkoutMessage }) => {
  const { action } = useContext(LangContext);

  const handleAgree = () => {
    setIsAgree(prev => {
      localStorage.setItem(ls.isAgree, !prev);
      return !prev;
    });
  };

  return (
    <>
      <div className='payment-block license-page '>
        <h5>License Agreement</h5>
        <div
          className='fabj-license-agreement'
          dangerouslySetInnerHTML={{
            __html: sanitize(action.trans(PAGE_CHECKOUT_LICENSE)),
          }}
        ></div>
        <div
          className={`payment-block-body ${checkoutMessage.includes('License') && 'error'}`}
          id='checkout-license-checkbox'
        >
          <label className='input-checkbox' onChange={handleAgree}>
            <input type='checkbox' name='agreement' value={isAgree} />{' '}
            <span className='label'>{action.trans(PAGE_CHECKOUT_LICENSE_AGREEMENT)}</span>
          </label>
        </div>
      </div>
    </>
  );
};

export default License;
