import { useNavigate } from 'react-router-dom';
import { FOOTER_PRIVACY, FOOTER_TERMS_POINT } from '../../../route/constant';
import { MembersContainer, MembersWrapper, MemberTitleContainer } from './TermStyle';
import { useEffect } from 'react';

const TermEn = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MembersContainer className='container'>
        <div className='terms'>
          <div className='terms-header'>
            <MemberTitleContainer>
              <h1 className='logo'>
                <img src='/assets/img/members/logo.png' alt='logo' />
              </h1>
              <h2 className='terms-title'>TERMS OF SERVICE</h2>
            </MemberTitleContainer>
          </div>
          <MembersWrapper className='terms-body'>
            <p className='members-title'>Terms of Service (General Members)</p>
            <h2>Chapter 1. General Provisions</h2>

            <div className='term-block'>
              <h3>Article 1. Purpose</h3>
              <p>
                These Terms of Service are intended to govern the rights, obligations,
                responsibilities, and other necessary matters between buyers and Fab365, Inc.
                (hereinafter referred to as 'the Company') in relation to the use of content
                purchase services (hereinafter referred to as 'Service' or 'Purchase Service')
                offered by Fab365, an e-Product marketplace for 3D printing.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 2. Specification, Effectiveness, and Revision of Terms</h3>
              <p>
                (1) The Company shall post these Terms along with the Company's trade name, business
                location, representative's name, business registration number, contact details
                (telephone, fax, email address, etc.) on the initial or a connected screen of the
                Service for Members to access.
              </p>
              <p>
                (2) The Company may revise these Terms within the limits of not violating relevant
                laws such as the "Act on Regulation of Terms", "Basic Act on Electronic Documents
                and Electronic Transactions", "Electronic Signature Act", "Act on Promotion of
                Information and Communications Network Utilization and Information Protection,
                etc.", "Act on Consumer Protection in Electronic Commerce, etc.", "Electronic
                Financial Transactions Act", and others.
              </p>
              <p>
                (3) When the Company revises these Terms, it will specify and announce the revised
                Terms' application date and reasons for revision, along with the current Terms, from
                7 days prior to the application date until the day before the application date,
                using the method in Section 1. However, any revisions that are disadvantageous to
                Members will be clearly notified separately through electronic means within the
                Service, such as email and login consent pop-up windows.
              </p>
              <p>
                (4) If the Company notifies or announces the revised Terms as described above and
                clearly states that the Member's consent will be deemed given if they do not express
                their intention within 30 days, and the Member does not explicitly express refusal,
                the Member is considered to have agreed to the revised Terms.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 3. Definition of Terms</h3>
              <p>(1) The definitions of terms used in these Terms are as follows:</p>
              <p>
                1. “Member” ('Buyer' or 'General Member'): An individual or corporation that has
                provided personal information and registered with the Company, and can use the
                content purchase services provided by the Company, being an individual or
                corporation over 14 years of age.
                <br />
                2. “ID”: A combination of letters and numbers set by the Member and approved and
                registered by the Company for Member identification and service use.
                <br />
                3. “Password”: A combination of letters and numbers set by the Member for identity
                verification, protection of the Member's rights and confidentiality, and registered
                with the Company.
                <br />
                4. “Operator”: An individual selected by the Company for overall management and
                smooth operation of the services provided by the Company.
                <br />
                5. “Content”: 3D printing model data and related content traded between sellers and
                buyers, or between the Company and buyers, through the services provided by the
                Company.
              </p>
              <p>
                (2) The meanings of terms used in these Terms that are not defined in Section (1) or
                the Articles where the terms are used shall be interpreted according to general
                trading practices.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 4. Types of Service</h3>
              <p>
                (1) The service provided by the Company is intended to permit the use of a cyber
                mall for trading content or related goods, etc. (hereinafter referred to as
                'content, etc.'), or to facilitate telecommunication sales. The Company is a
                telecommunication sales intermediary, not a party to telecommunication sales.
                Therefore, the Company bears no responsibility for the content, etc. listed for sale
                in the Service by individual sellers. Additionally, the Company clearly indicates to
                buyers that it is a telecommunication sales intermediary in the transaction screen
                for content, etc.
              </p>
              <p>
                (2) However, the Company can directly sell content, etc. through the Service. In
                this case, the Company indicates that it is selling the content, etc. on the
                introduction screen or order screen of the content, etc., and the Company may bear
                responsibility as a telecommunication seller for such transactions.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 5. Disclaimer of Agency</h3>
              <p>
                The Company only bears the responsibility for operating and managing the system
                efficiently as a telecommunication sales intermediary for content, etc. that it does
                not directly sell. The Company does not act as an agent for buyers or sellers in
                transactions related to content, etc., and members who provide and register
                information related to such transactions bear direct responsibility for them.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 6. Disclaimer of Warranty</h3>
              <p>
                The Company does not guarantee the presence or authenticity of the intent to sell or
                purchase, the quality, completeness, safety, legality, or non-infringement of rights
                of others in the content, etc. sold by sellers to buyers through the system provided
                by the Company. All risks and responsibilities related to this are entirely borne by
                the respective member.
              </p>
            </div>

            <h2>Chapter 2. Contract of Use and Information Protection</h2>

            <div className='term-block'>
              <h3>Article 7. Formation of Service Use Contract</h3>
              <p>
                (1) The service use contract (hereinafter 'use contract') is established when the
                Company accepts the application for use by an individual who wishes to use the
                services (such as content purchase intermediation) provided by the Company. The
                Company will notify its acceptance of use on the relevant service screen or by email
                or other methods.
              </p>
              <p>
                (2) Individuals who wish to use the service agree to these Terms and fill in the
                necessary information according to the member registration application form
                determined by the Company.
              </p>
              <p>
                (3) Membership registration can be done by individuals or business operators
                (individual business operators and corporate business operators) who are over 14
                years old. If an applicant uses someone else's information to apply for use, service
                use may be restricted or punished according to relevant laws.
              </p>
              <p>
                (4) The processing of the application for use is based on the order of application,
                and the establishment date of membership registration is when the Company's
                acceptance reaches the member.
              </p>
              <p>
                (5) The Company may refuse or hold off on accepting an application for use for the
                following reasons:
              </p>
              <p>
                1. In case of application for use by misappropriating someone else's information.
                <br />
                2. If reapplying for use within 2 months from the date of termination of the use
                contract.
                <br />
                3. If a member who has received a suspension of membership qualifications or service
                provision from the Company terminates the use contract arbitrarily during the period
                of such measures and reapplies for use.
                <br />
                4. In case there is no spare capacity in the facilities or there are technical
                impediments.
                <br />
                5. In other cases where it is confirmed that the application for use violates these
                Terms or is illegal or improper, or the Company deems it necessary based on
                reasonable judgment.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 8. Application for Use and Personal Information</h3>
              <p>
                (1) Members must not provide false information when applying for use and must
                immediately update any changes in the provided information with the latest
                information.
              </p>
              <p>
                (2) All notices from the Company to its members are deemed to have been made when
                they reach the email provided by the member. Any damage caused by not updating
                information is entirely borne by the member, and the Company is not responsible for
                this.
              </p>
              <p>
                (3) The collection, use, processing, and provision to third parties of the member's
                personal information shall be governed by the{' '}
                <a href='' onClick={e => goTo(e, FOOTER_PRIVACY)} style={{ color: '#835fec' }}>
                  [Privacy Policy]
                </a>{' '}
                posted by the Company on the initial or connected screen of the Service.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 9. Management of ID and Password</h3>
              <p>
                (1) The responsibility for managing the ID and password lies with the member, and
                under no circumstances can a member transfer or lend their ID or password to others.
              </p>
              <p>
                (2) The member and the user themselves bear the responsibility for any loss or
                damage arising from the leakage, transfer, or lending of the ID or password without
                the Company's fault.
              </p>
              <p>
                (3) If a member becomes aware that their ID or password has been stolen or is being
                used unauthorized by a third party, they must immediately notify the Company, and
                the Company must make every effort to promptly process it.
              </p>

              <h3>Article 10. Termination of Use Contract</h3>
              <p>
                <strong>Termination by Member</strong>
              </p>
              <p>
                (1) Members may terminate the use contract at any time by notifying the Company of
                their intention to terminate through the relevant service screen. However, members
                must take necessary measures to complete all transactions at least 7 days prior to
                notifying their intention to terminate.
              </p>
              <p>
                (2) Members themselves bear responsibility for any disadvantages arising from
                expressions of intent within the period mentioned in the preceding paragraph, and
                upon termination of the use contract, the Company may recover various benefits
                provided to the member for free.
              </p>
              <br />
              <p>
                <strong>Termination by Company</strong>
              </p>
              <p>
                (3) The Company may terminate the use contract if any of the following reasons occur
                or are confirmed:
              </p>
              <p>
                1. In case of acts that infringe the rights, honor, credit, or other legitimate
                interests of other members or third parties or violate the laws of the Republic of
                Korea or public order and morals.
                <br />
                2. In case of acts or attempts to interfere with the smooth progress of the services
                provided by the Company.
                <br />
                3. If it is confirmed that there are reasons for refusal of acceptance as mentioned
                in Article 7, Section 5.
                <br />
                4. In case of violation of the member's obligations, representations and warranties,
                conditions precedent, etc., stipulated in these Terms.
                <br />
                5. In other cases where the Company deems it necessary to refuse to provide the
                service based on reasonable judgment.
              </p>
              <p>
                (4) If the Company terminates the contract, the Company will notify the member of
                the reasons for termination by email, phone, or other methods. The use contract is
                terminated when the Company's intention to terminate reaches the member.
              </p>
              <p>
                (5) Even if the Company terminates the use contract according to this Article, these
                Terms continue to apply to the completion of transactions already concluded before
                termination.
              </p>
              <p>
                (6) If the use contract is terminated according to this Article, the Company may
                recover various benefits provided to the member for free.
              </p>

              <h3>Article 11. Service Period and Interruption</h3>
              <p>
                (1) The service period according to these Terms is from the time the Company's
                acceptance of the application for use reaches the member until the termination of
                the use contract.
              </p>
              <p>
                (2) The Company may temporarily suspend the provision of services for maintenance,
                inspection, replacement, breakdown of computer and other information and
                communication facilities, and disconnection of communication.
              </p>
              <p>
                (3) The Company may limit or temporarily suspend the provision of services in case
                of force majeure, such as natural disasters or equivalent.
              </p>
              <p>
                (4) The Company may modify, suspend, or change all or part of the services provided
                for free according to the Company's policy and operational needs, and unless there
                are special regulations in relevant laws, the Company does not separately compensate
                members.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 12. Provision of Information and Posting of Advertisements</h3>
              <p>
                (1) The Company may provide various information related to the service and
                advertisements on the service screen or by email or other methods to members.
              </p>
              <p>
                (2) Members may engage in transactions with advertisers by using advertisements
                posted on the service or participating in promotional activities of advertisers
                through the service. In case of disputes or other problems arising in the course of
                such transactions, members and advertisers must resolve them directly, and the
                Company is not responsible for such issues.
              </p>
            </div>
            <h2>Chapter 3. Use of Service</h2>

            <div className='term-block'>
              <h3>Article 13. Service Use by Members</h3>
              <p>
                The Company grants Members a non-transferable, non-exclusive, and worldwide license
                to use all types of digital content included in this Service for personal,
                non-commercial purposes.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 14. Service Fees</h3>
              <p>
                The Company may charge service fees (service usage fees) as required for various
                services necessary for free electronic commerce between Members, according to the
                Company's internal policies.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 15. Conclusion of Sales Contract and Payment of Price</h3>
              <p>
                (1) The sales contract for content, etc., is concluded when the buyer expresses an
                intention to accept the sales conditions offered by the seller for the content,
                etc., and the seller expresses an intention to accept it.
              </p>
              <p>
                (2) The Company provides methods for buyers to pay the purchase price by credit card
                payment or other methods determined by the Company.
              </p>
              <p>
                (3) The buyer bears all responsibilities and disadvantages arising from the
                information entered by the buyer related to the payment of the purchase price.
              </p>
              <p>
                (4) If the buyer does not pay the purchase price within a certain period after
                ordering the content, etc., the Company may cancel the order without the buyer's
                consent.
              </p>
              <p>
                (5) The Company will take measures to allow the buyer to check the details of the
                sales contract concluded by the buyer in the service menu such as 'My Shopping
                History' and guide the cancellation method and procedure of the sales contract.
              </p>
              <p>
                (6) The Company may verify whether the buyer has legitimate authority to use the
                payment method used for paying the purchase price and may suspend the transaction
                until such verification is completed or cancel the transaction if verification is
                impossible.
              </p>
              <p>
                (7) The actual amount paid by the buyer is the amount applied after the supplier's
                cost, basic usage fee, applied discount, shipping cost, option product details set
                by the seller (actual purchase amount), and the purchase voucher issued to the buyer
                (cash receipt, tax invoice, credit card sales slip, etc.) is issued for the actual
                purchase amount.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 16. Download</h3>
              <p>
                If a buyer purchases content, etc., that can be delivered in the form of a download,
                the buyer can download the purchased content after paying the purchase price.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 17. Withdrawal of Subscription</h3>
              <p>
                (1) Regarding the withdrawal of subscription (refund), the Company complies with the
                related regulations of the "Act on Consumer Protection in Electronic Commerce, etc."
                and "Content Industry Promotion Act" and the matters of this Article.
              </p>
              <p>
                (2) If the Company does not directly sell content, etc. as a telecommunication
                seller under the "Act on Consumer Protection in Electronic Commerce, etc.", the
                Company explicitly indicates this fact on the product description screen, etc. In
                addition, the Company provides the seller's name, address, phone number, and other
                matters required by related laws to the buyer before the subscription is made.
              </p>
              <p>
                (3) If the seller is a telecommunication seller under the "Act on Consumer
                Protection in Electronic Commerce, etc.", the buyer may withdraw the subscription
                (refund) according to the regulations in Section (4) and below of this Article.
              </p>
              <p>
                (4) The buyer can withdraw the subscription within 7 days from the date of purchase
                or the date of availability of the content, etc.
              </p>
              <p>
                (5) Notwithstanding the contents of the preceding section, the buyer cannot withdraw
                the subscription according to the preceding paragraph against the seller's will in
                any of the following cases:
              </p>
              <p>
                1. If the goods, etc., are destroyed or damaged due to reasons attributable to the
                consumer. However, this does not apply if the packaging, etc., was damaged to check
                the contents of the goods, etc.
                <br />
                2. If the value of the goods, etc., has significantly decreased due to the
                consumer's use or partial consumption.
                <br />
                3. If the value of the goods, etc., has significantly decreased over time to the
                extent that it is difficult to resell.
                <br />
                4. If the packaging of goods, etc., that can be duplicated has been damaged.
                <br />
                5. In case the provision of services or digital content as defined in Article 5 of
                the "Basic Act on Cultural Industry Promotion" has started (in this Service, the
                completion of the download of the content, etc., is considered the start of
                provision). However, in the case of a contract consisting of divisible services or
                divisible digital content, this does not apply to the uninitiated part.
                <br />
                6. In other cases prescribed by Presidential Decree of the Republic of Korea for the
                safety of transactions.
              </p>
              <p>
                (6) In the case of goods, etc., for which withdrawal of subscription, etc., is not
                possible according to the provisions from Paragraph 2 to Paragraph 5 of the
                preceding section, the seller must take measures so as not to hinder the exercise of
                rights of withdrawal of subscription, etc., by clearly indicating that fact on the
                packaging of the goods, etc., or providing a test-use product or other methods.
                However, in the case of digital content that cannot be withdrawn under Paragraph 5
                of the preceding section, the seller shall take measures so as not to hinder the
                exercise of rights of withdrawal of subscription, etc., by indicating that
                withdrawal of subscription, etc., is not possible, along with the following methods
                prescribed by Presidential Decree of the Republic of Korea:
              </p>
              <p>
                1. Allowing partial use: Providing a preview, pre-listen, etc., of part of the
                digital content.
                <br />
                2. Allowing temporary use: Providing digital content by setting a certain usage
                period.
                <br />
                3. Providing trial-use digital content: Providing digital content that can only use
                some limited functions.
                <br />
                4. In cases where it is difficult to provide test-use products by the methods in
                Subparagraphs 1 to 3: Providing information on digital content.
              </p>
              <p>
                (7) The contents of this Article also apply to the Company when it sells content,
                etc., treating the Company as the seller.
              </p>
              <p>
                (8) Refund due to withdrawal of subscription will be immediately canceled upon
                completion of the cancellation procedure in the case of card payment.
              </p>
            </div>

            <h2>Chapter 4. Use of Additional Services</h2>

            <div className='term-block'>
              <h3>Article 18. Credit Service</h3>
              <p>
                (1) The Company may award 'Credits' to buyers through various methods within the
                Service, and buyers may use them as a payment method for the purchase of content,
                etc.
              </p>
              <p>
                (2) The type of Credits, top-up, withdrawal of subscription, refund, and other
                matters regarding Credits shall be governed by the{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS_POINT)} style={{ color: '#835fec' }}>
                  [Credit Terms of Use]
                </a>{' '}
                posted by the Company on the initial or connected screen of the Service.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 19. Paid Gift Vouchers</h3>
              <p>
                (1) The Company may sell paid gift vouchers that can be used to purchase content,
                etc., sold within the Service. Buyers can purchase paid gift vouchers to use as a
                payment method for purchasing content, etc.
              </p>
              <p>
                (2) Refund of paid gift vouchers purchased by the buyer shall be governed by the
                provisions of Article 17. However, the amount refunded to the buyer will be limited
                to the proportion of the unused face value of the gift voucher out of the total gift
                voucher purchase amount of the buyer.
              </p>
              <p>
                (3) If the paid gift voucher is converted into 'Credits' that can be used within the
                Service, such 'Credits' can also be refunded according to Article 6 of the{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS_POINT)} style={{ color: '#835fec' }}>
                  [Credit Terms of Use]
                </a>
                , Article 17 of these Terms, and the preceding section.
              </p>
            </div>
            <h2>Chapter 5. Management and Protection of Users</h2>
            <div className='term-block'>
              <h3>Article 20. User Management</h3>
              <p>
                (1) The Company may take the following measures against members who violate the
                contents of these Terms or relevant laws:
              </p>
              <p>
                1. Recovery of some or all of the benefits additionally provided by the Company.
                <br />
                2. Restriction on the use of specific services.
                <br />
                3. Termination of the use contract.
                <br />
                4. Claim for damages.
              </p>
              <p>
                (2) If the Company takes any of the measures mentioned in each subparagraph of the
                preceding paragraph, the Company will notify the member in advance by telephone or
                email. In cases where the member's contact is disconnected or urgent, such as
                emergency situations, the Company may take measures first and then notify
                afterwards.
              </p>
              <p>
                (3) Members may argue against the Company's measures according to this Article if
                they have reasons for objection.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 21. Member's Posts and Copyright</h3>
              <p>
                (1) The copyright of various posts or works (hereinafter 'posts') such as
                evaluations and opinions about content, etc., sold in the Service, Q&A, and other
                content, etc., created by members using the services provided by the Company,
                belongs to the member who created them. Members bear responsibility for any
                infringement of others' copyrights in such posts.
              </p>
              <p>
                (2) The Company may delete certain posts or restrict the use of specific services
                for the poster without prior notice if the post falls under any of the following
                subparagraphs and may terminate the use contract if a post falling under any of the
                following subparagraphs is posted more than twice. However, the Company will not
                delete posts for the sole reason that they contain simple complaints or other
                contents unfavorable to the Company or sellers as long as they do not fall under any
                of the following paragraphs:
              </p>
              <p>
                1. In case it contains content that violates the laws of the Republic of Korea.
                <br />
                2. In case of posting or advertising content, etc., or obscene materials prohibited
                from sale by relevant laws.
                <br />
                3. In case it includes content of false or exaggerated advertising.
                <br />
                4. In case it infringes the rights, honor, credit, or other legitimate interests of
                others.
                <br />
                5. In case it induces direct transactions or posts links to other sites.
                <br />
                6. In case it includes malicious code or data that can cause malfunction of
                information and communication devices.
                <br />
                7. In case it is against social public order or good customs.
                <br />
                8. In case it is deemed to interfere with the smooth progress of the services
                provided by the Company.
                <br />
                9. In case it includes content related to criminal acts.
                <br />
                10. In case it includes content that causes political or economic disputes.
              </p>
              <p>
                (3) The Company may reproduce, distribute, transmit, display the posts created by
                members in order to promote and advertise content, etc., traded in the Service, and
                may modify or edit them within the scope that does not change the essential content.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 22. Prohibited Acts</h3>
              <p>
                <strong>Direct Transaction</strong>
              </p>
              <p>
                (1) The Company is not responsible for any problems arising from direct transactions
                conducted without using the services provided by the Company, and such
                responsibility lies with the transaction parties.
              </p>
              <p>
                (2) Sellers confirmed to have conducted or induced direct transactions may have
                their service use contract terminated, and members may report sellers who have
                conducted or induced direct transactions to the Company.
              </p>
              <br />
              <p>
                <strong>System Misuse</strong>
              </p>
              <p>
                (3) It is prohibited to use the service in abnormal ways not according to the
                service use methods provided by the Company or to access the system.
              </p>
              <p>
                (4) If system misuse is confirmed, the Company may recover some or all of the
                benefits additionally provided by the Company to the member.
              </p>
              <br />
              <p>
                <strong>Payment Fraud</strong>
              </p>
              <p>
                (5) It is prohibited to use the purchase service provided by the Company by
                misappropriating someone else's name or card information.
              </p>
              <p>
                (6) Abnormal payment methods prohibited by laws such as the Specialized Credit
                Financial Business Act, such as financing by disguising as product sales or service
                provision, are prohibited, and if confirmed, the Company may terminate the use
                contract, stop the member's transactions, and notify relevant authorities.
              </p>
              <p>
                (7) It is prohibited to conduct purchasing activities without actual intent to
                purchase the content, etc., such as when the content, etc., does not exist in
                reality or is traded only formally.
              </p>
              <br />
              <p>
                <strong>Other Prohibited Acts</strong>
              </p>
              <p>
                (8) Members shall not engage in acts such as concluding an abnormal number of
                transactions using the discount rate provided by the Company, causing loss to the
                Company, and interfering with the creation of a fair market environment.
              </p>
              <br />
              <p>
                <strong>Sanctions</strong>
              </p>
              <p>
                (9) If it is confirmed that a buyer has conducted acts according to this Article,
                the Company may stop or cancel the transaction, and if it is confirmed that the
                buyer has conducted such acts more than twice, the Company may terminate the use
                contract with the buyer and claim damages if any loss occurs due to such acts.
              </p>
            </div>
            <h2>Chapter 6. Miscellaneous</h2>

            <div className='term-block'>
              <h3>Article 23. Relationship with Related Laws</h3>
              <p>
                (1) Matters not specified in these Terms shall be governed by the "Act on Consumer
                Protection in Electronic Commerce, etc." and other related laws and general
                commercial practices.
              </p>
              <p>
                (2) In case of transactions through the Service, related laws such as the "Act on
                Consumer Protection in Electronic Commerce, etc." shall be preferentially applied to
                the transaction parties, and the transaction parties cannot claim exemption against
                the counterparty of the transaction based on the provisions of these Terms.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 24. Compensation for Damages</h3>
              <p>
                (1) If the Company or a member violates the obligations, representations, and
                warranties stipulated in these Terms, they must compensate the counterparty for the
                damage that has a substantial causal relationship with such violation of
                obligations.
              </p>
              <p>
                (2) If a member violates the obligations stipulated in these Terms or infringes on
                the copyright or other rights of others, causing the Company to be subject to claims
                for damages, etc., from third parties, the member must indemnify the Company and
                compensate for all damages incurred by the Company.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 25. Disclaimer of the Company</h3>
              <p>
                (1) The Company is not liable for any special damages incurred by members or third
                parties due to the temporary suspension of the service for the reasons mentioned in
                Article 11, Section 2.
              </p>
              <p>
                (2) The Company is exempted from liability for limiting or suspending the service
                for the reasons mentioned in Article 11, Section 3.
              </p>
              <p>
                (3) The Company is not liable for any service use disruptions caused by the member's
                fault.
              </p>
              <p>
                (4) The Company is not responsible for any damage caused by the member leaking or
                providing their personal information to others.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 26. Governing Law and Jurisdiction</h3>
              <p>
                (1) Lawsuits between the Company and members shall be governed by the laws of the
                Republic of Korea.
              </p>
              <p>
                (2) For disputes arising between the Company and members, the jurisdictional court
                shall be determined by the member's address at the time of the lawsuit, and if there
                is no address, by the local court having jurisdiction over the residence. However,
                if the member's address or residence is unclear at the time of the lawsuit, the
                jurisdictional court will be determined according to the Civil Procedure Act.
              </p>
              <p>
                (3) For members with addresses or residences abroad, the Seoul Central District
                Court of the Republic of Korea shall be the jurisdictional court for disputes
                between the Company and members, notwithstanding the preceding section.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 27. Other Provisions</h3>
              <p>
                (1) The Company and members cannot transfer the rights and obligations under these
                Terms to third parties without the prior written consent of the other party.
              </p>
              <p>
                (2) Contracts, agreements, notifications, etc., additionally made by the agreement
                between the parties in relation to these Terms, and contents notified to members by
                the Company through the Service due to changes in the Company's policies, enactment
                or amendment of laws, or instructions or guidelines from public institutions, also
                constitute part of these Terms.
              </p>
            </div>

            <h2>Addendum</h2>

            <div className='term-block'>
              <h3>1. Effective Date</h3>
              <p>(1) These Terms are effective from February 23, 2024.</p>
              <p>
                (2) The previous Terms, which have been in effect since December 26, 2016, are
                replaced by these Terms.
              </p>
            </div>
            <div className='terms-header'>
              <h1 className='members-title'>Terms of Service (Designer Members)</h1>
            </div>
            <div className='terms-body'>
              <h2>Chapter 1. General Provisions</h2>

              <div className='term-block'>
                <h3>Article 1. Purpose</h3>
                <p>
                  These Terms of Service are intended to govern the rights, obligations,
                  responsibilities, and other necessary matters between sellers and Fab365, Inc.
                  (hereinafter referred to as 'the Company') in relation to the use of content
                  selling services (hereinafter referred to as 'Service' or 'Selling Service')
                  offered by Fab365, an e-Product marketplace for 3D printing.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 2. Specification, Effectiveness, and Revision of Terms</h3>
                <p>
                  (1) The Company shall post these Terms along with the Company's trade name,
                  business location, representative's name, business registration number, contact
                  details (telephone, fax, email address, etc.) on the initial or a connected screen
                  of the Service for Members to access.
                </p>
                <p>
                  (2) The Company may revise these Terms within the limits of not violating relevant
                  laws such as the "Act on Regulation of Terms", "Basic Act on Electronic Documents
                  and Electronic Transactions", "Electronic Signature Act", "Act on Promotion of
                  Information and Communications Network Utilization and Information Protection,
                  etc.", "Act on Consumer Protection in Electronic Commerce, etc.", "Electronic
                  Financial Transactions Act", and others.
                </p>
                <p>
                  (3) When the Company revises these Terms, it will specify and announce the revised
                  Terms' application date and reasons for revision, along with the current Terms,
                  from 7 days prior to the application date until the day before the application
                  date, using the method in Section 1. However, any revisions that are
                  disadvantageous to Members will be clearly notified separately through electronic
                  means within the Service, such as email and login consent pop-up windows.
                </p>
                <p>
                  (4) If the Company notifies or announces the revised Terms as described above and
                  clearly states that the Member's consent will be deemed given if they do not
                  express their intention within 30 days, and the Member does not explicitly express
                  refusal, the Member is considered to have agreed to the revised Terms.
                </p>
              </div>

              <div className='term-block'>
                <h3>Article 3. Definition of Terms</h3>
                <p>(1) The definitions of terms used in these Terms are as follows:</p>
                <p>
                  1. “Member” ('Seller' or 'Designer Member'): An individual or corporation that has
                  provided personal information and registered with the Company, and can use the
                  purchase and selling services provided by the Company, being an individual or
                  corporation over 14 years of age.
                  <br />
                  2. “ID”: A combination of letters and numbers set by the Member and approved and
                  registered by the Company for Member identification and service use.
                  <br />
                  3. “Password”: A combination of letters and numbers set by the Member for identity
                  verification, protection of the Member's rights and confidentiality, and
                  registered with the Company.
                  <br />
                  4. “Operator”: An individual selected by the Company for overall management and
                  smooth operation of the services provided by the Company.
                  <br />
                  5. “Content”: 3D printing model data and related content traded between sellers
                  and buyers, or between the Company and buyers, through the services provided by
                  the Company.
                </p>
                <p>
                  (2) The meanings of terms used in these Terms that are not defined in Section (1)
                  or the Articles where the terms are used shall be interpreted according to general
                  trading practices.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 4. Types of Service</h3>
                <p>
                  (1) The service provided by the Company is intended to permit the use of a cyber
                  mall for trading content or related goods, etc. (hereinafter referred to as
                  'content, etc.'), or to facilitate telecommunication sales. The Company is a
                  telecommunication sales intermediary, not a party to telecommunication sales.
                  Therefore, the Company bears no responsibility for the content, etc. listed for
                  sale in the Service by individual sellers. Additionally, the Company clearly
                  indicates to buyers that it is a telecommunication sales intermediary in the
                  transaction screen for content, etc.
                </p>
                <p>
                  (2) However, the Company can directly sell content, etc. through the Service. In
                  this case, the Company indicates that it is selling the content, etc. on the
                  introduction screen or order screen of the content, etc., and the Company may bear
                  responsibility as a telecommunication seller for such transactions.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 5. Disclaimer of Agency</h3>
                <p>
                  The Company only bears the responsibility for operating and managing the system
                  efficiently as a telecommunication sales intermediary for content, etc. that it
                  does not directly sell. The Company does not act as an agent for buyers or sellers
                  in transactions related to content, etc., and members who provide and register
                  information related to such transactions bear direct responsibility for them.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 6. Disclaimer of Warranty</h3>
                <p>
                  The Company does not guarantee the presence or authenticity of the intent to sell
                  or purchase, the quality, completeness, safety, legality , or non-infringement of
                  rights of others in the content, etc. sold by sellers to buyers through the system
                  provided by the Company. All risks and responsibilities related to this are
                  entirely borne by the respective member.
                </p>
              </div>
              <h2>Chapter 2. Contract of Use and Information Protection</h2>
              <div className='term-block'>
                <h3>Article 7. Formation of Service Use Contract</h3>
                <p>
                  (1) The service use contract (hereinafter 'use contract') is established when the
                  Company accepts the application for use by an individual who wishes to use the
                  services (such as content selling intermediation) provided by the Company. The
                  Company will notify its acceptance of use on the relevant service screen or by
                  email or other methods.
                </p>
                <p>
                  (2) Individuals who wish to use the service agree to these Terms and fill in the
                  necessary information according to the member registration application form
                  determined by the Company.
                </p>
                <p>
                  (3) Membership registration can be done by individuals or business operators
                  (individual business operators and corporate business operators) who are over 14
                  years old. If an applicant uses someone else's information to apply for use,
                  service use may be restricted or punished according to relevant laws.
                </p>
                <p>
                  (4) The processing of the application for use is based on the order of
                  application, and the establishment date of membership registration is when the
                  Company's acceptance reaches the member.
                </p>
                <p>
                  (5) The Company may refuse or hold off on accepting an application for use for the
                  following reasons:
                </p>
                <p>
                  1. In case of application for use by misappropriating someone else's information.
                  <br />
                  2. If reapplying for use within 2 months from the date of termination of the use
                  contract.
                  <br />
                  3. If a member who has received a suspension of membership qualifications or
                  service provision from the Company terminates the use contract arbitrarily during
                  the period of such measures and reapplies for use.
                  <br />
                  4. In case there is no spare capacity in the facilities or there are technical
                  impediments.
                  <br />
                  5. In other cases where it is confirmed that the application for use violates
                  these Terms or is illegal or improper, or the Company deems it necessary based on
                  reasonable judgment.
                </p>
                <p>
                  (6) The Company may request proof of accuracy of the information provided during
                  the application for use from sellers within the scope allowed by relevant laws,
                  and if the seller unreasonably refuses to provide this, the Company may refuse or
                  hold off on accepting the application for use.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 8. Application for Use and Personal Information</h3>
                <p>
                  (1) Members must not provide false information when applying for use and must
                  immediately update any changes in the provided information with the latest
                  information.
                </p>
                <p>
                  (2) All notices from the Company to its members are deemed to have been made when
                  they reach the email provided by the member. Any damage caused by not updating
                  information is entirely borne by the member, and the Company is not responsible
                  for this.
                </p>
                <p>
                  (3) The collection, use, processing, and provision to third parties of the
                  member's personal information shall be governed by the{' '}
                  <a href='' onClick={e => goTo(e, FOOTER_PRIVACY)} style={{ color: '#835fec' }}>
                    [Privacy Policy]
                  </a>{' '}
                  posted by the Company on the initial or connected screen of the Service.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 9. Management of ID and Password</h3>
                <p>
                  (1) The responsibility for managing the ID and password lies with the member, and
                  under no circumstances can a member transfer or lend their ID or password to
                  others.
                </p>
                <p>
                  (2) The member and the user themselves bear the responsibility for any loss or
                  damage arising from the leakage, transfer, or lending of the ID or password
                  without the Company's fault.
                </p>
                <p>
                  (3) If a member becomes aware that their ID or password has been stolen or is
                  being used unauthorized by a third party, they must immediately notify the
                  Company, and the Company must make every effort to promptly process it.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 10. Termination of Use Contract</h3>
                <p>
                  <strong>Termination by Member</strong>
                </p>
                <p>
                  (1) Members may terminate the use contract at any time by notifying the Company of
                  their intention to terminate through the relevant service screen. However, members
                  must take necessary measures to complete all transactions at least 7 days prior to
                  notifying their intention to terminate.
                </p>
                <p>
                  (2) Members themselves bear responsibility for any disadvantages arising from
                  expressions of intent within the period mentioned in the preceding paragraph, and
                  upon termination of the use contract, the Company may recover various benefits
                  provided to the member for free.
                </p>
                <br />
                <p>
                  <strong>Termination by Company</strong>
                </p>
                <p>
                  (3) The Company may terminate the use contract if any of the following reasons
                  occur or are confirmed:
                </p>
                <p>
                  1. In case of acts that infringe the rights, honor, credit, or other legitimate
                  interests of other members or third parties or violate the laws of the Republic of
                  Korea or public order and morals.
                  <br />
                  2. In case of acts or attempts to interfere with the smooth progress of the
                  services provided by the Company.
                  <br />
                  3. If it is confirmed that there are reasons for refusal of acceptance as
                  mentioned in Article 7, Section 5.
                  <br />
                  4. In case of violation of the member's obligations, representations and
                  warranties, conditions precedent, etc., stipulated in these Terms.
                  <br />
                  5. In other cases where the Company deems it necessary to refuse to provide the
                  service based on reasonable judgment.
                </p>
                <p>
                  (4) If one party encounters any of the following circumstances, the other party
                  may terminate the use contract without prior notice:
                </p>
                <p>
                  1. If the obligations under these Terms are breached and the other party does not
                  rectify it within 7 days after being requested to do so.
                  <br />
                  2. In cases of financial insolvency, suspension of transactions by financial
                  institutions, initiation of recovery and bankruptcy procedures, administrative
                  measures such as business suspension or cancellation, preservation measures for
                  major assets, business transfer, merger, etc., making it impossible to perform the
                  use contract.
                </p>
                <p>
                  (5) If the Company terminates the contract, the Company will notify the member of
                  the reasons for termination by email, phone, or other methods. The use contract is
                  terminated when the Company's intention to terminate reaches the member.
                </p>
                <p>
                  (6) Even if the Company terminates the use contract according to this Article,
                  these Terms continue to apply to the completion of transactions already concluded
                  before termination.
                </p>
                <p>
                  (7) If the use contract is terminated according to this Article, the Company may
                  recover various benefits provided to the member for free.
                </p>

                <h3>Article 11. Service Period and Interruption</h3>
                <p>
                  (1) The service period according to these Terms is from the time the Company's
                  acceptance of the application for use reaches the member until the termination of
                  the use contract.
                </p>
                <p>
                  (2) The Company may temporarily suspend the provision of services for maintenance,
                  inspection, replacement, breakdown of computer and other information and
                  communication facilities, and disconnection of communication.
                </p>
                <p>
                  (3) The Company may limit or temporarily suspend the provision of services in case
                  of force majeure, such as natural disasters or equivalent.
                </p>
                <p>
                  (4) The Company may modify, suspend, or change all or part of the services
                  provided for free according to the Company's policy and operational needs, and
                  unless there are special regulations in relevant laws, the Company does not
                  separately compensate members.
                </p>
              </div>

              <h2>Chapter 3. Use of Service</h2>

              <div className='term-block'>
                <h3>Article 12. Service Use by Members</h3>
                <p>
                  The Company grants Members a non-transferable, non-exclusive, and worldwide
                  license to use all types of digital content included in this Service for personal,
                  non-commercial purposes.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 13. Service Fees</h3>
                <p>
                  (1) The service fee refers to the amount that the seller must pay to the Company
                  for using the service to sell content, etc. The Company determines the service fee
                  for each seller considering factors such as service provision costs, market
                  conditions, classification and type of content, etc., transaction method, sales
                  performance of the seller, and buyer satisfaction. The service fee can be
                  calculated in various ways, such as a fixed amount or a certain percentage of the
                  total sales amount through the seller's service.
                </p>
                <p>
                  (2) The Company notifies the service fee to the seller through the initial screen
                  when logging in as a seller to the service. The Company may establish or change
                  the service fee as necessary, and such establishment or changes are announced
                  through the service screen provided by the Company.
                </p>
                <p>
                  (3) The Company may discount the service fee for specific content within the range
                  of the service fee to promote sales.
                </p>
                <p>
                  (4) The service fee can be paid by deducting from the sales amount or using the
                  sales escrow, and the rate and payment method can be changed according to the
                  agreement between the Company and the seller or the Company's internal
                  circumstances.
                </p>
                <p>
                  (5) The Company issues a tax invoice for the service fee upon the seller's
                  request.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 14. Seller's Obligations</h3>
                <p>
                  (1) The seller must upload the content, etc., they wish to sell to the service and
                  directly write and manage the information required for sale, such as price,
                  description, and preview.
                </p>
                <p>
                  (2) If there are special transaction conditions or additional costs for the
                  registered products, the seller must clearly state these so that buyers can be
                  aware of them.
                </p>
                <p>
                  (3) The seller must enter the following information when registering products and
                  modify and supplement relevant information according to changes in the Product
                  Information Provision Notification after product registration:
                </p>
                <p>
                  1. Information about goods, etc., for each item.
                  <br />
                  2. Information on transaction conditions, etc., as stipulated in the "Notification
                  on Provision of Information on Goods, etc., in Electronic Commerce, etc." (Fair
                  Trade Commission Notification No. 2015-17, effective April 1, 2016, hereinafter
                  'Product Information Provision Notification').
                </p>
                <p>
                  (4) The description of the content, etc., written by the seller and the actual
                  content, etc., must not be substantially different.
                </p>
                <p>
                  (5) The seller must not sell or induce the sale of products directly to buyers
                  without using the service.
                </p>
                <p>
                  (6) The seller must always keep information such as the seller's name, phone
                  number, address, etc., which the Company is required to provide to consumers as a
                  telecommunication sales intermediary under the "Act on Consumer Protection in
                  Electronic Commerce, etc." and related laws, up to date.
                </p>
                <p>
                  (7) The seller must not sell content, etc., that infringes on intellectual
                  property rights or other rights of others.
                </p>
                <p>
                  (8) The seller must not sell content, etc., that can be used to 3D print goods
                  such as firearms, pornography, etc., which are prohibited from manufacturing,
                  distributing, possessing, using, etc., under relevant laws.
                </p>
                <p>
                  (9) The seller must not sell content, etc., that includes logical errors, data
                  different from the file format, data that can cause malfunction of 3D printers, or
                  any other error or defect that makes normal operation impossible.
                </p>
                <p>
                  (10) The seller must not cause tangible or intangible damage to the Company or the
                  Service, such as reputation damage or disrepute, due to violation of relevant laws
                  or other responsible reasons by the seller.
                </p>
                <p>
                  (11) If the seller fails to fulfill their obligations under this Article, the
                  Company may take necessary measures such as restricting the sale of the seller's
                  content, suspending service provision, terminating the use contract, etc.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 15. Withdrawal of Subscription</h3>
                <p>
                  (1) Regarding the withdrawal of subscription (refund), the seller must comply with
                  the related regulations of the "Act on Consumer Protection in Electronic Commerce,
                  etc." and "Content Industry Promotion Act" and the matters of this Article.
                </p>
                <p>
                  (2) Buyers may withdraw their subscription within 7 days from the date of purchase
                  or the date of availability of the content, etc.
                </p>
                <p>
                  (3) Notwithstanding the contents of the preceding section, the buyer cannot
                  withdraw the subscription according to the preceding paragraph against the
                  seller's will in any of the following cases:
                </p>
                <p>
                  1. If the goods, etc., are destroyed or damaged due to reasons attributable to the
                  consumer. However, this does not apply if the packaging, etc., was damaged to
                  check the contents of the goods, etc.
                  <br />
                  2. If the value of the goods, etc., has significantly decreased due to the
                  consumer's use or partial consumption.
                  <br />
                  3. If the value of the goods, etc., has significantly decreased over time to the
                  extent that it is difficult to resell.
                  <br />
                  4. If the packaging of goods, etc., that can be duplicated has been damaged.
                  <br />
                  5. If the provision of services or digital content as defined in Article 5 of the
                  "Basic Act on Cultural Industry Promotion" has started (in this Service, the
                  completion of the download of the content, etc., is considered the start of
                  provision). However, in the case of a contract consisting of divisible services or
                  divisible digital content, this does not apply to the uninitiated part.
                  <br />
                  6. In other cases prescribed by Presidential Decree of the Republic of Korea for
                  the safety of transactions.
                </p>
                <p>
                  (4) In the case of goods, etc., for which withdrawal of subscription, etc., is not
                  possible according to the provisions from Paragraph 2 to Paragraph 5 of the
                  preceding section, the seller must take measures so as not to hinder the exercise
                  of rights of withdrawal of subscription, etc., by clearly indicating that fact on
                  the packaging of the goods, etc., or providing a test-use product or other
                  methods. However, in the case of digital content that cannot be withdrawn under
                  Paragraph 5 of the preceding section, the seller shall take measures so as not to
                  hinder the exercise of rights of withdrawal of subscription, etc., by indicating
                  that withdrawal of subscription, etc., is not possible, along with the following
                  methods prescribed by Presidential Decree of the Republic of Korea:
                </p>
                <p>
                  1. Allowing partial use: Providing a preview, pre-listen, etc., of part of the
                  digital content.
                  <br />
                  2. Allowing temporary use: Providing digital content by setting a certain usage
                  period.
                  <br />
                  3. Providing trial-use digital content: Providing digital content that can only
                  use some limited functions.
                  <br />
                  4. In cases where it is difficult to provide test-use products by the methods in
                  Subparagraphs 1 to 3: Providing information on digital content.
                </p>
                <p>
                  (5) If the seller does not comply with the withdrawal of subscription (refund)
                  regulations stipulated in relevant laws and these Terms, the Company may take
                  measures against the seller such as warning, temporary suspension of content
                  sales, suspension of selling service, termination of the use contract, etc.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 16. Settlement</h3>
                <p>
                  (1) Settlement for the sales amount of content, etc., sold by the seller is
                  calculated based on the sales price minus the service fee, and it is the principle
                  to pay within 30 days after the seller requests settlement through the settlement
                  screen, but the payment deadline can be negotiated individually with each seller.
                </p>
                <p>
                  (2) Sellers can check the estimated settlement amount on the settlement screen by
                  logging into the service. However, the Company may not be able to provide an
                  automated settlement screen to sellers due to development, maintenance, or
                  management of the service, and in such cases, the Company may notify sellers of
                  sales details and their settlement details by email monthly.
                </p>
                <p>
                  (3) The Company may withhold settlements for up to 3 months if there are multiple
                  requests for withdrawal of subscription (refund) or reasonable claims of buyer
                  dissatisfaction (claims) for the content, etc., sold by the seller, and refunds
                  exceeding 50% of the expected settlement amount are anticipated within 1 month
                  after settlement.
                </p>
              </div>
              <h2>Chapter 4. Use of Additional Services</h2>
              <div className='term-block'>
                <h3>Article 17. Sales Promotion Service</h3>
                <p>
                  The Company may provide sellers with discount coupons or 'Credits' that can be
                  given to buyers to promote the sale of content, etc., according to the Company's
                  operational policies.
                </p>
              </div>
              <h2>Chapter 5. Management and Protection of Users</h2>
              <div className='term-block'>
                <h3>Article 18. User Management</h3>
                <p>
                  (1) The Company may take the following measures against members who violate the
                  contents of these Terms or relevant laws:
                </p>
                <p>
                  1. Recovery of some or all of the benefits additionally provided by the Company.
                  <br />
                  2. Restriction on the use of specific services.
                  <br />
                  3. Termination of the use contract.
                  <br />
                  4. Claim for damages.
                </p>
                <p>
                  (2) If the Company takes any of the measures mentioned in each subparagraph of the
                  preceding paragraph, the Company will notify the member in advance by telephone or
                  email. In cases where the member's contact is disconnected or urgent, such as
                  emergency situations, the Company may take measures first and then notify
                  afterwards.
                </p>
                <p>
                  (3) Members may argue against the Company's measures according to this Article if
                  they have reasons for objection.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 19. Copyright and License</h3>
                <p>
                  <strong>Content, etc.</strong>
                </p>
                <p>
                  (1) All copyright of the content, etc., produced and sold through the Service by
                  the seller remains with the copyright owner, and no provision of these Terms shall
                  be interpreted as transferring copyright to the Company or the buyer.
                </p>
                <p>
                  (2) By uploading content, etc., to the Service and registering them as sales
                  content, the seller grants the Company an irrevocable, non-exclusive,
                  sublicensable, royalty-free, fully-paid, worldwide license and sublicensing rights
                  to reproduce, distribute, display, perform, and create derivative works of such
                  content, etc., for the purpose of operating, advertising, marketing the Service
                  and intermediating the transaction of content, etc., between the Company and the
                  buyer or between the sellers and the buyer, and represents and warrants that the
                  seller has the rights to grant such rights to the Company.
                </p>
                <p>
                  (3) As long as the Company uses the content, etc., within the scope and purpose of
                  this agreement and no separate contract is made, the Company does not pay any
                  additional royalties to the seller.
                </p>
                <br />
                <p>
                  <strong>Posts</strong>
                </p>
                <p>
                  (4) The copyright of various posts or works (hereinafter 'posts') such as
                  evaluations and opinions about content, etc., sold in the Service, Q&A, and other
                  content, etc., created by members using the services provided by the Company,
                  belongs to the member who created them. Members bear responsibility for any
                  infringement of others' copyrights in such posts.
                </p>
                <p>
                  (5) The Company may delete certain posts or restrict the use of specific services
                  for the poster without prior notice if the post falls under any of the following
                  subparagraphs and may terminate the use contract if a post falling under any of
                  the following subparagraphs is posted more than twice. However, the Company will
                  not delete posts for the sole reason that they contain simple complaints or other
                  contents unfavorable to the Company or sellers as long as they do not fall under
                  any of the following paragraphs:
                </p>
                <p>
                  1. In case it contains content that violates the laws of the Republic of Korea.
                  <br />
                  2. In case of posting or advertising content, etc., or obscene materials
                  prohibited from sale by relevant laws.
                  <br />
                  3. In case it includes content of false or exaggerated advertising.
                  <br />
                  4. In case it infringes the rights, honor, credit, or other legitimate interests
                  of others.
                  <br />
                  5. In case it induces direct transactions or posts links to other sites.
                  <br />
                  6. In case it includes malicious code or data that can cause malfunction of
                  information and communication devices.
                  <br />
                  7. In case it is against social public order or good customs.
                  <br />
                  8. In case it is deemed to interfere with the smooth progress of the services
                  provided by the Company.
                  <br />
                  9. In case it includes content related to criminal acts.
                  <br />
                  10. In case it includes content that causes political or economic disputes.
                </p>
                <p>
                  (6) The Company may reproduce, distribute, transmit, display the posts created by
                  members in order to promote and advertise content, etc., traded in the Service,
                  and may modify or edit them within the scope that does not change the essential
                  content.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 20. Prohibited Acts</h3>
                <p>
                  <strong>False Transactions</strong>
                </p>
                <p>
                  (1) Sellers must not write or indicate opinions about the content, etc., being
                  sold by themselves or other sellers in the Service to influence the sales of the
                  content, etc.
                </p>
                <br />
                <p>
                  <strong>Direct Transaction</strong>
                </p>
                <p>
                  (2) The Company is not responsible for any problems arising from direct
                  transactions conducted without using the services provided by the Company, and
                  such responsibility lies with the transaction parties.
                </p>
                <p>
                  (3) Sellers confirmed to have conducted or induced direct transactions may have
                  their service use contract terminated, and members may report sellers who have
                  conducted or induced direct transactions to the Company.
                </p>
                <br />
                <p>
                  <strong>System Misuse</strong>
                </p>
                <p>
                  (4) It is prohibited to use the service in abnormal ways not according to the
                  service use methods provided by the Company or to access the system.
                </p>
                <p>
                  (5) If system misuse is confirmed, the Company may recover some or all of the
                  benefits additionally provided by the Company to the member.
                </p>
                <br />
                <p>
                  <strong>Sanctions</strong>
                </p>
                <p>
                  (6) If it is confirmed that a seller has conducted acts according to this Article,
                  the Company may stop or cancel the transaction, and if it is confirmed that the
                  seller has conducted such acts more than twice, the Company may terminate the use
                  contract with the seller and claim damages if any loss occurs due to such acts.
                </p>
              </div>
              <h2>Chapter 6. Miscellaneous</h2>
              <div className='term-block'>
                <h3>Article 21. Relationship with Related Laws</h3>
                <p>
                  (1) Matters not specified in these Terms shall be governed by the "Act on Consumer
                  Protection in Electronic Commerce, etc." and other related laws and general
                  commercial practices.
                </p>
                <p>
                  (2) In case of transactions through the Service, related laws such as the "Act on
                  Consumer Protection in Electronic Commerce, etc." shall be preferentially applied
                  to the transaction parties, and the transaction parties cannot claim exemption
                  against the counterparty of the transaction based on the provisions of these
                  Terms.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 22. Relationship with Related Terms</h3>
                <p>
                  (1) For the use of the content, etc., purchasing service by the seller, the
                  contents of the [Terms of Service (General Members)] shall apply.
                </p>
                <p>
                  (2) In the relationship between the Company and the seller, if there is a conflict
                  between the contents of these Terms and the [Terms of Service (General Members)],
                  the contents of these Terms shall prevail.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 23. Compensation for Damages</h3>
                <p>
                  (1) If the Company or a member violates the obligations, representations, and
                  warranties stipulated in these Terms, they must compensate the counterparty for
                  the damage that has a substantial causal relationship with such violation of
                  obligations.
                </p>
                <p>
                  (2) If a member violates the obligations stipulated in these Terms or infringes on
                  the copyright or other rights of others, causing the Company to be subject to
                  claims for damages, etc., from third parties, the member must indemnify the
                  Company and compensate for all damages incurred by the Company.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 24. Confidentiality</h3>
                <p>
                  (1) The Company or the member shall not disclose information obtained from the
                  other party such as the buyer list, technical information, production and sales
                  plans, know-how, etc., which are managed as secrets, to third parties, except when
                  required by law, and shall not use such information for purposes other than the
                  performance of the use contract.
                </p>
                <p>
                  (2) The obligation of the preceding paragraph shall continue for 3 years after the
                  termination of the use contract.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 25. Disclaimer of the Company</h3>
                <p>
                  (1) As a telecommunications sales broker, the Company only provides services for
                  the transaction of content, etc. In the event of a dispute related to the
                  information on content, etc., registered by the seller or transactions with
                  buyers, the Company does not intervene in the dispute, and all responsibilities
                  resulting from the dispute are borne by the seller. However, the Company may
                  exceptionally intervene in such disputes through the dispute resolution mechanism
                  (including customer service) operated by the Company for the reasonable and smooth
                  adjustment of disputes, and the seller must respect the decision of the dispute
                  resolution center based on the principle of good faith to the fullest extent.
                </p>
                <p>
                  (2) If there is a demand from the rightful owner, the Company can delete or modify
                  the information related to the content, etc., and the seller cannot claim
                  compensation for damages caused by this from the Company.
                </p>
                <p>
                  (3) The Company is not responsible for any special damages incurred by members or
                  third parties due to the temporary suspension of the service for the reasons
                  mentioned in Article 11, Section 2.
                </p>
                <p>
                  (4) The Company is exempt from liability for limiting or suspending the service
                  for the reasons mentioned in Article 11, Section 3.
                </p>
                <p>
                  (5) The Company is not liable for any service usage disruptions caused by the
                  member's fault.
                </p>
                <p>
                  (6) The Company is not responsible for any damage caused by the member leaking or
                  providing their personal information to others.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 26. Governing Law and Jurisdiction</h3>
                <p>
                  (1) Lawsuits between the Company and members shall be governed by the laws of the
                  Republic of Korea.
                </p>
                <p>
                  (2) For disputes arising between the Company and members, the jurisdictional court
                  shall be determined by the member's address at the time of the lawsuit, and if
                  there is no address, by the local court having jurisdiction over the residence.
                  However, if the member's address or residence is unclear at the time of the
                  lawsuit, the jurisdictional court will be determined according to the Civil
                  Procedure Act.
                </p>
              </div>
              <div className='term-block'>
                <h3>Article 27. Other Provisions</h3>
                <p>
                  (1) The Company and members cannot transfer the rights and obligations under these
                  Terms to third parties without the prior written consent of the other party.
                </p>
                <p>
                  (2) Contracts, agreements, notifications, etc., additionally made by the agreement
                  between the parties in relation to these Terms, and contents notified to members
                  by the Company through the Service due to changes in the Company's policies,
                  enactment or amendment of laws, or instructions or guidelines from public
                  institutions, also constitute part of these Terms.
                </p>
              </div>
              <h2>Addendum</h2>
              <div className='term-block'>
                <h3>1. Effective Date</h3>
                <p>(1) These Terms are effective from February 23, 2024.</p>
                <p>
                  (2) The previous Terms, which have been in effect since December 26, 2016, are
                  replaced by these Terms.
                </p>
              </div>
            </div>
          </MembersWrapper>
        </div>
      </MembersContainer>
    </>
  );
};

export default TermEn;
