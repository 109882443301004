import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FOOTER_TERMS } from '../../../../route/constant';

function English() {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };
  return (
    <div id='main'>
      <div class='container'>
        <div class='terms'>
          <div class='terms-header'>
            <h1 class='terms-title'>Credit terms and Conditions</h1>
          </div>
          <div class='terms-body'>
            <div class='term-block'>
              <h3>Article 1. Types of Credits</h3>
              <p>
                To use content, payment must be made using electronic payment methods available
                within ‘FAB365’. These electronic payment methods consist of electronic payment
                methods sold for a fee (hereinafter referred to as 'Credits') and electronic payment
                methods that are sold for a fee or provided free of charge but have a shorter usage
                period than 'Credits' (hereinafter referred to as 'Bonus Credits').
              </p>
            </div>

            <div class='term-block'>
              <h3>Article 2. Charging Credits</h3>
              <p>
                (1) Members may acquire 'Credits' or 'Bonus Credits' by paying the fees set by the
                Company. The Company will post the methods of purchasing 'Credits', the purchase
                units, and payment methods within ‘FAB365’.
              </p>
              <p>
                (2) The Company can sell 'Credits' or 'Bonus Credits' in the following ways, and the
                sales methods are subject to change or addition:
              </p>
              <p>
                1. Single Purchase Products: Products that provide 'Credits' or 'Bonus Credits'
                differently according to the amount paid by the member.
                <br />
                2. Limited Sale Products: Products that allow members to purchase 'Credits' or
                'Bonus Credits' only once or a limited number of times.
                <br />
                3. Subscription Products: Products where a member agrees in advance with the Company
                to purchase 'Credits' regularly every month through a payment method selected by the
                member, and the Company regularly provides 'Credits' or 'Bonus Credits' in
                accordance with the agreement.
              </p>
              <p>
                (3) In the case where a member purchases the Subscription Product as specified in
                section (2), paragraph 3 of this article, the following provisions shall apply with
                priority:
              </p>
              <p>
                1. When a member purchases a Subscription Product, payment for the product will
                proceed automatically on a set date each month unless the member expresses a desire
                to discontinue using the product.
                <br />
                2. Unless the member indicates withdrawal, the Company will provide 'Credits' and
                other additional rewards in accordance with the conditions advertised/displayed to
                the member at the time of purchase of the Subscription Product.
                <br />
                3. If a member no longer wishes to use a Subscription Product, they must express
                their intention to discontinue use to the Company or the third party providing the
                payment service for the Subscription Product. The Company will separately indicate
                and guide the business operator to whom the discontinuation of use must be
                expressed, depending on the payment method for the Subscription Product.
                <br />
                4. The expression of intention to discontinue use as mentioned in the preceding
                paragraph shall not be considered as an expression of intention to withdraw the
                offer to purchase 'Credit' products from the Company. Such expressions of intention
                only have the effect of notifying the discontinuation of future regular payments and
                do not affect the withdrawal, refund, or similar matters related to 'Credits'
                provided through regular payments. Matters related to the withdrawal, refund, or
                similar of 'Credits' are subject to the provisions of{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS)} style={{ color: '#835fec' }}>
                  [Service Terms and Conditions (General Members)]
                </a>{' '}
                Article 17.
                <br />
                5. Other matters not specified in this paragraph shall be governed by the provisions
                of other Articles of these Terms and Conditions.
              </p>
              <p>
                (4) No interest earnings will be generated on 'Credits' or 'Bonus Credits' purchased
                or received by the member.
              </p>
              <p>
                (5) The payment methods for purchasing 'Credits' or 'Bonus Credits' can be any of
                the following methods provided by the Company:
              </p>
              <p>
                1. Account transfer
                <br />
                2. Credit card payment
                <br />
                3. Telephone or mobile phone payment
                <br />
                4. Gift certificate payment
                <br />
                5. Payment by electronic currency
                <br />
                6. Other payment methods provided by the Company
              </p>
              <p>
                (6) When a member makes a purchase of 'Credits' or 'Bonus Credits' and pays the
                purchase price in foreign currency, the actual billed amount charged to the member
                may differ from the amount the member expected to pay to the Company at the time of
                purchase due to fluctuations in exchange rates.
              </p>
            </div>

            <div class='term-block'>
              <h3>Article 3. Provision of Bonus Credits, etc.</h3>
              <p>
                (1) The Company may provide users with 'Bonus Credits', 'Credits', coupons, etc.,
                (hereinafter collectively referred to as 'Bonus Credits, etc.') for free through
                events, campaigns, promotions, and other methods.
              </p>
              <p>
                (2) 'Bonus Credits, etc.' provided free of charge by the Company can only be used
                within their validity period and will expire after this period. 'Bonus Credits,
                etc.' provided for free are not eligible for a refund.
              </p>
              <p>
                (3) The Company may limit the methods or scope of use for 'Bonus Credits, etc.' and
                will provide specific details through a separate screen.
              </p>
              <p>
                (4) The Company may terminate the service of 'Bonus Credits, etc.' In such cases,
                the provisions of{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS)} style={{ color: '#835fec' }}>
                  [Service Terms and Conditions (General Members)]
                </a>{' '}
                Article 11, section (4) shall apply, and any unused 'Bonus Credits, etc.' will
                expire by the announced termination date of the service.
              </p>
            </div>

            <div class='term-block'>
              <h3>Article 4. Precautions Regarding Credits and Bonus Credits, etc.</h3>
              <p>
                (1) 'Credits', 'Bonus Credits, etc.' provided by the Company cannot be exchanged for
                cash, goods, or other items of economic value outside of the use of the Company's
                services. 'Credits', 'Bonus Credits, etc.' are held and used on an account basis,
                and it is not possible to link or combine 'Credits', 'Bonus Credits, etc.' across
                multiple accounts held by a user.
              </p>
              <p>
                (2) 'Credits' and 'Bonus Credits' are used in the following order: 'Bonus Credits'
                received for free, 'Bonus Credits' purchased, and 'Credits' purchased. For the same
                type of 'Bonus Credits' or 'Credits', those with the earliest expiration date will
                be used first.
              </p>
            </div>

            <div class='term-block'>
              <h3>Article 5. Withdrawal of Subscription for Credits</h3>
              <p>
                (1) The following provisions apply to the withdrawal of subscription for the
                purchase of 'Credits':
              </p>
              <p>
                1. A member may withdraw their subscription within 7 days from the date of the
                contract for the purchase of 'Credits' or from the date the 'Credits' are credited
                to the member's ID according to the purchase contract.
                <br />
                2. A member cannot withdraw their subscription against the will of the Company in
                any of the following cases:
                <br />
                &nbsp; &nbsp; &nbsp;① If the member has used part of the 'Credits'.
                <br />
                &nbsp; &nbsp; &nbsp;② In other cases specified by law for the safety of
                transactions.
                <br />
                3. In cases where subscription withdrawal is permissible, it is the principle of the
                Company to refund the payment amount using the same method as the transaction
                cancellation. However, if it is not possible to cancel the transaction or refund the
                payment amount using the same method, a cash refund can be made.
              </p>
              <p>
                (2) Matters related to the refund of 'Credits' to which the provisions of this
                Article do not apply shall be governed by the provisions of Article 6.
              </p>
            </div>

            <div class='term-block'>
              <h3>Article 6. Refund of Credits</h3>
              <p>
                (1) The validity period for using 'Credits' is until 5 years from the date of
                purchase of the 'Credits', while the validity period for 'Bonus Credits' is
                individually indicated/notified to the member in a manner that is easily accessible
                before the purchase of 'Bonus Credits'. 'Credits' and 'Bonus Credits' are not
                refundable once their validity period has expired.
              </p>
              <p>
                (2) If a member requests a refund for unused 'Credits' purchased, before the expiry
                of the validity period (including Article 5), the Company will refund at the unit
                price at the time of purchase of those 'Credits'. However, the Company may establish
                a separate refund policy considering discounts or other factors related to 'Credit'
                products, and such a separate refund policy will be guided and displayed in a manner
                that is easily accessible to members before purchasing the 'Credit' products.
              </p>
              <p>
                (3) At the time of refund, a refund fee, which is the greater amount of 10% of the
                refund amount or $1.00, will be deducted. Therefore, if the amount before deduction
                of the refund fee is less than $1.00, a refund is not possible.
              </p>
              <p>
                (4) If a member charges 'Credits' or 'Bonus Credits' through actions prohibited by
                law, such as misusing payment information, and requests a refund of 'Credits', the
                Company may refuse to refund the 'Credits'.
              </p>
              <p>
                (5) The Company is not responsible for any losses incurred due to the member’s
                failure to accurately input refund information in relation to the refund.
              </p>
              <p>
                (6) Refunds may be restricted in cases where the contract is terminated due to the
                member's fault, such as violations of current laws and regulations or significant
                breaches of terms and conditions.
              </p>
            </div>
            <div class='term-block'>
              <h3>Addendum</h3>
              <p>(1) These terms and conditions shall take effect from February 23, 2024</p>
              <p>
                (2) The previous point terms and conditions, which have been in effect since May 30,
                2019, are replaced by these terms and conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default English;
