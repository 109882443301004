import { useEffect, useState } from 'react';
import ItemSlide from '../../../component/slider/ItemSlide';
import { isFree } from '../../../util/itemData';

import DownloadButton from './DownloadButton';
import BuyDataNowButton, { ToggleSwitchStyle } from './BuyDataNowButton';
import RewardBuyButton from './RewardBuyButton';
import styled from 'styled-components';
import { ImgStyled } from '../../../styles/ImgStyled';
import { Link } from 'react-router-dom';
import {
  CartBtn,
  CategoryStyle,
  Container,
  DesignerStyle,
  ItemName,
  LikeBtn,
  ProductInfo,
} from './TopContentStyle';
import ItemPrice from './ItemPrice';
import Information from './Information';
import ShareButton from '../../../common/sharBtn/ShareButton';
import ItemBanner from './ItemBanner';
import PurchaseButton from './ToggleSwitch';
import EditModelBtn from '../../../component/mangemodel/common/EditModelBtn';
import ActionButton from './ActionButton';
const TopContent = ({
  itemData,
  studio,
  detail,
  isLoading,
  isLike,
  freeTicketCount,
  addBag,
  onClickLikeButton,
  onClickDownload,
  hasOwn,
  onClickPage,
  isMobile,
  isLogin,
  downloadStatus,
}) => {
  const [hideEditModelBtn, setHideEditModelBtn] = useState(window.innerWidth <= 700);
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 700) {
        setHideEditModelBtn(false);
      } else {
        setHideEditModelBtn(true);
      }
    });
  }, []);

  return (
    <div className='container'>
      <Box className='store-detail-content'>
        <ItemSlide data={itemData?.imgs} studioName={studio?.name} exclusive={detail.exclusive} />
        <div className='store-detail-content-description'>
          <div className='store-detail-content-description-header'>
            <CategoryStyle categoryName={detail.category_name}>
              <Link to={`/categories`}>Category</Link>
              <ImgStyled src='/assets/img/itemDetail/chevron_forward _gray.png' alt='arrow-right' />
              <Link to={`/categories/${detail.category_name}`} className='detail-category-name'>
                {detail.category_name}
              </Link>
            </CategoryStyle>
            <DesignerStyle>
              <button className='designer-info' onClick={() => onClickPage(studio)}>
                <ImgStyled src={studio?.logo} alt='' />
                <div>{studio?.name}</div>
              </button>

              {detail?.isDesigner && isLogin && !hideEditModelBtn && (
                <>
                  <EditModelBtn designerName={studio?.name} itemId={detail?.id} />
                </>
              )}
              <ShareButton detail={detail} />
            </DesignerStyle>
            <ProductInfo reword={detail.price === '0.00'}>
              <ItemName reword={detail.price === '0.00' && !detail.isOwn}>{detail.name}</ItemName>
              {isLoading ? (
                <div style={{ height: '45px' }}></div>
              ) : (
                detail.status !== 'secret' && (
                  <ItemPrice
                    itemNumber={detail.id}
                    price={isFree(detail.free) ? 0 : detail.price}
                    freeTicketCount={freeTicketCount}
                    isLogin={isLogin}
                    qty={detail.qty}
                    discountRate={detail.discountRate}
                    originPrice={detail.originPrice}
                    finalPrice={detail.price}
                    secretProduct={detail.status}
                    isMobile={isMobile}
                    margin='0 0 50px 0'
                    priceOption={detail?.price_option}
                    isOwn={detail.isOwn}
                    test={detail}
                    downloadStatus={downloadStatus}
                    buyStatus={detail?.buy_status}
                    isDesigner={detail?.isDesigner && isLogin}
                  />
                )
              )}
            </ProductInfo>
            <Information
              className='information'
              printingTime={detail?.print_time}
              totalWeight={detail?.total_weight}
              allFileSize={detail?.allFileSize}
              released={detail?.released}
              fileCount={detail?.print_count}
              lpartSizeX={detail?.lpart_size_x}
              lpartSizeY={detail?.lpart_size_y}
              lpartSizeZ={detail?.lpart_size_z}
              // dataFileModify={detail?.date_file_modify}
              dataFileModify={detail?.file_modify_date}
            />
            {detail?.isDesigner && isLogin ? (
              <>
                <ActionButton
                  type='download'
                  onClickDownload={onClickDownload}
                  isLogin={isLogin}
                  id={detail.id}
                  isLoading={isLoading}
                  showProduct={detail.status}
                  isOwn={detail.isOwn}
                  priceOption={detail.price_option}
                  isDesigner={detail.isDesigner && isLogin}
                />
                <LikeBtn
                  disabled={detail.status === 'secret'}
                  secret={detail.status === 'secret'}
                  download={detail.isDesigner && isLogin}
                  onClick={() => onClickLikeButton(Number(detail?.studio_id))}
                  style={{ marginTop: '6px' }}
                >
                  <span>
                    {detail.status === 'secret' ? (
                      <ImgStyled src='/assets/img/SVG/favorite-fff.svg' alt='Secret' />
                    ) : isLike ? (
                      <ImgStyled src='/assets/img/SVG/favorite-fill.svg' alt='Liked' />
                    ) : (
                      <ImgStyled src='/assets/img/SVG/favorite.svg' alt='Not Liked' />
                    )}
                  </span>
                </LikeBtn>
              </>
            ) : (
              freeTicketCount !== undefined && (
                <div className='product-actions'>
                  {(isLogin && detail.isOwn) || detail.price_option === 'free' ? (
                    <ActionButton
                      type='download'
                      onClickDownload={onClickDownload}
                      isLogin={isLogin}
                      id={detail.id}
                      isLoading={isLoading}
                      showProduct={detail.status}
                      isDesigner={detail.isDesigner && isLogin}
                      isOwn={detail.isOwn}
                      priceOption={detail.price_option}
                    />
                  ) : null}

                  {detail.price_option === 'paid' && !detail.isOwn && (
                    <>
                      {/*TODO 추후 추가예정 */}
                      {/* <ToggleSwitchStyle>
                        Purchase Including Commercial License <PurchaseButton purchase={true} />
                      </ToggleSwitchStyle> */}
                      <ActionButton
                        type='buy'
                        isLogin={isLogin}
                        id={detail.id}
                        isLoading={isLoading}
                        showProduct={detail.status}
                        isDesigner={detail.isDesigner && isLogin}
                      />
                    </>
                  )}

                  {detail.price_option === 'reward' && !detail.isOwn && (
                    <ActionButton
                      type='reward'
                      isLogin={isLogin}
                      id={detail.id}
                      isLoading={isLoading}
                      showProduct={detail.status}
                      isDesigner={detail.isDesigner && isLogin}
                      freeTicketCount={freeTicketCount}
                      qty={detail.qty}
                    />
                  )}
                  <Container className='Container'>
                    <LikeBtn
                      disabled={detail.status === 'secret'}
                      secret={detail.status === 'secret'}
                      download={(isLogin && detail.isOwn) || detail.price_option !== 'paid'}
                      onClick={() => onClickLikeButton(Number(detail?.studio_id))}
                    >
                      <span>
                        {detail.status === 'secret' ? (
                          <ImgStyled src='/assets/img/SVG/favorite-fff.svg' alt='Secret' />
                        ) : isLike ? (
                          <ImgStyled src='/assets/img/SVG/favorite-fill.svg' alt='Liked' />
                        ) : (
                          <ImgStyled src='/assets/img/SVG/favorite.svg' alt='Not Liked' />
                        )}
                      </span>
                    </LikeBtn>
                    {hasOwn ||
                      (detail.price_option === 'paid' && detail.price > 0 && (
                        <CartBtn
                          secret={detail.status === 'secret'}
                          disabled={detail.status === 'secret'}
                          onClick={() => addBag(Number(detail?.studio_id))}
                        >
                          {detail.status !== 'secret' && (
                            <ImgStyled
                              src='/assets/img/itemDetail/add_shopping_cart.png'
                              alt='Cart'
                            />
                          )}
                          {detail.status === 'secret' ? 'Not available' : 'Add to Cart'}
                        </CartBtn>
                      ))}
                  </Container>
                </div>
              )
            )}
          </div>
        </div>
      </Box>
      <ItemBanner
        price={detail.price}
        isLogin={isLogin}
        bannerName={detail.price > 0 ? 'creditBanner' : 'freeItemBanner'}
      />
    </div>
  );
};

export default TopContent;

const Box = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 1000px) {
    .store-detail-content-description .product-actions {
      margin-top: 0 !important;
    }
  }
`;
