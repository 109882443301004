import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appleLogin } from '../../action/request';
import { Ss, ls } from '../../constant';
import UserContext from '../../context/user/user';
import { ITEMS, SNS_SIGNUP } from '../../route/constant';
import { useState } from 'react';
import { gtagOnClickLogin } from '../../action/gTag';
import { RETURN_CODE } from '../../action/constant';
import { useRecoilValue } from 'recoil';
import { isWelcomeModal } from '../../atom/atom';
import { useCookies } from 'react-cookie';

function AppleCallback() {
  const [cookies] = useCookies(['autoLogin']);
  const isWelcomeModalValue = useRecoilValue(isWelcomeModal);
  const location = useLocation();
  const [code, setCode] = useState();
  const { action: userAction, state: userState } = useContext(UserContext);
  const navigate = useNavigate();

  // 인가코드 가져오기
  const getCode = async () => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationCode = urlParams.get('code');
    setCode(authorizationCode);
    // console.log('authorizationCode', authorizationCode);
  };
  const getUserInfo = async code => {
    try {
      // const from = userState.isApp ? 'M' : 'W';
      const from = userState.isApp ? 'M' : cookies.autoLogin ? 'A' : 'W';
      const { data: res } = await appleLogin(code, from);

      // block된 유저일 경우
      if (res.returnCode === RETURN_CODE.C00008) {
        alert(res.message);
        return navigate('/');
      }

      // console.log('res', res);
      // console.log('Apple get User 요청 완료');
      const userInfo = res.data.userInfo;
      const userToken = res.data.token;

      if (userInfo) {
        // token을 localStorage에 저장
        localStorage.setItem(ls.id, userInfo.id_uesr);
        localStorage.setItem(ls.accessToken, userToken.access_token);
        localStorage.setItem(ls.refreshToken, userToken.refresh_token);
        // userInfo context에 저장
        userAction.setEmail(userInfo.email);
        userAction.setId(userInfo.id_uesr);
        userAction.setIsLogin(true);
        userAction.setThumbnail(userInfo.thumbnail);
        userAction.setIsLogin(false);
        if (sessionStorage.getItem(Ss.itemNumber)) {
          navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
          sessionStorage.removeItem(Ss.itemNumber);
        }

        if (isWelcomeModalValue) {
          navigate('/');
          localStorage.removeItem(ls.loginPath);
        } else {
          navigate(localStorage.getItem(ls.loginPath));
          localStorage.removeItem(ls.loginPath);
        }
        // else {
        //   // 로그인하면 로컬스토리지에 저장 된 기존에 있던 위치로 이동
        //   navigate(localStorage.getItem(ls.loginPath));
        //   // 로그인 위치로 이동 후 로그인 위치 정보 삭제
        //   localStorage.removeItem(ls.loginPath);
        // }
        // gtag 애플 로그인 추적 태그
        gtagOnClickLogin('Apple');
      }
      // 이메일 null 이면 알림
      if (res.data.email === null) {
        // TODO : 문구 컨펌받기
        alert(
          'You must set up an email in your Apple account before you can join. For more information, please contact Fab365.'
        );
      }
      // 회원가입 처리
      if (res.data.id_user === null) {
        // console.log('회원가입');
        // user context에 data 저장
        userAction.setSnsUserInfo(res.data);
        navigate(SNS_SIGNUP);
      }
    } catch (error) {
      console.error(error);
      navigate('/');
      alert('Request failed.');
    }
  };

  useEffect(() => {
    getCode();
  }, [location.search, navigate]);

  useEffect(() => {
    if (code !== undefined) {
      getUserInfo(code);
    }
  }, [code]);

  return null;
}

export default AppleCallback;
