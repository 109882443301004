import { UserEmailSignUpContainer } from './style/SignupStyle';

const UserEmailSignUp = ({
  onCreateAccount,
  email,
  password,
  onChangeEmail,
  onChangePassword,
  confirmPassword,
  onChangeConfirmPassword,
  handleFocus,
  handleKeyPress,
  setIsEmailSignUp,
}) => {
  return (
    <UserEmailSignUpContainer className='UserEmailSignUpContainer'>
      <input
        type='text'
        placeholder='Email address'
        className='form-input'
        name='email'
        value={email}
        onChange={onChangeEmail}
        onKeyDown={handleKeyPress}
        onFocus={e => handleFocus(e)}
      />
      <input
        type='password'
        placeholder='Password'
        className='form-input'
        name='password'
        value={password}
        onChange={onChangePassword}
        onKeyDown={handleKeyPress}
        maxLength={36}
        onFocus={e => handleFocus(e)}
      />
      <input
        type='password'
        placeholder='Confirm password'
        className='form-input'
        name='confirmPassword'
        value={confirmPassword}
        onChange={onChangeConfirmPassword}
        onKeyDown={handleKeyPress}
        maxLength={36}
        onFocus={e => handleFocus(e)}
      />
      <button type='button' className='btn btn-full signup' onClick={onCreateAccount}>
        Create Account
      </button>
      <button type='button' className='btn btn-full signup' onClick={() => setIsEmailSignUp(false)}>
        <img src='/assets/img/login/arrow_forward_ios.png' alt='google' />
        <p>Social sign up</p>
      </button>
    </UserEmailSignUpContainer>
  );
};

export default UserEmailSignUp;
