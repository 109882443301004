import Layout from '../../../component/layout/Layout';
import { TAB_REWARD_TICKET } from '../component/constant';
import MyTab from '../component/MyTab';
import moment from 'moment';
import './MyFreeTicket.css';
import TicketStatus from './component/TicketStatus';
import { STATUS_ACTIVE, STATUS_EXPIRED, STATUS_USED } from './component/constant';
import { useEffect } from 'react';
import { getMyTickets } from '../../../action/request';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';

const MyFreeTicket = () => {
  const [freeTicketCount, setFreeTicketCount] = useState(0);
  const [freeTicketHistory, setFreeTicketHistory] = useState([]);
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const isExpired = data => {
    if (data.status === STATUS_EXPIRED) {
      return true;
    }

    if (data.status === STATUS_ACTIVE) {
      return moment() > moment(data.expiredAt);
    }
    return false;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const {
        data: { data: res },
      } = await getMyTickets();
      setFreeTicketCount(res.ticketCount);
      setFreeTicketHistory(res.tickets);
      // console.log(res.tickets);
    } catch (e) {}
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  return (
    <>
      <Layout>
        <div id='main' className='my-free-ticket-page'>
          <div className='page-top mypage'>
            <div className='container'>
              <h1 className='page-title align-left'>My Page</h1>
            </div>
          </div>
          <MyTab tab={TAB_REWARD_TICKET} />

          <section className='mypage-section'>
            <div className='container'>
              <div className='content-box orders'>
                <div className='content-box-header'>
                  <h2>Reward ticket</h2>
                </div>
                <div className='content-box-body'>
                  <div className='point-info-box'>
                    <div className='your-point'>
                      <dl>
                        <dt style={{ fontWeight: '500' }}>My Reward ticket</dt>
                        <dd>
                          <strong className='my-point'>
                            {freeTicketCount}
                            <span style={{ fontWeight: '600' }}> ea</span>
                          </strong>
                        </dd>
                      </dl>
                    </div>
                  </div>

                  <div>
                    <ul className='free-ticket-help'>
                      <li>&bull; Reward tickets are coupons for downloading reward model.</li>
                      <li>
                        &bull; Reward tickets can only be used for reward models, not for paid
                        models.
                      </li>
                      <li>
                        &bull; The reward models are created as a form of fan art, filled with
                        utmost respect for masterpieces. And we share them for FREE with our
                        customers.
                      </li>
                      <li>
                        &bull; You can receive a reward ticket immediately after signing up for
                        Fab365.
                      </li>
                      <li>
                        &bull; You can earn additional reward tickets for each purchased paid model
                        (including models purchased with Fab365 credit).
                      </li>
                      <li>&bull; The issued reward ticket is valid for 6 months.</li>
                      <li>
                        &bull; Please note that reward tickets are only available for accounts that
                        have completed email authentication..
                      </li>
                    </ul>
                  </div>

                  <div className='point-history'>
                    <h3 style={{ fontWeight: '500' }}>History of Reward ticket</h3>
                    <div className='table-container'>
                      <table className='table table-plain'>
                        <colgroup>
                          <col style={{ width: '15rem' }} />
                          <col />
                          <col style={{ width: '15rem' }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>Created date</th>
                            <th>history</th>
                            <th>used date</th>
                            <th>expire date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {freeTicketHistory?.map(t => {
                            return (
                              <tr key={t.freeTicketUuid}>
                                <td>{moment(t.createdAt).format('YYYY-MM-DD')}</td>
                                <td>
                                  <TicketStatus data={t} />
                                </td>
                                <td>
                                  {t.status === STATUS_USED && (
                                    <span style={{ color: '#b9b9b9' }}>
                                      {moment(t.modifiedAt).format('YYYY-MM-DD')}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {t.status !== STATUS_USED && isExpired(t) && (
                                    <span style={{ color: '#b9b9b9' }}>
                                      {moment(t.expiredAt).format('YYYY-MM-DD')}
                                    </span>
                                  )}
                                  {t.status !== STATUS_USED &&
                                    !isExpired(t) &&
                                    moment(t.expiredAt).format('YYYY-MM-DD')}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default MyFreeTicket;
