/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { CHECKOUT_FREE } from '../../../route/constant';
import { createCheckoutFree } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import CartContext from '../../../context/cart.js/carts';
import styled from 'styled-components';

const RewardBuyButton = ({ isLogin, freeTicketCount, id, qty, secretProduct }) => {
  const { action: modalAction } = useContext(ModalContext);
  const { action: cartAction } = useContext(CartContext);
  const isSecret = secretProduct === 'secret';

  const navigate = useNavigate();

  const checkoutFree = async itemID => {
    try {
      const { data: res } = await createCheckoutFree(itemID);
      cartAction.checkoutFreeProduct(res.data);

      navigate(CHECKOUT_FREE);
    } catch (error) {
      // console.log(error);
    }
  };

  if (!isLogin) {
    return (
      <>
        <Btn
          onClick={!isSecret ? () => modalAction.setIsLogin(true) : null}
          disabled={isSecret}
          isSecret={isSecret}
        >
          {!isSecret && <img src='/assets/img/itemDetail/btn-icon.png' />}
          {isSecret ? 'No longer for sale' : 'GET MODEL NOW'}
        </Btn>
      </>
    );
  }

  if (freeTicketCount - qty < 0) {
    return (
      <>
        <Btn isInsufficient={true} disabled>
          {!isSecret && <img src='/assets/img/itemDetail/btn-icon-fff.png' />}
          {isSecret ? 'No longer for sale' : 'NOT ENOUGH TICKETS'}
        </Btn>
      </>
    );
  }

  return (
    <>
      {/* 로그인 && 티켓 충분 상태 */}
      <Btn onClick={() => checkoutFree(id)} disabled={isSecret} isSecret={isSecret}>
        <img src='/assets/img/itemDetail/btn-icon.png' />
        {isSecret ? 'No longer for sale' : 'GET MODEL NOW'}
      </Btn>
    </>
  );
};

export default RewardBuyButton;

const Btn = styled.button`
  background-color: ${props => (props.isSecret || props.isInsufficient ? '#929599' : '#f7f15b')};
  color: ${props => (props.isSecret || props.isInsufficient ? '#fff' : '#000')};
  border: none;
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  cursor: ${props => (props.isSecret ? 'default' : ' pointer')};
  line-height: 1.5;

  img {
    width: 15px;
    position: relative;
    right: 10px;
    top: 5px;
  }
`;
