import { useEffect, useRef, useState } from 'react';
import {
  NewSortBoxContainer,
  OptionButton,
  OptionItem,
  OptionList,
  TitleButton,
} from './styles/newSortBoxStyle';

const NewSortBox = ({ setSelectedOption, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (listRef.current && !listRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = (option, sortType) => {
    setIsOpen(false);
    setSelectedOption({ name: option, sortType: sortType });
  };

  const RenderOptionItem = ({ id, name, sortType }) => {
    return (
      <OptionButton key={id} onClick={() => handleClick(name, sortType)}>
        <OptionItem>{name}</OptionItem>
      </OptionButton>
    );
  };
  return (
    <NewSortBoxContainer>
      <TitleButton
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {selectedOption?.name || 'Latest'}
        <img src='/assets/img/mange/dropIcon.png' alt='dropIcon' />
      </TitleButton>
      {isOpen && (
        <OptionList ref={listRef}>
          <RenderOptionItem id={1} name={'Latest'} sortType={'latest'} />
          <RenderOptionItem id={2} name={'Most downloaded'} sortType={'download'} />
          <RenderOptionItem id={3} name={'High Price'} sortType={'highPrice'} />
          <RenderOptionItem id={4} name={'Low Price '} sortType={'lowPrice'} />
        </OptionList>
      )}
    </NewSortBoxContainer>
  );
};

export default NewSortBox;
