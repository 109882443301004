import { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import AboutEn from './component/AboutEn';
import AboutKo from './component/AboutKo';

const About = () => {
  const { action } = useContext(LangContext);

  return (
    <>
      <Layout>
        <main id='main' className='fab-page-main'>
          <div id='main2'>
            {action.isKo() ? <AboutKo /> : <AboutEn />}
            <span></span>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default About;
