/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import uuid from 'react-uuid';
import { ImgStyled } from '../../styles/ImgStyled';
import Exclusive from '../../common/Exclusive';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
const Gallery = ({ imgs, isRequest, studioName, exclusive }) => {
  const [index, setIndex] = useState(0);
  const [onLoaded, setOnLoaded] = useState(false);
  const { id } = useParams();

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 7,
    responsive: [
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const clickTbs = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    setIndex(idx);
  };
  const stop = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    setIndex(0);
  }, [id]);

  if (!imgs) return <></>;
  return (
    <>
      <div className='store-detail-gallery-desktop'>
        <div className='large-img'>
          {onLoaded && exclusive && <Exclusive />}
          <a
            //뒤로가기 버튼 눌렀을때 새창으로 열리는 버그가 발생하여 해당 부분 주석처리
            // href={imgs[index]?.fullscreen}
            data-index={index}
            className='show-image-viewer'
            onClick={stop}
            onContextMenu={e => {
              e.preventDefault();
            }}
          >
            {imgs[0]?.type === 'img' && (
              <>
                {isRequest && (
                  <ImgStyled style={{ cursor: 'pointer' }} src={imgs[index]?.url} alt='' />
                )}
                {!isRequest && studioName && (
                  <>
                    <GalleryMainImg alt=' '>
                      <GalleryImg src={imgs[index]?.img} alt='' setOnLoaded={setOnLoaded} />
                      {/* <GalleryImg src={imgs[index]?.img} alt='' setOnLoaded={setOnLoaded} /> */}
                    </GalleryMainImg>
                    <WatermarkBox className='watermark'>
                      <Watermark src='/assets/img/water_title.png' alt='watermark' />
                      <WatermarkName>✕ {studioName}</WatermarkName>
                    </WatermarkBox>
                  </>
                )}
              </>
            )}
            {/* 이미지 0번째 데이터에 type이 없는 경우 wartermark bType (예시 128아이템) */}
            {imgs[0]?.type === undefined && (
              <>
                {isRequest && (
                  <ImgStyled style={{ cursor: 'pointer' }} src={imgs[index]?.url} alt='' />
                )}
                {!isRequest && studioName && (
                  <>
                    <GalleryMainImg>
                      <GalleryImg src={imgs[index]?.img} alt='' setOnLoaded={setOnLoaded} />
                    </GalleryMainImg>
                    <WatermarkBox className='watermark'>
                      <Watermark src='/assets/img/water_title.png' alt='watermark' />
                      <WatermarkName>✕ {studioName}</WatermarkName>
                    </WatermarkBox>
                  </>
                )}
              </>
            )}
            {imgs[index]?.type === 'video' && (
              <a>
                <div className='vc'>
                  <iframe
                    src={'https://youtube.com/embed/' + imgs[index]?.url}
                    frameborder='0'
                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                    allowfullscreen
                    style={{ width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              </a>
            )}
          </a>
        </div>
        <div className='tbs'>
          <div className='slider'>
            <div className='slides'>
              <Slider {...settings}>
                {imgs.map((img, idx) => {
                  if (isRequest) {
                    return (
                      <a
                        href={img?.thumbs[0]?.url}
                        data-large-img={img?.thumbs[0]?.url}
                        data-index={idx}
                        className={'slide' + (idx === index ? ' active' : '')}
                        data-type={img?.type}
                        key={uuid()}
                        onClick={e => clickTbs(e, idx)}
                        onContextMenu={e => {
                          e.preventDefault();
                        }}
                      >
                        <span className='tb'>
                          <ImgStyled src={img.thumbs[0]?.url} alt='' className='request' />
                        </span>
                      </a>
                    );
                  }
                  if (img?.type === 'video') {
                    return (
                      <a
                        href={img.img}
                        data-large-img={img.img}
                        data-index={idx}
                        className={'slide' + (idx === index ? ' active' : '')}
                        data-type={img?.type}
                        key={uuid()}
                        onClick={e => clickTbs(e, idx)}
                      >
                        <span className='tb'>
                          <ImgStyled src={`//img.youtube.com/vi/${img.img}/0.jpg`} alt='' />
                        </span>
                      </a>
                    );
                  }
                  return (
                    <Thumbnail
                      data-large-img={img?.img}
                      data-index={idx}
                      className={'slide' + (idx === index ? ' active' : '')}
                      data-type={img?.type}
                      key={uuid()}
                      onClick={e => clickTbs(e, idx)}
                      onContextMenu={e => {
                        e.preventDefault();
                      }}
                    >
                      <span className='tb'>
                        <ImgStyled src={img?.img} alt='' className='request' />
                      </span>
                    </Thumbnail>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className='store-detail-gallery-mobile'>
        {onLoaded && exclusive && <Exclusive left='-21px' width='127px' />}
        <div className='slider'>
          <div className='slides'>
            <Slider
              {...{
                arrows: false,
                dots: true,
              }}
            >
              {imgs?.map((img, idx) => {
                if (img?.type === 'video') {
                  return (
                    <div className='slide'>
                      <div className='vc'>
                        <iframe
                          src={`https://youtube.com/embed/${img?.img}`}
                          frameborder='0'
                          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  );
                }
                return (
                  <a
                    href={img?.img}
                    data-large-img={img?.img}
                    data-index={idx}
                    key={uuid()}
                    onClick={stop}
                    className='slide show-image-viewer'
                    onContextMenu={e => {
                      e.preventDefault();
                    }}
                  >
                    <GalleryMainImg>
                      <GalleryImg src={img?.url || img?.img} alt='' setOnLoaded={setOnLoaded} />
                    </GalleryMainImg>
                  </a>
                );
              })}
            </Slider>
          </div>
          {!isRequest && studioName && imgs[index]?.type === 'img' && (
            <>
              <WatermarkBox className='watermark'>
                <Watermark src='/assets/img/water_title.png' alt='watermark' />
                <WatermarkName>✕ {studioName}</WatermarkName>
              </WatermarkBox>
            </>
          )}
          {!isRequest && studioName && imgs[index]?.type === undefined && (
            <>
              <WatermarkBox className='watermark'>
                <Watermark src='/assets/img/water_title.png' alt='watermark' />
                <WatermarkName>✕ {studioName}</WatermarkName>
              </WatermarkBox>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Gallery;

const GalleryMainImg = styled.div`
  aspect-ratio: 4/3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  position: relative;
`;

const WatermarkBox = styled.div`
  display: flex;
  position: absolute;
  bottom: 20%;
  right: 10%;
  gap: 5px;
  align-items: center;
  background: linear-gradient(90deg, rgba(65, 65, 65, 0.5) 0%, rgba(188, 188, 188, 0) 100%);
  pointer-events: none;
`;

const Watermark = styled.img`
  width: 100%;
  max-width: 60px;
  padding: 14px;
  pointer-events: none;

  @media screen and (max-width: 768px) {
    max-width: 50px;
    padding: 10px;
  }
`;

const WatermarkName = styled.span`
  color: #fff !important;
`;

const Thumbnail = styled.a`
  aspect-ratio: 4/3;
  max-width: 104px;

  span {
    width: 100% !important;
    height: 100% !important;
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`;

const GalleryImg = styled.img.attrs(props => ({
  onLoad: () => props.setOnLoaded(true),
}))`
  pointer-events: none;
  cursor: pointer;

  .search {
    height: 100%;
  }

  .request {
    aspect-ratio: 1/1;
    object-fit: cover;
  }
`;
