import { useState } from 'react';
import ItemRecommand from '../../component/item/ItemRecommand';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import { PRINT_TIPS_LIST } from '../../route/constant';
import { useNavigate } from 'react-router-dom';

const BrokenLivingHinge = ({ tab, title }) => {
  const [isOne, setIsOne] = useState(false);
  const [isTwo, setIsTwo] = useState(false);
  const [isThree, setIsThree] = useState(false);
  const [isFour, setIsFour] = useState(false);
  const imageBase = 'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/pages/14013382173-tips/';

  const navigate = useNavigate();

  const toggle = (bool, setter) => e => {
    e.stopPropagation();
    e.preventDefault();
    setter(!bool);
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu tab={tab} title={title} />
          <div className='container fab-accordion-mt-s1 fab-accordion-mb-s1'>
            <div className='fab-accordion __w1340'>
              <div className='fab-accordion-header'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
                <h2 className='fab-accordion-header--title'>
                  <small>Problem 1</small>
                  <br />
                  Living hinge breaking easily.
                </h2>
                <div className='fab-accordion-header--description'>
                  <p>
                    Fab365 folding design often applies the elasticity of the filament on a living
                    hinge.
                  </p>
                  <p>
                    Some of our customers say this living hinge breaks easily. We are going to find
                    out how to prevent living hinges from being broken.
                  </p>
                </div>
              </div>
              <div className='fab-accordion-body'>
                <div className='fab-accordion-list'>
                  <ul>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isOne ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isOne, setIsOne)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Cause 01</small>
                            <br />
                            Characteristics of Filament
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            Fab365 uses filament to test print. Some PLA have almost no elasticity
                            that it is not suitable to print a living hinge. PLA that was not used
                            for a long time and was exposed to air also has less elasticity which
                            results in breaking easily. Clear PLA, even though it's new, mostly has
                            less elasticity and breaks easily.
                          </p>
                          <p>
                            To resolve this issue, you have to check if the PLA that you’re using is
                            the right living hinge to use. Try checking it by bending the PLA that
                            you are going to use and see if it breaks. If it breaks, that PLA is not
                            suitable to print a living hinge.
                          </p>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-01.jpg'} alt='' />
                          </figure>
                          <figure className='__w1240'>
                            <img src={imageBase + 'tip-02.jpg'} alt='' />
                            <img src={imageBase + 'tip-03.jpg'} alt='' />
                          </figure>
                          <p>
                            <em>
                              Solution 1) Use PLA with elasticity.
                              <br />
                              Solution 2) After printing is finished, use a hair dryer or a heat gun
                              to heat up the living hinge and soften it, then you can bend it.
                            </em>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isTwo ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isTwo, setIsTwo)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Cause 02</small>
                            <br />
                            Lack of extrusion
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            A living hinge has to be printed in the right thickness. If the amount
                            of extrusion is too low, it will tear. If there is too much extrusion,
                            it will break because it is too thick.
                          </p>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-04.jpg'} alt='' />
                          </figure>
                          <p>
                            <em>Solution 1) Increase the amount of extrusion or decrease it.</em>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isThree ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isThree, setIsThree)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Cause 03</small>
                            <br />
                            Defect in bridge - bridge is sagging
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            Fab365’s folding design is completed by inserting interlocking snap-fit.
                            If these parts are not printed well, it will not be put together. If you
                            give too much force, the living hinge that connects each part will
                            break. Therefore, please check if the holes in the parts that are going
                            to be put together are printed correctly.
                          </p>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-05.jpg'} alt='' />
                          </figure>
                          <p>
                            <em>
                              Solution 1) Control the settings related to bridge in slicing.
                              <br />
                              Solution 2) Smooth out the sagged part of the bridge after printing is
                              finished.
                            </em>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isFour ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isFour, setIsFour)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Cause 04</small>
                            <br />
                            When the print out is not completely adhered and and corners are
                            floating apart
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            If the print out is not completely adhered to the bottom that the edges
                            float, the connecting parts are not going to fit together well. If you
                            give too much force to put connecting parts together, the living hinge,
                            which is relatively weaker, will break.{' '}
                          </p>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-06.jpg'} alt='' />
                          </figure>
                          <figure className='__w1240'>
                            <img src={imageBase + 'tip-07.jpg'} alt='' />
                            <img src={imageBase + 'tip-08.jpg'} alt='' />
                          </figure>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-09.jpg'} alt='' />
                          </figure>
                          <p>
                            <em>Solution 1) Increase the speed of the fan.</em>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='fab-accordion-footer'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
              </div>
            </div>
          </div>

          <section>
            <ItemRecommand itemId={null} />
          </section>
        </div>
      </Layout>
    </>
  );
};

export default BrokenLivingHinge;
