import React from 'react';
import styled from 'styled-components';

const ErrMessage = ({ onRequestClose, onConfirm, resend }) => {
  const colorClick = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const Bg = colorClick ? '#1d1d1d' : '#ffff';
  const fontColor = colorClick ? '#fff' : '#000';
  const boder = colorClick ? '#1d1d1d' : '#eaeaea';
  const okBg = colorClick ? '#A7C4F5' : ' #0b58d0';

  return (
    <Modal bg={Bg} bordercolor={boder}>
      <Title color={fontColor}>fab365.net says: </Title>
      <Content color={fontColor}>
        We have sent an account verification email to the email address you entered. Please log in
        after completing authentication.
      </Content>
      <Content color={fontColor}>
        If you haven't received the account verification email, please click the Resend button.
      </Content>
      <div className='btnwrap'>
        <Btn onClick={onConfirm} color={fontColor} border={1} bg={Bg} disabled={resend}>
          Resend
        </Btn>
        <Btn onClick={onRequestClose} bg={okBg} bordercolor={okBg} color={Bg}>
          OK
        </Btn>
      </div>
    </Modal>
  );
};

export default ErrMessage;

const Modal = styled.div`
  position: absolute;
  max-width: 450px;
  border-radius: 10px;
  top: -30px;
  left: 3.125rem;

  background-color: ${props => props.bg};
  z-index: 1000;
  padding: 30px;
  border: ${props => props.bordercolor} solid 1px;

  .modal {
    background: #1a1717;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .btnwrap {
    display: flex;
    justify-content: end;
    margin-top: 30px;
  }
`;

const Content = styled.p`
  color: ${props => props.color};
  font-size: 14px;
  margin-bottom: 10px;
`;

const Title = styled.p`
  color: ${props => props.color};

  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Btn = styled.button`
  width: 70px;
  height: 40px;
  border-radius: 20px;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  border: ${props => props.bordercolor} solid 1px;
  margin-left: 10px;
  cursor: pointer;
`;
