import styled from 'styled-components';

export const Webpage2StylesContainer = styled.div`
  /*기타 설정 : 시작 */
  html {
    -webkit-text-size-adjust: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body,
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  table,
  tr,
  th,
  td,
  form,
  fieldset,
  label,
  legend,
  input,
  textarea,
  button,
  menu {
    margin: 0px;
    padding: 0px;
    -webkit-text-size-adjust: none;
  }

  body,
  input,
  textarea,
  select,
  table,
  button,
  code {
    color: #383b40;
    font-family: 'Montserrat', sans-serif;
    /* font-family: 'Outfit', sans-serif; */
    font-weight: 400;
    text-align: left;
  }

  body {
    width: 100%;
    position: relative;
    word-wrap: break-word;
    word-break: break-all;
    min-width: 280px;
    min-height: 100vh;
    background: #fff /*f5f5f5*/;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  /*스크롤바*/
  /*body::-webkit-scrollbar {width: 15px;}
body::-webkit-scrollbar-thumb {
background-color: #82bbf0;
border-radius: 0px;
background-clip: padding-box;
border-left: 0px solid transparent;}
body::-webkit-scrollbar-track {
background-color: #d8d8d8;
border-radius: 0px;*/
  /*box-shadow: inset 0px 0px 5px white;*/

  nav,
  menu,
  ul,
  li,
  ol {
    list-style: none;
  }

  /* div {
    display: block;
  } */

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img,
  fieldset {
    border: none;
  }

  em,
  address {
    font-style: normal;
  }

  select {
    cursor: pointer;
  }

  section,
  article,
  header,
  footer,
  nav,
  aside,
  hgroup {
    display: block;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  button {
    border: none;
    background: none;
    cursor: pointer !important;
  }

  button:focus {
    outline: none !important;
  }

  a {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    cursor: pointer;
  }

  a:hover,
  a:active {
    text-decoration: none !important;
  }

  table {
    table-layout: fixed;
  }

  .clearFix:after {
    content: '';
    display: block;
    clear: both;
  }

  .ibFix {
    line-height: 0px;
  }

  .hiddenFix {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    line-height: 0;
    text-indent: -10000px;
  }

  .hiddenTxt {
    padding: 0;
    margin: 0;
    font-size: 0;
  }

  caption {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    line-height: 0;
    text-indent: -10000px;
  }

  .floatLeft {
    float: left;
  }

  .floatLeft:after {
    content: '';
    display: block;
    clear: both;
  }

  .floatRight {
    float: right;
    position: relative;
  }

  .floatRight:after {
    content: '';
    display: block;
    clear: both;
  }

  .pageFix {
    overflow: hidden;
  }

  .tCenter {
    text-align: center;
  }

  .inside {
    display: inline-block;
  }

  .inside:after {
    content: '';
    display: block;
    clear: both;
  }

  .row {
    display: block;
    width: 100%;
  }

  .row:after {
    content: '';
    display: block;
    clear: both;
  }

  .bold {
    font-weight: 700;
  }

  select {
    -webkit-appearance: none; /* for chrome */
    -moz-appearance: none; /*for firefox*/
    appearance: none;
  }

  select::-ms-expand {
    display: none; /*for IE10,11*/
  }

  .gotoTop {
    position: fixed;
    display: none;
    z-index: 100;
    width: 40px;
    height: 40px;
    background: url('/assets/img/2.0WebImg/go_top.png') center no-repeat;
    background-size: 40px 40px;
    bottom: 30px;
    right: calc(50% - 500px);
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.15));
  }

  :root {
    --main: #5532eb;
    --txt: #383b40;
  }

  .show500 {
    display: none;
  }
  .show700 {
    display: none;
  }

  .swiper {
    overflow: visible !important;
  }
  .swiper-container {
    overflow: visible !important;
  }
  /*기타 설정 : 끝*/

  /*공통 레이아웃 : 시작*/
  .allWrap {
    display: block;
    width: 100%;
  }
  .allWrap--padding {
    padding: 50px 0 0;
  }

  .centerWrap {
    display: block;
    width: 100%;
    max-width: 1360px;
    /*padding:0 10px;*/
    margin: 0 auto;
    position: relative;
  }

  .centerWrap:after {
    content: '';
    display: block;
    clear: both;
  }

  /*공통 레이아웃 : 끝*/

  /*top banner - start*/
  .topBanner {
    display: block;
    width: 100%;
    height: 45px;
    position: relative;
    z-index: 200;
    /* top: 0; */
    /* left: 0; */
  }

  .topBanner .img1 {
    display: block;
    width: 100%;
    height: 45px;
  }
  /*top banner - end*/

  /*header - start*/
  header {
    display: block;
    /* position: fixed; */
    /* z-index: 100; */
    /* top: 45px; */
    /* left: 0; */
    width: 100%;
    /* background: #0d0d0d; */
  }

  .sticky-container {
    display: block;
    background: #0d0d0d;
    height: 70px;
    z-index: 100;
    position: relative;
  }

  .sticky-container.zindex {
    z-index: 1000;
  }

  header .row1 {
    display: block;
    width: 100%;
    padding: 15px 26px;
    background: #0d0d0d;
    z-index: 999;
    position: sticky;
    max-width: 1360px;
    margin: 0 auto;
    left: 50% !important;
    transform: translateX(-50%) !important;
    position: absolute;
  }

  header .row1:after {
    content: '';
    display: block;
    clear: both;
  }

  header .row1 .headerLogo {
    display: block;
    width: 89px;
    height: 28px;
    float: left;
    margin: 6px 0 0;
    line-height: 0;
  }

  header .row1 .headerLogo img {
    display: block;
    width: 100%;
  }

  header .row1 .searchWrap {
    display: block;
    float: left;
    width: 100%;
    max-width: 760px;
    margin: 0 0 0 44px;
    position: relative;
    padding: 0 16px;
    background: #fff;
    border-radius: 20px;
  }

  header .row1 .searchWrap .inputSearch {
    height: 40px;
    line-height: 40px;
    text-align: left;
    width: 100%;
    padding: 0 30px 0 0;
    background: #fff;
    outline: none !important;
    border: none !important;

    font-size: 15px;
    color: #232323 !important;
  }

  header .row1 .searchWrap .buttonSearch {
    position: absolute;
    top: 0;
    right: 8px;
    width: 40px;
    height: 40px;
    background: url('/assets/img/2.0WebImg/ui/search_icon.svg') center no-repeat;
    background-size: 24px 24px;
    font-size: 0;
  }

  header .row1 .subMenu {
    display: block;
    float: right;
  }

  header .row1 .subMenu:after {
    content: '';
    display: block;
    clear: both;
  }

  header .row1 .subMenu .gotoSupplies {
    display: block;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding: 0 0 0 28px;
    color: #fff !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    background: url('/assets/img/2.0WebImg/ui/icon_bag.svg') left no-repeat;
    background-size: 24px 24px;
  }

  header .row1 .subMenu .gotoCart,
  header .row1 .subMenu .gotoSignup {
    display: block;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: left;
    color: #fff !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  header .row1 .subMenu .gotoCart {
    background: url('/assets/img/2.0WebImg/ui/icon_cart.svg') left no-repeat;
    background-size: 24px 24px;
    padding: 0 0 0 28px;
    position: relative;
  }

  header .row1 .subMenu .gotoSupplies + .gotoCart,
  header .row1 .subMenu .gotoSupplies + .gotoSignup {
    margin: 0 0 0 33px;
  }

  header .row1 .subMenu .gotoLogin {
    display: block;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: left;
    color: #fff !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  header .row1 .subMenu .gotoCart + .gotoLogin,
  header .row1 .subMenu .gotoSignup + .gotoLogin {
    margin: 0 0 0 33px;
  }

  header .row1 .subMenu .myPic {
    display: block;
    float: left;
    margin: 5px 0 0 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 0;
  }

  header .row1 .subMenu .myPic > .myPicImg1 {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  header .row2 {
    display: block;
    width: 100%;
    /* padding: 0 0 0 26px; */
    background: #0d0d0d;
    z-index: 800;
    transition: transform 0.3s ease-out;
    top: 70px;
    position: sticky;
  }

  header .row2:after,
  .row2-inner:after {
    content: '';
    display: block;
    clear: both;
  }

  .row2-inner {
    max-width: 1360px;
    margin: 0 auto;
    width: 100%;
    padding: 0 0 0 26px;
  }

  header .row2 .buttonCategory,
  header .row1 .buttonCategory {
    display: block;
    float: left;
    height: 50px;
    width: 138px;
  }

  header .row2 .buttonCategory:after,
  header .row1 .buttonCategory:after {
    content: '';
    display: block;
    clear: both;
  }

  header .row2 .buttonCategory > .iconMenu,
  header .row1 .buttonCategory > .iconMenu {
    display: block;
    float: left;
    width: 20px;
    height: 50px;
    background: url('/assets/img/2.0WebImg/ui/icon_menu.svg') center no-repeat;
    background-size: 20px 20px;
  }

  header .row2 .buttonCategory > .txt1,
  header .row1 .buttonCategory > .txt1 {
    display: block;
    float: left;
    height: 50px;
    line-height: 50px;
    margin: 0 0 0 6px;
    color: #fff !important;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
  }

  header .row2 .buttonCategory > .iconArrow,
  header .row1 .buttonCategory > .iconArrow {
    display: block;
    float: right;
    width: 24px;
    height: 50px;
    background: url('/assets/img/2.0WebImg/ui/icon_navegate-next.svg') center no-repeat;
    background-size: 24px 24px;
    margin: 0 0 0 20px;
  }

  header .row2 .buttonCategory > .iconArrow {
    margin: 0;
  }

  header .row2 nav {
    display: block;
    float: left;
    /*padding:0 0 0 60px;*/
    width: calc(100% - 138px);
    overflow: hidden;
  }

  header .row2 nav:after {
    content: '';
    display: block;
    clear: both;
  }

  header .row2 nav .navMenu {
    display: block;
    float: left;
    height: 50px;
    line-height: 50px;
    text-align: left;
    color: #fff !important;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 0 60px;
  }

  header .row2 nav .navMenu.active {
    color: #6bff80 !important;
    font-weight: 500;
  }

  header .navSwiper .swiper-slide {
    display: inline-block;
    width: auto;
    float: left;
  }

  .badge {
    text-indent: 0;
    position: absolute;
    background: #28ce40;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    top: 3px;
    left: 13px;
  }
  /*header - end*/

  /*category menu - start*/
  .categoryMenu {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 80px;
    left: 0px;
    width: 451px;
    /*height: 836px;*/
    border-radius: 8px;
    background: #000;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 40px 32px;
  }

  .categoryMenu .menuAll {
    display: block;
    width: 100%;
  }

  .categoryMenu .bottomButtons {
    display: none;
  }

  .categoryMenu .cloeeMenuMo {
    display: none;
  }

  .categoryMenu .buttonGroupRoW {
    display: none;
  }

  .dimm1 {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900;
    top: 0;
    left: 0;
  }

  .dimm1.active {
    display: block;
  }

  .categoryMenu.active {
    display: block;
  }

  .categoryMenu > .title {
    display: block;
    width: 100%;
    text-align: left;
    color: #c1c1c1;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 0 0 16px 8px;
    border-bottom: 1px solid #d9d9d9;
  }

  .categoryMenu .menuGroup {
    display: block;
    width: calc(100% + 26px);
    margin: 32px 0 0;
    padding: 0 18px 0 0;
    overflow-y: scroll;
    box-sizing: border-box;
    max-height: calc(100vh - 360px);
  }

  .categoryMenu .menuGroup::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px !important;
  }
  .categoryMenu .menuGroup::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }

  .categoryMenu .menuGroup::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }

  .categoryMenu .cloeeMenuMo {
    display: none;
  }

  .categoryMenu .menuGroup:after {
    content: '';
    display: block;
    clear: both;
  }

  .categoryMenu .menuGroup > .menu {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    width: 100%;
  }

  .categoryMenu .menuGroup > .menu + .menu {
    margin: 16px 0 0;
  }

  .categoryMenu .menuGroup > .menu:after {
    content: '';
    display: block;
    clear: both;
  }
  .categoryMenu .menuGroup > .menu > .icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #d9d9d9;
    border-radius: 50%;
    color: #0f0f0f !important;
    font-size: 18px;
    font-weight: 400;
  }

  .categoryMenu .menuGroup > .menu > .icon > img {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50px;
  }

  .categoryMenu .menuGroup > .menu > .txt {
    display: block;
    width: calc(100% - 47px);
    height: 32px;
    line-height: 32px;
    text-align: left;
    color: #fff !important;
    font-size: 18px;
    font-weight: 400;
    background: url('/assets/img/2.0WebImg/ui/ar-r.svg') right no-repeat;
    background-size: 16px 16px;
  }
  /*category menu - end*/

  /*user & designer menu - start*/
  .udMenu {
    display: none;
    position: absolute;
    z-index: 900;
    top: 20px;
    right: 26px;
    width: 180px;
    border-radius: 6px;
    background: #fff;
    padding: 16px 16px 6px;
  }

  .udMenu.active {
    display: block;
  }

  .udMenu > .name {
    display: block;
    width: 100%;
    text-align: left;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 0 10px;
    border-bottom: 1px solid #d9d9d9;
  }

  .udMenu .menuGroup {
    display: block;
    width: 100%;
  }

  .udMenu .menuGroup > .menu {
    display: block;
    width: 100%;
    padding: 10px 0;
  }

  .udMenu .menuGroup > .menu:after {
    content: '';
    display: block;
    clear: both;
  }
  .udMenu .menuGroup > .menu > .icon {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 0;
    float: left;
  }

  .udMenu .menuGroup > .menu > .icon > img {
    display: block;
    width: 20px;
    height: 20px;
  }

  .udMenu .menuGroup > .menu > .txt {
    display: block;
    width: calc(100% - 32px);
    float: left;
    margin: 0 0 0 12px;
    line-height: 20px;
    text-align: left;
    color: #000 !important;
    font-size: 14px;
    font-weight: 300;
  }

  .udMenu .menuGroup > .menu {
  }

  .udMenu .line1 {
    display: block;
    width: 100%;
    height: 1px;
    background: #d9d9d9;
  }

  .udMenu {
  }
  .udMenu {
  }
  .udMenu {
  }
  .udMenu {
  }
  .udMenu {
  }
  /*user & designer menu - end*/

  /*homeSection - start*/
  .homeSection {
    display: block;
    width: 100%;
    margin-top: 30px;
    overflow: hidden;
    max-height: 470px;

    aspect-ratio: 25 / 9;
  }

  .homeSection.mobile {
    aspect-ratio: 13 / 9;
  }

  .homeSection .mainSwiper {
    display: block;
    width: 100%;
    position: relative;
  }

  .mainSwiper.mainJumbotron {
  }

  .homeSection .mainSwiper .swiper-slide {
    line-height: 0;
  }

  /*.homeSection .mainSwiper */
  .imgPc {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 0;
  }

  /*.homeSection .mainSwiper */
  .imgPc > img {
    display: block;
    width: 100%;
    border-radius: 8px;
    height: 100%;
  }

  /*.homeSection .mainSwiper */
  .imgMo {
    display: none;
    width: 100%;
    line-height: 0;
  }

  /*.homeSection .mainSwiper */
  .imgMo > img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  .homeSection .swiper-pagination {
    position: absolute;
    bottom: 5% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .homeSection .swiper-pagination-bullet {
    width: 6px !important;
    height: 6px !important;
    background: #fff !important;
    opacity: 1 !important;
    margin: 0 3px !important;
  }

  .homeSection .swiper-pagination-bullet-active {
    background: #2f2f2f !important;
  }

  .homeSection .buttonSwiperPrev {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    left: 0;
    background: url('/assets/img/2.0WebImg/ui/home-arrow-l1.png') center no-repeat;
    background-size: 36px 36px;
  }
  .homeSection .buttonSwiperPrev:hover {
    background: url('/assets/img/2.0WebImg/ui/home-arrow-l2.png') center no-repeat;
    background-size: 36px 36px;
  }

  .homeSection .buttonSwiperNext {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    right: 0;
    background: url('/assets/img/2.0WebImg/ui/home-arrow-r1.png') center no-repeat;
    background-size: 36px 36px;
  }

  .homeSection .buttonSwiperNext:hover {
    background: url('/assets/img/2.0WebImg/ui/home-arrow-r2.png') center no-repeat;
    background-size: 36px 36px;
  }
  /*homeSection - end*/

  /*adTypeA - start*/
  .adTypeA {
    display: block;
    padding: 50px 10px;
  }

  .adTypeA .centerWrap {
    line-height: 0;
  }

  .adTypeA .centerWrap .adImgPc {
    display: block;
    width: 100%;
    line-height: 0;
  }

  .adTypeA .centerWrap .adImgPc > img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  .adTypeA .centerWrap .adImgMo {
    display: none;
    width: 100%;
    line-height: 0;
  }

  .adTypeA .centerWrap .adImgMo > img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }
  /*adTypeA - end*/

  /*adTypeB - start*/
  .adTypeB {
    display: block;
    padding: 50px 10px;
  }

  .adTypeB .centerWrap {
    line-height: 0;
    width: 100%;
  }

  .adTypeB .centerWrap .adImg1in2 {
    display: block;
    width: calc(50% - 8px);
    float: left;
    line-height: 0;
  }

  .adTypeB .centerWrap .adImg1in2 img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  .adTypeB .centerWrap .adImg1in2Pc + .adImg1in2Pc {
    margin: 0 0 0 16px;
  }
  .adTypeB .centerWrap .adImg1in2Mo + .adImg1in2Mo {
    margin: 0 0 0 4px;
  }

  /* skeleton */
  .adTypeB .centerWrap .adImg1in2Pc--0 {
    margin-right: 16px;
  }
  /* skeleton */
  .adTypeB .centerWrap .adImg1in2Mo--0 {
    margin-right: 4px;
  }

  /* .adImg1in2Pc {
    display: nonoe;
  } */
  .adImg1in2Mo {
    display: none !important;
  }
  /*adTypeB - end*/

  /*sectionTypeA - start*/
  .sectionTypeA {
    display: block;
    width: 100%;
    padding: 0 10px;
  }

  .sectionTypeA .centerWrap {
    padding: 80px 0 50px;
    border-bottom: 1px solid #d8d8d8;
  }

  .titleRow {
    display: block;
    width: 100%;
  }

  .titleRow:after {
    content: '';
    display: block;
    clear: both;
  }

  .titleRow > .title {
    display: block;
    width: 100%;
    color: #171717;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .secondRow {
    display: block;
    width: 100%;
  }

  .secondRow:after {
    content: '';
    display: block;
    clear: both;
  }

  .secondRow > .viewAll {
    display: block;
    float: right;
    background: url('/assets/img/2.0WebImg/ui/arrow-view-all.png') right no-repeat;
    background-size: 16px 16px;
    height: 28px;
    padding: 0 20px 0 0;
    line-height: 28px;
    color: #1fbe05 !important;
    font-size: 16px;
    text-align: right;
    font-weight: 600;
  }

  /* skeleton */
  .secondRow > span > .skeleton--viewAll {
    display: block;
    float: right;
    height: 28px;
    padding: 0 20px 0 0;
    line-height: 28px;
  }

  .titleRow > .viewAll.viewAllMo {
    display: none;
  }

  .sectionASwiper {
    display: block;
    width: 100%;
    margin: 16px 0 0;
    overflow: hidden !important;
  }

  .shopItemCard {
    display: block;
    width: 100%;
  }

  .shopItemCard .aspectRatio {
    aspect-ratio: 1/1;
  }

  .shopItemCard > .itemLinkPart1 {
    display: block;
    width: 100%;
  }

  .shopItemCard > .itemLinkPart2 {
    display: block;
    width: 100%;
  }

  .shopItemCard .imgWrap {
    display: block;
    width: 100%;
    height: 100%;
    /* background-color: #000; */
    line-height: 0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }

  .shopItemCard .react-loading-skeleton {
    max-height: 460px;
  }

  .shopItemCard .imgWrap .normal {
  }

  .shopItemCard .imgWrap:hover .normal {
    display: none;
  }

  .shopItemCard .imgWrap .hover {
    display: none;
  }

  .shopItemCard .imgWrap:hover .hover {
    display: block;
  }

  .shopItemCard .imgWrap:hover .hover .designerImg {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 46px;
    height: 46px;
    z-index: 10;
    line-height: 0;
  }

  .shopItemCard .imgWrap:hover .hover .designerImg img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .shopItemCard .imgWrap:hover .hover .buttonFavorite {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/favorite2.svg') center no-repeat;
    background-size: 24px 24px;
    right: 20px;
    bottom: 70px;
  }

  .shopItemCard .imgWrap:hover .hover .buttonCart {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart.svg') center no-repeat;
    background-size: 24px 24px;
    right: 20px;
    bottom: 20px;
  }

  .buttonFavorite.active {
    background: #fff url('/assets/img/2.0WebImg/ui/favorite_on.svg') center no-repeat !important;
    background-size: 24px 24px !important;
  }

  .buttonCart.active {
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart_on.svg') center no-repeat !important;
    background-size: 24px 24px !important;
  }

  .shopItemCard .imgWrap .itemLabel {
    position: absolute;
    left: 0;
    top: 0;
    background: #282432;
    height: 34px;
    line-height: 34px;
    text-align: left;
    padding: 0 11px 0 16px;
    z-index: 10;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
  }

  .shopItemCard .imgWrap .itemLabel.exclusive {
    background: rgb(40, 36, 50);
    clip-path: polygon(0px 0%, 100% 0%, 87% 100%, 0% 100%);
    color: rgb(255, 255, 255) !important;
    width: 135px;
  }

  @media screen and (max-width: 500px) {
    .shopItemCard .imgWrap .itemLabel.exclusive {
      width: 110px;
    }
  }

  /* .shopItemCard .imgWrap .itemLabel.exclusive:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -20px;
    border-top: 17px solid #282432;
    border-left: 10px solid #282432;
    border-right: 10px solid transparent;
    border-bottom: 17px solid transparent;
  } */

  .shopItemCard .imgWrap .itemLabel.reward {
    background: #f7f15b;
    color: #151515 !important;
  }

  .shopItemCard .imgWrap .itemLabel.reward:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -20px;
    border-top: 17px solid #f7f15b;
    border-left: 10px solid #f7f15b;
    border-right: 10px solid transparent;
    border-bottom: 17px solid transparent;
  }

  .shopItemCard .imgWrap .numLabel {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #282432;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    z-index: 10;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 18px;
    border-radius: 6px 0 0 0;
  }

  .shopItemCard .imgWrap .numLabel.reward {
    background: #f7f15b;
    color: #151515 !important;
  }

  .shopItemCard .imgWrap .numLabel.reward--bk {
    background: #282432;
    color: #fff !important;
  }

  .shopItemCard .imgWrap .ticket {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 56px;
    height: 28px;
    background: url('/assets/img/2.0WebImg/ui/icon_ticket.png') center no-repeat;
    width: 56px;
    height: 28px;
    background-size: 56px 28px;
  }

  .shopItemCard .imgWrap .ticket .amount {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background: #f7f15b;
    color: #151515;
    border-radius: 13px;
    color: #151515;
    font-size: 14px;
    font-weight: 500;
    top: -13px;
    right: -13px;
  }

  /*.shopItemCard .imgWrap .itemLabel.blue{
    background: #3C6B97;
    color: #FFF !important;
}

.shopItemCard .imgWrap .itemLabel.blue:after{
    content: "";
    display: block;
    position: absolute;
    top:0;
    right: -20px;
    border-top:17px solid #3C6B97;
    border-left:10px solid #3C6B97;
    border-right:10px solid transparent;
    border-bottom:17px solid transparent;
}*/

  .shopItemCard .imgWrap .itemImg1 {
    display: block;
    width: 100%;
  }

  .shopItemCard .imgWrap .comming_soon {
    border-radius: 8px;
  }

  .shopItemCard .imgWrap .itemImg1.over {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .shopItemCard .imgWrap:hover img {
    opacity: 0;
  }

  .shopItemCard .imgWrap:hover img.over,
  .shopItemCard .imgWrap:hover .comming_soon,
  .shopItemCard .imgWrap:hover img.designerImg2 {
    opacity: 1;
  }

  .shopItemCard .title {
    display: block;
    width: 100%;
    margin: 16px 0 0;
    color: #171717 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* skeleton */
  .shopItemCard .skeleton--title {
    width: 100%;
    margin: 16px 0 0;
    font-size: 14px;
    line-height: 18px;
  }

  .shopItemCard .subtitle {
    display: block;
    width: 100%;
    margin: 4px 0 0;
    color: #686868 !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0.36px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* skeleton */
  .shopItemCard .skeleton--subtitle {
    width: 100%;
    margin: 4px 0 0;
    font-size: 18px;
    line-height: 23px;
  }

  .shopItemCard .subtitle:first-child {
    margin: 10px 0 0;
  }

  .shopItemCard .priceRow {
    display: block;
    width: 100%;
    margin: 12px 0 0;
  }

  .shopItemCard .priceRow:after {
    content: '';
    display: block;
    clear: both;
  }

  .shopItemCard .priceRow > .price {
    display: block;
    float: left;
    color: #212121 !important;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    height: 25px;
    line-height: 25px;
  }

  /* skeleton */
  .shopItemCard .priceRow > span > .price {
    display: block;
    float: left;
    font-size: 20px;
    height: 25px;
    line-height: 25px;
  }

  .shopItemCard .priceRow > .originalPrice {
    display: block;
    float: left;
    color: #8c96a0 !important;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    height: 25px;
    line-height: 25px;
    margin: 0 0 0 5px;
    text-decoration: line-through;
  }

  .shopItemCard .priceRow > .discount {
    display: block;
    float: left;
    color: #46b81e !important;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    height: 25px;
    line-height: 25px;
    margin: 0 0 0 6px;
  }

  .shopItemCard .etc {
    display: block;
    width: 100%;
    padding: 9px 0 0;
  }

  @media screen and (max-width: 480px) {
    .shopItemCard .etc {
      padding-top: 10px;
    }

    .shopItemCard .priceRow {
      margin-top: 10px !important;
    }
  }

  .shopItemCard .etc.none {
    display: none;
    height: 14px;
  }

  /* skeleton */
  .shopItemCard .skeleton--etc {
    margin: 10px 0 0;
  }

  .shopItemCard .etc:after {
    content: '';
    display: block;
    clear: both;
  }

  .shopItemCard .etc > .favorites {
    display: block;
    float: left;
    height: 14px;
    line-height: 14px;
    padding: 0 0 0 15px;
    background: url('/assets/img/2.0WebImg/ui/favorite.svg') left no-repeat;
    background-size: 14px 14px;
    color: #929599 !important;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }

  .shopItemCard .etc > .comments {
    display: block;
    float: left;
    height: 14px;
    line-height: 14px;
    padding: 0 0 0 17px;
    background: url('/assets/img/2.0WebImg/ui/mode_comment.svg') left no-repeat;
    background-size: 14px 14px;
    color: #929599 !important;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    margin: 0 0 0 8px;
  }

  .shopItemCard .etc > .pictures {
    display: block;
    float: left;
    height: 14px;
    line-height: 14px;
    padding: 0 0 0 17px;
    background: url('/assets/img/2.0WebImg/ui/photo_camera.svg') left no-repeat;
    background-size: 14px 14px;
    color: #929599 !important;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    margin: 0 0 0 8px;
  }

  .sectionTypeA .buttonSwiperPrev {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px); /*카드에서 사진빼고 나머지 height는 224px*/
    left: -95px;
    background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-l.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
  }

  .sectionTypeA .buttonSwiperNext {
    position: absolute;
    z-index: 90;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    right: -95px;
    background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-r.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
  }

  .sectionTypeA .buttonSwiperPrev:hover {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    left: -95px;
    background: #000 url('/assets/img/2.0WebImg/ui/ar-l.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #000;
  }

  .sectionTypeA .buttonSwiperNext:hover {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    right: -95px;
    background: #000 url('/assets/img/2.0WebImg/ui/ar-r.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #000;
  }
  /*sectionTypeA - end*/

  /*sectionTypeB - start*/
  .sectionTypeB {
    display: block;
    width: 100%;
    padding: 0 10px;
  }

  .sectionTypeB .centerWrap {
    padding: 80px 0 50px;
  }

  .sectionTypeB .shopItemCard:hover .imgWrap .hover .designerImg {
    top: 12px;
    right: 12px;
  }

  .sectionTypeB .shopItemCard:hover .imgWrap .hover .buttonFavorite {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/favorite2.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 62px;
  }

  .sectionTypeB .shopItemCard:hover .imgWrap .hover .buttonCart {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 12px;
  }

  .swiperBigWrap {
    display: block;
    width: 100%;
    position: relative;
  }

  .sectionTypeB .buttonSwiperPrev {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 94px);
    left: -95px;
    background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-l.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
  }

  .sectionTypeB .buttonSwiperPrev:hover {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 94px);
    left: -95px;
    background: #000 url('/assets/img/2.0WebImg/ui/ar-l.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #000;
  }

  .sectionTypeB .buttonSwiperNext {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 94px);
    right: -95px;
    background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-r.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
  }

  .sectionTypeB .buttonSwiperNext:hover {
    position: absolute;
    z-index: 10;
    width: 70px;
    height: 70px;
    top: calc(50% - 94px);
    right: -95px;
    background: #000 url('/assets/img/2.0WebImg/ui/ar-r.svg') center no-repeat;
    background-size: 36px 36px;
    border-radius: 6px;
    border: 1px solid #000;
  }

  .bannersWrap {
    display: block;
    width: 100%;
    padding: 16px 0 4px;
  }

  .bannersWrap .bannerImg {
    width: 100%;
    aspect-ratio: 3/1;
  }

  .bannersWrap img {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  /*.bannersWrap{
    display: flex;
    width: 100%;
    padding:16px 0 4px;
    column-gap:25px;
}

.bannersWrap .banner1in3{
    display: block;
    width: calc((100% - 50px) / 3);
    line-height: 0;
}

.bannersWrap .banner1in3 img{
    display: block;
    width: 100%;
}*/

  /*sectionTypeB - start*/

  /*sectionTypeC - start*/
  .sectionTypeC {
    display: block;
    width: 100%;
    padding: 0 10px;
  }

  .sectionCSwiper,
  .sectionDSwiper {
    display: none !important;
  }

  .sectionTypeC .centerWrap {
    padding: 80px 0 50px;
    border-bottom: 1px solid #d8d8d8;
  }

  .col4List {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 45px;
    margin: 16px 0 0;
  }

  .col4ListAlwaysOpen {
    display: flex !important;
  }

  .col4List .shopItemCard {
    width: calc((100% - 60px) / 4);
  }

  .sectionTypeC .shopItemCard:hover .imgWrap .hover .designerImg {
    top: 12px;
    right: 12px;
  }

  .sectionTypeC .shopItemCard:hover .imgWrap .hover .buttonFavorite {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/favorite2.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 62px;
  }

  .sectionTypeC .shopItemCard:hover .imgWrap .hover .buttonCart {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 12px;
  }

  /*sectionTypeC - end*/

  /*sectionTypeD - start*/
  .sectionTypeD {
    display: block;
    width: 100%;
    padding: 0 10px;
  }

  .sectionTypeD .centerWrap {
    padding: 80px 0 50px;
    border-bottom: 1px solid #d8d8d8;
  }

  .sectionTypeD .titleRow > .title {
    text-align: center;
  }

  .sectionTypeD .secondRow > .midTitle {
    display: block;
    float: left;
    color: #171717;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.22px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .swiperMenu {
    display: block;
    width: 100%;
    max-width: 890px;
    /* min-width: 350px; */
    position: relative;
    margin: 30px auto 60px;
    aspect-ratio: 5/1;
  }

  .swiperMenuBody {
    display: block;
    width: 100%;
    overflow: hidden !important;
  }

  .swiperMenuOne {
    display: block;
    width: 100%;
    opacity: 0.5;
    cursor: pointer;
  }

  .swiperMenuOne.active {
    opacity: 1;
  }

  .swiperMenuOne.active .menuImgWrap {
  }

  .swiperMenuOne .menuImgWrap {
  }

  .swiperMenuOne .menuImgWrap > img {
  }

  .swiperMenuOne .menuTxt {
    display: block;
    width: 100%;
    text-align: center;
    color: #282432;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.16px;
    line-height: 20px;
    margin: 24px 0 0;
    word-wrap: break-word;
  }

  .swiperMenu .buttonSwiperPrev {
    position: absolute;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: 31px /*calc(50% - 24px)*/;
    left: -68px;
    background: url('/assets/img/2.0WebImg/ui/list_arrow_left.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .swiperMenu .buttonSwiperPrev:hover {
    background: url('/assets/img/2.0WebImg/ui/list_arrow_left_hover.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .buttonSwiperNextS4.card-swiper {
    top: 30%;
  }

  .buttonSwiperPrevS4.card-swiper {
    top: 30%;
  }

  .buttonSwiperNextS4.card-swiper:hover {
    top: 30%;
  }

  .buttonSwiperPrevS4.card-swiper:hover {
    top: 30%;
  }

  .swiperMenu .buttonSwiperNext {
    position: absolute;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: 31px /*calc(50% - 24px)*/;
    right: -68px;
    background: url('/assets/img/2.0WebImg/ui/list_arrow_right.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .swiperMenu .buttonSwiperNext:hover {
    background: url('/assets/img/2.0WebImg/ui/list_arrow_right_hover.svg') center no-repeat;
    background-size: 48px 48px;
  }
  /*sectionTypeD - end*/

  /*partners - start*/
  .sponsor {
    display: block;
    width: 100%;
    padding: 0 10px;
    background: #f2f2f2;
  }

  .sponsor .centerWrap {
    padding: 80px 0 70px;
    /*border-bottom:1px solid #d8d8d8;*/
  }

  .sponsorList {
    display: block;
    width: 100%;
    position: relative;
    margin: 30px auto 60px;
  }

  .sponsorListBody1 {
    display: block;
    width: 100%;
    overflow: hidden !important;
  }

  .sponsor .buttonSwiperPrev {
    position: absolute;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: 18px /*calc(50% - 24px)*/;
    left: -68px;
    background: url('/assets/img/2.0WebImg/ui/list_arrow_left.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .sponsor .buttonSwiperPrev:hover {
    background: url('/assets/img/2.0WebImg/ui/list_arrow_left_hover.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .sponsor .buttonSwiperNext {
    position: absolute;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: 18px /*calc(50% - 24px)*/;
    right: -68px;
    background: url('/assets/img/2.0WebImg/ui/list_arrow_right.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .sponsor .buttonSwiperNext:hover {
    background: url('/assets/img/2.0WebImg/ui/list_arrow_right_hover.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .partinerImg {
    display: block;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
  }

  .sponsor .swiper-wrapper {
    transition-timing-function: linear;
  }

  .centerWrap.mainJumbotron * {
    max-height: 460px;
  }

  /*partners - end*/

  /*footer : start*/
  footer {
    display: block;
    width: 100%;
  }
  .footer--margin {
    margin: 40px 0 0;
  }
  footer .footerRow1 {
    display: block;
    width: 100%;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    padding: 12px 10px;
  }
  footer .footerRow1 .footerMenu {
    display: block;
    float: left;
  }

  footer .footerRow1 .footerMenu:after {
    content: '';
    display: block;
    clear: both;
  }

  footer .footerRow1 .footerMenu a {
    display: block;
    float: left;
    height: 36px;
    line-height: 36px;
    text-align: left;
    color: #000 !important;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 300;
  }

  footer .footerRow1 .footerMenu a + a {
    margin: 0 0 0 40px;
  }

  footer .footerRow1 .footerSubMenu {
    float: right;
    display: block;
  }

  footer .footerRow1 .footerSubMenu:after {
    content: '';
    display: block;
    clear: both;
  }

  footer .footerRow1 .footerSubMenu .selectF1 {
    display: block;
    float: left;
    width: 128px;
    height: 36px;
    border: 1px solid #c4c4c4;
    text-align: left;
    padding: 0 17px 0 10px;
    background: #fff url('/assets/img/2.0WebImg/ui/tri2.svg') calc(100% - 10px) no-repeat;
    background-size: 6px 5px;
    color: #000 !important;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
  }

  footer .footerRow1 .footerSubMenu .youtube {
    display: block;
    float: left;
    margin: 2px 0 0 12px;
    width: 32px;
    height: 32px;
    background: url('/assets/img/2.0WebImg/ui/icon_youtube.svg') center no-repeat;
    background-size: 24px 24px;
  }

  footer .footerRow1 .footerSubMenu .instagram {
    display: block;
    float: left;
    margin: 2px 0 0 8px;
    width: 32px;
    height: 32px;
    background: url('/assets/img/2.0WebImg/ui/icon_instagram.svg') center no-repeat;
    background-size: 24px 24px;
  }

  footer .footerRow1 .footerSubMenu .facebook {
    display: block;
    float: left;
    margin: 2px 0 0 8px;
    width: 32px;
    height: 32px;
    background: url('/assets/img/2.0WebImg/ui/icon_facebook.svg') center no-repeat;
    background-size: 24px 24px;
  }

  footer .footerRow2 {
    display: block;
    width: 100%;
    padding: 40px 10px;
  }

  footer .footerRow2 .col1 {
    display: block;
    float: left;
    width: 165px;
    height: 36px;
    line-height: 0;
  }

  footer .footerRow2 .col1 .footerLogo {
    display: block;
    width: 100%;
  }

  footer .footerRow2 .col2 {
    display: block;
    float: left;
    margin: 0 0 0 60px;
  }

  footer .footerRow2 .col2 > .fTxt {
    display: block;
    width: 100%;
    text-align: left;
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.24px;
    line-height: 22px;
  }

  footer .footerRow2 .col2 > .fTxt > .t1 {
    font-weight: 700;
  }
  /*footer : end*/

  /*designer_search : start*/
  .designerTopBanner {
    display: block;
    width: 100%;
    padding: 30px 10px;
  }

  .sorts {
    display: block;
    width: 100%;
  }

  .sorts:after {
    content: '';
    display: block;
    clear: both;
  }

  .buttonSort {
    position: relative;
    display: block;
    float: left;
    text-align: left;
    color: #171717 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    padding: 0 24px 0 10px !important;
    background: url('/assets/img/2.0WebImg/ui/tri2.svg') calc(100% - 10px) no-repeat;
    background-size: 8px 5px;
    border: none !important;
  }

  .buttonSort:focus {
    outline: none !important;
  }
  .buttonSort + .buttonSort {
    margin: 0 0 0 34px;
  }
  /*designer_search : end*/

  .paging {
    display: block;
    width: 100%;
    text-align: center;
    padding: 100px 0;
  }

  .paging .buttonPage {
    display: block;
    float: left;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #efefef;
    line-height: 24px;
    text-align: center;
    color: #747474 !important;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .paging .buttonPage.active {
    color: #fff !important;
    background: #000;
  }

  .paging .buttonPage + .buttonPage {
    margin: 0 0 0 4px;
  }

  .paging .pageFirst {
    background: #efefef url('/assets/img/2.0WebImg/ui/nav_first.svg') center no-repeat;
    background-size: 18px 18px;
  }

  .paging .pageLast {
    background: #efefef url('/assets/img/2.0WebImg/ui/nav_last.svg') center no-repeat;
    background-size: 18px 18px;
  }

  .paging .pagePrev {
    background: #efefef url('/assets/img/2.0WebImg/ui/nav_prev.svg') center no-repeat;
    background-size: 18px 18px;
  }

  .paging .pageNext {
    background: #efefef url('/assets/img/2.0WebImg/ui/nav_next.svg') center no-repeat;
    background-size: 18px 18px;
  }

  /*designer : start*/
  .pageTitleRow {
    display: block;
    width: 100%;
    padding: 48px 10px 0;
  }

  .pageTitle {
    display: block;
    text-align: left;
    color: #171717;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }
  /*designer : end*/

  @media screen and (max-width: 1600px) {
    .sectionTypeA .buttonSwiperPrev {
      width: 24px;
      background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-l.svg') center no-repeat;
      background-size: 18px 18px;
      left: -32px;
    }
    .sectionTypeA .buttonSwiperNext {
      width: 24px;
      background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-r.svg') center no-repeat;
      background-size: 18px 18px;
      right: -32px;
    }
    .sectionTypeB .buttonSwiperPrev {
      width: 24px;
      background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-l.svg') center no-repeat;
      background-size: 18px 18px;
      left: -32px;
    }
    .sectionTypeB .buttonSwiperNext {
      width: 24px;
      background: #fff url('/assets/img/2.0WebImg/ui/arrow-s1-r.svg') center no-repeat;
      background-size: 18px 18px;
      right: -32px;
    }

    .sectionTypeA .buttonSwiperPrev:hover {
      width: 24px;
      left: -32px;
      background: #000 url('/assets/img/2.0WebImg/ui/ar-l.svg') center no-repeat;
      background-size: 18px 18px;
    }
    .sectionTypeA .buttonSwiperNext:hover {
      width: 24px;
      right: -32px;
      background: #000 url('/assets/img/2.0WebImg/ui/ar-r.svg') center no-repeat;
      background-size: 18px 18px;
    }
    .sectionTypeB .buttonSwiperPrev:hover {
      width: 24px;
      left: -32px;
      background: #000 url('/assets/img/2.0WebImg/ui/ar-l.svg') center no-repeat;
      background-size: 18px 18px;
    }
    .sectionTypeB .buttonSwiperNext:hover {
      width: 24px;
      right: -32px;
      background: #000 url('/assets/img/2.0WebImg/ui/ar-r.svg') center no-repeat;
      background-size: 18px 18px;
    }

    .sponsor .buttonSwiperPrev {
      width: 24px;
      background-size: 24px 24px;
      left: -32px;
    }

    .sponsor .buttonSwiperPrev:hover,
    .sponsor .buttonSwiperNext:hover {
      background-size: 24px 24px;
    }
    .sponsor .buttonSwiperNext {
      width: 24px;
      background-size: 24px 24px;
      right: -32px;
    }
  } /*반응형 1600px 끝*/

  @media screen and (max-width: 1450px) {
    .sectionTypeA .buttonSwiperPrev,
    .sectionTypeA .buttonSwiperPrev:hover {
      left: 0;
    }
    .sectionTypeA .buttonSwiperNext,
    .sectionTypeA .buttonSwiperNext:hover {
      right: 0;
    }
    .sectionTypeB .buttonSwiperPrev,
    .sectionTypeB .buttonSwiperPrev:hover {
      left: 0;
    }
    .sectionTypeB .buttonSwiperNext,
    .sectionTypeB .buttonSwiperNext:hover {
      right: 0;
    }

    .sponsor .buttonSwiperPrev {
      display: none;
    }
    .sponsor .buttonSwiperNext {
      display: none;
    }
  } /*반응형 1450px 끝*/

  @media screen and (max-width: 1380px) {
    .categoryMenu {
      left: 10px;
    }
  } /*반응형 1380px 끝*/

  @media screen and (max-width: 1200px) {
    /*header : start*/

    header .row1 .searchWrap {
      width: calc(100% - 400px);
    }

    header .row2 nav .navMenu {
      margin: 0 0 0 40px;
    }
    /*header : end*/
  } /*반응형 1200px 끝*/

  @media screen and (max-width: 1024px) {
    .col4List .shopItemCard {
      width: calc((100% - 40px) / 3);
    }

    .swiperMenu .buttonSwiperNext,
    .swiperMenu .buttonSwiperPrev {
      display: none;
    }
    .swiperMenuOne .menuImgWrap {
      padding: 8px;
    }
  } /*반응형 1024px 끝*/

  @media screen and (max-width: 700px) {
    .show700 {
      display: inline;
    }

    /*header : start*/
    header .centerWrap {
      padding: 0;
    }

    header .row1 {
      padding: 0 20px;
      height: 103px;
      width: 100%;
    }

    header .row1 .headerLogo {
      width: 77px;
      height: 24px;
      position: absolute;
      top: 15px;
      left: 56px;
      margin: 0;
    }

    header .row1 .subMenu .gotoCart {
      position: absolute;
      top: 7px;
      right: 15px;
    }

    header .row1 .searchWrap {
      position: absolute;
      top: 53px;
      left: 20px;
      margin: 0;
      width: calc(100% - 40px);
    }

    header .row1 .subMenu .gotoSupplies,
    header .row1 .subMenu .gotoSignup,
    header .row1 .subMenu .gotoLogin {
      display: none;
    }

    header .row1 .subMenu .myPic > .myPicImg1 {
      display: none;
    }

    header .row1 .subMenu .gotoCart {
      font-size: 0;
      margin: 0 !important;
      float: right;
    }

    header .row2 {
      padding: 0;
      width: 100%;
      top: 102px;
    }

    header .row2 .buttonCategory,
    header .row1 .buttonCategory {
      position: absolute;
      left: 10px;
      top: 7px;
      width: 40px;
      height: 40px;
    }

    header .row2 .buttonCategory > .iconMenu,
    header .row1 .buttonCategory > .iconMenu {
      width: 40px;
      height: 40px;
      background: url('/assets/img/2.0WebImg/ui/icon_menu.svg') center no-repeat;
      background-size: 20px 20px;
    }

    header .row2 .buttonCategory > .txt1,
    header .row1 .buttonCategory > .txt1 {
      display: none;
    }

    header .row2 .buttonCategory > .iconArrow,
    header .row1 .buttonCategory > .iconArrow {
      display: none;
    }

    header .row2 nav {
      width: 100%;
      padding: 0 0 0 20px;
    }

    header .row2 nav .navMenu {
      margin: 0 30px 0 0;
      /* height: 52px; */
      /* line-height: 52px; */
    }

    .row2-inner {
      padding: 0;
    }
    /*header : end*/

    /*homeSection : start*/
    .homeSection {
      padding: 16px 0 0;
    }

    .homeSection .centerWrap {
      padding: 0;
    }

    .homeSection .swiper-pagination {
      bottom: 18px !important;
    }

    .homeSection .buttonSwiperNext,
    .homeSection .buttonSwiperPrev {
      display: none;
    }

    /*.homeSection .mainSwiper */
    .imgPc {
      display: none;
    }

    /*.homeSection .mainSwiper */
    .imgMo {
      display: block;
    }

    .imgMo > img {
      border-radius: 0;
    }

    /*homeSection : end*/

    /*adTypeA - start*/
    .adTypeA {
      display: block;
      padding: 16px 0;
    }

    .adTypeA .centerWrap {
      padding: 0;
    }

    .adTypeA .centerWrap .adImgPc {
      display: none;
    }

    .adTypeA .centerWrap .adImgMo {
      display: block;
    }
    .adTypeA .centerWrap .adImgMo > img {
      border-radius: 0;
    }
    /*adTypeA - end*/

    /*sectionTypeA - start*/
    .sectionTypeA {
      padding: 0 32px;
    }

    .sectionTypeA .centerWrap {
      padding: 24px 0 40px;
      border-bottom: none;
    }

    .sectionASwiper {
      width: calc(100% + 64px);
      transform: translateX(-32px);
      padding: 0 0 0 32px;
    }
    /*sectionTypeA - end*/

    /*sectionTypeB - start*/
    .sectionTypeB {
      padding: 0 32px;
    }

    .sectionTypeB .centerWrap {
      padding: 24px 0 40px;
      border-bottom: none;
    }

    .sectionASwiper {
      width: calc(100% + 64px);
      transform: translateX(-32px);
      padding: 0 0 0 32px;
    }
    /*sectionTypeB - end*/

    .titleRow > .title {
      font-size: 20px;
      line-height: 30px;
    }

    .secondRow {
      position: relative;
    }

    .secondRow > .viewAll {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 14px;
      background: url('/assets/img/2.0WebImg/ui/arrow-view-all.png') right no-repeat;
      background-size: 12px 12px;
      height: 28px;
      padding: 0 16px 0 0;
    }

    /* skeleton */
    .secondRow > span > .skeleton--viewAll {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 14px;
      height: 28px;
      padding: 0 16px 0 0;
    }

    /*sectionTypeC - start*/
    .sectionTypeC .col4List,
    .sectionTypeD .col4List {
      display: none;
    }
    .sectionCSwiper,
    .sectionDSwiper {
      display: block !important;
    }

    .sectionTypeC,
    .sectionTypeD {
      padding: 0 32px;
    }

    .sectionTypeC .centerWrap,
    .sectionTypeD .centerWrap {
      padding: 24px 0 40px;
      border-bottom: none;
    }
    /*sectionTypeC - end*/

    .shopItemCard .imgWrap .hover {
      display: none !important;
    }
    .shopItemCard .imgWrap .normal {
      display: block !important;
    }

    .shopItemCard .imgWrap:hover img {
      opacity: 1;
    }

    /*sectionTypeD - start*/
    .sectionTypeD .titleRow .title {
      text-align: left !important;
    }

    .swiperMenuBody {
      width: calc(100% + 64px);
      transform: translateX(-32px);
      padding: 0 0 0 32px;
    }

    .sectionTypeD .secondRow > .viewAll {
    }

    .sectionTypeD .secondRow {
      display: none;
    }

    .sectionTypeD .swiperMenu {
      margin: 16px 0;
    }

    .swiperMenuOne .menuTxt {
      margin: 11px 0 0;
      font-size: 11px;
      line-height: 19px;
    }

    .swiperMenuOne.active .menuImgWrap {
    }

    .swiperMenuOne .menuImgWrap {
      padding: 4px;
    }

    .sectionTypeD .titleRow {
      position: relative;
    }

    .titleRow > .viewAll.viewAllMo {
      display: block;
      position: absolute;
      line-height: 28px;
      color: #1fbe05 !important;
      text-align: right;
      font-weight: 600;
      right: 0;
      bottom: 0;
      font-size: 14px;
      background: url('/assets/img/2.0WebImg/ui/arrow-view-all.png') right no-repeat;
      background-size: 12px 12px;
      height: 28px;
      padding: 0 16px 0 0;
    }

    .secondRow > .viewAll.viewAllPc {
      display: none;
    }

    /*sectionTypeD - end*/

    /*adTypeB - start*/
    .adTypeB {
      padding: 50px 0px;
    }

    .adTypeB .centerWrap {
      display: flex;
      justify-content: center;
    }

    /* skeleton */
    .adTypeB .skeleton--centerWrap {
      display: block;
    }

    .adImg1in2Pc {
      display: none !important;
    }

    .adImg1in2Mo {
      display: block !important;
    }

    .adTypeB .centerWrap .adImg1in2 {
      width: 50%;
    }

    /* skeleton */
    .adTypeB .skeleton--centerWrap .adImg1in2 {
      width: calc(50% - 2px);
    }

    .adTypeB .centerWrap .adImg1in2 img {
      border-radius: 0px;
    }
    /*adTypeB - end*/

    /* section banner */
    .bannersWrap img {
      width: 100vw;
      margin-left: -32px;
    }

    /* skeleton */
    .bannersWrap span {
      width: 100vw;
      margin-left: -16px;
    }

    /*footer : start*/
    footer .footerRow1 {
      padding: 0 32px;
      border-bottom: none;
    }
    footer .footerRow1 .footerMenu {
      padding: 10px 0;
    }
    footer .footerRow1 .footerMenu a {
      float: left;
      clear: both;
      height: 40px;
      line-height: 40px;
    }
    footer .footerRow1 .footerMenu a + a {
      margin: 0;
    }

    footer .footerRow1 .footerSubMenu {
      float: right;
      width: 100%;
      margin: 20px 0 20px;
    }

    footer .footerRow2 {
      padding: 32px;
    }
    footer .footerRow2 .col1 {
      width: 80px;
      height: 19px;
    }
    footer .footerRow2 .col2 {
      margin: 10px 0 0;
      width: 100%;
    }

    /*footer : end*/

    /*sponsor : start*/
    .sponsor {
      padding: 0 32px;
    }
    .sponsor .centerWrap {
      padding: 40px 0 30px;
    }
    .sponsorListBody1 {
      width: calc(100% + 64px);
      transform: translateX(-32px);
      padding: 0 0 0 32px;
    }
    /*sponsor : end*/

    .sectionTypeA .buttonSwiperPrev {
      display: none;
    }
    .sectionTypeA .buttonSwiperNext {
      display: none;
    }
    .sectionTypeB .buttonSwiperPrev {
      display: none;
    }
    .sectionTypeB .buttonSwiperNext {
      display: none;
    }

    /*categoryMenu : start*/
    .categoryMenu {
      width: 100%;
      top: 3px;
      height: calc(100vh - 50px);
      left: 0;
      border-radius: 0;
      padding: 113px 32px;
    }

    .categoryMenu .menuGroup {
      display: none;
      margin: 20px 0 0;
      padding: 0 0 20px;
      max-height: none;
    }

    .categoryMenu.active {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    .categoryMenu .active + .menuGroup {
      display: block;
    }

    .categoryMenu .menuGroup > .menu {
      display: block;
      width: auto;
      float: left;
      clear: both;
    }

    .categoryMenu .cloeeMenuMo {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      top: 19px;
      right: 20px;
      background: url('/assets/img/2.0WebImg/ui/close.svg') center no-repeat;
      background-size: 40px 40px;
      z-index: 900;
    }

    .categoryMenu .menuGroup > .menu:after {
      content: '';
      display: block;
      clear: both;
    }

    .categoryMenu .menuGroup > .menu > .icon {
      display: none;
    }

    .categoryMenu .menuGroup > .menu > .txt {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      width: auto;
      float: left;
      display: block;
      background: none;
    }

    .dimm1.active {
      display: none;
    }

    .categoryMenu .title {
      display: none;
    }

    .categoryMenu .buttonGroupRoW {
      display: block;
      width: 100%;
    }

    .categoryMenu .buttonGroupRoW:after {
      content: '';
      display: block;
      clear: both;
    }

    .categoryMenu .buttonGroupRoW .buttonGroupJustLink {
      color: #fff;
      font-size: 28px;
      font-weight: 400;
      display: block;
      float: left;
    }

    .categoryMenu .buttonGroupRoW .buttonGroup {
      color: #fff;
      font-size: 28px;
      font-weight: 400;
      display: block;
      float: left;
      background: url('/assets/img/2.0WebImg/ui/expand_more.svg') right no-repeat;
      background-size: 24px 24px;
      padding: 0 30px 0 0;
    }

    .categoryMenu .buttonGroupRoW .buttonGroup.active {
      background: url('/assets/img/2.0WebImg/ui/expand_more2.svg') right no-repeat;
      background-size: 24px 24px;
    }

    .categoryMenu .menuGroup + .buttonGroupRoW {
      margin: 24px 0 0;
    }

    .categoryMenu .menuAll {
      display: block;
      width: 100%;
      height: calc(100% - 100px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .categoryMenu .menuAll::-webkit-scrollbar {
      width: 8px;
    }
    .categoryMenu .menuAll::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
    }

    .categoryMenu .menuAll.scrollFix::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }

    .categoryMenu .bottomButtons {
      display: block;
      width: 100%;
      /* margin-top: 70px; */
      /* position: fixed; */
      /* bottom: 0; */
      /* left: 0; */
      /* padding: 10px 32px 20px; */
    }

    .categoryMenu .bottomButtons:after {
      content: '';
      display: block;
      clear: both;
    }

    .categoryMenu .bottomButtons > .buttonWhite1in2 {
      display: block;
      width: calc(50% - 4px);
      border-radius: 6px;
      background: #fff;
      text-align: center;
      font-size: 14px;
      color: #222 !important;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      float: left;
    }
    .categoryMenu .bottomButtons > .buttonWhite1in2 + .buttonWhite1in2 {
      float: right;
    }
    /*categoryMenu : end*/

    /*designer : start*/

    .pageTitleRow {
      padding: 24px 32px 0;
    }

    .pageTitle {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
    }

    .sorts {
    }
    .sorts .floatRight {
      float: left;
    }
    .sorts .buttonSort {
      border-radius: 18px;
      background: #f2f2f2 url('/assets/img/2.0WebImg/ui/tri2.svg') calc(100% - 12px) no-repeat;
      background-size: 8px 5px;
      height: 36px !important;
      line-height: 36px !important;
      padding: 0 31px 0 16px !important;
      font-size: 16px !important;
    }

    .sorts .buttonSort + .buttonSort {
      margin: 0 0 0 8px;
    }

    .col4ListAlwaysOpen .shopItemCard {
      width: calc(50% - 10px);
    }

    .allWrap--padding {
      padding-top: 74px;
    }

    .designerTopBanner {
      padding: 30px 0;
    }

    .designerTopBanner img {
      border-radius: 0;
    }
    /*designer : end*/

    .paging {
      padding: 72px 0;
    }

    .paging .buttonPage {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
    }

    .paging .pageFirst {
      background: #efefef url('/assets/img/2.0WebImg/ui/nav_first.svg') center no-repeat;
      background-size: 24px 24px;
    }

    .paging .pageLast {
      background: #efefef url('/assets/img/2.0WebImg/ui/nav_last.svg') center no-repeat;
      background-size: 24px 24px;
    }

    .paging .pagePrev {
      background: #efefef url('/assets/img/2.0WebImg/ui/nav_prev.svg') center no-repeat;
      background-size: 24px 24px;
    }

    .paging .pageNext {
      background: #efefef url('/assets/img/2.0WebImg/ui/nav_next.svg') center no-repeat;
      background-size: 24px 24px;
    }
  } /*반응형 700px 끝*/

  @media screen and (max-width: 500px) {
    .show500 {
      display: inline;
    }

    .topBanner {
      display: none;
    }

    header {
      top: 0;
    }

    .allWrap--padding {
      /* padding: 120px 0 0; */
    }

    /*footer .footerRow1 .footerMenu{
        display: block !important;
    }import Github from '../component/sns/Github/index';

    

    footer .footerRow1 .footerMenu:after{content: ""; display: block; clear: both;}

    footer .footerRow1 .footerMenu a{
        display: block;
        float: left;
        clear: both;
    }*/

    .shopItemCard .imgWrap .itemLabel {
      font-size: 11px;
    }

    .shopItemCard .subtitle {
      font-size: 16px;
    }

    .shopItemCard .imgWrap .numLabel {
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 15px;
    }
  } /*반응형 500px 끝*/

  @media screen and (max-width: 400px) {
  } /*반응형 400px 끝*/

  @media screen and (max-width: 380px) {
    footer .footerRow1 .footerSubMenu .selectF1 {
      width: 100px;
    }
  } /*반응형 380px 끝*/

  @media screen and (max-width: 360px) {
    .sorts .floatRight {
      width: 100%;
      padding: 0 0 14px;
    }

    .sorts .buttonSort {
      width: 100%;
    }

    .sorts .buttonSort + .buttonSort {
      margin: 14px 0 0;
    }
  } /*반응형 360px 끝*/

  @media screen and (max-width: 340px) {
    .col4ListAlwaysOpen .shopItemCard {
      width: 100%;
    }
  } /*반응형 340px 끝*/

  @media screen and (max-width: 320px) {
  } /*반응형 320px 끝*/

  @media screen and (max-width: 300px) {
  } /*반응형 300px 끝*/
`;
