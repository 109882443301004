import React, { useEffect, useState } from 'react';
import { ls } from '../../constant';
import Logined2 from './Logined2';
import Banner from './Banner';
import Logouted2 from './Logouted2';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';

function Header2({ showMenu, position }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isPhone, setIsPhone] = useState(false);

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const onChangeSearchKeyword = e => {
    setSearchKeyword(e.target.value);
  };

  const resetSearchKeyword = () => {
    setSearchKeyword('');
  };

  // 반응형 감지
  useEffect(() => {
    const handleResize = () => {
      // 너비가 700px 이하인 경우를 모바일로 판단
      setIsMobile(window.innerWidth <= 700);
      // 너비가 500px 이하인 경우를 핸드폰 기기로 판단
      setIsPhone(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // 초기 렌더링 시 한 번 실행

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);
  return (
    <Webpage2StylesContainer>
      <Banner />
      {isLoginUsingToken ? (
        <Logined2
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
          resetSearchKeyword={resetSearchKeyword}
          isMobile={isMobile}
          showMenu={showMenu}
          isPhone={isPhone}
        />
      ) : (
        <Logouted2
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
          resetSearchKeyword={resetSearchKeyword}
          isMobile={isMobile}
          showMenu={showMenu}
          isPhone={isPhone}
          position={position}
        />
      )}
    </Webpage2StylesContainer>
  );
}

export default Header2;
