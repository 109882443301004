import styled from 'styled-components';
import { ImgStyled } from '../styles/ImgStyled';

const Exclusive = ({ left, width }) => {
  return (
    <ExclusiveStyle className='exclusive' left={left} width={width}>
      <ImgStyled src='/assets/img/icon/fab365-only.png' alt='exclusive' />
    </ExclusiveStyle>
  );
};

export default Exclusive;

const ExclusiveStyle = styled.div`
  display: inline-block;
  width: ${props => props.width || '171px'};
  position: absolute;
  top: 0;
  left: ${props => props.left || '0'};
  z-index: 10;

  img {
    width: 100%;
  }
`;
