import {
  PAGEID_HELP_14013138301_BROKEN_LIVING_HINGE,
  PAGEID_HELP_14013138302_MOVING_PARTS_FALL,
  PAGEID_HELP_14013138303_PARTS_NOT_MOVE,
  PAGEID_HELP_14013138304_BED_TYPES,
  PAGEID_HELP_14013138305_BED_GOOD,
  PAGEID_HELP_14013138306_BED_PEI,
} from "../../route/constant";

export const TIPS = [
  {
    IMAGE:
      "https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/hairgel2.jpg",
    SUBJECT: "Is bed not sticking well?",
    BODY: 'There are a lot of tips online on how to enhance the adhesive strength of bed in many different ways and we tried hair-spray, adhesive glue but none of them brought a satisfying solution.<br/><br/>Use "Gatsby\'s hair gel."',
    DATE: "Jan 31 2020",
    URL: PAGEID_HELP_14013138306_BED_PEI + "/not_sticking_to_bed",
  },
  {
    IMAGE:
      "https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/pei-pex.jpg",
    SUBJECT: "Which is the best 3D Printer Bed/Plate?",
    BODY: "PEI/PEX the best bed.",
    DATE: "Jan 22 2020",
    URL: PAGEID_HELP_14013138305_BED_GOOD + "/best_3d_printer_bed",
  },
  {
    IMAGE:
      "https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/all.jpg",
    SUBJECT: "Types of 3D Printer bed/plate",
    BODY: "3D Printer bed/plate has to have a strong adhesion while printing but has to come apart well when printing is completed",
    DATE: "Jan 13 2020",
    URL: PAGEID_HELP_14013138304_BED_TYPES + "/3d_printer_bed_types",
  },
  {
    IMAGE:
      "https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/pages/14013382173-tips/14013138301.jpg",
    SUBJECT: "Broken living hinge",
    BODY: "Some of our customers say this living hinge breaks. We are going to find out how to prevent living hinges from being broken.",
    DATE: "Dec 15 2020",
    URL: PAGEID_HELP_14013138301_BROKEN_LIVING_HINGE + "/broken_living_hinge",
  },
  {
    IMAGE:
      "https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/pages/14013382173-tips/14013138302.jpg",
    SUBJECT: " Failure to print the moving part",
    BODY: "You have to be very cautious on adhesion of the bed",
    DATE: "Dec 15 2020",
    URL: PAGEID_HELP_14013138302_MOVING_PARTS_FALL + "/moving_parts_fall",
  },
  {
    IMAGE:
      "https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/pages/14013382173-tips/14013138303.jpg",
    SUBJECT: " Parts do not move",
    BODY: "Each part has to be spaced out consistently, and if they don’t, they will stick together and cannot be moved.",
    DATE: "Dec 15 2020",
    URL: PAGEID_HELP_14013138303_PARTS_NOT_MOVE + "/parts_not_move",
  },
];
