import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styled from 'styled-components';

const ReadQuill = ({ requestBody }) => {
  const renderedBody = useMemo(() => {
    return (
      <ReactQuill value={requestBody} readOnly={true} theme='snow' modules={{ toolbar: false }} />
    );
  }, [requestBody]);

  return <EditorQuill>{renderedBody}</EditorQuill>;
};

export default ReadQuill;

const EditorQuill = styled.div`
  width: 100%;

  .quill {
    margin-bottom: 30px;
  }

  .video-size {
    margin-bottom: 10px;
  }

  .ql-tooltip {
    display: none;
  }

  .input-box {
    margin-bottom: 10px;
    h3 {
      margin-bottom: 10px;
      line-height: 20px;
    }
    input {
      width: 100px;
      height: 30px;
      margin-right: 10px;
      padding: 5px 10px;
      box-sizing: border-box;
    }

    button {
      height: 30px;
    }
  }

  em {
    font-style: italic;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #aaa !important;
  }
  .ql-container.ql-snow {
    border: none;
  }

  .ql-video {
    width: 800px;
    height: 450px;
  }

  .add-file {
    margin-top: 80px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .file-list {
    display: flex;
    flex-direction: column;
    div {
      margin: 10px 0px;
    }
    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }
  }

  .ql-editor ol {
    padding-left: 1rem;

    li {
      padding-left: 1.5rem;
      ::before {
        width: 1em;
        text-align: justify;
      }
    }
  }
  .ql-editor ul {
    padding-left: 1rem;
    li {
      padding-left: 1.5rem;
      ::before {
        width: 1em;
        text-align: justify;
      }
    }
  }

  .ql-editor .ql-indent-1 {
    padding-left: 2.5rem !important;
  }
  .ql-editor .ql-indent-2 {
    padding-left: 3.5rem !important;
  }
  .ql-editor .ql-indent-3 {
    padding-left: 4.5rem !important;
  }
  .ql-editor .ql-indent-4 {
    padding-left: 5.5rem !important;
  }
  .ql-editor .ql-indent-5 {
    padding-left: 6.5rem !important;
  }
  .ql-editor .ql-indent-6 {
    padding-left: 7.5rem !important;
  }
  .ql-editor .ql-indent-7 {
    padding-left: 8rem !important;
  }
  .ql-editor .ql-indent-8 {
    padding-left: 9.5rem !important;
  }
  .ql-editor .ql-indent-9 {
    padding-left: 10.5rem !important;
  }

  ul {
    list-style-type: disc !important;
    line-height: 25px;
  }

  ol {
    list-style-type: decimal !important;
    line-height: 25px;
  }

  p {
    font-size: 16px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  li {
    font-size: 16px;
  }

  img {
    max-width: 800px;
  }

  a {
    color: #06c;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }
`;
