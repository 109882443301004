import { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import AboutCoCeoEn from './component/AboutCoCeoEn';
import AboutCoCeoKo from './component/AboutCoCeoKo';

const AboutCoCeo = () => {
  const { action } = useContext(LangContext);

  return (
    <>
      <Layout>
        <main>
          <div id='main2'>{action.isKo() ? <AboutCoCeoKo /> : <AboutCoCeoEn />}</div>
        </main>
      </Layout>
    </>
  );
};

export default AboutCoCeo;
