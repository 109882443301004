import { useEffect } from 'react';
import { REVIEW } from '../../../route/constant';
import { getMainThumb } from '../../../util/image';
import { getOidUrl } from '../../../util/url';
import { useState } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../styles/ImgStyled';

const UsersWork = ({ data, page }) => {
  const [viewSize, setViewSize] = useState(window.innerWidth);

  const navigate = useNavigate();

  const handleResize = () => {
    setViewSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path, { state: page });
  };

  const settings = {
    dots: viewSize <= 1024 ? true : false,
    infinite: false,
    speed: 300,
    slidesToShow:
      viewSize > 1024
        ? 4
        : viewSize <= 1024 && viewSize > 768
        ? 3
        : viewSize <= 768 && viewSize > 640
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className='post-list post-list-4' id='userReviewPost'>
        <div className='list clearfix'>
          <Slider {...settings}>
            {data?.map((w, idx) => {
              const url = getOidUrl(REVIEW, w.id, w.subject, w.body);
              return (
                <div className='list-item' key={idx}>
                  <div className='post-list-item post-list-item-square'>
                    <Link to={url} onClick={e => goTo(e, url)} tabIndex='0'>
                      <div className='tb'>
                        <ImgStyled src={getMainThumb(w)} alt='' />
                      </div>
                      <div className='post-desc'>
                        <h3 className='post-title'>{w.subject}</h3>
                        <div className='post-meta'>
                          <span className='author'>
                            By. <span className='name'>{w.user_name}</span>
                          </span>
                          <span className='post-stat'>
                            <span className='hearted'>{w.like_count}</span>
                            <span className='comment'>{w.comment_count}</span>
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default UsersWork;
