import React, { useContext, useEffect } from 'react';
import Login from '../../component/layout/Login';
import ModalContext from '../../context/modal/modal';
import styled from 'styled-components';

const AppdesignerLogin = () => {
  const { action: modalAction } = useContext(ModalContext);

  useEffect(() => {
    modalAction.setIsLogin(true);
  }, []);

  return (
    <AppLogin>
      <Login appValue={true} />
    </AppLogin>
  );
};

export default AppdesignerLogin;

const AppLogin = styled.div`
  @media screen and (max-width: 400px) {
    button {
      margin-bottom: 0px !important;
    }
  }
`;
