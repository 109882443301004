import { useNavigate } from 'react-router-dom';
import { FOOTER_CONTACT_BUSINESS } from '../../../route/constant';
import {
  MembersContainer,
  MembersWrapper,
  MemberTitleContainer,
} from '../../Term/component/TermStyle';

const CopyrightEn = () => {
  const navigate = useNavigate();
  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };
  return (
    <>
      <MembersContainer className='container'>
        <div className='terms'>
          <div className='terms-header'>
            <MemberTitleContainer>
              <h1 className='logo'>
                <img src='/assets/img/members/logo.png' alt='logo' />
              </h1>
              <h2 className='terms-title'>CONTENT AND COPYRIGHT POLICY</h2>
            </MemberTitleContainer>
          </div>
          <MembersWrapper className='terms-body'>
            <p className='members-title'>Content and Copyright Policy</p>
            <div className='term-block'>
              <h3>Article 1. Purpose</h3>
              <p>
                This license agreement aims to specify the ownership and license provision
                (including purpose and scope of use) of the content sold in relation to transactions
                of content on the Fab365 e-Product marketplace for 3D printing (hereinafter referred
                to as 'Service'), provided by Fab365, Inc. (hereinafter referred to as 'the
                Company'). It also aims to establish the rights, obligations, responsibilities, and
                other necessary matters related to the ownership and use of content for both buyers
                and sellers.
              </p>
            </div>

            <div className='term-block'>
              <h3>Article 2. Definition of Terms</h3>
              <p>(1) The definitions of terms used in this agreement are as follows:</p>
              <p>
                1. Member: An individual or corporation that has provided personal information and
                registered as a member with the Company, categorized as General Members and Seller
                Members as defined below:
              </p>
              <p>
                ① General Member ('General Member' or 'Buyer'): An individual or corporation over 14
                years of age who can use the purchasing services provided by the Company.
              </p>
              <p>
                ② Designer Member ('Designer Member' or 'Seller'): An individual or corporation over
                14 years of age who can use both the purchasing and selling services provided by the
                Company.
              </p>
              <p>
                2. Content: 3D printing model data and related content traded between sellers and
                buyers, or between the Company and buyers, through the services provided by the
                Company.
              </p>
              <br />
              <p>
                (2) The meanings of terms used in this agreement that are not defined in paragraph
                (1) or in the articles where the terms are used shall be interpreted according to
                general trading practices.
              </p>
            </div>

            <div className='term-block'>
              <h3>Article 3. Ownership of Content</h3>
              <p>
                The copyright of all content produced and sold by the seller through the Service
                remains with the copyright owner, and nothing in this agreement shall be interpreted
                as transferring copyright to the buyer.
              </p>
            </div>
            <div className='term-block'>
              <h3>Article 4. License Granted by the Seller to the Buyer</h3>
              <p>
                (1) When a buyer purchases content through the Service, the seller grants a license
                to use that content to the buyer from the time of purchase. The purpose and method
                of using such content by the buyer are as follows:
              </p>
              <p>1. Purpose of using content: Personal, non-commercial purposes.</p>
              <p>
                2. Scope of using content: Reproduction, distribution, display, performance,
                broadcasting, rental, adaptation, modification, editing, public transmission, and
                creation of derivative works are not allowed, but viewing and 3D printer output are
                possible.
              </p>
              <p>
                3. Conditions of using content: Non-transferable, non-exclusive, sublicense is not
                granted, and the right is used without separate royalties excluding the purchase
                cost, both domestically and internationally.
              </p>
              <p>
                4. Media or platforms for using content: Includes all forms of media, equipment, and
                technology that are currently known or will be developed in the future.
              </p>
            </div>

            <div className='term-block'>
              <h3>Article 5. Sale of Printed Content</h3>
              <p>
                (1) Buyers may print the content purchased through the Service using a 3D printer,
                but they may not sell, reproduce, distribute, display, perform, broadcast, rent,
                adapt, modify, edit, publicly transmit, or create derivative works of the printed
                materials for commercial purposes.
              </p>
              <br />
              <p>
                (2) However, the Company may allow some exceptions for the sale of printed content,
                and specific details of the exceptions are provided through{' '}
                <a
                  href=''
                  onClick={e => goTo(e, FOOTER_CONTACT_BUSINESS)}
                  style={{ color: '#835fec' }}
                >
                  [separate guidelines]
                </a>
                .
              </p>
            </div>

            <div className='term-block'>
              <h3>Article 6. Royalties</h3>
              <p>
                Unless the buyer and seller enter into a separate agreement apart from this
                contract, the buyer does not pay any separate royalties while using the content
                within the scope and purpose under this contract.
              </p>
            </div>

            <div className='term-block'>
              <h3>Article 7. Separate License Agreement Between Seller and Buyer</h3>
              <p>
                Notwithstanding the contents of this contract, the seller and buyer may enter into a
                separate license agreement on the use, scope, conditions of use, media, and sale of
                printed content, which may differ from the contents of this contract through mutual
                individual agreement.
              </p>
            </div>

            <div className='term-block'>
              <h3>Article 8. Role of the Company</h3>
              <p>
                The Company endeavors to take commercially reasonable measures to ensure that buyers
                or other third parties do not infringe on the licenses granted to the buyers by this
                contract or by the license agreements individually concluded between the seller and
                buyer. However, the Company is not obligated to take factual or legal action against
                members or third parties who violate the copyright or related rights of sellers
                registered in the Service, nor is it liable for compensating buyers for such
                infringement.
              </p>
            </div>

            <div className='term-block'>
              <h3>Article 9. Governing Law and Jurisdiction</h3>
              <p>
                (1) Litigations between the Company and buyers, or the Company and sellers, shall be
                governed by the laws of the Republic of Korea.
              </p>
              <p>
                (2) In the case of a lawsuit involving the Company related to this contract, the
                court with jurisdiction over the headquarters of the Company shall be the agreed
                jurisdictional court.
              </p>
            </div>
            <div className='term-block'>
              <h3>Addendum</h3>
              <p>These terms and conditions shall take effect from February 23, 2024.</p>
            </div>
          </MembersWrapper>
        </div>
      </MembersContainer>
    </>
  );
};

export default CopyrightEn;
