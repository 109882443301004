import RequestStatus from '../../../../component/request/RequestStatus';
import { MY_REQUEST_EDIT } from '../../../../route/constant';
import { getMainThumb } from '../../../../util/image';
import RequestIcon from '../../../Request/component/RequestIcon';
import { deleteRequest } from '../../../../action/request';
import { RETURN_CODE } from '../../../../action/constant';
import { useNavigate } from 'react-router-dom';

const RequestItem = ({ data }) => {
  const navigate = useNavigate();

  const tryDeleteRequest = async oId => {
    try {
      const { data } = await deleteRequest(oId);
      if (data.returnCode === RETURN_CODE.C00000) window.location.href = '/my/requests';
    } catch (e) {
      alert(e);
    }
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      {data.map(r => {
        const forJs = {
          oid: r.request_oid,
        };

        return (
          <li>
            <div
              className='mypage-request-list-item'
              data-json="' . htmlspecialchars(json_encode($forJs)) . '"
            >
              <div className='mypage-request-list-item-inner'>
                <div className='tb requestsTb'>
                  <img src={getMainThumb(r)} alt='' />
                  <RequestIcon status={r.status} />
                </div>
                <div className='product-meta'>
                  <div className='post-meta'>
                    <h5 className='product-name'>{r.subject}</h5>
                    <div className='post-stat'>
                      <span className='comment'>{r.comment_count}</span>
                    </div>
                  </div>
                  <div className='request-status'>
                    <div className='request-status-header'>{r.vote_count}%</div>
                    <div className='request-status-body'>
                      <div className='progress'>
                        <span
                          className='bar'
                          style={{ width: Math.floor(r.vote_count) + '%' }}
                        ></span>
                      </div>
                    </div>
                    <div className='request-status-footer'>
                      <div className='supporters'>
                        Supporters <strong>{r.vote_count}</strong>
                      </div>
                      <RequestStatus status={r.status} />
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <a
                    href=''
                    className='btn btn-sm'
                    onClick={e => goTo(e, MY_REQUEST_EDIT + '/' + r.request_oid)}
                  >
                    Edit
                  </a>
                  <button
                    className='btn btn-sm loading_color'
                    data-action='1201-request-delete'
                    onClick={() => {
                      tryDeleteRequest(r.request_oid);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </>
  );
};

export default RequestItem;
