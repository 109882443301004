import React from 'react';
import styled from 'styled-components';
import Layout from '../../component/layout/Layout';
import { useNavigate } from 'react-router-dom';
import {
  FOOTER_CONTACT,
  FOOTER_COPYRIGHT,
  FOOTER_PRIVACY,
  FOOTER_TERMS,
  FOOTER_TERMS_POINT,
} from '../../route/constant';

const NoticeContainer = styled.div`
  width: 100vw;
  height: auto;
  background-image: url('/assets/img/notice/notice-bg.png');
  box-sizing: border-box;
  padding: 50px;
  @media (max-width: 500px) {
    padding: 20px;
  }
`;

const LogoBox = styled.div`
  width: 100%;
  /* background-color: yellow; */
  text-align: center;
  padding-bottom: 30px;
  margin: 20px 0;
`;

const ContentBox = styled.div`
  width: 800px;
  background-color: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 16px;
  transition: all.2s;
  .titleWrap {
    display: flex;
    width: 100%;
    height: 65px;
    border-radius: 16px 16px 0 0;
    justify-content: center;
    align-items: center;
    background-color: #111;
    h2 {
      text-align: center;
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 2%;
      transition: all.2s;
      line-height: 25.2px;
    }
  }
  .contentWrap {
    padding: 50px;
    padding-bottom: 0;
    div {
      padding: 18px 0;
      h5 {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.4175rem;
        padding-bottom: 25px;
      }
      p {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.375rem;
        padding-bottom: 25px;
      }
      .grid {
        display: grid;
        grid-template-columns: 1fr 25fr;
        .dot-icon {
          width: 3px;
          height: 3px;
          background-color: #111;
          border-radius: 50px;
          margin: 8px 8px;
          box-sizing: border-box;
        }
        p {
          padding-top: 10px;
          line-height: 26px;
          padding-bottom: 0;
        }
      }
    }
    .link {
      color: #000;
      padding-bottom: 1px;
      border-bottom: 1px solid #835fec;
      color: #835fec;
    }
  }
  @media (max-width: 1000px) {
    width: 600px;
    transition: all.2s;
  }
  @media (max-width: 720px) {
    width: 500px;
    transition: all.2s;
  }
  @media (max-width: 610px) {
    width: 400px;
    transition: all.2s;
    .contentWrap {
      padding: 20px;
    }
  }
  @media (max-width: 500px) {
    width: 90%;
    transition: all.2s;
    .contentWrap {
      padding: 20px;
    }
    .titleWrap {
      h2 {
        font-size: 16px;
        transition: all.2s;
      }
    }
  }
`;

const Notice = () => {
  const navigate = useNavigate();
  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <Layout>
      <NoticeContainer>
        <LogoBox>
          <img src='/assets/img/notice/logo.svg' alt='logo' />
        </LogoBox>
        <ContentBox>
          <div className='titleWrap'>
            <h2>Service Improvement Notice</h2>
          </div>
          <div className='contentWrap'>
            {/* 1. Hello, Fab365 Users! */}
            <div>
              <h5>Hello, Fab365 Users!</h5>
              <p>
                Thank you for always loving Fab365. We are reaching out to inform you about changes
                to our reward policy.
              </p>
            </div>
            {/* 2. The changes will be effective from February 23, 2024 (UTC+9) */}
            <div>
              <h5>The changes will be effective from February 23, 2024 (UTC+9)</h5>
              <p>
                Find out exactly what we are changing below. If you have opted out of receiving
                marketing emails – please note that this is not one of those emails. Legally, we are
                required to inform all Fab365 users about changes to the terms and conditions.
              </p>
            </div>
            {/* 3. Why the Change? */}
            <div>
              <h5>Why the Change?</h5>
              <p>
                We are well aware that some of you have been confused and discomforted by the term
                "Free Item". Strictly speaking, calling a sign-up celebration or a bonus given with
                a paid purchase "Free" was somewhat inappropriate. Therefore, we thought it
                necessary to use terms that convey clearer meanings.
              </p>
              <p>
                Additionally, we are developing a new rewards system to allow you to experience
                various rewards based on your activities within the service. To facilitate this, we
                have determined that it is necessary to boldly revise and supplement the existing
                Point policy. Furthermore, to avoid confusion with the new rewards system that will
                be introduced soon, we have decided to change the name of the currency as well.
              </p>
              <p>
                Following these two major changes, the related terms and documentation will also be
                updated. We hope you understand that these measures are part of our effort to
                provide you with better service.
              </p>
            </div>
            {/* 4. What Changes? */}
            <div>
              <h5>What Changes?</h5>
              <p>Here is a summary of the main changes:</p>
              <p className='grid'>
                <i className='dot-icon'></i>
                <span>
                  The term "Free Item" will be changed to "Reward Model", and "Free item - download
                  ticket" will be changed to "Reward Ticket". This will also involve modifications
                  to related pages and descriptions.
                </span>
              </p>
              <p className='grid'>
                <i className='dot-icon'></i>
                <span>
                  The virtual currency within the service, "Point", will be transformed into a new
                  currency called "Credit". 1 Credit will have a value of $0.01 USD. This means that
                  $1 USD is equivalent to 100 Credits. The Points you currently hold will be
                  converted at a rate of 1 Credit for every 0.01 Point. Additionally, the bonus
                  Credits given when charging Credits will be adjusted, and related pages and
                  descriptions will also be updated.
                </span>
              </p>
              <p className='grid'>
                <i className='dot-icon'></i>
                <span>
                  We have made some documentation revisions, including correcting typos, fixing
                  incorrect clause numbers, etc. The "Point Terms" document has been changed to
                  "Credit Terms and Conditions". You can check the updated documents at the
                  following links:
                  <p>
                    &#8226;{' '}
                    <a href='' onClick={e => goTo(e, FOOTER_TERMS)}>
                      <span className='link'>Terms of Service</span>
                    </a>
                    <br />
                    &#8226;{' '}
                    <a href='' onClick={e => goTo(e, FOOTER_PRIVACY)}>
                      <span className='link'>PRIVACY POLICY</span>
                    </a>
                    <br />
                    &#8226;{' '}
                    <a href='' onClick={e => goTo(e, FOOTER_COPYRIGHT)}>
                      <span className='link'>Content & Copyright</span>
                    </a>
                    <br />
                    &#8226;{' '}
                    <a href='' onClick={e => goTo(e, FOOTER_TERMS_POINT)}>
                      <span className='link'>Credit terms and Conditions</span>
                    </a>
                    <br />
                  </p>
                </span>
              </p>
            </div>
            {/* 5. Do I Need to Take Any Action? */}
            <div>
              <h5>Do I Need to Take Any Action?</h5>
              <p>
                No, there is no action required from you. Continuing to use the Fab365 service after
                February 23, 2024 (UTC+9) indicates your agreement to these updates. Just be aware
                that your existing “Points” and “Free item - download tickets” will be converted
                into the same value of “Credits” and “Reward Tickets”.
              </p>
            </div>
            {/* 6. Have Any Questions? */}
            <div>
              <h5>Have Any Questions?</h5>
              <p>
                Please{' '}
                <a href='mailto:info@fab365.net'>
                  <span className='link'>contact us here</span>
                </a>
                , and we will be happy to help.
              </p>
              <p>
                Warm regards,
                <br />
                Fab365.
              </p>
            </div>
          </div>
        </ContentBox>
      </NoticeContainer>
    </Layout>
  );
};

export default Notice;
