const ProfilePrinterOptions = ({ data, selected, onChange }) => {
  return (
    <>
      <div className="form-group">
        <label for="userConfirmPassword" className="form-label">
          Printer
        </label>
        <div className="form-control">
          <select className="form-input" name="printer" value={selected} onChange={e=>onChange(e.target.value)}>
            <option value="0">Select your 3D printer {">>>"}</option>
            {data?.map((p) => {
              return <option value={p.id_printer} key={p.id_printer}>{p.printer}</option>;
            })}
          </select>
        </div>
      </div>
    </> 
  );
};

export default ProfilePrinterOptions;
