import { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import CopyrightEn from './component/CopyrightEn';
import CopyrightKo from './component/CopyrightKo';
import { MainContainer } from '../Term/Term';

const Copyright = () => {
  const { action } = useContext(LangContext);
  return (
    <Layout>
      <MainContainer id='main'>{action.isKo() ? <CopyrightKo /> : <CopyrightEn />}</MainContainer>
    </Layout>
  );
};

export default Copyright;
