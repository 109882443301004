import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const LinkModal = ({
  saveLink,
  setLinkModal,
  setLinkText,
  setLinkUrl,
  linkText,
  linkUrl,
  setEdit,
  setEditLinkUrl,
  onClickDeleteLink,
  linkModal,
  tag,
}) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClick = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setEditLinkUrl(false);
        setLinkModal(false);
        setLinkText('');
        setLinkUrl('');
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [modalRef]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      saveLink(linkText, linkUrl);
    }
  };

  const onClickCopy = async () => {
    await navigator.clipboard.writeText(`${linkUrl}`);
    alert('The link has been copied.');
  };

  const onClickEdit = () => {
    setLinkModal(true);
    setEdit(true);
  };

  return (
    <Link ref={modalRef}>
      {!linkModal ? (
        <>
          <div className='edit-url'>
            <img src='/assets/img/icon/icon-globe.png' className='icon' />
            <a href={linkUrl} target='_blank'>
              {linkUrl}
            </a>
            <button onClick={() => onClickCopy()}>
              <img src='/assets/img/icon/icon-copy.png' className='icon' />
            </button>
            <button onClick={() => onClickEdit()}>
              <img src='/assets/img/icon/icon-edit.png' className='icon' />
            </button>
            <button onClick={() => onClickDeleteLink()}>
              <img src='/assets/img/icon/icon-delete.png' className='icon' />
            </button>
          </div>
        </>
      ) : (
        <>
          {tag === 'IMG' ? (
            ''
          ) : (
            <input
              placeholder='text'
              value={linkText}
              onChange={e => setLinkText(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          )}

          <div className='link'>
            <input
              placeholder='link'
              value={linkUrl}
              onChange={e => setLinkUrl(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button onClick={() => saveLink(linkText, linkUrl)} className='save-btn'>
              save
            </button>
          </div>
        </>
      )}
    </Link>
  );
};

export default LinkModal;

const Link = styled.div`
  width: 400px;
  background-color: #ffffff;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 20px;

  input {
    width: 80%;
    padding: 5px;
  }

  .link {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .save-btn {
      background-color: #efefef;
    }

    button {
      height: 30px;
      padding: 0px 10px;
      border: none;
    }
  }

  .edit-url {
    display: grid;
    grid-template-columns: 10% 60% 10% 10% 10%;
    align-items: center;
    button {
      border: none;
      background-color: #ffffff;
    }

    a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }
`;
