export const OPTION_ITEM_NEW = 'new';
export const OPTION_ITEM_POPULAR = 'popular';
export const OPTION_ITEM_PRICE_HIGH = 'priceDsc';
export const OPTION_ITEM_PRICE_LOW = 'priceAsc';

// TODO:독점
export const OPTION_ITEM_ALL = 'itemAll';
export const OPTION_ITEM_ONLYFAB365 = 'Fab365';
export const OPTION_ITEM_GENERAL = 'General';
export const OPTION_ITEM_SET = 'itemSet';
export const OPTION_ITEM_SINGLE = 'itemSingle';
