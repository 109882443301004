import React, { createContext, useState } from 'react';

const CartContext = createContext({
  state: {
    check: {},
    checkout: {},
    cartsCount: 0,
    checkoutFree: {},
  },
  action: {
    checkProduct: () => {},
    uncheckProduct: () => {},
    checkoutProducts: () => {},
    changeCartsCount: () => {},
    checkoutFreeProduct: () => {},
    initCarts: () => {},
    initCheck: () => {},
  },
});

const CartProvider = ({ children }) => {
  const [check, setCheck] = useState({});
  const [checkout, setCheckout] = useState({});
  const [cartsCount, setCartsCount] = useState(0);
  const [checkoutFree, setcheckoutFree] = useState(null);

  const checkProduct = (id, product) => {
    setCheck(prev => {
      return {
        ...prev,
        [id]: product,
      };
    });
  };
  const uncheckProduct = id => {
    setCheck({
      ...check,
      [id]: 'false',
    });
  };
  const checkoutProducts = items => {
    setCheckout(items);
  };
  const changeCartsCount = count => {
    setCartsCount(count);
  };
  const checkoutFreeProduct = item => {
    setcheckoutFree(item);
  };

  const initCarts = () => {
    setCheck({});
    setCheckout({});
    setCartsCount(0);
  };

  const initCheck = () => {
    setCheck({});
  };

  const value = {
    state: { check, checkout, cartsCount, checkoutFree },
    action: {
      checkProduct,
      uncheckProduct,
      checkoutProducts,
      changeCartsCount,
      checkoutFreeProduct,
      initCarts,
      initCheck,
    },
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const { Consumer: CartConsumer } = CartContext;

export { CartProvider, CartConsumer };
export default CartContext;
