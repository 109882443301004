import styled from 'styled-components';

export const MembersContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 94px;
  h2,
  h3 {
    font-weight: 700;
  }
`;

export const MembersWrapper = styled.div`
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 25px 30px 30px 30px !important;

  .members-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 25px;
  }

  .terms {
    padding: 30px !important;
  }
`;

export const MemberTitleContainer = styled.div`
  h1 {
    display: flex;
    justify-content: center;
  }
  img {
    width: 76.87px;
    height: 17px;
    margin: 50px 0;
    text-align: center;
  }

  h2 {
    background-color: #000;
    border-radius: 10px 10px 0 0;
    color: #fff;
    font-family: Outfit;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
