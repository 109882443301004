import { useNavigate } from 'react-router-dom';
import { ITEMS } from '../../../route/constant';
import CartPrice from './CartPrice';

const BuyableProduct = ({ data, limit, deleteItem }) => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='purchase-product-list-item-inner'>
        <div className='checker'>
          <label className='input-radio'>
            <input type='radio' value={data.productId} data-price='0' disabled />
            <span className='label'></span>
          </label>
        </div>
        <a href='' onClick={e => goTo(e, ITEMS + '/' + data.productId)}>
          <img src={data.itemRowDtos.list_img} className='tb' alt='' />
        </a>
        <div className='product-meta'>
          <span className='product-name'>
            <a href='' onClick={e => goTo(e, ITEMS + '/' + data.productId)}>
              {data.itemRowDtos.name_en}
            </a>
          </span>
          <span className='free-limit-106f'>
            <b>{limit.expireTime}</b>
          </span>
          {data.isBuyable && (
            <>
              <span className='icon-lock id-13fa'></span>
              <span className='price'>
                <CartPrice data={data} />
              </span>
            </>
          )}
          {!data.isBuyable && <span className='warn-3c9a'>{data.isBuyableErrorMsg}</span>}
        </div>
        <a
          className='btn-delete'
          data-action='cart-delete'
          data-item={data.productId}
          onClick={() => deleteItem(data.productId)}
        >
          ×
        </a>
      </div>
    </>
  );
};

const CartFreeExpiredProduct = ({ data, limit }) => {
  return (
    <>
      <li>
        {data.isBuyable && (
          <div className='purchase-product-list-item'>
            <BuyableProduct data={data} limit={limit} />
          </div>
        )}
        {!data.isBuyable && (
          <div className='purchase-product-list-item already-bought-3c3a'>
            <BuyableProduct data={data} limit={limit} />
          </div>
        )}
      </li>
    </>
  );
};

export default CartFreeExpiredProduct;
