/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components';

const DownloadButton = ({ isLoading, onClickDownload }) => {
  return (
    <>
      {/* 로그인 && 티켓 충분 상태 */}

      {!isLoading && (
        <DownloadButtonStyle onClick={onClickDownload}>
          <img src='/assets/img/itemDetail/btn-icon-fff.png' />
          DOWNLOAD
        </DownloadButtonStyle>
      )}
    </>
  );
};

export default DownloadButton;

const DownloadButtonStyle = styled.button`
  font-family: 'Outfit', sans-serif;
  width: 100%;
  background-color: #000 !important;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  img {
    width: 14px;
  }
`;
