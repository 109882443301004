import React, { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import { useLocation, useNavigate } from 'react-router';
import { Container } from './styles';
import { login, validateVerifyEmail } from '../../action/request';
import { useEffect } from 'react';
import { useState } from 'react';
import { Ss, ls } from '../../constant';
import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import { ITEMS } from '../../route/constant';
import { isWelcomeModal } from '../../atom/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';

function Verify() {
  const navigate = useNavigate();
  const isWelcomeModalState = useSetRecoilState(isWelcomeModal);
  const { action: userAction } = useContext(UserContext);
  const { state, action } = useContext(ModalContext);
  const location = useLocation();
  const code = location.search.split('=')[1];
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    verifyEmail();
  }, [code]);

  const verifyEmail = async () => {
    try {
      const {
        data: { data },
      } = await validateVerifyEmail(code);

      const accessToekn = data.token.access_token;
      const refreshToken = data.token.refresh_token;
      localStorage.removeItem(ls.accessToken);
      localStorage.removeItem(ls.refreshToken);
      sessionStorage.removeItem(Ss.inviteToken);

      localStorage.setItem(ls.accessToken, accessToekn);
      localStorage.setItem(ls.refreshToken, refreshToken);

      userAction.setEmail(data.userInfo.email);
      userAction.setId(data.userInfo.id);
      userAction.setIsLogin(true);
      //recoil 로그인상태 저장
      userAction.setThumbnail(data.userInfo.thumbnail);
      action.setIsLogin(false);

      if (sessionStorage.getItem(Ss.itemNumber)) {
        navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
        sessionStorage.removeItem(Ss.itemNumber);
      }

      if (data.message) {
        setErrorMessage(data.message);
      } else {
        setErrorMessage('');
      }

      if (!errorMessage.length) {
        //로그인 성공시 모달 띄우고 홈으로 이동1
        isWelcomeModalState(true);
        navigate('/');
      }
    } catch (e) {}
  };

  useEffect(() => {}, []);

  return (
    <Layout>
      <Container>
        <div className='container'>
          <h3 className='blank-h3'>Email address verification</h3>
          {!errorMessage.length ? (
            <div className='blank-alarm blank-success'>
              Thanks! Your email address has been verified.
            </div>
          ) : (
            <div className='blank-alarm blank-error'>{errorMessage}</div>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default Verify;
