import React from 'react';
import { theme } from '../../../styles/theme';
import Skeleton from 'react-loading-skeleton';

const SkeletonCardMain = () => {
  const SKELETON_PADDINGTOP = '100%';
  return (
    <div>
      <Skeleton
        style={{
          paddingTop: SKELETON_PADDINGTOP,
          borderRadius: '8px',
        }}
        baseColor={theme.skeleton_gray}
        highlightColor={theme.skeleton_light_gray}
      />
      <Skeleton
        className='skeleton--title'
        width={160}
        baseColor={theme.skeleton_dark_gray}
        highlightColor={theme.sleleton_dark_gray_highlight}
      />
      <Skeleton
        className='skeleton--subtitle'
        width={200}
        baseColor={theme.skeleton_dark_gray}
        highlightColor={theme.sleleton_dark_gray_highlight}
      />
      <Skeleton
        className='price'
        width={120}
        style={{ marginTop: 20 }}
        baseColor={theme.skeleton_dark_gray}
        highlightColor={theme.sleleton_dark_gray_highlight}
      />
      <Skeleton
        className='skeleton--etc'
        width={200}
        height={11}
        baseColor={theme.skeleton_dark_gray}
        highlightColor={theme.sleleton_dark_gray_highlight}
      />
    </div>
  );
};

export default SkeletonCardMain;
