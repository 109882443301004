/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Layout from '../../../component/layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import Categoryist from './CategoryList';
import ItemList from './ItemList';
import { getCategories, getCollectionItemsNew, getFreeLimit } from '../../../action/request';
import { gtagViewItemList } from '../../../action/gTag';
import { COLLECTIONS } from '../../../route/constant';
import Pagination from './Pagination';
import { ls } from '../../../constant';

const CollectionItemList = () => {
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(Number(localStorage.getItem(ls.collectionListPage)) || 1);
  const [collectionItems, setCollectionItems] = useState({ count: 0 });
  const [categoryTitle, setCategoryTitle] = useState('');
  const [limit, setLimit] = useState({});
  const { collectionURL } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data: categories } = await getCategories();
      setCategories(categories.data);

      const { data: resLimit } = await getFreeLimit();
      setLimit(resLimit.data);

      updateItemPage(page);
      // gtag 아이템 리스트 조회 태그
      if (collectionURL) {
        gtagViewItemList(collectionURL);
        // console.log('tag test', window.location.href, collectionName);
      }
    } catch (error) {}
  };

  const pageChange = async value => {
    updateItemPage(value);
  };

  const pageConfirm = value => {
    updateItemPage(value);
  };

  const updateItemPage = async value => {
    setPage(value);
    localStorage.setItem(ls.collectionListPage, value);

    try {
      const { data: resCollectionItems } = await getCollectionItemsNew({
        page: value,
        url: collectionURL,
      });
      // console.log(resCollectionItems.data);
      if (resCollectionItems.returnCode === 'C00000') {
        setCollectionItems(resCollectionItems.data);
        setCategoryTitle(resCollectionItems.data.category_name);
      } else if (resCollectionItems.returnCode === 'C00009') {
        // url로 조회한 아이템이 없다면 > 없는 페이지이므로 ERROR 페이지로 이동
        navigate(COLLECTIONS);
      }
    } catch (error) {}
  };

  const goToCollections = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate(COLLECTIONS);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top page-top-with-breadcrumb'>
            <div className='container container-lg'>
              <h1 className='page-title'>Collection</h1>
            </div>
          </div>

          <div className='main-content-with-aside'>
            <Categoryist current={'Collections'} data={categories.items} />
            <div className='main-content'>
              {/* collection 아이템 조회 */}
              <section className='store-collection-list'>
                <div className='container'>
                  <div
                    className='content-section content-section-has-top-nav'
                    style={{ minHeight: '900px' }}
                  >
                    <div className='content-section-header'>
                      {/* TODO : 제목 넣기 */}
                      <h2>{categoryTitle}</h2>
                      <a href='' className='btn-more' onClick={goToCollections}>
                        Collection List
                      </a>
                    </div>
                    <div className='product-list product-list-3'>
                      {<ItemList data={collectionItems?.items} limit={limit} />}
                    </div>
                    <Pagination
                      page={page}
                      total={Math.ceil(collectionItems.count / 9)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CollectionItemList;
