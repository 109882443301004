import styled from 'styled-components';

export const ManageInputContainer = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 600;

  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #000;

  img {
    width: 20px;
  }
`;

export const LabelStyle = styled.label`
  border-right: 1px solid #000;
  height: 100%;

  display: flex;
  flex-basis: 200px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 700px) {
    max-width: 120px;
  }
`;

export const InputStyle = styled.input`
  border: none;
  height: 100%;
  margin: 0 10px;
  padding-left: 20px !important;

  flex-grow: 1;
  color: #000;

  &::placeholder {
    color: #9a9a9a;
  }
`;

export const ImgWrapper = styled.div`
  flex-basis: 60px;
  text-align: center;
`;
