import { useNavigate } from 'react-router-dom';
import { MY_PROFILE } from '../../../route/constant';
import UserContext from '../../../context/user/user';
import { useContext, useEffect } from 'react';
import ModalContext from '../../../context/modal/modal';
import {
  MembersContainer,
  MembersWrapper,
  MemberTitleContainer,
} from '../../Term/component/TermStyle';

const PrivacyEn = () => {
  const navigate = useNavigate();
  const { state } = useContext(UserContext);
  const { action } = useContext(ModalContext);

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MembersContainer class='container'>
        <div class='terms'>
          <div class='terms-header'>
            <MemberTitleContainer>
              <h1 className='logo'>
                <img src='/assets/img/members/logo.png' alt='logo' />
              </h1>
              <h2 className='terms-title'>PRIVACY POLICY</h2>
            </MemberTitleContainer>
          </div>
          <MembersWrapper class='terms-body'>
            <p className='members-title'>PRIVACY POLICY</p>
            <div class='term-block'>
              <p>
                Fab365, Inc., (hereinafter referred to as 'the Company') has adopted the following
                Privacy Policy (hereinafter, 'Privacy Policy') in order to protect users' personal
                information and their rights and interests, and to address grievances related to
                personal information. This is in accordance with privacy protection laws and
                regulations, including the "Privacy Protection Act" and "the Law Regarding the
                Promotion of Information and Communication Network Use and Protection of
                Information."
              </p>
            </div>

            <div class='term-block'>
              <h3>Article 1: Purpose of Personal Information Processing</h3>
              <p>
                The Company processes only the minimal personal information necessary for the
                following purposes. The processed personal information will be used solely for these
                purposes, and the Company will seek prior consent for any change in the purpose of
                use. The purposes for which the Company processes personal information are as
                follows:
              </p>
              <p>
                - identifying users for membership services
                <br />
                - providing digital content trade platform services
                <br />
                - personal identification, preventing abuse and unauthorized use, discerning
                subscription intentions
                <br />
                - determining if a user is under 14 years old, obtaining consent from a legal
                representative for collecting personal information from children under 14
                <br />
                - conducting accident investigations, resolving disputes, responding to complaints,
                delivering notifications
                <br />
                - developing new services, providing customized services, offering event and
                commercial information and participation opportunities
                <br />
                - verifying service validity, understanding access frequency, compiling statistics
                on service usage by members
                <br />- issuing tax invoices and processing payments for charged services.
              </p>
            </div>
            <div class='term-block'>
              <h3>
                Article 2: Items of Personal Information to be Collected and Method of Collection
              </h3>
              <p>(1) The categories of personal information collected are as follows:</p>
              <p>
                1. General Members or Buyers:
                <br />
                Name, ID, mobile phone number, email address, (for foreigners) foreign registration
                number, service usage information, device information, date of birth, gender,
                personal authentication information, information related to purchases,
                cancellations, and refunds, account numbers, card numbers and other payment-related
                information, information for issuing cash receipts, etc.
              </p>
              <p>
                2. Designer Members or Sellers:
                <br />
                Name, ID, mobile phone number, email address, (for foreigners) foreign registration
                number, service usage information, device information, date of birth, gender,
                personal authentication information, information related to purchases,
                cancellations, and refunds, payment information, settlement details, account
                numbers, information for issuing cash receipts, information for issuing tax
                invoices, sales history, experience, business registration number, etc.
              </p>
              <br />
              <p>
                (2) In the course of using the service, the following information may be
                automatically generated and collected or converted and collected:
              </p>
              <p>
                - IP Address, cookies, time of visit, service usage records, access logs, device
                information, etc.
              </p>
              <br />
              <p>(3) The company collects personal information in the following ways:</p>
              <p>
                - Provided through the homepage, document forms, fax, telephone, email, boards,
                event entries, member centers, real-name certification companies, other affiliate
                companies, and voluntarily provided by the user during service use.
              </p>
            </div>
            <div class='term-block'>
              <h3>Article 3: Privacy Processing and Holding Period</h3>
              <p>
                The Company shall promptly destroy personal information once the purpose of its
                collection and use is achieved. However, information corresponding to the following
                items will be retained for the periods specified below for the outlined reasons:
              </p>
              <p>
                1. Record of contract or subscription withdrawal: as per the Act on Consumer
                Protection in Electronic Commerce Transactions, Etc. (5 years).
              </p>
              <p>
                2. Record of payment and supply of goods: as per the Act on Consumer Protection in
                Electronic Commerce Transactions, Etc. (5 years).
              </p>
              <p>
                3. Record of resolution for consumer complaints or disputes: as per the Act on
                Consumer Protection in Electronic Commerce Transactions, Etc. (3 years).
              </p>
              <p>
                4. Record of visit: as per the Protection of Communications Secrets Act (3 months).
              </p>
              <p>
                5. Record of electronic financial transactions and information on the other party in
                such transactions: as per the Electronic Financial Transaction Act (5 years).
              </p>
              <p>
                6. Information for national tax proof: for proof and declarations related to
                national tax (5 years).
              </p>
              <p>
                7. Information on taxation, including value-added tax: as per the Value-Added Tax
                Act (5 years).
              </p>
            </div>
            <div class='term-block'>
              <h3>Article 4: Providing Personal Information to Third Parties</h3>
              <p>
                (1) The Company processes users' personal information for the purposes outlined in
                Article 1 (Purpose of Personal Information Processing) and shall not process or
                provide it to third parties beyond these purposes without prior user consent.
                However, personal information may be provided to third parties in the following
                cases:
              </p>
              <p>1. If the member has agreed to the provision in advance.</p>
              <p>
                2. If the provision is required by laws or regulations, or is requested by
                investigative agencies according to the procedures and methods set forth in the laws
                and regulations
              </p>
              <br />
              <p>
                (2) The current status of providing personal information to third parties is as
                follows and may change depending on the situation:
              </p>
              <table class='table table-bordered'>
                <thead>
                  <tr>
                    <th>The Provided Party</th>
                    <th>Purpose of Provision</th>
                    <th>Information Provided</th>
                    <th>Retention Period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class='term-block'>
              <h3>Article 5: Entrustment of Personal Information Processing</h3>
              <p>
                (1) The Company may entrust the processing of personal information to another
                company to improve services and fulfill contractual obligations.
              </p>
              <br />
              <p>
                (2) Due to the entrustment of personal information processing, it is necessary to
                educate the trustee to prevent the loss, theft, leakage, alteration, or damage of
                personal information of data subjects, and to supervise the safe handling of
                personal information by the trustee through status checks and similar measures.
              </p>
              <br />
              <p>
                (3) The current status of personal information processing entrustment is as follows
                and may change depending on the situation.
              </p>
              <table class='table table-bordered'>
                <thead>
                  <tr>
                    <th>Trustee Corporation</th>
                    <th>Entrusted Task</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class='term-block'>
              <h3>
                Article 6: Rights and Obligations of Users and Their Legal Representatives and Their
                Exercise
              </h3>
              <p>
                (1) Members and their legal representatives may always access or correct the
                member's registered personal information. This can be done through an application or
                by requesting such access and correction to the personal information protection
                manager by written document, telephone, or via email. The Company shall not use or
                provide personal information until the correction is completed if requested by the
                member.
              </p>
              <br />
              <p>
                (2) Members may always withdraw their consent regarding the collection, use, and
                provision of personal information. Withdrawal requests can be made in writing, by
                telephone, or via email, and the manager shall take necessary measures immediately.
              </p>
              <br />
              <p>
                (3) You have the right to request the deletion of your personal data. This can be
                done from the Personal Details section in your profile.{' '}
                <a
                  href=''
                  onClick={e => {
                    if (!state.isLogin) {
                      e.preventDefault();
                      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
                      action.setIsLogin(true);
                    } else {
                      goTo(e, MY_PROFILE);
                    }
                  }}
                  style={{ color: '#835fec' }}
                >
                  Go to personal data deletion
                </a>
              </p>
            </div>
            <div class='term-block'>
              <h3>Article 7: Destruction of Privacy</h3>
              <p>
                (1) The Company shall promptly destroy personal information once the processing
                purpose is achieved. The Company may destroy or separately store personal
                information of users who have not used the service for a year. Provided, the Company
                shall store and use items legally obliged to be stored within the period and for the
                purposes ordered, according to laws and regulations.
              </p>
              <br />
              <p>(2) The detailed procedure and method of destruction are as follows:</p>
              <p>
                1. Procedure of Destruction:
                <br />
                Information entered by members will be transferred to a separate database (in the
                case of paper, to another document) after its purpose is achieved and stored for a
                certain period or destroyed immediately according to internal policy and other
                related laws and regulations. Personal information transferred to a database shall
                not be used for any other purpose unless allowed by law.
              </p>
              <p>
                2. Method of Destruction:
                <br />
                Personal information printed on paper shall be shredded or incinerated, and
                electronic files containing personal information shall be destroyed using technical
                methods that prevent the reproduction of records.
              </p>
            </div>
            <div class='term-block'>
              <h3>
                Article 8: Installation and Operation of Automatic Personal Information Collectors
                and Refusal Thereof
              </h3>
              <p>
                (1) The Company uses 'cookies' to provide members with customized services,
                facilitating easy and convenient use of the service. Cookies are small amounts of
                information sent by the server (HTTP) to the user's computer browser and are stored
                on the user's PC.
              </p>
              <br />
              <p>
                (2) Members have the right to install/operate and refuse cookies. Members can allow
                or refuse cookies by setting options in their web browser. However, if members
                refuse to save cookies, they may experience difficulties or be unable to use
                services that require log-in.
              </p>
            </div>
            <div class='term-block'>
              <h3>Article 9: Measures to Secure the Safety of Privacy</h3>
              <p>
                The Company takes the following technical, managerial, and physical measures to
                ensure security:
              </p>
              <p>
                1. Minimizing and Training of Personal Information Processing Employees:
                <br />
                The Company designates specific employees to process personal information, minimizes
                the number of such employees, and implements management measures.
              </p>
              <p>
                2. Encryption of Personal Information:
                <br />
                Members' ID passwords are stored encrypted and are known only to the members. Only
                the members can identify and modify their passwords.
              </p>
              <p>
                3. Technical Measures Against Hacking:
                <br />
                The Company installs security programs and regularly inspects the system to prevent
                personal information leakage and damage due to hacking or computer viruses.
                Technical and physical surveillance and quarantine are implemented by installing
                systems in access-controlled areas.
              </p>
            </div>
            <div class='term-block'>
              <h3>Article 10: Administrators of Personal Information Protection</h3>
              <p>
                The company has designated a Personal Information Protection Officer as follows to
                protect personal information and address complaints related to personal information:
              </p>
              <p>- Name: Lee Jong-Han</p>
              <p>- Affiliation and Status: Representative Director</p>
              <p>- Telephone: +82 2-579-0365</p>
              <p>
                - Email:{' '}
                <a href='mailto:info@fab365.net' style={{ color: '#835fec' }}>
                  info@fab365.net
                </a>
              </p>
            </div>
            <div class='term-block'>
              <h3>Article 11: Consultations on Infringements of Privacy Rights</h3>
              <p>
                For reports or consultations on infringements of privacy rights, please contact the
                following organizations:
              </p>
              <p>
                - Personal Information Dispute Mediation Committee (
                <a href='https://www.kopico.go.kr/' style={{ color: '#835fec' }}>
                  www.kopico.go.kr
                </a>{' '}
                / +82 2-405-5150)
              </p>
              <p>
                - Privacy Infringement Declaration Center of Korea Internet and Security Agency (
                <a href='https://privacy.kisa.or.kr/' style={{ color: '#835fec' }}>
                  privacy.kisa.or.kr
                </a>{' '}
                / 118 without a telephone exchange number)
              </p>
              <p>
                - National Police Agency Cyber Bureau (
                <a href='https://ecrm.police.go.kr/minwon/main' style={{ color: '#835fec' }}>
                  ecrm.police.go.kr
                </a>{' '}
                / 182 without a telephone exchange number)
              </p>
            </div>
            <div class='term-block'>
              <h3>Article 12: Obligation of Notification upon Modification of Privacy Policy</h3>
              <p>
                The Company will publicly disclose any modifications to this privacy policy,
                including the points of modification, the execution date, and the modified contents.
              </p>
              <p>
                The effective date of this personal information processing policy: December 26,
                2016.
              </p>
            </div>
          </MembersWrapper>
        </div>
      </MembersContainer>
    </>
  );
};

export default PrivacyEn;
