import ManageButton from '../common/ManageButton';

import { getColorByStatus, getByStatus, getByBtnTitle } from '../../../util/managemodel';
import { StatusValue } from '../../designers/Contents/manageModelPage/manageEditStyle';
import styled from 'styled-components';

const ManageButtonAction = ({ status, onClickBtn, onClickStopSelling, editValue }) => {
  return (
    <ManageBtnContainer columns={status !== 'draft' ? '30% 15% 30%' : '20% 15% 15% 30%'}>
      {status === 'public' && (
        <div className='stop-selling'>
          <ManageButton
            text='Stop Selling'
            backgroundColor='#FD5757'
            color='#fff'
            onClick={() => onClickStopSelling()}
          />
        </div>
      )}

      <span className='status'>
        Status:{' '}
        <StatusValue className='status-value' color={status ? getColorByStatus(status) : '#969696'}>
          {status ? getByStatus(status) : 'Draft'}
        </StatusValue>
      </span>

      <ManageButton
        text='Back'
        border='2px solid #000'
        color='#000'
        onClick={() => onClickBtn('back')}
      />
      {status === 'draft' && (
        <ManageButton
          text='Save Draft'
          border='2px solid #4CBE37'
          color='#4CBE37'
          onClick={() => onClickBtn('draft')}
        />
      )}

      <ManageButton
        text={getByBtnTitle(status)}
        backgroundColor={status === 'secret' ? '#FD5757' : '#4CBE37'}
        color='#fff'
        onClick={() =>
          onClickBtn(status === 'secret' ? 'secret' : status === 'public' ? 'update' : 'public')
        }
      />
    </ManageBtnContainer>
  );
};

export default ManageButtonAction;

const ManageBtnContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.columns};
  gap: 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: end;
  justify-items: end;
  > button {
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status {
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
  }

  .stop-selling {
    width: 120px;
    position: absolute;
    left: 0px;
  }
`;
