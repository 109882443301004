import styled from 'styled-components';

export const ImgStyled = styled.img`
  pointer-events: none;
  cursor: pointer;

  .search {
    height: 100%;
  }

  .request {
    aspect-ratio: 1/1;
    object-fit: cover;
  }
`;
