export const formatFileSize = size => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;
  let formattedSize = size;

  while (formattedSize >= 1024 && unitIndex < units.length - 1) {
    formattedSize /= 1024;
    unitIndex++;
  }

  // 1MB 이하일 경우 올림 처리
  if (unitIndex === 0 && formattedSize < 1) {
    formattedSize = Math.ceil(formattedSize);
  }

  // 소수점 두 자리까지 표시 후 올림
  const formattedSizeRounded = Math.ceil(formattedSize * 100) / 100;

  // 1000 단위로 쉼표 추가
  const formattedSizeWithCommas = formattedSizeRounded.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return {
    filesize: formattedSizeWithCommas,
    unit: units[unitIndex],
  };
};

export const formatFileTotal = filesArray => {
  let totalSize = 0;
  const filesData = filesArray.map(file => {
    totalSize += file?.file_size || file?.size;
  });

  return formatFileSize(totalSize);
};
