/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components';
import { PRINT_TIPS_LIST, REQUESTS, REVIEW } from '../../route/constant';

import { ImgStyled } from '../../styles/ImgStyled';

const Community = ({ goTo, communityRef, style }) => {
  return (
    <Container className='fab-header' ref={communityRef} style={style}>
      <div className='fab-header-nav-gnb-submenu fab-header-nav-gnb-submenu--community'>
        <div className='fab-header-container container'>
          <div className='fab-header-community-posts'>
            <a onClick={e => goTo(e, REVIEW)}>
              <div className='desc'>
                <h5>Review</h5>
                <p>
                  See amazing reviews from makers.
                  <br />
                  And tell your story
                </p>
              </div>
              <div className='img'>
                <ImgStyled
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/header/header/community-review.png'
                  alt=''
                />
              </div>
            </a>
            <a onClick={e => goTo(e, REQUESTS)}>
              <div className='desc'>
                <h5>Request</h5>
                <p>
                  Do you have any design you want?
                  <br />
                  Apply and we'll make it
                </p>
              </div>
              <div className='img'>
                <ImgStyled
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/header/header/community-request.png'
                  alt=''
                />
              </div>
            </a>
            <a onClick={e => goTo(e, PRINT_TIPS_LIST)}>
              <div className='desc'>
                <h5>Print Tips</h5>
                <p>Essential Tips for successful 3D printing</p>
              </div>
              <div className='img'>
                <ImgStyled
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/header/header/community-help.png'
                  alt=''
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Community;

const Container = styled.div`
  z-index: 10;

  transition: transform 0.5s ease-out 0s;

  @media screen and (max-width: 700px) {
    .fab-header-community-posts {
      padding-left: 20px;
    }
  }
`;
