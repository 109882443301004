import React, { useEffect, useState } from 'react';
import { getHomeInfoDetail } from '../../action/request';
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { REVIEW } from '../../route/constant';
import { getMainThumb } from '../../util/image';
import { replicateObject } from '../../util/skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { theme } from '../../styles/theme';
import { emptyReviewCard } from './constant';

import { ImgStyled } from '../../styles/ImgStyled';
import ModelCard from '../common/itemCards/ModelCard';

function SectionBReview({ data, isMobile }) {
  const emptyCards = replicateObject(emptyReviewCard, 4);
  const [cards, setCards] = useState(emptyCards);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const navigate = useNavigate();
  const SKELETON_PADDINGTOP = '100%'; /* 이미지의 가로-세로 비율에 따라 조정 (세로 / 가로 * 100) */

  const options = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 25,
    loop: true,

    navigation: {
      prevEl: '.buttonSwiperPrevS6a',
      nextEl: '.buttonSwiperNextS6a',
    },
    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
      1180: {
        slidesPerView: 4,
      },
    },
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSkeletonLoading) return;

    navigate(path);
  };

  const fetchData = async () => {
    try {
      const { data: detailData } = await getHomeInfoDetail(data.location);
      setCards(detailData.data.items);
      // setIsSkeletonLoading(false);
      setTimeout(() => {
        setIsSkeletonLoading(false);
      }, 500);
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  // swiper는 슬라이드 목록이 없을 때 autoplay, navigation 동작을 시작하지 않는 오류가 생기기 때문에 slide 값이 있을 때 동작하게 한다.
  if (!cards.length) return null;

  return (
    <div className='sectionTypeB'>
      <div className='centerWrap'>
        <div className='titleRow'>
          <div className='title'>
            {!isSkeletonLoading ? (
              data.title
            ) : (
              <Skeleton
                width={isMobile ? 200 : 800}
                baseColor={theme.skeleton_dark_gray}
                highlightColor={theme.sleleton_dark_gray_highlight}
              />
            )}
          </div>
        </div>
        <div className='secondRow' style={{ height: isMobile && isSkeletonLoading && '0' }}>
          {!isSkeletonLoading ? (
            <Link to={data.viewAll} className='viewAll' onClick={e => goTo(e, data.viewAll)}>
              <span>VIEW ALL</span>
            </Link>
          ) : (
            <Skeleton
              className='skeleton--viewAll'
              width={94}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
          )}
        </div>
        <div className='swiperBigWrap'>
          <Swiper {...options} modules={[Navigation]} className='sectionASwiper sectionASwiper006a'>
            <div className='swiper-wrapper'>
              {cards.map((card, idx) => {
                const url = REVIEW + '/' + card.review_oid;
                return (
                  <SwiperSlide key={idx}>
                    <div className='swiper-slide'>
                      <ModelCard value='review' data={card} />
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>

          <button
            tabIndex='0'
            type='button'
            className='buttonSwiperPrev buttonSwiperPrevS6a'
            title='prev'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
          <button
            tabIndex='0'
            type='button'
            className='buttonSwiperNext buttonSwiperNextS6a'
            title='next'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
        </div>
      </div>
    </div>
  );
}

export default SectionBReview;
