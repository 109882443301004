import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { theme } from '../../styles/theme';
import { ImgStyled } from '../../styles/ImgStyled';
import useImgLoadCheck from './useImgLoadCheck';

function AdTypeA({ data, isMobile, isSkeletonLoading }) {
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { isImageLoaded, handleImageLoad } = useImgLoadCheck();

  const SKELETON_PADDINGTOP_PC =
    '8.805%'; /* 이미지의 가로-세로 비율에 따라 조정 (세로 / 가로 * 100) */
  const SKELETON_PADDINGTOP_MO = '25%';

  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSkeletonLoading) return;

    if (!path?.includes('http')) {
      if (path.includes('my')) {
        if (!userState.isLogin) {
          // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
          modalAction.setIsLogin(true);
        } else {
          navigate(path);
        }
      } else {
        // 리액트 내부 링크일 때 리액트에서 이동
        navigate(path);
      }
    } else {
      // 리액트 외부 링크일 때 새로운 창 열기
      window.open(path, '_blank');
    }
  };

  return (
    <div
      className='adTypeA'
      style={{
        width: '100%',
        aspectRatio: isImageLoaded ? 'auto' : '8/1',
      }}
    >
      <div className='centerWrap'>
        <Link
          to={data?.banner.url}
          className={!isMobile ? 'adImgPc' : 'adImgMo'}
          onClick={e => goTo(e, data?.banner.url)}
        >
          <ImgStyled
            src={!isMobile ? data?.banner.imgDesktop : data?.banner.imgMobile}
            alt={data?.banner.title}
            onLoad={handleImageLoad}
          />
        </Link>
      </div>
    </div>
  );
}

export default AdTypeA;
