import { sanitize } from 'dompurify';
import { useState } from 'react';
import { useEffect } from 'react';
import { searchItems } from '../../action/request';
import { useNavigate, useParams } from 'react-router-dom';
import { priceStr } from '../Price/Price';
import { ImgStyled } from '../../styles/ImgStyled';

const ItemRecommand = ({ itemId }) => {
  const [header, setHeader] = useState('');
  const [items, setItems] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let header, idItems;
    switch (id) {
      case 210:
        header = 'Related items';
        idItems = [174, 167, 156, 199];
        break;
      case 206:
        header = 'Other items purchased by users <strong>who purchased the T-4a shuttle</strong>';
        idItems = [203, 122, 167];
        break;
      case 199:
        header = 'Related items';
        idItems = [216, 174, 167, 156];
        break;
      case 174:
        header = 'Related items';
        idItems = [199, 167, 156];
        break;
      case 156:
        header = 'Related items';
        idItems = [199, 167, 174];
        break;
      case 167:
        header = 'Related items';
        idItems = [180, 179, 170, 191];
        break;
      case 191:
        header = 'Related items';
        idItems = [180, 179, 170];
        break;
      case 181:
        header = 'Related items';
        idItems = [203, 122, 167];
        break;
      case 180:
        header = 'Related items';
        idItems = [191, 179, 170];
        break;
      case 179:
        header = 'Related items';
        idItems = [180, 191, 170];
        break;
      case 170:
        header = 'Related items';
        idItems = [180, 179, 191];
        break;

      case 213:
        header = 'Related items';
        idItems = [189, 204, 200, 199];
        break;
      case 216:
        header = 'Related items';
        idItems = [167, 199, 203];
        break;

      //spacex
      case 197:
        header = 'Related items';
        idItems = [228, 192, 203, 212];
        break;
      case 203:
        header = 'Related items';
        idItems = [228, 192, 197, 212];
        break;
      case 228:
        header = 'Related items';
        idItems = [203, 192, 197, 212];
        break;

      case 119:
        header = 'Related items';
        idItems = [131, 110, 112];
        break;

      //sales
      case 112:
        header = 'Related items';
        idItems = [122, 174, 167];
        break;
      case 122:
        header = 'Related items';
        idItems = [112, 174, 167];
        break;
      case 142:
        header = 'Related items';
        idItems = [122, 174, 167];
        break;
      default:
        header = '<strong>Hot</strong> This Week';
        idItems = [230, 235, 227, 203];
    }
    setHeader(header);
    fetchData(idItems);
  }, [id, itemId]);

  const fetchData = async ids => {
    if (ids) {
      try {
        const { data: resItems } = await searchItems({ ids });
        setItems(resItems.items);
      } catch (e) {}
    }
  };

  const goTo = (e, path) => {
    // e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='container'>
        <div className='content-section'>
          <div className='content-section-header'>
            <h2 dangerouslySetInnerHTML={{ __html: sanitize(header) }}></h2>
          </div>
          <div className='post-list post-list-4' id='item-recommend'>
            <div className='list clearfix'>
              {items &&
                items.map(item => {
                  return (
                    <div className='list-item' key={item.id}>
                      <div className='post-list-item post-list-item-square'>
                        <a href='' onClick={e => goTo(e, '/items/' + item.id)}>
                          <div
                            className='tb'
                            onContextMenu={e => {
                              e.preventDefault();
                            }}
                          >
                            <ImgStyled src={item.listImg} alt='' />
                          </div>
                          <div className='post-desc'>
                            <h3 className='post-title'>{item.name}</h3>
                            <div className='post-meta'>
                              <span className='author'>{priceStr(item)}</span>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemRecommand;
