import ItemRecommand from '../../component/item/ItemRecommand';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import TipSummary from './component/TipSummary';
import { TIPS } from './constant';
const PrintTip = ({ title, tab }) => {
  return (
    <Layout>
      <div id='main'>
        <Menu title={title} tab={tab} />
        <section className='story-section'>
          <div className='container'>
            <div className='featured-story-list'>
              <ul>
                {TIPS.map((tip, idx) => {
                  return <TipSummary tip={tip} key={idx} />;
                })}
              </ul>
            </div>
          </div>
        </section>
        <section>
          <ItemRecommand itemId={null} />
        </section>
      </div>
    </Layout>
  );
};

export default PrintTip;
