import {
  STATUS_PRODUCTION_ENUM,
  STATUS_REJECT_ENUM,
  STATUS_RELEASED_ENUM,
  STATUS_REVIEW_ENUM,
  STATUS_VOTING_ENUM,
} from "./constant";

const RequestStatus = ({ status }) => {
  switch (status) {
    case STATUS_VOTING_ENUM:
      return <div className="status">Voting</div>;

    case STATUS_REVIEW_ENUM:
      return <div className="status under-review">Under review</div>;

    case STATUS_PRODUCTION_ENUM:
      return <div className="status in-production">In Production</div>;

    case STATUS_REJECT_ENUM:
      return <div className="status rejected">Rejected</div>;

    case STATUS_RELEASED_ENUM:
      return (
        <div className="status" style={{ color: "#004da7" }}>
          Released
        </div>
      );
    default:
      <></>;
  }
};

export default RequestStatus;
