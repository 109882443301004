import styled from 'styled-components';

export const CategoryStyle = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 13px;
  font-weight: 400;
  align-items: center;

  img {
    width: 4.5px;
    height: 7.5px;
  }

  @media (max-width: 1000px) {
    a {
      border: 1px solid #dbdbdb;
      height: 13px;
      line-height: 13px;
      padding: 5px;
      border-radius: 28px;
    }

    .detail-category-name {
      display: ${props => (props.categoryName ? 'block' : 'none')};
    }
  }

  @media (max-width: 640px) {
    border-bottom: none;
  }
`;

export const DesignerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding: 32px 0;

  img {
    width: 24px;
    height: 24px;
  }

  .designer-info {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .designer-info:after {
    content: '';
    display: block;
    background-image: url('/assets/img/itemDetail/chevron_forward.png');
    width: 6px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; /* 또는 cover */
    margin-left: 5px;
  }

  @media (max-width: 640px) {
    padding: 20px 0 10px 0;
  }
`;

export const ItemName = styled.h2`
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 26px !important;
  font-weight: 200;

  @media (max-width: 640px) {
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 5px;
  gap: 10px;
`;

export const LikeBtn = styled.button`
  background-color: ${props => (props.secret ? '#929599' : '#fff')};
  border: 1px solid #a2a2a2;
  border-radius: 5px;
  height: 60px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  width: ${props => (props.secret || props.download ? '100%' : '60px')};
  flex: ${props => props.secret && '1'};

  img {
    width: 20px;
    height: 20px;
  }
`;
export const CartBtn = styled.button`
  background-color: ${props => (props.secret ? '#929599' : '#fff')};
  padding: 10px;
  border-radius: 4px;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.8;
  border: 1px solid #a2a2a2;
  flex: 1;
  color: ${props => (props.secret ? '#fff' : '#000')};

  img {
    width: 20px;
    position: relative;
    right: 10px;
    top: 5px;
  }
`;

export const ProductInfo = styled.div`
  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;
