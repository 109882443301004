/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import uuid from 'react-uuid';
import { allowScroll } from '../../util/commentImageViewer';

const CommentImageViewer = ({ imgData, setShowImgViewer }) => {
  const [index, setIndex] = useState(imgData.index);
  const [dataArr, setDataArr] = useState([]);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    // 데이터 없을 경우 닫기 처리
    if (imgData.data.lenght === 0) {
      setShowImgViewer(false);
      return;
    }
    setIndex(imgData.index);
    setDataArr(imgData.data);
    // console.log('dataArr', dataArr);
    // console.log('imgData.index', imgData.index);
  }, []);

  const settingsLarge = {
    arrows: true,
    dots: false,
    infinite: false,
    fade: true,
    draggable: false,
    beforeChange: (current, next) => {
      setIndex(next);
    },
    responsive: [
      {
        breakpoint: 640,
        settings: {
          draggable: false,
          fade: true,
        },
      },
    ],
  };

  const settingsTBS = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 12,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 12,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  useEffect(() => {
    setPanPosition({ x: 0, y: 0 });
    setZoomLevel(1);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  }, [index]);

  const clickTbs = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    setIndex(idx);
  };

  const handleMouseDown = e => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseMove = e => {
    e.preventDefault();
    if (isDragging) {
      setPanPosition(prevPosition => ({
        x: prevPosition.x + e.movementX / zoomLevel,
        y: prevPosition.y + e.movementY / zoomLevel,
      }));
    }
  };

  const handleMouseUp = e => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleWheel = e => {
    // e.preventDefault();
    const newZoomLevel = zoomLevel - e.deltaY * 0.01;
    const minZoomLevel = 0.5;
    const maxZoomLevel = 3;
    if (newZoomLevel >= minZoomLevel && newZoomLevel <= maxZoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  };

  const onClickClose = e => {
    e.preventDefault();
    setShowImgViewer(false);
    allowScroll();
  };

  const handleTouchStart = e => {
    // e.preventDefault();
    setIsTouch(true);
  };

  const handleTouchMove = e => {
    // e.preventDefault();
    setIsTouch(true);
  };

  const handleTouchEnd = e => {
    // e.preventDefault();
    setTimeout(() => {
      setIsTouch(false);
    }, 500);
  };

  return (
    <div className='image-viewer'>
      <a onClick={onClickClose} href='#' className='btn-close'>
        &times;
      </a>
      <div className='large-img'>
        <div className='slider'>
          <Slider {...settingsLarge} ref={sliderRef}>
            {dataArr.map((img, idx) => {
              return (
                <div className='img' key={uuid()}>
                  <div
                    className='image-zoom'
                    ref={containerRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onWheel={handleWheel}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      overflow: isTouch ? 'auto' : 'hidden',
                      cursor: isDragging ? 'grabbing' : 'grab',
                      WebkitOverflowScrolling: 'touch',
                    }}
                  >
                    <div
                      data-large-img={img?.url}
                      data-index={idx}
                      className='image-container'
                      data-type={img?.type}
                      key={uuid()}
                      onClick={e => clickTbs(e, idx)}
                      style={{
                        position: 'absolute',
                        transform: `translate(${panPosition.x}px, ${panPosition.y}px) scale(${zoomLevel})`,
                        transformOrigin: '50% 50%',
                      }}
                    >
                      <img src={dataArr[index]?.url} alt='' />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className='image-viewer-footer'>
        <div className='tbs'>
          <div className='slider'>
            <div className='slides slick-initialized slick-slider'>
              <Slider {...settingsTBS}>
                {dataArr.map((img, idx) => {
                  return (
                    <a
                      data-large-img={img?.url}
                      data-index={idx}
                      className={'slide' + (idx === index ? ' active' : '')}
                      data-type={img?.type}
                      key={uuid()}
                      onClick={e => clickTbs(e, idx)}
                    >
                      <span className='tb'>
                        <img
                          src={img?.url}
                          alt=''
                          onContextMenu={e => {
                            e.preventDefault();
                          }}
                        />
                      </span>
                    </a>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentImageViewer;
