import React from 'react';
import Layout from '../../component/layout/Layout';
import styled from 'styled-components';

const ApplyDesignerForm = () => {
  return (
    <Layout>
      <div className='container'>
        <ApplyForm>
          <div className='top-contant'>
            <span className='title'>Join Fab365 team!</span>
            <p className='content'>
              Fab365 team invites you to be among our special designers. If you would like to
              feature your models on our platform, <br />
              please fill out the form. Our support team will contact you back soon to provide more
              details.
            </p>
          </div>
          <div className='apply-form'>
            <div className='form'>
              <span>
                Designer Name (nickname which will be seen by users) <span className='red'> *</span>{' '}
              </span>
              <input placeholder='Your answer' />
            </div>
            <div className='form'>
              <span>
                Fab365.net account email
                <br />
                (please register and type here the email you used to sign up, as we will turn your
                account into designer's studio to track all your sales and other statistics)
                <span className='red'> *</span>
              </span>
              <input placeholder='Your answer' />
            </div>
            <div className='form'>
              <span>Link to your Cults3D account</span>
              <input placeholder='Your answer' />
            </div>
            <div className='form'>
              <span>
                Link to the models you want to feature on our platform (We will let you know if we
                would like to add others besides those you put here. Please note that we currently
                accept only models costs 3 USD and more)
              </span>
              <input placeholder='Your answer' />
            </div>
            <div className='form'>
              <span>Questions or requests</span>
              <input placeholder='Your answer' />
            </div>
          </div>
          <Box>
            <button>Submit</button>
          </Box>
        </ApplyForm>
      </div>
    </Layout>
  );
};

export default ApplyDesignerForm;

const ApplyForm = styled.div`
  min-height: 500px;
  margin: 100px auto;
  max-width: 640px;

  .title {
    font-size: 30px;
    font-weight: 500;
  }

  .content {
    margin-top: 10px;
  }
  .apply-form {
    margin-top: 50px;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    span {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 20px;

      .red {
        color: red;
      }
    }

    input {
      width: 50%;
      padding: 10px;
      border: none;
      border-bottom: 1px solid #000;
    }
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  button {
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
  }
`;
