import { useContext, useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import Categoryist from './component/CategoryList';
import './Category.css';
import Pagination from './component/Pagination';
import CollectionList from './component/CollectionList';
import { getCategories, getCollectionList, getFreeLimit } from '../../action/request';
import UserContext from '../../context/user/user';
import { ls } from '../../constant';
const Collection = ({}) => {
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(Number(localStorage.getItem(ls.collectionPage)) || 1);
  const [limit, setLimit] = useState({});
  const [collections, setCollections] = useState({ count: 0 });
  const { state } = useContext(UserContext);

  useEffect(() => {
    fetchData();

    // 콜렉션 아이템 리스트 페이지네이션 초기화
    localStorage.removeItem(ls.collectionListPage);
  }, []);

  // 로그인 상태 변경때마다 fetchData() 호출
  useEffect(() => {
    fetchData();
  }, [state.isLogin]);

  const fetchData = async () => {
    try {
      const { data: categories } = await getCategories();
      setCategories(categories.data);
      updateListPage(1);

      const { data: resLimit } = await getFreeLimit();
      setLimit(resLimit.data);

      updateListPage(page);
    } catch (error) {}
  };

  const pageChange = async value => {
    updateListPage(value);
  };

  const pageConfirm = value => {
    updateListPage(value);
  };

  const updateListPage = async value => {
    setPage(value);
    localStorage.setItem(ls.collectionPage, value);

    const { data: collectionList } = await getCollectionList({ page: value });
    setCollections(collectionList.data);
    // console.log(value, collectionList.data);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top page-top-with-breadcrumb'>
            <div className='container container-lg'>
              <h1 className='page-title'>Collection</h1>
            </div>
          </div>
          <div className='main-content-with-aside'>
            <Categoryist current={'Collections'} data={categories.items} />
            <div className='main-content'>
              <section className='store-collection'>
                <div className='container'>
                  <div
                    className='content-section content-section-has-top-nav'
                    style={{ minHeight: '900px' }}
                  >
                    <div className='featured-collections'>
                      <CollectionList data={collections.collections} />
                    </div>
                    <Pagination
                      page={page}
                      total={Math.ceil(collections.count / 5)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Collection;
