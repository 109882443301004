import React, { useRef, useState } from 'react';
// import './App.css'; // CSS 파일을 가져옵니다.
import styled from 'styled-components';

const FileName = ({ content }) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = e => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // 터치 이벤트 핸들러
  const handleTouchStart = e => {
    setIsDragging(true);
    const touch = e.touches[0];
    setStartX(touch.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchMove = e => {
    if (!isDragging) return;
    const touch = e.touches[0];
    const x = touch.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <FileNameStyle
      ref={containerRef}
      className='scrollable-container'
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // 마우스가 컨테이너를 벗어났을 때도 드래그 종료
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div ref={containerRef} className='scrollable-content'>
        {content}
      </div>
    </FileNameStyle>
  );
};

const FileNameStyle = styled.div`
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
  padding: 8px;
  user-select: none;
  display: flex;
  align-items: center;
  direction: rtl;
  border-radius: 10px 0 0 10px;

  .scrollable-content {
    white-space: nowrap;
    position: absolute;
    user-select: none;

    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    padding-left: 10px;
  }
`;
export default FileName;
