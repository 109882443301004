import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  createCartAdd,
  getHomeInfoDetail,
  postHomeInfoDetailSub,
  toggleItemLike,
} from '../../action/request';
import { fillArray } from './util';

import { gtagAddToCart } from '../../action/gTag';
import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import { ITEMS } from '../../route/constant';
import CartContext from '../../context/cart.js/carts';
import { ls } from '../../constant';
import { replicateObject } from '../../util/skeleton';
import { emptyCard, emptyMenu } from './constant';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { theme } from '../../styles/theme';

import styled from 'styled-components';
import { ImgStyled } from '../../styles/ImgStyled';
import { DESIGNERS } from '../../route/constant';
import { updateLikeState } from '../../util/updateLikeState';
import { likeAtom } from '../../atom/atom';
import { useRecoilState } from 'recoil';
import ModelCard from '../common/itemCards/ModelCard';
import useClickHandler from '../common/itemCards/useCardClickHandeler';
import useDownload from '../../hook/useDownload';
import Selectefile from '../selectefile/Selectefile';

function SectionD({ data, isMobile }) {
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const emptyMenuss = replicateObject(emptyMenu, 7);
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const emptyCards = replicateObject(emptyCard, 8).map(card => {
    return { ...card, product_id: 'null' };
  });
  const SKELETON_PADDINGTOP = '100%'; /* 이미지의 가로-세로 비율에 따라 조정 (세로 / 가로 * 100) */
  const [menus, setMenus] = useState(emptyMenuss);
  const [selectedMenu, setSelectedMenu] = useState({ name: '', url: '', id_category: '' });
  const [cards, setCards] = useState(emptyCards);
  const [isFetching, setIsFetching] = useState(true);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [likeState, setLikeSatate] = useRecoilState(likeAtom);
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);

  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const designerName = selectedMenu?.url;

  const navigate = useNavigate();

  const refreshPage =
    localStorage.getItem('refreshPage') === null ? 'refresh' : localStorage.getItem('refreshPage');

  const SECTIONC_LENGTH = 8;

  const menuOptions = {
    slidesPerView: 4.3,
    centeredSlides: false,
    spaceBetween: 16,
    loop: true,

    navigation: {
      prevEl: `.buttonSwiperPrevSM${data.location}`,
      nextEl: `.buttonSwiperNextSM${data.location}`,
    },
    breakpoints: {
      450: {
        slidesPerView: 4.3,
        spaceBetween: 16,
      },
      600: {
        slidesPerView: 5.0,
        spaceBetween: 16,
      },
      700: {
        slidesPerView: 5.5,
        spaceBetween: 20,
      },
      800: {
        slidesPerView: 6.0,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 20,
      },
    },
  };

  const cardOptions = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 25,
    loop: true,

    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2,
      },
      1180: {
        slidesPerView: 3,
      },
    },
  };

  const goTo = (e, path, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSkeletonLoading) return;
    if (data.location === 'designer') {
      navigate(path, { state: { id: id } });
    } else {
      navigate(path);
    }
  };

  const onChangeMenu = async (location, name, idCategory, url) => {
    setIsFetching(true);
    try {
      // 선택된 menu 값으로 새로운 cards 값을 받아온다.
      const getItem = localStorage.getItem(`${location}`);
      const sort = JSON.parse(getItem);

      const { data: detailSubData } = await postHomeInfoDetailSub(
        data.location,
        idCategory,
        userState.isLogin,
        sort.sort
      );

      localStorage.setItem(
        `${location}`,
        JSON.stringify({
          sort: sort.sort,
          name: name,
          id: idCategory,
          url: url,
        })
      );
      if (detailSubData?.data?.length > SECTIONC_LENGTH) {
        setCards(detailSubData?.data?.slice(0, SECTIONC_LENGTH));
        setIsFetching(false);
      } else {
        setCards(detailSubData?.data);
        setIsFetching(false);
      }
      setIsSkeletonLoading(false);
      setSelectedMenu({ name, url: url || name, id_studio: detailSubData.data[0].id_studio });
    } catch (e) {}
  };

  const fetchData = async () => {
    try {
      const { data: detailData } = await getHomeInfoDetail(data.location, userState.isLogin);
      const getItem = localStorage.getItem(`${data.location}`);
      const sort = JSON.parse(getItem);

      if (refreshPage === 'refresh') {
        let url = detailData.data.subTypes.filter(d => d.name === detailData.data.subTitle)[0].url;
        if (!url)
          url = detailData.data.subTypes.filter(d => d.name === detailData.data.subTitle)[0].name;

        setSelectedMenu({
          name: detailData.data.subTitle,
          url,
          id_studio: detailData.data.subTypes[0].id_studio,
        });
        setMenus(detailData.data.subTypes);

        localStorage.setItem(
          `${data.location}`,
          JSON.stringify({
            sort: detailData.data.sortInfo,
            name: detailData.data.subTitle,
            id: detailData.data.subTypes[0].id_category,
            url: url,
          })
        );

        if (detailData.data.items.length > SECTIONC_LENGTH) {
          setCards(detailData.data.items.slice(0, SECTIONC_LENGTH));
          setIsFetching(false);
        } else {
          setCards(detailData.data.items);
          setIsFetching(false);
        }

        setTimeout(() => {
          setIsSkeletonLoading(false);
        }, 500);

        localStorage.setItem('refreshPage', false);
      } else {
        let subTypes = detailData?.data?.subTypes;
        const newArr = subTypes?.filter(e => e.id_category !== sort.id);
        const changelist = subTypes?.filter(e => e.id_category === sort.id);
        onChangeMenu(data.location, sort.name, sort.id, sort.url);
        setMenus([...changelist, ...newArr]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, [userState.isLogin]);

  useEffect(() => {
    if (likeState) {
      const newCards = cards.map(card =>
        card.product_id === likeState.id
          ? {
              ...card,
              product_like_count: `${Number(likeState.count)}`,
              my_like: likeState.value,
            }
          : card
      );

      setCards(newCards);
    }
  }, [likeState]);

  const clickLike = async (e, id, count) => {
    e.stopPropagation();
    if (!userState.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeSatate,
          cards: cards,
          count: count,
          data: data,
        });
        setCards(updatedCards);
      }
    }
  };

  const clickCart = async (e, price, id, inCart) => {
    e.stopPropagation();
    // 이미 카트에 담겨있을 경우
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = cards.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setCards(newCards);

          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  const isMobileBorder = isMobile ? 2 : 3;

  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imgMinWidth = isMobile && width > 380 ? 75 : 60;

  const onClickDesignerPage = (e, studioName, productId, studioId) => {
    if (studioName === 'FAB365') {
      goTo(e, ITEMS + '/' + productId);
    } else {
      moveToDesigner(studioName, studioId);
    }
  };

  const moveTopage =
    data.location === 'designer'
      ? `${DESIGNERS}/${designerName}`
      : data.viewAll.trim() + '/' + selectedMenu.url;

  function moveToDesigner(studioName, id) {
    if (data.location === 'designer') {
      navigate(`${DESIGNERS}/${designerName}`);
    } else {
      navigate(`${DESIGNERS}/${studioName}`, { state: { id: id } });
    }
  }

  // swiper는 슬라이드 목록이 없을 때 autoplay, navigation 동작을 시작하지 않는 오류가 생기기 때문에 slide 값이 있을 때 동작하게 한다.
  if (!menus.length) return null;

  return (
    <div className='sectionTypeD'>
      <div className='centerWrap'>
        <div className='titleRow'>
          <div className='title'>
            {!isSkeletonLoading ? (
              data?.title
            ) : (
              <Skeleton
                width={isMobile ? 200 : 500}
                baseColor={theme.skeleton_dark_gray}
                highlightColor={theme.sleleton_dark_gray_highlight}
              />
            )}
          </div>

          {isMobile ? (
            !isSkeletonLoading ? (
              <Link
                to={data.viewAll.trim() + '/' + selectedMenu.url}
                className='viewAll viewAllMo'
                onClick={e =>
                  goTo(
                    e,
                    data?.location === 'designer'
                      ? `${DESIGNERS}/${selectedMenu.url}`
                      : data.viewAll.trim() + '/' + selectedMenu.url
                  )
                }
              >
                <span>VIEW ALL</span>
              </Link>
            ) : (
              <Skeleton
                className='skeleton--viewAll'
                width={94}
                baseColor={theme.skeleton_dark_gray}
                highlightColor={theme.sleleton_dark_gray_highlight}
                style={{ position: 'absolute', right: '0', top: '8px' }}
              />
            )
          ) : null}
        </div>

        {/* 배너 */}
        {data?.banner?.imgDesktop && (
          <div className='bannersWrap' onClick={e => goTo(e, data.viewAll)}>
            <div className='bannerImg'>
              <ImgStyled
                src={!isMobile ? data?.banner?.imgDesktop : data?.banner?.imgMobile}
                alt=''
              />
            </div>
          </div>
        )}

        <div className='swiperMenu'>
          <Swiper
            {...menuOptions}
            className='swiperMenuBody swiperMenuBody4'
            modules={[Navigation]}
          >
            <div className='swiper-wrapper'>
              {menus?.map((menu, idx) => (
                <SwiperSlide key={idx}>
                  <div
                    className='swiper-slide'
                    onClick={() =>
                      onChangeMenu(data.location, menu.name, menu.id_category, menu.url)
                    }
                  >
                    <div
                      className={`swiperMenuOne ${
                        !isSkeletonLoading && menu.name === selectedMenu.name && 'active'
                      }`}
                      style={{ opacity: isSkeletonLoading && '1' }}
                    >
                      {!isSkeletonLoading ? (
                        <div
                          className='menuImgWrap'
                          // 디자이너일때만 아이콘에 padding 값 들어 감
                        >
                          <Border
                            border={menu.name === selectedMenu.name ? isMobileBorder : 0}
                            size={isMobile ? imgMinWidth : 110}
                          />
                          <Img src={menu?.thumb2} size={isMobile ? imgMinWidth : 110} />
                        </div>
                      ) : (
                        <Skeleton
                          baseColor={theme.skeleton_gray}
                          highlightColor={theme.skeleton_light_gray}
                          style={{
                            paddingTop: SKELETON_PADDINGTOP,
                            height: 0,
                            borderRadius: '999px',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperPrev buttonSwiperPrevSM${data.location}`}
            title='prev'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperNext buttonSwiperNextSM${data.location}`}
            title='next'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
        </div>

        <div className='secondRow'>
          <div className='midTitle'>
            {!isSkeletonLoading && !isFetching ? (
              selectedMenu.name
            ) : (
              <Skeleton
                width={isMobile ? 200 : 500}
                baseColor={theme.skeleton_dark_gray}
                highlightColor={theme.sleleton_dark_gray_highlight}
              />
            )}
          </div>
          {!isSkeletonLoading && !isFetching ? (
            <Link to={moveTopage} className='viewAll' onClick={e => goTo(e, moveTopage)}>
              <span>VIEW ALL</span>
            </Link>
          ) : (
            <Skeleton
              className='skeleton--viewAll'
              width={94}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
          )}
        </div>

        {/* Desktop */}
        <div className='col4List'>
          {fillArray([...cards], { product_id: false }, SECTIONC_LENGTH).map((card, idx) => {
            return card?.product_id ? (
              <ModelCard
                key={idx}
                handleClick={() => handleClick(idx)}
                twoClick={twoClick === idx}
                oneClick={oneClick === idx}
                data={card}
                clickCart={clickCart}
                clickLike={clickLike}
                onClickDownload={onClickDownload}
                buyStatus={card.buy_status}
                downloadStatus={card.download_status}
                isDesigner={card.isDesigner && userState.isLogin}
                isOwn={card?.isOwn && userState.isLogin}
              />
            ) : (
              <div className={`shopItemCard ${!isSkeletonLoading && 'comingSoon'}`} key={idx}>
                {!isSkeletonLoading && !isFetching ? (
                  <div className='imgWrap'>
                    <ImgStyled
                      src='/assets/img/2.0WebImg/content/coming_soon.jpg'
                      className='itemImg1 comming_soon'
                      alt='FAB365 COMING SOON'
                    />
                  </div>
                ) : (
                  <Skeleton
                    style={{
                      paddingTop: SKELETON_PADDINGTOP,
                      height: 0,
                      borderRadius: '8px',
                    }}
                    baseColor={theme.skeleton_gray}
                    highlightColor={theme.skeleton_light_gray}
                  />
                )}
                {/* commin soon 높이를 다른 카드와 맞추기 위한 의미없는 태그 */}
                <Link to='#' className='itemLinkPart2' title='item detail'>
                  {!isSkeletonLoading && !isFetching ? (
                    <div className='title'>&nbsp;</div>
                  ) : (
                    <Skeleton
                      className='skeleton--title'
                      width={160}
                      baseColor={theme.skeleton_dark_gray}
                      highlightColor={theme.sleleton_dark_gray_highlight}
                    />
                  )}
                  {!isSkeletonLoading && !isFetching ? (
                    <div className='subtitle'>&nbsp;</div>
                  ) : (
                    <Skeleton
                      className='skeleton--subtitle'
                      width={200}
                      baseColor={theme.skeleton_dark_gray}
                      highlightColor={theme.sleleton_dark_gray_highlight}
                    />
                  )}

                  <div className='priceRow'>
                    {!isSkeletonLoading && !isFetching ? (
                      <div className='price'>&nbsp;</div>
                    ) : (
                      <Skeleton
                        className='price'
                        width={120}
                        baseColor={theme.skeleton_dark_gray}
                        highlightColor={theme.sleleton_dark_gray_highlight}
                      />
                    )}
                  </div>

                  {!isSkeletonLoading && !isFetching ? (
                    <div className='etc'>
                      <>
                        <button
                          tabIndex='0'
                          type='button'
                          className='favorites'
                          style={{ opacity: 0 }}
                        >
                          &nbsp;
                        </button>
                        <button
                          tabIndex='0'
                          type='button'
                          className='comments'
                          style={{ opacity: 0 }}
                        >
                          &nbsp;
                        </button>
                        <button
                          tabIndex='0'
                          type='button'
                          className='pictures'
                          style={{ opacity: 0 }}
                        >
                          &nbsp;
                        </button>
                      </>
                    </div>
                  ) : (
                    <Skeleton
                      className='skeleton--etc'
                      width={200}
                      height={11}
                      baseColor={theme.skeleton_dark_gray}
                      highlightColor={theme.sleleton_dark_gray_highlight}
                    />
                  )}
                </Link>
              </div>
            );
          })}
        </div>

        {/* Mobile */}
        <Swiper {...cardOptions} className='sectionASwiper sectionDSwiper sectionASwiperC002'>
          <div className='swiper-wrapper'>
            {fillArray([...cards], { product_id: false }, 1).map((card, idx) => {
              return card.product_id ? (
                <SwiperSlide key={idx}>
                  <div className='swiper-slide'>
                    <ModelCard
                      key={idx}
                      handleClick={() => handleClick(idx)}
                      twoClick={twoClick === idx}
                      oneClick={oneClick === idx}
                      data={card}
                      clickCart={clickCart}
                      clickLike={clickLike}
                      onClickDownload={onClickDownload}
                      buyStatus={card.buy_status}
                      downloadStatus={card.download_status}
                      isDesigner={card.isDesigner && userState.isLogin}
                      isOwn={card?.isOwn && userState.isLogin}
                    />
                  </div>
                </SwiperSlide>
              ) : (
                <SwiperSlide key={idx}>
                  <div className='swiper-slide'>
                    <div className={`shopItemCard ${!isSkeletonLoading && 'comingSoon'}`}>
                      {!isSkeletonLoading && !isFetching ? (
                        <div className='imgWrap'>
                          <ImgStyled
                            src='/assets/img/2.0WebImg/content/coming_soon.jpg'
                            className='itemImg1 comming_soon'
                            alt='FAB365 COMING SOON'
                          />
                        </div>
                      ) : (
                        <Skeleton
                          style={{
                            paddingTop: SKELETON_PADDINGTOP,
                            height: 0,
                            borderRadius: '8px',
                          }}
                          baseColor={theme.skeleton_gray}
                          highlightColor={theme.skeleton_light_gray}
                        />
                      )}
                      {/* commin soon 높이를 다른 카드와 맞추기 위한 의미없는 태그 */}
                      <Link href='#' className='itemLinkPart2' title='item detail'>
                        {!isSkeletonLoading && !isFetching ? (
                          <div className='title'>&nbsp;</div>
                        ) : (
                          <Skeleton
                            className='skeleton--title'
                            width={160}
                            baseColor={theme.skeleton_dark_gray}
                            highlightColor={theme.sleleton_dark_gray_highlight}
                          />
                        )}
                        {!isSkeletonLoading && !isFetching ? (
                          <div className='subtitle'>&nbsp;</div>
                        ) : (
                          <Skeleton
                            className='skeleton--subtitle'
                            width={200}
                            baseColor={theme.skeleton_dark_gray}
                            highlightColor={theme.sleleton_dark_gray_highlight}
                          />
                        )}

                        <div className='priceRow'>
                          {!isSkeletonLoading && !isFetching ? (
                            <div className='price'>&nbsp;</div>
                          ) : (
                            <Skeleton
                              className='price'
                              width={120}
                              baseColor={theme.skeleton_dark_gray}
                              highlightColor={theme.sleleton_dark_gray_highlight}
                            />
                          )}
                        </div>

                        {!isSkeletonLoading && !isFetching ? (
                          <div className='etc'>
                            <>
                              <button
                                tabIndex='0'
                                type='button'
                                className='favorites'
                                style={{ opacity: 0 }}
                              >
                                &nbsp;
                              </button>
                              <button
                                tabIndex='0'
                                type='button'
                                className='comments'
                                style={{ opacity: 0 }}
                              >
                                &nbsp;
                              </button>
                              <button
                                tabIndex='0'
                                type='button'
                                className='pictures'
                                style={{ opacity: 0 }}
                              >
                                &nbsp;
                              </button>
                            </>
                          </div>
                        ) : (
                          <Skeleton
                            className='skeleton--etc'
                            width={200}
                            height={11}
                            baseColor={theme.skeleton_dark_gray}
                            highlightColor={theme.sleleton_dark_gray_highlight}
                          />
                        )}
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </div>
      {selectedId && selectedModal && (
        <Selectefile
          setSelectedModal={setSelectedModal}
          id={selectedId}
          setSelectedId={setSelectedId}
          selectedName={selectedName}
          status={true}
        />
      )}
    </div>
  );
}

export default SectionD;

const Border = styled.div`
  border: ${props => props.border}px solid #000;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 100px;
  position: absolute;
`;

const Img = styled(ImgStyled)`
  width: ${props => props.size}px;
`;
