import { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import TermEn from './component/TermEn';
import TermKo from './component/TermKo';
import styled from 'styled-components';

const Term = () => {
  const { action } = useContext(LangContext);
  return (
    <>
      <Layout>
        <MainContainer id='main'>{action.isKo() ? <TermKo /> : <TermEn />}</MainContainer>
      </Layout>
    </>
  );
};
export default Term;

export const MainContainer = styled.div`
  background-image: url('/assets/img/members/bg.png');
  background-size: contain;
  background-size: 1920px auto;
`;
