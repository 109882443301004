import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
`;

export const ManageDropBoxContainer = styled.div`
  font-family: 'Inter', sans-serif;

  position: relative;

  border: ${({ isRequired }) => (isRequired ? '1px solid #FF0000' : '1px solid #000')};
  height: 48px;

  display: flex;
  align-items: center;
  border-radius: 10px;

  width: 100%;
  background: #fff;
`;

export const ManageDropBoxList = styled.ul`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;

  z-index: 99;
`;

export const ManageDropBoxItem = styled.li`
  button {
    font-size: 16px !important;
    color: #000 !important;
    height: 100% !important;
    padding: 15px !important;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer !important;

  width: 100%;
  height: 100%;
  margin: 0 20px !important;

  img {
    width: 12px;
  }
`;

export const Placeholder = styled.h3`
  color: ${({ selectItem }) => (selectItem ? '#000' : '#9A9A9A')};
  font-size: ${({ placeholderSize }) => placeholderSize};
  font-weight: 400;
`;

export const ItemButton = styled.button`
  width: 100%;
  font-size: 16px;
`;

export const ManageTitleBoxLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-right: 1px solid black;
  height: 100%;
  width: 100%;
  max-width: 200px;
  font-weight: 600;

  font-size: ${({ titleSize }) => titleSize};

  @media screen and (max-width: 700px) {
    max-width: 120px;
  }
`;

export const ManageTitleBox = styled.div`
  margin-bottom: 10px;

  font-size: ${({ titleSize }) => titleSize};
`;

export const RequiredText = styled.p`
  color: #ff0000;
  margin-bottom: 5px;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;
