/* eslint-disable jsx-a11y/anchor-is-valid */
import { sanitize } from 'dompurify';
import { DEFAULT_AVATAR } from '../../../component/avatar/const';
import moment from 'moment';
import { formatHrefLink, isEditableRequest } from '../../../util/comment';
import { useState, useRef, useContext, useEffect } from 'react';
import { uploadItemImage, deleteRequestComment } from '../../../action/request';
import UserContext from '../../../context/user/user';
import { preventScroll } from '../../../util/commentImageViewer';
import { ImgStyled } from '../../../styles/ImgStyled';

const RequestCommentBody = ({
  comment,
  onReload,
  showReply,
  onEdit,
  setShowImgViewer,
  setImgData,
}) => {
  const date = moment(comment.date).format('YYYY-MM-DD');
  const [editBody, setEditBody] = useState(comment['body']);
  const [isEdit, setIsEdit] = useState(false);
  const [medias, setMedias] = useState(comment.medias);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(UserContext);
  const imageInput = useRef(null);
  const user = comment['user'];
  const body = comment['body'];

  // 코멘트 변화 일어나면 수정 가능 상태 false처리
  useEffect(() => {
    setIsEdit(false);
    // console.log(comment);
  }, [comment]);

  const onDeleteImage = idx => {
    const newMedias = medias.filter((_, i) => i !== idx);
    setMedias(newMedias);
  };

  const imageChanged = async e => {
    try {
      const files = e.target.files;
      const newMedias = [...medias];
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadItemImage({
          file: files[i],
        });
        newMedias.push(res.img);
      }
      setMedias(newMedias);
      setLoading(false);
    } catch (e) {
      alert('request failed');
    }
  };

  // 리퀘스트 댓글 수정
  const editComment = async e => {
    e.preventDefault();
    e.stopPropagation();
    // 아무 내용 없다면 알림
    if (!editBody || editBody === '') {
      return alert('Comment is empty');
    }
    onEdit({
      comment_id: comment.comment_id,
      msg: editBody,
      medias,
      imgs: medias.map(media => JSON.stringify(media)),
    });
    setIsEdit(false);
  };

  const onDeleteComment = async () => {
    if (window.confirm('Delete?')) {
      try {
        await deleteRequestComment(comment.comment_id);
        onReload();
      } catch (e) {}
    }
  };

  const onClickImg = (imgArr, idx) => {
    // console.log(imgArr, idx);
    setShowImgViewer(true);
    setImgData({ index: idx, data: imgArr });
    preventScroll();
  };

  return (
    <>
      <div className='comment-item'>
        {isEdit && (
          <div className='comment-write'>
            <div className='profile'>
              <div className='profile-pic'>
                <ImgStyled src={user.thumbnail ? user.thumbnail : DEFAULT_AVATAR} alt='' />
              </div>
            </div>
            <form className='comment-write-form' data-action='1101-comment-edit-submit'>
              <div className='write-form'>
                <input type='hidden' name='comment' />
                <textarea
                  placeholder='Please input your text'
                  name='msg'
                  value={editBody}
                  onChange={e => setEditBody(e.target.value)}
                ></textarea>
                <div className='attachments'>
                  <ul className='files'>
                    {medias?.map((media, idx) => {
                      return (
                        <li key={idx + media.url}>
                          <div className='file-item'>
                            {/* 수정상태의 이미지 썸네일 */}
                            <a>
                              <img src={media.thumbs[0].url} alt='' />
                            </a>
                            <button
                              type='button'
                              className='btn-delete'
                              onClick={() => onDeleteImage(idx)}
                            >
                              ×
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className='form-action'>
                  <div className='file-uploader'>
                    <input
                      ref={imageInput}
                      type='file'
                      accept='image/*'
                      multiple
                      onChange={imageChanged}
                    />
                    <button
                      type='button'
                      className={'btn-upload-file loading_color ' + (loading && 'loading')}
                      onClick={() => imageInput.current.click()}
                    >
                      Edit Image
                    </button>
                  </div>
                  <button type='submit' className='btn btn-green btn-fill' onClick={editComment}>
                    Edit Comments
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        {!isEdit && (
          <>
            <div className='comment-item-header'>
              <div className='comment-item-header'>
                <div className='profile'>
                  <div className='profile-pic'>
                    <img
                      src={
                        comment.status === 'active'
                          ? user.thumbnail
                            ? user.thumbnail
                            : DEFAULT_AVATAR
                          : DEFAULT_AVATAR
                      }
                      alt=''
                    />
                  </div>
                  <div className='profile-name'>{comment.status === 'active' ? user.name : ''}</div>
                  <div className='comment-date'>{comment.status === 'active' ? date : ''}</div>
                </div>
              </div>

              {isEditableRequest(comment, String(state.id)) && (
                <>
                  <div className='util-btns'>
                    <button
                      className='btn-edit'
                      onClick={
                        () => {
                          setIsEdit(true);
                          // 수정 누를 때 medias 목록 업데이트, 댓글 내용 업데이트
                          setMedias(comment.medias);
                          setEditBody(comment.body);
                        }
                        //
                      }
                    >
                      Edit
                    </button>
                    <button className='btn-delete' onClick={onDeleteComment}>
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className='comment-item-body'>
              <div className='comment-content'>
                <p dangerouslySetInnerHTML={formatHrefLink(comment.body)}></p>
              </div>
              {/* 수정상태가 아닌 댓글 */}
              <div className='attachments' data-image-gallery='true'>
                {comment.status === 'active'
                  ? comment.medias?.map((media, idx) => {
                      return (
                        <a
                          // href={media.thumbs[0].url}
                          onClick={() => onClickImg(comment.medias, idx)}
                          data-tb={media.type}
                          key={idx}
                        >
                          <img src={media.thumbs[0].url} alt='' />
                        </a>
                      );
                    })
                  : ''}
              </div>
            </div>
            {/* 리플 버튼 표시 */}
            {state.id && (
              <div className='comment-item-footer'>
                <a className='btn btn-xs' data-action='1102-comment-reply' onClick={showReply}>
                  Reply
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RequestCommentBody;
