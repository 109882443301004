import styled from 'styled-components';

export const ManageDashBoardStyle = styled.div`
  font-family: 'Inter', sans-serif;
  margin-top: 50px;
`;

export const TotalModels = styled.h2`
  font-size: 32px;
  font-weight: 500;
  padding: 15px !important;
  font-family: 'Inter', sans-serif !important;
  @media screen and (max-width: 700px) {
    padding: 0px !important;
  }
`;

export const DashBoardForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 50px;
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
`;

export const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media screen and (max-width: 700px) {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: ${({ isMobile }) => (isMobile ? '100%' : '306px')};
  margin-left: 25px;

  button:first-child {
    flex-basis: 240px !important;
  }
  button:last-child {
    flex: 1;
    font-size: 14px !important;
  }

  @media screen and (max-width: 700px) {
    justify-content: flex-end;
    margin-top: 20px;

    button:first-child {
      flex-basis: 240px !important;
    }
    button:last-child {
      flex: 0;
      flex-basis: 51px !important;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  & > div:nth-child(2) {
    margin-left: ${({ isMobile }) => (isMobile ? 'auto' : '0')};
    margin-bottom: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  }
`;

export const UploadButtonBox = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  gap: 15px;

  & > div:nth-child(1) {
  }

  & > :nth-child(2) {
    width: 240px;
  }
`;
