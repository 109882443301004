import { useNavigate } from 'react-router-dom';
import { ITEMS } from '../../../route/constant';
import CartPrice from './CartPrice';

const CartFreeNotExpiredProduct = ({ data, limit, deleteItem }) => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <li>
      <div
        className={'purchase-product-list-item' + (data.isBuyable ? '' : ' already-bought-3c3a')}
      >
        <div className='purchase-product-list-item-inner'>
          <div className='checker'>
            <label className='input-radio'>
              {data.isBuyable && (
                <input
                  type='radio'
                  value={data.productId}
                  data-price={data.salesPrice}
                  name='free-item'
                  data-name='cart-product'
                ></input>
              )}
              {!data.isBuyable && (
                <input type='radio' value={data.productId} data-price='0' disabled></input>
              )}
              <span className='label'></span>
            </label>
          </div>
          <a href='' onClick={e => goTo(e, ITEMS + '/' + data.productId)}>
            <img src={data.itemRowDtos.list_img} className='tb' alt='' />
          </a>
          <div className='product-meta'>
            <span className='product-name'>
              <a href='' onClick={e => goTo(e, ITEMS + '/' + data.productId)}>
                {data.itemRowDtos.name_en}
              </a>
            </span>
            {limit.hasDownloadHistory && <span className='icon-lock id-13fa'></span>}
            {data.isBuyable && (
              <span className='price'>
                <CartPrice data={data} />
              </span>
            )}
            {!data.isBuyable && <span className='warn-3c9a'>{data.isBuyableErrorMsg}</span>}
          </div>
          <a
            href='#'
            className='btn-delete'
            data-action='cart-delete'
            data-item="' .$dto->productId . '"
            onClick={() => deleteItem(data.productId)}
          >
            ×
          </a>
        </div>
      </div>
    </li>
  );
};

export default CartFreeNotExpiredProduct;
