export function downloadSdk(url) {
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = url;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "apple-web-sdk");
}
