import { useParams } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import ReviewDetail from './component/ReviewDetail';
import ReviewMain from './component/ReviewMain';
import CommentImageViewer from '../../component/comment/CommentImageViewer';
import { useState } from 'react';

const Review = () => {
  const { oid, subject } = useParams();
  const [showImgViewer, setShowImgViewer] = useState(false);

  const [imgData, setImgData] = useState({ index: 0, data: {} });

  return (
    <>
      <div id='main'>
        <Layout>
          {!oid && <ReviewMain />}
          {oid && (
            <ReviewDetail
              oid={oid}
              subject={subject}
              setShowImgViewer={setShowImgViewer}
              setImgData={setImgData}
            />
          )}
        </Layout>
        {showImgViewer && (
          <CommentImageViewer imgData={imgData} setShowImgViewer={setShowImgViewer} />
        )}
      </div>
    </>
  );
};

export default Review;
