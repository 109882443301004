import styled from 'styled-components';
const ProcessingModal = () => {
  return (
    <ProcessingModalContainer>
      <Box>
        <div className='img-container'>
          <img src='/assets/img/mange/processing_icon.png' alt='' />
        </div>
        <div>
          <p>
            <img src='/assets/img/mange/ProcessingTxt.png' alt='' />
            <AnimatedDots />
          </p>
        </div>
      </Box>
    </ProcessingModalContainer>
  );
};

export default ProcessingModal;

const ProcessingModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 95%;
  max-width: 443px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  border-radius: 10px;

  .img-container {
    width: 63.72px;
    height: 75.72px;
  }

  img {
    text-align: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    font-family: 'Inter' !important;
    font-size: 32px;
    font-weight: 600 !important;
    text-align: center;
    color: #9a9a9a;
    margin-top: 10px;
    max-width: 150px;
  }
`;

const AnimatedDots = styled.span`
  display: inline-block;
  vertical-align: sub;
  text-align: left;
  position: relative;
  letter-spacing: 0.1em; /* 추가: content 간격 설정 */

  &::after {
    content: '';
    animation: dots 1s steps(3, start) infinite;
    position: absolute;
    bottom: 0;
    line-height: 1; /* 추가: 라인 높이 설정 */
  }

  @keyframes dots {
    0%,
    20% {
      content: '';
    }
    40% {
      content: '.';
    }
    60% {
      content: '..';
    }
    80%,
    100% {
      content: '...';
    }
  }
`;
