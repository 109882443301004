/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import {
  HOSTNAME,
  baseUrl,
  getPrinters,
  getSlicers,
  snsSignup,
  validInvite,
} from '../../action/request';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import Layout from '../../component/layout/Layout';
import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import LangContext from '../../context/lang/lang';
import capitalizeFirstLetter from '../../util/capitalizeFirstLetter';
import { useNavigate } from 'react-router-dom';
import { Ss, ls } from '../../constant';
// import { GOOGLE_LOGIN_CALL } from '../../action/constant';
import { gtagOnClickSignup } from '../../action/gTag';
import { FOOTER_PRIVACY, FOOTER_TERMS } from '../../route/constant';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isWelcomeModal } from '../../atom/atom';

function SnsSignup() {
  //회원가입이 성공하면 isWelcome을 true로 변경하여 welcomeModal을 띄우기 위한 atom
  const setIsWelcomeValue = useSetRecoilState(isWelcomeModal);
  const [printers, setPrinters] = useState([]);
  const [slicers, setSlicers] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState(0);
  const [selectedSlicer, setSelectedSlicer] = useState(0);
  const [inviteTokenState, setInviteTokenState] = useState(null);
  const { state, action: userAction } = useContext(UserContext);
  const [currentIp, setCurrentIp] = useState(null);
  const { action: modalAction } = useContext(ModalContext);
  const { state: langState } = useContext(LangContext);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;
  const navigate = useNavigate();

  const FAB365_URL = 'fab365.net';
  const FAB365_URL_MOCK = 'java.fab365.net';
  const FAB365_DEV_URL = 'dev.fab365.net';
  const FAB365_DEV_URL_MOCK = 'dev-java.fab365.net';
  const FAB365_DEV_URL_MOCK_SECOND = 'dev2.fab365.net';

  let redirectBaseUrl;

  if (HOSTNAME === FAB365_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL;
  } else if (HOSTNAME === FAB365_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK_SECOND) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK_SECOND;
  }

  const config = {
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: redirectBaseUrl,
    access_type: 'offline',
    response_type: 'code',
    scope: 'email profile',
  };

  const queryString = Object.entries(config)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const GOOGLE_LOGIN_CALL = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;

  useEffect(() => {
    // singup으로 넘어온 유저가 아니면 홈으로 리다이렉트
    // console.log('snsUserInfo 저장 :: ', state.snsUserInfo);
    if (state.snsUserInfo.sns === undefined) {
      navigate('/');
    }
    // 로그인 한 유저면 리다이렉트
    if (state.isLogin) {
      // 로컬스토리지에 기존 위치가 있다면 리다이렉트
      if (localStorage.getItem(ls.loginPath)) {
        navigate(localStorage.getItem(ls.loginPath));
        // 로그인 위치로 이동 후 로그인 위치 정보 삭제
        localStorage.removeItem(ls.loginPath);
      } else {
        navigate('/');
      }
    }
  }, [state.isLogin, navigate, state.snsUserInfo]);

  useEffect(() => {
    fetchData();
    // 초대 토큰 저장
    if (!sessionStorage.getItem(Ss.inviteToken)) {
      // console.log('초대토큰없음');
    }
    if (sessionStorage.getItem(Ss.inviteToken)) {
      if (sessionStorage.getItem(Ss.inviteToken) === 'google') return;
      setInviteTokenState(sessionStorage.getItem(Ss.inviteToken));
      // console.log('초대토큰있음');
    }
  }, []);

  useEffect(() => {
    if (inviteTokenState) {
      // 초대 토큰이 있다면 유효성 검사
      checkValidToken(inviteTokenState);
    }
  }, [inviteTokenState]);

  // 토큰 유효성 검사
  const checkValidToken = async tokenData => {
    const { data: validTokenRes } = await validInvite(tokenData);
    // console.log('validTokenRes ::: ', validTokenRes);
    if (validTokenRes.result === true) {
    } else if (validTokenRes.result === false) {
      alert('Invalid token');
      navigate('/');
    }
  };

  const fetchData = async () => {
    // printers & slicers
    try {
      const { data: printers } = await getPrinters();
      setPrinters(printers.data);
      const { data: slicers } = await getSlicers();
      setSlicers(slicers.data);
    } catch (e) {}
  };

  const onChangeSelectedPrinter = e => {
    setSelectedPrinter(e.target.value);
  };
  const onChangeSelectedSlicer = e => {
    setSelectedSlicer(e.target.value);
  };

  // 회원가입버튼 클릭
  const onCreateAccount = async () => {
    try {
      onSignUp();
    } catch (e) {}
  };

  const onSignUp = async () => {
    //email 없으면 회원가입 처리 막아두기
    if (
      state.snsUserInfo.email === '' ||
      state.snsUserInfo.email === 'null' ||
      state.snsUserInfo.email === null ||
      state.snsUserInfo.email === undefined ||
      state.snsUserInfo.email === 'undefined'
    ) {
      alert('email is required');
      return;
    }

    const params = {
      action: 'signup',
      params: {
        email: state.snsUserInfo.email,
        sns: state.snsUserInfo.sns,
        sns_token: state.snsUserInfo.sns_token,
        printer: selectedPrinter,
        slicer: selectedSlicer,
        invite_token: inviteTokenState,
      },
    };
    try {
      const { data } = await snsSignup(JSON.stringify(params));
      // console.log('회원가입 클릭 후 데이터 ::: ', data);
      if (data.returnCode === 'C00000') {
        // navigate('/');
        sucessSignup(state.snsUserInfo.sns);
      }
      if (data.data) {
        userAction.setIsSignup(false);
        return userAction.setIsLogin(true);
      }
      if (data.message) return alert(data.message);
    } catch (e) {}
  };

  // 회원가입 성공 후 로그인
  const sucessSignup = async sns => {
    if (sns === 'google') {
      window.location.href = GOOGLE_LOGIN_CALL;
      // gtag 구글 회원가입 추적 태그
      gtagOnClickSignup('Google');
    } else if (sns === 'apple') {
      window.location.href = `${baseUrl}api/sns/apple/callback`;
      // gtag 애플 회원가입 추적 태그
      gtagOnClickSignup('Apple');
    } else {
      window.location.href = `${baseUrl}sns/${sns}/callback`;
      // gtag 구글 제외 소셜 회원가입 추적 태그 (페이스북, 야후, 트위터, 애플, 깃헙)
      gtagOnClickSignup(sns);
    }
    setIsWelcomeValue(true);
    navigate('/');
  };

  const goToPrivacy = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate(FOOTER_PRIVACY);
  };

  const goToTerms = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate(FOOTER_TERMS);
  };

  return (
    <Layout>
      <div id='main' className='sns-signup-main'>
        <div id='sns-signup' className='container'>
          <div className='modal-page'>
            <div className='modal-page-header'>
              {/* TODO : 소셜 바인딩 */}
              <h1 className='modal-page-header-title'>
                {state.snsUserInfo.sns && capitalizeFirstLetter(state.snsUserInfo.sns)} Sign up
              </h1>
            </div>
            <div className='modal-page-body'>
              <form className='m-b-md' id='page-signup-fb-25be'>
                <input type='hidden' name='sns' value='<?php echo $sns?>' />
                <div className='signup-agree m-b-md'>
                  <p>
                    By creating an account, you agree to Fab365's
                    <br />
                    <a href='' className='txt-green' onClick={goToPrivacy}>
                      Privacy Policy
                    </a>
                    and{' '}
                    <a href='' className='txt-green' onClick={goToTerms}>
                      Terms of Use
                    </a>
                  </p>
                </div>
                <div className='form-control' style={{ visibility: 'hidden' }}></div>
                {/* TODO : 이메일 바인딩 */}
                <div className='form-control'>
                  {state.snsUserInfo.email === 'null' ? '' : state.snsUserInfo.email}
                </div>
                {/* <div className='form-control'>
                  <select
                    className='form-input'
                    name='printer'
                    onChange={onChangeSelectedPrinter}
                    value={selectedPrinter}
                  >
                    <option value='0'>Select your 3D printer {'>>>'}</option>
                    {printers.map(p => {
                      return (
                        <option key={p.id_printer} value={p.id_printer}>
                          {p.printer}
                        </option>
                      );
                    })}
                  </select>
                </div> */}

                {/* <div className='form-control'>
                  <select
                    className='form-input'
                    name='slicer'
                    onChange={onChangeSelectedSlicer}
                    value={selectedSlicer}
                  >
                    <option value='0'>Select your slicer {'>>>'}</option>
                    {slicers.map(s => {
                      return (
                        <option key={s.id_slicer} value={s.id_slicer}>
                          {s.slicer}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                {/* TODO : 회원가입 처리 */}
                <button
                  onClick={onCreateAccount}
                  type='button'
                  className='btn btn-full btn-fill btn-green signup'
                >
                  Create Account
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SnsSignup;
