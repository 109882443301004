/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import CheckoutProcess from './component/CheckoutProcess';
import { createCheckoutStep3 } from '../../action/request';
import { fileSize } from '../../util/file';
import { ls } from '../../constant';
import Tippy from '@tippyjs/react';
import styled, { css } from 'styled-components';
import DownloadProcessingModal from '../../component/modal/DownloadProcessingModal';
import Selectefile from '../../component/selectefile/Selectefile';

function CheckoutSucess() {
  const [orderInfo, setOrderInfo] = useState();
  const [isFree, setIsFree] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [itemCount, setItemCount] = useState(1);
  const [downloadModalStep, setDownloadModalStep] = useState(0);
  const [itemId, setItemId] = useState(0);
  const [selectedModal, setSelectedModal] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const { orderNumber } = useParams();
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const goToOrder = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/my/orders');
  };

  const goToDownloads = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/my/downloads');
  };

  const goToCategories = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/categories');
  };

  const clickDownload = async id => {
    setItemId(id);
    setSelectedModal(true);
    setSelectedId(id);
    // setDownloadModalStep(1);
  };

  const fetchData = async () => {
    const { data: orderInfoRes } = await createCheckoutStep3(orderNumber);

    setOrderInfo(orderInfoRes.data);
    setSelectedId(orderInfoRes?.data?.order?.item[0].id_item);

    setIsLoading(false);
    if (orderInfoRes.data.order.price) {
      // 구매한 아이템이 프리인지 아닌지 확인
      orderInfoRes.data.order.item[0].item.free === 'n' ? setIsFree(false) : setIsFree(true);
      setItemCount(orderInfoRes.data.order.item.length);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <>
      <PageMobileStyle id='main' step={downloadModalStep}>
        {selectedModal && selectedId && (
          <Selectefile
            setSelectedModal={setSelectedModal}
            selectedName={orderInfo?.order?.item[0]?.item.name_en}
            id={selectedId}
            setSelectedId={setSelectedId}
          />
        )}
        <Layout>
          <div id='main'>
            <CheckoutProcess step={3} />
            <section className='download-section'>
              <div className='container'>
                <div className='download'>
                  <div className='download-message'>
                    {/* TODO: status 구분 */}
                    <strong>Thank You!</strong>
                    <br />
                    We are processing your payment.
                    {/* <strong>Sorry</strong>
                <br />
                Payment has failed. */}
                    {/* <strong>Thank You!</strong>
                <br />
                Purchased Successfully. */}
                    {!isFree &&
                      (isLoading ? (
                        <div></div>
                      ) : (
                        <>
                          <div
                            style={{
                              marginTop: '30px',
                              fontSize: '24px',
                              color: '#4200F6',
                              fontWeight: '4000',
                            }}
                          >
                            you've got
                          </div>
                          <div style={{ fontSize: '40px', fontWeight: 'bold', color: '#4200F6' }}>
                            {orderInfo.order.item[0].item.item_qty} Reward ticket
                          </div>
                          <div style={{ fontSize: '24px', fontWeight: '400', color: '#4200F6' }}>
                            So, you have {orderInfo && orderInfo.usable} tickets now.
                          </div>
                          <div
                            style={{
                              color: '#511AD0',
                              fontSize: '14px',
                              marginTop: '10px',
                              fontWeight: '600',
                            }}
                          >
                            Reward ticket?&nbsp;
                            <span className='fab-btn-i--tooltip-f' aria-describedby='tippy-1'>
                              <NewTippyStyle
                                className='tippy-1'
                                content={[
                                  <li>
                                    &bull; Reward tickets are coupons for downloading reward model.
                                  </li>,
                                  <li>
                                    &bull; Reward tickets can only be used for reward models, not
                                    for paid models.
                                  </li>,
                                  <li>
                                    &bull; The reward models are created as a form of fan art,
                                    filled with utmost respect for masterpieces. And we share them
                                    for FREE with our customers.
                                  </li>,
                                  <li>
                                    &bull; You can receive a reward ticket immediately after signing
                                    up for Fab365.
                                  </li>,
                                  <li>
                                    &bull; You can earn additional reward tickets for each purchased
                                    paid model (including models purchased with Fab365 credit).
                                  </li>,
                                  <li>&bull; The issued reward ticket is valid for 6 months.</li>,
                                  <li>
                                    &bull; Please note that reward tickets are only available for
                                    accounts that have completed email authentication..
                                  </li>,
                                ]}
                              >
                                <img
                                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/svg/icon-tooltip.svg'
                                  alt=''
                                />
                              </NewTippyStyle>
                            </span>
                          </div>
                        </>
                      ))}
                  </div>

                  <div className='content-box'>
                    <div className='content-box-header'>
                      <h2>Download</h2>
                    </div>
                    <div className='content-box-body'>
                      <OrderNumberContainer className='orderno'>
                        <div>
                          Order Number <span className='txt-green'>#{orderNumber}</span>
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          Once you have downloaded the purchased model, you cannot receive a refund.
                        </p>
                      </OrderNumberContainer>
                      <div className='purchase-product-list per-one'>
                        <ul className='clearfix'>
                          {orderInfo &&
                            orderInfo.order.item.map(l => {
                              const size = fileSize(l.item.all_file_size);
                              return (
                                <li key={l.id_item}>
                                  <div className='purchase-product-list-item'>
                                    <div className='purchase-product-list-item-inner'>
                                      <img src={l.item.list_img} className='tb' alt='' />
                                      <div className='product-meta'>
                                        {/* TODO: 아이템 이름 언어에 따라 변경 */}
                                        <span className='product-name'>{l.item.name_en}</span>
                                        <span className='file-size'>
                                          {size.size.toFixed(1) + '' + size.unit}
                                        </span>
                                        <button
                                          className='btn btn-sm btn-download'
                                          onClick={() => {
                                            clickDownload(l?.item.id_item);
                                          }}
                                        >
                                          Download
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>

                      <div className='download-footer clearfix'>
                        <div className='notice'>
                          You can see your order detail &amp; download your file again{' '}
                          <a href='#' className='txt-green' onClick={goToOrder}>
                            Order
                          </a>{' '}
                          &amp;{' '}
                          <a href='#' className='txt-green' onClick={goToDownloads}>
                            Download
                          </a>
                        </div>
                        <a href='#' className='txt-purple btn-continue' onClick={goToCategories}>
                          Continue Shopping &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Layout>
      </PageMobileStyle>
      {/* 다운로드 모달 */}
      {downloadModalStep > 0 && (
        <DownloadProcessingModal
          itemId={itemId}
          step={downloadModalStep}
          setStep={setDownloadModalStep}
        />
      )}
    </>
  );
}

export default CheckoutSucess;

export const TippyStyle = styled(Tippy)`
  line-height: 1.4em;
  font-size: 14px;
  padding: 0.2rem;
  /* Styling the arrow for different placements */
  &[data-placement^='top'] {
    .tippy-arrow {
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 7px 7px 0;
      border-color: lightgray transparent transparent transparent;
    }
  }
  &[data-placement^='bottom'] {
    .tippy-arrow {
      z-index: 999;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
      border-style: solid;
      border-width: 0 7px 7px;
      border-color: transparent transparent lightgray transparent;
    }
  }
`;

const PageMobileStyle = styled.div`
  ${props =>
    props.step !== 0 &&
    css`
      height: 100vh;
      overflow: hidden;
    `}
  /* STL다운로드 모달 640px 이하에서 띄울 시 스크롤바 없애기 */
   @media screen and (max-width: 640px) {
    ${props =>
      props.step !== 0 &&
      css`
        display: none;
      `}
  }
`;

const NewTippyStyle = styled(TippyStyle)`
  li {
    list-style: none;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const OrderNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 830px) {
    flex-direction: column;

    p {
      margin-top: 10px;
    }
  }
`;
