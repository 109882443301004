import { positiveValueWithDecimal } from '../../section/util';
import {
  Box,
  InputWrapper,
  LargestPartWrapper,
  PartSizeInput,
  Section,
  Symbol,
} from './styles/LargestPartSizeStyle';

const LargestPartSIze = ({ largestPartSize, onChange }) => {
  return (
    <LargestPartWrapper>
      <h3>Largest part size</h3>
      <Box>
        <Section>
          <div className='input-wrapper'>
            <InputWrapper>
              <label htmlFor='xAxis'>X axis</label>
              <PartSizeInput
                type='number'
                id='xAxis'
                onChange={onChange}
                value={largestPartSize.xAxis}
                min={0}
                onKeyDown={e => positiveValueWithDecimal(e)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor='yAxis'>Y axis</label>
              <PartSizeInput
                type='number'
                id='yAxis'
                onChange={onChange}
                value={largestPartSize.yAxis}
                min={0}
                onKeyDown={e => positiveValueWithDecimal(e)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor='zAxis'>Z axis</label>
              <PartSizeInput
                type='number'
                id='zAxis'
                onChange={onChange}
                value={largestPartSize.zAxis}
                min={0}
                onKeyDown={e => positiveValueWithDecimal(e)}
              />
            </InputWrapper>
          </div>
        </Section>
        <Symbol>mm</Symbol>
      </Box>
    </LargestPartWrapper>
  );
};

export default LargestPartSIze;
