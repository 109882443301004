import styled from 'styled-components';

const statusColor = {
  public: '#000',
  draft: '#9A9A9A',
  secret: '#FD5757',
};

const gridTemplateColumnsPC = `
  minmax(0, 120px) minmax(110px, 452px) minmax(0, 240px) minmax(0, 120px)
  minmax(0, 120px) minmax(11px, 128px) minmax(0, 160px)
`;

const gridTemplateColumnsMobile = `
  minmax(0, 120px) minmax(150px, 452px) minmax(0, 240px) minmax(0, 120px)
  minmax(0, 120px) minmax(11px, 128px) 
`;

const containMinWidth = '648px';

export const Container = styled.div`
  overflow-x: auto !important; /* 가로 스크롤 활성화 */
  overflow-y: auto !important; /* 세로 스크롤 비활성화 */
  white-space: nowrap; /* 가로 스크롤을 위해 nowrap 설정 */
  position: relative;
  margin: ${({ isMobile }) => (isMobile ? '0 0 0 -1.5rem' : '0')};
  //마진의 값 *2 로 width 값 조정
  width: ${({ isMobile }) => (isMobile ? 'calc(100% + 3rem)' : '100%')};
  position: relative;
`;

export const DesignerItemListContainer = styled.div`
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  border-radius: 10px 10px 0 0;
  border: 1px solid #000;
  width: 100%;
  max-width: 1340px;
  min-width: ${containMinWidth};
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? gridTemplateColumnsMobile : gridTemplateColumnsPC};
`;

export const GridItem = styled.div`
  padding: 20px 0;
  font-size: 16px;
  display: flex;
  justify-content: ${({ className }) => (className === 'img-wrapper' ? 'flex-start' : 'center')};
  gap: ${({ className }) => (className === 'img-wrapper' ? '10px' : '0')};
  align-items: center;

  font-weight: ${({ status }) => (status === 'public' ? 600 : 400)};
  color: ${({ status }) => status === 'public' && '#4CBE37'};

  cursor: ${({ className }) => (className === 'img-wrapper' ? 'pointer' : 'default')};
`;

export const HeaderItem = styled(GridItem)`
  color: #9a9a9a;
  font-weight: bold;
`;

export const ImgWrapper = styled.div`
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    min-width: 40px;
    min-height: 40px;
    object-fit: cover;
  }
`;

export const Button = styled.button`
  color: ${({ className }) => (className === 'edit' ? '#000' : '#9A9A9A')} !important;
`;

export const ProductItemContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? gridTemplateColumnsMobile : gridTemplateColumnsPC};

  background-color: ${({ status }) => (status === 'public' ? '#fff' : '#F5F5F5')};
  max-height: 100px;
  border-bottom: 1px solid #000;

  color: ${({ status }) => statusColor[status]};

  .category {
    white-space: wrap;
    text-align: center;
  }

  .item-id {
    font-weight: 600 !important;
    color: #000;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;
export const ItemListContainer = styled.div`
  border: 1px solid #000;
  border-top: none;
  border-radius: 0 0 10px 10px;
  min-width: ${containMinWidth};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  button {
    font-weight: 600;
    font-family: 'Outfit', sans-serif !important;
  }

  .delete {
    color: #9a9a9a;
  }
`;

export const DraftText = styled.div`
  color: #9a9a9a;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ProductName = styled.div`
  /* text-align: ${({ isDraft }) => (isDraft ? 'center' : 'left')}; */

  text-align: left;

  width: 100%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  max-height: 2.4em;
  line-height: 1.2;
`;
