import React, { useEffect, useState, useContext } from 'react';

import { getDesignerReview } from '../../../action/request';

import ModelCard from '../../common/itemCards/ModelCard';
import Pagenation from '../Pagenation';

import { CardWrap, Card } from '../designers';
import { useRecoilState } from 'recoil';
import { designerPaginationAtom } from '../../../atom/atom';
import UserContext from '../../../context/user/user';

const Reviews = ({ value, moveScroll, selectedSort, designerURL }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [count, setCount] = useState();
  const [pageNation, setPageNation] = useRecoilState(designerPaginationAtom);
  const { state: userState } = useContext(UserContext);
  const isLogin = userState.isLogin;
  const scrollD = localStorage.getItem('scrollDesigner');

  useEffect(() => {
    if (pageNation) {
      setPage(pageNation);
      fetchReviews(pageNation);
    } else {
      fetchReviews(page);
    }
  }, [page, selectedSort, isLogin, designerURL]);

  const fetchReviews = async index => {
    try {
      const requestBody = {
        page: Number(index),
        sortType: selectedSort.value,
      };

      const { data: designerData } = await getDesignerReview({ isLogin, designerURL, requestBody });
      const resultData = designerData.data;
      setData(resultData.list);
      setCount(resultData?.pagingInfo.total);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <CardWrap>
        {data?.map((item, key) => {
          return (
            <Card key={key}>
              <ModelCard data={item} value={value} page={page} />
            </Card>
          );
        })}
      </CardWrap>
      {count > 0 && (
        <Pagenation
          count={count}
          setPage={setPage}
          page={page}
          moveScroll={moveScroll}
          value={value}
          setPageNation={setPageNation}
          viewCount={16}
        />
      )}
    </div>
  );
};

export default Reviews;
