import styled from 'styled-components';

const ManageButton = ({
  backgroundColor,
  maxWidth,
  color,
  text,
  onClick,
  border,
  type,
  disabled,
}) => {
  return (
    <ManageButtonContainer
      backgroundColor={backgroundColor}
      maxWidth={maxWidth}
      color={color}
      onClick={onClick}
      border={border}
      type={type}
      disabled={disabled}
    >
      {text}
    </ManageButtonContainer>
  );
};

export default ManageButton;

const ManageButtonContainer = styled.button`
  font-weight: 600 !important;
  font-family: 'Outfit', sans-serif;
  text-align: center !important;
  color: ${({ color }) => color} !important;
  font-size: 16px !important;

  background-color: ${({ backgroundColor }) => backgroundColor} !important;
  padding: 10px 0 !important;

  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};

  border-radius: 10px;
  border: ${({ border }) => border || 'none'} !important;
`;
