import { useNavigate } from 'react-router-dom';
import ItemRecommand from '../../component/item/ItemRecommand';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import { PRINT_TIPS_LIST } from '../../route/constant';
import { renderTipFeature } from '../../util/renderer';

const BestBed = ({ title, tab }) => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu title={title} tab={tab} />
          <div className='container fab-accordion-mt-s1 fab-accordion-mb-s1'>
            <div className='fab-accordion __w1340'>
              <div className='fab-accordion-header'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
                <h2 className='fab-accordion-header--title'>
                  Which is the best 3D Printer Bed/Plate?
                </h2>
              </div>
              <div className='featured-story-list'>
                <ul>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/pei-pex.jpg',
                      null,
                      'PEI/PEX the best bed.<br/><br/>PEI bed was originally used in Prusa, but now all the other production companies are producing it.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/pei2.jpg',
                      null,
                      'The PEI bed from Twotress and Wham Bam Systems is the best. There are alot of companies making PEI/PEX bed now, you can select other production companies if you wish.',
                      null,
                      null
                    )}
                  </li>
                </ul>
              </div>
              <div className='fab-accordion-footer'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
              </div>
            </div>
          </div>

          <section>
            <ItemRecommand itemId={null} />
          </section>
        </div>
      </Layout>
    </>
  );
};

export default BestBed;
