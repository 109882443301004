import { SYMBOL_KRW, SYMBOL_USD } from '../component/Price/constant';
export const getMainThumb = model => {
  if (model?.medias?.length <= 0) {
    return model?.mainImg;
  }
  return model.medias[parseInt(model.mainImg)].thumbs[0].url;
};

export const getRequestCommentImages = () => {
  // return array_map(
  //   function (CommentMediaDraftDto $media){
  //     $full = $media->url;
  //     $thumb = $media->thumbs[0] ? $media->thumbs[0]->url : "";
  //     return sprintf("<a href='%s' data-tb='%s'><img src='%s'></a>", $full, $thumb, $thumb);
  //   }, $medias);
};

export const DEFAULT_AVATAR = '/assets/img/default-profile-pic.png';
export const getSymbol = () => {
  return window.navigator.language === 'ko' ? SYMBOL_KRW : SYMBOL_USD;
};
