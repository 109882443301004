import Layout from "../../component/layout/Layout";
import Menu from "../../component/menu/Menu";
import "./DownloadModelFiles.css";
const DownloadModelFiles = ({ tab, title }) => {
  return (
    <>
      <Layout>
        <div className="main">
          <Menu tab={tab} title={title} />
          <section className="about-section">
            <div className="help">
              <div className="container">
                <div className="about-description-block">
                  <div className="about-description-block-header">
                    <h2>Help center</h2>
                    <p>How to download model files?</p>
                  </div>
                </div>

                <div className="help-block">
                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>1. You must log in.</p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/need_login.png"
                    className="help-img"
                    alt=""
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>2. Go to the my download page.</p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/go_to_download.png"
                    alt=""
                    className="help-img"
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        3. On the download page, you can download model files.
                        <br />
                        Click the "Download" button.
                      </p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-model-files/how-to-download-model-files-1.png"
                    className="help-img"
                    alt=""
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        In some browsers, mouse left click is not working
                        sometimes.
                        <br />
                        If the mouse left click is not working, click the mouse
                        right button then you can see menus.
                        <br />
                        Click "Open link in new tab" or "Open link in new
                        window".
                      </p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-model-files/how-to-download-model-files-2.png"
                    className="help-img"
                    alt=""
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>Then you can download the model file.</p>
                    </div>
                  </div>
                  <img
                    src="https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/how-to-download-model-files/how-to-download-model-files-3.png"
                    className="help-img"
                    alt=""
                  />

                  <div className="about-description-block">
                    <div className="about-description-block-header">
                      <p>
                        We apologise for any inconvenience caused. We will fix
                        inconveniences as soon as possible.
                        <br />
                        If you have any other problems, please let us know.
                        <a href="mailto:help@fab365.net">help@fab365.net</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default DownloadModelFiles;
