import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_AES_SECRETKEY;
const iv = process.env.REACT_APP_AES_IV;

// 암호화
export const encrypt = (val, userId) => {
  let text = val.toString();
  const key = (userId + secretKey).slice(0, 32);
  // console.log('userId + 암호화 키: ', key);
  // console.log('userId + 암호화 키 글자 수 : ', key.length);

  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return encrypted.toString();
};

// 복호화
export const decrypt = (encrypted, userId) => {
  const key = (userId + secretKey).slice(0, 32);

  try {
    const decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Decryption error', error);
    return '';
  }
};

// 랜덤 문자열 생성
export const generateRandomString = length => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};
