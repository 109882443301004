import { sanitize } from 'dompurify';
import { useContext, useState } from 'react';
import ReviewCommentHeader from '../../../component/comment/ReviewCommentHeader';
import UserContext from '../../../context/user/user';
import Pagination from '../../Category/component/Pagination';
import RequestCommentBody from './ReviewCommentBody';
import RequestCommentReply from './ReviewCommentReply';
import ReviewCommentReplyWrite from './ReviewCommentReplyWrite';

const ReviewComment = ({
  idRequest,
  commentGroups,
  commentReplys,
  count,
  size,
  page,
  setPage,
  onReload,
  setShowImgViewer,
  setImgData,
}) => {
  const { state } = useContext(UserContext);
  const [showReply, setShowReply] = useState([]);

  const pageChange = val => {
    setPage(val);
  };
  const pageConfirm = val => {
    setPage(val);
  };
  const searchReplys = () => {};

  const showCommentReply = idx => {
    const showList = [...showReply];
    showList[idx] = { ...showList[idx], show: true };
    setShowReply(showList);
    const target = showList[idx]?.target;
    if (target) {
      target.current.scrollIntoView();
    }
  };

  const setCommentReply = (target, idx) => {
    setShowReply(sr => {
      const showList = [...sr];
      showList[idx] = { target, show: false };
      return showList;
    });
  };

  return (
    <>
      <div className='container'>
        <div className='story-comments'>
          <div className='comments'>
            {/* {state.isLogin && (
              <ReviewCommentHeader thumbnail={state.thumbnail} id={idRequest} />
            )} */}
            {state.id && (
              <ReviewCommentHeader thumbnail={state.thumbnail} id={idRequest} onReload={onReload} />
            )}

            <div className='comments-body'>
              <div className='comment-list'>
                <ul>
                  {commentGroups?.map((c, idx) => {
                    const replys = commentReplys?.filter(r => {
                      return c.oid_comment === r.oid_parent;
                    });
                    // 리플 삭제되면 삭제된 것이 맨 위로 정렬
                    replys?.sort((a, b) => {
                      if (a.status === 2 && b.status !== 2) {
                        return -1;
                      } else if (a.status !== 2 && b.status === 2) {
                        return 1;
                      } else {
                        return 0;
                      }
                    });
                    return (
                      <li key={c.oid_comment}>
                        <div
                          className='comment-list-item'
                          data-json={sanitize(
                            JSON.stringify({
                              comment: c.oid_comment,
                              root: c.oid_target,
                            })
                          )}
                        >
                          <RequestCommentBody
                            comment={c}
                            onReload={onReload}
                            showReply={() => showCommentReply(idx)}
                            setShowImgViewer={setShowImgViewer}
                            setImgData={setImgData}
                          />
                          {replys?.map((c, replyIdx) => {
                            return (
                              <RequestCommentReply
                                replys={c}
                                idUser={state.id}
                                onReload={onReload}
                                setCommentReply={target => setCommentReply(target, idx)}
                                isLast={state.id && replyIdx === replys.length - 1}
                                showReply={target => showCommentReply(idx)}
                                setShowImgViewer={setShowImgViewer}
                                setImgData={setImgData}
                              />
                            );
                          })}
                          {showReply[idx]?.show && (
                            <ReviewCommentReplyWrite
                              thumbnail={state.thumbnail}
                              onReload={onReload}
                              root={idRequest}
                              parent={c.oid_comment}
                            />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <Pagination
                  page={page}
                  total={Math.ceil(count / size)}
                  onpagechange={pageChange}
                  onconfirm={pageConfirm}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewComment;
