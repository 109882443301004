import { useContext, useEffect, useState } from 'react';
import LangContext from '../../../context/lang/lang';
import { ABOUT, ABOUT_SERVICE } from '../../../route/constant';
import './ServiceKo.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { allowScroll, preventScroll } from '../../../util/commentImageViewer';

const ServiceKo = ({ isMobile }) => {
  const { action } = useContext(LangContext);
  const navigate = useNavigate();

  const toggleDetail = e => {
    e.stopPropagation();
    e.preventDefault();

    let classes;
    if (e.target.tagName === 'SPAN') {
      classes = e.target.parentElement.parentElement.parentElement.parentElement.classList;
    } else {
      classes = e.target.parentElement.parentElement.parentElement.classList;
    }
    let isHas = classes.value.split(' ').find(c => c === '--open');
    if (isHas) {
      if (isMobile) allowScroll();
      classes.remove('--open');
    } else {
      if (isMobile) preventScroll();
      classes.add('--open');
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  });

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <section className='fab-page-tabmenu fab-ko'>
        <div className='container'>
          <div className='fab-page-tabmenu-inner'>
            <ul>
              <li className='--active'>
                <a href='' onClick={e => goTo(e, ABOUT_SERVICE)}>
                  <span>SERVICE</span>
                </a>
              </li>
              <li>
                <a href='' onClick={e => goTo(e, ABOUT)}>
                  <span>ABOUT</span>
                </a>
              </li>
            </ul>
            <div className='fab-langs'>
              <a
                href='#'
                className='locale-en'
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('en', 'service');
                }}
              >
                EN
              </a>{' '}
              <span className='--split'>/</span>
              <a
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('ko', 'service');
                }}
                href='#'
                className='--active locale-ko'
              >
                KR
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section fab-page-section-ko' id='fabPageServiceTopDesc'>
        <div className='container fab-page-container'>
          <div className='fab-page-service-top-desc --mb-80 --mb-150--m'>
            <div className='--desc'>
              <h2 className='--logo'>
                <img
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/common/logo-black.svg'
                  alt='FAB 365'
                />
              </h2>
              <p className='--txt-lg --mb-5'>
                150여개국의 글로벌&nbsp;
                <br className='--when-mobile' />
                유저들이 사용하는
              </p>
              <p className='--txt-xxl'>
                창의적이고 혁신적인 <br />
                <strong>
                  3D 프린팅 <br className='--when-mobile' />
                  모델 마켓플레이스
                </strong>
              </p>
            </div>
            <div className='--model --model1'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/model1.svg'
                alt=''
              />
            </div>
          </div>
          <div className='fab-page-service-top-desc fab-page-service-top-desc2'>
            <div className='--desc'>
              <p className='--txt-lg --mb-60'>
                FAB365는 3D 프린팅을 <br className='--when-mobile' />
                즐기는 <br className='--when-desktop' />
                사람들을 위하여&nbsp;
                <br className='--when-mobile' />
                <strong>
                  멋진 디자인과 <br className='--when-desktop' /> 차원이 다른 품질,&nbsp;
                  <br className='--when-mobile' />
                  다양한 종류의 3D 모델
                </strong>{' '}
                을<br />
                전세계에 서비스하는&nbsp;
                <br className='--when-mobile' />
                컨텐츠 플랫폼입니다.
              </p>
              <p className='--font-basic'>
                3D 프린터를 장만했지만 제대로 된 데이타를 구하지 못해 매번 프린팅을 실패하거나,
                유료로 구매했지만 복잡하고 어려운 프린팅으로 스트레스를 받지 않으셨나요? 무료로
                공유되는 데이타와는 차원이 다른 혁신적인 설계와 수준 높은 품질의 3D 모델이 바로
                FAB365에 있습니다.
                <br />
                <br />
                우리는 가장 대중적으로 사용되는 다양한 종류의 3D 프린터에서도 잘 출력되도록
                테스트하고 검증된 디자인의 데이타를 제공하여 모든 3D 프린팅 사용자가 만족스러운
                출력물을 얻을 수 있는 것을 목표로 합니다. 이제 FAB365의 고품질 컨텐츠를 통해 3D
                프린팅의 새로운 차원을 경험해 보세요.
              </p>
            </div>
            <div className='--model --model2'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/model2.svg'
                alt=''
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className='fab-page-section fab-page-section-ko fab--bg-dark-gray'
        id='fabPageServiceDesc'
      >
        <div className='container fab-page-container'>
          <h2 className='--txt-xxl --txt-center --mb-70 --mb-40--m'>
            FAB365의 <strong>놀라운 디자인</strong>
          </h2>

          <article className='fab-page-card --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  쉽고 재밌는
                  <br /> <strong>폴더블 디자인</strong>
                </h3>
                <p className='--font-basic'>
                  팹365의 모델은 종이접기처럼 몇번 접고나면 형태가 완성이 됩니다.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div className='fab-page-card-img-inner'>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img1.jpg'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    일반적인 프라모델처럼 수많은 파트를 일일이 조립하지 않고 한번에 3D 프린팅하여
                    바로 접어서 완성하는 폴더블 디자인은 FAB365의 대표적인 디자인입니다.
                    <br />
                    <br />
                    이미 펼쳐진 출력물을 종이접기처럼 몇번 접고나면 형태가 완성이 됩니다. 또한
                    출력하자마자 조립없이 돌아가고 움직이는 파트는 3D 프린팅을 하는 즐거움을
                    배가시켜 줍니다.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --dark --black --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  매우 정교하고
                  <br /> <strong>놀라운 디테일</strong>
                </h3>
                <p className='--font-basic'>
                  팹365의 모델은 매니아들이 감탈할정도의 완벽한 디테일을 자랑합니다.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div className='fab-page-card-img-inner'>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img2.jpg'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    FAB365의 모든 컨텐츠는 매니아들이 감탄할 정도의 놀라운 디테일을 자랑합니다.
                    우리는 컨텐츠를 개발할때 방대한 자료수집과 철저한 고증을 통해 설계하기 때문에
                    사소한 것 하나 놓치지 않습니다.
                    <br />
                    <br />
                    출력하고 조립하여 완성했을때 사용자가 희열을 느낄 수 있도록 하는 것이 우리의
                    궁긍적인 목표이기 때문입니다.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --green --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  3D 모델 매니아를 위한
                  <br /> <strong>다양한 장르</strong>
                </h3>
                <p className='--font-basic'>
                  팹365의 모델은 3D모델을 사랑하는 사람들의 정확한 니즈를 파악하고 연구합니다.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img3.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    우리는 3D 모델을 사랑하는 사람들의 마음을 진심으로 이해하고 있습니다. 로보트
                    매니아, 자동차 매니아, 밀리터리 매니아, SF영화 매니아... 그 매니아들이 반드시 3D
                    프린터로 출력하고 싶은 컨텐츠를 연구한 후 멋진 디자인으로 개발하여 출시하고
                    있습니다.
                    <br />
                    <br />
                    앞으로도 3D 프린터 사용자들이 소장하고 싶은 다양한 장르의 아이템을 지속적으로
                    선보일 예정입니다.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <div className='--mt-160 --mb-160 --mt-100--m --mb-100--m'>
            <img
              src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/imgs.png'
              alt=''
              className='--img1'
              data-aos='fade-up'
            />
          </div>

          <h2 className='--txt-xxl --txt-center --mb-70 --mb-40--m'>
            FAB365의 <strong>고품질 컨텐츠</strong>
          </h2>

          <article className='fab-page-card --dark --blue --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  <strong>서포트가 없는</strong>
                  <br /> 혁신적인 설계
                </h3>
                <p className='--font-basic'>
                  팹365의 모델은 특별한 설계 노하우를 통해 서포트가 발생하지 않는 디자인을
                  제공합니다.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img4.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    3D 프린팅에서 가장 큰 문제점은 복잡한 서포트의 발생과 어려운 서포트 제거
                    작업입니다. 우리는 특별한 설계 노하우를 통해 서포트가 필요없는 디자인을
                    제공합니다.
                    <br />
                    <br />
                    재료와 출력시간을 줄여주고, 서포터를 제거하는 후가공을 불필요하도록 해줍니다.
                    또한 서포트가 없는 디자인은 재료를 아낄뿐만 아니라, 폐기물을 발생시키지 않기
                    때문에 지구를 보호하는 또 하나의 방법이기도 합니다.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --dark --black --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  획기적인{' '}
                  <strong>
                    프린트-인-
                    <br />
                    플레이스
                  </strong>{' '}
                  기술
                </h3>
                <p className='--font-basic'>
                  팹365의 모델은 여러개의 부품을 다양한 결합구조를 통해서 하나로 연결하여 파일수를
                  최소화하기 때문에, 프린팅 횟수를 획기적으로 줄여줍니다.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img5.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    수많은 파츠를 일일이 출력해야하는 번거로움은 마치 복잡한 프라모델을 조립하는
                    것과 비슷합니다. 우리는 이런 불편함을 해결하기 위해 프린트-인-플레이스 기술을
                    개발하였습니다.
                    <br />
                    <br />이 기술은 여러 개의 부품을 다양한 결합구조를 통해서 하나로 연결하여 파일
                    수를 최소화하기 때문에 반복적인 파일의 압축과 해제, 슬라이싱 작업, 프린팅 횟수를
                    획기적으로 줄여줄 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  어떤 프린터로 출력해도
                  <br /> <strong>완벽한 결과물</strong>
                </h3>
                <p className='--font-basic'>
                  FAB365는 어떤 프린터에서도 실패하지 않고 완벽한 결과물을 출력할 수 있도록 프린터의
                  사양과 옵션에 최적화된 파일을 제공하고 있습니다.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img6.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    끊임없이 출시되는 다양한 종류의 개인용 3D 프린터는 저사양과 고사양에 따라
                    출력물의 품질 차이가 천지 차이라고 할 수 있습니다. 거기에 수많은 유저들이 낮은
                    품질의 3D 모델로 인해 프린팅을 실패하게 되면서 많은 스트레스를 받고 있습니다.
                    <br />
                    <br />
                    그래서 FAB365는 어떤 프린터에서도 실패하지 않고 완벽한 결과물을 출력할 수 있도록
                    프린터의 사양과 옵션에 최적화된 파일을 제공하고 있습니다. FAB365의 모든 데이타는
                    설계 단계에서부터 모든 프린터의 프린팅 방식을 고려해서 설계가 되었기 때문입니다.
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className='fab-page-card --green --mb-30' data-aos='fade-up'>
            <div className='fab-page-card-inner'>
              <div className='fab-page-card-desc'>
                <h3 className='--txt-xl'>
                  수많은 출력 테스트를
                  <br /> 거친 <strong>검증된 품질</strong>
                </h3>
                <p className='--font-basic'>
                  팹365의 모델은 수많은 출력 테스트와 수없는 검증을 거친 후에 제품을 출시합니다.
                </p>
                <a href='#' onClick={toggleDetail} className='--more --font-basic'>
                  <span>More</span>
                </a>
              </div>
              <div className='fab-page-card-img'>
                <div
                  className='fab-page-card-img-inner'
                  style={{
                    backgroundImage:
                      'url(https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img7.jpg)',
                  }}
                >
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/service/img-empty.png'
                    alt=''
                  />
                </div>
                <div className='fab-page-card-img--desc'>
                  <p className='--font-basic'>
                    완벽한 설계와 앞선 기술을 사용한 3D 모델 파일이라 할지라도 다양한 종류의 3D
                    프린터에서 제대로 출력되지 않는다면 아무 소용이 없습니다.
                    <br />
                    <br />
                    그래서 우리는 컨텐츠를 디자인하는 과정에서 수많은 테스트 프린팅을 통하여 수정,
                    보완 작업을 합니다. 필라멘트의 종류, 실내 온도, 기계의 연식 등 다양한 환경과
                    조건에서 프린팅을 하고 출력된 제품을 수없이 조립을 하여 완벽한 검증을 거친 후에
                    제품을 출시하게 됩니다.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default ServiceKo;
