import styled from 'styled-components';

export const PaymentContainer = styled.div`
  margin-top: 16px;
  display: flex;

  * {
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    flex-flow: column;
  }
`;

export const StripeContainer = styled.div`
  width: 50%;
  padding: 8px 16px 8px 0px;
  display: flex;
  flex-flow: column;

  .stripe-inner-container {
    background-color: #f2f2f2;
    padding: 1rem 1.5rem;
    height: auto;
  }

  .icon-pay {
    display: inline-block;
    height: 1.49rem;
    line-height: 2rem;
    vertical-align: middle;
    margin-left: 0.8rem;
  }

  .stripe-error {
    color: rgb(105, 115, 134);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }

  label {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 8px 0px;
  }
`;

export const PaypalContainer = styled(StripeContainer)`
  padding: 8px 0 8px 16px;

  @media screen and (max-width: 768px) {
    padding: 8px 0px;
  }
`;

export const StripeForm = styled.form`
  width: 100%;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  border: 1px solid rgba(50, 50, 93, 0.1);

  .StripeElement {
    padding: 12px;
  }

  button {
    background: #5469d4;
    color: #ffffff;
    font-family: Arial, sans-serif;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 30px;
  }
`;
