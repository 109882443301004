import { useContext, useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import ServiceEn from './component/ServiceEn';
import ServiceKo from './component/ServiceKo';

const Service = () => {
  const [isSkip, setIsSkip] = useState(null);
  const { action } = useContext(LangContext);
  const [isMobile, setIsMobile] = useState(false);

  // 반응형 감지
  useEffect(() => {
    const handleResize = () => {
      // 너비가 768px 이하인 경우를 모바일로 판단
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // 초기 렌더링 시 한 번 실행

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  return (
    <Layout>
      <main id='main' className='fab-page-main fab--bg-gray'>
        <div id='main2'>
          {action.isKo() ? <ServiceKo isMobile={isMobile} /> : <ServiceEn isMobile={isMobile} />}
        </div>
      </main>
    </Layout>
  );
};

export default Service;
