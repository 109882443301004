import React from 'react';
import Slider from 'react-slick';

const slides = [
  { num: 1, name: 'Millennium FALCON' },
  { num: 2, name: 'WALL-E' },
  { num: 3, name: 'TIE interceptor' },
  { num: 4, name: 'Razor Crest' },
  { num: 5, name: 'Star Destroyer' },
];

function MainSlide() {
  const settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    variableWidth: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    dots: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className='invite-landing-main-slide'>
      <Slider {...settings}>
        {slides.map(slide => (
          <img
            key={slide.num}
            srcSet={`
            /assets/img/inviteLanding/main_slide_${slide.num}_mo.png  480w,
            /assets/img/inviteLanding/main_slide_${slide.num}.png    1920w
          `}
            src={`/assets/img/inviteLanding/main_slide_${slide.num}.png`}
            alt={slide.name}
          />
        ))}
      </Slider>
    </div>
  );
}

export default MainSlide;
