import styled from 'styled-components';

const FollowerUserProfile = ({ followList }) => {
  return (
    <>
      <FollowerUerProfileStyle className='FollowerUerProfileStyle'>
        {followList &&
          followList.map((followListData, index) => {
            return (
              <article key={index}>
                <img
                  src={
                    followListData.thumbnail === ''
                      ? '/assets/img/designers/follower_user_profile.png'
                      : followListData.thumbnail
                  }
                  alt=''
                />
                <h3>{followListData.name}</h3>
              </article>
            );
          })}
      </FollowerUerProfileStyle>
    </>
  );
};

export default FollowerUserProfile;

const FollowerUerProfileStyle = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 0fr));
  gap: 20px;
  justify-items: center;
  padding-bottom: 50px;

  article {
    width: 150px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 15px;
    img {
      width: 120px;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      margin-bottom: 15px;
      object-fit: fill;
    }

    h3 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      white-space: normal;
      word-wrap: break-word;
      text-align: center;
      color: #232323;
    }
  }

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding-bottom: 30px;

    article {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      max-width: 360px;
      padding: 6px;

      img {
        width: 48px;
        margin-bottom: 0px;
      }

      h3 {
        margin-left: 16px;
        white-space: normal;
        word-wrap: break-word;
        flex-grow: 1;
        overflow-wrap: break-word;
        word-break: break-all;
        text-align: left;
      }
    }
  }
`;
