import React, { useRef } from 'react';
import styled from 'styled-components';

const ManageImg = ({
  title,
  width,
  height,
  value,
  color,
  icon,
  size,
  imageChanged,
  index,
  setIndex,
  onClick,
  bordercolor,
  acceptTypes,
  multiple,
}) => {
  const fileInputRef = useRef(null);

  const handleImgClick = () => {
    fileInputRef.current.click();
    setIndex(index);
  };

  return (
    <Img onClick={value ? onClick : handleImgClick} color={color}>
      {index === 0 && <span className='required'>*</span>}

      <ManageImgBox
        width={width}
        height={height}
        color={color}
        icon={icon}
        size={size}
        bordercolor={bordercolor}
      >
        <input
          type='file'
          accept={acceptTypes}
          hidden
          ref={fileInputRef}
          onChange={imageChanged}
          multiple={multiple === 'detail'}
        />
        <img
          src={
            value ? '/assets/img/designers/add-slot.png' : '/assets/img/designers/bx-image-add.png'
          }
          className='icon'
        />
        <span className='add-img'>{value ? 'Add Slot' : 'Add Image'}</span>
      </ManageImgBox>
      <span className='img-title'>{title}</span>
    </Img>
  );
};

export default ManageImg;

const Img = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .img-title {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    color: ${props => props.color || '#000'};
  }

  .required {
    position: absolute;
    left: 8px;
    top: 8px;
    font-size: 30px;
    color: #ff0000;
  }
`;

const ManageImgBox = styled.div`
  width: ${props => props.width || '120px'};
  height: ${props => props.height || '120px'};
  border: 1px solid;
  border-color: ${props => props.bordercolor || '#000'};
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    width: ${props => props.icon || '30px'};
    height: ${props => props.icon || '30px'};
    margin-bottom: 3px;
  }

  .add-img {
    font-size: ${props => props.size || 14}px;
    font-weight: 400;
    color: ${props => props.color || '#000'};
    text-align: center;
  }
`;
