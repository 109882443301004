import React, { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import LicenseEn from './component/LicenseEn';
import LicenseKo from './component/LicenseKo';
import { MainContainer } from '../Term/Term';
const UserLicense = () => {
  const { action } = useContext(LangContext);
  return (
    <Layout>
      <MainContainer id='main'>{action.isKo() ? <LicenseKo /> : <LicenseEn />}</MainContainer>
    </Layout>
  );
};

export default UserLicense;
