import { useContext, useEffect, useState } from 'react';
import Options from '../../../component/option/Options';
import { STATUS_RELEASED_ENUM, STATUS_VOTING_ENUM } from '../../../component/request/constant';
import ModalContext from '../../../context/modal/modal';
import UserContext from '../../../context/user/user';
import { MY_REQUEST_WRITE } from '../../../route/constant';
import Pagination from '../../Category/component/Pagination';
import { LISTING_SORT_NEWEST, LISTING_SORT_VOTE_HIGH } from './constant';
import RequestList from './RequestList';
import RequestReleased from './RequestList';
import './RequestMain.css';
import { getRequests } from '../../../action/request';
import { ls } from '../../../constant';
import { useNavigate } from 'react-router-dom';

const RequestMain = () => {
  const [sort, setSort] = useState(localStorage.getItem(ls.requestSort) || 'newest');
  const [status, setStatus] = useState(localStorage.getItem(ls.requestStatus) || '');
  const [search, setSearch] = useState(localStorage.getItem(ls.requestSearch) || '');
  const [tempSearch, setTempSearch] = useState(localStorage.getItem(ls.requestSearch) || '');
  const [page, setPage] = useState(Number(localStorage.getItem(ls.requestPage)) || 1);
  const [total, setTotal] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [requests, setRequests] = useState([]);
  const { state: userState } = useContext(UserContext);
  const { action } = useContext(ModalContext);
  const [releaseds, setReleaseds] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [page, sort, status, search]);

  const fetchData = async () => {
    try {
      const {
        data: { data: res },
      } = await getRequests({ page, sort, status, search });
      setTotal(res.paging.count);
      setPageSize(res.paging.size);
      setRequests(res.requests);
      setReleaseds(res.released);
    } catch (e) {}
  };

  const pageChange = val => {
    setPage(val);
    localStorage.setItem(ls.requestPage, val);
  };

  const pageConfirm = val => {
    setPage(val);
    localStorage.setItem(ls.requestPage, val);
  };

  const sortChange = val => {
    setSort(val);
    pageChange(1);
    localStorage.setItem(ls.requestSort, val);
  };

  const statusChange = val => {
    setStatus(val);
    pageChange(1);
    localStorage.setItem(ls.requestStatus, val);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchChange(e);
      pageChange(1);
    }
  };

  const searchChange = e => {
    e.preventDefault();
    e.stopPropagation();

    setSearch(tempSearch);
    localStorage.setItem(ls.requestSearch, tempSearch);
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='page-top request page-top-with-breadcrumb'>
        <div className='container container-lg'>
          <h1 className='page-title'>Request</h1>
        </div>
      </div>
      <section className='story-section'>
        <div className='container'>
          <div className='story-best-request'>
            <div className='content-section-header'>
              <h2>
                <strong>Released</strong> Request
              </h2>
            </div>
            <div className='post-list post-list-4' id='bestRequestPost'>
              <div className='list clearfix'>
                <RequestList data={releaseds} isSlide={true} />
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className='container'>
          <div className='request-cta'>
            <p>Do you have a design you need?</p>
            {userState.id && (
              <a
                href=''
                onClick={e => goTo(e, MY_REQUEST_WRITE)}
                className='btn btn-green btn-fill'
              >
                Upload my request
              </a>
            )}
            {!userState.id && (
              <a
                className='btn btn-green btn-fill need-login'
                onClick={() => action.setIsLogin(true)}
              >
                Upload my request
              </a>
            )}
          </div>

          <div className='request-list'>
            <div className='content-section-header'>
              <h2>
                <strong>Voting</strong> Request
              </h2>
            </div>
            <div className='request-list-header'>
              <form id='request-listing' method='GET' action=''>
                <input type='hidden' name='page' value='' />

                <input
                  type='text'
                  name='search'
                  placeholder='Search requests..'
                  value={tempSearch}
                  className='request-search'
                  onChange={e => setTempSearch(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <a
                  href='#'
                  data-action='request-search'
                  className='centered-svg request-search'
                  onClick={searchChange}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20.46 20.46'
                    className='icon-search'
                    style={{ width: '1.525rem', height: '1.75rem' }}
                  >
                    <title>search</title>
                    <path
                      className='line'
                      d='M8.09.64A7.45,7.45,0,1,1,.64,8.09,7.45,7.45,0,0,1,8.09.64Z'
                      fill='#fff'
                      stroke='#000'
                    ></path>
                    <line
                      className='line'
                      x1='13.35'
                      y1='13.35'
                      x2='19.55'
                      y2='19.55'
                      stroke='#000'
                    ></line>
                  </svg>
                </a>
                <Options
                  value={sort}
                  onChange={sortChange}
                  option={[
                    {
                      value: LISTING_SORT_NEWEST,
                      text: 'Newest',
                    },
                    {
                      value: LISTING_SORT_VOTE_HIGH,
                      text: 'MostRequest',
                    },
                  ]}
                />
                <Options
                  value={status}
                  onChange={statusChange}
                  option={[
                    {
                      value: '',
                      text: 'All Request',
                    },
                    {
                      value: STATUS_RELEASED_ENUM,
                      text: 'Released',
                    },
                    {
                      value: STATUS_VOTING_ENUM,
                      text: 'In Voting',
                    },
                  ]}
                />
              </form>
            </div>

            <div className='request-list-body'>
              <div className='post-list post-list-4'>
                <div className='list clearfix'>
                  <RequestList data={requests} />
                </div>
              </div>
              <Pagination
                page={page}
                total={Math.ceil(total / pageSize)}
                onpagechange={pageChange}
                onconfirm={pageConfirm}
              />
              {/* <!--pagination-->
              <?php
              $args = [
                "sort" => $sort,
                "search" => $search,
                "status" => $status,
              ];
              echo PagingRenderer::render($paging, $pagingUrl, $args);
              ?> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestMain;
