import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const ClearSession = () => {
  const location = useLocation();
  const excludedPaths = useMemo(() => ['/sns/signup', '/landing'], []);

  useEffect(() => {
    const handlePathChange = () => {
      const currentPath = location.pathname;
      // 현재 경로
      // console.log(currentPath);

      // excludedPaths에 등록된 경로를 제외하고 모든 경로에서 sessionStorage 비우기
      if (!excludedPaths.includes(currentPath)) {
        sessionStorage.clear();
        // console.log('sessionStorage cleared.');
      }
    };
    handlePathChange();
  }, [excludedPaths, location]);

  return null;
};

export default ClearSession;
