function calculateDiscountedPrice(originalPrice, discountRate) {
  if (originalPrice === 0 && discountRate === 0) {
    return '0.00';
  }

  if (originalPrice || discountRate) {
    var subTotal = (originalPrice * discountRate) / 100;
    subTotal = Math.floor(subTotal * 100) / 100;
    var finalPrice = Number(originalPrice) - subTotal;

    finalPrice = finalPrice.toString();

    const decimalIndex = finalPrice.indexOf('.');

    if (decimalIndex === -1) {
      finalPrice += '.00';
    } else {
      // 소수점이 있을 경우
      const decimalPart = finalPrice.substring(decimalIndex + 1);
      if (decimalPart.length > 2) {
        finalPrice = finalPrice.substring(0, decimalIndex + 3);
      } else if (decimalPart.length === 1) {
        finalPrice += '0';
      }
    }

    return finalPrice;
  }
}

const descriptionChange = value => {
  if (!value) return '';
  const parser = new DOMParser();
  const dom = parser.parseFromString(value, 'text/html'); // 받은 문자열을 html 형식으로 변경
  const parserText = dom.body.textContent || ''; // 이때 &amp; 같은 모든 엔티티 문자열이 &으로 변경됩니다!
  return parserText
    .replace(/<br\s*\/?>/gi, '')
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\r\n/g, '<br>')
    .replace(/\n/g, '<br>')
    .replace(/<br>/g, '')
    .toLowerCase()
    .replace(/\s+/g, '');
};

export function createCompareObj(data, type) {
  //중복된 Key 값
  const obj = {
    status: data?.status,
    name: data?.name,
    printingTime: data?.print_time || '',
    totalWeight: data?.total_weight || '',
    partSizeX: data?.lpart_size_x || data?.part_size_x || '',
    partSizeY: data?.lpart_size_y || data?.part_size_y || '',
    partSizeZ: data?.lpart_size_z || data?.part_size_z || '',
    idCategory: data?.category_name ? data?.category_name : '',
    description: descriptionChange(data?.description),
    listImg: data?.list_img || data?.listImg || '',
    listImg2: data?.list_img2 === 'false' ? '' : data?.list_img2 || data?.listImg2 || '',
    files: data?.files?.map(item => item.file_url),
  };

  if (type === 'initialValue') {
    const initialObj = {
      finalPrice: calculateDiscountedPrice(
        Number(data?.original_price),
        Number(data?.discount_rate)
      ),
      tags: data?.tags?.replace(/\s+/g, ''),
      detailImg: data?.detail_img?.map(item => item.img),
      ...obj,
    };
    return initialObj;
  } else if (type === 'changeValue') {
    const changeObj = {
      finalPrice: data?.finalPrice,
      tags: data?.tags
        .filter(item => item.isValid)
        .map(item => item.value)
        .join(',')
        .trim()
        .replace(/\s+/g, ''),
      detailImg: data?.detailImg.filter(item => item.value !== 'add').map(item => item.img),
      ...obj,
    };
    return changeObj;
  } else if (type === 'updateValue') {
    const updateObj = {
      finalPrice: data?.final_price === '0.00' ? '' : data?.final_price,
      tags: data?.tags?.replace(/\s+/g, ''),
      detailImg: data?.detail_img?.map(item => item.img),
      ...obj,
    };
    return updateObj;
  }
}
