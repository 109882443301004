export const calculateDiscountPrice = (
  originalPrice,
  discountLimit,
  discountAmount,
  setIsLimit
) => {
  let discount = (originalPrice * discountAmount) / 100;
  let discountRate = discountAmount;
  setIsLimit(false);

  if (discountLimit == 0) {
  } else if (discountAmount === 0) {
    discount = Math.min(discountLimit, originalPrice);
    discountRate = discount;
  } else if (discount > discountLimit) {
    discount = discountLimit;
    setIsLimit(true);
  }

  discount = (Math.floor(discount * 100) / 100).toFixed(2);

  return { discount, discountRate };
};

export function moneyFormatter(money) {
  function removeZero(money) {
    const trimmed = money.replace(/^0+/, '');
    return trimmed ? trimmed : 0;
  }

  let fixed = money.replace(/[^0-9.]/gi, '');

  if (isNaN(fixed) || !fixed) return 0;

  if (fixed.indexOf('.') < 0) return removeZero(fixed);

  const usd = fixed.split('.');
  fixed = removeZero(usd[0]) + '.' + usd[1].substr(0, 2);

  return fixed;
}

export const calculateSub = function (a, b, digit = 2) {
  const aFix = parseFloat(a).toFixed(digit);
  const bFix = parseFloat(b).toFixed(digit);
  const sub = aFix - bFix;

  return sub.toFixed(digit);
};

export function renderAvailableMileage(myPoint, mileage) {
  const diff = calculateSub(myPoint, mileage, 0);
  if (diff >= 0) {
    return diff;
  } else {
    return 0;
  }
}
