import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Pagination = ({ page, total, onpagechange, onconfirm }) => {
  const [current, setCurrent] = useState(page);
  const inputRef = useRef();

  const isItemDetailPage = useLocation().pathname.includes('/items');
  const prevClick = () => {
    onpagechange(Number(page) - 1);
  };
  const nextClick = () => {
    onpagechange(Number(page) + 1);
  };
  const currentChange = e => {
    let value = e.target.value;
    setCurrent(value);
  };

  useEffect(() => {
    // 아이템 페이지에서는 스크롤 변동 없음 (탭 이동)
    if (isItemDetailPage) {
      return;
    }
  }, [page]);

  const pageEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      let value = e.target.value;
      if (/^\d*$/.test(value)) {
        value = value === '' ? 1 : value;
        value = value > total ? total : value;
        onconfirm(value);
        setCurrent(value);
      }
    }
  };

  useEffect(() => {
    setCurrent(page);
    // 페이지 이동 시 페이지 Input 포커스 해제
    inputRef.current.blur();
  }, [page]);

  return (
    <>
      <div className='pagination'>
        <form>
          <a
            className='btn-prev'
            onClick={prevClick}
            style={{ visibility: page < 2 ? 'hidden' : '' }}
          >
            prev
          </a>
          <span className='pages'>
            <input
              name='page'
              value={current}
              // value={page}
              className='current'
              onChange={currentChange}
              onKeyDown={pageEnter}
              ref={inputRef}
              style={{ width: '7.78125px', background: '#fff' }}
            />
            &nbsp;of <span className='total'>{!!total ? total : 1}</span>
          </span>
          <a
            className='btn-next'
            onClick={nextClick}
            style={{ visibility: page >= total ? 'hidden' : '' }}
          >
            next
          </a>
        </form>
      </div>
    </>
  );
};

export default Pagination;
