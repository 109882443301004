import React, { useContext, useEffect, useRef, useState } from 'react';
import { Header, HeaderBg, Img } from '../designers';
import { getDesignerFollow, getDesignerInfoId, getDesignerUnFollow } from '../../../action/request';

import UserContext from '../../../context/user/user';
import ModalContext from '../../../context/modal/modal';
import { useNavigate } from 'react-router-dom';
import { followIdAtom, isMobileAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';
import DesignersMobileHeader from './DesignersInfo';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const OnlyDesignersHeader = ({
  data,
  isFollow,
  setIsFollow,
  DesignerSectionPropsStyle,
  index,
  designerSection,
  setFollowId,
  value,
}) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { action: modalAction } = useContext(ModalContext);
  const { state: userData } = useContext(UserContext);
  const location = useLocation();
  const url = location.pathname;
  const isLogin = userData?.isLogin;
  const textRef = useRef(null);
  const studioId = Number(data?.id_studio);
  const isApp = userData?.isApp;
  const [designerFollow, setDesignerFollow] = useState();
  const [, setdesignerId] = useRecoilState(followIdAtom);
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);

  const navigate = useNavigate();

  function hasNameAfterDesigners(url) {
    const basePath = '/designers';
    return url.startsWith(basePath) && url.length > basePath.length;
  }

  const isDesignerPage = hasNameAfterDesigners(url);

  const onClickRead = () => setIsReadMore(!isReadMore);

  const onClickFollow = async e => {
    if (!isLogin) {
      modalAction.setIsLogin(true);
      return;
    }

    const action = e.target.id === 'follow' ? getDesignerFollow : getDesignerUnFollow;
    const res = await action(studioId);

    if (res.data.returnCode === 'C00000') {
      const isNowFollowing = e.target.id === 'follow';
      setIsFollow(isNowFollowing);
      setFollowId?.({ id: studioId, isFollow: isNowFollowing });
      setdesignerId({ id: studioId, isFollow: isNowFollowing });
    }

    fetchData(index);
  };

  const fetchData = async () => {
    const { data: designerData } = await getDesignerInfoId({ isLogin, studioId });
    setDesignerFollow(designerData.data.follow_count);
  };

  useEffect(() => {
    if (!isLogin) setIsFollow(false);
  }, [isLogin]);

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(textRef.current.scrollHeight >= 75);
      }
      setIsMobile(window.innerWidth < 600);
    };

    checkTextOverflow();
    window.addEventListener('resize', checkTextOverflow);

    return () => {
      window.removeEventListener('resize', checkTextOverflow);
    };
  }, []);

  const designersPageM =
    isDesignerPage && isMobile ? '40px 0px 0px 0px' : DesignerSectionPropsStyle?.headerPadding;

  return (
    <DesignersPageHeader>
      <button className='viewDesignerList' onClick={() => navigate('/designers')}>
        View Designer List
      </button>
    </DesignersPageHeader>
  );
};

export default OnlyDesignersHeader;

const DesignersPageHeader = styled.div`
  padding: 16px 0px 50px 50px;
  background-image: url('/assets/img/designers/designerBg.png');
  height: 330px;
  background-repeat: no-repeat;

  .viewDesignerList {
    color: #fff;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 18px;
    font-size: 16px;
    font-family: 'Inter';

    &:before {
      display: inline-block;
      content: '';
      background-image: url('/assets/img/designers/arrow_back_ios.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 7px;
      height: 10px;
      margin-right: 6px;
    }
  }
`;
