import { OPTION_ITEM_ALL, OPTION_ITEM_ONLYFAB365, OPTION_ITEM_GENERAL } from './constant';

const SelectType = ({ value, onchange }) => {
  const select = e => {
    onchange(e.target.value);
  };
  return (
    <>
      <select className='form-input type' onChange={select} value={value}>
        <option value={OPTION_ITEM_ALL}>All</option>
        <option value={OPTION_ITEM_ONLYFAB365}>Fab365 Only</option>
        <option value={OPTION_ITEM_GENERAL}>General</option>
      </select>
    </>
  );
};

export default SelectType;
