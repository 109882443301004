import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twitterLogin } from '../../action/request';
import { Ss, ls } from '../../constant';
import UserContext from '../../context/user/user';
import { ITEMS, SNS_SIGNUP } from '../../route/constant';
import { gtagOnClickLogin } from '../../action/gTag';
import { RETURN_CODE } from '../../action/constant';
import { useRecoilValue } from 'recoil';
import { isWelcomeModal } from '../../atom/atom';
import { useCookies } from 'react-cookie';

function TwitterCallback() {
  const [cookies] = useCookies(['autoLogin']);
  const isWelcomeModalValue = useRecoilValue(isWelcomeModal);
  const navigate = useNavigate();
  const location = useLocation();
  const { action: userAction, state: userState } = useContext(UserContext);
  const [oauth, setOauth] = useState({ oauth_token: undefined, oauth_verifier: undefined });

  // token과 verifier 가져오기
  const getToken = async () => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationToken = urlParams.get('oauth_token');
    const authorizationVerifier = urlParams.get('oauth_verifier');
    if (authorizationVerifier && authorizationToken) {
      setOauth({
        oauth_token: authorizationToken,
        oauth_verifier: authorizationVerifier,
      });
    }
    // console.log(oauth);
  };

  // token과 verifier로 유저 정보 가져오기
  const getUserInfo = async token => {
    try {
      // const from = userState.isApp ? 'M' : 'W';
      const from = userState.isApp ? 'M' : cookies.autoLogin ? 'A' : 'W';
      const { data: res } = await twitterLogin(oauth.oauth_token, oauth.oauth_verifier, from);
      // console.log('callback res :::', res);
      if (res.message === 'social token error') {
        return navigate('/');
      }

      // block된 유저일 경우
      if (res.message !== 'social token error' && res.returnCode === RETURN_CODE.C00008) {
        alert(res.message);
        return navigate('/');
      }

      const userInfo = res.data.userInfo;
      const userToken = res.data.token;

      // console.log('google get User 요청 완료');

      // 로그인 처리
      if (userInfo) {
        // token을 localStorage에 저장
        localStorage.setItem(ls.id, userInfo.id_uesr);
        localStorage.setItem(ls.accessToken, userToken.access_token);
        localStorage.setItem(ls.refreshToken, userToken.refresh_token);
        // userInfo context에 저장
        userAction.setEmail(userInfo.email);
        userAction.setId(userInfo.id_uesr);
        userAction.setIsLogin(true);
        userAction.setThumbnail(userInfo.thumbnail);
        userAction.setIsLogin(false);
        if (sessionStorage.getItem(Ss.itemNumber)) {
          navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
          sessionStorage.removeItem(Ss.itemNumber);
        }
        if (isWelcomeModalValue) {
          navigate('/');
          localStorage.removeItem(ls.loginPath);
        } else {
          navigate(localStorage.getItem(ls.loginPath));
          localStorage.removeItem(ls.loginPath);
        }
        //
        // else {
        //   // 로그인하면 로컬스토리지에 저장 된 기존에 있던 위치로 이동
        //   navigate(localStorage.getItem(ls.loginPath));
        //   // 로그인 위치로 이동 후 로그인 위치 정보 삭제
        //   localStorage.removeItem(ls.loginPath);
        // }
        //
        // gtag 트위터 로그인 추적 태그
        gtagOnClickLogin('Twitter');
      }
      // 이메일 null 이면 홈으로 이동
      if (res.data.email === '' || res.data.email === null) {
        // TODO : 문구 컨펌받기
        alert(
          'You must set up an email in your Twitter account before you can join. For more information, please contact Fab365.'
        );
      }
      // 회원가입 처리
      if (res.data.id_user === null) {
        // console.log('회원가입');
        // user context에 data 저장
        userAction.setSnsUserInfo(res.data);
        // console.log('트위터 회원가입', res.data);
        navigate(SNS_SIGNUP);
      }
    } catch (error) {
      console.error(error);
      navigate('/');
      alert('Request failed.');
    }
  };

  useEffect(() => {
    getToken();
  }, [location.search, navigate]);

  useEffect(() => {
    if (oauth.oauth_token !== undefined && oauth.oauth_verifier !== undefined) {
      getUserInfo();
    }
  }, [oauth]);

  return null;
}

export default TwitterCallback;
