import * as DOMPurify from 'dompurify';

const TipFeature = ({ img, subject, body, date, link }) => {
  return (
    <>
      <div className='featured-story-item'>
        <div className='tb'>
          {link !== null ? (
            <>
              <a href={link}>
                <img src={img} alt={subject} />
              </a>
            </>
          ) : (
            <>
              <img src={img} alt={subject} />
            </>
          )}
        </div>
        <div className='featured-story-desc'>
          <h3 className='story-title'>{subject}</h3>
          <p
            className='story-excerpt'
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
          ></p>
          <span className='story-date'>{date}</span>
          {link !== null ? (
            <>
              <a href={link} className='btn btn-sm'>
                Learn more
              </a>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default TipFeature;
