import React, { useContext } from 'react';
import styled from 'styled-components';
import { ls } from '../../../constant';
import { useState } from 'react';
import { useEffect } from 'react';
import CartContext from '../../../context/cart.js/carts';
import UserContext from '../../../context/user/user';
import { useNavigate } from 'react-router-dom';
import { MY_CART } from '../../../route/constant';
import ModalContext from '../../../context/modal/modal';

function ToTheTop() {
  const { state: cartState } = useContext(CartContext);
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);

  const navigate = useNavigate();

  const [cartsCount, setCartsCount] = useState(Number(localStorage.getItem(ls.cartsCount)) || 0);

  useEffect(() => {
    if (userState.isLogin) setCartsCount(Number(localStorage.getItem(ls.cartsCount)) || 0);
    else setCartsCount(0);
  }, [cartState.cartsCount, userState.isLogin]);

  const goToCart = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      navigate(MY_CART);
    }
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container>
      <button className='cartBtn' onClick={goToCart}>
        <span className='badge'>{cartsCount}</span>
      </button>
      <div className='line'></div>
      <button className='toTheTopBtn' onClick={goToTop}></button>
    </Container>
  );
}

export default ToTheTop;

const Container = styled.div`
  border: 1px solid #8b8b8b;
  border-radius: 4px;
  width: 72px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 19px 25px 11px;
  background-color: #ffffff;
  z-index: 10;
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border-box;

  button {
    border: none;
    cursor: pointer;
  }

  .cartBtn {
    background: url('/assets/img/2.0WebImg/ui/icon_cart_bk.svg') left no-repeat;
    background-size: 24px 24px;
    position: relative;
    width: 24px;
    height: 24px;
    display: block;
  }

  .toTheTopBtn {
    background: url('/assets/img/2.0WebImg/ui/arrow_top.svg') left no-repeat;
    background-size: 32px 32px;
    width: 32px;
    height: 32px;
  }

  .line {
    margin: 11px 0;
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
  }

  .badge {
    text-indent: 0;
    position: absolute;
    background: #28ce40;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    top: -5px;
    right: -2px;
    color: #ffffff;
    z-index: 10;
  }
`;
