/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { getUserInfo } from '../../../action/request';
import {
  MY_DOWNLOADS,
  MY_REWARD_TICKETS,
  MY_LIKE,
  MY_ORDER,
  MY_CREDIT,
  MY_PROFILE,
  MY_REQUEST,
  MY_REVIEW,
  MY_SALES,
} from '../../../route/constant';
import {
  TAB_DOWNLOAD,
  TAB_REWARD_TICKET,
  TAB_LIKE,
  TAB_MY_SALES,
  TAB_ORDER,
  TAB_CREDIT,
  TAB_PROFILE,
  TAB_REQUEST,
  TAB_REVIEW,
} from './constant';
import { useNavigate } from 'react-router-dom';

const MyTab = ({ tab }) => {
  const [isDesigner, setIsDesigner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // 유저 정보 가져오기 (디자이너 정보)
    try {
      const { data: userInfoRes } = await getUserInfo();
      // console.log('userInfoRes >>', userInfoRes);
      if (userInfoRes.returnCode === 'C00000') {
        // console.log('디자이너 여부 ', userInfoRes.data.designer);
        setIsDesigner(userInfoRes.data.designer);
      }
    } catch (e) {}
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='tab-menu'>
        <div className='container'>
          <ul>
            <li className={tab === TAB_PROFILE ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_PROFILE)}>
                <span>Profile</span>
              </a>
            </li>
            <li className={tab === TAB_ORDER ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_ORDER)}>
                <span>Order</span>
              </a>
            </li>
            <li className={tab === TAB_DOWNLOAD ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_DOWNLOADS)}>
                <span className='qlabel' its-ta-ident-ref='http://www.wikidata.org/entity/Q7126717'>
                  Download
                </span>
              </a>
            </li>
            <li className={tab === TAB_LIKE ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_LIKE)}>
                <span>Like</span>
              </a>
            </li>
            <li className={tab === TAB_REVIEW ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_REVIEW)}>
                <span>Review</span>
              </a>
            </li>
            <li className={tab === TAB_REQUEST ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_REQUEST)}>
                <span>Request</span>
              </a>
            </li>
            <li className={tab === TAB_CREDIT ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_CREDIT)}>
                <span>Credit</span>
              </a>
            </li>
            <li className={tab === TAB_REWARD_TICKET ? 'active' : ''}>
              <a href='' onClick={e => goTo(e, MY_REWARD_TICKETS)}>
                <span>Reward ticket</span>
              </a>
            </li>
            {/* {isDesigner && (
              <li className={tab === TAB_MY_SALES ? 'active' : ''}>
                <a href='' onClick={e => goTo(e, MY_SALES)}>
                  <span>My sales</span>
                </a>
              </li>
            )} */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MyTab;
