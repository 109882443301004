// Layout section
export const FOOTER_ABOUT = '/about';
export const FOOTER_CONTACT = '/contact';
export const FOOTER_COPYRIGHT = '/copyright';
export const FOOTER_DESIGNER = '/designer';
export const FOOTER_HELP = '/help';
export const FOOTER_LICENSE = '/license';
export const FOOTER_PRINT_TIPS = '/print-tips';
export const FOOTER_PRIVACY = '/privacy';
export const FOOTER_TERMS = '/terms';
export const FOOTER_USER_LICENSE = '/user-license';
export const FOOTER_TERMS_POINT = '/terms-point';
export const FOOTER_CONTACT_BUSINESS = '/contact/business';
export const SNS_FACEBOOK = '/sns/facebook';
export const SEARCH = '/search';
export const MAIN = '/';

//About section
export const ABOUT_SERVICE = '/about/service';
export const ABOUT = '/about';
export const ABOUT_PHILOSOPHY = '/about/philosophy';
export const ABOUT_CEO = '/about/ceo';
export const ABOUT_CO_CEO = '/about/co-ceo';

// Tip section
export const PRINT_TIPS_LIST = '/print-tips-list';
export const PAGEID_HELP_14013138301_BROKEN_LIVING_HINGE = '/pages/14013138301';
export const PAGEID_HELP_14013138302_MOVING_PARTS_FALL = '/pages/14013138302';
export const PAGEID_HELP_14013138303_PARTS_NOT_MOVE = '/pages/14013138303';
export const PAGEID_HELP_14013138304_BED_TYPES = '/pages/14013138304';
export const PAGEID_HELP_14013138305_BED_GOOD = '/pages/14013138305';
export const PAGEID_HELP_14013138306_BED_PEI = '/pages/14013138306';

// HELP section
export const HELP = '/help';
export const PAGES_HELP_REVIEW = '/pages-help-review';
export const PAGES_HELP_FREE_TICKET = '/pages-free-ticket';
export const HELP_HOW_TO_DOWNLOAD_MODEL_FILES = '/help/63';
export const HELP_HOW_TO_DOWNLOAD_SLICER_SETTING = '/help/43';
export const HELP_HOW_TO_IMPORT_FAB365_CURA_PROFILE = '/help/29';
export const HELP_SLICER_SETTINGS = '/help/settingList';
export const HELP_POINT_HOW_TO_USE = '/help/31';

export const CATEGORIES = '/categories';
export const COLLECTIONS = '/collections';
export const DESIGNERS = '/designers';
export const SETITEMS = '/set-items';
export const ITEMS = '/items';
export const VEHICLES_MACHINERY = CATEGORIES + '/Vehicles & Machinery';
export const MILITARY = CATEGORIES + '/Military';
export const SPACE = CATEGORIES + '/Space';
export const ROBOT = CATEGORIES + '/Robot';
export const TOY_GAME = CATEGORIES + '/Toys & Games';
export const CHARACTERS = CATEGORIES + '/Characters';
export const CREATURES = CATEGORIES + '/Creatures';
export const HOUSEHOLD = CATEGORIES + '/Household';
export const FASHION_COSTUME_PROPS = CATEGORIES + '/Fashion & Costume&Props';
export const LANDSCAPE_BUILDINGS = CATEGORIES + '/Landscape & Buildings';
export const HOBBY_TOOLS_GADGET = CATEGORIES + '/Hobby & Tools & Gadget';
export const ART_DESIGN = CATEGORIES + '/Art & Design';

export const REVIEW = '/reviews';
export const REQUESTS = '/requests';

export const MY_PROFILE = '/my/profile';
export const MY_ORDER = '/my/orders';
export const MY_LIKE = '/my/likes';
export const MY_CREDIT = '/my/credit';
export const TERMS_POINT = '/terms-point';
export const MY_DOWNLOADS = '/my/downloads';
export const MY_REQUEST = '/my/requests';
export const MY_REQUEST_WRITE = '/my/requests/write';
export const MY_REQUEST_EDIT = '/my/requests/edit';
export const MY_REVIEW = '/my/reviews';
export const MY_REVIEW_WRITE = '/my/reviews/write';
export const MY_REVIEW_EDIT = '/my/reviews/edit';
export const MY_REWARD_TICKETS = '/my/reward_tickets';
export const MY_SALES = '/my/sales';
export const MY_SALES_PRODUCTS = '/my/sales/products';
export const MY_SALES_PRODUCT = '/my/sales/product';
export const MY_SALES_DAILY = '/my/sales/daily';

export const MY_CART = '/my/carts';

// checkout

export const CHECKOUT_STEP1 = '/checkout-step1';
export const CHECKOUT_STEP2 = '/checkout-step2';
export const CHECKOUT_SUCCESS = '/checkout';
export const CHECKOUT_FREE = '/checkout_free';

// sns signup & login
export const APPLE_CALLBACK = '/sns/apple/redirect';
export const TWITTER_CALLBACK = '/sns/twitter/redirect';
export const GITHUB_CALLBACK = '/sns/github/redirect';
export const GOOGLE_CALLBACK = '/sns/google/callback';
export const YAHOO_CALLBACK = '/sns/yahoo/redirect';
export const FACEBOOK_CALLBACK = '/sns/facebook/redirect';
export const SNS_SIGNUP = '/sns/signup';
export const RESET_PASSWORD = '/reset_password';

// invite & landing
export const LANDING = '/landing';
export const INVITE = '/invite';

// verify
export const VERIFY = '/email-verify';

// notice
export const NOTICE = '/notice';
export const TEST = '/test';

// not found > 코드 맨 밑에 있어야 함
export const NOT_FOUND = '/*';

// 디자이너 전용페이지
export const ONLYDESIGNERS = '/onlydesigners';

// 디자이너 신청 페이지 생성
export const APPLYDESIGNERFORM = '/apply-designer';

// 모티링크 로그인 페이지
export const APPFAB365LOGIN = '/appdesigner-login';

// 디자이너 전용페이지(APP)
export const APPDESIGNERS = '/appdesigners'
