export const ls = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  id: 'id',
  requestSort: 'requestSort',
  requestStatus: 'requestStatus',
  requestPage: 'requestPage',
  requestSearch: 'requestSearch',
  loginPath: 'loginPath',
  totalAmount: 'totalAmount',
  isAgree: 'isAgree',
  chargePoint: 'chargePoint',
  cartsCount: 'cartsCount',
  scrollY: 'scrollY',
  itemPage: 'itemPage',
  itemSort: 'itemSort',
  itemType: 'itemType',
  prevScrollY: 'prevScrollY',
  specialDesignersPage: 'specialDesignersPage',
  designerItemListPage: 'designerItemListPage',
  collectionPage: 'collectionPage',
  collectionListPage: 'collectionListPage',
};

export const Ss = {
  inviteToken: 'inviteToken',
  itemNumber: 'itemNumber',
};
