import Layout from "../../component/layout/Layout";

const FreeTicket = () => {
  return (
    <>
      <Layout>
        <div id="main">
          <div className="container">
            <div className="terms">
              <div className="terms-header">
                <h1 className="terms-title">FREE ITEM - DOWNLOAD TICKET</h1>
              </div>
              <div className="terms-body">
                <div className="term-block">
                  <h3>Q. What is the FREE ITEM - DOWNLOAD TICKET?</h3>
                  <p>
                    A. A FREE ITEM - DOWNLOAD TICKET is a Coupon for downloading
                    free items.
                  </p>
                </div>

                <div className="term-block">
                  <h3>Q. How can I get the FREE ITEM - DOWNLOAD TICKET?</h3>
                  <p>
                    A. You can get 1 FREE ITEM - DOWNLOAD TICKET right after
                    signing up for Fab365. and you can get 1 FREE ITEM -
                    DOWNLOAD TICKET right after buying a paid item, too.
                  </p>
                </div>

                <div className="term-block">
                  <h3>Q. How many FREE ITEM - DOWNLOAD TICKETS can I get?</h3>
                  <p>
                    A. You can get FREE ITEM - DOWNLOAD TICKETS for the number
                    of purchases.
                  </p>
                </div>

                <div className="term-block">
                  <h3>
                    Q. What is the validity period of the FREE ITEM - DOWNLOAD
                    TICKET?
                  </h3>
                  <p>
                    A. The issued FREE ITEM - DOWNLOAD TICKET is valid for 6
                    months.
                  </p>
                </div>

                <div className="term-block">
                  <h3>Q. What can I buy with a FREE ITEM - DOWNLOAD TICKET?</h3>
                  <p>
                    A. A FREE ITEM - DOWNLOAD TICKET can only be used for FREE
                    ITEMS and cannot used for paid item.
                  </p>
                </div>

                <div className="term-block">
                  <h3>
                    Q. Can I get the FREE ITEM - DOWNLOAD TICKET by buying a
                    paid item with Fab365 POINT?
                  </h3>
                  <p>
                    A. Yes, you can get it because Fab365 POINT is same with
                    cash in Fab365.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FreeTicket;