export const getInputs = (
  isFresPlaceholder,
  isFree,
  priceUSD,
  discountRate,
  calculateDiscountedPrice
) => [
  {
    type: 'number',
    label: 'Price USD',
    placeholder: isFresPlaceholder,
    id: 'priceUSD',
    value: isFree ? '' : priceUSD,
    name: 'priceUSD',
    symbol: '$',
    disabled: isFree,
    backgroundColor: isFree ? '#DFDFDF' : '',
  },
  {
    type: 'number',
    label: 'Discount Rate',
    placeholder: isFresPlaceholder,
    id: 'discountRate',
    value: isFree ? '' : discountRate,
    name: 'discountRate',
    symbol: '%',
    disabled: isFree,
    backgroundColor: isFree ? '#DFDFDF' : '',
  },
  {
    type: 'text',
    label: 'Final Price',
    placeholder: isFree ? 'None' : 'Auto-calculate',
    id: 'finalPrice',
    value: isFree ? '' : calculateDiscountedPrice(priceUSD, discountRate) || '',
    name: 'finalPrice',
    symbol: '$',
    disabled: true,
    backgroundColor: '#DFDFDF',
  },
];
