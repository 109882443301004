const ProfileSlicerOptions = ({ data, selected, onChange }) => {
  return (
    <>
      <div className="form-group">
        <label for="userConfirmPassword" className="form-label">
          Slicer
        </label>
        <div className="form-control">
          <select
            className="form-input"
            name="slicer"
            value={selected}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="0">Select your slicer {">>>"}</option>
            {data?.map((s) => {
              return (
                <option value={s.id_slicer} key={s.id_slicer}>
                  {s.slicer}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
};

export default ProfileSlicerOptions;
