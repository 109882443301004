import ItemSlide from '../../component/slider/ItemSlide';
import ItemComment from '../Item/component/ItemComment';
import RequestComment from './component/RequestComment';
import RequestDescription from './component/RequestDescription';
import RequestSlide from './component/RequestSlide';
import { useContext, useState, useEffect } from 'react';
import { getRequest } from '../../action/request';
import { RETURN_CODE } from '../../action/constant';
import { useNavigate } from 'react-router-dom';
import { REQUESTS } from '../../route/constant';
import { formatHrefLink } from '../../util/comment';
import ReadQuill from '../../component/editor/ReadQuill';

const RequestDetail = ({ oid, subject, setShowImgViewer, setImgData }) => {
  const [request, setRequest] = useState([]);
  const [commentGroups, setCommentGroups] = useState([]);
  const [commentReplys, setCommentReplys] = useState([]);
  const [handleFlag, setHandleFlag] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [htmlContent, setHtmlContent] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [page, handleFlag]);

  const fetchData = async () => {
    try {
      const { data } = await getRequest({ oid, subject });

      if (data.returnCode !== RETURN_CODE.C00000) {
        navigate(REQUESTS);
        return;
      }
      const body = data.data.request.body;

      const decodeHtml = html => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      };

      const decodedHtml = decodeHtml(body);
      setHtmlContent(decodedHtml);
      setRequest(data.data.request);
      setCommentGroups(data.data.commentGroups);
      setCommentReplys(data.data.commentReplys);
      setTotal(data.data.pagingComment.count);
      setPageSize(data.data.pagingComment.size);
    } catch (e) {
      navigate(REQUESTS);
    }
  };

  const handleOnReload = () => {
    setHandleFlag(!handleFlag);
  };

  return (
    <section className='store-detail-section'>
      <div className='container'>
        <div className='store-detail-content'>
          <RequestSlide data={request} />
          <RequestDescription data={request} onReload={handleOnReload} />
        </div>
      </div>
      <div className='store-detail-tabs'>
        <div className='store-detail-tab-menu'>
          <div className='container'>
            <ul className='menu'>
              <li>
                <a>
                  <span>Reason for request</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='store-detail-tab-contents'>
        <div className='store-detail-tab-content active' id='store_detail_information'>
          <div className='container'>
            <div className='store-detail-content-block'>
              <div className='store-detail-content-block-left'>
                <div className='description'>
                  <ReadQuill requestBody={htmlContent} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='store-detail-tabs'>
        <div className='store-detail-tab-menu'>
          <div className='container'>
            <ul className='menu'>
              <li>
                <a>
                  <span>
                    Comments <small className='badge'>{request.comment_count}</small>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='store-detail-tab-contents'>
        <RequestComment
          commentGroups={commentGroups}
          commentReplys={commentReplys}
          idRequest={request.request_oid}
          count={total}
          size={pageSize}
          page={page}
          setPage={setPage}
          onReload={handleOnReload}
          setShowImgViewer={setShowImgViewer}
          setImgData={setImgData}
        />
      </div>
    </section>
  );
};

export default RequestDetail;
