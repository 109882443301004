export const getTitle = title => {
  const words = title.split(' ');
  // 마지막 요소를 제외한 나머지 요소들을 합치기
  const combinedWords = words.slice(0, -1).join(' ');
  // 마지막 요소는 그대로 두기
  const lastWord = words[words.length - 1];
  return { combinedWords, lastWord };
};

// 할인율 계산
export const calculateDiscountPercentage = (originPrice, finalPrice) => {
  const newOriginPrice = Number(originPrice);
  const newFinalPrice = Number(finalPrice);
  if (newOriginPrice && newFinalPrice) {
    const discount = ((newOriginPrice - newFinalPrice) / newOriginPrice) * 100;
    return discount; // 소수점 2자리까지 표시
  }
  return 0;
};

// 현재 배열의 길이가 목표 길이보다 작을 경우, fillValue로 채운다.
export const fillArray = (arr, fillValue, targetLength) => {
  while (arr.length < targetLength) {
    arr.push(fillValue);
  }
  return arr;
};

export const goTo = (e, path, isSkeletonLoading, navigate) => {
  e.preventDefault();
  e.stopPropagation();

  if (isSkeletonLoading) return;

  navigate(path);
};

export function removeSpecialCharactersAndSpaces(input) {
  const result = input.replace(/[^a-zA-Z0-9]/g, '');
  return result;
}

export function positiveValue(value) {
  return value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
}

export function positiveValueWithDecimal(e) {
  if (
    !/[0-9.]/.test(e.key) && // 숫자 및 소수점
    e.key !== 'Backspace' && // 백스페이스
    e.key !== 'Delete' && // Delete 키
    e.key !== 'ArrowLeft' && // 왼쪽 화살표
    e.key !== 'ArrowRight' && // 오른쪽 화살표
    e.key !== 'Tab' // 탭 키
  ) {
    e.preventDefault(); // 위 키들이 아닌 경우 입력 방지
  }
}
