import { useState, useEffect } from 'react';
import Options from '../../../component/option/Options';
import { MY_REVIEW_WRITE } from '../../../route/constant';
import Pagination from '../../Category/component/Pagination';
import { LISTING_SORT_LIKE_DESC, LISTING_SORT_NEWEST } from './constant';
import ReviewList from './ReviewList';
import UsersWork from './UsersWork';
import { getReviews } from '../../../action/request';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import UserContext from '../../../context/user/user';
import { useRecoilState } from 'recoil';
import { paginationAtom } from '../../../atom/atom';

const ReviewMain = () => {
  const [sort, setSort] = useState(LISTING_SORT_NEWEST);
  const [page, setPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(16);
  const [picks, setPick] = useState([]);
  const [index, setIndex] = useRecoilState(paginationAtom);

  const { action: modalAction } = useContext(ModalContext);
  const { state: userState } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (index) {
      setPage(index);
      setIndex(false);
    }
    fetchData();
  }, [page, sort]);

  const fetchData = async () => {
    try {
      const {
        data: { data: res },
      } = await getReviews({ page, sort });
      setReviews(res.reviews);
      setPageSize(res.paging.size);
      setTotal(res.paging.count);
      setPick(res.picks);
    } catch (e) {}
  };

  const sortChange = val => {
    setSort(val);
  };

  const pageChange = val => {
    setPage(val);
  };
  const pageConfirm = val => {
    setPage(val);
  };

  const onWriteReview = e => {
    e.preventDefault();
    e.stopPropagation();

    if (userState.isLogin) {
      navigate(MY_REVIEW_WRITE);
    } else {
      modalAction.setIsLogin(true);
    }
  };

  return (
    <>
      <div id='main'>
        <div className='page-top story page-top-with-breadcrumb'>
          <div className='container container-lg'>
            <h1 className='page-title'>Review</h1>
          </div>
        </div>

        <section className='story-section'>
          <div className='container'>
            <div className='story-best-review'>
              <div className='content-section-header'>
                <h2>
                  <strong>User's</strong> work
                </h2>
              </div>
              <UsersWork data={picks} page={page} />
            </div>
          </div>

          <hr />
          <div className='container'>
            <div className='review-cta'>
              <p>did you make it? please write making story !</p>
              <button
                onContextMenu={e => e.preventDefault()}
                className='btn btn-green btn-fill '
                onClick={onWriteReview}
              >
                Write Review
              </button>
            </div>

            <div className='review-list'>
              <div className='review-list-header'>
                <form id='review-listing' method='GET' action='<?php echo EnumUrl3::REVIEW ?>'>
                  <input type='hidden' name='page' value='<?php echo $paging->getCurPage() ?>' />
                  <Options
                    value={sort}
                    onChange={sortChange}
                    option={[
                      {
                        value: LISTING_SORT_NEWEST,
                        text: 'Newest',
                      },
                      {
                        value: LISTING_SORT_LIKE_DESC,
                        text: 'Like: High to Low',
                      },
                    ]}
                  />
                </form>
              </div>

              <div className='review-list-body'>
                <ReviewList data={reviews} page={page} />
                <Pagination
                  page={page}
                  total={Math.ceil(total / pageSize)}
                  onpagechange={pageChange}
                  onconfirm={pageConfirm}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReviewMain;
