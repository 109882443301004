import React, { useContext, useEffect, useState } from 'react';
import { createCartAdd, toggleItemLike } from '../../action/request';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import { gtagAddToCart } from '../../action/gTag';
import { ls } from '../../constant';
import CartContext from '../../context/cart.js/carts';
import { replicateObject } from '../../util/skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { emptyCard } from './constant';
import SectionHeader from '../common/sectionHeader/SectionHeader';

import { updateLikeState } from '../../util/updateLikeState';
import { useRecoilState } from 'recoil';
import { likeAtom } from '../../atom/atom';
import ModelCard from '../common/itemCards/ModelCard';
import useClickHandler from '../common/itemCards/useCardClickHandeler';
import useDownload from '../../hook/useDownload';
import Selectefile from '../selectefile/Selectefile';

function SectionB({ data, isMobile, locationData }) {
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const emptyCards = replicateObject(emptyCard, 4);
  const [cards, setCards] = useState(emptyCards);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const [likeState, setLikeState] = useRecoilState(likeAtom);

  const navigate = useNavigate();

  const options = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 25,
    loop: true,

    navigation: {
      prevEl: `.buttonSwiperPrevS4${locationData?.location}`,
      nextEl: `.buttonSwiperNextS4${locationData?.location}`,
    },
    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
      1180: {
        slidesPerView: 4,
      },
    },
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSkeletonLoading) return;

    navigate(path);
  };

  useEffect(() => {
    setCards(data?.items);
    setTimeout(() => {
      setIsSkeletonLoading(false);
    }, 200);
  }, [data]);

  useEffect(() => {
    if (likeState) {
      const newCards = cards.map(card =>
        card.product_id === likeState.id
          ? {
              ...card,
              product_like_count: `${Number(likeState.count)}`,
              my_like: likeState.value,
            }
          : card
      );

      setCards(newCards);
    }
  }, [likeState]);

  const clickLike = async (e, id, count) => {
    e.stopPropagation();
    if (!userState.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeState,
          cards: cards,
          count: count,
          data: data,
        });
        setCards(updatedCards);
      }
    }
  };

  const clickCart = async (e, price, id, inCart) => {
    e.stopPropagation();
    // 이미 카트에 담겨있을 경우
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = cards.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setCards(newCards);

          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  // swiper는 슬라이드 목록이 없을 때 autoplay, navigation 동작을 시작하지 않는 오류가 생기기 때문에 slide 값이 있을 때 동작하게 한다.
  if (!cards?.length) return null;

  return (
    <div className='sectionTypeB'>
      <div className='centerWrap'>
        {data && (
          <SectionHeader
            isSkeletonLoading={isSkeletonLoading}
            data={data}
            isMobile={isMobile}
            goTo={goTo}
            sectionValue={'A'}
            skeleton={isMobile ? 200 : 800}
          />
        )}

        <div className='swiperBigWrap'>
          <Swiper {...options} modules={[Navigation]} className='sectionASwiper sectionASwiper004'>
            <div className='swiper-wrapper'>
              {cards.map((card, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <div className='swiper-slide'>
                      <ModelCard
                        key={idx}
                        handleClick={() => handleClick(idx)}
                        twoClick={twoClick === idx}
                        oneClick={oneClick === idx}
                        data={card}
                        clickCart={clickCart}
                        clickLike={clickLike}
                        onClickDownload={onClickDownload}
                        buyStatus={card.buy_status}
                        downloadStatus={card.download_status}
                        isDesigner={card.isDesigner && userState.isLogin}
                        isOwn={card?.isOwn && userState.isLogin}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>

          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperPrev buttonSwiperPrevS4${locationData?.location}`}
            title='prev'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperNext buttonSwiperNextS4${locationData?.location}`}
            title='next'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
        </div>
      </div>
      {selectedId && selectedModal && (
        <Selectefile
          setSelectedModal={setSelectedModal}
          id={selectedId}
          setSelectedId={setSelectedId}
          selectedName={selectedName}
          status={true}
        />
      )}
    </div>
  );
}

export default SectionB;
