import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImgStyled } from '../../styles/ImgStyled';

const HoverEvent = ({ children, text, img, date, position, page }) => {
  const [isHover, setIsHover] = useState(false);

  const handleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {}, [isHover]);

  return (
    <HoverEventStyle
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      date={date}
      position={position}
      page={page}
    >
      {isHover && !img && text && <div className='hover-text'>{text}</div>}
      {isHover && !img && date && <div className='hover-date'>{date}</div>}
      {img && isHover && <ImgStyled src={img} alt='hover' className='hover-img' />}
      {children}
    </HoverEventStyle>
  );
};

export default HoverEvent;

const HoverEventStyle = styled.div`
  position: relative;
  font-family: 'inter', sans-serif;
  display: ${props => (props.date ? 'flex' : 'block')};
  justify-content: flex-end;

  & > .hover-text {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    position: absolute;
    display: inline-block;
    width: max-content;
    top: ${props => (props.page === 'mySales' ? '25px' : props.position || '-40px')};
    left: 50%;
    transform: ${props => (props.page === 'mySales' ? 'translateX(-60%)' : 'translateX(-50%)')};
    border: 1px solid #a1a1a1;
    color: #616161;
    border-radius: 4px;
    background-color: #fff;
  }

  & > .hover-date {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    position: absolute;
    display: inline-block;
    width: max-content;
    border: 1px solid #a1a1a1;
    color: #616161;
    border-radius: 4px;
    bottom: 15px;
    background-color: #fff;
  }

  .hover-img {
    position: absolute;
    bottom: 74px;
    left: 50%;
    transform: translateX(-50%);
    width: 124px;
  }
`;
