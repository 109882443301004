import React, { useState, useRef, useMemo, useContext, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import EditorToolbar, { formats } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

import ImageResize from 'quill-image-resize';
import AutoLinks from 'quill-auto-links';
import { fontSize } from './EditorToolbar';
import { ReviewUploadItemImage } from '../../action/request';
import UserContext from '../../context/user/user';
import LinkModal from './LinkModal';

Quill.register('modules/ImageResize', ImageResize);
Quill.register('modules/autoLinks', AutoLinks);

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSize;
Quill.register(Size, true);

const ImageFormat = Quill.import('formats/image');

class CustomImageFormat extends ImageFormat {
  static create(value) {
    let node = super.create(value);
    if (value.src) {
      node?.setAttribute('src', value.src);
    }
    if (value.width) {
      node?.setAttribute('width', value.width);
    }
    return node;
  }

  static value(node) {
    return {
      src: node?.getAttribute('src') || '',
      width: node?.getAttribute('width') || 'auto',
    };
  }
}

CustomImageFormat.blotName = 'image';
CustomImageFormat.tagName = 'img';

export const Editor = ({ setValue, value }) => {
  const { state: userState } = useContext(UserContext);
  // const [value, setValue] = useState('');
  const [linkModal, setLinkModal] = useState(false);
  const [linkText, setLinkText] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [seletedIndex, setSeletedIndex] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [deleteLink, setDeleteLink] = useState(false);
  const [editLinkUrl, setEditLinkUrl] = useState(false);
  const [tag, setTag] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [edit, setEdit] = useState(false);
  const quillRef = useRef(null);

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.addEventListener('change', async () => {
      const file = input.files?.[0];

      try {
        const { data: res } = await ReviewUploadItemImage({
          file: file,
          idUser: userState.id,
        });

        if (res.returnCode === 'C00008') {
          alert(res?.message);
        } else {
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();

          editor.insertEmbed(range?.index, 'image', res.img.url);
          editor.setSelection(range?.index + 1);
        }
      } catch (error) {
        console.info(error);
      }
    });
  };

  function checkShortUrl(url) {
    const videoShortId = url.split('shorts/');
    if (url.includes('shared')) {
      const splitShortId = videoShortId[1]?.split('?');
      return splitShortId[0];
    } else {
      return videoShortId[1];
    }
  }

  function checkYoutubeUrl(url) {
    let youtubeUrlId = '';

    if (url.includes('shorts')) {
      youtubeUrlId = checkShortUrl(url);
    } else if (url.includes('v=')) {
      const splitVideo = url.split('v=')[1];
      youtubeUrlId = splitVideo;
    } else {
      const splitVideoShared = url.split('be/');
      const splitVideoId = splitVideoShared[1]?.split('?');
      youtubeUrlId = splitVideoId[0];
    }

    return youtubeUrlId;
  }

  const insertVideo = () => {
    const quillEditor = quillRef.current.getEditor();
    const url = prompt('Enter YouTube URL');
    if (url) {
      let videoId = '';

      if (url.includes('youtu' || 'youtube')) {
        if (url.includes('&t')) {
          const settingUrl = url.split('&t');
          videoId = checkYoutubeUrl(settingUrl[0]);
        } else {
          videoId = checkYoutubeUrl(url);
        }
      }
      const range = quillEditor.getSelection();
      const embedUrl = url?.includes('youtu' || 'youtube')
        ? `https://www.youtube.com/embed/${videoId}`
        : url;
      quillEditor.insertEmbed(range.index, 'video', embedUrl);
    }
  };

  const handleSelectionChange = range => {
    const quill = quillRef.current.getEditor();

    if (range && range.length > 0) {
      const selectedText = quill.getText(range.index, range.length);
      setLinkText(selectedText);
      setSeletedIndex({ range: range?.index, length: range?.length });
      setTag(false);
    } else if (range) {
      setCurrentIndex(range?.index);
      setLinkText('');
      setTag(false);
    }

    if (range) {
      let currentIndex = range?.index;
      let endIndex = range?.index + range?.length;
      const images = [];

      while (currentIndex < endIndex) {
        const [leaf] = quill.getLeaf(currentIndex);
        const imageNode = leaf.domNode;

        if (leaf && leaf.domNode.tagName === 'IMG') {
          setTag('IMG');
          images.push({
            index: currentIndex,
            domNode: imageNode,
          });
        }
        currentIndex += 1;
      }
      setSelectedImages(images);
    }
  };

  const linkHandler = () => {
    const quill = quillRef?.current?.getEditor();
    const range = quill?.getSelection();
    setCurrentIndex(range?.index);

    handleSelectionChange();
    setEditLinkUrl(true);
    setLinkModal(true);
  };

  const editlinkHandler = (rangeIndex, length) => {
    setSeletedIndex({ range: rangeIndex, length: length });
  };

  const saveLink = (text, url) => {
    const imgLink = selectedImages?.length !== 0;
    if (imgLink) {
      handleModalSubmit(url);
    } else {
      if (url !== '') {
        const quill = quillRef?.current?.getEditor();
        const title = text ? text : url;
        const length = title?.length;
        if (seletedIndex) {
          quill.deleteText(seletedIndex.range, seletedIndex.length);
          quill.insertText(seletedIndex.range, title, 'link', url);
          quill.insertText(seletedIndex.range + length, ' ');
        } else {
          if (!currentIndex) {
            quill.insertText(0, title, 'link', url);
            quill.insertText(length, ' ');
          } else {
            quill.insertText(currentIndex, title, 'link', url);
            quill.insertText(currentIndex.range + length, ' ');
          }
        }

        setLinkText('');
        setLinkUrl('');
        setCurrentIndex('');
        setSeletedIndex('');
        setLinkModal(false);
        setEditLinkUrl(false);
      } else {
        alert('Please enter the link (URL).');
      }
    }

    setTag(false);
    setEdit(false);
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: '#toolbar',
        handlers: {
          video: insertVideo,
          image: imageHandler,
          link: linkHandler,
        },
      },
      ImageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize'],
        displaySize: true,
      },
      autoLinks: true,
    };
  }, []);

  useEffect(() => {
    if (!useState.isLogin) {
      setValue('');
    }
  }, [userState]);

  useEffect(() => {
    const editorContainer = quillRef.current?.getEditor()?.container;
    const handleClick = event => {
      const target = event.target;

      const editor = quillRef.current?.getEditor();

      if (target.tagName === 'A') {
        const href = target.getAttribute('href');
        const text = target.textContent || '';
        setLinkUrl(href);
        setLinkText(text === href ? '' : text);
        setEditLinkUrl(true);
        setLinkModal(false);
        const range = editor.getSelection();
        if (range) {
          const editorText = editor.getText();
          let start = editorText.indexOf(text);

          // Handle the case where text appears multiple times
          if (start === -1) {
            console.error('Text not found in editor');
          }

          editlinkHandler(start, text?.length);
        }
        setDeleteLink({ target: target, tagName: 'link' });
        event.preventDefault(); // 기본 링크 클릭 동작 방지
      }
      if (target.parentElement.tagName === 'A') {
        const parentLink = target.parentElement.tagName === 'A' ? target.parentElement : null;
        setDeleteLink({ target: target, tagName: 'img' });
        setSelectedImages(target);
        setTag('IMG');

        if (parentLink) {
          const existingUrl = parentLink.getAttribute('href');
          setLinkUrl(existingUrl);
          setEditLinkUrl(true);
          setLinkModal(false);
        } else {
          setEditLinkUrl(true);
          setLinkModal(true);
        }
        setCurrentImage(target);
      }
    };

    if (editorContainer) {
      editorContainer.addEventListener('click', handleClick);
    }

    // Cleanup on unmount
    return () => {
      if (editorContainer) {
        editorContainer.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === 'Backspace') {
      setLinkText('');
    }
    setTag(false);

    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();

    // 스페이스 키 (키 코드 32)
    if (event.key === ' ' && !event.ctrlKey && !event.shiftKey && !event.altKey) {
      event.preventDefault();
      quill.insertText(range.index, '\u00A0'); // &nbsp;를 의미하는 유니코드 삽입
      quill.setSelection(range.index + 1); // 커서 이동
    }

    // 탭 키 (키 코드 9)
    if (event.key === 'Tab') {
      event.preventDefault();
      const tabSpaces = '\u00A0\u00A0\u00A0\u00A0'; // 4개의 공백 삽입
      quill.insertText(range.index, tabSpaces);
      quill.setSelection(range.index + tabSpaces.length); // 커서 이동
    }

    // 링크 자동 변환 기능 추가
    quillRef.current.getEditor().on('text-change', function (delta, oldDelta, source) {
      if (source === 'user') {
        const range = quillRef.current.getEditor().getSelection();
        if (range) {
          const text = quillRef.current.getEditor().getText(0, range.index + range.length);
          const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // http, https, www 시작
          const matches = [...text.matchAll(urlRegex)];

          matches.forEach(match => {
            const url = match[0];
            const startIndex = match.index;
            const endIndex = startIndex + url.length;

            // 'www.'로 시작하는 경우 'https://'를 추가
            const formattedUrl = url.startsWith('www.') ? 'https://' + url : url;

            quillRef.current.getEditor().formatText(startIndex, url.length, 'link', formattedUrl);
          });
        }
      }
    });
  };

  const onClickDeleteLink = () => {
    const link = deleteLink?.target;
    const linkContents = link.innerHTML;
    const linkParent = link.parentNode;

    if (deleteLink?.tagName === 'link') {
      const spanElement = document.createElement('span');
      spanElement.innerHTML = linkContents;
      spanElement.setAttribute('style', 'color: inherit; text-decoration: none;');
      linkParent.insertBefore(spanElement, link);
      linkParent.removeChild(link);
    } else {
      const editor = quillRef.current.getEditor();
      const delta = editor.getContents();
      const newOps = delta.ops.map(op => {
        if (op.insert) {
          return {
            attributes: op?.attributes
              ? {
                  width: op.attributes.width,
                  link: op.attributes.link === linkUrl ? '' : op.attributes.link,
                }
              : '',
            insert: op.insert.image ? { image: op.insert.image } : op?.insert,
          };
        }
        return op;
      });
      const newDelta = { ops: newOps };
      editor.setContents(newDelta);
    }

    setEditLinkUrl(false);
    setLinkModal(false);
    setLinkUrl('');
    setTag(false);
  };

  const handleModalSubmit = url => {
    const linknode = deleteLink?.target;
    if (selectedImages?.length === 0 && currentImage && url && edit === false) {
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      linknode.parentNode.insertBefore(link, linknode);
      link.appendChild(linknode);
    }

    if (edit) {
      if (deleteLink && url) {
        const parentAnchor = linknode.closest('a');

        if (parentAnchor) {
          parentAnchor.href = url;
        }
      }
    }

    if (selectedImages?.length !== 0 && !edit) {
      let length = '';
      selectedImages.map((el, index) => {
        const imageNode = el.domNode;
        const parentNode = imageNode.parentNode;
        const images = parentNode?.getElementsByTagName('img');
        length = images;
      });

      Array.from(length).forEach(img => {
        const parentNode = img;
        const link = document.createElement('a');
        link.href = url; // 여기에 원하는 URL을 넣으세요.
        link.target = '_blank';
        parentNode?.parentNode?.insertBefore(link, img);
        link.appendChild(img);
      });
    }
    setEditLinkUrl(false);
    setLinkUrl('');
    setTag(false);
    setEdit(false);
  };

  return (
    <>
      <EditorWrap>
        <EditorQuill className='text-editor'>
          <div className='quill'>
            <EditorToolbar />
            {editLinkUrl ? (
              <LinkModal
                saveLink={saveLink}
                setLinkModal={setLinkModal}
                setLinkText={setLinkText}
                setLinkUrl={setLinkUrl}
                linkText={linkText}
                linkUrl={linkUrl}
                editLinkUrl={editLinkUrl}
                setEditLinkUrl={setEditLinkUrl}
                onClickDeleteLink={onClickDeleteLink}
                linkModal={linkModal}
                tag={tag}
                setEdit={setEdit}
              />
            ) : (
              ''
            )}

            <ReactQuill
              theme='snow'
              ref={quillRef}
              value={value}
              onChange={setValue}
              placeholder={'Type your message here…'}
              modules={modules}
              formats={formats}
              onChangeSelection={handleSelectionChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </EditorQuill>
      </EditorWrap>
    </>
  );
};

export default Editor;

const EditorWrap = styled.div`
  /* margin-top: 3rem; */
`;

const EditorQuill = styled.div`
  width: 100%;

  .quill {
    margin-bottom: 30px;
  }

  .video-size {
    margin-bottom: 10px;
  }

  .ql-tooltip {
    display: none;
  }

  .input-box {
    margin-bottom: 10px;
    h3 {
      margin-bottom: 10px;
      line-height: 20px;
    }
    input {
      width: 100px;
      height: 30px;
      margin-right: 10px;
      padding: 5px 10px;
      box-sizing: border-box;
    }

    button {
      height: 30px;
    }
  }

  em {
    font-style: italic;
  }

  .ql-editor {
    min-height: 400px;
    max-height: 800px;
    overflow-y: scroll;
  }

  .ql-container {
    resize: vertical;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-thumb {
      background: #aaa;
    }
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #aaa !important;
  }
  .ql-container.ql-snow {
    border-left: 1px solid #aaa !important;
    border-right: 1px solid #aaa !important;
    border-bottom: 1px solid #aaa !important;
    border-top: none;
  }

  .ql-video {
    width: 800px;
    height: 450px;
  }

  .add-file {
    margin-top: 80px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .file-list {
    display: flex;
    flex-direction: column;
    div {
      margin: 10px 0px;
    }
    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }
  }

  .ql-editor ol {
    padding-left: 1rem;

    li {
      padding-left: 1.5rem;
      ::before {
        width: 1em;
        text-align: justify;
      }
    }
  }
  .ql-editor ul {
    padding-left: 1rem;
    li {
      padding-left: 1.5rem;
      ::before {
        width: 1em;
        text-align: justify;
      }
    }
  }

  .ql-editor .ql-indent-1 {
    padding-left: 2.5rem !important;
  }
  .ql-editor .ql-indent-2 {
    padding-left: 3.5rem !important;
  }
  .ql-editor .ql-indent-3 {
    padding-left: 4.5rem !important;
  }
  .ql-editor .ql-indent-4 {
    padding-left: 5.5rem !important;
  }
  .ql-editor .ql-indent-5 {
    padding-left: 6.5rem !important;
  }
  .ql-editor .ql-indent-6 {
    padding-left: 7.5rem !important;
  }
  .ql-editor .ql-indent-7 {
    padding-left: 8rem !important;
  }
  .ql-editor .ql-indent-8 {
    padding-left: 9.5rem !important;
  }
  .ql-editor .ql-indent-9 {
    padding-left: 10.5rem !important;
  }

  ul {
    list-style-type: disc !important;
    line-height: 25px;
  }

  ol {
    list-style-type: decimal !important;
    line-height: 25px;
  }

  p {
    font-size: 16px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  li {
    font-size: 16px;
  }

  img {
    max-width: 800px;
  }

  a {
    color: #06c;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  table {
    border-color: 1px solid #000;
    width: 400px;
  }
`;
