import styled from 'styled-components';

export const ManageUploadInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* box-shadow: inset 0px 0px 3px 1px rgb(0, 38, 255); */
  font-family: 'Inter', sans-serif;
  width: 100%;
`;

export const InputStyle = styled.input`
  font-size: 16px;
  padding: 20px !important;
  /* height: 48px !important; */
  border-radius: ${({ symbol }) => (symbol ? '10px 0 0 10px' : '10px')};
  font-size: 16px;
  width: 100%;
  height: 48px;

  border: none;
  border-right: ${({ symbol }) => (symbol ? '1px solid #000' : 'none')};

  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  text-align: ${({ textAlign }) => textAlign || 'left'} !important;

  &::placeholder {
    color: #9a9a9a;
    font-size: 12px;
    text-align: left !important;
  }
`;

export const LabelStyle = styled.label`
  font-size: 20px;
  font-weight: 400;
`;

export const RequiredText = styled.p`
  color: #ff0000;
  font-size: 14px;
  font-weight: 400;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  background-color: ${({ symbol }) => (symbol ? '#9a9a9a' : '#fff')};

  border-radius: 10px;

  border: ${({ isRequired }) => (isRequired ? '1px solid red' : '1px solid #000')};
`;

export const SymbolBox = styled.div`
  font-size: ${({ symbolSize }) => symbolSize || 20}px;
  font-weight: 400;
  color: #fff;

  width: 60px;
  height: 100% !important;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ValueLength = styled.div`
  color: #9a9a9a;
  font-size: 14px;
  font-weight: 400;

  width: 100%;
  height: 100%;
  max-width: 100px;
  text-align: center;
  border-left: 1px solid #9a9a9a;
  padding: 10px;
`;
