import { useNavigate } from 'react-router-dom';
import { ABOUT } from '../../../route/constant';

const PhilosophyEn = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <section
        className='fab-page-top'
        style={{
          backgroundImage:
            "url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/philosophy/top-img.jpg')",
        }}
      ></section>

      <section className='fab-page-section' id='fabPageAboutPhilosophy'>
        <div className='container fab-page-container'>
          <div className='--row'>
            <h3 className='--title --txt-xxl'>
              FAB365 <br />
              <strong>Culture</strong>
            </h3>
            <div className='--desc'>
              <blockquote className='--quote --txt-lg --mb-50 --mb-30--m'>
                "The experts who know 3D models very well, <br className='--when-desktop' />
                people
                <br className='--when-mobile' />
                who are only crazy about 3D printing and people <br className='--when-mobile' /> who
                use 3D printing the most,
                <br className='--when-mobile' />
                <strong>are constantly researching together</strong>”
              </blockquote>
              <p className='--font-basic --mb-100 --mb-60--m'>
                3D printing requires not only understanding of the 3D printer's machine, but also
                the know-how from experiencing and understanding numerous variables that occur from
                using the device. In addition, the output quality will vary slightly depending on
                the compatibility of the 3D model file and the software. It is very important to
                have a thorough understanding of the hardware and software and design the 3D model.
                Therefore, FAB365 is gathered and researched by the best experts in 3D printing to
                create the world's highest quality 3D models. This is why we can best create 3D
                models that will satisfy users around the world.
                <br />
                <br />
                We not only design high quality 3D models, but also we continue to release items
                that are preferred by a variety of people who want to print from all around the
                world. This is because we are the oldest enthusiasts in the field. Because there are
                people who are curious and who like to imagine, you can understand the thoughts of
                users who are enthusiastic about new concepts and novel items. Only from the user's
                point of view, we consider and study what they want, what they want to print, and
                what they want to buy. It is also the reason why the numerous items released by
                FAB365 are a super hit around the world. Because, all of our teams are military
                enthusiasts, car enthusiasts, robot enthusiasts, and mechanic enthusiasts.
                <br />
                <br />
                The core culture of FAB365 is to create the world's best 3D printing contents, the
                attitude of researching crazy once we fall into something, the tenacity that grows
                until the problem is solved.
              </p>
              <a href='' className='--more' onClick={e => goTo(e, ABOUT)}>
                <span className='--font-basic'>← Back</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PhilosophyEn;
