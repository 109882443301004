import React, { useState } from 'react';

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
  'video',
  'table',
];

export const fontSize = ['12px', '16px', '24px', '36px'];

const FontEditor = () => {
  const [selected, setSelected] = useState('16px');

  const onChange = val => {
    setSelected(val);
  };

  return (
    <select className='ql-size' defaultValue={selected} onChange={onChange}>
      {fontSize.map(val => {
        return (
          <option value={val} key={val}>
            {val.replace(/[^0-9]/g, '') === '12'
              ? 'Small'
              : val.replace(/[^0-9]/g, '') === '16'
              ? 'Normal'
              : val.replace(/[^0-9]/g, '') === '24'
              ? 'Large'
              : 'Very Large'}
          </option>
        );
      })}
    </select>
  );
};

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id='toolbar'>
    <span className='ql-formats'>
      <FontEditor />
    </span>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
      <select className='ql-color' />
    </span>
    <span className='ql-formats'>
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
      <button className='ql-indent' value='-1' />
      <button className='ql-indent' value='+1' />
    </span>
    <span className='ql-formats'>
      <select className='ql-align' />
    </span>
    <span className='ql-formats'>
      <button className='ql-image' />
      <button className='ql-video' />
      <button className='ql-link' />
    </span>
  </div>
);

export default QuillToolbar;
