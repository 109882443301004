/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import { InvitePageContainer } from './styles';
import { HOSTNAME, getInviteToken } from '../../action/request';
import { ls } from '../../constant';
import { useNavigate } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import ModalContext from '../../context/modal/modal';
import UserContext from '../../context/user/user';

function Invite() {
  const [inviteToken, setInviteToken] = useState(null);
  const navigate = useNavigate();
  const { action } = useContext(ModalContext);
  const { state } = useContext(UserContext);
  const [urlString, setUrlString] = useState('Please log in and try again.');
  const content = `I invite you to join FAB365 which is the premium 3D model platform. 
Sign up now using the link below,
verify your account, and receive five free model files and a reward ticket.
          
`;
  const isLogin =
    localStorage.getItem(ls.accessToken) !== undefined &&
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== null;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLogin) {
      alert('To invite friends, please log in first.');
      navigate('/');
      action.setIsLoginForInvite(true);
    } else {
      fetchData();
    }
  }, [isLogin, inviteToken, localStorage.getItem(ls.accessToken)]);

  const fetchData = async () => {
    if (isLogin) {
      const res = await getInviteToken();
      setInviteToken(res.data.data);
      if (HOSTNAME === 'localhost') {
        setUrlString(`http://${HOSTNAME}:3000/landing?t=${inviteToken}`);
      } else {
        setUrlString(`https://${HOSTNAME}/landing?t=${inviteToken}`);
      }
    }
  };

  // URL 복사 기능
  const copyTextToClipboard = async () => {
    if (inviteToken) {
      try {
        await navigator.clipboard.writeText(`${content}${urlString}`);
        alert('The invitation link has been copied.');
      } catch (error) {
        alert('Please try again after refreshing the page.');
      }
    }
  };

  return isLogin ? (
    <Layout>
      <InvitePageContainer id='main2'>
        <div class='invitation-wrap'>
          <div class='invitation-inner'>
            <div class='bg-left'>
              <img src='/assets/img/invite/bg.png' alt='Background image' />
            </div>
            <div class='bg-right'>
              <img src='/assets/img/invite/bg.png' alt='Background image' />
            </div>
            <div class='invitation-logo'>
              <img src='/assets/img/invite/black.png' alt='Fab365 logo' />
            </div>
            <div class='invitation-title'>
              <img
                class='title-text'
                src='/assets/img/invite/title.png'
                alt='Share the url below'
                draggable='false'
              />
              <img
                class='title-arrow title-arrow-left'
                src='/assets/img/invite/arrow.png'
                alt='arrow'
                draggable='false'
              />
              <img
                class='title-arrow title-arrow-right'
                src='/assets/img/invite/arrow.png'
                alt='arrow'
                draggable='false'
              />
            </div>
            <div class='invitation-link'>
              <div class='link-box'>
                <div className='content'>
                  <div className='invite-text'>
                    I invite you to join FAB365 which is the premium 3D model platform. Sign up now
                    using the link below, <br />
                    verify your account, and receive five free model files and a reward ticket.
                  </div>
                  <p id='copyText'>{urlString}</p>
                </div>
                <button onClick={copyTextToClipboard} alt='copy'>
                  <img src='/assets/img/invite/icon_link.png' alt='' />
                </button>
              </div>
            </div>
            <div class='invitation-desc'>
              <p>
                Whenever your friends access the website through this URL, sign up, and
                <br />
                <strong>successfully log in</strong>, you will receive a Reward ticket.
              </p>
            </div>
            <div class='invitation-main'>
              <img src='/assets/img/invite/main.png' alt='main image' draggable='false' />
            </div>
            <div class='invitation-item-wrap'>
              <p class='invitation-item-desc'>Models available for download with Reward tickets.</p>
              <ul class='invitation-item-grid'>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_01.png' alt='Pokemon-Pikachu' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_02.png' alt='Razor-crest' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_03.png' alt='Super-Mario' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_04.png' alt='starship' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_05.png' alt='buzz' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_06.png' alt='AT-ST' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_07.png' alt='ECTO-1' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_08.png' alt='wall-E' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_09.png' alt='eve' />
                </li>
                <li class='invitation-item'>
                  <img src='/assets/img/invite/item_10.png' alt='Many other FREE items' />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </InvitePageContainer>
    </Layout>
  ) : (
    <div></div>
  );
}

export default Invite;
