import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CartContext from '../../../context/cart.js/carts';
import { CHECKOUT_FREE } from '../../../route/constant';
import { createCheckoutFree } from '../../../action/request';

const ActionButton = ({
  type,
  isLogin,
  id,
  isLoading,
  showProduct,
  freeTicketCount,
  qty,
  onClickDownload,
  isOwn,
  priceOption,
  isDesigner,
}) => {
  const { action: modalAction } = useContext(ModalContext);
  const { action: cartAction } = useContext(CartContext);
  const secret = showProduct === 'secret' && !isDesigner;
  const navigate = useNavigate();
  const isInsufficient = type === 'reward' && freeTicketCount - qty < 0 && isLogin;
  const isOwnState = isLogin && isOwn && type === 'download' && secret;

  const buttonText = () => {
    if (isLoading || (secret && !isOwn)) {
      return 'No longer for sale';
    }
    if (isInsufficient) {
      return 'NOT ENOUGH TICKETS';
    }
    if (isOwn) {
      return 'DOWNLOAD';
    }

    switch (type) {
      case 'download':
        return 'DOWNLOAD';
      case 'reward':
        return 'GET MODEL NOW';
      default:
        return 'BUY NOW';
    }
  };

  const renderButtonIcon = () => {
    if (type === 'download' && priceOption === 'reward') {
      return <img src='/assets/img/itemDetail/btn-icon.png' />;
    }
    if (type === 'download' && priceOption !== 'reward') {
      return <img src='/assets/img/itemDetail/btn-icon-fff.png' />;
    }
    if (type === 'buy' || (!isOwn && isInsufficient)) {
      return <img src='/assets/img/itemDetail/btn-icon-fff.png' />;
    }
    if (buttonText() === 'GET MODEL NOW') {
      return <img src='/assets/img/itemDetail/btn-icon.png' />;
    }
    return null;
  };

  const checkoutFree = async itemID => {
    try {
      const { data: res } = await createCheckoutFree(itemID);
      cartAction.checkoutFreeProduct(res.data);

      navigate(CHECKOUT_FREE);
    } catch (error) {
      // handle error
    }
  };

  const onClickActionButton = () => {
    if (isOwnState) {
      onClickDownload();
      return;
    }

    if (secret || isInsufficient) {
      return;
    }

    if (type === 'buy') {
      if (!isLogin) {
        modalAction.setIsLogin(true);
      } else {
        navigate(`/checkout-step1?item=${id}`);
      }
    } else if (type === 'download' && onClickDownload) {
      onClickDownload();
    } else if (type === 'reward') {
      if (!isLogin) {
        modalAction.setIsLogin(true);
      } else {
        checkoutFree(id);
      }
    }
  };

  return (
    <>
      <Btn
        onClick={onClickActionButton}
        secret={secret}
        type={type}
        isInsufficient={isInsufficient}
        isOwnState={isOwnState}
        priceOption={priceOption}
      >
        {renderButtonIcon()}
        {buttonText()}
      </Btn>
    </>
  );
};

export default ActionButton;

const Btn = styled.button`
  background-color: ${props =>
    (props.secret && !props.isOwnState) || props.isInsufficient
      ? '#929599'
      : props.type === 'reward' || props.priceOption === 'reward'
      ? '#f7f15b'
      : '#000'};
  color: ${props =>
    props.secret || props.isInsufficient
      ? '#fff'
      : props.type === 'reward' || props.priceOption === 'reward'
      ? '#000'
      : '#fff'};
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.8;
  border: none;

  img {
    width: 15px;
    position: relative;
    right: 10px;
    top: 5px;
  }
`;

export const ToggleSwitchStyle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin: 12px 10px;
`;
