import { useEffect, useState } from "react";
import { getPrinters, getSlicers } from "../../../../action/request";
import PrinterSettingDownload from "./PrinterSettingDownload";
import ProfilePrinterOptions from "./ProfilePrinterOptions";
import ProfileSlicerOptions from "./ProfileSlicerOptions";

const ProfilePrinterSetting = ({ data, onChange, onDelete }) => {
  const [printers, setPrinters] = useState([]);
  const [slicers, setSlicers] = useState([]);

  const fetchData = async () => {
    try {
      const { data: printers } = await getPrinters();
      setPrinters(printers.data);
      const { data: slicers } = await getSlicers();
      setSlicers(slicers.data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const changePrinterSetting = (idx) => (val) => {
    onChange(idx, "printer_id", val);
  };

  const changeSlicerSetting = (idx) => (val) => {
    onChange(idx, "slicer_id", val);
  };
  return (
    <>
      {data?.map((s, idx) => {
        return (
          <fieldset key={s.printer_id + "." + s.slicer_id}>
            <ProfilePrinterOptions
              data={printers}
              selected={s.printer_id}
              onChange={changePrinterSetting(idx)}
            />
            <ProfileSlicerOptions
              data={slicers}
              selected={s.slicer_id}
              onChange={changeSlicerSetting(idx)}
            />
            <div className="form-group form-action">
              <div className="form-control">
                <button
                  style={{ marginRight: "7px" }}
                  type="button"
                  className="btn btn-fill"
                  data-action="delete-printer"
                  onClick={() => onDelete(idx)}
                >
                  Delete this printer
                </button>
                <PrinterSettingDownload
                  slicer={s.slicer_id}
                  printer={s.printer_id}
                  downloadable={s.isSettingDownloadable}
                />
              </div>
            </div>
          </fieldset>
        );
      })}
    </>
  );
};

export default ProfilePrinterSetting;
