import { ls } from '../constant';

// 코멘트 이미지 뷰어 열리면 body 스크롤 고정
export const preventScroll = () => {
  const currentScrollY = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
  document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
  document.body.style.overflowY = 'scroll';
  localStorage.setItem(ls.scrollY, currentScrollY);
};

// 코멘트 이미지 뷰어 열리면 body 스크롤 고정 해제
export const allowScroll = () => {
  const prevScrollY = localStorage.getItem(ls.scrollY);
  document.body.style.position = '';
  document.body.style.width = '';
  document.body.style.top = '';
  document.body.style.overflowY = '';
  window.scrollTo(0, prevScrollY);
};
