import { useNavigate } from 'react-router-dom';
import ItemRecommand from '../../component/item/ItemRecommand';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import { PRINT_TIPS_LIST } from '../../route/constant';
import { renderTipFeature } from '../../util/renderer';

const BedPei = ({ title, tab }) => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu title={title} tab={tab} />
          <div className='container fab-accordion-mt-s1 fab-accordion-mb-s1'>
            <div className='fab-accordion __w1340'>
              <div className='fab-accordion-header'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
                <h2 className='fab-accordion-header--title'>Is bed not sticking well?</h2>
              </div>
              <div className='featured-story-list'>
                <ul>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/hairgel.jpg',
                      'Use "Gatsby\'s hair gel."',
                      '"Gatsby\'s hair gel." strengthens the adhesion of bed..<br/><br/>If you apply this, it protects the coating and enhances the adhesive strength and the printout falls off easily even after printing. After using all different kinds of hair gel, "Super hard styling gel" from Gatsby company in Japan, was the best solution. All the other hair gel brands were not very successful.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/hairgel2.jpg',
                      'How to use it?',
                      'You drop the hair gel on the bed.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/hairgel3.jpg',
                      'How to use it?',
                      'Spread out evenly using a thick paper like a business card or thin plastic plate. Apply it before printing regardless of the temperature of the bed.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/handwashing.jpg',
                      'Cleaning method',
                      'If you keep using hair gel, the bed gets dirty. The bed has to be cleaned regularly.<br/><br/>1) Detach the bed from the printer<br/>2) If you cleanse the bed with water, the hair gel will be removed completely.<br/>3) Dry the bed and stick it back to the printer.<br/>4) Use the hair gel again.<br/><br/>Repeat 1)~4).',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/dontuse.jpg',
                      'Caution',
                      'Hair gel must be used only on PEI/PEX/Carbon coated glass beds.<br/>If you use it on different types of bed, the adhesion will lack.',
                      null,
                      null
                    )}
                  </li>
                </ul>
              </div>
              <div className='fab-accordion-footer'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
              </div>
            </div>
          </div>

          <section>
            <ItemRecommand itemId={null} />
          </section>
        </div>
      </Layout>
    </>
  );
};
export default BedPei;
