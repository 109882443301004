export const stringToUrl = (prefix, str, maxLen = 128) => {
  // replace now allowed to whitespace
  let uri = str.replace(/[^a-zA-Z0-9_-]/g, ' ');

  // remove whitespace
  uri = uri.replace(/\s+/g, ' ');
  uri = uri.replace(/\s+/g, '_');

  const len = maxLen - prefix.length;
  return uri.substring(0, len);
};

export const getOidUrl = (prefix, oid, subject, body) => {
  const str = subject + ' ' + body?.substring(0, 128);
  const url = prefix + '/' + oid + '/' + stringToUrl('', str);
  return url;
};
