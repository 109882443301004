/* eslint-disable jsx-a11y/anchor-is-valid */
import { sanitize } from 'dompurify';
import {
  formatHrefLink,
  imgHtml,
  imgsHtml,
  imgsToMedias,
  isEditableItemComment,
} from '../../util/comment';
import { DEFAULT_AVATAR } from '../avatar/const';
import { useContext, useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import UserContext from '../../context/user/user';
import { uploadItemImage } from '../../action/request';
import { preventScroll } from '../../util/commentImageViewer';
import { ImgStyled } from '../../styles/ImgStyled';

const CommentReplyItem = ({
  reply,
  isLast,
  pkComment,
  showReply,
  onDelete,
  onEdit,
  setCommentReply,
  setShowImgViewer,
  setImgData,
  showProduct,
  value,
  userState,
  designerId,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editBody, setEditBody] = useState(reply.comment);
  const [imgs, setImgs] = useState(imgsHtml(reply));
  const [medias, setMedias] = useState(imgsToMedias(reply.imgs));
  const lastRef = useRef();
  const [loading, setLoading] = useState(false);
  const imageInput = useRef(null);
  // const user = reply['user'];
  const body = reply.comment;

  const { state } = useContext(UserContext);

  useEffect(() => {
    if (isLast) {
      setCommentReply(lastRef);
    }
  }, []);

  const editComment = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    // 아무 내용 없다면 알림
    if (!editBody || editBody === '') {
      return alert('Comment is empty');
    }

    if (value === 'designer') {
      onEdit(id, editBody, medias);
    } else {
      onEdit({
        comment: reply[pkComment],
        msg: editBody,
        medias,
        imgs: medias.map(media => JSON.stringify(media)),
      });
    }

    setIsEdit(false);
  };

  const imageChanged = async e => {
    try {
      const files = e.target.files;
      const newMedias = [...medias];
      const newImages = [...imgs];
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadItemImage({
          file: files[i],
        });
        newImages.push(imgHtml(res.img.thumbs[0].url));
        newMedias.push(res.img);
      }
      setMedias(newMedias);
      setImgs(newImages);
      setLoading(false);
    } catch (e) {
      alert('request failed');
    }
  };

  const onDeleteImage = idx => {
    const newMedias = medias.filter((_, i) => i !== idx);
    const newImages = imgs.filter((_, i) => i !== idx);
    setMedias(newMedias);
    setImgs(newImages);
  };

  const onClickImg = (imgArr, idx) => {
    // console.log(imgArr, idx);
    setShowImgViewer(true);
    setImgData({ index: idx, data: imgArr });
    preventScroll();
  };

  return (
    <>
      {reply && (
        <div key={reply.id_comment} className='comment-item comment-item-re'>
          {isEdit && (
            <div className='comment-write'>
              <div className='profile'>
                <div className='profile-pic'>
                  {Number(designerId) === Number(reply?.user?.id_user) ? (
                    <img src={'/assets/img/designers/crown.png'} className='crown' />
                  ) : (
                    ''
                  )}
                  <ImgStyled
                    src={reply?.user?.thumbnail ? reply.user.thumbnail : DEFAULT_AVATAR}
                    alt=''
                  />
                </div>
              </div>
              <form className='comment-write-form' data-action='1103-comment-reply-submit'>
                <div className='write-form'>
                  <textarea
                    placeholder='Please input your text'
                    name='msg'
                    value={editBody}
                    onChange={e => setEditBody(e.target.value)}
                  ></textarea>
                  <div className='attachments'>
                    <ul className='files'>
                      {imgs?.map((thumb, idx) => {
                        return (
                          <li key={idx + thumb.url}>
                            <div className='file-item'>
                              <a>
                                <ImgStyled src={thumb?.url} alt='' />
                              </a>
                              <button
                                type='button'
                                className='btn-delete'
                                onClick={() => onDeleteImage(idx)}
                              >
                                ×
                              </button>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className='form-action'>
                    <div className='file-uploader'>
                      <input
                        ref={imageInput}
                        type='file'
                        accept='image/*'
                        multiple
                        onChange={imageChanged}
                      />
                      <button
                        type='button'
                        className={'btn-upload-file loading_color ' + (loading && 'loading')}
                        onClick={() => imageInput.current.click()}
                      >
                        Edit Image
                      </button>
                    </div>
                    <button
                      type='submit'
                      className='btn btn-green btn-fill'
                      onClick={e => editComment(e, reply?.id_comment)}
                      id='addBtn'
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {!isEdit && (
            <>
              <div className='comment-item-header'>
                <div className='profile'>
                  <div className='profile-pic'>
                    {Number(designerId) === Number(reply?.user?.id_user) ? (
                      <img src={'/assets/img/designers/crown.png'} className='crown' />
                    ) : (
                      ''
                    )}
                    <img
                      src={reply?.user?.thumbnail ? reply.user.thumbnail : DEFAULT_AVATAR}
                      alt=''
                    />
                  </div>
                  {reply.user?.name && (
                    <div className='profile-name'>
                      {reply.user.name ? reply.user.name : 'noname'}
                    </div>
                  )}
                  <div className='comment-date'>{reply.date_reg}</div>
                </div>
                {isEditableItemComment(reply, String(state.id)) && (
                  <div className='util-btns'>
                    <button
                      className='btn-edit'
                      data-action='1100-comment-edit'
                      onClick={() => setIsEdit(true)}
                    >
                      Edit Comments
                    </button>
                    <button
                      className='btn-delete'
                      data-action='1104-comment-delete'
                      onClick={() => onDelete({ oidComment: reply[pkComment] })}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
              <div className='comment-item-body'>
                <div className='comment-content'>
                  <p dangerouslySetInnerHTML={formatHrefLink(reply.comment)}></p>
                </div>
                <div className='attachments' data-image-gallery='true'>
                  {imgs?.map((img, idx) => {
                    return (
                      <a
                        // href={img.url}
                        onClick={() => onClickImg(imgs, idx)}
                        data-tb={img.tb}
                        key={idx + img.url}
                        style={{
                          width: `5.875rem`,
                          height: `5.875rem`,
                          backgroundImage: `url(${img.url})`,
                          backgroundSize: `cover`,
                          backgroundPosition: `center`,
                        }}
                      >
                        {/* <img src={img.src} alt='' /> */}
                      </a>
                    );
                  })}
                </div>
              </div>
              {state.id && isLast && showProduct !== 'secret' && (
                <div className='comment-item-footer' ref={lastRef}>
                  {/* <a onClick={showReply} className='btn btn-xs' data-action='1102-comment-reply'>
                   */}
                  <a onClick={showReply} className={`btn btn-xs`} data-action='1102-comment-reply'>
                    Reply
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {/* '<div className="comment-item comment-item-re" id=" hash + '">
            
            '</div>'); */}
    </>
  );
};

export default CommentReplyItem;
