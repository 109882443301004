import { useNavigate } from 'react-router-dom';
import { ABOUT, ABOUT_CO_CEO } from '../../../route/constant';
import './AboutCeoKo.css';
const AboutCeoKo = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };
  return (
    <>
      <article className='fab-founder-detail'>
        <div className='--desc'>
          <section className='fab-page-section fab-page-section-ko'>
            <div className='container'>
              <div className='--desc-header --mb-60 --mb-40--m'>
                <h2 className='--name --txt-xxl --mb-5'>
                  <strong>구 상 권</strong>
                </h2>
                <p className='--role --font-basic'>공동 최고 경영자 겸 컨텐츠 개발 책임자</p>
              </div>
              <div className='--desc-body --mb-80 --mb-50--m'>
                <p className='--font-basic'>
                  FAB365의 최고 경영자 겸 컨텐츠 개발 책임자인 구상권은 회사의 컨텐츠 개발 전략을
                  총괄하여 전세계적인 3D프린팅 커뮤니티에서 인정받는 성과를 보여주고 있습니다.
                  대한민국의 서울대학교 디자인학 박사 학위를 받고 건국대학교, 이화대학교에서 교수로
                  강의를 한 최고의 3D 디자인 전문가입니다.
                  <br />
                  <br />
                  지난 30년간 현대자동차, 기아자동차의 자동차 3D 디자인과 삼성, LG의 전자제품 3D
                  디자인 프로젝트에 참여하였으며 영화, CF 영상특수효과의 프로젝트에도 다수
                  참여하였습니다. 구상권은 프라모델 매니아, 애니매이션 매니아, 듀카티 오토바이
                  매니아로 그의 오랜 경험과 깊이 있는 수준이 FAB365의 컨텐츠에 반영되어 개발되고
                  있습니다.
                </p>
              </div>
              <div className='--desc-footer'>
                <a href='' className='--more' onClick={e => goTo(e, ABOUT)}>
                  <span className='--font-basic'>← Back</span>
                </a>
                <a href='' className='--more' onClick={e => goTo(e, ABOUT_CO_CEO)}>
                  <span className='--font-basic'>Co-CEO / Lee Jong han →</span>
                </a>
              </div>
            </div>
          </section>
        </div>

        <div
          className='--img'
          style={{
            backgroundImage:
              "url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/founder/ceo1.jpg')",
          }}
        ></div>
      </article>
    </>
  );
};
export default AboutCeoKo;
