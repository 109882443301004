import { useNavigate } from 'react-router-dom';
import { SYMBOL_USD } from '../../../../component/Price/constant';
import { MY_ORDER } from '../../../../route/constant';

const OrderBill = ({ pay, order }) => {
  const navigate = useNavigate();

  const getDiscount = () => {
    return order.discount === '' ? '0.00' : order.discount;
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='mypage-order-detail-right'>
        <div className='your-order-box m-b-sm'>
          <h3>Payment Approved</h3>
          <div className='your-order-info'>
            <div className='price-info'>
              <dl>
                <dt>Product price</dt>
                <dd>
                  {SYMBOL_USD +
                    ' ' +
                    (Number(pay.price) + Number(pay.mileage) + Number(getDiscount())).toFixed(2)}
                </dd>
              </dl>
              <dl>
                <dt>Discount</dt>
                <dd>
                  <span className='txt-red'>- {SYMBOL_USD + ' ' + getDiscount()}</span>
                </dd>
              </dl>
            </div>

            <div className='price-info'>
              <dl>
                <dt>Subtotal</dt>
                <dd>
                  {SYMBOL_USD +
                    ' ' +
                    (order.subtotal === ''
                      ? (Number(pay.price) + Number(pay.mileage)).toFixed(2)
                      : order.subtotal)}
                </dd>
              </dl>
              <dl>
                <dt>Use Credit</dt>
                <dd>
                  <span className='txt-red'>- {SYMBOL_USD + ' ' + pay.mileage}</span>
                </dd>
              </dl>
            </div>
            <dl>
              <dt>Total</dt>
              <dd>
                <strong className='total-price'>{SYMBOL_USD + ' ' + pay.price}</strong>
              </dd>
            </dl>
          </div>
        </div>
        <div className='text-right'>
          <a href='' onClick={e => goTo(e, MY_ORDER)}>
            Go to order list &gt;
          </a>
        </div>
      </div>
    </>
  );
};

export default OrderBill;
