import DiscountPrice from '../../../component/section/DiscountPrice';
import { ImgStyled } from '../../../styles/ImgStyled';
import { CartProductWrapper, SpanWidth } from '../../Cart/component/CartProduct';

const finalPrice = item => {
  if (item.salesPrice <= 0) return 'FREE';

  if (item.isDiscount)
    return (
      <>
        <strong>{Number(item.salesPrice).toFixed(2)}$</strong>{' '}
        <s>{Number(item.listPrice).toFixed(2)}$</s>
      </>
    );

  return <>{Number(item.salesPrice).toFixed(2)}$</>;
};

const CheckoutProduct = ({ data }) => {
  return (
    <>
      <div className='purchase-product-list per-one'>
        <ul className='clearfix'>
          {data.map(p => {
            return (
              <li key={p.productId}>
                <div
                  className={
                    'purchase-product-list-item ' + (!p.isBuyable && ' already-bought-3c3a')
                  }
                >
                  <div className='purchase-product-list-item-inner'>
                    <ImgStyled src={p.itemRowDtos.list_img} className='tb' alt='' />
                    <CartProductWrapper className='product-meta' cartItem={'cartItem'}>
                      <span className='product-name'>{p.itemRowDtos.name_en}</span>
                      {/* {p.isBuyable && <span className='price notranslate'>{finalPrice(p)}</span>} */}

                      {p.isBuyable && (
                        <SpanWidth className='price notranslate' cartItem={'cartItem'}>
                          <DiscountPrice
                            finalPrice={`$${finalPrice(p).props.children[0]}`}
                            discountRate={p.itemRowDtos.discount_rate}
                            originPrice={p.itemRowDtos.original_price}
                            cartProduct={'cartProduct'}
                            fontSize={'20px'}
                            cartProductPayment={true}
                          />
                        </SpanWidth>
                      )}
                      {!p.isBuyable && <span className='warn-3c9a'>{p.isBuyableErrorMsg}</span>}
                    </CartProductWrapper>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default CheckoutProduct;
