// import { useEffect, useState } from 'react';
// import styled from 'styled-components';

// const ReviewTag = ({ tagList, setTagList, edit }) => {
//   const [tag, setTag] = useState('');
//   const [tagDropdown, setTagDropdown] = useState(false);

//   const handleKeyDown = e => {
//     if (tag !== '' && e.key === 'Enter') {
//       e.preventDefault();
//       setTimeout(() => {
//         submitTagItem();
//       }, 0);
//     }
//     if (e.key === 'Backspace' && tag === '') {
//       removeTag();
//     }
//   };

//   const submitTagItem = () => {
//     if (tagList?.length > 0) {
//       setTagList([...tagList, tag]);
//     } else {
//       setTagList([tag]);
//     }
//     setTag('');
//     setTagDropdown(false);
//   };

//   const removeTag = () => {
//     if (tagList?.length === 1) {
//       setTagList([]);
//     } else {
//       setTagList(tagList?.filter((tag, idx) => idx !== tagList?.length - 1));
//     }
//   };

//   const onChangeTag = e => {
//     setTagDropdown(true);
//     setTag(e.target.value);
//   };

//   const onClickAddTag = () => {
//     if (tagList?.length > 0) {
//       setTagList([...tagList, tag]);
//     } else {
//       setTagList([tag]);
//     }
//     setTag('');
//     setTagDropdown(false);
//   };

//   useEffect(() => {
//     if (edit) {
//       const tagarr = tag.split(',');
//       setTagList(tagarr);
//     }
//   }, []);

//   return (
//     <WholeBox>
//       <div className='form-group form-group-h'>
//         <label className='form-label'>Tags</label>
//         <TagBox>
//           {tagList?.map((tag, idx) => (
//             <TagItem key={idx}>
//               <span className='tag'>{tag}</span>
//             </TagItem>
//           ))}

//           <TagInput
//             onKeyDown={handleKeyDown}
//             onChange={e => onChangeTag(e)}
//             value={tag}
//             placeholder={tagList?.length == 0 ? 'Input your tags' : ''}
//           />
//         </TagBox>
//         {tagDropdown && (
//           <TagContainer className='add-tag' onClick={() => onClickAddTag()}>
//             Add <span className='add-blod'>{tag}</span>...
//           </TagContainer>
//         )}
//       </div>
//     </WholeBox>
//   );
// };

// export default ReviewTag;

// const TagContainer = styled.div`
//   border: 1px solid #aaa;
//   border-top: none;
//   height: 2.5rem;
//   line-height: calc(2.5rem - 2px);
//   font-size: 0.875rem;
//   padding: 0 0.5rem;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   -webkit-border-radius: 0;
//   -moz-border-radius: 0;
//   border-radius: 0;
//   -moz-appearance: none;
//   appearance: none;
//   width: 100%;
//   background-color: #00c900;
//   color: #fff;

//   .add-blod {
//     font-size: 17px;
//     font-weight: 700;
//   }
// `;

// const WholeBox = styled.div``;

// const TagBox = styled.div`
//   border: 1px solid #aaa;
//   min-height: 3rem;
//   line-height: calc(2.5rem - 2px);
//   font-size: 0.875rem;
//   padding: 5px;

//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   -webkit-border-radius: 0;
//   -moz-border-radius: 0;
//   border-radius: 0;
//   -moz-appearance: none;
//   appearance: none;
//   width: 100%;
//   background-color: #fff;
// `;

// const TagItem = styled.div`
//   display: inline-flex;
//   align-items: center;
//   background-color: rgb(12, 161, 1);
//   color: rgb(255, 255, 255);
//   font-weight: 400;
//   cursor: pointer;
//   height: 60%;
//   margin: 0px 5px;

//   .tag {
//     font-size: 16px;
//     padding: 0px 5px;
//   }
// `;

// const TagInput = styled.input`
//   display: inline-flex;

//   background: transparent;
//   border: none;
//   outline: none;
//   cursor: text;
//   padding-left: 10px;
// `;

const ReviewTag = ({ tags }) => {
  return (
    <>
      <div className='form-group form-group-h'>
        <label className='form-label'>Tags</label>
        <div className='form-control'>
          <input
            type='text'
            className='form-input'
            placeholder='Input your tags'
            name='tags'
            value={tags?.join(',')}
          />
        </div>
      </div>
    </>
  );
};

export default ReviewTag;
