import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getHeaderBanner, getTopBanner } from '../../action/request';
import { RETURN_CODE } from '../../action/constant';
import { ImgStyled } from '../../styles/ImgStyled';

// - 이미지를 지정할 수 있다.
// - 링크 URL을 설정할 수 있고 이미지를 클릭하면 해당 주소로 이동한다.
// - 뉴스 티커 형태의 자막이 흘러가도록 설정할 수도 있다.
//    - 텍스트의 내용과 색 역시 어드민의 [배너 설정 기능]에서 지정한다.
function Banner() {
  const [bannerInfo, setbannerInfo] = useState({});

  const navigate = useNavigate();
  const isLandingPage = useLocation().pathname.includes('/landing');

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    if (!path?.includes('http')) {
      // 리액트 내부 링크일 때 리액트에서 이동
      navigate(path);
    } else {
      // 리액트 외부 링크일 때 새로운 창 열기
      window.open(path, '_blank');
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await getTopBanner();
      if (data.returnCode === RETURN_CODE.C00000) {
        setbannerInfo(data.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className='topBanner'
      style={{ background: '#43E25A', display: isLandingPage ? 'none' : '' }}
    >
      <div className='centerWrap' onClick={e => goTo(e, bannerInfo.url)}>
        <ImgStyled src={bannerInfo.img} className='img1' alt={bannerInfo.title} />
      </div>
    </div>
  );
}

export default Banner;
