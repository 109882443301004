const RequestTitle = ({ subject, setSubject }) => {
  return (
  <>
    <div className="form-group form-group-h">
    <label className="form-label">Title</label>
    <div className="form-control">
        <input
        type="text"
        className="form-input"
        placeholder="Input your title"
        name="subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        />
    </div>
    </div>
  </>
  );
};

export default RequestTitle;