import { useEffect, useState } from 'react';

import { yearlySales } from '../../../../action/request';
import styled from 'styled-components';
import SalesMonthlyTd from './SalesMonthlyTd';
import moment from 'moment';
import HoverEvent from '../../../../component/common/HoverEvent';
import { ImgStyled } from '../../../../styles/ImgStyled';

const SalesMonthly = ({ year, moveScroll, designerMargin }) => {
  const [months, setMonths] = useState([]);


  const fillMissingMonths = data => {
    const thisMonth = moment().format('MM');
    const nowYear = moment().format('YYYY');

    const monthLimit = Number(year) === Number(nowYear) || !year ? parseInt(thisMonth, 10) : 12;

    const allMonths = Array.from({ length: monthLimit }, (_, i) => {
      const month = (i + 1).toString().padStart(2, '0');
      return {
        month,
        downloads: 0,
        fees: '0',
        earnings: '0.00',
        monthDate: `${year}-${month}-01`,
      };
    }).reverse();

    const dataMap = new Map(data.map(item => [item.month, item]));

    return allMonths.map(monthData => dataMap.get(monthData.month) || monthData);
  };

  useEffect(() => {
    // 넘어오는 year 정보가 없을 경우 현재 년도로 api 호출
    const thisYear = new Date().getFullYear();

    const getYearlySales = async () => {
      try {
        const { data: res } = await yearlySales(year ? year : thisYear);

        if (res.result !== undefined) {
          setMonths(res.result);
          if (res.result.length >= 12) {
            setMonths(res.result);
          } else {
            const data = res.result;
            setMonths(fillMissingMonths(data));
          }
        } else {
          const nowYear = moment().format('YYYY');
          const thisMonth = moment().format('MM');
          const allMonths = Array.from(
            { length: Number(year) === Number(nowYear) || !year ? parseInt(thisMonth, 10) : 12 },
            (_, i) => {
              const month = (i + 1).toString().padStart(2, '0');
              return {
                month,
                downloads: 0,
                fees: '0',
                earnings: '0.00',
                monthDate: `${year}-${month}-01`,
              };
            }
          ).reverse();
          setMonths(allMonths);
        }
      } catch (e) {
        alert(e);
      }
    };
    getYearlySales();
  }, [year]);

  return (
    <Monthly>
      <table>
        <colgroup>
          <col style={{ width: '6em' }} />
          <col width='' />
          <col width='' />
          <col width='' />
          <col style={{ width: '10em' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Month</th>
            <th>Download</th>
            <th>
              <TooltipWrap>
                <Tooltip>
                  Fees
                  <HoverEvent text={`${designerMargin}% of gross sales`} page='mySales'>
                    <TooltipText>ⓘ</TooltipText>
                  </HoverEvent>
                </Tooltip>
              </TooltipWrap>
            </th>
            <th>
              <TooltipWrap>
                <Tooltip>
                  Earnings
                  <HoverEvent text='Total income minus fees' page='mySales'>
                    <TooltipText>ⓘ</TooltipText>
                  </HoverEvent>
                </Tooltip>
              </TooltipWrap>
            </th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody id='montlyReport'>
          {months &&
            months?.map((data, index) => {
              return <SalesMonthlyTd data={data} key={index} moveScroll={moveScroll} />;
            })}
        </tbody>
      </table>
    </Monthly>
  );
};
export default SalesMonthly;

const Monthly = styled.div`
  min-width: 550px;
  overflow: scroll;

  @media screen and (max-width: 480px) {
    min-width: 480px;
    overflow: scroll;
  }
`;

const TooltipWrap = styled.div`
  position: relative;
`;

const Tooltip = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TooltipText = styled.div`
  cursor: pointer;
  margin-left: 5px;
`;
