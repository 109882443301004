import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  ItemButton,
  ManageDropBoxContainer,
  ManageDropBoxItem,
  ManageDropBoxList,
  ManageTitleBox,
  ManageTitleBoxLeft,
  Placeholder,
  TitleBox,
} from './styles/manageDropBoxStyle';
import { RequiredText } from './styles/ManageUploadInputStyle';

const ManageDropBox = ({
  placeholder,
  title,
  placeholderSize,
  setDropSelectValue,
  dropSelectValue,
  titleLeft,
  titleSize,
  data,
  requireText,
  isRequired,
  setParams,
  params,
  submitData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = e => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleClickItem = item => {
    setDropSelectValue(item);
    // setParams({ ...params, idCategory: item?.id_category, offset: 0 });
    if (submitData) {
      submitData(item);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box>
      <TitleBox>
        {!titleLeft && title && <ManageTitleBox titleSize={titleSize}>{title}</ManageTitleBox>}
        {requireText && isRequired && <RequiredText>* {requireText}</RequiredText>}
      </TitleBox>

      <ManageDropBoxContainer
        className='ManageDropBox'
        titleLeft={titleLeft}
        isRequired={isRequired}
      >
        {titleLeft && <ManageTitleBoxLeft titleSize={titleSize}>{title}</ManageTitleBoxLeft>}

        <Button
          onClick={() => setIsOpen(true)}
          placeholderSize={placeholderSize}
          className='manage-select-button'
          type='button'
        >
          <Placeholder selectItem={dropSelectValue?.name} placeholderSize={placeholderSize}>
            {dropSelectValue?.name ? dropSelectValue?.name : placeholder}
          </Placeholder>
          <img src='/assets/img/mange/navigate_next.png' alt='arrow-down' />
        </Button>
        {isOpen && (
          <ManageDropBoxList ref={dropdownRef} title={title}>
            {data?.map((item, index) => (
              <ManageDropBoxItem key={index} onClick={() => setIsOpen(false)}>
                <ItemButton onClick={() => handleClickItem(item)}>{item.name}</ItemButton>
              </ManageDropBoxItem>
            ))}
          </ManageDropBoxList>
        )}
      </ManageDropBoxContainer>
    </Box>
  );
};

export default ManageDropBox;
