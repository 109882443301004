import { useState } from 'react';
import styled from 'styled-components';

const ToggleSwitch = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <ToggleSwitchStyle active={isActive} onClick={handleClick}>
      <ToggleKnob active={isActive} />
    </ToggleSwitchStyle>
  );
};

export default ToggleSwitch;

const ToggleSwitchStyle = styled.div`
  width: ${props => (props.active ? '26px' : '22px')};
  height: ${props => (props.active ? '16px' : '12px')};
  background: ${props => (props.active ? '#00C02A' : '#fff')};
  border-radius: 12px;
  position: relative;
  border: ${props => (props.active ? 'none' : '2px solid #6f6f6f')};
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.active ? 'flex-end' : 'flex-start')};
`;

const ToggleKnob = styled.div`
  width: ${props => (props.active ? '12px' : '10px')};
  height: ${props => (props.active ? '12px' : '10px')};
  background: white;
  border-radius: 50%;
  position: absolute;
  left: -2px;
  border: ${props => (props.active ? 'none' : '2px solid #6f6f6f')};

  transition: transform 0.3s ease;
  transform: ${props => (props.active ? 'translateX(15px)' : 'translateX(0)')};
`;
