export const isVideo = img => {
  return img.type === 'video';
};

export const isFree = free => {
  return free !== 'n';
};

export const isIncluded = itemNumber => {
  ///웰컴 무료상품 주석 처리
  // const freeDownloadItems = [439, 410, 321, 277, 229];
  // const isFreeItem = freeDownloadItems.includes(itemNumber);
  // return isFreeItem;
};
