import { ImgStyled } from '../../../styles/ImgStyled';
import {
  ImgWrapper,
  InputStyle,
  LabelStyle,
  ManageInputContainer,
} from './styles/manageSearchInputStyle';

const ManageSearchInput = ({ id, label, type, placeholder, value, onChange }) => {
  return (
    <ManageInputContainer>
      <LabelStyle htmlFor={id}>{label}</LabelStyle>
      <InputStyle id={id} type={type} placeholder={placeholder} value={value} onChange={onChange} />
      <ImgWrapper>
        <ImgStyled src='/assets/img/mange/search.png' alt='search' />
      </ImgWrapper>
    </ManageInputContainer>
  );
};

export default ManageSearchInput;
