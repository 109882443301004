const ProfileEmail = ({ subscription, onChange, onClick, disabled }) => {
  const isSubscribe = category => {
    return (
      subscription.filter(s => {
        return s.category === category;
      }).length === 0
    );
  };

  return (
    <>
      <div className='content-box-header' style={{ marginTop: '30px' }}>
        <h2>Email Subscription</h2>
      </div>
      <div className='content-box-body'>
        <form className='form' name='subscribe'>
          <div className='form-group form-group-inline'>
            <label className='form-label'>Newsletter</label>
            <div className='form-control'>
              <label className='input-checkbox'>
                <input
                  type='checkbox'
                  name='newsletter'
                  onChange={e => onChange('1', e.target.checked)}
                  checked={isSubscribe('1')}
                />
                <span className='label'></span>
              </label>
            </div>
          </div>
          <div className='form-group form-group-inline'>
            <label className='form-label'>Discount, Sales</label>
            <div className='form-control'>
              <label className='input-checkbox'>
                <input
                  type='checkbox'
                  name='discount'
                  onChange={e => onChange('2', e.target.checked)}
                  checked={isSubscribe('2')}
                />
                <span className='label'></span>
              </label>
            </div>
          </div>
          <div className='form-group form-group-inline'>
            <label className='form-label'>New Items</label>
            <div className='form-control'>
              <label className='input-checkbox'>
                <input
                  type='checkbox'
                  name='newitems'
                  onChange={e => onChange('3', e.target.checked)}
                  checked={isSubscribe('3')}
                />
                <span className='label'></span>
              </label>
            </div>
          </div>
          <div className='form-action'>
            <button
              type='button'
              className='btn btn-green btn-fill'
              data-action='subscribe'
              onClick={onClick}
              disabled={disabled}
            >
              Save Subscription
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProfileEmail;
