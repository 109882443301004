import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../constant';
import DownloadProcessingModal from './DownloadProcessingModal';

const WelcomeModal = () => {
  const [downloadModalStep, setDownloadModalStep] = useState(0);
  const navigate = useNavigate();
  const [WelcomeUser, setWelcomeUser] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

  document.body.style.overflow = 'hidden';

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    return () => (document.body.style.overflow = 'unset');
  }, []);

  const clickDownload = () => {
    setWelcomeUser(true);
    setDownloadModalStep(1);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //다운로드 한 item 넘버

  return (
    <>
      <WelcomeModalContainer isMobile={isMobile}>
        <div className='img-container'>
          <img
            src={`${isMobile ? '/assets/img/welcomModal_mo.png' : '/assets/img/welcomModal.png'}`}
            alt=''
            className='welcome-modal'
          />
          <button onClick={clickDownload} className='btn  btn-download' download>
            DOWNLOAD FREE MODELS
          </button>
        </div>
      </WelcomeModalContainer>
      {/* 다운로드 모달 */}
      {downloadModalStep > 0 && (
        <DownloadProcessingModal
          step={downloadModalStep}
          setStep={setDownloadModalStep}
          WelcomeUser={WelcomeUser}
        />
      )}
    </>
  );
};

const WelcomeModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);

  .img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }

  .welcome-modal {
    z-index: 1005;
    width: 100%;
    max-width: ${props => (props.isMobile ? '360px' : '800px;')};
    margin: 0 auto;
  }

  button {
    font-size: 16px;
    padding: 30px;
    background: linear-gradient(
      90deg,
      rgba(0, 206, 120, 1) 20.308934771825395%,
      rgba(0, 206, 119, 1) 20.308934771825395%,
      rgba(0, 236, 94, 1) 80.31017485119048%
    );
    border: none;
    border-radius: 999px;
    font-weight: bold;
    max-width: 381px;
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: ${props => (props.isMobile ? '-160px' : '-140px;')};
  }

  button:after {
    content: ${props => (props.isMobile ? '' : "''")};
    display: inline-block;
    background: url('/assets/img/welcome_download.png') no-repeat;
    background-size: cover;
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 357px) {
    button {
      margin-top: -47%;
      text-align: center;
      line-height: 1;
    }
  }
`;

export default WelcomeModal;
