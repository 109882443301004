import { useNavigate } from 'react-router-dom';
import { ABOUT, ABOUT_CEO } from '../../../route/constant';
import './AboutCoCeoKo.css';
const AboutCoCeoKo = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };
  return (
    <>
      <article className='fab-founder-detail'>
        <div className='--desc'>
          <section className='fab-page-section fab-page-section-ko'>
            <div className='container'>
              <div className='--desc-header --mb-60 --mb-40--m'>
                <h2 className='--name --txt-xxl --mb-5'>
                  <strong>이 종 한</strong>
                </h2>
                <p className='--role --font-basic'>공동 최고 경영자 겸 플랫폼 서비스 책임자</p>
              </div>
              <div className='--desc-body --mb-80 --mb-50--m'>
                <p className='--font-basic'>
                  FAB365의 최고 경영자 겸 플랫폼 서비스 책임자인 이종한은 회사의 글로벌 서비스를
                  총괄하여 전세계 수많은 사용자들이 질높은 서비스를 이용할 수 있도록 이끌고
                  있습니다. 이종한은 한국과 중국에서 흥행한 게임 던전앤파이터를 서비스한 게임회사
                  네오플 창업에 공헌하였으며, 이후 국내 최고의 소셜커머스 위메프를 공동 창업하여
                  성공적으로 런칭하는게 기여하였습니다.
                  <br />
                  <br />
                  IT 비스니스 전문가로 스타트업 팀빌딩과 조직운영, e-Commerce에 대한 풍부한 경험으로
                  FAB365의 비즈니스 전략을 책임지고 있습니다. 또한 FAB365를 통해 누군가의 창의적인
                  아이디어가 시간과 공간을 초월하여 실물로 제작될 수 있다는 점에서 창작자의 사업적인
                  성공으로 이끌고자 하는 비전을 제시하고자 합니다.
                </p>
              </div>
              <div className='--desc-footer'>
                <a href='' className='--more' onClick={e => goTo(e, ABOUT)}>
                  <span className='--font-basic'>← Back</span>
                </a>
                <a href='' className='--more' onClick={e => goTo(e, ABOUT_CEO)}>
                  <span className='--font-basic'>CEO /Goo Sang kwon →</span>
                </a>
              </div>
            </div>
          </section>
        </div>

        <div
          className='--img'
          style={{
            backgroundImage:
              "url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/founder/ceo2.jpg')",
          }}
        ></div>
      </article>
    </>
  );
};

export default AboutCoCeoKo;
