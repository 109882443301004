import React, { useContext } from 'react';
import Layout from '../../../component/layout/Layout';
import LangContext from '../../../context/lang/lang';
import Korean from './components/Korean';
import English from './components/English';

function TermsPoint() {
  const { state } = useContext(LangContext);

  return <Layout>{state.lang === 'ko' ? <Korean /> : <English />}</Layout>;
}

export default TermsPoint;
