import { useContext } from 'react';
import CartContext from '../../../context/cart.js/carts';
import { ITEMS } from '../../../route/constant';
import CartPrice from './CartPrice';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ImgStyled } from '../../../styles/ImgStyled';

const CartProductBuyable = ({ data, deleteItem }) => {
  const { state, action } = useContext(CartContext);
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='purchase-product-list-item-inner'>
        <div className='checker'>
          <label className='input-checkbox'>
            {data.isBuyable && (
              <input
                type='checkbox'
                value={data.productId}
                checked={state.check[data.productId] && state.check[data.productId] !== 'false'}
                onChange={e => {
                  if (e.target.checked) {
                    action.checkProduct(data.productId, data);
                  } else {
                    action.uncheckProduct(data.productId);
                  }
                }}
                data-price={data.salesPrice}
                data-name='cart-product'
              />
            )}
            {!data.isBuyable && (
              <input type='checkbox' value={data.producId} data-price='0' disabled />
            )}
            <span className='label'></span>
          </label>
        </div>
        <ImgStyled src={data.itemRowDtos.list_img} className='tb' alt='' />
        <CartProductWrapper className='product-meta' cartItem={'cartItem'}>
          <span className='product-name'>{data.itemRowDtos.name_en}</span>
          {data.isBuyable && (
            <SpanWidth className='price notranslate' cartItem={'cartItem'}>
              <CartPrice data={data} />
            </SpanWidth>
          )}
          {!data.isBuyable && <span className='warn-3c9a'>{data.isBuyableErrorMsg}</span>}
        </CartProductWrapper>
        <a
          className='btn-delete'
          data-action='cart-delete'
          data-item={data.productId}
          onClick={() => deleteItem(data.productId)}
        >
          ×
        </a>
      </div>
    </>
  );
};
const CartProduct = ({ data, deleteItem }) => {
  return (
    <>
      <li>
        {data.isBuyable && (
          <div className='purchase-product-list-item'>
            <CartProductBuyable data={data} deleteItem={deleteItem} />
          </div>
        )}
        {!data.isBuyable && (
          <div className='purchase-product-list-item already-bought-3c3a'>
            <CartProductBuyable data={data} deleteItem={deleteItem} />
          </div>
        )}
      </li>
    </>
  );
};

export const CartProductWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 1100px) {
    flex-direction: ${props => props.cartItem && 'column'};
    gap: ${props => props.cartItem && '10px'};
  }
`;

export const SpanWidth = styled.span`
  @media screen and (max-width: 1100px) {
    width: ${props => props.cartItem && '100%'};
  }
`;

export default CartProduct;
