// import React, { useState } from 'react';
// import styled from 'styled-components';

// const Dropdown = ({ options, onChangeSort, selectedSort }) => {
//   const optionData = options?.filter(el => el.value !== selectedSort.value);
//   const [isOpen, setIsOpen] = useState(false);

//   const onClickSort = sort => {
//     onChangeSort(sort);
//     setIsOpen(false);
//   };

//   return (
//     <DropdownWrap>
//       <div className='seleted' onClick={() => setIsOpen(!isOpen)}>
//         <p>{selectedSort?.title}</p>
//         <img src='/assets/img/designers/icon_down.png' alt='' className='icon' />
//       </div>
//       <>
//         {isOpen ? (
//           <Options>
//             {optionData?.map((option, key) => {
//               return (
//                 <Option key={key} onClick={() => onClickSort(option)}>
//                   {option.title}
//                 </Option>
//               );
//             })}
//           </Options>
//         ) : (
//           ''
//         )}
//       </>
//     </DropdownWrap>
//   );
// };

// export default Dropdown;

// const DropdownWrap = styled.button`
//   border: 1px solid #6e6e6e !important;
//   margin-top: 30px;
//   border-radius: 10px;
//   height: 36px;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0px 10px;
//   min-width: 100px;

//   .seleted {
//     display: flex;
//     justify-content: flex-end;
//     margin-right: 5px;
//     border-radius: 10px;
//   }
//   p {
//     font-weight: 600;
//     color: #171717;
//     font-size: 16px;
//     cursor: pointer;
//     margin-right: 3px;
//   }

//   .icon {
//     width: 10px;
//     cursor: pointer;
//     padding-bottom: 3px;
//   }
// `;

// const Options = styled.ul`
//   position: absolute;
//   top: 36px;
//   padding-top: 30px;
//   z-index: 999;
//   background-color: #ffffff;
//   padding: 0px 20px !important;
//   border: 1px solid #6e6e6e;
//   border-radius: 10px;
//   width: 219px;
// `;

// const Option = styled.li`
//   height: 36px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   font-weight: 600;
//   color: #171717;
//   font-size: 16px;
//   cursor: pointer;
//   img {
//     width: 11px;
//     padding-bottom: 8px;
//     margin-left: 5px;
//   }
// `;

import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Dropdown = ({ options, onChangeSort, selectedSort }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <DesignerSectionDropDownStyle id='DesignerSectionDropDown' ref={dropdownRef}>
      <ButtonWrapper onClick={() => setIsOpen(!isOpen)}>
        <ButtonStyle>{selectedSort.title}</ButtonStyle>
        <img src='/assets/img/designers/icon_down.png' alt='' />
      </ButtonWrapper>
      {isOpen && (
        <UlStyle>
          {options.map((option, index) => {
            return (
              <LiStyle
                key={index}
                onClick={() => {
                  onChangeSort(option);
                  setIsOpen(false);
                }}
              >
                {option.title}
              </LiStyle>
            );
          })}
        </UlStyle>
      )}
    </DesignerSectionDropDownStyle>
  );
};

const DesignerSectionDropDownStyle = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  min-width: 100px;

  padding: 0px 24px;
  height: 38px;
  font-size: 16px;
  border: 1px solid #6e6e6e;
  border-radius: 10px;
  background-color: #fff;

  white-space: nowrap;
  cursor: pointer;
`;

const LiStyle = styled.li`
  cursor: pointer;
  height: 36px;
  font-family: 'Inter';
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.button`
  width: 100%;
  height: 100%;
  font-size: 16px !important;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  img {
    width: 8px;
    margin-left: 6px;
    margin-bottom: 2px;
  }
`;

const ButtonStyle = styled.div`
  border: none;
  font-weight: 500 !important;
  color: rgb(23, 23, 23) !important;
  font-size: 16px !important;
  font-family: 'Inter';
`;

const UlStyle = styled.ul`
  background-color: #fff;
  text-align: right;
  font-weight: 500 !important;
  border: 1px solid #6e6e6e;
  position: absolute;
  top: 41px;
  right: -2px;
  border-radius: 10px;
  z-index: 100;
  box-sizing: border-box;
  padding: 12px 40px !important;
`;

export default Dropdown;
