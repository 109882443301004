import { useEffect } from 'react';
import ManageUploadInput from '../../../mangemodel/common/ManageUploadInput';
import {
  AdditionalInfoContainer,
  InputWrapper,
  LargestPartWrapper,
  PartSizeInput,
  TitleBox,
  WeightWrapper,
} from './additionallinfoStyle';
import TagList from './TagList';
import LargestPartSIze from '../../../mangemodel/common/LargestPartSIze';
import { positiveValue, positiveValueWithDecimal } from '../../../section/util';

const AdditionalInfo = ({
  setAddInfo,
  addInfo,
  tag,
  setTag,
  largestPartSize,
  printingTime,
  totalWeight,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    setAddInfo({
      printingTime: '',
      totalWeight: '',
      largestPartSize: { xAxis: '', yAxis: '', zAxis: '' },
    });
  };

  const onChange = e => {
    setAddInfo(pre => ({
      ...pre,
      [e.target.id]: positiveValue(e.target.value),
      largestPartSize: { ...pre.largestPartSize, [e.target.id]: positiveValue(e.target.value) },
    }));
  };

  const onKeyDown = e => {
    if (
      !/[0-9.]/.test(e.key) && // 숫자 및 소수점
      e.key !== 'Backspace' && // 백스페이스
      e.key !== 'Delete' && // Delete 키
      e.key !== 'ArrowLeft' && // 왼쪽 화살표
      e.key !== 'ArrowRight' && // 오른쪽 화살표
      e.key !== 'Tab' // 탭 키
    ) {
      e.preventDefault(); // 위 키들이 아닌 경우 입력 방지
    }
  };

  return (
    <AdditionalInfoContainer>
      <TitleBox>
        <h2 className='title'>Additional Information</h2>
        <p className='description'>
          Fill in all the information to increase your chances of making a sale!
        </p>
        <span className='description'>(Subject to change by the administrator.)</span>
      </TitleBox>
      <form onSubmit={handleSubmit}>
        <TagList setTag={setTag} tag={tag} />
        <WeightWrapper>
          <ManageUploadInput
            type='number'
            label='Printing time'
            id='printingTime'
            placeholder='Please enter the estimated total print time'
            placeho
            symbol='hours'
            symbolSize='16px'
            value={printingTime || ''}
            name='printingTime'
            onChange={onChange}
            textAlign='right'
            onKeyDown={e => positiveValueWithDecimal(e)}
          />
          <ManageUploadInput
            type='number'
            label='Total weight'
            id='totalWeight'
            placeholder='Please enter the estimated filament consumption'
            symbol='g'
            symbolSize='16px'
            name='totalWeight'
            value={totalWeight}
            onChange={onChange}
            textAlign='right'
            onKeyDown={e => positiveValueWithDecimal(e)}
          />
          <LargestPartSIze largestPartSize={largestPartSize} onChange={onChange} />
        </WeightWrapper>
      </form>
    </AdditionalInfoContainer>
  );
};

export default AdditionalInfo;
