import { useContext, useState } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import './Business.css';
const Business = () => {
  const [isShop, setIsShop] = useState(false);
  const [isEdu, setIsEdu] = useState(false);
  const { state, action } = useContext(LangContext);
  const toggleAnchor = (setter, value) => {
    setter(!value);
  };
  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top about page-top-with-breadcrumb'>
            <div className='container container-lg'>
              <h1 className='page-title'>Business License</h1>
            </div>
          </div>

          <div className='container'>
            <div className='tip183_container tip183_tips_detail' id='problem1'>
              <div className='tip183_back top'></div>
              <div className='tip183_problem'>
                <div className='tip183_tit'>
                  <p>Fab365</p>
                  <h3>Business License</h3>
                </div>
                <p>
                  {action.transBusiness('title')}
                  <br />
                  <br />
                  {action.transBusiness('h1')}
                </p>
                <ul className='tip183_slice_list'>
                  <li>
                    <div
                      className={'tip183_re' + (isShop ? ' tip183_on' : '')}
                      onClick={() => toggleAnchor(setIsShop, isShop)}
                    >
                      <strong>{action.transBusiness('h_shop')}</strong>
                    </div>
                    <div className='tip183_detail' style={{ display: isShop ? 'block' : 'none' }}>
                      <div className='tip183_txt'>
                        <p>{action.transBusiness('business1')}</p>
                        <br />
                        <p>{action.transBusiness('business2')}</p>
                        <p>{action.transBusiness('business3')}</p>
                        <br />
                        <p>&nbsp;&nbsp;{action.transBusiness('business4')}</p>
                        <p>&nbsp;&nbsp;{action.transBusiness('business5')}</p>
                        <p>&nbsp;&nbsp;{action.transBusiness('business6')}</p>
                        <p>&nbsp;&nbsp;{action.transBusiness('business7')}</p>
                        <br />
                        <p>{action.transBusiness('business8')}</p>
                        <p>{action.transBusiness('business9')}</p>
                        <br />
                        <p>
                          <a
                            className='txt-green'
                            target='_blank'
                            href='https://www.patreon.com/goodesign/posts'
                            rel='noreferrer'
                          >
                            https://www.patreon.com/fab365
                          </a>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div
                      className={'tip183_re' + (isEdu ? ' tip183_on' : '')}
                      onClick={() => toggleAnchor(setIsEdu, isEdu)}
                    >
                      <strong>{action.transBusiness('h_edu')}</strong>
                    </div>
                    <div className='tip183_detail' style={{ display: isEdu ? 'block' : 'none' }}>
                      <div className='tip183_txt'>
                        <p>{action.transBusiness('edu_1')}</p>
                        <p>{action.transBusiness('edu_2')}</p>
                        <p>{action.transBusiness('common_duration')}</p>
                        <p>{action.transBusiness('common_paypal')}</p>
                        <p className='warning'>{action.transBusiness('common_w1')}</p>
                        <p className='warning'>{action.transBusiness('common_w2')}</p>
                        <p className='warning'>{action.transBusiness('common_w3')}</p>
                        <p className='warning'>{action.transBusiness('common_w5')}</p>
                        <p>{action.transBusiness('common_email')}</p>
                        <div className='tip183_img'>
                          <ul>
                            <li></li>
                          </ul>
                          <p>1. {action.transBusiness('edu_i1')}</p>
                          <p>2. {action.transBusiness('edu_i2')}</p>
                          <p>3. {action.transBusiness('shop_i3')}</p>
                          <p>4. {action.transBusiness('shop_i4')}</p>
                          <p>5. {action.transBusiness('shop_i5')}</p>
                          <p>6. {action.transBusiness('shop_i6')}</p>
                          <p>7. {action.transBusiness('edu_i7')}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='tip183_back'></div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Business;
