import Layout from "../../component/layout/Layout";

const SlicerFiles = () => {
  return (
    <>
      <Layout>
        <div id="main">
          <div className="container">
            <div className="terms">
              <div className="terms-header">
                <h1 className="terms-title">Slice Setting File List</h1>
              </div>
              <div className="terms-body padding-bottom-zero">
                <div className="term-block">
                  <h3>
                    <p>
                      Please check the list of slice setting files now
                      available.
                      <br />
                      The slice setting files will be updated continuously.
                    </p>
                  </h3>
                </div>
                <div className="term-block" style={{ maxWidth: "700px" }}>
                  <h3>
                    <p>
                      <img
                        className="help-review-image"
                        src="/assets/img/slice setting files list.png"
                        alt=""
                      />
                    </p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default SlicerFiles;
