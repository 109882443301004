import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import './UsePoint.css';
const UsePoint = ({ tab, title }) => {
  return (
    <>
      <Layout>
        <div id='main'>
          <Menu tab={tab} title={title} />{' '}
          <section className='about-section'>
            <div className='help'>
              <div className='container'>
                <div className='about-description-block'>
                  <div className='about-description-block-header'>
                    <h2>Help center</h2>
                    <p>How to use point?</p>
                  </div>
                </div>

                <div className='help-block'>
                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>1) You must log in.</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/point-how-to-use/point-how-to-use.1.png'
                    className='help-img'
                    alt=''
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>2) Go to the point page.</p>
                    </div>
                    <div className='about-description-block-body'>
                      <img
                        src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/point-how-to-use/point-how-to-use.2.png'
                        className='help-img half-img'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>3) You can check the point you have.</p>
                    </div>
                    <div className='about-description-block-body'>
                      <img
                        src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/point-how-to-use/point-how-to-use.3.png'
                        className='help-img half-img'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>
                        4) Enter the points to be used on the payment page. This is the case when
                        you enter less points than the amount paid.
                      </p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/point-how-to-use/point-how-to-use.4.png'
                    className='help-img'
                    alt=''
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>5) This is the case when the entire amount is paid with points.</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/point-how-to-use/point-how-to-use.5.png'
                    className='help-img'
                    alt=''
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>6) After payment, go to the points page.</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/point-how-to-use/point-how-to-use.6.png'
                    className='help-img'
                    alt=''
                  />

                  <div className='about-description-block'>
                    <div className='about-description-block-header'>
                      <p>7) You can check the point history on the points page.</p>
                    </div>
                  </div>
                  <img
                    src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/help/point-how-to-use/point-how-to-use.7.png'
                    className='help-img'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};
export default UsePoint;
