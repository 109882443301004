import React, { useContext, useEffect, useState } from 'react';
import { FOOTER_TERMS_POINT } from '../../../../route/constant';
import { PAGE_FOOTER_HEADER_TERMS_POINT } from '../../../../context/lang/constant';
import LangContext from '../../../../context/lang/lang';
import {
  CREATING_PAYMENT,
  NOT_AGREED_MESSAGE,
  NOT_CHOSEN_POINT,
  PROCESSING_PAYMENT,
} from './constant';
import Payment from './Payment';
import { ls } from '../../../../constant';
import { useNavigate } from 'react-router-dom';

const chargeArray = [
  {
    usdAmount: 50,
    totalCredits: '5,500',
    chargeCredits: '5,000',
    bonusPercent: '10%',
    bonusCredits: '500',
    rewardTicket: null,
  },
  {
    usdAmount: 100,
    totalCredits: '11,500',
    chargeCredits: '10,000',
    bonusPercent: '15%',
    bonusCredits: '1,500',
    rewardTicket: null,
  },
  {
    usdAmount: 200,
    totalCredits: '24,000',
    chargeCredits: '20,000',
    bonusPercent: '20%',
    bonusCredits: '4,000',
    rewardTicket: '1 Reward ticket',
  },
  {
    usdAmount: 400,
    totalCredits: '50,000',
    chargeCredits: '40,000',
    bonusPercent: '25%',
    bonusCredits: '10,000',
    rewardTicket: '2 Reward tickets',
  },
];

function Charge({ onRefresh }) {
  const { state } = useContext(LangContext);
  const [chargePoint, setChargePoint] = useState(0);
  const [isAgree, setIsAgree] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMesage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();

  const handleAgree = () => {
    setIsAgree(prev => {
      localStorage.setItem(ls.isAgree, !prev);
      return !prev;
    });
  };

  const handleChargePoint = point => {
    setChargePoint(point);
    localStorage.setItem(ls.chargePoint, point);
  };

  const checkReadyForPay = () => {
    if (!isAgree && localStorage.getItem(ls.isAgree) !== 'true') {
      setIsError(true);
      setErrorMesage(NOT_AGREED_MESSAGE);
      return false;
    }

    if (chargePoint === 0 && !localStorage.getItem(ls.chargePoint)) {
      setIsError(true);
      setErrorMesage(NOT_CHOSEN_POINT);
      return false;
    }

    setIsError(false);
    setErrorMesage('');
    return true;
  };

  useEffect(() => {
    localStorage.removeItem(ls.isAgree);
    localStorage.removeItem(ls.chargePoint);

    return () => {
      localStorage.removeItem(ls.isAgree);
      localStorage.removeItem(ls.chargePoint);
    };
  }, []);

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='content-box-header'>
        <h2>Charge</h2>
      </div>
      <div className='content-box-body'>
        <div className='point-charge'>
          <p className='notice'>Charge the credits and get the benefits.</p>
          <p className='notice'>The credit terms may change according to our circumstances.</p>
          <p className='notice'>
            {/* TODO: 한글 확인 */}
            <a href='' onClick={e => goTo(e, FOOTER_TERMS_POINT)} style={{ color: '#0000FF' }}>
              {state.lang === 'en' ? 'Credit terms and conditions >>>' : '크레딧 이용약관 >>>'}{' '}
            </a>
          </p>
          <p style={{ fontWeight: 'bold', fontSize: '18px' }}>100 Credits = $1</p>

          <div className='point-charge-items'>
            {chargeArray.map(charge => (
              <div className='point-charge-item'>
                <div
                  className={`point-charge-item-inner ${
                    errorMessage.includes('points') ? 'error' : ''
                  }`}
                >
                  <label className='input-radio'>
                    <input
                      type='radio'
                      name='charge_point'
                      value='50'
                      onChange={() => handleChargePoint(charge.usdAmount)}
                    />
                    <span className='label'>
                      <span className='point-name'>
                        <i className='icon-cash-blue-flexable'></i> {charge.totalCredits} =
                      </span>
                      <div className='point-information-container'>
                        <div className='point-information'>
                          <div className='point-information-box'>
                            <span className='point-information-font--12px'>Charging</span>
                            <span className='point-information-d--flex'>
                              <i className='icon-cash-black'></i>
                              {charge.chargeCredits}
                            </span>
                          </div>
                          <div className='point-information-plus'>+</div>
                          <div className='point-information-box'>
                            <span className='point-information-font--12px'>
                              Bonus ({charge.bonusPercent})
                            </span>
                            <span className='point-information-d--flex'>
                              <i className='icon-cash-black'></i>
                              <span>{charge.bonusCredits}</span>
                            </span>
                          </div>
                        </div>
                        {charge.rewardTicket && (
                          <div className='point-reward-ticket'>+ {charge.rewardTicket}</div>
                        )}
                      </div>
                      <strong className='point'>$ {charge.usdAmount}</strong>
                    </span>
                  </label>
                </div>
              </div>
            ))}
          </div>

          <div className='payment-block'>
            <div className='payment-block-body' id='checkout-license-checkbox'>
              <label className='input-checkbox'>
                <input type='checkbox' name='agreement' value={isAgree} onChange={handleAgree} />
                <span
                  className='label'
                  style={{ color: errorMessage.includes('License') && 'red' }}
                >
                  I have read, understand and accept the Credit terms and conditions
                </span>
              </label>
            </div>
          </div>

          <div className='payment-block'>
            <div
              id='checkout-alert'
              className={
                isError ? 'show error' : isLoading || isProcessing ? 'loading show' : 'hidden'
              }
              style={{ marginTop: '10px' }}
            >
              {isLoading ? CREATING_PAYMENT : isProcessing ? PROCESSING_PAYMENT : errorMessage}
            </div>
            <Payment
              checkReadyForPay={checkReadyForPay}
              chargePoint={chargePoint}
              onRefresh={onRefresh}
              setIsLoading={setIsLoading}
              setIsProcessing={setIsProcessing}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Charge;
