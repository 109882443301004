import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { editModelAtom, selectedTabAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';

const EditModelBtn = ({ designerName, itemId }) => {
  const [, setEditModel] = useRecoilState(editModelAtom);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/designers/${designerName.replace(/\s+/g, '')}`);
    setEditModel({ id: itemId, status: true, detailPage: true });
  };

  return (
    <EditModelBtnContainer onClick={handleClick}>
      <img src='/assets/img/mange/edit_btn.png' alt='edit model' />
      <p>Edit Model</p>
    </EditModelBtnContainer>
  );
};

export default EditModelBtn;

const EditModelBtnContainer = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99px;
  height: 24px;
  border-radius: 6px;
  background-color: #f3f6f9;
  font-family: 'Inter';
  font-size: 12px;
  color: #767676;
  gap: 5px;
  margin-left: auto;

  img {
    width: 13px;
    height: 13px;
    margin-bottom: 2px;
  }
`;
