import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ImgStyled } from '../../styles/ImgStyled';

const ShareButton = ({ detail }) => {
  const [click, setClick] = useState(false);
  const ref = useRef(null);
  const [twitterUrl, setTiwitterUrl] = useState('');
  const [pinUrl, setPinUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setClick(false);
    }
  };

  const handleClick = () => {
    setClick(!click);
  };

  // 트위터 공유
  const buildTwitterUrl = item => {
    setTiwitterUrl(
      'https://twitter.com/intent/tweet?text=' +
        encodeURIComponent(item.name) +
        '&url=' +
        encodeURIComponent('https://fab365.net/items/') +
        item.id
    );
  };
  // 핀터레스트 공유
  const buildPinUrl = item => {
    setPinUrl(
      'https://www.pinterest.com/pin/create/button/?description=' +
        encodeURIComponent(item.name) +
        '&url=' +
        encodeURIComponent('https://fab365.net/items/') +
        item.id +
        '&media=' +
        encodeURIComponent(item.listImg)
    );
  };
  // 페이스북 공유
  const buildFacebookUrl = item => {
    setFacebookUrl(
      'https://www.facebook.com/dialog/share?app_id=' +
        encodeURIComponent(process.env.REACT_APP_FB_APP_ID) +
        '&display=popup&href=' +
        encodeURIComponent('https://fab365.net/items/') +
        item.id
    );
  };

  const linkShare = () => {
    const url = window.location.href; // 현재 페이지의 URL
    navigator.clipboard.writeText(url).then(() => {
      alert('Link copied.');
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // detail 상태 변화시 SNS 공유 정보 업데이트
  useEffect(() => {
    buildFacebookUrl(detail);
    buildPinUrl(detail);
    buildTwitterUrl(detail);
  }, [detail]);

  return (
    <ShareButtonStyle className='ShareButtonStyle' ref={ref}>
      <button onClick={() => setClick(!click)}>
        <ImgStyled className='shared' src='/assets/img/itemDetail/share.png' alt='share' />
      </button>

      {click && (
        <Ul>
          <li onClick={handleClick}>
            <a href={pinUrl} target='_new' className='share-btn'>
              <ImgStyled src='/assets/img/itemDetail/pinter.png' alt='pinter' />
              <span>Pinterest</span>
            </a>
          </li>
          <li onClick={handleClick}>
            <a href={twitterUrl} target='_new' className='share-btn'>
              <ImgStyled src='/assets/img/itemDetail/x.png' alt='x' />
              <span>X</span>
            </a>
          </li>
          <li onClick={handleClick}>
            <a href={facebookUrl} target='_new' className='share-btn'>
              <img src='/assets/img/itemDetail/facebook.png' alt='facebook' />
              <span>Facebook</span>
            </a>
          </li>
          <li onClick={handleClick}>
            <button onClick={linkShare} className='share-btn'>
              <img src='/assets/img/itemDetail/link.png' alt='link' />
              <span>Copy link</span>
            </button>
          </li>
        </Ul>
      )}
    </ShareButtonStyle>
  );
};

const ShareButtonStyle = styled.div`
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;

  button {
    border: none !important;
    background: none !important;
    width: 29px;
    margin: 0;
    cursor: pointer;

    .shared {
      width: 18px;
      height: 20px;
    }
  }
`;

export default ShareButton;

const Ul = styled.ul`
  display: flex;
  gap: 12px;
  border: 1px solid #a1a1a1;
  display: flex;
  flex-direction: column;
  width: 93px;
  text-align: left;
  padding: 15px;
  position: absolute;
  top: 28px;
  right: 0;
  border-radius: 5px;
  background-color: #fff;
  z-index: 10;

  li:nth-child(3) {
    border-bottom: 1px solid #bbbbbb;
    padding-bottom: 15px;
  }

  li:nth-child(4) {
    img {
      width: 15px;
      height: 7.5px;
    }
  }

  img {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }

  li {
    width: 100%;
    line-height: 1.5;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  span {
    color: #616161;
  }

  .share-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  button {
    padding: 0;
    cursor: pointer;
  }
`;
