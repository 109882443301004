import { sanitize } from 'dompurify';

export const isEditable = (comment, idUser) => {
  if (!idUser) return false;

  if (idUser !== comment.oid_user && idUser !== comment.user_id) return false;

  // if (comment.status !== 'active') return false;

  return true;
};

// 리퀘스트 코멘트 전용 (응답데이터가 달라 공통으로 사용 불가)
export const isEditableRequest = (comment, idUser) => {
  if (!idUser) return false;
  // console.log('isEditableRequest', comment, idUser);

  if (idUser !== comment?.user?.id_user && idUser !== comment?.user_id) return false;

  if (comment.status !== 'active') return false;

  return true;
};

// 아이템 상세페이지 코멘트 전용 (응답데이터가 달라 공통으로 사용 불가)
export const isEditableItemComment = (comment, idUser) => {
  if (!idUser) return false;

  if (idUser !== comment?.user?.id_user) return false;

  return true;
};

export const imgsHtml = comment =>
  comment?.imgs?.map((img, idx) => {
    return {
      url: img,
      tb: img,
      src: img,
    };
  });

export const imgHtml = img => {
  return {
    url: img,
    tb: img,
    src: img,
  };
};

export const requestImageHtml = images =>
  images?.map(img => {
    return {
      url: img.url,
      tb: img.url,
      src: img.thumbs[0]?.url,
    };
  });

export const imgsToMedias = imgs => {
  return imgs?.map(img => {
    const parsed = sanitize(img);
    return {
      type: 'img',
      url: parsed,
      thumbs: [{ url: parsed }],
    };
  });
};

export const formatHrefLink = comment => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // https://로 시작하는 내용이 있다면 a 태그로 감싸기
  const replacedContent = comment.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank" class="href-link-style">' + url + '</a>';
  });

  // 공백문자 적용
  const replacedLineBreaks = replacedContent.replace(/\n/g, '<br/>');

  return { __html: replacedLineBreaks };
};
