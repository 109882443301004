import styled from 'styled-components';
import { ImgStyled } from '../../../styles/ImgStyled';
import HoverEvent from '../../../component/common/HoverEvent';
import { fileSize } from '../../../util/file';

const Information = ({
  printingTime,
  totalWeight,
  allFileSize,
  fileCount,
  lpartSizeX,
  lpartSizeY,
  lpartSizeZ,
  released, // 처음 업로드 한 날짜
  dataFileModify, // 마지막 수정 날짜
}) => {
  //마우스 호버시 최초 업로드 날짜 노출
  const size = fileSize(allFileSize);
  const lpartAllTrue = Boolean(lpartSizeX) && Boolean(lpartSizeY) && Boolean(lpartSizeZ);

  // 처음 개시 날짜
  const uploadChangeDate = new Date(released);
  const firstUploadDate = uploadChangeDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // 마지막 업뎃 날짜
  const lastUploadChangeDate = dataFileModify ? new Date(dataFileModify) : null;
  const lastUpdateDate = lastUploadChangeDate
    ? lastUploadChangeDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : null;

  return (
    <InformationContainer className='information'>
      <TitleContainer>
        <p>Information</p>
        <HoverEvent date={`published ${firstUploadDate || lastUpdateDate}`}>
          <span> updated {lastUpdateDate || firstUploadDate}</span>
        </HoverEvent>
      </TitleContainer>
      <BoxStyle>
        <HoverEvent
          text='Actual print times may vary depending on the printer model'
          position='-15px'
        >
          <InformationMenu>
            <div>
              <ImgStyled src='/assets/img/itemDetail/alarm.png' alt='' />
              <p>Printing time</p>
            </div>
            <div className='about'>
              {printingTime ? <p>About</p> : ''}
              <Unknown printingTime={printingTime}>
                {printingTime ? `${printingTime} hours` : 'Unknown'}
              </Unknown>
            </div>
          </InformationMenu>
        </HoverEvent>
        <HoverEvent>
          <InformationMenu>
            <div>
              <ImgStyled src='/assets/img/itemDetail/scale.png' alt='' />
              <p>Total weight</p>
            </div>
            <div>
              <Unknown totalWeight={totalWeight}>
                {totalWeight ? `${totalWeight} g` : 'Unknown'}
              </Unknown>
            </div>
          </InformationMenu>
        </HoverEvent>
      </BoxStyle>
      <IconBox>
        <HoverEvent text='File size'>
          <FolderWrapper>
            <ImgStyled src='/assets/img/itemDetail/folder.png' alt='' className='folder' />
          </FolderWrapper>
          <p>
            <span> {size.size.toFixed(1) + ' ' + size.unit}</span>
          </p>
        </HoverEvent>
        <HoverEvent text='Number of files'>
          <ImgStyled src='/assets/img/itemDetail/file_copy.png' alt='' />
          <p>
            <span>{fileCount}</span> Files
          </p>
        </HoverEvent>
        <HoverEvent text='Number of parts' img='/assets/img/itemDetail/popup_sizeInfo.png'>
          <DeployedCodeWrapper>
            <ImgStyled src='/assets/img/itemDetail/deployed_code.png' alt='' />
          </DeployedCodeWrapper>
          <Box lpartSizeX={lpartSizeX} lpartSizeY={lpartSizeY} lpartSizeZ={lpartSizeZ}>
            {lpartAllTrue ? (
              <>
                <div>
                  x:
                  <span>{lpartSizeX}</span>
                </div>
                <div>
                  y:
                  <span>{lpartSizeY}</span>
                </div>
                <div>
                  z:
                  <span>{lpartSizeZ}</span>
                </div>
              </>
            ) : (
              <Lpart>Unknown</Lpart>
            )}
          </Box>
        </HoverEvent>
      </IconBox>
    </InformationContainer>
  );
};

export default Information;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 10px 0;

  p {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
  }

  span {
    color: #767676;
    font-size: 13px;
    font-weight: 400;
    font-family: 'inter', sans-serif;
  }
`;

const BoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Outfit', sans-serif;
`;

const InformationMenu = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f3f6f9;
  height: 54px;

  img {
    width: 17px;
    height: 17px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter', sans-serif;
    margin: 0 10px;
  }

  span {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
  }
`;

const IconBox = styled.div`
  display: flex;
  /* box-shadow: inset 0px 0px 3px 1px rgb(255, 0, 0); */
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: 100%;

  span {
    font-weight: 600;
  }

  img {
    width: 25px;
  }

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin: 40px 0px 24px;
    gap: 15px;
  }

  & > div:nth-child(2) {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }

  @media (max-width: 640px) {
    & > div {
      padding: 0;
      text-align: center;
      margin: 0;
      justify-content: flex-start;
      margin: 50px 0;
    }

    .folder {
      margin-bottom: 10px;
    }
  }
`;

const InformationContainer = styled.div`
  border-top: 1px solid #e0e0e0;
`;

const Box = styled.div`
  display: flex;

  flex-direction: ${props =>
    props.lpartSizeX && props.lpartSizeY && props.lpartSizeZ ? 'row' : 'column'};

  gap: 2px;

  @media (max-width: 640px) {
    flex-direction: column;
  }

  span {
    margin-right: 1.5px;
  }
`;

const Unknown = styled.span`
  color: ${props => (props.printingTime || props.totalWeight ? '#000' : '#767676')} !important;
`;

const FolderWrapper = styled.div`
  height: 29px;
`;

const DeployedCodeWrapper = styled.div`
  height: 32px;
`;

const Lpart = styled.div`
  font-family: 'Outfit', sans-serif;
`;
