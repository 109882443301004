import React from 'react';
import styled from 'styled-components';

const ItemDetailNavBtn = ({
  color,
  bg,
  btn,
  disabled,
  title,
  icon,
  onClick,
  showProduct,
  isOwn,
  buyStatus,
  priceOption,
  isDesigner,
  downloadStatus,
}) => {
  const secret = showProduct === 'secret';
  return (
    <div>
      <Btn
        className='buy-btn'
        onClick={() => onClick()}
        bg={bg}
        color={color}
        disabled={disabled || (!isOwn && secret && !isDesigner)}
        btn={btn}
        secret={secret}
        buyStatus={buyStatus}
        priceOption={priceOption}
        isDesigner={isDesigner}
        isOwn={isOwn}
      >
        {title !== 'Download' &&
          ((priceOption === 'reward' && buyStatus === 'y') ||
          (priceOption === 'reward' && isDesigner) ||
          (priceOption === 'reward' && isOwn) ? (
            <img src={`/assets/img/itemDetail/${icon ? icon : 'btn-icon'}.png`} className='icon' />
          ) : (
            <img
              src={`/assets/img/itemDetail/${icon ? icon : 'btn-icon-fff'}.png`}
              className='icon'
            />
          ))}

        {secret && !isOwn && !isDesigner ? 'No longer for sale' : title}
      </Btn>
    </div>
  );
};

export default ItemDetailNavBtn;

const Btn = styled.button`
  width: 25rem;

  font-family: 'Outfit', sans-serif !important;

  @media (max-width: 600px) {
    width: 10rem;
    font-size: 14px;
  }

  cursor: pointer !important;

  height: 50px;
  border-radius: 4px;
  color: ${props =>
    props.color ||
    (props.isSecret
      ? '#fff'
      : (props.priceOption === 'reward' && props.buyStatus === 'y') ||
        (props.priceOption === 'reward' && props.isDesigner) ||
        (props.priceOption === 'reward' && props.isOwn)
      ? '#000'
      : '#fff')};

  border: none;

  background-color: ${props => {
    if (props.disabled) return 'rgb(146, 149, 153)';
    if (
      (props.priceOption === 'reward' && props.buyStatus === 'y') ||
      (props.priceOption === 'reward' && props.isDesigner) ||
      (props.priceOption === 'reward' && props.isOwn)
    )
      return '#F7F15B';
    return props.bg || '#000';
  }};

  font-size: 16px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    max-width: 23px;
    max-height: 23px;
    margin-right: 10px;
  }
`;
