import styled from 'styled-components';

export const ManageCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LabelStyle = styled.label`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const InputStyle = styled.input`
  margin-left: 5px !important;
  width: 23px;
  height: 23px;
  border: 2px solid #9a9a9a;
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: #fff;
    border: none;
  }

  &:checked::after {
    content: '';
    position: absolute;
    background-image: url('/assets/img/mange/checked.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;
