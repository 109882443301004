import React, { useContext, useEffect, useState } from 'react';
import { sanitize } from 'dompurify';
import CartContext from '../../context/cart.js/carts';
import './Checkout.css';
import { getItemDetail, getItemOwn, postCheckoutFree } from '../../action/request';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import {
  PAGE_CHECKOUT_LICENSE,
  PAGE_CHECKOUT_LICENSE_AGREEMENT,
} from '../../context/lang/constant';
import { useNavigate } from 'react-router-dom';
import { CHECKOUT_SUCCESS } from '../../route/constant';
import { ls } from '../../constant';
import UserContext from '../../context/user/user';
import { ImgStyled } from '../../styles/ImgStyled';
import TroubleCheckOut from '../../component/checkOut/TroubleCheckOut';

function CheckoutFree() {
  const { state: cartState } = useContext(CartContext);
  const { action } = useContext(LangContext);
  const [isAgreement, setIsAgreement] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const navigate = useNavigate();
  const [qty, setQty] = useState(0);
  const [isLoginState, setIsLoginState] = useState(false);
  const { state } = useContext(UserContext);

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    let isLoginUsingToken =
      localStorage.getItem(ls.accessToken) !== 'undefined' &&
      localStorage.getItem(ls.accessToken) !== 'null' &&
      localStorage.getItem(ls.accessToken);
    if (isLoginUsingToken) {
      setIsLoginState(true);
    } else {
      setIsLoginState(false);
    }
  }, [state.isLogin]);

  // 동의 버튼
  const agreementHandler = () => {
    setIsAgreement(!isAgreement);
  };

  // 새로고침하거나 cartState.checkoutFree 없으면 뒤로가기
  useEffect(() => {
    if (cartState.checkoutFree === null) {
      navigate(-1);
    }
    checkIsOwn();
    fetchData();
  }, [cartState.checkoutFree, navigate]);

  // qty 값 가져오기
  const fetchData = async () => {
    try {
      const { data: resDetail } = await getItemDetail({
        id: cartState.checkoutFree.checkoutDto.products.productId,
        isLoginState,
      });
      setQty(resDetail.item.qty);
      // console.log(resDetail.item.qty);
    } catch (error) {
      // console.log(error);
    }
  };

  // 내가 구매한 상품인지 체크 (구매완료 페이지에서 뒤로가기 누를경우 checkoutpage로 이동하는 현상 방지)
  const checkIsOwn = async () => {
    if (cartState.checkoutFree) {
      const res = await getItemOwn({
        idItem: cartState.checkoutFree.checkoutDto.products.productId,
      });
      // 이미 내가 구매한 아이템이라면 상세페이지로 리다이렉트
      if (res.data.result) {
        navigate(`/items/${cartState.checkoutFree.idItem.id_item}`);
      }
    }
  };

  const clickCheckoutButton = async e => {
    e.stopPropagation();
    e.preventDefault();

    if (isAgreement && cartState.checkoutFree) {
      try {
        const { data: res } = await postCheckoutFree(
          cartState.checkoutFree.checkoutDto.products.productId
        );
        // 구매 완료 페이지로 이동
        navigate(`${CHECKOUT_SUCCESS}/${res.data}`);
      } catch (e) {
        // 이미 구매한 상품을 중복 구매할 경우 Error 반환
        alert(e.response.data.message);
        navigate(`/items/${cartState.checkoutFree.idItem.id_item}`);
      }
    } else {
      setIsErrorMessage(true);
    }
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <>
      <Layout>
        <div className='wrapper'>
          <div id='main'>
            <div className='process'>
              <div className='container'>
                <ul>
                  <li>
                    <div className='process-item'>
                      <span className='num'>1</span>
                      <span className='label'>Cart</span>
                    </div>
                  </li>
                  <li>
                    <div className='process-item active'>
                      <span className='num'>2</span>
                      <span className='label'>Payment</span>
                    </div>
                  </li>
                  <li>
                    <div className='process-item'>
                      <span className='num'>3</span>
                      <span className='label'>Download</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <section className='shoppingbag-section'>
              {cartState && (
                <div className='container checkoutFreeItem'>
                  <div className='shoppingbag'>
                    <div className='cart'>
                      <div className='content-box'>
                        <div className='content-box-header'>
                          <h2>
                            Cart <small className='badge'>1</small>
                          </h2>
                        </div>
                        <div className='content-box-body'>
                          <div className='purchase-product-list'>
                            <div className='purchase-product-list-item'>
                              <div className='purchase-product-list-item-inner'>
                                <ImgStyled
                                  className='tb'
                                  src={
                                    cartState.checkoutFree &&
                                    cartState.checkoutFree.checkoutDto.products.img
                                  }
                                  alt=''
                                />
                                <div className='product-meta'>
                                  <span className='product-name'>
                                    {cartState.checkoutFree &&
                                      cartState.checkoutFree.checkoutDto.products.name}
                                  </span>
                                  <span className='price'>REWARD</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          {/* 라이센스 동의 */}
                          <div className='payment-block'>
                            <h5>License Agreement</h5>
                            <div
                              className='fabj-license-agreement'
                              dangerouslySetInnerHTML={{
                                __html: sanitize(action.trans(PAGE_CHECKOUT_LICENSE)),
                              }}
                            ></div>
                            <div
                              className='payment-block-body error'
                              id='checkout-license-checkbox'
                            >
                              <label className='input-checkbox'>
                                <input
                                  type='checkbox'
                                  name='agreement'
                                  value='y'
                                  onClick={agreementHandler}
                                />
                                <span className={isErrorMessage ? `label error` : `label`}>
                                  {action.trans(PAGE_CHECKOUT_LICENSE_AGREEMENT)}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* checkout */}
                    <div className='total'>
                      {isErrorMessage && (
                        <div id='checkout-alert' className='error show'>
                          You should check License Agreement
                        </div>
                      )}
                      <div className='your-order-box'>
                        <h3>Checkout</h3>
                        <div className='your-order-info'>
                          <div className='price-info'>
                            <dl>
                              <dt>Reward Ticket</dt>
                              <dd>{qty}</dd>
                            </dl>
                          </div>
                          <button
                            onClick={clickCheckoutButton}
                            className='btn btn-fill btn-green btn-full'
                            data-action='1501-checkout-free'
                          >
                            Continue Checkout
                          </button>
                        </div>
                      </div>
                      <TroubleCheckOut />
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default CheckoutFree;
