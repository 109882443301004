/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from 'react-router-dom';
import { COLLECTIONS } from '../../../route/constant';
import { ImgStyled } from '../../../styles/ImgStyled';

const CollectionList = ({ data }) => {
  const navigate = useNavigate();

  return (
    <>
      {data?.map((c, idx) => {
        const color = idx % 2 !== 0 ? 'light' : 'dark';
        const btn = idx % 2 !== 0 ? '' : 'btn-white';
        return (
          <div className={'featured-collection ' + color} key={c.id_category}>
            <div className='img'>
              <ImgStyled src={c.img} alt={c.name} />
            </div>
            <div className='desc'>
              <div className='desc-inner'>
                <h3>{c.name}</h3>
                <p>{c.desc}</p>
                <Link
                  to={COLLECTIONS + '/' + c.url}
                  className={'btn-all btn btn-hover ' + btn}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(COLLECTIONS + '/' + c.url);
                  }}
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CollectionList;
