import React, { useContext } from 'react';
import LangContext from '../../context/lang/lang';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PAGE_FOOTER_HEADER_COPYRIGHT,
  PAGE_FOOTER_HEADER_PRIVACY,
  PAGE_FOOTER_HEADER_TERMS,
} from '../../context/lang/constant';
import {
  ABOUT_SERVICE,
  FOOTER_CONTACT,
  FOOTER_CONTACT_BUSINESS,
  FOOTER_COPYRIGHT,
  FOOTER_PRIVACY,
  FOOTER_TERMS,
  FOOTER_USER_LICENSE,
} from '../../route/constant';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import { ImgStyled } from '../../styles/ImgStyled';

function Footer2() {
  const { state, action } = useContext(LangContext);

  const location = useLocation();
  const isLandingPage = location.pathname.includes('/landing');
  const isHome = location.pathname === '/'; // 2.0 1차 개발에서 홈에만 푸터 padding값 적용을 위한 변수
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <Webpage2StylesContainer style={{ display: isLandingPage ? 'none' : '' }}>
      <div className='allWrap'>
        <footer className={isHome ? 'footer--margin' : ''}>
          <div className='footerRow1'>
            <div className='centerWrap'>
              <div className='footerMenu'>
                {/* <a href='#' onClick={e => goTo(e, ABOUT_SERVICE)}>
                  <span>Why Fab365?</span>
                </a> */}
                <a href='#' onClick={e => goTo(e, FOOTER_TERMS)}>
                  <span>{action.trans(PAGE_FOOTER_HEADER_TERMS)}</span>
                </a>
                <a href='#' onClick={e => goTo(e, FOOTER_PRIVACY)}>
                  <span>{action.trans(PAGE_FOOTER_HEADER_PRIVACY)}</span>
                </a>
                <a href='#' onClick={e => goTo(e, FOOTER_COPYRIGHT)}>
                  <span>{action.trans(PAGE_FOOTER_HEADER_COPYRIGHT)}</span>
                </a>
                {/* <a href='#' onClick={e => goTo(e, FOOTER_CONTACT)}>
                  <span>Contact</span>
                </a> */}
                {/* TODO:라이센스 */}
                <a href='#' onClick={e => goTo(e, FOOTER_USER_LICENSE)}>
                  <span>User License</span>
                </a>
                {/* <a href='#' onClick={e => goTo(e, FOOTER_CONTACT_BUSINESS)}>
                  <span>Business</span>
                </a> */}
              </div>

              <div className='footerSubMenu'>
                <select
                  className='selectF1'
                  title='select language'
                  value={state.lang}
                  onChange={e => action.setLangState(e.target.value)}
                >
                  <option value='en'>English</option>
                  <option value='ko'>Korean</option>
                </select>

                <a
                  href='https://www.youtube.com/c/FAB3653DPrinting'
                  className='youtube'
                  target='_blank'
                  title='new window : Fab365 youtube'
                  rel='noreferrer'
                ></a>
                <a
                  href='https://www.instagram.com/fab_365/'
                  className='instagram'
                  target='_blank'
                  title='new window : Fab365 instagram'
                  rel='noreferrer'
                ></a>
                <a
                  href='https://www.facebook.com/Fab365.net'
                  className='facebook'
                  target='_blank'
                  title='new window : Fab365 facebook'
                  rel='noreferrer'
                ></a>
              </div>
            </div>
          </div>
          <div className='footerRow2'>
            <div className='centerWrap'>
              <a href='#' className='col1' title='home'>
                <ImgStyled
                  src='/assets/img/2.0WebImg/ui/logo_big.png'
                  className='footerLogo'
                  alt=''
                />
              </a>
              <div className='col2'>
                <div className='fTxt'>
                  #302, 152, Nonhyeon-ro, Gangnam-gu, Seoul, Republic of Korea, Zip code 06301
                  <br />
                  <span className='t1'>Tel</span> +82-2-579-0365 <span className='t1'>E-Mail</span>{' '}
                  info@fab365.net
                  <br />© 2018 Fab365 co.,Ltd.
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Webpage2StylesContainer>
  );
}

export default Footer2;
