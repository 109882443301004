import { ImgStyled } from '../../../styles/ImgStyled';
import styled from 'styled-components';
import ManageButton from '../common/ManageButton';

const ModelEmpty = ({ noUploadModel, noSearchModel, onClick, isMobile }) => {
  return (
    <ModelEmptyContainer
      className='ModelEmptyContainer'
      noUploadModel={noUploadModel}
      isMobile={isMobile}
    >
      {noUploadModel && (
        <>
          <TextBox isMobile={isMobile}>
            {isMobile ? (
              <span>
                You have not
                <br /> uploaded any <br />
                models yet
              </span>
            ) : (
              <>
                <span style={{ marginBottom: '20px' }}>You have not uploaded any models yet</span>
                <ManageButton
                  backgroundColor='#4CBE37'
                  maxWidth='240px'
                  color='white'
                  text='+ Upload'
                  onClick={onClick}
                />
              </>
            )}
          </TextBox>
        </>
      )}
      {noSearchModel && (
        <>
          {isMobile ? (
            <Box>
              <ImgStyled src='/assets/img/mange/not-found.png' />
              <NoSearch isMobile={isMobile}>
                <span>No models found</span>
              </NoSearch>
            </Box>
          ) : (
            <>
              <ImgStyled src='/assets/img/mange/not-found.png' />
              <TextBox isMobile={isMobile}>
                <span>No models found</span>
              </TextBox>
            </>
          )}
        </>
      )}
    </ModelEmptyContainer>
  );
};

export default ModelEmpty;

const ModelEmptyContainer = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  color: #9a9a9a;

  width: 100%;
  min-width: 653px;

  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  justify-content: center;
  border: 1px solid #000;
  border-radius: 0 0 10px 10px;
  border-top: none;

  padding: 100px 0;

  img {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
  }

  p {
    padding-bottom: ${({ noUploadModel }) => (noUploadModel ? '50px' : '0')};
  }
`;

export const TextBox = styled.div`
  text-align: ${({ isMobile }) => (isMobile ? 'left' : 'center')};
  width: ${({ isMobile }) => (isMobile ? '100vw' : '100%')};
  white-space: ${({ isMobile }) => (isMobile ? 'normal' : 'nowrap')};

  span {
    display: block;
    text-align: center;
    line-height: 1.2;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 653px;

  span {
    text-align: center !important;
    width: 100%;
    display: block;
  }
`;

const NoSearch = styled.div`
  width: 100%;
`;
