import { sanitize } from 'dompurify';

const RequestMedia = ({ media, setMedias, isMain, setMainIdx, idx, onDelete }) => {
  const mainChange = idx => {
    setMainIdx(idx);
  };

  return (
    <>
      <li>
        <div className='file-item'>
          <input type='hidden' name='imgs[]' value={sanitize(JSON.stringify(media))} />
          <a
            className={isMain ? 'active' : ''}
            href='#'
            onClick={e => {
              e.preventDefault();
              mainChange(idx);
            }}
          >
            <img src={media.thumbs[0].url} alt='' />
          </a>
          <button type='button' className='btn-delete' onClick={() => onDelete(idx)}>
            &times;
          </button>
        </div>
      </li>
    </>
  );
};

export default RequestMedia;
