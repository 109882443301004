import { useEffect, useState } from 'react';
import ItemRecommand from '../../component/item/ItemRecommand';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import { PRINT_TIPS_LIST } from '../../route/constant';
import { useNavigate } from 'react-router-dom';

const PartsNotMove = ({ tab, title }) => {
  const [isOne, setIsOne] = useState(false);
  const [isTwo, setIsTwo] = useState(false);
  const [isThree, setIsThree] = useState(false);

  const navigate = useNavigate();

  const imageBase = 'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/pages/14013382173-tips/';

  const toggle = (bool, setter) => e => {
    e.stopPropagation();
    e.preventDefault();
    setter(!bool);
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu tab={tab} title={title} />
          <div className='container fab-accordion-mt-s1 fab-accordion-mb-s1'>
            <div className='fab-accordion __w1340'>
              <div className='fab-accordion-header'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
                <h2 className='fab-accordion-header--title'>
                  <small>Problem 3</small>
                  <br />
                  Parts do not move
                </h2>
                <div className='fab-accordion-header--description'>
                  <p>
                    Each part has to be spaced out consistently, and if they don’t, they will stick
                    together and cannot be moved. The reason is the following:
                  </p>
                </div>
              </div>
              <div className='fab-accordion-body'>
                <div className='fab-accordion-list'>
                  <ul>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isOne ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isOne, setIsOne)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Cause 01</small>
                            <br />
                            Elephant foot
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            Elephant foot occurs as the result of the nozzle and the bed being too
                            close, or too much filament is extruded that make each part stick
                            together and not move.{' '}
                          </p>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-12.jpg'} alt='' />
                          </figure>

                          <p>
                            Bad Example : Parts are stuck together in the first layer. (See photo
                            below)
                          </p>
                          <figure className='__w1240'>
                            <img src={imageBase + 'tip-13.jpg'} alt='' />
                            <img src={imageBase + 'tip-14.jpg'} alt='' />
                          </figure>

                          <p>
                            Good Example : Parts are spaced out in the first layer. (See photo
                            below)
                          </p>
                          <figure className='__w1240'>
                            <img src={imageBase + 'tip-15.jpg'} alt='' />
                            <img src={imageBase + 'tip-16.jpg'} alt='' />
                          </figure>

                          <p>
                            <em>
                              Solution 1) To prevent Elephant foot, increase the nozzle height
                              slightly away from the bed. But, be careful not to set the offset too
                              high.
                              <br />
                              Solution 2) Lower Extrusion Multiplier. If you decrease the filament
                              in the slicer software, you can prevent this from Elephant foot.
                              <br />
                              Solution 3) Use Raft. Raft is used to separate the parts better after
                              printing is complete. Because of less extrusion of filament, parts do
                              not adhere from the first layer of 3D printed objects.
                              <br />
                            </em>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isTwo ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isTwo, setIsTwo)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Cause 02</small>
                            <br />
                            Stringing
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            Stringing occurs when the temperature is too high or retraction is low.
                            Additional filament drips out while the nozzle is moving, resulting in
                            excessive hairy looking annoying threads. These threads make the parts
                            stick together and they become not moveable anymore.{' '}
                          </p>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-17.jpg'} alt='' />
                          </figure>
                          <p>
                            <em>Solution 1) Increase the retraction</em>
                          </p>

                          <p>
                            The retraction is an action to backspin the motor to retract the
                            filament to prevent from web-like strings dripping out.{' '}
                          </p>
                          <p>
                            To decrease the amount of strings, you have to increase the amount of
                            retraction from the slicer. There is a difference in each printers’
                            method, (ex: Bowden, direct, etc.) so you need to find the right
                            settings for your printer and model.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          'fab-accordion-item' + (isThree ? ' fab-accordion-item--open' : '')
                        }
                      >
                        <a
                          href='#'
                          onClick={toggle(isThree, setIsThree)}
                          className='fab-accordion-item-header'
                        >
                          <h4 className='fab-accordion-item--title'>
                            <small>Cause 03</small>
                            <br />
                            Rough Surface
                          </h4>
                        </a>
                        <div className='fab-accordion-item-body'>
                          <p>
                            If the surface is not smooth and irregular, the gap between each part
                            becomes irregular that parts adhere and cause not to move.{' '}
                          </p>
                          <figure className='__w900'>
                            <img src={imageBase + 'tip-18.jpg'} alt='' />
                          </figure>
                          <p>
                            <em>Solution 1) Check your 3D Printer settings</em>
                          </p>

                          <p>
                            There are so many reasons to cause the rough surface that It is better
                            to go over your printer’s setting and adjust your steps per millimeter.
                            Main causes can be low belt tension, stiffness of the movement of the
                            nozzle, or loose screw. You might have to adjust the first layer height,
                            temperature, the speed, and also keep the surface clean.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='fab-accordion-footer'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
              </div>
            </div>
          </div>

          <section>
            <ItemRecommand itemId={null} />
          </section>
        </div>
      </Layout>
    </>
  );
};

export default PartsNotMove;
