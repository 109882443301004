import { TAB_ORDER } from '../../component/constant';
import MyTab from '../../component/MyTab';
import moment from 'moment';
import Pagination from '../../../Category/component/Pagination';
import { useState } from 'react';
import { useEffect } from 'react';
import { getOrders } from '../../../../action/request';
import { Link, useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../../styles/ImgStyled';
import { orderPageAtom } from '../../../../atom/atom';
import { useRecoilState } from 'recoil';
const OrderMain = () => {
  const [items, setItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [pageSize, setPageSize] = useState(8);
  const [total, setTotal] = useState(0);
  const [orderPage, setOrderPage] = useRecoilState(orderPageAtom);

  const navigate = useNavigate();

  const pageChange = val => {
    setOrderPage(val);
  };
  const pageConfirm = val => {
    setOrderPage(val);
  };

  useEffect(() => {
    fetchData(orderPage);
  }, [orderPage]);

  const fetchData = async orderPage => {
    try {
      const { data: resOrder } = await getOrders({ page: orderPage });
      const res = resOrder.data;
      setItems(res.itemPool);
      setOrders(res.orders);
      // console.log(res);
      setTotal(parseInt(res.count));
      setPageSize(res.paging.size);
    } catch (e) {}
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div id='main'>
        <div className='page-top mypage'>
          <div className='container'>
            <h1 className='page-title align-left'>My Page</h1>
          </div>
        </div>
        <MyTab tab={TAB_ORDER} />

        <section className='mypage-section'>
          <div className='container'>
            <div className='content-box orders'>
              <div className='content-box-header'>
                <h2>Your Order List</h2>
              </div>
              <div className='content-box-body'>
                <div className='your-order-list'>
                  <ul>
                    {orders?.map((o, idx) => {
                      const orderDate = moment(o.date_order).format('MMM DD YYYY');
                      const orderItems = items.filter(i => {
                        return (
                          o.orderItems.filter(oi => {
                            return oi.item_id === i.id_item;
                          }).length > 0
                        );
                      });
                      const freeItems = orderItems.filter(f => {
                        return f.free === 'y';
                      });

                      if (orderItems.length === 0) return <></>;

                      return (
                        <li key={o.id_order}>
                          <Link
                            to={'/my/orders/' + o.orderno}
                            onClick={e => goTo(e, '/my/orders/' + o.orderno)}
                          >
                            <div className='order-item'>
                              <div className='date'>{orderDate}</div>
                              <div className='order-detail'>
                                <ImgStyled
                                  src={orderItems[0]?.list_img}
                                  alt={orderItems[0]?.name_en}
                                  className='product-tb'
                                />
                                <p className='product-name'>
                                  {orderItems[0].name_en}
                                  {o.orderItems.length > 1 && (
                                    <span className='more'>more {o.orderItems.length - 1}</span>
                                  )}
                                  {freeItems.length > 0 && (
                                    <>
                                      <br />
                                      <span
                                        style={{
                                          color: '#511AD0',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {/* 구매시 소진된 티켓 수 표시 */}
                                        {orderItems[0].used_qty.split('.')[0]} Reward ticket used
                                      </span>
                                    </>
                                  )}
                                  <small className='date'>{orderDate}</small>
                                </p>
                              </div>

                              <div className='orderno'>
                                {o.status === 'order_pending' && '(Processing Payment)'}#{o.orderno}
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Pagination
          page={orderPage}
          total={parseInt(Math.round(total / pageSize))}
          onconfirm={pageConfirm}
          onpagechange={pageChange}
        />
      </div>
    </>
  );
};

export default OrderMain;
