import { useEffect } from 'react';
import Layout from '../../../component/layout/Layout';
import { MY_REVIEW_WRITE } from '../../../route/constant';
import { TAB_REVIEW } from '../component/constant';
import MyTab from '../component/MyTab';
import ReviewItem from './component/ReviewItem';
import { getMyReviews } from '../../../action/request';
import { useState } from 'react';
import Pagination from '../../Category/component/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';

const MyReview = () => {
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(16);
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const pageChange = val => {
    setPage(val);
  };
  const pageConfirm = val => {
    setPage(val);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    try {
      const {
        data: { data: res },
      } = await getMyReviews({ page });
      setReviews(res.reviews);
      setPageSize(res.pagingInfo.size);
      setTotal(res.count);
    } catch (e) {}
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top mypage'>
            <div className='container'>
              <h1 className='page-title align-left'>My Page</h1>
            </div>
          </div>

          <MyTab tab={TAB_REVIEW} />

          <section className='mypage-section'>
            <div className='container'>
              <div className='content-box orders'>
                <div className='content-box-header'>
                  <h2>My Reviews</h2>
                </div>
                <div className='content-box-body'>
                  <div className='review-cta'>
                    <p>did you make it? please write making story !</p>
                    <Link
                      className='btn btn-green btn-fill'
                      onClick={e => goTo(e, MY_REVIEW_WRITE)}
                      to={MY_REVIEW_WRITE}
                    >
                      Write Review
                    </Link>
                  </div>

                  <div className='mypage-request-list'>
                    <ul className='clearfix'>
                      <ReviewItem data={reviews} />
                    </ul>
                    <hr />

                    {/* <?php echo PagingRenderer::render($paging, EnumUrl3::MY_REVIEW); ?> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Pagination
            page={page}
            onconfirm={pageConfirm}
            onpagechange={pageChange}
            total={parseInt(Math.ceil(total / pageSize))}
          />
        </div>
      </Layout>
    </>
  );
};

export default MyReview;
