import React from 'react';
import DesignersCountBox from './DesignersCountBox';
import { commentTotalCountAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';

const DesingersBoxs = ({ data, designerFollow, value }) => {
  const checkValue = value === 'designerList';
  const [totalCount, setTotalCount] = useRecoilState(commentTotalCountAtom);
  // const localDesignerData = useRecoilValue(localDesignerDataAtom);
  const formatNumber = num => {
    if (num >= 10000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 10000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num;
  };

  return (
    <>
      <DesignersCountBox
        icon={'/assets/img/designers/icon_cube.png'}
        title={'Models'}
        count={formatNumber(data?.model_count || 0)}
        tab={0}
        data={data}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/icon_download.png'}
        title={'Downloads'}
        count={formatNumber(data?.downloads || 0)}
        tab={0}
        data={data}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/icon_peoplePlus.png'}
        title={'Followers'}
        count={designerFollow ? designerFollow : data?.follow_count || 0}
        tab={1}
        data={data}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/icon_chat_read.png'}
        title={'Comments'}
        count={formatNumber(
          checkValue
            ? data?.comment_count || 0
            : totalCount?.total === 'update'
            ? totalCount.count
            : data?.comment_count || 0
        )}
        tab={2}
        data={data}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/icon_camera.png'}
        title={'Reviews'}
        count={formatNumber(data?.review_count || 0)}
        className={'camera'}
        tab={3}
        data={data}
      />
    </>
  );
};

export default DesingersBoxs;
