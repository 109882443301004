/* eslint-disable jsx-a11y/anchor-is-valid */
import { sanitize } from 'dompurify';
import { DEFAULT_AVATAR } from '../../../component/avatar/const';
import moment from 'moment';
import { formatHrefLink, isEditable } from '../../../util/comment';
import { useState, useRef, useContext } from 'react';
import { uploadItemImage, editReviewComment, deleteReviewComment } from '../../../action/request';
import UserContext from '../../../context/user/user';
import { preventScroll } from '../../../util/commentImageViewer';
import { ImgStyled } from '../../../styles/ImgStyled';

const ReviewCommentBody = ({ comment, onReload, showReply, setShowImgViewer, setImgData }) => {
  const date = moment(comment.date?.c * 1000).format('YYYY-MM-DD');
  const [editBody, setEditBody] = useState(comment['body']);
  const [isEdit, setIsEdit] = useState(false);
  const [medias, setMedias] = useState(comment.medias);
  const [loading, setLoading] = useState(false);
  const imageInput = useRef(null);
  const { state } = useContext(UserContext);
  const user = comment['user'];
  const body = comment['body'];

  // const forData = {
  //   oid_comment: comment,
  //   body: body,
  //   medias: medias,
  // };

  // const medias = comment.medias?.map((m) => {
  //   return JSON.stringify(m);
  // });

  // const forData = {
  //   oid_comment: comment.oid_comment,
  //   _category: comment._category,
  //   body: comment.body,
  //   medias: medias,
  // };

  // console.log(comment);

  const onDeleteImage = idx => {
    const newMedias = medias.filter((_, i) => i !== idx);
    setMedias(newMedias);
  };

  const imageChanged = async e => {
    try {
      const files = e.target.files;
      const newMedias = [...medias];
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadItemImage({
          file: files[i],
        });
        newMedias.push(res.img);
      }
      setMedias(newMedias);
      setLoading(false);
    } catch (e) {
      alert('request failed');
    }
  };

  const editComment = async e => {
    e.preventDefault();
    e.stopPropagation();
    // 아무 내용 없다면 알림
    if (!editBody || editBody === '') {
      return alert('Comment is empty');
    }
    try {
      const { data: res } = await editReviewComment({
        category: '302',
        comment: comment.oid_comment,
        msg: editBody,
        medias: medias,
      });
      if (res) {
        onReload();
      }
    } catch (e) {}

    setIsEdit(false);
  };

  const onDeleteComment = async () => {
    if (window.confirm('Delete?')) {
      try {
        const { data: res } = await deleteReviewComment({
          category: '302',
          oidComment: comment.oid_comment,
        });
        if (res) {
          onReload();
        }
      } catch (e) {}
    }
  };

  const onClickImg = (imgArr, idx) => {
    // console.log(imgArr, idx);
    setShowImgViewer(true);
    setImgData({ index: idx, data: imgArr });
    preventScroll();
  };

  return (
    <>
      <div
        className='comment-item'
        // data-comment={sanitize(JSON.stringify(forData))}
      >
        {isEdit && (
          <div className='comment-write'>
            <div className='profile'>
              <div className='profile-pic'>
                <ImgStyled src={user.thumbnail ? user.thumbnail : DEFAULT_AVATAR} alt='' />
              </div>
            </div>
            <form className='comment-write-form' data-action='1101-comment-edit-submit'>
              <div className='write-form'>
                <input type='hidden' name='comment' />
                <textarea
                  placeholder='Please input your text'
                  name='msg'
                  value={editBody}
                  onChange={e => setEditBody(e.target.value)}
                ></textarea>
                <div className='attachments'>
                  <ul className='files'>
                    {medias?.map((media, idx) => {
                      return (
                        <li key={idx + media.url}>
                          <div className='file-item'>
                            <a>
                              <ImgStyled src={media.thumbs[0].url} alt='' />
                            </a>
                            <button
                              type='button'
                              className='btn-delete'
                              onClick={() => onDeleteImage(idx)}
                            >
                              ×
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className='form-action'>
                  <div className='file-uploader'>
                    <input
                      ref={imageInput}
                      type='file'
                      accept='image/*'
                      multiple
                      onChange={imageChanged}
                    />
                    <button
                      type='button'
                      className={'btn-upload-file loading_color ' + (loading && 'loading')}
                      onClick={() => imageInput.current.click()}
                    >
                      Edit Image
                    </button>
                  </div>
                  <button type='submit' className='btn btn-green btn-fill' onClick={editComment}>
                    Edit Comments
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        {!isEdit && (
          <>
            <div className='comment-item-header'>
              <div className='comment-item-header'>
                <div className='profile'>
                  <div className='profile-pic'>
                    <ImgStyled
                      src={
                        comment.status === 1
                          ? user.thumbnail
                            ? user.thumbnail
                            : DEFAULT_AVATAR
                          : DEFAULT_AVATAR
                      }
                      alt=''
                    />
                  </div>
                  <div className='profile-name'>{comment.status === 1 ? user.name : ''}</div>
                  <div className='comment-date'>{comment.status === 1 ? date : ''}</div>
                </div>
              </div>

              {isEditable(comment, String(state.id)) && comment.status === 1 && (
                <>
                  <div className='util-btns'>
                    <button
                      className='btn-edit'
                      onClick={
                        () => {
                          setIsEdit(true);
                        }

                        //
                      }
                    >
                      Edit
                    </button>
                    <button className='btn-delete' onClick={onDeleteComment}>
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className='comment-item-body'>
              <div className='comment-content'>
                <p
                  dangerouslySetInnerHTML={
                    comment.status === 1 ? formatHrefLink(comment.body) : { __html: 'Deleted' }
                  }
                ></p>
              </div>
              <div className='attachments' data-image-gallery='true'>
                {comment.status === 1
                  ? medias?.map((media, idx) => {
                      return (
                        <a
                          // href={media.url}
                          onClick={() => onClickImg(medias, idx)}
                          data-tb={media.type}
                          key={idx}
                        >
                          <ImgStyled src={media.thumbs[0].url} alt='' />
                        </a>
                      );
                    })
                  : ''}
              </div>
            </div>
            {/* 리플 버튼 표시 */}
            {state.id && (
              <div className='comment-item-footer'>
                <a className='btn btn-xs' data-action='1102-comment-reply' onClick={showReply}>
                  Reply
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ReviewCommentBody;
