/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import MainSlide from './components/MainSlide';
import FreeitemSlide from './components/FreeitemSlide';
import { useContext } from 'react';
import ModalContext from '../../context/modal/modal';
import Layout from '../../component/layout/Layout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Ss, ls } from '../../constant';
import styled from 'styled-components';

function Landing() {
  const innerWidth = window.innerWidth <= 640;

  const [isMobile, setIsMobile] = useState(window.innerWidth);

  const { action } = useContext(ModalContext);
  const location = useLocation();
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  // 세션스토리지에 저장
  // signupPage 제외하고는 세션스토리지 비우기
  const getInviteToken = () => {
    const urlParams = new URLSearchParams(location.search);
    const inviteToken = urlParams.get('t');
    if (inviteToken !== undefined) {
      sessionStorage.setItem(Ss.inviteToken, inviteToken);
    }
  };

  useEffect(() => {
    if (isLoginUsingToken) {
      navigate('/');
    } else {
      getInviteToken();
    }
  }, []);

  useEffect(() => {
    // window.addEventListener('resize', () => {setIsMobile(window.innerWidth <= 640)});
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 640);
    });

    return () => {
      window.removeEventListener('resize', () => setIsMobile(window.innerWidth <= 640));
    };
  }, [isMobile]);

  const onClickSignUp = () => {
    action.setIsSignup(true);
  };

  // 소셜미디어 공유
  const link = window.location.href;

  // Facebook 공유
  const shareFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`);
  };

  // Twitter 공유
  const shareTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${link}`);
  };

  //  링크 복사
  const shareUrl = () => {
    let textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = link;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    alert('The invitation link has been copied. ');
  };

  return (
    <Layout>
      <Container>
        <div id='invite-landing' className='invite-landing-container'>
          {/* section1-main */}
          <section className='invite-landing-main-section'>
            <header className='invite-landing-header'>
              <img src='/assets/img/inviteLanding/logo.svg' alt='logo' />
            </header>
            <p className='invite-landing-main-title'>
              <span>Premium 3D printing STL files</span>
              <br />
              which are worth paying for
            </p>
            <p className='invite-landing-sub-title'>
              We believe that great designers and designs deserve great recognition. <br />
              Fab365 is a platform that actualizes that value by developing or discovering premium
              designs and selling them for appropriate price.
            </p>
            <button
              className='invite-landing-sinup-btn invite-landing-signup size-m'
              onClick={onClickSignUp}
            >
              <span>SIGN UP</span>
              <img
                className='invite-landing-sinup-btn-arrow'
                src='/assets/img/inviteLanding/btn_arrow.svg'
                alt='arrow'
              />
            </button>
            <MainSlide />
            <div className='invite-landing-masking-tape-bg'>
              <div className='invite-landing-masking-tape'></div>
            </div>
          </section>

          {/* section2-signup */}
          <section className='invite-landing-signup-section'>
            <div className='invite-landing-freeitem-title-container'>
              <p className='invite-landing-freeitem-title'>
                Sign up now and download the model you want for free!
              </p>
              <p className='invite-landing-freeitem-sub'>
                Fab365's free designs are kind of fan art, and created with the utmost respect for
                masterpieces. <br />
                And we share them in gratitude with our new members.
              </p>
            </div>
            <FreeitemSlide onClickSignUp={onClickSignUp} />
          </section>

          {/* section3-ticket */}
          <section className='invite-landing-ticket-section'>
            <p className='invite-landing-ticket-title'>
              Fab365's free designs can only be downloaded with Reward ticket
            </p>
            <img
              className='invite-landing-ticket'
              src='/assets/img/inviteLanding/ticket.svg'
              alt='Free item download ticket'
            />
          </section>

          {/* section4-howto */}
          <section className='invite-landing-howto-section'>
            <p className='invite-landing-howto-title'>
              How to get <span style={{ color: '#57FF7C' }}>Reward ticket</span>
            </p>
            <div className='invite-landing-image-wrapper'>
              <img
                className='invite-landing-howto-process-pc'
                src='/assets/img/inviteLanding/howto_process_pc02.png'
                alt='how to get free item pc image'
              />
            </div>
            <img
              className='invite-landing-howto-process-mo'
              src='/assets/img/inviteLanding/howto_process_mo02.png'
              alt='how to get free item mobile image'
            />

            <button
              className='invite-landing-sinup-btn invite-landing-signup'
              onClick={onClickSignUp}
            >
              <span>SIGN UP</span>
              <img
                className='invite-landing-sinup-btn-arrow'
                src='/assets/img/inviteLanding/btn_arrow.svg'
              />
              <p>and get a free-item download ticket</p>
            </button>
          </section>
          {/* section5-paiditem */}
          <section className=' invite-landing-paiditem-section'>
            <p className='invite-landing-paid-title'>
              We also have a variety of high-quality designs available for purchase
            </p>
            <div className='invite-landing-image-wrapper first-wrapper'>
              <img
                className='invite-landing-paid-item-bk-pc'
                src='/assets/img/inviteLanding/paid_item_bk_pc.png'
              />
            </div>
            <div className='invite-landing-image-wrapper'>
              <img
                className='invite-landing-paid-item-wh-pc'
                src='/assets/img/inviteLanding/paid_item_wh_pc.png'
              />
            </div>
            <img
              className='invite-landing-paid-item-mo'
              src='/assets/img/inviteLanding/paid_item_mo.png'
            />
          </section>
          {/* section6-review */}
          <section className='invite-landing-review-section'>
            <p className='invite-landing-review-title'>
              The enthusiastic responses <br />
              from customers to Fab365's designs.
            </p>
            <div className='invite-landing-image-wrapper'>
              <img
                className='invite-landing-review-pc'
                src='/assets/img/inviteLanding/review_pc.png'
              />
            </div>
            <img
              className='invite-landing-review-mo'
              src='/assets/img/inviteLanding/review_mo.png'
            />
          </section>
          {/* section7-enjoy */}
          <section className='invite-landing-enjoy-section'>
            <p className='invite-landing-enjoy-title'>Sign up now and enjoy our premium designs</p>
            <button
              className='invite-landing-sinup-btn invite-landing-signup'
              onClick={onClickSignUp}
            >
              <span>SIGN UP</span>
              <img
                className='invite-landing-sinup-btn-arrow'
                src='/assets/img/inviteLanding/btn_arrow.svg'
              />
              <img
                className=' invite-landing-enjoy-bg left'
                src='/assets/img/inviteLanding/enjoy_bg.png'
              />
              <img
                className='invite-landing-enjoy-bg right'
                src='/assets/img/inviteLanding/enjoy_bg.png'
              />
            </button>
            <p className='invite-landing-enjoy-sub-title'>Share this page with your friends</p>
            <div className='invite-landing-social-container'>
              <button className='invite-landing-social-facebook' onClick={shareFacebook}>
                <img src='/assets/img/inviteLanding/icon_facebook.png' />
              </button>
              <button className='invite-landing-social-x' onClick={shareTwitter}>
                <img src='/assets/img/inviteLanding/icon_twitter-X.png' />
              </button>
              <button className='invite-landing-social-link' onClick={shareUrl}>
                <img src='/assets/img/inviteLanding/icon-link.png' />
              </button>
            </div>
          </section>
        </div>
      </Container>
    </Layout>
  );
}

export default Landing;

const LandingPc = styled.div`
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    vertical-align: top;
  }

  section img {
    width: 100%;
  }

  a {
    cursor: pointer;
  }

  section.five-model,
  section.just-take,
  section.check-out,
  section .share-section {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .five-model .five-model-img-wrapper {
    position: absolute;
    bottom: 15%;
    width: 30%;
  }

  .just-take .just-take-img-wrapper {
    position: absolute;
    bottom: 15%;
    width: 30%;
  }

  .check-out .check-out-wrapper {
    position: absolute;
    bottom: 15%;
    width: 30%;
  }

  .share-section {
    position: relative;
  }

  .share-section .share-img-wrapper {
    position: absolute;
    width: 17%;
    left: 60%;
    bottom: 46%;
  }

  .invite-landing-social-container {
    position: absolute;
    top: 68%;
    width: 100%;
  }

  .invite-landing-social-container article {
    width: 9%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 27%;
  }

  .invite-landing-social-container a {
    margin: 2%;
  }

  .invite-landing-social-container img {
    width: 100%;
  }
`;

const LandingMobile = styled.div`
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    vertical-align: top;
  }

  section img {
    width: 100%;
  }

  a {
    cursor: pointer;
  }

  section {
    position: relative;
  }

  .mobile {
    display: block;
  }

  .five_model_m_btn,
  .just_take_m_btn {
    position: absolute;
    width: 90%;
    top: 84%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .just_so_m_btn {
    position: absolute;
    width: 90%;
    top: 91%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .sign_up_m_btn {
    position: absolute;
    width: 90%;
    top: 64%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .invite-landing-social-container_mo {
    display: flex;
    position: absolute;
    transform: translate3d(32%, -180%, 0px);
    justify-content: center;
    width: 60%;
  }

  .invite-landing-social-container_mo a {
    margin: 2px;
  }
`;

const LandingImg = styled.img`
  cursor: auto !important;
`;
