import TipFeature from "../page/PrintTip/component/TipFeature";

export const renderTipFeature = (img, subject, body, date, link) => {
  return (
    <TipFeature
      img={img}
      subject={subject}
      body={body}
      date={date}
      link={link}
    />
  );
};
