// import { HOSTNAME } from './request';

export const RETURN_CODE = {
  C00000: 'C00000',
  C00008: 'C00008',
  E00001: 'E00001', // 토큰 관련 에러
};

// 구글 로그인 api (구글로 회원가입 후 바로 로그인하기 위함)
// const FAB365_URL = 'fab365.net';
// const FAB365_URL_MOCK = 'java.fab365.net';
// const FAB365_DEV_URL = 'dev.fab365.net';
// const FAB365_DEV_URL_MOCK = 'dev-java.fab365.net';
// let redirectBaseUrl;

// if (HOSTNAME === FAB365_URL) {
//   redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL;
// } else if (HOSTNAME === FAB365_URL_MOCK) {
//   redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL_MOCK;
// } else if (HOSTNAME === FAB365_DEV_URL) {
//   redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL;
// } else if (HOSTNAME === FAB365_DEV_URL_MOCK) {
//   redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK;
// }

// const config = {
//   client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
//   redirect_uri: redirectBaseUrl,
//   access_type: 'offline',
//   response_type: 'code',
//   scope: 'email profile',
// };

// const queryString = Object.entries(config)
//   .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
//   .join('&');

// export const GOOGLE_LOGIN_CALL = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;
