import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import 'react-loading-skeleton/dist/skeleton.css';
import { ImgStyled } from '../../styles/ImgStyled';
import useImgLoadCheck from './useImgLoadCheck';

function AdTypeB({ data, isMobile }) {
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { isImageLoaded, handleImageLoad } = useImgLoadCheck();

  const SKELETON_PADDINGTOP_PC =
    '9.063%'; /* 이미지의 가로-세로 비율에 따라 조정 (세로 / 가로 * 100) */
  const SKELETON_PADDINGTOP_MO = '25.278%';

  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    if (!path?.includes('http')) {
      if (path.includes('my')) {
        if (!userState.isLogin) {
          // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
          modalAction.setIsLogin(true);
        } else {
          navigate(path);
        }
      } else {
        // 리액트 내부 링크일 때 리액트에서 이동
        navigate(path);
      }
    } else {
      // 리액트 외부 링크일 때 새로운 창 열기
      window.open(path, '_blank');
    }
  };

  return (
    <div
      className='adTypeB'
      style={{
        width: '100%',
        aspectRatio: isImageLoaded ? 'auto' : '8/1',
      }}
    >
      <div className='centerWrap'>
        {data?.banners.map((ad, idx) => {
          return (
            <Link
              to={ad.url}
              className={`adImg1in2 ${!isMobile ? 'adImg1in2Pc' : 'adImg1in2Mo'}`}
              onClick={e => goTo(e, ad.url)}
              key={idx}
            >
              <ImgStyled
                src={!isMobile ? ad.imgDesktop : ad.imgMobile}
                className='downloadNone'
                alt={ad.title}
                onLoad={handleImageLoad}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default AdTypeB;
