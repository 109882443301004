const Options = ({ option, value, onChange }) => {
  const select = (e) => {
    onChange(e.target.value);
  };
  return (
    <>
      <select
        className="form-input sort"
        style={{ marginLeft: 5 }}
        onChange={select}
        value={value}
      >
        {option?.map((o, idx) => {
          return (
            <option key={o.value} value={o.value}>
              {o.text}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default Options;
