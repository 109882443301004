const SelectReviewItems = ({ data, selected, onChange }) => {
  return (
    <>
      <div className='form-group form-group-h'>
        <label className='form-label'>Select Item for Review</label>
        <div className='form-control'>
          <select
            className='form-input'
            name='item'
            value={selected}
            onChange={e => onChange(e.target.value)}
          >
            <option value=''>===== Select Item =====</option>
            {data &&
              data?.map(o => {
                return (
                  <option key={o.id_item} value={o.id_item}>
                    {o.name_en}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </>
  );
};

export default SelectReviewItems;
