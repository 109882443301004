import { useContext } from "react";
import Layout from "../../component/layout/Layout";
import LangContext from "../../context/lang/lang";
import ContactEn from "./component/ContactEn";
import ContactKo from "./component/ContactKo";

const Contact = () => {
  const { action } = useContext(LangContext);
  return (
    <>
      <Layout>
        <div id="main">{action.isKo() ? <ContactKo /> : <ContactEn />}</div>
      </Layout>
    </>
  );
};

export default Contact;
