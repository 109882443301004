import { useState } from 'react';
import RequestStatus from '../../../component/request/RequestStatus';
import { REQUESTS } from '../../../route/constant';
import { getMainThumb } from '../../../util/image';
import { getOidUrl } from '../../../util/url';
import RequestIcon from './RequestIcon';
import Slider from 'react-slick';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../styles/ImgStyled';

const RequestList = ({ data, isSlide = false }) => {
  const [viewSize, setViewSize] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleResize = () => {
    setViewSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: viewSize <= 1024 ? true : false,
    infinite: false,
    speed: 300,
    slidesToShow:
      viewSize > 1024
        ? 4
        : viewSize <= 1024 && viewSize > 768
        ? 3
        : viewSize <= 768 && viewSize > 640
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      {isSlide ? (
        <Slider {...settings}>
          {data?.map((r, idx) => {
            return (
              <div className='list-item' key={idx}>
                <div className='post-list-item post-list-item-square'>
                  <Link
                    to={getOidUrl(REQUESTS, r.request_oid, r.subject, r.body)}
                    onClick={e => goTo(e, getOidUrl(REQUESTS, r.request_oid, r.subject, r.body))}
                  >
                    <div className='tb requestsTb'>
                      <ImgStyled src={getMainThumb(r)} alt='' />
                      <RequestIcon status={r.status} />
                    </div>
                    <div className='post-desc'>
                      <h3 className='post-title'>{r.subject}</h3>
                      <div className='post-meta'>
                        <span className='author'>
                          By. <span className='name'>{r.user_name}</span>
                        </span>
                        <span className='post-stat'>
                          <span className='comment'>{r.comment_count}</span>
                        </span>
                      </div>
                      <div className='request-status' style={{ overflow: 'hidden' }}>
                        <div className='request-status-header'>{Math.floor(r.vote_count)}</div>
                        <div className='request-status-body'>
                          <div className='progress'>
                            <span
                              className='bar'
                              style={{ width: Math.floor(r.vote_count) + '%' }}
                            ></span>
                          </div>
                        </div>
                        <div className='request-status-footer'>
                          <div className='supporters'>
                            Supporters &nbsp;
                            <strong>{r.vote_count}</strong>
                          </div>
                          <RequestStatus status={r.status} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </Slider>
      ) : (
        <>
          {data?.map((r, idx) => {
            return (
              <div className='list-item' key={idx}>
                <div className='post-list-item post-list-item-square'>
                  <Link
                    to={getOidUrl(REQUESTS, r.request_oid, r.subject, r.body)}
                    onClick={e => goTo(e, getOidUrl(REQUESTS, r.request_oid, r.subject, r.body))}
                  >
                    <div className='tb requestsTb'>
                      <ImgStyled src={getMainThumb(r)} alt='' />
                      <RequestIcon status={r.status} />
                    </div>
                    <div className='post-desc'>
                      <h3 className='post-title'>{r.subject}</h3>
                      <div className='post-meta'>
                        <span className='author'>
                          By. <span className='name'>{r.user_name}</span>
                        </span>
                        <span className='post-stat'>
                          <span className='comment'>{r.comment_count}</span>
                        </span>
                      </div>
                      <div className='request-status' style={{ overflow: 'hidden' }}>
                        <div className='request-status-header'>{Math.floor(r.vote_count)}</div>
                        <div className='request-status-body'>
                          <div className='progress'>
                            <span
                              className='bar'
                              style={{ width: Math.floor(r.vote_count) + '%' }}
                            ></span>
                          </div>
                        </div>
                        <div className='request-status-footer'>
                          <div className='supporters'>
                            Supporters &nbsp;
                            <strong>{r.vote_count}</strong>
                          </div>
                          <RequestStatus status={r.status} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default RequestList;
