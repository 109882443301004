import { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import {
  HELP_HOW_TO_DOWNLOAD_MODEL_FILES,
  HELP_HOW_TO_DOWNLOAD_SLICER_SETTING,
  HELP_HOW_TO_IMPORT_FAB365_CURA_PROFILE,
  HELP_POINT_HOW_TO_USE,
  HELP_SLICER_SETTINGS,
  PAGES_HELP_FREE_TICKET,
  PAGES_HELP_REVIEW,
} from '../../route/constant';
import './Help.css';
import { Link, useNavigate } from 'react-router-dom';

const Help = ({ tab, title }) => {
  const navigate = useNavigate();

  const toggle = e => {
    const classList = e.target.closest('.faq-list-item').classList;
    if (classList.value.indexOf('active') !== -1) {
      classList.remove('active');
    } else {
      classList.add('active');
    }
  };

  const faqItems = [
    {
      question: 'Earn Fab365 Credit by writing reviews',
      answer: (
        <>
          If you purchase a paid model and post a review, we will give you 100 Credits.{' '}
          <Link
            href=''
            onClick={e => goTo(e, PAGES_HELP_REVIEW)}
            className='faq-link fab-search-keyword'
          >
            Go to detail help page.
          </Link>
        </>
      ),
    },
    {
      question: 'What is a Reward Ticket?',
      answer: 'Reward tickets are coupons for downloading reward models.',
    },
    {
      question: 'What models can I redeem with a Reward Ticket?',
      answer:
        'Reward tickets can only be redeemed for a variety of reward models, not for paid models.',
    },
    {
      question: 'What is a Reward Model?',
      answer:
        'The reward models are created as a form of fan art, filled with utmost respect for masterpieces. And we share them for FREE with our customers.',
    },
    {
      question: 'How can I get the Reward Ticket?',
      answer:
        'You can receive reward tickets immediately after signing up for Fab365. And you can earn additional reward tickets for each purchased paid model (including models purchased with Fab365 credit).',
    },
    {
      question: 'What is the validity period of the Reward Ticket?',
      answer: 'The issued reward ticket is valid for 6 months.',
    },
    {
      question: 'Is there anything else to keep in mind about Reward Ticket?',
      answer:
        'Please note that reward tickets are only available for accounts that have completed email authentication.',
    },
    {
      question: 'Can I get the Reward Tickets by buying a paid model with Fab365 Credit?',
      answer:
        'Of course. Fab365 Credit is a digital currency that can be used like cash within the Fab365 service.',
    },
    {
      question: 'How to import fab365 cura profile?',
      answer: (
        <a
          href=''
          onClick={e =>
            goTo(e, HELP_HOW_TO_IMPORT_FAB365_CURA_PROFILE + '/how-to-import-fab365-cura-profile')
          }
          className='faq-link'
        >
          You can import fab365 cura profile on this page.
        </a>
      ),
    },
    {
      question: 'How to download model files?',
      answer: (
        <>
          You can download your own model files on your download page.
          <a
            href=''
            onClick={e =>
              goTo(e, HELP_HOW_TO_DOWNLOAD_MODEL_FILES + '/how-to-download-model-files')
            }
            className='faq-link'
          >
            Go to detail help page.
          </a>
        </>
      ),
    },
    {
      question: 'How to download slicer setting?',
      answer: (
        <>
          The slicing setting / profile is an expression of appreciation for paid purchasers. <br />
          If you have paid order and save printer/slicer information, you can download slicer
          settings. <br />
          <a
            href=''
            onClick={e =>
              goTo(e, HELP_HOW_TO_DOWNLOAD_SLICER_SETTING + '/how-to-download-slicer-setting')
            }
            className='faq-link'
          >
            Go to detail help page.
          </a>
        </>
      ),
    },
    {
      question: 'Slice Setting File List',
      answer: (
        <>
          Check the list of slice setting files now available.
          <a href='' onClick={e => goTo(e, HELP_SLICER_SETTINGS)} className='faq-link'>
            Go to detail help page.
          </a>
        </>
      ),
    },
    {
      question: 'How to use Fab365 Credits?',
      answer:
        'To use Fab365 Credits, please enter the amount you want to pay in the “Payment by credit” field during checkout.',
    },
    {
      question: 'Can I get a refund for the purchased file?',
      answer:
        'You can request a refund within 7 days from the date of purchase. However, if you have downloaded the file even once, a refund is not possible. For more details, please refer to Section 17 of the Terms of Service (General Membership).',
    },
    {
      question: 'Zip file is not extracted. What should I do?',
      answer:
        "Try another decompression software please. We sell by file testing many times. And it is extremely rare for a compressed file to not open. As a result, it is most likely a problem with the customer's Internet environment or the unpacking software.",
    },
    {
      question: 'Is the material used flexible material?',
      answer:
        'No. We use PLA filaments. Most PLA filaments have some elasticity, so they bend well and do not break at the right thickness. Fold the filament by hand to make sure your PLA is elastic enough. If the PLA you use breaks, it is not a suitable PLA for printing our foldable designs.',
    },
    {
      question:
        'If I purchase a model from your website, does it then give me the license to print and sell the model?',
      answer:
        "The files you purchase from FAB365 are for personal use only. That means you can't sell anything printed on the fab365's design. This is true for all digital files, not just 3D printed files. For example, if you purchased a music / movie file, you can't sell it on CD or USB, not just the file.",
    },
    {
      question: 'I want to print and sell the design of FAB365. What should I do?',
      answer: (
        <>
          You can subscribe to a commercial license on Patreon of GOODesign to sell this designer's
          printed products. However you need to separately purchase STL files. Sale of printed
          designs without license or resale of STL files are strictly prohibited. The commercial
          license is included in Tier B. For more information, please refer to the link below.
          <br />
          <a href='https://www.patreon.com/goodesign' target='_blank' rel='noreferrer'>
            https://www.patreon.com/goodesign
          </a>
          <br />
          Also before you decide to subscribe to any tier, please check the Important Notice below.
          <br />
          <a
            href='https://www.patreon.com/posts/important-notice-78113753'
            target='_blank'
            rel='noreferrer'
          >
            https://www.patreon.com/posts/important-notice-78113753
          </a>{' '}
          <br />
          For selling printed models of other designers please contact them separately.
        </>
      ),
    },
    {
      question: "I can't pay with my credit card. What's the problem?",
      answer:
        'Depending on your bank or individual, payment may be declined. There are so many different cases and it takes too long to get an answer from the bank. In this case, we recommend Paypal payment.',
    },
  ];

  // useEffect(() => {
  //   document.querySelectorAll('.faq-list-item').forEach(item => {
  //     item.addEventListener('click', toggle);
  //   });
  // }, []);

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu tab={tab} title={title} />
          <section className='about-section'>
            <div className='help'>
              <div className='container'>
                <div className='about-description-block'>
                  <div className='about-description-block-header'>
                    <h2>Help center</h2>
                    <p>How can we help you?</p>
                  </div>
                </div>

                <div className='faqs'>
                  <h3>FAQ</h3>
                  <div className='faq-list'>
                    {faqItems.map((item, index) => (
                      <div className='faq-list-item' key={index} onClick={toggle}>
                        <div className='q'>
                          <p>{item.question}</p>
                        </div>
                        <div className='a'>
                          <p>{item.answer}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='diagram'>
                  <div className='cta-box'>
                    <p className='txt-purple txt-xl m-b-lg'>
                      Can't find the answer you are looking for?
                    </p>
                    <p className='m-b-lg'>Please send us an e-mail. We will reply.</p>

                    <a href='mailto:help@fab365.net' className='btn btn-green btn-fill'>
                      Send E-mail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Help;
