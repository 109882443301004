import styled, { css } from 'styled-components';

const commonStyle = css`
  font-family: 'Inter', sans-serif;
`;

export const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const DesignerActionModalContainer = styled.div`
  ${commonStyle}
  border: 1px solid #000;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  max-width: 560px;

  padding: 0px 20px 20px;
  border-radius: 10px;

  text-align: center;
  background-color: #fff;
`;

export const DesignerActionModalTitle = styled.h2`
  ${commonStyle}

  font-size: 32px !important;
  font-weight: ${({ titleBold }) => titleBold} !important;

  padding: ${({ modalContent }) => (modalContent ? '30px  0 15px ' : '50px 0')} !important;

  white-space: pre-line;
`;

export const DesignerActionModalContent = styled.p`
  ${commonStyle}

  font-size: ${({ contentFontSize }) => contentFontSize};
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 30px !important;

  white-space: pre-line;
`;

export const ButtonStyle = styled.div`
  font-family: 'Outfit', sans-serif;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  gap: 40px;

  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export const ButtonItem = styled.button`
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: ${({ color }) => color} !important;

  padding: 12px 0px !important;
  width: 100%;

  border-radius: 10px;
  border: ${({ backgroundColor }) =>
    backgroundColor === '#fff' ? '1px solid #000' : 'none'} !important;
  background-color: ${({ backgroundColor }) => backgroundColor} !important;

  cursor: pointer !important;
`;
