import { useState } from 'react';
import styled from 'styled-components';

const TroubleCheckOut = () => {
  const [isHelpHidden, setIsHelpHidden] = useState(false);
  const [click, setClick] = useState(false);

  return (
    <TroubleCheckOutStyle isHelpHidden={isHelpHidden} click={click}>
      <div className='p-wrapper'>
        <p>Having trouble with checkout? </p>
        <img
          src='/assets/img/icon/question_mark.svg'
          alt=''
          onMouseEnter={() => {
            setIsHelpHidden(true);
            setClick(true);
          }}
          onMouseLeave={() => {
            setIsHelpHidden(false);
            setClick(false);
          }}
          onClick={() => {
            setClick(!click);
            setIsHelpHidden(!isHelpHidden);
          }}
        />
      </div>
      <div className='checkout_help'>
        Errors with payment seem to happen when your page is automatically translated from English
        to other languages. Please try to use the English version of our website or try to use
        another browser to log in. It should help you to proceed with payment. Please email us via
        info@fab365.net if it doesn't work, so we can assist you.
      </div>
    </TroubleCheckOutStyle>
  );
};

export default TroubleCheckOut;

const TroubleCheckOutStyle = styled.div`
  margin: 10px 0;

  .p-wrapper {
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
      width: 18px;
      height: 18px;
    }
  }

  .checkout_help {
    display: ${props => (props.isHelpHidden || props.click ? 'block' : 'none')};
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 30px 20px;
    background-color: #fff;
    margin-top: 20px;
    line-height: 20px;
  }

  @media screen and (min-width: 769px) {
    .p-wrapper {
      margin-bottom: ${props => (props.isHelpHidden || props.click ? '0' : '250px')};
    }
  }
`;
