import styled from 'styled-components';

export const FileModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  .file-gray {
    color: #9a9a9a;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
  }
  .small {
    font-size: 10px !important;
  }

  .file-size {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    font-family: 'Inter';
    .gap {
      margin-top: 3px;
    }
  }

  .close-btn {
    border: none;
    background-color: #fff;
    position: absolute;
    right: -30px;
    top: -40px;
    padding: ${props => props.status && '0 6px !important'};
    img {
      width: 24px;
    }
  }

  .filemodal-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 50px 30px;
    width: 560px;
    box-sizing: border-box;
  }

  .filemodal-top {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Outfit';
    border-bottom: 1px solid #9a9a9a;

    position: relative;
    height: 58px;
    /* margin-top: 16px; */
  }
  .filemodal-files {
    .filemodal-filesize {
      margin-top: 10px;
    }
    .delete-btn {
      border: 1px solid #9a9a9a;
      padding: 5px 25px;
      border-radius: 6px;
      font-weight: 600;
      color: #9a9a9a;
      background-color: #fff;
      font-size: 11px;
      font-family: 'Outfit';
    }

    .file-count {
      margin-left: 10px;
      font-size: 12px;
      font-family: 'INTER';
      font-weight: 400;
    }
  }

  .filemodal-list {
    background-color: #f5f5f5;
    max-height: 270px;
    min-height: 60px;
    margin-top: 50px;
    border-radius: 10px;
    padding: 10px;
    overflow: scroll;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    /* Firefox에서 스크롤바 숨기기 */
    scrollbar-width: none;

    .file-item {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }

    .file-selecte {
      width: 24px;
      height: 24px;
      border-radius: 100px;
      border: 1px solid #9a9a9a;
      margin-right: 6px;
      background-color: #fff;
      position: relative;

      .selected-item {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: #46b81e;
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }

  .file-wrap {
    display: flex;
    -webkit-box-align: center;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .file-total {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    margin: 3px 0px;
  }

  .margin {
    margin-top: 7px;
  }

  .files-btnwrap {
    display: grid;
    gap: 10px;
    justify-items: center;

    margin-top: 50px;
  }

  @media (max-width: 700px) {
    .filemodal-content {
      width: 90% !important;
      background-color: #fff;
      border-radius: 10px;
      padding: 50px 20px;
      box-sizing: border-box;
    }

    .filemodal-list {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      padding: 10px;
      gap: 10px;
      max-height: 200px;
    }

    .file-selectedsize {
      margin: 5px 0px;
    }
    .file-wrap {
      display: flex;
      -webkit-box-align: center;
      align-items: stretch;
      -webkit-box-pack: justify;
      justify-content: space-between;
      flex-direction: column;
    }

    .close-btn {
      right: -20px;
      top: -40px;
    }
    .files-btnwrap {
      margin-top: 30px;
    }
  }
`;

export const FileBtns = styled.button`
  background-color: ${props => props.bg} !important;
  color: #fff !important;
  height: 48px;
  width: 100%;
  max-width: 330px;
  border-radius: 10px;
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 20px;
  border: none !important;
  font-family: 'Outfit';

  cursor: pointer;
`;
