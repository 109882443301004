import styled from 'styled-components';

export const InvitePageContainer = styled.div`
  .invitation-wrap {
    width: 100%;
    height: 100%;
    background-color: #4fc75b;
    box-sizing: border-box;
    overflow: hidden;
  }

  .invitation-inner {
    width: 1248px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
  }

  /* bg */
  .bg-left,
  .bg-right {
    width: 265px;
    position: absolute;
  }

  .bg-left {
    left: 0;
  }

  .bg-right {
    right: 0;
  }

  .bg-left img,
  .bg-right img {
    width: 100%;
  }

  /* 로고 */
  .invitation-logo {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    padding: 50px;
    text-align: center;
  }

  .invitation-logo img {
    width: 76.87px;
  }

  /* 타이틀 */
  .invitation-title {
    width: 447px;
    /* background-color: purple; */
    text-align: center;
    position: relative;
  }

  .invitation-title .title-text {
    width: 447px;
  }

  .invitation-title .title-arrow {
    width: 36px;
    position: absolute;
    bottom: 10px;
  }

  .title-arrow-left {
    left: 60px;
  }

  .title-arrow-right {
    right: 60px;
  }

  /* link */
  .invitation-link {
    width: 100%;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    position: relative;
  }

  .link-box {
    width: 640px;

    margin: auto;
    border-radius: 20px;
    padding: 20px 50px;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: center;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Outfit', sans-serif;
    box-sizing: border-box;
  }

  .link-box .content {
    width: 100%;
  }

  .link-box .content .invite-text {
    text-align: start;
  }

  #copyText {
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-top: 30px;
  }

  .invitation-link input:focus {
    outline: none;
  }

  .invitation-link button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .invitation-link button img {
    width: 24px;
    padding-left: 10px;
  }

  /* desc */

  .invitation-desc {
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }

  .invitation-desc p {
    line-height: 1.5em;
    font-family: 'Pretendard', sans-serif;
    color: #404040;
  }

  .invitation-desc strong {
    font-weight: 800;
    line-height: 1.5em;
    color: #000;
  }

  /* 메인이미지 */

  .invitation-main {
    padding: 40px 0 50px 0;
    width: 100%;
    text-align: center;
    z-index: 10;
  }

  .invitation-main img {
    width: 592px;
  }

  /* 아이템 */

  .invitation-item-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .invitation-item-desc {
    width: 100%;
    color: #fff;
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .invitation-item-grid {
    display: grid;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
    grid-template-columns: repeat(5, minmax(240px, 1fr));
    transition: all 0.3;
    justify-content: space-evenly;
    align-content: space-evenly;
    gap: 10px;
  }

  .invitation-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .invitation-item img {
    transition: all 0.3;
    width: 240px;
    aspect-ratio: auto 1 / 1;
  }

  @media (max-width: 1325px) {
    /* invitation page */
    .invitation-item-wrap {
      width: 95%;
    }
    .invitation-item-grid {
      grid-template-columns: repeat(5, minmax(220px, 1fr));
    }
    .invitation-item img {
      width: 220px;
      height: 220px;
      transition: all 0.3;
    }
  }

  @media (max-width: 1270px) {
    /* invitation page */
    .invitation-inner {
      width: 100%;
    }
    .invitation-item-wrap {
      width: 88%;
    }
    .invitation-item-grid {
      grid-template-columns: repeat(5, minmax(200px, 1fr));
    }
    .invitation-item img {
      width: 200px;
      height: 200px;
    }
  }

  @media (max-width: 1180px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(5, minmax(180px, 1fr));
    }
    .invitation-item img {
      width: 180px;
      height: 180px;
    }
  }

  @media (max-width: 1100px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(3, minmax(180px, 1fr));
      justify-content: center;
      align-content: center;
    }
    .invitation-item:nth-child(10) {
      grid-column: 2; /* 마지막 아이템을 두 번째 열로 이동 */
    }
    .invitation-item img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 962px) {
    /* invitation page */
    .invitation-desc {
      padding: 10px 100px;
      width: 100%;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    /* invitation page */
    .link-box {
      margin: 0;
      width: 80%;
      height: auto;
      margin: auto;
      border-radius: 20px;
      padding: 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: 25.2px;
      text-align: center;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .link-box #copyText {
      padding-bottom: 10px;
    }
  }
  @media (max-width: 640px) {
    /* invitation paga */
    .invitation-item-grid {
      grid-template-columns: repeat(2, minmax(180px, 1fr));
      justify-content: center;
      align-content: center;
    }
    .bg-left {
      left: -100px;
    }
    .bg-right {
      right: -100px;
    }
    .invitation-main img {
      width: 100%;
      padding-bottom: 80px;
    }
    .invitation-item-desc {
      text-align: center;
    }
  }

  @media (max-width: 550px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(2, minmax(160px, 1fr));
      justify-content: center;
      align-content: center;
    }

    .bg-left {
      left: -150px;
    }

    .bg-right {
      right: -150px;
    }

    .invitation-title {
      width: 80%;
      text-align: center;
      position: relative;
      margin-bottom: 50px;
    }

    .invitation-title .title-text {
      width: 100%;
    }

    .invitation-title .title-arrow {
      width: 36px;
      position: absolute;
      bottom: -50px;
    }

    .title-arrow-left {
      display: none;
    }

    .title-arrow-right {
      left: 0;
      right: 0;
      margin: auto;
    }
    .invitation-link {
      padding-top: 20px;
    }
    .link-box {
      box-sizing: border;
      font-size: 16px;
    }
    .invitation-desc {
      padding: 10px 50px;
    }
  }

  @media (max-width: 360px) {
    /* invitation page */
    .link-box {
      font-size: 14px;
    }
    .item-grid {
      grid-template-columns: repeat(2, minmax(160px, 1fr));
    }
  }

  @media (max-width: 340px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(2, minmax(130px, 1fr));
    }
  }

  @media (max-width: 310px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(2, minmax(130px, 1fr));
    }
    .invitation-item img {
      width: 130px;
      height: 130px;
    }
    .invitation-main {
      padding-bottom: 0;
    }
  }

  @media (max-width: 290px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
    .invitation-item img {
      width: 120px;
      height: 120px;
    }
    .invitation-main {
      padding-bottom: 0;
    }
  }

  @media (max-width: 260px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
    .invitation-item img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 230px) {
    /* invitation page */
    .invitation-item-grid {
      grid-template-columns: repeat(2, minmax(80px, 1fr));
    }
    .invitation-item img {
      width: 80px;
      height: 80px;
    }
  }
`;
