import {
  InputStyle,
  InputWrapper,
  LabelStyle,
  LabelWrapper,
  ManageUploadInputContainer,
  RequiredText,
  SymbolBox,
  ValueLength,
} from './styles/ManageUploadInputStyle';

const ManageUploadInput = ({
  label,
  id,
  type,
  onChange,
  value,
  placeholder,
  isRequired,
  requireText,
  symbol,
  backgroundColor,
  maxLength,
  disabled,
  name,
  symbolSize,
  textAlign,
  onKeyDown,
}) => {
  return (
    <ManageUploadInputContainer className='ManageUploadInput'>
      {label && (
        <LabelWrapper>
          <LabelStyle htmlFor={id}>{label}</LabelStyle>
          {requireText && isRequired && <RequiredText>* {requireText}</RequiredText>}
        </LabelWrapper>
      )}
      <InputWrapper symbol={symbol} isRequired={isRequired} value={value}>
        <InputStyle
          id={id}
          type={type}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          isRequired={isRequired}
          symbol={symbol}
          backgroundColor={backgroundColor}
          maxLength={maxLength}
          disabled={disabled}
          name={name}
          textAlign={textAlign}
          min={type === 'number' ? 0 : undefined}
          onKeyDown={onKeyDown}
        />
        {symbol && <SymbolBox symbolSize={symbolSize}>{symbol}</SymbolBox>}
        {maxLength && (
          <ValueLength>
            <span>
              {value?.length} / {maxLength}
            </span>
          </ValueLength>
        )}
      </InputWrapper>
    </ManageUploadInputContainer>
  );
};

export default ManageUploadInput;
