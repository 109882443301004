import { useState } from 'react';
import { useRef } from 'react';
import { DEFAULT_AVATAR } from '../../../util/image';
import {
  createItemComment,
  uploadItemImage,
  postDesignerCommentsWrite,
} from '../../../action/request';
import { imgHtml } from '../../../util/comment';
import { RETURN_CODE } from '../../../action/constant';

const ItemCommentReplyWrite = ({
  userState,
  root,
  parent,
  onReload,
  studioId,
  value,
  thumbnail,
}) => {
  const [editBody, setEditBody] = useState('');
  const [thumbs, setThumbs] = useState([]);
  const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState(false);
  const imageInput = useRef(null);

  const onDeleteImage = idx => {
    const newMedias = medias.filter((media, i) => i !== idx);
    const newThumbs = thumbs.filter((thumb, i) => i !== idx);
    setMedias(newMedias);
    setThumbs(newThumbs);
  };

  const imageChanged = async e => {
    try {
      const files = e.target.files;
      const newMedias = [...medias];
      const newThumbs = [...thumbs];
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadItemImage({
          file: files[i],
        });
        newThumbs.push(imgHtml(res.img.thumbs[0].url));
        newMedias.push(res.img);
      }
      setMedias(newMedias);
      setThumbs(newThumbs);
      setLoading(false);
    } catch (e) {
      alert('request failed');
    }
  };

  const onCreateComment = async e => {
    e.stopPropagation();
    e.preventDefault();
    // 아무 내용 없다면 알림
    if (!editBody || editBody === '') {
      return alert('Comment is empty');
    }
    try {
      if (value === 'designer') {
        const { data: desingerComment } = await postDesignerCommentsWrite(studioId, {
          root: '',
          parent: parent,
          studioId: Number(studioId),
          msg: editBody,
          'imgs[]': medias.map(media => JSON.stringify(media)),
          medias,
          thumbs: [{ size: '200x200', url: userState?.thumbnail }],
        });

        if (desingerComment.returnCode === RETURN_CODE.C00000) {
          onReload();
        }
      } else {
        const { data: res } = await createItemComment({
          root,
          parent,
          msg: editBody,
          imgs: medias.map(media => JSON.stringify(media)),
          medias,
        });

        if (res.returnCode === RETURN_CODE.C00000) {
          onReload();
        }
      }

      setEditBody('');
    } catch (e) {}
  };

  return (
    <div className='comment-item comment-item-re' id="' + hash + '">
      <div className='comment-write'>
        <div className='profile'>
          <div className='profile-pic'>
            {userState?.isDesigner && Number(studioId) === Number(userState?.idStudio) ? (
              <img src={'/assets/img/designers/crown.png'} className='crown' />
            ) : (
              ''
            )}
            <img src={userState?.thumbnail || thumbnail || DEFAULT_AVATAR} alt='' />
          </div>
        </div>
        <form className='comment-write-form' data-action='1103-comment-reply-submit'>
          <div className='write-form'>
            <textarea
              placeholder='Please input your text'
              name='msg'
              value={editBody}
              onChange={e => setEditBody(e.target.value)}
            ></textarea>
            <div className='attachments'>
              <ul className='files'>
                {thumbs?.map((thumb, idx) => {
                  return (
                    <li key={idx + thumb.url}>
                      <div className='file-item'>
                        <a>
                          <img src={thumb.url} alt='' />
                        </a>
                        <button
                          type='button'
                          className='btn-delete'
                          onClick={() => onDeleteImage(idx)}
                        >
                          ×
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='form-action'>
              <div className='file-uploader'>
                <input
                  type='file'
                  accept='image/*'
                  multiple
                  ref={imageInput}
                  onChange={imageChanged}
                />
                <button
                  type='button'
                  className={'btn-upload-file loading_color ' + (loading && 'loading')}
                  onClick={() => imageInput.current.click()}
                >
                  Add Image
                </button>
              </div>
              <button
                type='submit'
                className='btn btn-green btn-fill'
                onClick={onCreateComment}
                id='addBtn'
              >
                Add Comments
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ItemCommentReplyWrite;
