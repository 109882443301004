import { useContext, useEffect, useState } from 'react';
import { deleteCartAllItem, deleteCartItem, getCarts } from '../../action/request';
import Layout from '../../component/layout/Layout';
import CartContext from '../../context/cart.js/carts';
import { CATEGORIES } from '../../route/constant';
import { getSymbol } from '../../util/image';
import ItemBanner from '../Item/component/ItemBanner';
import CartItems from './component/CartItems';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../constant';

const Cart = () => {
  const ZERO_POINT = 0;

  const [total, setTotal] = useState(ZERO_POINT.toFixed(2));
  const [limit, setLimit] = useState({});
  const { state, action } = useContext(CartContext);
  const [carts, setCarts] = useState({});
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    // 처음 진입 시 체크 아이템 초기화
    action.initCheck();
    fetchData();
  }, []);

  useEffect(() => {
    let total = 0;
    for (const p in state.check) {
      if (state.check[p] !== 'false') {
        total += parseFloat(state.check[p].listPrice);
      }
    }
    setTotal(total !== 0 ? total.toFixed(2) : ZERO_POINT.toFixed(2));
  }, [state.check]);

  const fetchData = async () => {
    try {
      const { data: resCarts } = await getCarts();
      setCarts(resCarts.data.checkoutDto);
      setLimit(resCarts.data.freeItemLimitDto);

      resCarts?.data?.checkoutDto?.products?.forEach(
        p => p.isBuyable && action.checkProduct(p.productId, p)
      );

      action.changeCartsCount(resCarts.data?.checkoutDto?.products?.length || 0);
      localStorage.setItem(ls.cartsCount, resCarts.data?.checkoutDto?.products?.length || 0);
    } catch (e) {}
  };

  const deleteItem = async itemId => {
    try {
      await deleteCartItem(itemId);
      action.uncheckProduct(itemId);
      fetchData();
    } catch (e) {}
  };

  const deleteAllItem = async () => {
    if (!carts.products?.length) return;

    try {
      await deleteCartAllItem();
      // carts context 초기화
      action.initCarts();
      fetchData();
    } catch (e) {}
  };

  const checkout = async () => {
    const checkedItems = Object.values(state.check)
      .filter(item => item !== 'false')
      .map(item => Number(item.productId));
    if (checkedItems.length) {
      try {
        const params = checkedItems.join(',');
        navigate(`/checkout-step1?item=${params}`);
      } catch (e) {}
    } else alert('No items selected');
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  return (
    <>
      <Layout>
        <div className='wrapper'>
          <div id='main'>
            <div className='process'>
              <div className='container'>
                <ul>
                  <li>
                    <div className='process-item active'>
                      <span className='num'>1</span>
                      <span className='label'>Cart</span>
                    </div>
                  </li>
                  <li>
                    <div className='process-item'>
                      <span className='num'>2</span>
                      <span className='label'>Payment</span>
                    </div>
                  </li>
                  <li>
                    <div className='process-item'>
                      <span className='num'>3</span>
                      <span className='label'>Download</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <section className='shoppingbag-section'>
              <div className='container'>
                <div className='shoppingbag'>
                  <div className='cart'>
                    <div className='content-box'>
                      <div className='content-box-header'>
                        <h2>
                          Cart <small className='badge'>{carts.products?.length || 0}</small>
                        </h2>
                      </div>
                      <div className='content-box-body'>
                        <CartItems data={carts.products} limit={limit} deleteItem={deleteItem} />
                        <div className='btn-action'>
                          <button className='btn btn-sm cart-delete-all' onClick={deleteAllItem}>
                            Delete All
                          </button>
                          <hr />
                          <a href='' className='txt-purple' onClick={e => goTo(e, CATEGORIES)}>
                            Continue Shopping &gt;
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='total'>
                    <div className='your-order-box'>
                      <h3>Your Order</h3>
                      <div className='your-order-info'>
                        <dl>
                          <dt>Total</dt>
                          <dd>
                            <span className='notranslate'>
                              <strong className='total-price'>{total}$</strong>
                            </span>
                          </dd>
                        </dl>
                        <button
                          className='btn btn-fill btn-green btn-full checkout'
                          onClick={checkout}
                        >
                          Check Out
                        </button>
                      </div>
                    </div>
                    <ItemBanner bannerName={'cardBanner'} isLogin={true} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Cart;
