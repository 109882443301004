import { useParams } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import RequestMain from './component/RequestMain';
import RequestDetail from './RequestDetail';
import { useEffect, useState } from 'react';
import { ls } from '../../constant';
import CommentImageViewer from '../../component/comment/CommentImageViewer';

const Request = () => {
  const { oid, subject } = useParams();
  const [showImgViewer, setShowImgViewer] = useState(false);
  const [imgData, setImgData] = useState({ index: 0, data: {} });

  useEffect(() => {
    return () => {
      // request 페이지 아닐경우 request sort, status, page, search 값 초기화
      localStorage.removeItem(ls.requestSort);
      localStorage.removeItem(ls.requestStatus);
      localStorage.removeItem(ls.requestPage);
      localStorage.removeItem(ls.requestSearch);
    };
  }, []);

  return (
    <>
      <Layout>
        <div id='main'>
          {!oid && <RequestMain />}
          {oid && (
            <RequestDetail
              oid={oid}
              subject={subject}
              setShowImgViewer={setShowImgViewer}
              setImgData={setImgData}
            />
          )}
        </div>
      </Layout>
      {showImgViewer && (
        <CommentImageViewer imgData={imgData} setShowImgViewer={setShowImgViewer} />
      )}
    </>
  );
};

export default Request;
