import styled from 'styled-components';

export const LargestPartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: 'Inter', sans-serif;

  input {
    width: 100%;
    max-width: 60px;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0;
    font-family: 'Inter', sans-serif;
    text-align: left;
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  label {
    text-align: center;
  }
`;

export const PartSizeInput = styled.input`
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 5px !important;
  border: 1px solid #000;
  border-radius: 5px;
  margin-left: 5px !important;
`;

export const Section = styled.div`
  width: 100%;
  background-color: #dfdfdf;
  padding: 6px 0 6px 6px;
  .input-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Symbol = styled.div`
  background-color: #9a9a9a;
  color: #fff;

  width: 100%;
  max-width: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;
  height: 50px;
`;
