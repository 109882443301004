import styled from 'styled-components';

export const EeaWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EeaBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* backdrop-filter: blur(5px); */
`;

export const GtagWrap = styled.div`
  position: fixed;

  background-color: #000;

  bottom: ${props => props.bottom}px;
  left: ${props => props.left}px;

  border-radius: ${props => props.radius};
  z-index: ${props => props.zIndex};
`;

export const GtagModal = styled.div`
  max-width: ${props => props.width};
  padding: 20px 30px;
  z-index: 999;
  .cookiesimg {
    width: 100%;
    margin-bottom: 20px;
    max-height: 150px;
  }
  span {
    color: #fff;
    font-family: Pretendard;
    line-height: 20px;
    font-size: 15px;
    font-weight: 300;
  }
  .bold {
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;
  }

  .btnWrap {
    margin-top: 20px;
    .settingPC {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .settingMobile {
      button {
        margin-top: 10px;
      }
    }
  }
`;

export const BtnDisplay = styled.div``;

export const GtgBtn = styled.button`
  width: ${props => props.width};
  height: 50px;
  background-color: ${props => props.color}!important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600 !important;
  font-family: Outfit;
  margin-top: ${props => props.top}px !important;

  border: none;
  color: #000;
`;

export const GtgSetting = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  z-index: 999;
  height: 100vh;

  .settingPc {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    min-width: 360px;
    max-width: 500px;
    min-height: 500px;
    max-height: 85vh;

    background-color: #000;
    border-radius: 16px;

    padding-bottom: 100px;

    .btnPC {
      display: flex;
      height: 100px;
      justify-content: space-between;
      padding: 10px 20px;
      border-top: 1px solid #fff;
      align-content: center;
      align-items: center;
      background-color: #000;
      border-radius: 0px 0px 16px 16px;
    }
  }

  .fixed {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }

  .settingMobile {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #000;
    border-radius: 16px;

    .btnMobile {
      display: flex;
      flex-direction: column;
      padding: 25px 20px;
      border-top: 1px solid #fff;
      align-content: center;
      align-items: center;
      position: fixed;
      bottom: 0px;
      width: 100%;

      background-color: #000;
      box-sizing: border-box;
      button {
        margin-top: 10px;
      }
    }
  }

  .settingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 10px 15px 10px 30px;
    background-color: #ffffff;
    border-radius: 16px 16px 0px 0px;

    button {
      border: none;
      background: none;
    }

    .logo {
      width: 133px;
    }
    .close {
      width: 24px;
      height: 24px;
    }
  }

  .seettingContent {
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    margin-left: 10px;
  }

  p {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .content {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
    color: #cccccc;
    display: inline-block;
    padding-right: 5px;
  }
  .policy {
    color: #cccccc;
    cursor: pointer;
    text-decoration: underline;
    text-underline-position: under;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 5px;
    font-size: 15px;
  }

  .manage {
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0px 10px;

    .manageContent {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #d9d9d9;
    }
    .header {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      height: 85px;

      cursor: pointer;
      .flex {
        display: flex;
        align-items: center;
      }
      .title {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
      }
      .always {
        color: #96ff85;
        text-align: right;
      }
      .arrowimg {
        width: 20px;
        margin-right: 10px;
      }
    }

    .contentsWrap {
      padding-bottom: 30px;
    }
  }
`;

export const ToggleContainer = styled.div`
  position: relative;
`;

export const ToggleBg = styled.div`
  width: 45px;
  height: 26px;
  border-radius: 30px;
  background-color: ${props => props.bg};
`;

export const ToggleCircle = styled.div`
  position: absolute;
  top: 2px;
  left: ${props => props.left}px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${props => props.bg};
  transition: 0.5s;
`;

export const Scroll = styled.div`
  overflow-y: scroll;
  height: calc(75vh - 200px);

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;
