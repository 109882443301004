const ItemDataVideo = ({videos}) => {
  return (
    <>
      {videos?.map((v, idx) => {
        return (
          <div className="video-container" key={idx}>
            <iframe
              src={"https://www.youtube.com/embed/" + v.youtube_id}
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        );
      })}
    </>
  );
};

export default ItemDataVideo