import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, Virtual } from 'swiper/modules';
import { getHomeJumbotron2 } from '../../../action/request';
import { Link, useNavigate } from 'react-router-dom';
import { replicateObject } from '../../../util/skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { theme } from '../../../styles/theme';
import { ImgStyled } from '../../../styles/ImgStyled';

function MainJumbotron({ isMobile }) {
  const emptySlides = replicateObject(
    {
      imgDesktop: null,
      imgMobile: null,
      separation: null,
      sort: null,
      title: null,
      url: null,
    },
    3
  );
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState(emptySlides);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const SKELETON_PADDINGTOP_PC =
    //innerWIdth 는 현 브라우저의 width를 가리킵니다.
    innerWidth > 980 ? '470px' : '40.00%';
  const SKELETON_PADDINGTOP_MO = '63.88%';

  const navigate = useNavigate();

  const options = {
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 10,
    loop: true,
    loopAdditionalSlides: 1,

    autoplay: {
      delay: 5000,
      disableOnInteraction: false, // 사용자의 슬라이드 조작 후에도 자동 재생 계속
      pauseOnMouseEnter: true, // 마우스 오버 시 일시 정지 활성화
    },
    pagination: {
      el: '.swiper-pagination-main1',
    },
    navigation: {
      prevEl: '.buttonSwiperPrevMain1',
      nextEl: '.buttonSwiperNextMain1',
    },
  };

  const fetchData = async () => {
    try {
      // const { data } = await getHomeJumbotron();
      const { data } = await getHomeJumbotron2();
      const resultData = data.data;
      const sortData = resultData.sort((a, b) => Number(b.sort) - Number(a.sort));

      setSlides(sortData);
      setIsSkeletonLoading(false);
    } catch (e) {}
  };

  const resizeListener = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSkeletonLoading) return;

    if (!path?.includes('http')) {
      // 리액트 내부 링크일 때 리액트에서 이동

      if (path.includes('designers')) {
        // navigate(path);

        const arr = path.split(',');
        navigate(arr[0], { state: { id: arr[1] } });
      } else {
        navigate(path);
      }
    } else {
      // 리액트 외부 링크일 때 새로운 창 열기
      window.open(path, '_blank');
    }
  };

  // swiper는 슬라이드 목록이 없을 때 autoplay, navigation 동작을 시작하지 않는 오류가 생기기 때문에 slide 값이 있을 때 동작하게 한다.
  if (!slides.length) return null;
  return (
    <div className={`homeSection ${isMobile && 'mobile'}`}>
      {isSkeletonLoading ? (
        <Skeleton
          style={{
            paddingTop: isMobile ? SKELETON_PADDINGTOP_MO : SKELETON_PADDINGTOP_PC,
            height: '100%',
            borderRadius: isMobile ? 0 : '8px',
          }}
          baseColor={theme.skeleton_dark_gray}
          highlightColor={theme.sleleton_dark_gray_highlight}
        />
      ) : (
        <div className='centerWrap mainJumbotron'>
          {slides && (
            <>
              <Swiper
                className='mainSwiper'
                {...options}
                modules={[Pagination, Navigation, Autoplay, Virtual]}
                virtual={slides?.length > 3 ? false : true}
              >
                <div className='swiper-wrapper'>
                  {slides.map(slide => (
                    <SwiperSlide key={slide.title}>
                      <div className='swiper-slide'>
                        <Link
                          to={slide.url}
                          className={!isMobile ? 'imgPc' : 'imgMo'}
                          onClick={e => goTo(e, slide.url)}
                        >
                          <ImgStyled
                            src={!isMobile ? slide.imgDesktop : slide.imgMobile}
                            alt={slide.title}
                            className='downloadNone'
                            fetchpriority='high' // 브라우저에 우선적으로 처리할 이미지 알려주는 코드
                          />
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>
                <div
                  className='swiper-pagination swiper-pagination-main1'
                  style={{ opacity: isSkeletonLoading ? 0 : 1 }}
                ></div>
                <button
                  tabIndex='0'
                  type='button'
                  className='buttonSwiperPrev buttonSwiperPrevMain1'
                  title='prev'
                  style={{ opacity: isSkeletonLoading ? 0 : 1 }}
                />
                <button
                  tabIndex='0'
                  type='button'
                  className='buttonSwiperNext buttonSwiperNextMain1'
                  title='next'
                  style={{ opacity: isSkeletonLoading ? 0 : 1 }}
                />
              </Swiper>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default MainJumbotron;
