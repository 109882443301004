import { useContext, useEffect } from 'react';
import LangContext from '../../../context/lang/lang';
import {
  ABOUT,
  ABOUT_CEO,
  ABOUT_CO_CEO,
  ABOUT_PHILOSOPHY,
  ABOUT_SERVICE,
} from '../../../route/constant';
import './AboutKo.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

const AboutKo = () => {
  const { action } = useContext(LangContext);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  });

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <section className='fab-page-tabmenu'>
        <div className='container'>
          <div className='fab-page-tabmenu-inner'>
            <ul>
              <li>
                <a href='' onClick={e => goTo(e, ABOUT_SERVICE)}>
                  <span>SERVICE</span>
                </a>
              </li>
              <li className='--active'>
                <a href='' onClick={e => goTo(e, ABOUT)}>
                  <span>ABOUT</span>
                </a>
              </li>
            </ul>
            <div className='fab-langs'>
              <a
                href='#'
                className='locale-en'
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('en', 'service');
                }}
              >
                EN
              </a>{' '}
              <span className='--split'>/</span>
              <a
                href='#'
                className='--active locale-ko'
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.setLangState('ko', 'service');
                }}
              >
                KR
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section fab-page-section-ko' id='fabPageAboutTopDesc'>
        <div className='container fab-page-container'>
          <div className='--row'>
            <div className='--title'>
              <h2 className='--txt-xxl'>
                FAB365
                <br /> <strong>비전</strong>
              </h2>
            </div>
            <div className='--desc'>
              <blockquote className='--txt-lg --mb-40'>
                "미래에는 누구나 상상하는 모든 것을&nbsp;
                <br className='--when-mobile' />
                쉽고 빠르게 3D 프린팅할수 있는
                <br className='--when-desktop' />
                <br className='--when-mobile' />
                시대가 올 것입니다.&nbsp;
                <br className='--when-mobile' />
                <br className='--when-mobile' />
                <strong>
                  전세계에서 가장 놀라운 고품질의&nbsp;
                  <br className='--when-mobile' />
                  3D 모델을 <br className='--when-desktop' />
                  만들어 고객들이 언제 어디서나 즐겁게 3D프린팅 하는 것,{' '}
                </strong>
                <br />
                FAB365가 반드시 이루고자 하는&nbsp;
                <br className='--when-mobile' />
                목표입니다."
              </blockquote>
              <p className='--font-basic'>
                FAB365는 좋은 디자인이라면 기꺼이 가치를 지불할 수 있는 전 세계의 고객들을
                모아나가면서, 그들을 만족시킬 수 있는 뛰어난 디자이너들을 초대할 것입니다. 3D
                디자이너가 고품질의 디자인을 지속적으로 생산할 수 있고, 3D 프린터 사용자들이
                고품질의 디자인을 합리적인 가격에 쉽고 편하게 즐길 수 있는 선순환의 시스템을 만들어
                갈 것입니다. 그리고 최종적으로는 사용자와 디자이너들이 활발하게 교류할 수 있는
                플랫폼으로 성장할 것입니다.
                <br />
                <br />
                3D 프린터의 등장으로 개인의 창의력을 자유롭게 발휘할 수 있는 시대가 가능해 졌습니다.
                실제로 지난 10여년간 많은 3D 디자이너들이 재미있는 실험에 도전했고, 다양한 작품들이
                탄생했으며, 자유롭게 공유되었습니다. 게다가 값싸고 성능좋은 3D 프린터의 대량보급에
                힘입어서 완전히 새로운 시대가 열리고 있습니다. 그 새로운 시대를 맞아 FAB365가 중심이
                되어 3D 프린팅용 컨텐츠의 리더로 성장하고자 합니다.
                <br />
                <br />
                우리는 3D프린터 사용자들이 만족할만한 디자인을 찾기 위해서 수년간 수많은 실험을
                거듭했습니다. 그 과정에서 우리는 방대한 정보와 높은 수준의 디자인 노하우를 얻게
                되었고, 고객들이 어떤 디자인을 원하는지 알게 되었습니다. FAB365의 차별화된
                디자인들은 이러한 과정을 통해서 탄생했으며, 앞으로도 전세계 수많은 사용자들의 사랑을
                받기 위해 끊임없이 컨텐츠를 개발하고 기술에 투자할 것입니다.
              </p>
            </div>
          </div>
        </div>
        <img
          src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/img1.jpg'
          alt=''
        />
      </section>

      <section className='fab-page-section fab-page-section-ko' id='fabPageAboutFeatures'>
        <div className='container fab-page-container'>
          <article className='fab-about-feature-box' data-aos='fade-up'>
            <div className='fab-about-feature-box-inner'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/i1.svg'
                alt=''
                className='--icon'
              />
              <h3 className='--title'>
                유저들이 원하는
                <br />
                <strong>아이템이 가장 많은 서비스</strong>
              </h3>
              <p className='--desc --font-basic --compact'>
                3D프린팅을 사용하는 얼리어댑터 유저들의 성향에 대한 분석, 전세계 3D 모델 빅데이터
                분석을 통해 우리는 유저들이 원하는 아이뎀을 만들고 있습니다. 또한 아이템 요청하기,
                프린팅 문제 해결과 같은 서비스로 사용자들과의 활발한 커뮤니케이션을 통해서
                사용자들이 가장 원하는 아이템을 끊임없이 출시할 것입니다.
              </p>
            </div>
          </article>
          <article className='fab-about-feature-box' data-aos='fade-up'>
            <div className='fab-about-feature-box-inner'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/i2.svg'
                alt=''
                className='--icon'
              />
              <h3 className='--title'>
                <strong>선도적인 기술의</strong>
                <br />
                3D 모델 디자인
              </h3>
              <p className='--desc --font-basic --compact'>
                가장 대중적인 FDM 뿐만 아니라 멀티컬러 프린터, DLP 프린터, 메탈 프린터 등에서도
                출력이 잘되는 컨텐츠를 설계하는 기술적인 노하우와 디자인 능력을 쌓아갈 것입니다.
                그리고 이러한 기술력을 바탕으로 세계에서 3D 프린팅에 최적화된 설계를 가장 잘하는
                기업이 되고자 합니다.
              </p>
            </div>
          </article>
          <article className='fab-about-feature-box' data-aos='fade-up'>
            <div className='fab-about-feature-box-inner'>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/i3.svg'
                alt=''
                className='--icon'
              />
              <h3 className='--title'>
                <strong>최고의 3D 디자이너들</strong>이<br />
                참여하는 플랫폼
              </h3>
              <p className='--desc --font-basic --compact'>
                전세계의 3D 디자이너들이 우리의 플랫폼에서 자신의 훌륭한 3D 모델을 판매할 수 있도록
                도울 것입니다. 유저들은 좋은 품질의 3D 모델을 합리적인 가격에 구매할 수 있고 3D
                디자이너들은 자신의 디자인을 전세계에 판매할 수 있는 기회를 얻을 수 있습니다.
              </p>
            </div>
          </article>
        </div>
      </section>

      <section className='fab-page-section fab-page-section-ko --dark' id='fabPageAboutPresent'>
        <div className='container fab-page-container'>
          <h3 className='--title --txt-xxl'>
            FAB365 <br />
            <strong>현재</strong>
          </h3>
          <img
            src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/img2.jpg'
            alt=''
            className='--img'
          />
          <blockquote className='--quote --txt-lg --mb-50 --mb-30--m'>
            "<strong>전세계 200여개국의 사용자들</strong>이<br />
            FAB365의 컨텐츠로 3D 프린팅하고&nbsp;
            <br className='--when-mobile' />
            있습니다."
          </blockquote>
          <p class='--desc --font-basic'>
            FAB365는 대한민국에서 탄생 하였지만 현재 전세계 200여개국에서 14만명 이상의 사람들이
            사용하는
            <br />
            글로벌 서비스로 자리매김 하고 있습니다. 특히 미국, 영국, 프랑스와 같은 3D 프린팅
            선진국의 사용자들이
            <br />
            매일매일 컨텐츠를 다운로드하고 있으며, 높은 만족도의 리뷰는 FAB365에 대한 높은 신뢰를
            보여주는 것입니다.
          </p>
        </div>
      </section>

      <section className='fab-page-section fab-page-section-ko' id='fabPageAboutStats'>
        <div className='container fab-page-container'>
          <div className='--grid'>
            <div className='--col'>
              <article className='fab-about-stat-box' data-aos='fade-up'>
                <div className='fab-about-stat-box-inner'>
                  <h3 className='--title --same-height --txt-lg'>
                    <strong>200개국, 14만명</strong>
                    <br /> 이상의 유저
                  </h3>
                  <p class='--font-basic'>
                    현재 전세계 200개국의 14만명 이상의 등록된 유저들이 <br />
                    FAB365의 최고의 3D 프린팅 컨텐츠를 즐기고 있습니다. <br />
                    인스타그램 약 10만명의 구독자, 유튜브 약 13만명의 구독자와 함께 하고 있습니다.
                  </p>
                </div>
                <img
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/sns.png'
                  className='--diagram'
                />
              </article>
            </div>
            <div className='--col'>
              <article className='fab-about-stat-box' data-aos='fade-up'>
                <div className='fab-about-stat-box-inner'>
                  <h3 className='--title --same-height --txt-lg'>
                    매년 3배의 <br />
                    <strong>급속도 성장</strong>
                  </h3>
                  <p className='--font-basic'>
                    전세계의 FAB365 사용자 수는 빠르게 증가하고 있으며 <br />
                    2018년부터 매년 3배씩 늘어나고 있습니다.
                  </p>
                </div>
                <img
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/graph.svg'
                  className='--diagram'
                />
              </article>
            </div>
            <div className='--col'>
              <article className='fab-about-stat-box' data-aos='fade-up'>
                <div className='fab-about-stat-box-inner'>
                  <h3 className='--title --txt-lg'>
                    전 세계 3D 프린팅 유튜버들의
                    <br /> <strong>인정과 극찬</strong>
                  </h3>
                  <p className='--font-basic'>
                    3D Printing Nerd(580k), Uncle Jessi(413k), WildRoseBuilds(112k),
                    Break'n'Makes(70k) 등
                    <br />
                    전세계의 3D 프린팅 유튜버들이 자발적으로 FAB365의 디자인을 소개하고
                    극찬했습니다.
                  </p>
                  <div className='--youtubers'>
                    <figure>
                      <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/tb2.jpg' />
                      <figcaption>
                        <span className='--font-basic'>3D Printing Nerd</span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/tb3.jpg' />
                      <figcaption>
                        <span className='--font-basic'>WildRoseBuilds</span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/tb1.jpg' />
                      <figcaption>
                        <span className='--font-basic'>Break’n’Makers</span>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section fab-page-section-ko' id='fabPageAboutPhilosophy'>
        <div className='container fab-page-container'>
          <div className='--row'>
            <h3 className='--title --txt-xxl'>
              FAB365 <br />
              <strong>문화</strong>
            </h3>
            <div className='--desc'>
              <blockquote className='--quote --txt-lg --mb-50 --mb-30--m'>
                "오직 3D 프린팅에 미친 사람들, <br className='--when-mobile' />
                3D 프린팅을
                <br className='--when-desktop' />
                가장 많이 사용하는&nbsp;
                <br className='--when-mobile' />
                사람들, 3D 모델을 가장 잘 설계하는
                <br />
                <strong>
                  전문가들이 모여 끊임없이 <br className='--when-mobile' />
                  연구하고 있습니다
                </strong>
                ."
              </blockquote>
              <a href='' className='--more' onClick={e => goTo(e, ABOUT_PHILOSOPHY)}>
                <span className='--font-basic'>View more →</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='fab-page-section fab-page-section-ko' id='fabPageAboutFounders'>
        <div className='container fab-page-container'>
          <h2 className='--txt-xxl --txt-center --mb-70 --mb-40--m'>
            FAB365 <strong>창업자</strong>
          </h2>
          <div className='--founders'>
            <a href='' className='--founder' onClick={e => goTo(e, ABOUT_CEO)}>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/ceo1.jpg'
                alt=''
              />
              <div className='--desc'>
                <h4 className='--txt-lg'>
                  <strong>구 상 권</strong>
                </h4>
                <p className='--font-basic'>공동 최고 경영자 겸 컨텐츠 개발 책임자</p>
              </div>
            </a>
            <a href='' className='--founder' onClick={e => goTo(e, ABOUT_CO_CEO)}>
              <img
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/about/page/about/ceo2.jpg'
                alt=''
              />
              <div className='--desc'>
                <h4 className='--txt-lg'>
                  <strong>이 종 한</strong>
                </h4>
                <p className='--font-basic'>공동 최고 경영자 겸 플랫폼 서비스 책임자</p>
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutKo;
