import styled from 'styled-components';

export const NewSortBoxContainer = styled.div`
  border: 1px solid #000;
  border-radius: 10px;
  padding: 8px 20px;
  position: relative;

  display: flex;
  z-index: 90;
`;

export const TitleButton = styled.button`
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 10px;
    height: 8px;
    margin: 5px 0 0 10px;
  }
`;

export const OptionList = styled.ul`
  display: flex;
  flex-direction: row;

  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;

  width: 200px;

  display: flex;
  flex-direction: column;
`;

export const OptionItem = styled.li`
  display: flex;
  flex-direction: row;

  color: #000 !important;
  justify-content: flex-end;
`;

export const OptionButton = styled.button`
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100%;
  padding: 8px 20px !important;

  text-align: left !important;
`;
