import React from 'react';
import Slider from 'react-slick';

const slides = [
  { num: '01', name: 'R2D2' },
  { num: '02', name: 'ATAT' },
  { num: '03', name: 'WALL-E' },
  { num: '04', name: 'FALCON' },
  { num: '05', name: 'Star Destroyer' },
  { num: '06', name: 'X-wing' },
  { num: '07', name: 'X-Lambda' },
  { num: '08', name: 'TIE fighter' },
  { num: '09', name: 'Eve' },
  { num: '10', name: 'Razor Crest' },
  { num: '11', name: 'AT-ST' },
  { num: '12', name: 'Super Mario' },
  { num: '13', name: 'Pikachu' },
  { num: '14', name: 'BUZZ' },
  { num: '15', name: 'Ecto-1' },
];

function FreeitemSlide({ onClickSignUp }) {
  const settings = {
    rows: 1,
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    draggable: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <div className='invite-landing-freeitem-slot'>
      <Slider {...settings}>
        {slides.map(slide => (
          <div className='invite-landing-freeitem-slot-inner-container' key={slide.num}>
            <img
              srcSet={`
        /assets/img/inviteLanding/freeitem_slide_${slide.num}_mo.png  480w,
        /assets/img/inviteLanding/freeitem_slide_${slide.num}_pc.png 1920w
      `}
              src={`/assets/img/inviteLanding/freeitem_slide_${slide.num}_pc.png`}
              alt={slide.name}
            />
            <button className='invite-landing-signup' onClick={onClickSignUp}>
              DOWNLOAD
            </button>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default FreeitemSlide;
