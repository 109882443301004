import { sanitize } from 'dompurify';
import { useContext } from 'react';
import UserContext from '../../context/user/user';
import { imgsHtml, imgsToMedias, isEditable } from '../../util/comment';
import { DEFAULT_AVATAR } from '../avatar/const';
import CommentReplyItem from './CommentReplyItem';

const CommentReply = ({
  replys,
  pkComment,
  setCommentReply,
  showReply,
  idx,
  onDelete,
  onEdit,
  setShowImgViewer,
  setImgData,
  showProduct,
  editComment,
  designerId,
  value,
}) => {
  const { state: userState } = useContext(UserContext);

  // replys 오름차순으로 정렬
  const replysSort = replys.sort((a, b) => {
    if (a.id_comment > b.id_comment) return 1;
    if (a.id_comment < b.id_comment) return -1;
    return 0;
  });

  return (
    <>
      {replysSort?.map((r, i) => (
        <CommentReplyItem
          key={r[pkComment]}
          reply={r}
          pkComment={pkComment}
          showReply={target => showReply(idx)}
          setCommentReply={target => setCommentReply(target, idx)}
          isLast={userState.id && i === replysSort.length - 1}
          onDelete={onDelete}
          onEdit={onEdit}
          setShowImgViewer={setShowImgViewer}
          setImgData={setImgData}
          showProduct={showProduct}
          value={value}
          userState={userState}
          designerId={designerId}
        />
      ))}
    </>
  );
};

export default CommentReply;
