import { useRef, useEffect, useState } from 'react';
import { sanitize } from 'dompurify';
import { fileSize } from '../../../util/file';
import './ItemInfo.css';
import ItemDataVideo from '../../../component/ItemData/ItemDataVideo';
import styled from 'styled-components';
import ReadQuill from '../../../component/editor/ReadQuill';
import ItemIframe from './ItemIframe';

const ItemDetailInfo = ({ item, itemDatas, iframeSrc, id }) => {
  const detailheight = 430;
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const textRef = useRef(null);
  const body = item?.desc;

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        const element = textRef.current;
        if (element.offsetHeight >= detailheight) {
          setIsOverflowing(true);
          setMoreInfo(true);
        } else {
          setIsOverflowing(false);
        }
      }
    };
    checkTextOverflow();

    return () => {
      window.removeEventListener('load', checkTextOverflow);
    };
  }, [item, id, body, iframeSrc]);

  const onClickMore = () => {
    setMoreInfo(!moreInfo);
  };

  const decodeHtml = html => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  const decodedHtml = decodeHtml(body);

  return (
    <DetailInfo descHeight={moreInfo ? `${detailheight}px` : '100%'} id='detail-desc'>
      {item && (
        <>
          <div className='store-detail-tab-content active' id='store_detail_information'>
            <div className='container'>
              <div className='store-detail-content-block-left'>
                <div className='description' ref={textRef}>
                  <ItemDataVideo videos={itemDatas?.videos} />
                  {iframeSrc && <ItemIframe iframeSrc={iframeSrc} body={body} />}

                  {body?.includes('<p>') ? (
                    <DetailQuill>
                      <ReadQuill requestBody={decodedHtml} />
                    </DetailQuill>
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(body?.replaceAll('\n', '<br/>')),
                      }}
                      className='html'
                      style={{ textAlign: 'left' }}
                    />
                  )}

                  {itemDatas?.infos?.length !== 0 && (
                    <div className='table'>
                      {itemDatas?.infos?.map((info, idx) => {
                        return (
                          <div key={`${info.name}${idx}`} className='info'>
                            <p>{info.name}</p>
                            <span className='text'>{info.text}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {isOverflowing && (
            <>
              <button className='overflowingBtn' onClick={() => onClickMore()}>
                {moreInfo ? 'More Information' : 'Less Information'}
                <img
                  className='icon'
                  src={
                    moreInfo ? '/assets/img/icon/down_arrow.png' : '/assets/img/icon/up_arrow.png'
                  }
                />

                {moreInfo && <GradientImg src={'/assets/img/itemDetail/gradient.png'} />}
              </button>
            </>
          )}
        </>
      )}
    </DetailInfo>
  );
};

export default ItemDetailInfo;

const GradientImg = styled.img`
  width: 100%;
  position: absolute;
  bottom: 65px;
`;

const DetailInfo = styled.div`
  margin-top: 50px;
  padding-bottom: 60px;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;

  #store_detail_information {
    padding-bottom: 20px;
  }

  .container {
    padding: 0px !important;
  }

  .table {
    margin-top: 3rem;
    text-align: left;
    p {
      font-weight: 500;
      padding: 1rem 1.5rem;
    }

    span {
      font-weight: 500;
      line-height: 20px;
      padding: 1rem 1.5rem;
    }
  }

  .info {
    width: 100%;
    display: grid;
    -webkit-box-align: center;
    align-items: start;
    grid-template-columns: 30% 70%;

    min-height: 65px;
    box-sizing: border-box;

    :nth-child(odd) {
      background-color: #f5f7f7;
    }
  }

  .description {
    width: 100%;
    max-height: ${props => props.descHeight};
    text-overflow: ellipsis;
    overflow: hidden;
    /* padding-bottom: 30px; */

    box-sizing: border-box;

    text-align: left;
  }

  .overflowingBtn {
    position: relative;
    width: 100%;
    height: 56px;
    background-color: #fff;
    color: #282828;

    font-size: 16px;
    font-weight: 500;
    border: 1px solid #545454;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    font-family: 'Inter';

    box-shadow: 5px 5px 15px 0px #0000001a;
  }

  .icon {
    width: 24px;
    margin-top: 3px;
    margin-left: 6px;
  }

  .bg {
    position: absolute;
    top: -121px;
  }
`;

const DetailQuill = styled.div`
  .ql-editor {
    text-align: left !important;
    padding: 0px !important;
  }
`;
