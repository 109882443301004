// import DiscountPrice from '../../../component/section/DiscountPrice';

// const CartPrice = ({ data }) => {
//   console.log(data);
//   if (data.salesPrice <= 0) return 'FREE';

//   if (data.isDiscount)
//     return (
//       <>
//         <strong>$ {data.salesPrice}</strong> <s>$ {data.listPrice}</s>
//       </>
//     );

//   return <>`${Number(data.salesPrice).toFixed(2)}$`</>;
// };

// export default CartPrice;

import DiscountPrice from '../../../component/section/DiscountPrice';

const CartPrice = ({ data }) => {
  if (data.salesPrice <= 0) return 'FREE';

  if (data.isDiscount)
    return (
      <>
        <strong>$ {data.salesPrice}</strong> <s>$ {data.listPrice}</s>
      </>
    );

  return (
    <>
      <DiscountPrice
        finalPrice={`$${Number(data.salesPrice).toFixed(2)}`}
        discountRate={data.itemRowDtos.discount_rate}
        originPrice={data.itemRowDtos.original_price}
        cartProduct={'cartProduct'}
        fontSize={'20px'}
      />
    </>
  );
};

export default CartPrice;
