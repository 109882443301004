import React, { useState, useContext } from 'react';

import styled from 'styled-components';
import ReviewList from '../../Review/component/ReviewList';
import { ImgStyled } from '../../../styles/ImgStyled';
import { getMainThumb } from '../../../util/image';
import { getOidUrl } from '../../../util/url';
import { REVIEW } from '../../../route/constant';
import { Link, useNavigate } from 'react-router-dom';
import Pagenation from '../../../component/designers/Pagenation';

import ModalContext from '../../../context/modal/modal';
import { ls } from '../../../constant';
import { getSelectReviewItems } from '../../../action/request';
import { MY_REVIEW_WRITE } from '../../../route/constant';

const ItemDetailReview = ({
  reviewCount,
  reviews,
  moveScroll,
  setScrollRef,
  setReviewPage,
  reviewPage,
  id,
  isMobile,
}) => {
  const [pageNation, setPageNation] = useState();
  const navigate = useNavigate();
  const { action: modalAction } = useContext(ModalContext);

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path, { state: reviewPage });

    setScrollRef({ ref: 1, page: reviewPage, offsetTop: window.scrollY });
  };

  const onClickWriteReview = async () => {
    if (!isLoginUsingToken) {
      modalAction.setIsLogin(true);
    } else {
      const { data: resultReivews } = await getSelectReviewItems(id);
      if (resultReivews?.returnCode === 'C00008') {
        alert(resultReivews?.message);
      } else {
        setScrollRef({ ref: 1, page: reviewPage, offsetTop: window.scrollY });
        navigate(`${MY_REVIEW_WRITE}/${id}`);
      }
    }
  };

  return (
    <DetailReview>
      <div className='title-wrap'>
        <p className='reviews-title'>Reviews({reviewCount})</p>
        <button className='view-all' onClick={e => goTo(e, '/reviews')}>
          VIEW ALL
        </button>
      </div>
      <div>
        <ul className={isMobile ? 'mobile-review' : 'reviewList'}>
          {reviews?.map((r, idx) => {
            const url = getOidUrl(REVIEW, r.id, r.subject, r.body);
            return (
              <li className='list-item' key={idx}>
                <Link to={url} onClick={e => goTo(e, url)}>
                  <img src={getMainThumb(r)} alt='' className='thumb-img' />
                  <div className='item-info'>
                    <h2 className='post-title text-styeld'>{r.subject}</h2>
                    <p className='name text-styeld'>{r.user_name}</p>
                    <div className='post-meta'>
                      <span className='post-stat'>
                        <span className='hearted'>{r.like_count}</span>
                        <span className='comment'>{r.comment_count}</span>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      {reviewCount > 0 && (
        <Pagenation
          count={reviewCount}
          setPage={setReviewPage}
          page={reviewPage}
          value={'review'}
          moveScroll={moveScroll}
          setPageNation={setPageNation}
          viewCount={isMobile ? 4 : 8}
          margin={'50px 0px'}
          isMobile={isMobile}
        />
      )}
      <div className='btn-wrap'>
        <button className='write-review' onClick={() => onClickWriteReview()}>
          Write Review
        </button>
        <span>Did you make it? please write making story!</span>
      </div>
    </DetailReview>
  );
};

export default ItemDetailReview;

const DetailReview = styled.div`
  margin-top: 60px;
  border-bottom: 1px solid #d8d8d8;

  .title-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .reviews-title {
    color: #171717;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .view-all {
    display: block;
    float: right;
    background: url('/assets/img/2.0WebImg/ui/arrow-view-all.png') right no-repeat;
    background-size: 16px 16px;
    height: 28px;
    padding: 0 20px 0 0;
    line-height: 28px;
    color: #1fbe05 !important;
    font-size: 16px;
    text-align: right;
    font-weight: 600;
  }

  .reviewList {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 60px;
    grid-column-gap: 10px;
  }

  .mobile-review {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .post-meta {
      margin-top: 10px;
    }

    .list-item {
      border-bottom: 1px solid #e9ecef;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  button {
    background-color: #fff;
    border: none;
  }

  .thumb-img {
    width: 100%;
    border-radius: 8px;
  }

  .item-info {
    margin-top: 15px;
  }

  .post-title {
    font-size: 14px;
    font-weight: 400;
    color: #686868;
  }

  .name {
    color: #232323;
    font-size: 18px;

    width: 100%;
  }

  .text-styeld {
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .post-meta {
    margin-top: 25px;
  }

  .hearted {
    display: block;
    float: left;
    height: 14px;
    line-height: 14px;
    padding: 0 0 0 15px;
    background: url('/assets/img/2.0WebImg/ui/favorite.svg') left no-repeat;
    background-size: 14px 14px;
    color: #929599 !important;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }

  .comment {
    display: block;
    float: left;
    height: 14px;
    line-height: 14px;
    padding: 0 0 0 17px;
    background: url('/assets/img/2.0WebImg/ui/mode_comment.svg') left no-repeat;
    background-size: 14px 14px;
    color: #929599 !important;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    margin: 0 0 0 8px;
  }

  .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 20px;
  }

  .write-review {
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #545454;
    box-shadow: 5px 5px 15px 0px #0000001a;

    width: 23.375rem;
    height: 51px;
    border-radius: 4px;
    margin-bottom: 12px;
  }
`;
