import { useNavigate } from 'react-router-dom';
import ItemRecommand from '../../component/item/ItemRecommand';
import Layout from '../../component/layout/Layout';
import Menu from '../../component/menu/Menu';
import { PRINT_TIPS_LIST } from '../../route/constant';
import { renderTipFeature } from '../../util/renderer';

const BedType = ({ title, tab }) => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <Menu title={title} tab={tab} />
          <div className='container fab-accordion-mt-s1 fab-accordion-mb-s1'>
            <div className='fab-accordion __w1340'>
              <div className='fab-accordion-header'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
                <h2 className='fab-accordion-header--title'>Types of 3D Printer bed/plate</h2>
                <div className='fab-accordion-header--description'>
                  <p>
                    3D Printer bed/plate has to have a strong adhesion while printing but has to
                    come apart well when printing is completed.
                  </p>
                </div>
              </div>
              <div className='featured-story-list'>
                <ul>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/acryl.jpg',
                      '1. Plastic',
                      'A Bed type in early days of 3D printers. It warps and crooks which makes printing difficult if it is used for a long period of time.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/tape.jpg',
                      null,
                      'Because the adhesion is too strong, people used the blue tape on top of it.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/glass.jpg',
                      '2. Glass',
                      "It's the most popular and easy solution because it provides an excellent smooth surface but has less adhesion.",
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/carbon.jpg',
                      '3. Carbon coated glass',
                      'This is a bed that supplements the weakness of glass.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/Carbon_01.jpg',
                      null,
                      'If the temperature rises, the pattern of carbon expands that holds the printout. If the temperature lowers, it contracts and releases the printout. ',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/carbon2.jpg',
                      null,
                      'You have to wait for a long time because you can detach the printout after the temperature lowers completely.  You have to be cautious when using a metal tool such as a chisel, which might damage the bed.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/rubber.jpg',
                      '4. Magnetic rubber bed',
                      'It is hard to level the bed evenly because it’s not completely flat and the level of adhesion strength drops significantly if the amount of  printout increases.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/pei-pex.jpg',
                      '5. PEI/PEX coated bed',
                      'A bed coated with Polyethylenimine on a metal plate with high elasticity. It is highly flat and adhesive that you can detach the print-out by bending it instantly after printing.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/powder.jpg',
                      '6. Powder coated bed',
                      'As a bed coated with a special material called Ultem, it creates a unique texture on the bottom of the printout’s surface. You can get a surface almost the same as the plastic product made from the factory.',
                      null,
                      null
                    )}
                  </li>
                  <li>
                    {renderTipFeature(
                      'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/assets/bed_tips/powder2.jpg',
                      null,
                      'Since adhesion is weak, you have to control the height of the nozzle precisely in order to get good results.',
                      null,
                      null
                    )}
                  </li>
                </ul>
              </div>
              <div className='fab-accordion-footer'>
                <a
                  href=''
                  onClick={e => goTo(e, PRINT_TIPS_LIST)}
                  className='fab-accordion--btn-back'
                >
                  BACK
                </a>
              </div>
            </div>
          </div>

          <section>
            <ItemRecommand itemId={null} />
          </section>
        </div>
      </Layout>
    </>
  );
};

export default BedType;
