const ContactKo = () => {
  return (
    <>
      <div className='page-top about page-top-with-breadcrumb'>
        <div className='container container-lg'>
          <h1 className='page-title'>Contact</h1>
        </div>
      </div>

      <section className='about-section'>
        <div className='contact'>
          <div className='container'>
            <div className='about-description-block'>
              <div className='about-description-block-header'>
                <h2>Contact us</h2>
                <p>
                  Fab365는 3D 프린팅 라이프스타일의
                  <br />
                  새로운 기준을 제시합니다.
                </p>
              </div>
              <div className='about-description-block-body'>
                <p>
                  우리는 항상 열려있습니다. 새로운 3D 프린팅 문화를 이끌어가고 싶은 모든 사람들과
                  함께 하고자 합니다. 디자이너, 3D 모델러, 3D 프린트 판매 종사자 그리고 3D 프린팅
                  출력소와의 사업제휴 및 협력에 관해 궁금한 점 있으시면 언제든지 문의주시길
                  바랍니다.
                </p>
              </div>
            </div>
            <div className='diagram'>
              <div className='about-contact-info'>
                <div className='about-contact-info-item'>
                  <dl>
                    <dt>Tel</dt>
                    <dd>
                      <a href='tel:+82-2-579-0365'>+82-2-579-0365</a>
                    </dd>
                  </dl>
                </div>
                <div className='about-contact-info-item'>
                  <dl>
                    <dt>Fax</dt>
                    <dd>+82-2-529-0808</dd>
                  </dl>
                </div>
                <div className='about-contact-info-item'>
                  <dl>
                    <dt>E-mail</dt>
                    <dd>
                      <a href='mailto:INFO@FAB365.NET'>info@fab365.net</a>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className='about-description-block about-description-block-2'>
              <div className='about-description-block-header'>
                <h2>Address</h2>
                <p>서울 강남구 논현로 152, 302호 (우) 06301</p>
              </div>
              <div className='about-description-block-body'>
                <div className='contact-map'>
                  <iframe
                    title='company location'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.042578832753!2d127.04058595135695!3d37.48332157971438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca6b382915821%3A0xcc0c49da06ac0b1e!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDrj4Tqs6Ey64-ZIOuFvO2YhOuhnCAxNTI!5e0!3m2!1sko!2skr!4v1564368386846!5m2!1sko!2skr'
                    width='100%'
                    height='760'
                    frameBorder='0'
                    style={{ border: 0 }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactKo;
