import * as DOMPurify from "dompurify";

const TipSummary = ({ tip }) => {
  return (
    <>
      <li>
        <div className="featured-story-item">
          <div className="tb">
            {tip.URL !== null ? (
              <a href={tip.URL}>
                <img src={tip.IMAGE} alt={tip.SUBJECT} />
              </a>
            ) : (
              <img src={tip.IMAGE} alt={tip.SUBJECT} />
            )}
          </div>
          <div className="featured-story-desc">
            <h3 className="story-title">{tip.SUBJECT}</h3>
            <p
              className="story-excerpt"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tip.BODY) }}
            ></p>
            <span className="story-date">{tip.DATE}</span>
            {tip.URL !== null ? (
              <a href={tip.URL} className="btn btn-sm">
                Learn more
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </li>
    </>
  );
};

export default TipSummary;
