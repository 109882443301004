import React from 'react';
import { ViewDetailsBtn, Img } from '../designers';
import DesingersBoxs from './DesingersBoxs';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const DesignersInfo = ({
  onClickFollow,
  isFollow,
  designerSection,
  navigate,
  data,
  designerFollow,
  value,
  isApp,
  index,
  designerPage,
}) => {
  const handleFollowClick = e => {
    onClickFollow(e, index);
  };

  const handleViewDetailsClick = () => {
    navigate(`/designers/${data?.name.replace(/\s+/g, '')}`, {
      state: { location: 'fromDesignerSection', action: 'viewDetails' },
    });
  };

  const renderFollowButton = () => {
    if (isApp) return null;
    return (
      <button onClick={handleFollowClick} id={isFollow ? 'unFollow' : 'follow'}>
        <Img
          src={`/assets/img/designers/${isFollow ? 'unfollow' : 'follow_button'}.png`}
          width={120}
          height={42}
          right={8}
        />
      </button>
    );
  };

  const renderViewDetailsButton = () => {
    if (!designerSection) return null;
    return (
      <ViewDetailsBtn onClick={handleViewDetailsClick}>
        <img src={`/assets/img/designers/view_detail_icon.png`} alt='View Details' />
        <span>View Details</span>
      </ViewDetailsBtn>
    );
  };

  return (
    <DesignerMobile
      className='profile-header-wrap'
      bottom={designerPage ? 10 : 20}
      name={designerPage ? 36 : 28}
      top={designerPage ? 24 : 0}
      nameWidth={designerPage ? 100 : 75}
      designerPage={designerPage ? 120 : 100}
      justifyContent={designerPage ? 'flex-start' : 'space-between'}
    >
      <HeaderSection
        isMobile={true}
        data={data}
        renderFollowButton={renderFollowButton}
        renderViewDetailsButton={renderViewDetailsButton}
        designerPage={designerPage}
        handleViewDetailsClick={handleViewDetailsClick}
      />
      <HeaderSection
        isMobile={false}
        data={data}
        renderFollowButton={renderFollowButton}
        renderViewDetailsButton={renderViewDetailsButton}
        handleViewDetailsClick={handleViewDetailsClick}
        designerPage={designerPage}
      />
      <div className={'countBox'}>
        <DesingersBoxs data={data} designerFollow={designerFollow} value={value} />
      </div>
    </DesignerMobile>
  );
};

const HeaderSection = ({
  isMobile,
  data,
  renderFollowButton,
  renderViewDetailsButton,
  designerPage,
  handleViewDetailsClick,
}) => (
  <>
    {isMobile ? (
      <div className='profile-header-mobile'>
        <div
          className={designerPage ? 'designer-mobile-profileWrap' : 'mobile-profileWrap'}
          onClick={() => handleViewDetailsClick()}
        >
          <Img
            src={data?.logo}
            width={designerPage ? 90 : isMobile ? 60 : 100}
            right={12}
            radius={100}
            className='profile'
          />
          <span className='name'>
            {data?.name}{' '}
            <Img alt='badge' src='/assets/img/designers/designer_badge.png' width={24} />
          </span>
        </div>
        <div className='desinerInfo'>
          <div className='btnWrap'>
            {renderFollowButton()}
            {renderViewDetailsButton()}
          </div>
        </div>
      </div>
    ) : (
      <div className={designerPage ? 'designerPage-profile-header-pc' : 'profile-header-pc'}>
        <Img
          src={data?.logo}
          width={designerPage ? 120 : isMobile ? 60 : 100}
          right={24}
          radius={100}
          className='profile'
        />
        <div>
          <span className='name'>
            {data?.name}{' '}
            <Img alt='badge' src='/assets/img/designers/designer_badge.png' width={24} />
          </span>
          <div className='desinerInfo'>
            <div className='btnWrap'>
              {renderFollowButton()}
              {renderViewDetailsButton()}
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

export default DesignersInfo;

const DesignerMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;

  .profile-header-pc {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
  }

  .designerPage-profile-header-pc {
    width: ${props => (props.justifyContent ? '' : 100)}%;
    display: flex;
    -webkit-box-align: center;
    align-items: flex-end;
    .name {
      margin-bottom: 30px;
      display: inline-block;
      margin-top: 0px;
    }
  }

  .profile-header-mobile {
    display: none;
  }

  .name {
    color: #fff;
    font-size: ${props => props.name || 28}px;
    font-weight: 600;
    margin-bottom: ${props => props.bottom}px;
    margin-top: ${props => props.top}px;
    overflow-wrap: break-word;
    display: inline-block;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    .profile-header-pc,
    .designerPage-profile-header-pc {
      display: none;
    }

    .profile-header-mobile {
      width: 100%;
      display: block;
    }

    .mobile-profileWrap {
      display: flex;
      align-items: center;

      .name {
        height: 100%;
        line-height: 35.28px;
      }
    }

    .designer-mobile-profileWrap {
      display: flex;
      -webkit-box-align: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 450px) {
    .name {
      overflow-wrap: break-word;
      display: inline-block;
      width: ${props => props.nameWidth || 75}%;
      margin-bottom: 0px;
    }

    .countBox {
      margin-left: 0px;
    }
  }
`;
